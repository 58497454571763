import { useContext, useEffect, useRef, useState } from "react"
import { Card, CardContent, Divider, Grid, SvgIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';
import { GroupByEnum, SearchResult } from '../../../types/search';
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import { CompanyFile } from '../../../types/files';
import { getFileIcon } from "../../../components/folders/utils/files";
import { SearchContext } from "../../../contexts/SearchContext";

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: grey[50],
    color: theme.palette.text.primary,
    border: `1px solid ${grey[200]}`,
    padding: theme.spacing(1),
    borderRadius: 10,
    cursor: 'pointer',
  },
  singleItem: {
    backgroundColor: grey[50],
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    cursor: 'pointer',
  },
  itemVisited: {
    backgroundColor: grey[200]
  },
  itemSelected: {
    backgroundColor: theme.palette.info.main,
  },
  icons: {
    gridArea: 'icons',
    display: 'flex',
    alignItems: 'center',
  },
  fileName: {
    fontSize: 14,
    marginLeft: theme.spacing(1),
  },
  relevanceScore: {
    position: "absolute",
    top: -22,
    right: 0,
    padding: 5,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 15,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main
  },
  relevanceScoreSingleItem: {
    top: -18
  },
  text: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 2
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: "95%"
  },
  icon: {
    height: 'auto',
  },
  card: {
    borderRadius: 10,
    padding: 10,
    width: "100%",
    marginBottom: 10,
    '&:hover': {
      boxShadow: theme.shadows[8],
    },
  },
  cardSingleItem: {
    borderRadius: 10,
    width: "100%",
    marginTop: 25,
    position: "relative",
    overflow: "visible",
    '&:hover': {
      boxShadow: theme.shadows[8],
    },
  },
  cardSelected: {
    overflow: "visible",
    transformStyle: "preserve-3d",
    "&::before": {
      content: '""',
      height: "100%",
      width: 24,
      position: "absolute",
      top: 0,
      left: -3,
      borderRadius: 10,
      backgroundColor: theme.palette.primary.light,
      transform: "translateZ(-1px)"
    }
  },
  cardHeader: {
    padding: 0,
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 2
  },
  cardContent: {
    padding: 0,
    overflowY: 'auto',
    maxHeight: 250,
    paddingRight: 10,
    paddingBottom: '0 !important',
    paddingTop: 15
  },
  cardContentSingleItem: {
    maxHeight: 250,
    padding: 0,
    paddingBottom: '0 !important',
    overflowY: 'auto',
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  cardTitleSingleItem: {
    borderRadius: 10,
    width: "100%",
    marginTop: 10,
    position: "relative",
    overflow: "visible",
    cursor: "pointer",
    '&:hover': {
      boxShadow: theme.shadows[8],
    },
  },
  cardTitleSelected: {
    overflow: "visible",
    transformStyle: "preserve-3d",
    backgroundColor: theme.palette.info.main,
    "&::before": {
      content: '""',
      height: "100%",
      width: 24,
      position: "absolute",
      top: 0,
      left: -3,
      borderRadius: 10,
      backgroundColor: theme.palette.primary.light,
      transform: "translateZ(-1px)"
    }
  },
  title: {
    marginLeft: 10,
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflowWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 2
  }
}));

interface Props {
  searchResults: SearchResult[];//PerFile;
  selectedSearchResult?: SearchResult | CompanyFile;
  titleSearchResults?: CompanyFile[];
  onResultSelection: (result: SearchResult | CompanyFile) => void;
  searchFolder: CompanyFile | null;
  groupByType: GroupByEnum;
}

const SearchResultsList = ({
  searchResults,
  selectedSearchResult,
  titleSearchResults,
  onResultSelection,
  searchFolder,
  groupByType,
}: Props) => {
  const { fileStructure } = useContext(FileStructureContext);
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>(null);
  const [viewedResults, setViewedResults] = useState<(SearchResult | CompanyFile)[]>([]);
  const [localSelectedSearchResult, setLocalSelectedSearchResult] = useState<SearchResult | CompanyFile | undefined>();

  const {
    contextSelectedSearchResult,
  } = useContext(SearchContext);

  useEffect(() => {
    if (selectedSearchResult) {
      setLocalSelectedSearchResult(selectedSearchResult);
    } else if (contextSelectedSearchResult) {
      setLocalSelectedSearchResult(contextSelectedSearchResult);
    } else {
      setLocalSelectedSearchResult(undefined);
    }
  }, [contextSelectedSearchResult, selectedSearchResult]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, []);

  const viewResultItem = (result: SearchResult | CompanyFile) => {
    setViewedResults([...viewedResults, result]);

    onResultSelection(result);
  }

  const getResultSentence = (sentence: string, keyword: string) => {
    const keywordIndex = sentence.indexOf(keyword);

    if (keywordIndex < 0) {
      return sentence;
    }

    const lastSentencePart = sentence.slice(keywordIndex + keyword.length);

    const initialCharsLength = (78 - keyword.length) / 2;

    let initialSentencePart = sentence.slice(0, keywordIndex);
    if (initialSentencePart.length > initialCharsLength) {
      initialSentencePart = initialSentencePart.slice(-initialCharsLength);
    }

    initialSentencePart = `...${initialSentencePart}`;

    return (<>
      {initialSentencePart}
      <span style={{ fontWeight: "bold" }}>{keyword}</span>
      {lastSentencePart}
    </>
    );
  }

  const groupByFile = () => {
    if (!searchResults || !searchResults.length) {
      return {};
    }

    return searchResults.reduce((result: any, item) => {
      const fileKey = item.fileKey;

      if (result[fileKey]) {
        result[fileKey].results.push(item);
      } else {
        const file = fileStructure.find(v => v.key === fileKey);
        result[fileKey] = {
          id: file?.id,
          name: file?.name,
          results: [item]
        };
      }

      return result;
    }, {});
  }

  const getSearchResults = (): React.ReactNode[] => {
    return searchResults.map((result, index) => {
      const file = fileStructure.find(v => v.key === result.fileKey);
      const isSelectedResult = result.id === localSelectedSearchResult?.id;

      const isVisited = viewedResults.includes(result);

      return (
        <Card
          className={classNames(classes.cardSingleItem, {
            [classes.cardSelected]: isSelectedResult
          })}
          key={'search-results-list-284-' + index}>
          <Grid container p={1} pt={2}>
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
              <SvgIcon viewBox="0 0 30 30">
                {getFileIcon(file?.name || "")}
              </SvgIcon>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="subtitle1" fontWeight="bold" title={file?.name} className={classes.cardHeader}>{file?.name}</Typography>
              <Box className={classNames(classes.relevanceScore, classes.relevanceScoreSingleItem)}>
                <Typography variant="caption">{Math.floor(result.sortMetric * 100)}% relevance score</Typography>
              </Box>
            </Grid>
          </Grid>
          <CardContent className={classes.cardContentSingleItem}>
            <Box
              ref={isSelectedResult ? ref : undefined}
              className={classNames(classes.singleItem, {
                [classes.itemSelected]: isSelectedResult,
                [classes.itemVisited]: isVisited
              })}
              onClick={() => viewResultItem(result)}
            >
              <Box display="flex">
                <Typography variant="caption" fontWeight="bold" mr={1}>{`Page ${result.page + 1}`}</Typography>
              </Box>
              <Typography
                className={classes.text}
                variant="body2"
                color="inherit"
              >
                {getResultSentence(result.sentence, result.keyword)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      );
    });
  }

  const getGroupedSearchResult = (): React.ReactNode[] => {
    const nodes: React.ReactNode[] = [];

    const results = groupByFile();

    for (let prop in results) {
      const searchData: any = results[prop];

      const isCardSelected = !!searchData.results.find((x: SearchResult) => x.id === localSelectedSearchResult?.id);

      nodes.push(
        <Card
          className={classNames(classes.card, {
            [classes.cardSelected]: isCardSelected
          })}
          key={searchData.id}
        >
          <Grid container>
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
              <SvgIcon viewBox="0 0 30 30">
                {getFileIcon(searchData.name)}
              </SvgIcon>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="subtitle1" fontWeight="bold" title={searchData.name} className={classes.cardHeader}>{searchData.name}</Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <CardContent
            className={classes.cardContent}
          >
            {
              searchData.results.map((result: SearchResult, i: any) => { //is result.page zero based??  It shouldn't be!  See processing
                const isSelectedResult = result.id === localSelectedSearchResult?.id;
                const isVisited = viewedResults.includes(result);
                return <Box
                  className={classNames(classes.item, {
                    [classes.itemSelected]: isSelectedResult,
                    [classes.itemVisited]: isVisited
                  })}
                  mb={2.5}
                  onClick={() => viewResultItem(result)}
                  key={'search-results-list-366-' + i}>
                  <Box display="flex" position="relative">
                    <Typography variant="caption" fontWeight="bold" mr={1}>{`Page ${result.page + 1}`}</Typography>
                    <Box className={classes.relevanceScore}>
                      <Typography variant="caption">{Math.floor(result.sortMetric * 100)}% relevance score</Typography>
                    </Box>
                  </Box>
                  <Typography
                    className={classes.text}
                    variant="body2"
                    color="inherit"
                  >
                    {getResultSentence(result.sentence, result.keyword)}
                  </Typography>
                </Box>;
              })
            }
          </CardContent>
        </Card>
      );
    }

    return nodes;
  }

  const getTitleSearchResults = (): React.ReactNode[] | undefined => {
    return titleSearchResults?.map((result, i) => {
      const isSelectedResult = result === selectedSearchResult;
      const split = result.path.split('/');

      const displayPath = split.slice(0, split.length - 1).join(" > ");

      const isVisited = viewedResults.includes(result);

      return <Card
        onClick={() => viewResultItem(result)}
        className={classNames(classes.cardTitleSingleItem, {
          [classes.itemVisited]: isVisited,
          [classes.cardTitleSelected]: isSelectedResult,
        })}
        key={'search-results-list-406-' + i}>
        <Grid container p={1} pt={2}>
          <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
            <SvgIcon viewBox="0 0 30 30">
              {getFileIcon(result?.name || "")}
            </SvgIcon>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle1" fontWeight="bold" title={result?.name} className={classes.title}>{result?.name}</Typography>
          </Grid>
          <Grid item xs={12} marginLeft="30px">
            {!searchFolder && (
              <Typography
                className={classNames(classes.text, classes.fileName)}
                color="inherit"
              >
                {displayPath}
              </Typography>)}
          </Grid>
        </Grid>
      </Card>
    });
  }

  return <>{searchResults.length > 0 ?
    <>
      {groupByType === GroupByEnum.Document ? getGroupedSearchResult() : getSearchResults()}
    </>
    : titleSearchResults?.length! > 0 ? getTitleSearchResults() : ""}</>;
};

export default SearchResultsList;
