import { InfoOutlined } from "@mui/icons-material";
import { styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import makeStyles from "@mui/styles/makeStyles";
import colors from "../../../theme/colors";
import { ReactNode } from "react";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 200,
    },
});

export const TagsPanel: React.FC<{
    children: ReactNode,
    tagsExpanded: boolean,
    onClick: () => void,
    width?: string,
    showHint?: boolean,
}> = ({ children, tagsExpanded, onClick, width, showHint = false }) => {
    const classes = useStyles();

    return (
        <div className={classes.tagsPanelContainer}
            style={{
                width: tagsExpanded ? width || '25%' : 100,
                minWidth: tagsExpanded ? 400 : 100
            }}>
            <div className={classes.tagsPanel}
                style={{
                    width: tagsExpanded ? '100%' : '90%',
                    cursor: tagsExpanded ? 'default' : 'pointer',
                }}
                onClick={() => {
                    if (!tagsExpanded) {
                        onClick();
                    }
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: tagsExpanded ? 'space-between' : 'center',
                    alignItems: 'center',
                    margin: '8px 16px 0px 10px',
                }}>
                    {tagsExpanded && (
                        <Typography variant="h5" fontWeight="bold" px={5} mx={2} mb={2} className={classes.title}>
                            Comments
                        </Typography>
                    )}
                    <div className={classes.flex}>
                        <div>  {
                            tagsExpanded ? <KeyboardArrowRightIcon
                                onClick={() => {
                                    onClick();
                                }}
                                className={classes.arrow}
                            /> : (
                                <div>
                                    <ChatOutlinedIcon className={classes.chatIcon} />
                                    {showHint && <CustomWidthTooltip title={<Typography variant="body2">Add a comment anywhere in a file by highlighting text in it.</Typography>}>
                                        <InfoOutlined style={{ marginLeft: 4 }} />
                                    </CustomWidthTooltip>}
                                </div>
                            )
                        }
                        </div>
                        {tagsExpanded && showHint && (
                            <CustomWidthTooltip title={<Typography variant="body2">Add a comment anywhere in a file by highlighting text in it.</Typography>}>
                                <InfoOutlined />
                            </CustomWidthTooltip>
                        )}
                    </div>
                </div>
                {tagsExpanded && (children)}
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    flex: {
        display: 'flex',
        alignItems: 'center',
        gap: 4
    },
    tagsPanelContainer: {
        '-moz-transition': 'width .3s',
        '-ms-transition': 'width .3s',
        '-o-transition': 'width .3s',
        '-webkit-transition': 'width .3s',
        transition: 'width .3s ease-out',
        height: '100%',
        width: '100%',
        padding: 5,
        borderRadius: 8,
        overflow: 'hidden',
    },
    tagsPanel: {
        height: '100%',
        backgroundColor: "#f8f9fa",
        padding: 5,
        borderRadius: 16,
        overflow: 'hidden'
    },
    title: {
        fontSize: 16,
        margin: 0
    },
    arrow: {
        fontSize: 24,
        color: '#878E95',
        marginTop: 6,
        cursor: 'pointer',
    },
    chatIcon: {
        color: colors.neutral['500'],
        fontSize: 24,
        marginTop: 6,
        cursor: 'pointer',
    },
}));