import React from 'react';
import { ExtFile, FileMosaic } from "@files-ui/react";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles(() => ({
    fileContainer: {
        "& .files-ui-file-mosaic-icon-layer-container": {
            "& .files-ui-file-mosaic-image-layer > img" :{
                height: '80% !important',
            },
            "& .files-ui-file-mosaic-upload-layer > .elevation-layer-container > .elevation-list": {
                "& .elevation-item": {
                    "&:last-child > .files-ui-loader-container": {
                        backgroundColor: 'unset !important',
                        cursor: 'auto',
                        "& svg > circle": {
                            strokeWidth: '12px',
                        },
                        "& div:last-child": {
                            display: 'none !important',
                        },
                    },
                },
            },
        },
    },
    fileLabel: {
        "& .files-ui-file-mosaic-file-name": {
            marginTop: '16px',
            height: 'auto',
            fontSize: '0.8rem',
            lineHeight: '16px',
            color: '#048290',
            WebkitLineClamp: 3,
        },
        "& .files-ui-tooltiptext": {
            width: '100%',
        }
    },
}));

const FilePreviewer: React.FC<{
    extFiles: Array<ExtFile>,
    onDelete: (fileId: string | number | undefined) => void,
}> = ({ extFiles, onDelete }) => {
    const classes = useStyles();

    return (<>{
        extFiles.map((extFile: ExtFile) => (
            <FileMosaic {...extFile}
                className={classnames(classes.fileContainer, classes.fileLabel)}
                resultOnTooltip
                alwaysActive
                info
                preview
                onDelete={onDelete}
                key={'file-previewer-55-' + extFile.id}
            />
        ))
    }</>);
}

export default FilePreviewer;