import { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { scrollbarStyle } from "../../shared/dashboard";
import arrowLeft from '../../assets/icons/arrow-left.svg';
import arrowRight from '../../assets/icons/arrow-right.svg';
import arrowForward from '../../assets/icons/arrow-forward.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Moment } from "moment";
import { RevenueSubcategories } from "../molecules/dashboard-query-answer/MetricsAnswer";

export type MetricsData = {
  metric: string;
  value: string;
  date?: Moment;
  units?: string;
}

type TimelineOptions = {
  visibleItems: number;
  elements: (ReactNode|undefined)[];
  dateHeader: string[];
  metricsData: MetricsData[][];
  unstyledStart?: boolean;
  freeStyled?: number[];
  onEdit?: (customValues?: MetricsData) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: 'border-box',
    position: 'relative',
    padding: '0 30px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  wrapper: {
    ...scrollbarStyle,
  },
  items: {
    transition: 'all 0.8s',
    willChange: 'transform',
    width: 'auto',
    height: 'fit-content',
  },
  itemTop: {
    fontSize: '1rem',
    display: 'inline-block',
    left: 0,
    padding: '0 0 40px',
    position: 'relative',
    transition: 'none',
    verticalAlign: 'top',
    whiteSpace: 'normal',
    "&::after": {
      borderRadius: '50%',
      content: '""',
      height: '20px',
      position: 'absolute',
      backgroundColor: '#7bd4d4',
      left: '50%',
      right: 'auto',
      transform: 'translate(-50%, -50%)',
      top: '100%',
      width: '20px',
    },
  },
  itemBottom: {
    fontSize: '1rem',
    display: 'inline-block',
    left: 0,
    padding: '40px 0 0',
    position: 'relative',
    transition: 'none',
    verticalAlign: 'top',
    whiteSpace: 'normal',
    "&::after": {
      top: 0,
    },
  },
  itemStartStyle: {
    "&::after": {
      left: '25%',
      borderRadius: 'unset',
      backgroundColor: theme.colors.neutral['500'],
      height: '25px',
      width: '5px',
    },
  },
  itemUnstyled: {
    '&::after': { all: 'revert' },
  },
  itemInner: {
    display: 'table',
    height: '100%',
    width: '100%',
  },
  contentWrap: {
    display: 'table-cell',
    margin: 0,
    padding: 0,
    verticalAlign: 'bottom',
  },
  contentWrapBottom: {
    verticalAlign: 'top',
  },
  contentTop: {
    display: 'block',
    position: 'relative',
    backgroundColor: '#fff',
    border: '2px solid #7bd4d4',
    borderRadius: '16px',
    color: '#333',
    margin: '0px 4px',
    padding: '8px 12px',
    "&::before": {
      borderTop: '12px solid #7bd4d4',
      borderRight: '12px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: '12px solid transparent',
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)',
      top: '100%',
      content: '""',
      height: 0,
      position: 'absolute',
      width: 0,
    },
    "&::after": {
      borderTop: '10px solid #fff',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderBottom: '9px solid transparent',
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)',
      top: '100%',
      content: '""',
      height: 0,
      position: 'absolute',
      width: 0,
    },
  },
  contentBottom: {
    display: 'block',
    position: 'relative',
    backgroundColor: '#fff',
    color: '#333',
  },
  contentHoverable: {
    boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
    "&:hover": {
      boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
    },
  },
  contentUnstyled: {
    all: 'revert',
    "&:hover": { all: 'revert' },
    "&::before": { all: 'revert' },
    "&::after": { all: 'revert' },
  },
  header: {
    fontFamily: 'Inter',
    fontSize: '0.95rem',
    fontWeight: 'normal',
    textAlign: 'center',
    color: theme.colors.neutral['500'],
  },
  leftmostHeader: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  firstColumn: {
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    borderLeft: `2px solid ${theme.colors.neutral['300']}`,
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
    color: theme.palette.primary.main,
  },
  midColumn: {
    justifyContent: 'center',
    color: 'black',
  },
  lastColumn: {
    justifyContent: 'center',
    borderRight: `2px solid ${theme.colors.neutral['300']}`,
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px',
    color: 'black',
  },
  row: {
    height: '50px',
    alignItems: 'center',
    borderTop: `2px solid ${theme.colors.neutral['300']}`,
    borderBottom: `2px solid ${theme.colors.neutral['300']}`,
  },
  cell: {
    minWidth: '40px',
    fontFamily: 'Inter',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
      fontFamily: 'Inter',
      fontSize: '0.9rem',
      color: theme.colors.neutral['600'],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
  },
  icon: {
    width: 40,
    height: 40,
    padding: 'unset',
  },
  divider: {
    display: 'block',
    position: 'absolute',
    height: '4px',
    transform: 'translateY(-50%)',
  },
  navButton: {
    backgroundColor: '#fff',
    border: '2px solid #7bd4d4',
    borderRadius: '50px',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    display: 'block',
    height: '40px',
    outline: 'none',
    position: 'absolute',
    textIndent: '-9999px',
    transform: 'translateY(-50%)',
    width: '40px',
    "&:disabled": {
      opacity: '0.5',
      pointerEvents: 'none',
    },
    "&::before": {
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      content: '""',
      display: 'block',
      height: '14px',
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%) translateY(-50%)',
      top: '50%',
      width: '8px',
    },
  },
  navStatic: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'auto',
    height: '64px',
    width: '64px',
    "&::before": {
      height: '40px',
      width: '40px',
    },
  },
  navButtonPrev: {
    left: 0,
    "&::before": {
      backgroundImage: `url(${arrowLeft})`,
    },
  },
  navButtonNext: {
    right: 0,
    "&::before": {
      backgroundImage: `url(${arrowRight})`,
    }
  },
  navButtonForward: {
    right: 0,
    "&::before": {
      backgroundImage: `url(${arrowForward})`,
    }
  },
}));

const MetricsTimeline: React.FC<TimelineOptions> = ({
  visibleItems = 6,
  elements = [],
  unstyledStart = false,
  freeStyled = [],
  metricsData, dateHeader, onEdit
}) => {
  const classes = useStyles();
  const [offsetPosition, setOffsetPosition] = useState<number>(0);
  const [leftmostPosition, setLeftmostPosition] = useState<number>(0);
  const [indexPosition, setIndexPosition] = useState<number>(0);
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [maxScrollIndex, setMaxScrollIndex] = useState<number>(0);
  const [minItemWidth, setMinItemWidth] = useState<number>(0);
  const [maxItemHeight, setMaxItemHeight] = useState<number>(0);
  const [hoverIndex, setHoverIndex] = useState<number[]|undefined>(undefined);

  const scrollRef = useRef<HTMLElement|null>(null);
  const itemTopRef = useRef<HTMLElement|null>(null);
  const itemBottomRef = useRef<HTMLElement|null>(null);
  const winWidth = useRef<number>(window.innerWidth);
  const resizeTimer = useRef<number>(0);

  const inactiveDividerStyle = useMemo(() => ({
    top: maxItemHeight,
    left: indexPosition === 0 ? (minItemWidth / 4 - 10) : 20,
    width: (2 - 1.5 * indexPosition) * minItemWidth - scrollOffset,
  }), [indexPosition, maxItemHeight, minItemWidth, scrollOffset]);

  const activeDividerStyle = useMemo(() => ({
    top: maxItemHeight,
    left: ((2 - 1.5 * indexPosition) * minItemWidth - scrollOffset) + (indexPosition === 0 ? (minItemWidth / 4 - 10) : 20) ,
    right: 40,
  }), [indexPosition, maxItemHeight, minItemWidth, scrollOffset]);

  const setUpTimeline = useCallback(() => {
    const wrap = scrollRef.current as HTMLElement;
    const scrollerTop = itemTopRef.current as HTMLElement;
    const scrollerBottom = itemBottomRef.current as HTMLElement;
    const itemsTop = Array.from(scrollerTop?.children ?? []) as HTMLElement[];
    const itemsBottom = Array.from(scrollerBottom?.children ?? []) as HTMLElement[];

    if (!wrap?.offsetWidth)
      return;

    let minWidth = wrap?.offsetWidth / visibleItems;
    let maxTopHeight = 0;
    let maxBottomHeight = 0;

    itemsTop!.forEach((item: HTMLElement, i) => {
      item.removeAttribute('style');
      item.style.width = `${(i === 0 ? 2 : 1) * minWidth}px`;
      maxTopHeight = Math.max(maxTopHeight, item.offsetHeight);
      item.style.opacity = '0';
    });
    maxTopHeight += 12;
    itemsTop!.forEach((item: HTMLElement) => {
      item.style.height = `${maxTopHeight}px`;
      item.style.opacity = '1';
    });

    itemsBottom!.forEach((item: HTMLElement, i) => {
      item.removeAttribute('style');
      item.style.width = `${(i === 0 ? 2 : 1) * minWidth}px`;
      maxBottomHeight = Math.max(maxBottomHeight, item.offsetHeight);
      item.style.opacity = '0';
    });
    itemsBottom!.forEach((item: HTMLElement) => {
      item.style.height = `${maxBottomHeight}px`;
      item.style.opacity = '1';
    });

    setIndexPosition(0);
    setMinItemWidth(minWidth);
    setMaxItemHeight(maxTopHeight);
    setOffsetPosition(-0.36 * minWidth);
    setLeftmostPosition(0.5 * minWidth);
  }, [visibleItems]);

  const handleResize = useCallback(() => {
    clearTimeout(resizeTimer.current);
    resizeTimer.current = window.setTimeout(() => {
      const newWinWidth = window.innerWidth;

      if (newWinWidth !== winWidth.current) {
        if (!!elements.length)
          setUpTimeline();
        winWidth.current = newWinWidth;
      }
      clearTimeout(resizeTimer.current);
    }, 300);
  }, [elements, setUpTimeline]);

  const handleNavigate = useCallback((increment: number) => {
    setIndexPosition((prev) => {
      let next = prev + increment;

      scrollRef.current?.scrollTo(0, 0);
      setOffsetPosition((next === 0) ? (-0.36 * minItemWidth) : (-(next + 1) * minItemWidth));

      return next;
    });
  }, [minItemWidth]);

  const handleScroll = useCallback(() => {
    setScrollOffset(scrollRef.current?.scrollLeft ?? 0);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    scrollRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      // eslint-disable-next-line
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setMaxScrollIndex(elements.length >= visibleItems
      ? (elements.length - visibleItems + 1) : 0);
    setUpTimeline();
  // eslint-disable-next-line
  }, [elements, visibleItems]);

  if (!elements.length)
    return (<></>);

  return (<>
    <Box className={classes.container}>
      <Box className={classes.wrapper} ref={scrollRef}
        sx={{ overflowX: indexPosition === 0 ? 'auto' : 'hidden' }}>
        <Box className={classes.items} ref={itemTopRef}
          sx={{ transform: `translateX(${offsetPosition}px)`, }}>
          {(unstyledStart ? elements : [(<></>), ...elements]).map((element: ReactNode|undefined, index) => (
            <Box className={classnames(classes.itemTop,
              (index === 0) && (unstyledStart ? classes.itemStartStyle : classes.itemUnstyled))}
              sx={{ padding: (index === 0 || freeStyled.includes(index - (unstyledStart ? 0 : 1))) ? '0 !important' : '40px 0 0' }}
              key={'metrics-timeline-428-' + index}>
              {!!element && (
                <Box className={classes.itemInner}
                  sx={{ width: (index === 0) ? '50% !important' : '100%' }}>
                  <Box className={classes.contentWrap}>
                    <Box className={classnames(classes.contentTop,
                      (index > 0) && classes.contentHoverable,
                      (index === 0) && classes.contentUnstyled,
                      freeStyled.includes(index - (unstyledStart ? 0 : 1)) && classes.contentUnstyled,
                    )}> {element} </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Box className={classes.items} ref={itemBottomRef}
          sx={{ transform: `translateX(${offsetPosition}px)`, }}>
          {(unstyledStart ? elements : [(<></>), ...elements]).map((_, index) => (
            <Box className={classnames(classes.itemBottom, (index === 0) && classes.itemUnstyled)}
              key={'metrics-timeline-448-' + index}>
              <Box className={classes.itemInner}>
                <Box className={classnames(classes.contentWrap, classes.contentWrapBottom)}>
                  <Box className={classnames(classes.contentBottom,
                    (index === 0) && classes.contentUnstyled)}>
                    {!!metricsData.length && (
                      <Stack spacing={1}>
                        <Box marginLeft={(index === 0) ? `${leftmostPosition}px !important` : 0}
                          marginRight={(index === (elements.length - (unstyledStart ? 1 : 0))) ? '8px !important' : 0}>
                          <Typography className={classnames(classes.header, (index === 0) && classes.leftmostHeader)}>
                            {dateHeader[index]}
                          </Typography>
                        </Box>
                        {metricsData.map((row, rIndex, self)=> (
                          <Box marginLeft={(index === 0) ? `${leftmostPosition}px !important` : 0}
                            marginRight={(index === (elements.length - (unstyledStart ? 1 : 0))) ? '8px !important' : 0}
                            key={'metrics-timeline-464-' + index + '-' + rIndex}>
                            <Stack direction="row" spacing={1}
                              className={classnames((index === 0) ? classes.firstColumn
                                : (index === (elements.length - (unstyledStart ? 1 : 0))) ? classes.lastColumn
                                : classes.midColumn, classes.row)}
                              onMouseLeave={() => setHoverIndex(undefined)}>
                              {(index === 0) ? (<>
                                {RevenueSubcategories.map(cat => cat.toLowerCase()).includes(row[index].metric.trim().toLowerCase()) ? (
                                  <Typography className={classes.cell}>
                                    {'Revenue '}
                                    <Typography component="span" className={classes.subtitle} display="inline"> ({row[index].metric})</Typography>
                                  </Typography>
                                ): (
                                  <Typography className={classes.cell}>
                                    {row[index].metric}
                                  </Typography>
                                )}
                              </>) : (
                                <Typography component={Box} className={classes.cell}
                                  onMouseEnter={() => setHoverIndex((index !== 0) ? [rIndex, index] : undefined)}>
                                  {row[index].value}
                                </Typography>
                              )}
                              {(hoverIndex?.[0] === rIndex && hoverIndex?.[1] === index) ? (
                                <IconButton size="small"
                                  className={classes.icon}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onEdit?.([...self][rIndex][index]);
                                  }}
                                > <EditOutlinedIcon fontSize="small" /> </IconButton>
                            ) : (<Box />)}
                            </Stack>
                          </Box>
                        ))}
                      </Stack>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.divider}
        sx={{...inactiveDividerStyle, backgroundColor: '#7bd4d4' }} />
      <Box className={classes.divider}
        sx={{...activeDividerStyle,  backgroundColor: '#7bd4d4' }} />
      {indexPosition > 0 && (
        <Box component="button"
          className={classnames(classes.navButton, classes.navButtonPrev)}
          sx={{ top: maxItemHeight, }}
          onClick={(e: any) => { e.preventDefault(); handleNavigate(-1); }} />
      )}
      {indexPosition < maxScrollIndex ? (
        <Box component="button"
          className={classnames(classes.navButton, classes.navButtonNext)}
          sx={{ top: maxItemHeight, }}
          onClick={(e: any) => { e.preventDefault(); handleNavigate(1); }} />
      ) : (
        <Box component="button"
          className={classnames(classes.navButton, classes.navStatic, classes.navButtonForward)}
          sx={{ top: maxItemHeight, cursor: 'auto'}} />
      )}
    </Box>
  </>);
}

export default memo(MetricsTimeline);
