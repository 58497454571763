import { Fragment } from 'react';
import moment from 'moment';
import { FundingAnsweredQuestion } from '../dashboard-query-answer/FundingListAnswer';
import { View, Text, Link } from '@react-pdf/renderer';
import { NoInfoYet } from '../../../types/search';
import styles from '../dashboard-export/styles';
import { DashboardQuery } from '../../../types/files';

const FundingListAnswer: React.FC<{ query: DashboardQuery }> = ({ query }) => {
    const answer = !!query.answer ? JSON.parse(query.answer) : null;
    const fundingAnswer: FundingAnsweredQuestion["answer"]|null = !!answer ? answer?.answer : null;

    if (typeof fundingAnswer !== 'object' || !fundingAnswer?.length) {
        return (<>
            <Text style={styles.sentence}>{NoInfoYet.answer}</Text>
        </>);
    }

    if (!!fundingAnswer.length){
        fundingAnswer?.sort((curr: any, next: any) => {
            if (!!curr.announced_date && !!next.announced_date) {
                const currMoment = moment({
                  year: curr.announced_date.year,
                  month: curr.announced_date.month - 1,
                  day: curr.announced_date.day,
                });
                const nextMoment = moment({
                  year: next.announced_date.year,
                  month: next.announced_date.month - 1,
                  day: next.announced_date.day,
                });

                // If we have the same date, sort by money_raised then number of investors
                if (currMoment.isSame(nextMoment)) {
                  if (curr.money_raised === null && next.money_raised === null) {
                    return curr.number_of_investor > next.number_of_investor ? -1 : 1;
                  }

                  return Number(curr.money_raised) > Number(next.money_raised) ? -1 : 1;
                }

                return currMoment.isBefore(nextMoment) ? 1 : -1;
            }

            return 0;
        });
    }

    return (<>
        {fundingAnswer?.map((funding, i) => {
            const dateString = !!funding.announced_date ? moment({
                year: funding.announced_date!.year,
                month: funding.announced_date!.month - 1,
                day: funding.announced_date!.day,
            }).format('MMM D, YYYY') : null;

            return (
                <View key={'funding-list-answer-57-' + i} style={{ marginVertical: i > 1 ? 4 : 0}}>
                    {i === 0 && (<Text style={styles.fundingLastRound}>{"Last round: "}</Text>)}
                    <View style={styles.fundingBlock}>
                        <Text>{`${funding.funding_type}${!!dateString ? (' - ' + dateString) : ''}`}</Text>
                        {funding.money_raised && (
                            <Text >
                                {`US$${(funding.money_raised >= 1e6)
                                    ? (funding.money_raised / 1e6).toFixed(1) + 'M': (funding.money_raised >= 1e3)
                                        ? (funding.money_raised / 1e3).toFixed(1) + 'K': funding.money_raised.toString()}`.replace('.0', '')}
                            </Text>
                        )}
                    </View>
                    {!!funding.investor_list?.length &&
                        <View style={{ width: '80%' }} wrap>
                            <Text>
                                {"Investors: "}
                                {funding.investor_list?.map((investor, i) => (<Fragment key={'funding-list-answer-1174-' + i}>
                                    {investor.linkedin_profile_url ? (
                                        <Link style={{...styles.link, fontSize: 10}} src={investor.linkedin_profile_url} >
                                            {investor.name}
                                        </Link>
                                    ) : (
                                        <Text>{investor.name}</Text>
                                    )}
                                    {i < (funding.investor_list?.length || 0) - 1 && (
                                        <Text>, </Text>
                                    )}
                                </Fragment>))}
                            </Text>
                        </View>
                    }
                    {(i === 0 && fundingAnswer.length > 1) && (
                        <View style={{ marginTop: 16 }}>
                            <Text style={styles.fundingPriorRound}>{"Prior rounds:"}</Text>
                        </View>
                    )}
                </View>
            );
        })}
    </>);
}

export default FundingListAnswer;
