import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { DashboardNote } from "../../../types/files";
import NoteEditor from "../../molecules/dashboard-notes/viewer/NoteEditor";
import { makeStyles } from "@mui/styles";
import { scrollbarStyle } from "../../../shared/dashboard";
import NoteSummary from "../../molecules/dashboard-notes/viewer/NoteSummary";
import DashboardNoteDialog from "../../modals/dashboard-notes/DashboardNoteDialog";
import ArrayUtils from "../../../utils/ArrayUtils";
import AddIcon from '@mui/icons-material/Add';
import { DashboardQueriesContext } from "../../../contexts/DashboardQueriesContext";
import WhatsExtracted from "../../atoms/WhatsExtracted";
import { DashboardContext } from "../../../contexts/DashboardContext";
import { CommentaryTitle } from "../../molecules/dashboard-notes/Commentary";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '500px',
        padding: '0 16px',
    },
    notesListBlock: {
        width: '100%',
        height: '100%',
        padding: 8,
        flexWrap: 'nowrap',
        overflowX: 'auto',
        ...scrollbarStyle,
    },
    summaryListBlock: {
        width: '100%',
        height: '100%',
        padding: 8,
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    addIcon: {
        fill: '#048290',
    },
    editButton: {
        height: '32px',
        width: '32px',
        background: 'rgba(212,236,237,1)',
        border: 'none',
        borderRadius: '50%',
        margin: '4px 2px',
    },
}));

const DashboardNotesViewer: React.FC<{}> = () => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);
    const { queries } = useContext(DashboardQueriesContext);

    const [notesEditorOpen, setNotesEditorOpen] = useState<boolean>(false);
    const [summaryQuery, setSummaryQuery] = useState<string>('');
    const [newNote, setNewNote] = useState<boolean>(false);
    const [editNote, setEditNote] = useState<DashboardNote | undefined>(undefined);

    useEffect(() => {
        if (!!queries) {
            const noteSummary =  queries.find((query) => query.title === 'Note Summary');
            const noteSummaryAnswer = noteSummary?.answer ? JSON.parse(noteSummary.answer) : null;

            if (!!noteSummaryAnswer?.answer)
                setSummaryQuery(noteSummaryAnswer?.answer as string);
        }
    }, [queries]);

    return (<>
        <Stack direction="column" className={classes.container} spacing={1} alignItems="flex-start" justifyContent="center">
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                    <Typography variant="h5-bold" fontFamily="Poppins">Notes</Typography>
                    <IconButton size="small" className={classes.editButton} onClick={() => setNewNote(true)}>
                        <AddIcon className={classes.addIcon} />
                    </IconButton>
                </Stack>
                <Box mr={2}>
                    <WhatsExtracted source="notes" iconAtEnd />
                </Box>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" width="100%" height="calc(100% - 50px)">
                <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" width="600px" height="100%">
                    {!!summaryQuery && !!dashboard?.notes?.length ? (<>
                        <Stack direction="column" className={classes.summaryListBlock} spacing={2}>
                            <NoteSummary summary={summaryQuery} />
                        </Stack>
                    </>) : (
                        <Stack alignItems="center" justifyContent="center" width="100%" height="calc(100% - 70px)">
                            <Typography color="lightgray">
                                Summary of notes will appear here.
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                <Divider orientation="vertical" />
                    <Stack direction="row" className={classes.notesListBlock} spacing={2}>
                        {newNote && (
                            <NoteEditor
                                preselectedDashboard={dashboard ?? undefined}
                                newNote
                                onSelect={(editNote: DashboardNote) => {
                                    setEditNote(editNote);
                                    setNotesEditorOpen(true);
                                }}
                                onCloseNewNote={() => setNewNote(false)}
                                key={'dashboard-notes-viewer-94'} />
                        )}
                        {!!dashboard?.notes?.length ? (<>
                            {ArrayUtils.sortByDescending(dashboard.notes, 'createdAt')
                                .map((note: DashboardNote) => (note.title !== CommentaryTitle) && (
                                    <NoteEditor
                                        preselectedDashboard={dashboard}
                                        predefinedNote={note}
                                        onSelect={(editNote: DashboardNote) => {
                                            setEditNote(editNote);
                                            setNotesEditorOpen(true);
                                        }}
                                        key={'dashboard-notes-viewer-120-' + note.id} />
                            ))}
                        </>) : (!newNote) && (
                            <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                                <Typography color="lightgray">
                                    No notes yet.
                                </Typography>
                            </Stack>
                        )}
                </Stack>
            </Stack>
        </Stack>
        <DashboardNoteDialog
            preselectedDashboard={dashboard ?? undefined}
            predefinedNote={editNote}
            isOpen={notesEditorOpen}
            onClose={() => {
                setNotesEditorOpen(false);
                setEditNote(undefined);
            }}
        />
    </>);
}

export default DashboardNotesViewer;