
import React from "react";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    block: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
}));

const CircularProgressPercent: React.FC<{ value: number }> = ({ value }) => {
    const classes = useStyles();

    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress size="10em" variant="determinate" value={value} />
            <Stack className={classes.block} alignItems="center" justifyContent="center">
                <Typography variant="h6" component="div" color="text.secondary">
                    {`${Math.round(value)}%`}
                </Typography>
            </Stack>
        </Box>
    );
}

export default CircularProgressPercent;
