import { useCallback, useContext, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, Popover, Stack, Switch, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { GroupSettingsContext } from '../../../contexts/GroupSettingsContext';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { RefreshDataEnum } from '../../../types/files';
import { DashboardsContext } from '../../../contexts/DashboardsContext';
import { updateDashboardFunc } from '../../../lib/helper';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: 300,
        zIndex: 1300,
        '& > .MuiPaper-root': {
            width: '100%',
            height: 'fit-content',
            padding: '16px 12px',
            marginTop: 8,
            borderRadius: 8,
        }
    },
    title: {
        fontWeight: 'bold',
        fontFamily: 'Inter',
        color: '#000',
    },
    action: {
        width: '100%',
        fontWeight: 'bold',
        color: '#7bd4d4',
        "&:hover": {
            background: 'rgba(4, 130, 144, 0.04)'
        },
        cursor: 'pointer',
    },
    homeIcon: {
        width: 32,
        height: 32,
        fill: '#048290',
    },
    titleIcon: {
        width: 24,
        height: 24,
        fill: '#048290',
    },
    stopIcon: {
        width: 16,
        height: 16,
        color: theme.colors.error['500'],
    },
    switchButton: {
        width: 80,
        height: 36,
        padding: 0,
        transform: 'scale(0.7)',
        '& .MuiSwitch-switchBase': {
            padding: '6px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(40px)',
                '& + .MuiSwitch-track': {
                    border: '2px solid #048290',
                    backgroundColor: '#fff',
                    opacity: 1,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 24,
            height: 24,
            color: '#048290',
        },
        '& .MuiSwitch-track': {
            borderRadius: '20px',
            border: '2px solid #048290',
            backgroundColor: '#fff',
            opacity: 1,
            '&:before, &:after': {
                position: 'absolute',
                color: '#7bd4d4',
                fontFamily: 'Inter',
                fontSize: '0.8rem',
                fontWeight: 600,
            },
            '&:before': {
                content: '"ON"',
                padding: '7px',
            },
            '&:after': {
                content: '"OFF"',
                padding: '7px 0 0 43px',
            },
        },
    },
}));

const DashboardSettingsModal: React.FC<{
    flags?: any,
    onOpenManageTags?: () => void,
}> = ({ flags, onOpenManageTags }) => {
    const classes = useStyles();
    const { externalSync, setExternalSync } = useContext(GroupSettingsContext);
    const { dashboards, setDashboards } = useContext(DashboardsContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const hasSharedDashboard = useMemo(() => dashboards.some(dashboard => dashboard.isPublic), [dashboards]);

    const handleSyncAllNewExternal = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setExternalSync(event.target.checked);
    }, [setExternalSync]);

    const handleStopAllSharing = useCallback(async () => {
        const promises: Promise<boolean>[] = [];

        dashboards.forEach(dashboard => {
            if (dashboard.isPublic) {
                const updatedDashboard = {
                    ...dashboard,
                    isPublic: false,
                    refreshData: {
                        lastUpdatedBy: 'user',
                        shouldRefresh: true,
                        modifiedData: RefreshDataEnum.Public,
                    }
                };

                promises.push(updateDashboardFunc(updatedDashboard));
            }
        });

        Promise.all(promises).then(() => {
            setDashboards(prev => prev.map(dashboard =>
                dashboard.isPublic ? {...dashboard, isPublic: false} : dashboard,
            ));
            setAnchorEl(null);
        });
    // eslint-disable-next-line
    }, [dashboards]);

    return (<>
        <Button variant="text" onClick={event => setAnchorEl(event.currentTarget)}>
            <SettingsIcon className={classes.homeIcon} />
        </Button>
        <Popover open={Boolean(anchorEl)}
            className={classes.modal}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={() => setAnchorEl(null)}>
            <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="center" width="100%">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    <SettingsIcon className={classes.titleIcon} />
                    <Typography className={classes.title} display="inline">
                        Dashboard settings
                    </Typography>
                </Stack>
                <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="center" pl={2}>
                    <Typography
                        component={Box}
                        className={classes.action}
                        onClick={onOpenManageTags}>
                        {'Manage tags ...'}
                    </Typography>
                    {flags.zapierPlan && (
                        <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center">
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Tooltip placement="top-start" title={<Typography component="span" fontSize="0.8rem">
                                        {`You can sync this data to any connected app through `}
                                        <Typography
                                            component="a"
                                            href="https://zapier.com/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            display="inline"
                                            sx={{
                                                color: '#7bd4d4',
                                                fontSize: '0.8rem',
                                                textDecoration: 'none',
                                                "&:hover": { textDecoration: 'none !important', }
                                            }}
                                        >Zapier</Typography>.
                                        <Box m="12px" />
                                        {` Learn more about getting set up with Zapier `}
                                        <Typography
                                            component="a"
                                            href="/integrations"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            display="inline"
                                            sx={{
                                                color: '#7bd4d4',
                                                fontSize: '0.8rem',
                                                textDecoration: 'none',
                                                "&:hover": { textDecoration: 'none !important', }
                                            }}
                                        >here</Typography>.
                                    </Typography>}
                                    componentsProps={{tooltip:{sx:{
                                        display: 'flex', alignItems: 'center', borderRadius: 'unset', transform: 'translate(0px) !important',
                                        backgroundColor: 'rgba(97, 97, 97)', width: '210px', height: '110px',  padding: 2,
                                    }}}}>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                        <Typography fontWeight="bold" color="#7bd4d4"> Auto-sync </Typography>
                                        <ErrorIcon sx={{ height: '20px', width: '20px', transform: 'rotate(180deg)', fill: '#7bd4d4' }} />
                                    </Stack>
                                </Tooltip>
                                <Switch className={classes.switchButton} checked={externalSync} onChange={handleSyncAllNewExternal} disableRipple />
                            </Stack>
                            <Typography fontSize="0.8rem" color="darkgray">
                                {'When this is "ON", all new dashboards will auto-sync to your connected tools.'}
                            </Typography>
                        </Stack>
                    )}
                    {hasSharedDashboard && (
                        <Typography
                            component={Stack}
                            className={classes.action}
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            onClick={hasSharedDashboard ? handleStopAllSharing : undefined}>
                            <FiberManualRecordIcon className={classes.stopIcon} />
                            <span>{'Stop sharing all dashboards'}</span>
                        </Typography>
                    )}
                </Stack>
            </Stack>
        </Popover>
    </>);
}

export default withLDConsumer()(DashboardSettingsModal);
