import React, { useRef } from "react";
import { Dashboard } from "../../../types/files";
import { Text, View } from '@react-pdf/renderer';
import styles from "./styles";

const PDFFirstPage: React.FC<{ dashboard: Dashboard|null }> = ({ dashboard }) => {
    return (<>
        <View style={styles.headerMain}>
            <Text style={{...styles.headerTitle, marginBottom: 8}}>
                {dashboard?.title}
            </Text>
            <View style={styles.headerSub}>
                <View style={styles.headerLeft}>
                    {!!dashboard?.investmentStage && (<>
                        <View style={{...styles.headerChip, backgroundColor: '#DCE9D5'}}>
                            <Text style={styles.headerChipText}>{dashboard?.investmentStage}</Text>
                        </View>
                        <View style={{ marginRight: 8 }} />
                    </>)}
                    {!!dashboard?.status && (<>
                        <View style={{...styles.headerChip, backgroundColor: '#D3E1F1'}}>
                            <Text style={styles.headerChipText}>{dashboard?.status}</Text>
                        </View>
                    </>)}
                </View>
                <View style={styles.headerRight}>
                    {dashboard?.tags?.map((tag, i) => (
                        <View style={{ marginLeft: 8 }} key={'pdf-header-28-' + i}>
                            <View style={{...styles.headerChip, backgroundColor: '#F1F3F5'}}>
                                <Text style={styles.headerChipText}>{tag}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        </View>
    </>);
}

const PDFSucceedingPage: React.FC<{ dashboard: Dashboard|null }> = ({ dashboard }) => {
    return (<>
        <View style={styles.headerLeft}>
            <Text style={styles.headerTitle}>{dashboard?.title}</Text>
            <View style={{marginLeft: 16}} />
                <View style={styles.headerLeft}>
                    {!!dashboard?.investmentStage && (<>
                        <View style={{...styles.headerChip, backgroundColor: '#DCE9D5'}}>
                            <Text style={styles.headerChipText}>{dashboard?.investmentStage}</Text>
                        </View>
                        <View style={{ marginRight: 8 }} />
                    </>)}
                    {!!dashboard?.status && (<>
                        <View style={{...styles.headerChip, backgroundColor: '#D3E1F1'}}>
                            <Text style={styles.headerChipText}>{dashboard?.status}</Text>
                        </View>
                    </>)}
                </View>
        </View>
    </>);
}

const PDFHeader: React.FC<{ dashboard: Dashboard|null }> = ({ dashboard }) => {
    const dashboardRef = useRef<Dashboard|null>(null);
    const pageOffsetRef = useRef<number>(1);

    return (<>
        <View style={styles.header} fixed>
            {/* load fonts first */}
            <View style={{ fontFamily: 'Poppins', fontWeight: 700 }}/>
            <View style={{ fontFamily: 'Lato', fontWeight: 700 }}/>
            {/* render different headers */}
            <View render={({ pageNumber}) => {
                if (dashboard?.title !== dashboardRef?.current?.title) {
                    dashboardRef.current = dashboard;
                    pageOffsetRef.current = pageNumber;
                }

                return (!(pageNumber - pageOffsetRef.current) ? (
                    <PDFFirstPage dashboard={dashboard} />
                ) : (
                    <PDFSucceedingPage dashboard={dashboard} />
                ));
            }} />
            <View style={styles.topDivider} />
        </View>
    </>);
}

export default PDFHeader;