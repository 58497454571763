import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { User, Site } from 'microsoft-graph';
import path from 'path'
let graphClient: Client | undefined = undefined;

function ensureClient(authProvider: AuthCodeMSALBrowserAuthenticationProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider
    });
  }

  return graphClient;
}

export async function getUser(authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<User> {
  ensureClient(authProvider);

  // Return the /me API endpoint result as a User object
  const user: User = await graphClient!.api('/me')
    // Only retrieve the specific fields needed
    .select('displayName,userPrincipalName')
    .get();

  return user;
}

export async function getSite(authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<Site> {
  ensureClient(authProvider);
  const sites = (await graphClient!.api('/sites?$select=siteCollection,webUrl&$filter=siteCollection/root%20ne%20null')
    .get()).value;
  if (sites.length > 0) {
    return sites[0]
  }
  return {};
}

/**
 *
 * Example usage of the below functions.  This can be used to navigate the folder structure on Sharepoint.
 * My thought is that we have two "upload" buttons: one for sharepoint and one for everything else.
 * The "sharepoint" one will use the SharepointContext auth setup + the Storage.put from helpers/s3 to read files and upload them to our s3 buckets
 *
 *
 *
 * const siteId = "notissia234.sharepoint.com"
  getDocumentStrorageFromSharepoint(provider, siteId).then(results => {
    console.log(results)
    results.forEach((result: { id: string, name: string }) => {
      getFileListFromSharepoint(provider, siteId, result.id).then(lists => {
        console.log(lists)
        lists.forEach((subFolder: { id: string, name: string }) => {
          getSubFolderFromSharepoint(provider, siteId, result.id, subFolder.name).then(subFolders => {
            console.log(subFolders)
          })
        })
      })
    })
  })
 */


/**
 *
 * Another example
 *
 *  getDocumentStrorageFromSharepoint(provider, siteId).then(results => {
    console.log(results)
    results.forEach((result: { id: string, name: string }) => {
      getFileListFromSharepoint(provider, siteId, result.id).then(lists => {
        iterateOverSubFolders(provider, siteId, result.id, lists)

      })
    })
  })
 */


/**
 *
 * This returns an array of objects that look like this:
 *
 *  createdBy: {user: {…}}
    createdDateTime: "2022-05-15T05:18:50Z"
    description: ""
    driveType: "documentLibrary"
    id: "b!1yLXa7DuSk2F4msx9pdDV6XLmXb-2rJBlVLKWndjXLj9csqyPtQNRZ5v8DwJ9YHd"
    lastModifiedBy: {user: {…}}
    lastModifiedDateTime: "2022-06-17T14:46:10Z"
    name: "Documents"
    owner:
    group: {id: '9faef03f-c2f0-4521-9080-d6fde1af8306', displayName: 'Global Administrator'}
    [[Prototype]]: Object
    quota: {}
    webUrl: "https://notissia234.sharepoint.com/Shared%20Documents"
 */
export async function getDocumentStrorageFromSharepoint(authProvider: AuthCodeMSALBrowserAuthenticationProvider, siteId: string = "notissia234.sharepoint.com") {
  ensureClient(authProvider);
  return graphClient!.api(path.join('/sites', siteId, 'drives'))
    .get().then(r => r.value);
}

/**
 * This returns an array of objects that look like this:
 *
 *
 *  cTag: "\"c:{F4C36310-6447-416A-B70E-0EDFCB49602C},0\""
    createdBy: {user: {…}}
    createdDateTime: "2022-06-17T14:46:10Z"
    eTag: "\"{F4C36310-6447-416A-B70E-0EDFCB49602C},1\""
    fileSystemInfo: {createdDateTime: '2022-06-17T14:46:10Z', lastModifiedDateTime: '2022-06-17T14:46:10Z'}
    folder: {childCount: 6}
    id: "01PZ2VX6AQMPB7IR3ENJA3ODQO37FUSYBM"
    lastModifiedBy: {user: {…}}
    lastModifiedDateTime: "2022-06-17T14:46:10Z"
    name: "Solar Acquisition Project"
    parentReference: {driveType: 'documentLibrary', driveId: 'b!1yLXa7DuSk2F4msx9pdDV6XLmXb-2rJBlVLKWndjXLj9csqyPtQNRZ5v8DwJ9YHd', id: '01PZ2VX6F6Y2GOVW7725BZO354PWSELRRZ', path: '/drives/b!1yLXa7DuSk2F4msx9pdDV6XLmXb-2rJBlVLKWndjXLj9csqyPtQNRZ5v8DwJ9YHd/root:'}
    size: 83495378
    webUrl: "https://notissia234.sharepoint.com/Shared%20Documents/Solar%20Acquisition%20Project"
 */
// siteId= "notissia234.sharepoint.com"
export async function getFileListFromSharepoint(authProvider: AuthCodeMSALBrowserAuthenticationProvider, siteId: string, driveId: string) {
  ensureClient(authProvider);
  return graphClient!.api(path.join('/sites', siteId, 'drives', `${driveId}`, 'root', 'children'))
    .get().then(r => r.value);
}

/**
 *
 * This returns an array of objects that look like this:
 * 0:
    cTag: "\"c:{EED63069-748C-4F76-A738-BEE64175F341},0\""
    createdBy: {user: {…}}
    createdDateTime: "2022-06-17T14:46:11Z"
    eTag: "\"{EED63069-748C-4F76-A738-BEE64175F341},1\""
    fileSystemInfo: {createdDateTime: '2022-06-17T14:46:11Z', lastModifiedDateTime: '2022-06-17T14:46:11Z'}
    folder: {childCount: 2}
    id: "01PZ2VX6DJGDLO5DDUOZH2OOF64ZAXL42B"
    lastModifiedBy: {user: {…}}
    lastModifiedDateTime: "2022-06-17T14:46:11Z"
    name: "Financials"
    parentReference: {driveType: 'documentLibrary', driveId: 'b!1yLXa7DuSk2F4msx9pdDV6XLmXb-2rJBlVLKWndjXLj9csqyPtQNRZ5v8DwJ9YHd', id: '01PZ2VX6AQMPB7IR3ENJA3ODQO37FUSYBM', path: '/drives/b!1yLXa7DuSk2F4msx9pdDV6XLmXb-2rJBlVLKWndj…yPtQNRZ5v8DwJ9YHd/root:/Solar Acquisition Project'}
    size: 5910554
    webUrl: "https://notissia234.sharepoint.com/Shared%20Documents/Solar%20Acquisition%20Project/Financials"
 */
export async function getSubFolderFromSharepoint(authProvider: AuthCodeMSALBrowserAuthenticationProvider, siteId: string, driveId: string, subFolder: string) {
  ensureClient(authProvider);
  return graphClient!.api(path.join('/sites', siteId, 'drives', `${driveId}`, 'root:', `${subFolder}:`, 'children'))
    .get().then(r => r.value);
}


//https://docs.microsoft.com/en-us/graph/api/driveitem-get-content?view=graph-rest-1.0&tabs=javascript
//The output of this should, I beleive, be able to go directly to s3UploadToExternalBucket's "file" parameter
//the "path" for that Storage.put would come from the nested objects found in the getSubFolderFromSharepoint functions
export async function downloadFileFromSharepoint(authProvider: AuthCodeMSALBrowserAuthenticationProvider, siteId: string, driveId: string, fileLocation: string) {
  ensureClient(authProvider);
  const downloadUrlKey = "@microsoft.graph.downloadUrl"
  const result: { id: string, [downloadUrlKey]: string } = await graphClient!.api(path.join('/sites', siteId, 'drives', `${driveId}`, 'root:', fileLocation) + `?select=id,${downloadUrlKey}`)
    .get()
  const newUrl = result[downloadUrlKey]
  return graphClient!.api(newUrl).get().then(stream => new Response(stream))
    // Create an object URL for the response
    .then(response => response.blob())
}
