import React from "react";
import { Text, View } from '@react-pdf/renderer';
import styles from "./styles";
import { DashboardQuery } from "../../../types/files";
import ExportQuery from "../dashboard-queries-export/ExportQuery";

const PDFBody: React.FC<{ queries: DashboardQuery[] }> = ({ queries }) => {
    return (<>
        <View style={styles.body}>
            {queries.map((query, i) => (
                <View style={styles.query} key={'pdf-body-11-' + i}>
                    <Text style={styles.queryTitle}>
                        {query.title}
                    </Text>
                    <View style={styles.queryAnswer}>
                        <ExportQuery query={query} />
                    </View>
                </View>
            ))}
        </View>
    </>);
}

export default PDFBody;