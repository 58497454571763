import React from "react";
import { Dialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExportPDF from "../../molecules/dashboard-export/ExportPDF";
import { Dashboard, DashboardQuery } from "../../../types/files";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: '70vw',
            maxWidth: '70vw !important',
            height: '99vh',
            maxHeight: '99vh !important',
            padding: 0,
            margin: 0,
            zIndex: 3000,
        }
    },
}));

const DashboardExportPDFPreviewModal: React.FC<{
    isOpen: boolean,
    dashboard: Dashboard|null,
    filteredQueries: DashboardQuery[],
    onClose: () => void
}> = ({ isOpen, dashboard, filteredQueries, onClose }) => {
    const classes = useStyles();

    return (
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <ExportPDF dashboard={dashboard} queries={filteredQueries} preview />
        </Dialog>
    );
}

export default DashboardExportPDFPreviewModal;