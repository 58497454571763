import { ExtendedComment, Comment } from "../types/comments";
import { SaveDashboardItemType } from "../types/common";
import { Dashboard } from "../types/files";

export const processComments = (comments: ExtendedComment[], itemType: SaveDashboardItemType): ExtendedComment[] => {
    let filteredComments = comments
        .filter((comment: Comment) => !comment.parentId);

    if (itemType === SaveDashboardItemType.Page) {
        filteredComments.sort((first, second) => (first.commentedItem[0].pageIndex > second.commentedItem[0].pageIndex) ? 1 :
            ((second.commentedItem[0].pageIndex > first.commentedItem[0].pageIndex) ? -1 : 0));
    } else if (itemType === SaveDashboardItemType.ScreenCapture) {
        filteredComments.sort((first, second) => (Date.parse(first.createdAt!.toString()) > Date.parse(second.createdAt!.toString())) ? 1 : -1);
    }

    return filteredComments.map((element: Comment, index: number) => {
        const newElement = { ...element } as ExtendedComment;
        newElement.index = index + 1;
        newElement.children = comments.filter((child: Comment) => element.id === child.parentId);

        const createdDates = [newElement.createdAt, ...newElement.children.map(x => x.createdAt)].sort();

        newElement.lastUpdatedAt = createdDates[createdDates.length - 1];

        return newElement;
    });
};

export const processCommentsFromAllDashboards = (comments: ExtendedComment[], dashboards: Dashboard[]): ExtendedComment[] => {
    let index = 0;
    let newComments = comments;
    newComments = newComments.map((element: ExtendedComment) => {
        if (!element.parentId && getItemTypeFromComment(element, dashboards) === SaveDashboardItemType.ScreenCapture) {
            index = index + 1;
            const newElement = { ...element } as ExtendedComment;
            newElement.index = index;
            newElement.children = comments.filter((child: Comment) => element.id === child.parentId);
            return newElement;
        }
        return element;
    });
    return newComments.map((element: Comment, index: number) => {
        const newElement = { ...element } as ExtendedComment;
        // newElement.index = index + 1;
        newElement.children = comments.filter((child: Comment) => element.id === child.parentId);

        const createdDates = [newElement.createdAt, ...newElement.children.map(x => x.createdAt)].sort();

        newElement.lastUpdatedAt = createdDates[createdDates.length - 1];

        return newElement;
    });
}

export const processTopCommentsForDashboard = (comments: ExtendedComment[], dashboard: Dashboard): ExtendedComment[] => {
    let index = 0;

    return comments.map(element => {
        if (getItemTypeFromComment(element, [dashboard]) === SaveDashboardItemType.ScreenCapture) {
            index = index + 1;
            element.index = index;
        }

        return element;
    });
}

export const preProcessTopCommentsForDashboard = (comments: ExtendedComment[]): ExtendedComment[] => {
    const newComments =  comments.map(element => {
        const createdDates = [element.createdAt, ...element?.children?.map(x => x.createdAt) || []].sort();

        element.lastUpdatedAt = createdDates[createdDates.length - 1];

        element.children = comments.filter((child: Comment) => element.id === child.parentId);
        return element;
    });
    return newComments;
}

const getItemTypeFromComment = (comment: ExtendedComment, dashboards: Dashboard[]) => {
    for (let dashboard of dashboards) {
        if (dashboard?.screenshots?.find(element => comment.documentId === element.id)) {
            return SaveDashboardItemType.ScreenCapture;
        } else if (dashboard?.selections?.find(element => comment.documentId === element.id)) {
            return SaveDashboardItemType.Text;
        } else if (dashboard?.documents?.find(element => comment.documentId === element.id)) {
            return SaveDashboardItemType.Page;
        }
    };
    return null;
}
