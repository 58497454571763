import { useContext, useEffect, useState } from "react";
import { getIntegrationsFunc } from "../../lib/helper";
import { AuthContext } from "../../contexts/AuthContext";
import { integration } from "../../types/integrations";
import { Stack } from "@mui/material";
import IntegrationIcon from "./IntegrationIcon";

const IntegrationIcons: React.FC<{}> = () => {
    const { userGroup } = useContext(AuthContext);
    const [integrations, setIntegrations] = useState<{ integration: integration, status: string }[]>([]);

    useEffect(() => {
        getIntegrationsFunc(userGroup)
            .then((integrationsResult) => {
                if (integrationsResult?.length)
                    setIntegrations(integrationsResult);
            });
    }, [userGroup]);

    if (!integrations.length || !integrations.some(int => int.status === 'CONNECTED'))
        return (<></>);

    return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
            {integrations.map((int, i) => <IntegrationIcon icon={{ name: int.integration, enabled: int.status === 'CONNECTED' }} key={'integration-icons-22-' + i} />)}
        </Stack>
    );
}

export default IntegrationIcons;