import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Dashboard, Workspace } from "../../../types/files";
import MatchResult from "./MatchResult";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import useDashboards from "../../../hooks/useDashboards";

export enum AllCompaniesSelects {
    TopMatches = 'Top matches',
    MostRecent = 'Most recent',
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    selectorForm: {
        minWidth: 150,
        width: 'fit-content',
        height: 'auto',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                fill: theme.colors.neutral['600'],
                '&.Mui-disabled': {
                    fill: 'rgba(0, 0, 0, 0.26)',
                },
            },
            "& fieldset": {
              border: 0,
            },
        },
    },
    menuItem: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 8,
    },
    menuLabel: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
    },
    menuSelected: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 700,
        color: theme.colors.neutral['600'],
    },
}));

const AllMatches: React.FC<{
    workspace?: Workspace,
    collectionKey?: string,
    search?: string,
    searching: boolean,
    modal?: boolean,
}> = ({ workspace, collectionKey, search, searching, modal }) => {
    const classes = useStyles();
    const { workspace: globalWorkspace, mappedOverviewQueries } = useContext(DashboardsContext);
    const { dashboardsLoaded, filterDashboardList } = useDashboards();

    const [selected, setSelected] = useState<AllCompaniesSelects>(AllCompaniesSelects.TopMatches);
    const [dashboardArray, setDashboardArray] = useState<Dashboard[]>([]);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const dashboardScoreMap: Map<Dashboard, number> = useMemo(() => new Map(
        filterDashboardList(search || '').map(dashboard => {
            const investmentThesis: { workspaceId: string, score: number}[]
                = mappedOverviewQueries.get(`${dashboard.id}:Investment Thesis`) || [];
            const score = investmentThesis?.find(iT => iT.workspaceId === localWorkspace!.id)?.score ?? 0;

            return [dashboard, score];
        }))
    , [filterDashboardList, localWorkspace, mappedOverviewQueries, search]);

    const sortedDashboards = useMemo(() => (selected === AllCompaniesSelects.TopMatches)
        ? Array.from(dashboardScoreMap).sort((a, b) => b[1] - a[1]).map(entry => entry[0])
        : Array.from(dashboardScoreMap).sort((a, b) => b[0].createdAt.localeCompare(a[0].createdAt)).map(entry => entry[0])
    , [dashboardScoreMap, selected]);

    const hasPreferences = useMemo(() => [
        Boolean(localWorkspace?.investmentThesis?.focusArea?.tags?.length),
        Boolean(localWorkspace?.investmentThesis?.stage?.stages?.length),
        Boolean(localWorkspace?.investmentThesis?.geography?.regions?.length),
        Boolean(localWorkspace?.investmentThesis?.additionalDetails?.freeText?.length),
    ].some(prop => prop), [localWorkspace?.investmentThesis]);

    const hasThesisScores = useMemo(() => (
        Array.from(dashboardScoreMap).some(entry => !!entry[1])
    ), [dashboardScoreMap]);

    const loading = useMemo(() =>
        (!dashboardsLoaded || (!!dashboardScoreMap.size && !dashboardArray.length))
    , [dashboardArray.length, dashboardScoreMap, dashboardsLoaded]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setDashboardArray(sortedDashboards);
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [sortedDashboards]);

    useEffect(() => {
        setSelected((hasPreferences && hasThesisScores) ? AllCompaniesSelects.TopMatches : AllCompaniesSelects.MostRecent);
    }, [hasPreferences, hasThesisScores]);

    return (<>
        <Stack className={classes.container} alignItems="space-between" justifyContent="stretch">
            <FormControl className={classes.selectorForm} variant="outlined">
                <Select value={selected} onChange={(e) => setSelected(e.target.value as AllCompaniesSelects)}
                    renderValue={(selected: string) => (
                        <Typography className={classes.menuSelected}>
                            {selected}
                        </Typography>
                    )}>
                    <MenuItem className={classes.menuItem} value={AllCompaniesSelects.TopMatches}>
                        <Typography className={classes.menuLabel}>
                            {AllCompaniesSelects.TopMatches}
                        </Typography>
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value={AllCompaniesSelects.MostRecent}>
                        <Typography className={classes.menuLabel}>
                            {AllCompaniesSelects.MostRecent}
                        </Typography>
                    </MenuItem>
                </Select>
            </FormControl>
            <MatchResult
                workspace={localWorkspace || undefined}
                dashboards={dashboardArray}
                collectionKey={collectionKey}
                display={(hasPreferences && hasThesisScores) || (selected === AllCompaniesSelects.MostRecent)}
                searching={searching}
                loading={loading}
                bulkSelect={modal} />
        </Stack>
    </>);
}

export default AllMatches;