import React, { useContext, useEffect, useState } from "react";
import { Fab, Grid } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import SearchTerms from "../search-terms/SearchTerms";
import NewAdvancedSearch from "./NewAdvancedSearch";
import { SearchNavigationContext } from "../../../../contexts/SearchNavigationContext";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        minHeight: 156,
        padding: '0 20px',
        width: "100%",
        float: 'left',
        zIndex: 0,
        position: 'absolute',
        top: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    innerContainer: {
        overflow: 'hidden',
        height: '100%',
        width: "100%",
        float: 'left',
        zIndex: 0,
        position: 'absolute',
        top: 0,
        left: 0,
    },
    searchTermsContainer: {
        width: "85%",
        backgroundColor: theme.palette.common.white,
        marginTop: 20,
        borderRadius: 10
    },
    content: {
        width: "85%",
        padding: '0 !important',
        '-moz-transition': 'height .1s',
        '-ms-transition': 'height .1s',
        '-o-transition': 'height .1s',
        '-webkit-transition': 'height .1s',
        transition: 'height .1s ease',
        overflow: 'hidden',
        position: 'absolute',
        top: 90,
    },
    action: {
        padding: '0 5px',
        '-moz-transition': 'height .1s',
        '-ms-transition': 'height .1s',
        '-o-transition': 'height .1s',
        '-webkit-transition': 'height .1s',
        transition: 'height .1s ease',
        position: 'absolute',
        bottom: -20,
        zIndex: 100,
        left: "50%",
        color: theme.palette.common.white
    },
    shadowElement: {
        boxShadow: `0px -22px 32px -21px ${theme.palette.primary.main} inset`,
        '-webkit-box-shadow': `0px -22px 32px -21px ${theme.palette.primary.main} inset`,
        '-moz-box-shadow': `0px -22px 32px -21px ${theme.palette.primary.main} inset`,
        top: 136,
        position: 'absolute',
        height: '21px',
        width: '100%',
    }
}));

const NewSearchBar: React.FC<{
    keywords: string[][];
    keywordsAndOperations: string[];
    onKeywordsUpdated: (keywords: string[][], phrase: string[]) => void,
    onSearch: () => void;
    onReset: () => void;
}> = ({ keywords, keywordsAndOperations, onKeywordsUpdated, onSearch, onReset }) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [finishedEditing, setFinishedEditing] = useState<boolean>(false);
    const [shouldSearch, setShouldSearch] = useState<boolean>(false);
    const [searchEditorInputValue, setSearchEditorInputValue] = useState<string>("");
    const [hasSearched, setHasSearched] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        const locationState = location.state as any;
        if (locationState?.action === "search") {
            setTimeout(() => {
                setShouldSearch(true);
            }, 500);
        }
    }, [location]);

    useEffect(() => {
        if (shouldSearch) {
            onSearch();
            setShouldSearch(false);
            setHasSearched(true);
        }
        // eslint-disable-next-line
    }, [shouldSearch]);

    const {
        contextAdvancedSearchObject,
        contextSelectedTab,
        contextSelectedSearchType,
        contextSelectedDocTypes
    } = useContext(SearchNavigationContext);
    const searchElements = contextAdvancedSearchObject[contextSelectedTab];

    useEffect(() => {
        if (hasSearched) {
            setHasSearched(false);
        }
        // make available search button when filters change
        // eslint-disable-next-line
    }, [contextSelectedSearchType, contextAdvancedSearchObject, contextSelectedDocTypes]);

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="start"
            flexDirection="row"
            p={2}
            className={classes.container}
            style={{ height: `${open && searchElements?.length > 1 ? `calc(156px + 48px * ${searchElements?.length - 1})` : "156px"}` }}
        >
            <Grid item xs={12} sx={{ height: '100%' }}>
                <Grid container justifyContent="center"
                    alignItems="start"
                    flexDirection="row"
                    className={classes.innerContainer}>
                    <Grid
                        item
                        p={1}
                        className={classes.searchTermsContainer}
                    >
                        <Grid container>
                            <Grid item xs={0.5} display="flex" alignItems="center">
                                <SearchIcon />
                            </Grid>
                            <Grid item xs={10}>
                                <SearchTerms
                                    sourceKeywords={keywords}
                                    sourceSearchPhrase={keywordsAndOperations}
                                    placeholder={"Search in files"}
                                    addPlaceholder={"Add keyword"}
                                    finishedEditing={finishedEditing}
                                    primaryBackground
                                    appearDisabled={hasSearched}
                                    onChange={(k, ko) => {
                                        onKeywordsUpdated(k, ko);
                                        setHasSearched(false);
                                        if (finishedEditing) {
                                            setFinishedEditing(false);
                                            setShouldSearch(true);
                                        }
                                    }}
                                    onInputChange={(value) => {
                                        setSearchEditorInputValue(value);
                                        setHasSearched(false);
                                    }}
                                    onSearch={() => {
                                        setFinishedEditing(true);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1.5} textAlign="end">
                                {
                                    !!(((keywords && keywords.length) || searchEditorInputValue) && !hasSearched) &&
                                    <Fab
                                        color="secondary"
                                        size="medium"
                                        aria-label="search"
                                        variant="extended"
                                        sx={{ color: "#fff", marginRight: 1 }}
                                        onClick={() => {
                                            setFinishedEditing(true);
                                        }}
                                    >
                                        Search
                                    </Fab>
                                }
                                {
                                    hasSearched &&
                                    <Fab
                                        color="secondary"
                                        size="small"
                                        aria-label="clear"
                                        onClick={onReset}
                                    >
                                        <ClearIcon sx={{ color: "#fff" }} />
                                    </Fab>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item px={2} pt={2} className={classes.content}>
                        <NewAdvancedSearch />
                    </Grid>
                </Grid>
            </Grid>
            {searchElements.length > 1 &&
                <>
                    {!open && <div className={classes.shadowElement} />}
                    <Fab size="small" color="secondary" className={classes.action} onClick={() => { setOpen(!open) }}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Fab>
                </>
            }
        </Grid>
    )
};

export default NewSearchBar;