import MUIModal, { ModalProps } from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: theme.spacing(50),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&:focus-visible': {
      outline: 'none',
    }
  },
}));

interface Props extends ModalProps {}

const Modal = ({ children, className, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <MUIModal {...rest}>
      <Paper className={classNames(classes.paper, className)} elevation={0}>
        {children}
      </Paper>
    </MUIModal>
  );
};

export default Modal;
