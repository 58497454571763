import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, rectIntersection, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Box, Divider, Fab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { API, graphqlOperation } from "aws-amplify";
import { s3Delete } from "../../../helpers/s3";
import DashboardItemDetailsDialog from "../../modals/dashboard-details/DashboardItemDetailsDialog";
import { SaveDashboardItemType } from "../../../types/common";
import { AuthContext } from "../../../contexts/AuthContext";
import { FileStructureContext } from "../../../contexts/FileStructureContext";
import { onCommentsAll, onCommentsAllDeleted } from "../../../graphql/subscriptions";
import { preProcessTopCommentsForDashboard, processTopCommentsForDashboard } from "../../../helpers/processComments";
import { deleteCommentsFunc, getDashboardCommentsFunc, updateDashboardFunc } from "../../../lib/helper";
import { getDashboardItemType, scrollbarStyle } from '../../../shared/dashboard';
import { ExtendedComment, } from "../../../types/comments";
import { Dashboard, DashboardDocument, DashboardScreenshot, DashboardSelection, RefreshDataEnum } from "../../../types/files";
import ArrayUtils from "../../../utils/ArrayUtils";
import DashboardItem from "../../molecules/dashboard-items/DashboardItem";
import DashboardItemCard from "../../molecules/dashboard-items/DashboardItemCard";
import ConfirmDialog from "../../modals/ConfirmDialog";
import { DashboardContext } from "../../../contexts/DashboardContext";

const useStyles = makeStyles((theme) => ({
    dashboardItemsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        position: "relative",
    },
    dashboardItems: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflowX: 'auto',
        margin: "0 32px",
        gap: 16,
        ...scrollbarStyle
    },
    card: {
        margin: "20px 0px",
        border: '1px solid #F1F3F5',
        borderBottom: '4px solid #F1F3F5',
        borderRadius: 6
    },
    movable: {
        visibility: 'hidden'
    },
    fabScrollLeft: {
        left: 0,
        top: "calc(50% - 26px)",
        backgroundColor: 'white',
        color: '#048290',
        border: '1px solid #048290',
        '& svg': {
            fill: '#048290',
            width: 26,
            height: 30
        }
    },
    fabScrollRight: {
        right: 0,
        top: "calc(50% - 26px)",
        backgroundColor: 'white',
        color: '#048290',
        border: '1px solid #048290',
        '& svg': {
            fill: '#048290',
            width: 26,
            height: 30
        }
    },
}));

const deleteConfirmationItemMessages: { [key in SaveDashboardItemType]: any } = {
    [SaveDashboardItemType.Page]: "Are you sure you want to delete this page from the dashboard?",
    [SaveDashboardItemType.Text]: "Are you sure you want to delete the text selection from the dashboard?",
    [SaveDashboardItemType.ScreenCapture]: "Are you sure you want to delete the screen capture from the dashboard?"
};

const DashboardItems: React.FC<{}> = () => {
    const classes = useStyles();
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const { userGroup } = useContext(AuthContext);
    const { fileStructure } = useContext(FileStructureContext);
    const { dashboard, setDashboard } = useContext(DashboardContext);

    const [activeId, setActiveId] = useState<string | null>(null);
    // updated for every new change. useful in order to not trigger the re-draw of the items
    const [dashboardUpToDate, setDashboardUpToDate] = useState<Dashboard>();
    const [items, setItems] = useState<(DashboardDocument | DashboardScreenshot | DashboardSelection)[]>([]);
    const [comments, setComments] = useState<ExtendedComment[]>([]);
    const [openItem, setOpenItem] = useState<DashboardDocument | DashboardScreenshot | DashboardSelection | undefined>();
    const [detailsDialogOpen, setDetailsDialogOpen] = useState<boolean>(false);
    const [deleteItemConfirmDialogOpen, setDeleteItemConfirmDialogOpen] = useState<boolean>(false);
    const [deleteDashboardItemLoading, setDeleteDashboardItemLoading] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<DashboardDocument | DashboardScreenshot | DashboardSelection | undefined>();
    const [selectedComment, setSelectedComment] = useState<ExtendedComment | undefined>();
    const [, setHighlightedComments] = useState<number[]>([]);
    const [scrollLeftArrowVisible, setScrollLeftArrowVisible] = useState<boolean>(false);
    const [scrollRightArrowVisible, setScrollRightArrowVisible] = useState<boolean>(false);

    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const commentSubscriptionRef = useRef<any>(null);
    const commentDeletedSubscriptionRef = useRef<any>(null);

    const subscribeComments = useCallback(async (localComments: ExtendedComment[]) => {
        if (commentSubscriptionRef.current) {
            commentSubscriptionRef.current.unsubscribe();
        }

        if (userGroup && dashboard) {
            commentSubscriptionRef.current = await (API.graphql(
                graphqlOperation(
                    onCommentsAll, { group: userGroup }
                )) as any)
                .subscribe({
                    next: async ({ value }: any) => {
                        if (!value?.data?.onCommentsAll?.id) {
                            return;
                        }

                        const localDashboardItems = [...dashboard.selections?.map(x => x.id) || [], ...dashboard.screenshots?.map(x => x.id) || []];

                        if (!localDashboardItems.includes(value.data.onCommentsAll.documentId)) {
                            return;
                        }

                        if (localComments.find(x => x.id === value.data.onCommentsAll.id)) {
                            setComments(localComments.map(x => {
                                if (x.id === value.data.onCommentsAll.id) {
                                    return value.data.onCommentsAll;
                                }

                                return x;
                            }));
                        } else {
                            if (value.data.onCommentsAll?.parentId) {
                                setComments(localComments.map(x => {
                                    if (x.id === value.data.onCommentsAll.parentId) {
                                        if (!x.children) {
                                            x.children = [value.data.onCommentsAll];
                                        } else if (x.children.find(c => c.id === value.data.onCommentsAll.id)) {
                                            x.children = x.children.map(c => {
                                                if (c.id === value.data.onCommentsAll.id) {
                                                    return value.data.onCommentsAll;
                                                }

                                                return c;
                                            });
                                        } else {
                                            x.children.push(value.data.onCommentsAll);
                                        }
                                    }

                                    return x;
                                }));
                            } else {
                                if (!dashboard) {
                                    return;
                                }
                                const newComment = { ...value.data.onCommentsAll, lastUpdatedAt: value.data.onCommentsAll.createdAt } as ExtendedComment;
                                const ascendingComments = ArrayUtils.sortByAscending([{ ...newComment }, ...localComments], "lastUpdatedAt");
                                const processedComments = processTopCommentsForDashboard(ascendingComments, dashboard);
                                setComments(ArrayUtils.sortByDescending(processedComments, "lastUpdatedAt"));
                            }
                        }
                    }
                });
        }
    }, [userGroup, dashboard]);

    const subscribeCommentDeleted = useCallback(async (localComments: ExtendedComment[]) => {
        if (commentDeletedSubscriptionRef.current) {
            commentDeletedSubscriptionRef.current.unsubscribe();
        }

        if (userGroup && dashboard) {
            commentDeletedSubscriptionRef.current = await (API.graphql(
                graphqlOperation(
                    onCommentsAllDeleted, { group: userGroup }
                )) as any)
                .subscribe({
                    next: async ({ value }: any) => {
                        if (!value?.data?.onCommentsAllDeleted?.id) {
                            return;
                        }

                        const localDashboardItems = [...dashboard.selections?.map(x => x.id) || [], ...dashboard.screenshots?.map(x => x.id) || []];

                        if (!localDashboardItems.includes(value.data.onCommentsAllDeleted.documentId)) {
                            return;
                        }

                        if (value.data.onCommentsAllDeleted.parentId) {
                            setComments(localComments.map(x => {
                                if (x.id === value.data.onCommentsAllDeleted.parentId && x.children) {
                                    x.children = x.children.filter(c => c.id !== value.data.onCommentsAllDeleted.id);
                                }

                                return x;
                            }));
                        } else {
                            setComments(localComments.filter(x => x.id !== value.data.onCommentsAllDeleted.id));
                        }
                    }
                });
        }
    }, [userGroup, dashboard]);

    const onNext = () => {
        if (!openItem) {
            return;
        }
        const oldOpenIndex = items.findIndex(x => x === openItem);
        if (oldOpenIndex === items.length - 1) {
            const newItem = items[0];
            setOpenItem(newItem);
        } else {
            const newItem = items[oldOpenIndex + 1];
            setOpenItem(newItem);
        }
    }

    const onBack = () => {
        if (!openItem) {
            return;
        }
        const oldOpenIndex = items.findIndex(x => x === openItem);
        if (oldOpenIndex === 0) {
            const newItem = items[oldOpenIndex + 1];
            setOpenItem(newItem);
        } else {
            const newItem = items[oldOpenIndex - 1];
            setOpenItem(newItem);
        }
    }

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (!dashboard)
            return;

        setActiveId(null);
        setDetailsDialogOpen(false);

        if (active?.id !== over?.id) {
            const oldIndex = items.map(x => x.id).indexOf(active?.id);
            const newIndex = items.map(x => x.id).indexOf(over?.id);
            const newItems = arrayMove(items, oldIndex, newIndex);
            setItems(newItems);

            const newDashboard = {
                ...dashboardUpToDate!,
                refreshData: {
                    lastUpdatedBy: 'user',
                    shouldRefresh: false,
                    modifiedData: RefreshDataEnum.Items
                }
            };

            if (newDashboard.documents) {
                newDashboard.documents.map((dataElement) => {
                    newItems.forEach((item, index) => {
                        if (item.id === dataElement.id) {
                            dataElement.order = index;
                        }
                    })
                    return dataElement;
                });
            }
            if (newDashboard.screenshots) {
                newDashboard.screenshots.map((dataElement) => {
                    newItems.forEach((item, index) => {
                        if (item.id === dataElement.id) {
                            dataElement.order = index;
                        }
                    })
                    return dataElement;
                });
            }
            if (newDashboard.selections) {
                newDashboard.selections.map((dataElement) => {
                    newItems.forEach((item, index) => {
                        if (item.id === dataElement.id) {
                            dataElement.order = index;
                        }
                    })
                    return dataElement;
                });
            }

            updateDashboardFunc(newDashboard);
            setDashboardUpToDate({ ...newDashboard });
        }
    }

    const deleteItem = async () => {
        if (!dashboard || !itemToDelete)
            return;

        setDeleteDashboardItemLoading(true);

        const itemType = getDashboardItemType(itemToDelete);

        const newDashboard = {
            ...dashboardUpToDate!,
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: false,
                modifiedData: RefreshDataEnum.Items
            }
        };

        switch (itemType) {
            case SaveDashboardItemType.Page: {
                newDashboard.documents = newDashboard.documents?.filter(x => x.id !== itemToDelete.id) || [];
                break;
            }

            case SaveDashboardItemType.Text: {
                newDashboard.selections = newDashboard.selections?.filter(x => x.id !== itemToDelete.id) || [];
                break;
            }

            case SaveDashboardItemType.ScreenCapture: {
                await s3Delete({ path: itemToDelete.id });
                newDashboard.screenshots = newDashboard.screenshots?.filter(x => x.id !== itemToDelete.id) || [];
                break;
            }

            default: break;
        }

        updateDashboardFunc(newDashboard).then(
            () => {
                if (itemType !== SaveDashboardItemType.Page) {
                    deleteCommentsFunc(itemToDelete.id)
                        .then(() => {
                            setItems(items.filter(x => x.id !== itemToDelete.id));
                            setDeleteDashboardItemLoading(false);
                            setDeleteItemConfirmDialogOpen(false);
                        })
                        .catch((err) => console.log(err));
                } else {
                    setItems(items.filter(x => x.id !== itemToDelete.id));
                    setDeleteDashboardItemLoading(false);
                    setDeleteItemConfirmDialogOpen(false);
                }
                setDashboard({ ...newDashboard });
            },
            (error: Error) => {
                setDeleteDashboardItemLoading(false);
                setDeleteItemConfirmDialogOpen(false);
                console.log(error);
            }
        )
    }

    const updateItem = (item: DashboardDocument | DashboardScreenshot | DashboardSelection) => {
        if (!dashboard)
            return;

        const newDashboard = {
            ...dashboardUpToDate!,
            updatedAt: (new Date()).toISOString(),
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: false,
                modifiedData: RefreshDataEnum.Items
            }
        } as Dashboard;
        const itemType = getDashboardItemType(item);
        if (itemType === SaveDashboardItemType.Page && newDashboard.documents) {
            newDashboard.documents = newDashboard.documents?.map(element => {
                if (element.id === item.id) {
                    return item as DashboardDocument;
                }
                return element;
            });
        } else if (itemType === SaveDashboardItemType.Text && newDashboard.selections) {
            newDashboard.selections = newDashboard.selections?.map(element => {
                if (element.id === item.id) {
                    return item as DashboardSelection;
                }
                return element;
            });
        } else if (newDashboard.screenshots) {
            newDashboard.screenshots = newDashboard.screenshots?.map(element => {
                if (element.id === item.id) {
                    return item;
                }
                return element;
            });
        }

        updateDashboardFunc(newDashboard).then(() => {
            setDashboard({ ...newDashboard });
        });
    }

    const adjustScrollArrows = () => {
        setTimeout(() => {
            if (!scrollRef.current) {
                return;
            }

            setScrollLeftArrowVisible(scrollRef.current?.scrollLeft > 0);
            setScrollRightArrowVisible(!(scrollRef.current.scrollWidth - scrollRef.current.scrollLeft === scrollRef.current?.clientWidth));
        }, 500);
    }

    useEffect(() => {
        let newItems: any[] = [];

        if (dashboard) {
            setDashboardUpToDate({ ...dashboard });

            if (dashboard.documents && dashboard.documents.length) {
                newItems = [...newItems, ...dashboard.documents];
            }
            if (dashboard.screenshots && dashboard.screenshots.length) {
                newItems = [...newItems, ...dashboard.screenshots];
            }
            if (dashboard.selections && dashboard.selections.length) {
                newItems = [...newItems, ...dashboard.selections];
            }

            newItems = newItems.sort((a, b) => (a === null) ? 1 : (b === null) ? -1 : a.order - b.order);
            newItems.map((el, index) => {
                if (el.order === null) {
                    el.order = index;
                }
                return el;
            });

            getDashboardCommentsFunc(dashboard.id).then(results => {
                const preProcessedComments = preProcessTopCommentsForDashboard(results);
                const ascendingComments = ArrayUtils.sortByAscending(preProcessedComments, "lastUpdatedAt");
                const processedComments = processTopCommentsForDashboard(ascendingComments, dashboard);
                setComments(ArrayUtils.sortByDescending(processedComments, "lastUpdatedAt"));
            }).finally(() => {
                adjustScrollArrows();
            });

            setItems(newItems);

            if (openItem) {
                const newOpenItem = newItems.find(e => e.id === openItem.id);
                setOpenItem(newOpenItem);
            }

            adjustScrollArrows();
        }
        // eslint-disable-next-line
    }, [dashboard]);

    useEffect(() => {
        subscribeComments(comments);
        subscribeCommentDeleted(comments);

        return () => {
            commentSubscriptionRef?.current?.unsubscribe();
            commentDeletedSubscriptionRef?.current?.unsubscribe();
        }
    }, [comments, subscribeComments, subscribeCommentDeleted]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Box display="none">
            {!!items.length && <Divider sx={{ mb: "16px !important"}}/>}
            <Box className={classes.dashboardItemsContainer}>
                <DndContext sensors={sensors} collisionDetection={rectIntersection}
                    onDragStart={({ active }) => {
                        if (!active) {
                            return;
                        }

                        setActiveId(active.id);
                    }}
                    onDragCancel={() => setActiveId(null)}
                    onDragEnd={handleDragEnd}>
                    <div ref={scrollRef} className={classes.dashboardItems}>
                        <SortableContext items={items} strategy={rectSortingStrategy}>
                            {items.length ?
                                items.map((prop, index) => <div
                                    style={{}}
                                    className={classNames(classes.card, {
                                        [classes.movable]: activeId === prop.id
                                    })}
                                    ref={el => itemRefs.current[index] = el}
                                    data-scrollid={`dashboard-item-${prop.id}`}
                                    key={'dashboard-details-page-1811-' + index}
                                >
                                    <DashboardItem id={prop.id!}>
                                        <DashboardItemCard
                                            item={prop}
                                            dashboard={dashboard!}
                                            onItemRemoved={deleteItem}
                                            onDashboardUpdated={(data: Dashboard) => { setDashboard(data); }}
                                            files={fileStructure}
                                            setOpenItem={setOpenItem}
                                            setDetailsDialogOpen={setDetailsDialogOpen}
                                            setItemToDelete={setItemToDelete}
                                            setDeleteItemConfirmDialogOpen={setDeleteItemConfirmDialogOpen}
                                            externalComments={comments.filter(c => c.documentId === prop.id)}
                                            setHighlightedComments={() => {
                                                setHighlightedComments(comments.filter(c => c.documentId === prop.id).map(c => c.index || 0));
                                            }}
                                        />
                                    </DashboardItem>
                                </div>) :
                                <></>
                                // <Box width="100%" height={434} sx={{ textAlign: 'center' }}><Typography paddingTop={25}>No pinned items yet.</Typography></Box>
                            }
                        </SortableContext>
                    </div>
                    {activeId && createPortal(<DragOverlay>
                        <Box>
                            <DashboardItem id={activeId}>
                                <DashboardItemCard
                                    item={items?.find((e) => e.id === activeId)!}
                                    dashboard={dashboard!}
                                    onItemRemoved={deleteItem}
                                    onDashboardUpdated={(data: Dashboard) => { setDashboard(data); }}
                                    files={fileStructure}
                                    setOpenItem={setOpenItem}
                                    setDetailsDialogOpen={setDetailsDialogOpen}
                                    setItemToDelete={setItemToDelete}
                                    setDeleteItemConfirmDialogOpen={setDeleteItemConfirmDialogOpen}
                                    externalComments={comments.filter(c => c.documentId === activeId)}
                                    setHighlightedComments={() => {
                                        setHighlightedComments(comments.filter(c => c.documentId === activeId).map(c => c.index || 0));
                                    }}
                                />
                            </DashboardItem>
                        </Box>
                    </DragOverlay>,
                    document.body)}
                </DndContext>
                {scrollLeftArrowVisible &&
                    <Fab
                        size="large"
                        variant="extended"
                        style={{ position: "absolute", width: 48, height: 48, borderRadius: "50%" }}
                        className={classes.fabScrollLeft}
                        onClick={() => {
                            const moveSize = -170 * 5;

                            scrollRef?.current?.scrollTo({ left: scrollRef?.current?.scrollLeft + moveSize, behavior: 'smooth' });
                            setTimeout(adjustScrollArrows, 300);
                        }}
                    >
                        <KeyboardArrowLeftIcon fontSize="large" color="info" />
                    </Fab>
                }
                {scrollRightArrowVisible &&
                    <Fab
                        size="large"
                        variant="extended"
                        style={{ position: "absolute", width: 48, height: 48, borderRadius: "50%" }}
                        className={classes.fabScrollRight}
                        onClick={() => {
                            const moveSize = 170 * 5;

                            scrollRef?.current?.scrollTo({ left: scrollRef?.current?.scrollLeft + moveSize, behavior: 'smooth' });
                            setTimeout(adjustScrollArrows, 300);
                        }}
                    >
                        <KeyboardArrowRightIcon fontSize="large" color="info" />
                    </Fab>
                }
            </Box>
        </Box>
        {openItem && (
            <DashboardItemDetailsDialog
                externalComments={comments.filter(e => e.documentId === openItem.id)}
                dashboard={dashboard!}
                item={openItem}
                itemIndex={items.findIndex(x => x === openItem)}
                itemsCount={items.length}
                isOpen={detailsDialogOpen}
                onSummaryUpdated={updateItem}
                onItemTitleUpdated={updateItem}
                onClose={() => {
                    setDetailsDialogOpen(false);
                    setOpenItem(undefined);
                    setSelectedComment(undefined);
                }}
                files={fileStructure}
                onNext={onNext}
                onBack={onBack}
                preselectedCommentId={selectedComment?.id}
            />
        )}
        {itemToDelete && (
            <ConfirmDialog
                title="Delete capture?"
                content={deleteConfirmationItemMessages[getDashboardItemType(itemToDelete)]}
                open={deleteItemConfirmDialogOpen}
                loading={deleteDashboardItemLoading}
                confirmCallback={deleteItem}
                cancelCallback={() => { setDeleteItemConfirmDialogOpen(false); setItemToDelete(undefined) }}
            />
        )}
    </>);
}

export default DashboardItems;