import LoginIcon from '@mui/icons-material/Login';
import { List, ListItem, Stack, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/images/sidebar-logo-revamped.svg";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        width: 94,
        height: '100vh',
        background: theme.colors.neutral['100'],
    },
    logoContainer: {
        width: 50,
        height: 50,
    },
    logo: {
        marginTop: 10,
        height: 27,
        width: 32,
    },
    tab: {
        display: "flex",
        justifyContent: "center",
        color: theme.colors.neutral["600"],
    },
    selectedTab: {
        position: "relative",
        zIndex: 2,
        "&::after": {
            content: '" "',
            position: "absolute",
            width: "48px",
            height: "48px",
            backgroundColor: theme.colors.primary["600"],
            borderRadius: "8px",
        },
    },
}));

const PublicSidebar: React.FC<{}> = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const defaultActions: { route: string | null, tooltip: string, icon: JSX.Element, handleClick?: any }[] = [
        { route: '/login', tooltip: 'Log In', icon: <LoginIcon />, handleClick: () => history.push('/login')  },
    ];

    const [actions] = useState<{route: string | null, tooltip: string, icon: JSX.Element, handleClick?: any}[]>(defaultActions);

    return (
        <Stack className={classes.rootContainer} alignItems="center" pt={5} pb={2}>
            <Stack className={classes.logoContainer} alignItems="center">
                <img className={classes.logo} src={Logo} alt="Notissia" />
            </Stack>
            <Stack alignItems="center" justifyContent="flex-end" pt={2} height="100%">
                <List component="nav">
                    {actions.map(({ icon, route, tooltip, handleClick }, i) => {
                        const isSelected = (route === "/")
                            ? location.pathname === route
                            : location.pathname.startsWith(route ?? '/?');

                        return (
                            <ListItem component={NavLink}
                                className={classes.tab}
                                activeClassName={classes.selectedTab}
                                isActive={() => location.pathname === '/login'}
                                to={route ?? location.pathname}
                                key={'public-sidebar-72-' + i}>
                                <Tooltip title={tooltip} placement="right"
                                    componentsProps={{ tooltip: { sx: {
                                        fontSize: 16,
                                        height: 51,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 1,
                                    }}}}>
                                    <IconButton onClick={handleClick} sx={{
                                        color: isSelected ? "white" : "inherit",
                                        zIndex: 1,
                                    }}> {icon} </IconButton>
                                </Tooltip>
                            </ListItem>
                        );
                    })}
                </List>
            </Stack>
        </Stack>
    );
}

export default PublicSidebar;
