import React from "react";
import { AnswerQuestionResponseType, NoInfoYet } from "../../../types/search";
import { Text } from '@react-pdf/renderer';
import { DashboardQuery } from "../../../types/files";
import styles from '../dashboard-export/styles';
import TextAnswer from './TextAnswer';
import LinkAnswer from "./LinkAnswer";
import FundingListAnswer from "./FundingListAnswer";
import MetricsAnswer from "./MetricsAnswer";

const ExportQuery: React.FC<{ query: DashboardQuery }> = ({ query }) => {
    const answer = !!query.answer ? JSON.parse(query.answer) : null;
    const type = !!answer ? answer?.type : '';

    switch (type) {
        case AnswerQuestionResponseType.TEXT:
            return (<TextAnswer query={query} />);
        case AnswerQuestionResponseType.URL:
        case AnswerQuestionResponseType.URL_LIST:
            return (<LinkAnswer query={query} />);
        case AnswerQuestionResponseType.FUNDING_LIST:
            return (<FundingListAnswer query={query} />);
        case AnswerQuestionResponseType.METRICS:
            return (<MetricsAnswer query={query} />);
    }

    return (<>
        <Text style={styles.sentence}>{NoInfoYet.answer}</Text>
    </>);
}

export default ExportQuery;
