import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Typography, Stack } from "@mui/material";
import { CompanyFile, DashboardNote } from "../../../types/files";
import { NoteMetric } from "../../../types/search";
import moment, { Moment } from "moment";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";
import DashboardCreationElement from "../../molecules/dashboard-timeline-elements/DashboardCreationElement";
import FoundedElement from "../../molecules/dashboard-timeline-elements/FoundedElement";
import FundingElement from "../../molecules/dashboard-timeline-elements/FundingElement";
import { DashboardContext } from "../../../contexts/DashboardContext";
import MetricsTimeline, { MetricsData } from "../../atoms/MetricsTimeline";
import AddSection from "../../atoms/AddSection";
import { Column } from "../dashboard-queries/KeyMetricsColumnQueries";
import theme from "../../../theme";
import { DollarMetrics, PercentMetrics, RevenueSubcategories } from "../../molecules/dashboard-query-answer/MetricsAnswer";
import AddCustomMetricsModal from "../../modals/dashboard-queries/AddCustomMetricsModal";

export enum DashboardEventType {
    Start = "start",
    Action = "action"
}

export interface DashboardEvent {
    date: Date;
    type: DashboardEventType;
    files: CompanyFile[];
    notes: DashboardNote[];
}

const QueryElements = ['Funding', 'Note Metrics', 'Founded'];

const DashboardMetricsTimeline: React.FC<{}> = () => {
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const { queries } = useContext(DashboardQueriesContext);

    const [withFounded, setWithFounded] = useState<boolean>(false);
    const [dateHeader, setDateHeader] = useState<string[]>([]);
    const [metricsData, setMetricsData] = useState<MetricsData[][]>([]);
    const [timelineElements, setTimelineElements] = useState<ReactNode[]>([]);
    const [addCustomMetricsModalOpen, setAddCustomMetricsModalOpen] = useState<boolean>(false);
    const [customValues, setCustomValues] = useState<MetricsData|undefined>(undefined);
    const [freeStyled, setFreeStyled] = useState<number[]>([]);

    const loadTimelineElements = useCallback((metrics: any[], founded: any, funding: any[], events: DashboardEvent[]) => {
        const keyDateFmt = 'MMM YYYY';
        const timelineItems: { [tlDate: string]: {
            funding?: { funding_type: string, money_raised?: string, investor_list: string, },
            founded?: { year: string, month?: string, },
            events?: { created?: Date },
            metrics?: { metric: string, value: string, date: Moment, units?: string },
        }[] } = {};
        const timelineElements: (ReactNode | undefined)[] = [];
        const header: string[] = ['Metrics'];
        const rows: MetricsData[][] = [];
        let hasFoundedYear = false;

        if (typeof founded === 'number') {
            const year = moment({year: founded}).format('YYYY');

            timelineItems[year] = [];
            timelineItems[year].push({founded: { year }});
            hasFoundedYear = true;
        } else {
            hasFoundedYear = false;
        }

        if (Array.isArray(funding) && !!funding.length) {
            funding.filter((funding: any) => !!funding.announced_date)
                .forEach((funding: any) => {
                    const {announced_date, funding_type, money_raised, investor_list} = funding;
                    const tlDate = moment({
                        year: announced_date.year, month: announced_date.month - 1, day: announced_date.day,
                    }).format(keyDateFmt);
                    const moneyRaised = money_raised ? `US$${(funding.money_raised >= 1e6)
                        ? (funding.money_raised / 1e6).toFixed(1) + 'M': (funding.money_raised >= 1e3)
                            ? (funding.money_raised / 1e3).toFixed(1) + 'K': funding.money_raised.toString()}`
                                .replace('.0', '') : undefined;
                    const investorList = investor_list?.map((investor: any) => investor.name)?.join(", ");

                    if (!timelineItems[tlDate])
                        timelineItems[tlDate] = [];
                    timelineItems[tlDate].push({funding: {funding_type, money_raised: moneyRaised, investor_list: investorList}});
                });
        }

        if (Array.isArray(metrics) && !!metrics.length) {
            metrics.forEach((metricsGroup: NoteMetric) => {
                if (!!metricsGroup && typeof metricsGroup === 'object') {
                    Object.entries(metricsGroup)
                        .filter(([_, metricValues]) => !!metricValues?.date)
                        .forEach(([metric, metricValues]) => {
                            const date = moment(metricValues?.date);
                            const tlDate = date.format(keyDateFmt);
                            let value: string|number|null = metricValues?.value;
                            let units = metricValues?.units ?? undefined;

                            if (value === null) {
                                units = '#';
                                value = 'N/A';
                            } else {
                                let cleanInput = String(value!).replace(/[^0-9bmkt.$%]/gi, '').toLowerCase();
                                let multiplier = 1;

                                if (cleanInput.includes('t'))
                                    multiplier = Math.pow(10, 12);
                                else if (cleanInput.includes('b'))
                                    multiplier = Math.pow(10, 9);
                                else if (cleanInput.includes('m'))
                                    multiplier = Math.pow(10, 6);
                                else if (cleanInput.includes('k'))
                                    multiplier = 1000;

                                const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.]/g, ''));

                                if (!units && DollarMetrics.includes(metric)) {
                                    units = '$';
                                    value = `$${numericValue.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else if (!units && PercentMetrics.includes(metric)) {
                                    units = '%';
                                    value = `${numericValue.toFixed(1)}%`;
                                } else {
                                    if (units === '$')
                                        value = `$${numericValue.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    else if (units === '%')
                                        value = `${numericValue.toFixed(1)}%`;
                                    else
                                        value = `${numericValue}`;
                                }
                            }

                            if (!timelineItems[tlDate])
                                timelineItems[tlDate] = [];
                            if (!timelineItems[tlDate].find(({ metrics }) => metrics?.metric === metric))
                                timelineItems[tlDate].push({ metrics: { metric, value, date, units } });
                        });
                }
            });
        }

        events.forEach((event) => {
            if (event.type === DashboardEventType.Start) {
                const tlDate = moment(event.date).format(keyDateFmt);

                if (!timelineItems[tlDate])
                    timelineItems[tlDate] = [];
                timelineItems[tlDate].push({events: {created: event.date }});
            }
        });

        Object.entries(timelineItems as any)
            .sort((curr: any, next: any) => moment(curr, keyDateFmt).isBefore(moment(next, keyDateFmt)) ? -1 : 1)
            ?.forEach(([key, value], i, self) => {
                const foundedItems = (value as {founded?: { year: string, month?: string }}[])
                    .map((item) => ({...item.founded})).filter((item) => !!Object.keys(item).length);
                const fundingItems = (value as {funding?: { funding_type: string, money_raised?: string, investor_list: string, }}[])
                    .map((item) => ({...item.funding})).filter((item) => !!Object.keys(item).length);
                const metricItems = (value as {metrics: MetricsData}[])
                    .map((item) => ({...item.metrics})).filter((item) => !!Object.keys(item).length);
                const eventItems = (value as {events?: { created: Date, files: CompanyFile[], notes: DashboardNote[] }}[])
                    .map((item) => ({...item.events})).filter((item) => !!Object.keys(item).length);

                if (!!foundedItems.length && self.length > 1) {
                    timelineElements.push(<FoundedElement items={foundedItems} />);
                    return;
                }

                header.push(key);
                metricItems.forEach(metricData => {
                    const lookUpRow = rows.find(row => row[0].metric.includes(metricData.metric as string)) ?? [];

                    if (!!lookUpRow?.length) {
                        Array(header.length - lookUpRow.length).fill({value: '-', date: undefined, units: undefined})
                            .forEach(emptyValues => lookUpRow.push({...metricData, ...emptyValues}));
                        lookUpRow[header.length - 1] = {...metricData};
                    } else {
                        const row: any[] = [];

                        Array(header.length).fill({value: '-', date: undefined, units: undefined})
                            .forEach(emptyValues => row.push({...metricData, ...emptyValues}));
                        row[header.length - 1] = {...metricData};
                        rows.push(row);
                    }
                });
                rows.forEach(row => {
                    Array(header.length - row.length).fill({value: '-', date: undefined, units: undefined})
                        .forEach(emptyValues => row.push({...row[0], ...emptyValues}));
                    header.forEach((tlDate, i) => { row[i].date = moment(tlDate); });
                });

                if (!fundingItems.length && !!metricItems.length && !eventItems.length) {
                    timelineElements.push(undefined);
                } else if (!!fundingItems.length || !!metricItems.length || !!eventItems.length) {
                    if (!!eventItems.length)
                        setFreeStyled(prev => [...prev, i]);
                    timelineElements.push(
                        <Stack direction="column" spacing={1} alignItems="center" key={'timeline-element-' + i}>
                            {!!eventItems.length ? eventItems.map((event, i) => (
                                <DashboardCreationElement item={event} key={'dashboard-metrics-timeline-228-' + i} />
                            )) : (<>
                                <FundingElement items={fundingItems} />
                                <Typography color="#666666" fontStyle="italic" fontSize="0.9rem">
                                    {`${moment(new Date(key)).format(keyDateFmt)}`}
                                </Typography>
                            </>)}
                        </Stack>
                    );
                }
            });

        const mainRevenue = rows.find(row => row[0].metric === 'Revenue');
        const subCategories = rows.filter(row => RevenueSubcategories.map(cat => cat.toLowerCase()).includes(row[0].metric.trim().toLowerCase()));

        if (!!subCategories.length) {
            mainRevenue?.forEach((main, i, self) => {
                subCategories.forEach(cat => {
                    if (cat[i].value === main.value)
                        self[i].value = '-';
                });
            });

            if (!mainRevenue?.some(main => main.value !== '-'))
                rows.splice(rows.findIndex(row => row[0].metric === 'Revenue'), 1);
        }

        setTimelineElements(timelineElements);
        setWithFounded(hasFoundedYear);
        setDateHeader(header);
        setMetricsData(rows);
    }, []);

    useEffect(() => {
        if (!!dashboard && queries.filter((query) => QueryElements.includes(query.title))?.some((query) => !!query.answer)) {
            const queryMap: { [key: string]: any } = queries.reduce((result, query) => {
                if (QueryElements.includes(query.title)) {
                    if (query.title === 'Note Metrics') {
                        const answer = query?.answer ? JSON.parse(query.answer)?.answer : null;
                        const history = query?.history ? JSON.parse(query.history) : [];

                        result[query.title] = [answer, ...history].map((history: any) => history?.answer ?? history) ?? [];
                    } else {
                        result[query.title] = query?.answer ? JSON.parse(query.answer)?.answer : null;
                    }
                }

                return result;
            }, {} as any);

            loadTimelineElements(
                queryMap['Note Metrics'],
                queryMap['Founded'],
                queryMap['Funding'],
                [{
                    date: new Date(dashboard.createdAt),
                    files: [],
                    notes: [],
                    type: DashboardEventType.Start,
                }]
            );
        }
    // eslint-disable-next-line
    }, [dashboard, queries]);

    if (isPublicView)
        return (<></>);

    if (!!timelineElements.length) {
        return (<>
            <Stack alignItems="flex-start" marginLeft={4} marginBottom={2}>
                <Typography fontFamily="Inter" fontWeight="bold" fontSize="1.2rem" color={theme.palette.primary.main}>
                    {'Financial Timeline'}
                </Typography>
            </Stack>
            <MetricsTimeline
                elements={timelineElements}
                visibleItems={6}
                dateHeader={dateHeader}
                metricsData={metricsData}
                unstyledStart={withFounded}
                freeStyled={freeStyled}
                onEdit={(customValues) => {
                    setCustomValues(customValues);
                    setAddCustomMetricsModalOpen(true);
                }} />
            <Stack alignItems="flex-start" marginLeft={8} marginBottom={2} width="100%">
                <AddSection column={Column} label="Add metrics" />
            </Stack>
            {addCustomMetricsModalOpen && (
                <AddCustomMetricsModal
                    isOpen={addCustomMetricsModalOpen}
                    customValues={customValues}
                    onClose={() => {
                        setCustomValues(undefined);
                        setAddCustomMetricsModalOpen(false);
                    }} />
            )}
        </>);
    }

    return (<></>);
};

export default DashboardMetricsTimeline;