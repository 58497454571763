export default class ObjectUtils {
    static equalObjects = (() => {
        const isObject = (object: any) => (object !== null && object !== undefined) && typeof object === 'object';

        const checkEqual = (objectA: any, objectB: any): boolean => {
            if (!isObject(objectA) || !isObject(objectB)) {
                return objectA === objectB;
        }

        const keysA = Object.keys(objectA);
        const keysB = Object.keys(objectB);

        if (keysA.length !== keysB.length)
            return false;

        for (const key of keysA)
            if (!keysB.includes(key) || !checkEqual(objectA[key], objectB[key]))
                return false;

            return true;
        };

        return (objectA: any, objectB: any) => checkEqual(objectA, objectB);
    })();
}