import React, { useState } from "react";
import { Button, Dialog, Stack, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Workspace } from "../../../types/files";
import InvestmentThesis from "../../organisms/workspace/InvestmentThesis";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiPaper-root": {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: '70vw',
            height: '80vh',
            padding: '8px 16px',
            background: 'white',
            borderRadius: 24,
            overflow: 'hidden',
        },
    },
    button: {
        minWidth: 150,
        width: 'fit-content',
        padding: '6px 10px',
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        textTransform: 'none',
        fontSize: '0.85rem',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    AIIcon: {
        height: 20,
        width: 20,
        color: theme.palette.primary.main,
    },
}));

const InvestmentThesisModal: React.FC<{ workspace: Workspace }> = ({ workspace }) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState<boolean>(false);

    return (<>
        <Stack component={Button} className={classes.button} variant="outlined"
            direction="row" spacing={1} alignItems="center" justifyContent="flex-end"
            onClick={() => setOpenModal(true)}>
            <AutoAwesomeIcon className={classes.AIIcon} />
            <span>{'View companies'}</span>
        </Stack>
        {openModal && (
            <Dialog className={classes.dialog} open={openModal} onClose={() => setOpenModal(false)}>
                <InvestmentThesis
                    workspace={workspace}
                    collectionKey={`selected-companies:${workspace.id}`}
                    onClose={() => setOpenModal(false)}
                    modal />
            </Dialog>
        )}
    </>);
};

export default InvestmentThesisModal;