import { memo } from "react";
import BulkShareButton from "./BulkShareButton";
import BulkSharePanel from "./BulkSharePanel";
import BulkShareAssignCards from "./BulkShareAssignCards";
import ShareSelect from "../../molecules/home-bulk-share-deals/ShareSelect";
import ShareCompanies from "../../molecules/home-bulk-share-deals/ShareCompanies";
import ShareInvestors from "../../molecules/home-bulk-share-deals/ShareInvestors";
import ShareStepper from "../../molecules/home-bulk-share-deals/ShareStepper";
import ShareEachCompany from "../../molecules/home-bulk-share-deals/ShareEachCompany";
import ShareEachInvestor from "../../molecules/home-bulk-share-deals/ShareEachInvestor";
import ShareConfirmAll from "../../molecules/home-bulk-share-deals/ShareConfirmAll";
import ShareMailingDone from "../../molecules/home-bulk-share-deals/ShareMailingDone";

export enum BulkShareCuratedTabs {
    Selection = 'selection',
    Companies = 'companies',
    Investors = 'investors',
    EachStepper = 'stepper',
    ConfirmAll = 'confirm',
    Done = 'done',
    Close = '{close}',
}

export const BulkShareDeals = {
    Button: BulkShareButton,
    Panel: BulkSharePanel,
    AssignCards: BulkShareAssignCards,
    Select: memo(ShareSelect),
    Companies: memo(ShareCompanies),
    Investors: memo(ShareInvestors),
    Stepper: memo(ShareStepper),
    EachCompany: memo(ShareEachCompany),
    EachInvestor: memo(ShareEachInvestor),
    ConfirmAll: memo(ShareConfirmAll),
    Done: memo(ShareMailingDone),
};

export default BulkShareDeals;
