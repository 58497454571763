import React from "react";
import { Text, View, Image, Link } from '@react-pdf/renderer';
import styles from "./styles";

const PDFFooter: React.FC<{}> = () => {
    return (<>
        <View style={styles.footer} fixed>
            <View style={styles.bottomDivider} />
            <Link src={'https://notissia.com'}>
                <Image style={styles.footerLogo} src={'/images/notissia-pdf-logo.png'} />
            </Link>
            <View style={styles.footerSub}>
                <Text style={styles.footerText}>
                    {'Automatically organize all your deal flow data.'}
                </Text>
                <View style={styles.footerRight}>
                    <Text style={styles.footerSignup}>
                        {'Sign up for early access at '}
                    </Text>
                    <Link style={styles.footerText} src={'https://notissia.com'}>
                        {'notissia.com'}
                    </Link>
                </View>
            </View>
        </View>
    </>);
}

export default PDFFooter;