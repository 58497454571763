import { Stack, Tooltip, Typography, Box, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useContext, useState, useCallback, useEffect } from "react";
import {DashboardContext} from "../../contexts/DashboardContext";
import useDashboard from "../../hooks/useDashboard";
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => ({
    switchButton: {
        width: '80px',
        height: '36px',
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: '6px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(40px)',
                '& .MuiSwitch-thumb': {
                    backgroundColor: '#048290',
                },
                '& + .MuiSwitch-track': {
                    border: '2px solid #048290',
                    backgroundColor: '#fff',
                    opacity: 1,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 24,
            height: 24,
            backgroundColor: theme.colors.primary['200'],
        },
        '& .MuiSwitch-track': {
            borderRadius: '20px',
            border: `2px solid ${theme.colors.primary['200']}`,
            backgroundColor: '#fff',
            opacity: 1,
            '&:before, &:after': {
                position: 'absolute',
                fontFamily: 'Inter',
                fontSize: '0.7rem',
                fontWeight: 600,
            },
            '&:before': {
                color: '#048290',
                content: '"ON"',
                padding: '9px',
            },
            '&:after': {
                color: theme.colors.primary['200'],
                content: '"OFF"',
                padding: '8px 46px',
            },
        },
    },
}));

const ZapierSync: React.FC<{
    hidden?: boolean,
    flags?: any
}> = ({ hidden, flags }) => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);
    const { autoSync } = useDashboard();
    const [syncExternally, setSyncExternally] = useState<boolean>(false);

    const handleSyncExternal = useCallback((checked: boolean) => {
        autoSync(checked).then(() => setSyncExternally(checked));
    }, [autoSync]);

    useEffect(() => {
        if (flags.zapierPlan === false) {
            if (dashboard?.shouldSyncExternally === true)
                handleSyncExternal(false);
        } else if (dashboard) {
            setSyncExternally(!!dashboard?.shouldSyncExternally);
        }
        // eslint-disable-next-line
    }, [flags.zapierPlan, dashboard]);

    if (hidden || !dashboard)
        return (<></>);

    if (!flags.zapierPlan)
        return (<></>);

    return (<>
        <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip placement="top-start" title={
                <Typography component="span" fontSize="0.8rem">
                    {`You can sync this data to any connected app through `}
                    <Typography
                        component="a"
                        href="https://zapier.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        display="inline"
                        sx={{
                            color: '#7bd4d4',
                            fontSize: '0.8rem',
                            textDecoration: 'none',
                            "&:hover": {
                                textDecoration: 'none !important',
                            }
                        }}
                    >Zapier</Typography>.
                    <Box m="12px" />
                    {` Learn more about getting set up with Zapier `}
                    <Typography
                        component="a"
                        href="/integrations"
                        target="_blank"
                        rel="noopener noreferrer"
                        display="inline"
                        sx={{
                            color: '#7bd4d4',
                            fontSize: '0.8rem',
                            textDecoration: 'none',
                            "&:hover": {
                                textDecoration: 'none !important',
                            }
                        }}
                    >here</Typography>.
                </Typography>}
                componentsProps={{tooltip:{sx:{
                    display: 'flex', alignItems: 'center', borderRadius: 'unset', transform: 'translate(-90px) !important',
                    backgroundColor: 'rgba(97, 97, 97)', width: '210px', height: '110px',  padding: 2,
                }}}}>
                <Stack direction="row" spacing="4px">
                    <ErrorIcon sx={{ height: '20px', width: '20px', transform: 'rotate(180deg)', fill: '#7bd4d4' }} />
                    <Typography sx={{
                        color: "#7bd4d4", fontWeight: 600, fontSize: '0.85rem', fontFamily: 'Inter',
                    }}> Sync </Typography>
                </Stack>
            </Tooltip>
            <Switch className={classes.switchButton} checked={syncExternally}
                onChange={(e) => handleSyncExternal(e.target.checked)}
                disableRipple/>
        </Stack>
    </>);
}

export default withLDConsumer()(ZapierSync);