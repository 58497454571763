import { useCallback, useContext } from 'react';
import { DashboardContext } from '../contexts/DashboardContext';
import { updateDashboardFunc } from '../lib/helper';
import {GroupSettingsContext} from '../contexts/GroupSettingsContext';
import {DashboardsContext} from '../contexts/DashboardsContext';
import { Dashboard, RefreshDataEnum } from '../types/files';
import ObjectUtils from '../utils/ObjectUtils';

const useDashboard = () => {
  const { dashboards } = useContext(DashboardsContext);
  const { dashboard } = useContext(DashboardContext);
  const { externalSync } = useContext(GroupSettingsContext);

  const updateTitle = useCallback((value: string): boolean => {
    if (!dashboard)
      return false;

    if (dashboard.title === value)
      return true;

    if (dashboards.some(dashboard => dashboard.title === value))
      return false;

    dashboard.title = value;
    dashboard.refreshData = {
      lastUpdatedBy: 'user',
      shouldRefresh: true,
      modifiedData: RefreshDataEnum.Title,
    };

    updateDashboardFunc(dashboard).then(() => {
      if (!!dashboards.length) {
        const found = dashboards.find(d => d.id === dashboard.id);
        if (!!found) {
          found.title = value;
        }
      }
    });

    return true;
  }, [dashboard, dashboards]);

  const updateStage = useCallback((value: string): boolean => {
    if (!dashboard)
      return false;

    if (dashboard.investmentStage === value)
      return true;

    dashboard.investmentStage = value;

    updateDashboardFunc(dashboard).then(() => {
      if (!!dashboards.length) {
        const found = dashboards.find(d => d.id === dashboard.id);
        if (!!found) {
          found.investmentStage = value;
        }
      }
    });

    return true;
  }, [dashboard, dashboards]);

  const updateTags = useCallback((value: string[]): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      if (!dashboard) {
        resolve(false);
        return;
      }

      if (ObjectUtils.equalObjects(dashboard.tags, value)) {
        resolve(true);
        return;
      }

      dashboard.tags = value;

      updateDashboardFunc(dashboard).then(() => {
        if (!!dashboards.length) {
          const found = dashboards.find(d => d.id === dashboard.id);

          if (!!found) {
            found.tags = value;
          }
        }

        resolve(true);
      });
    });
  }, [dashboard, dashboards]);

  const sharePublicly = useCallback(async (sharing: boolean, localDashboard?: Dashboard) => {
    const updatedDashboard = {
        ...(localDashboard ?? dashboard)!,
        isPublic: sharing,
        refreshData: {
            lastUpdatedBy: "",
            shouldRefresh: true,
            modifiedData: RefreshDataEnum.Public,
        }
    };

    await updateDashboardFunc(updatedDashboard);
  }, [dashboard]);

  const autoSync = useCallback(async (sync?: boolean) => {
    if (!dashboard)
      return;

    const newDashboard = {
        ...dashboard,
        shouldSyncExternally: sync ?? externalSync,
    };

    await updateDashboardFunc(newDashboard);
  }, [externalSync, dashboard]);

  return { updateTitle, updateStage, updateTags, sharePublicly, autoSync };
};

export default useDashboard;
