import { Box, Grid, IconButton, Typography, } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import LanguageIcon from '@mui/icons-material/Language';
import { CompanyFile, Dashboard, DashboardDocument, DashboardScreenshot, DashboardSelection, DashboardUid } from "../../../types/files";
import { SaveDashboardItemType } from "../../../types/common";
import DashboardFileComponent from "../dashboard-item-types/DashboardFileComponent";
import DashboardScreenshotComponent from "../dashboard-item-types/DashboardScreenshotComponent";
import DashboardSelectionComponent from "../dashboard-item-types/DashboardSelectionComponent";
import { getFolderFileIcon } from "../../folders/utils/files";
import { useHistory } from "react-router-dom";
import { ExtendedComment } from "../../../types/comments";
import CommentAvatars from "../../atoms/CommentAvatars";
import { getFileIcon } from "../../folders/utils/files";
import { isTitleDefault } from "../../../shared/dashboard";

const useStyles = makeStyles((theme) => ({
    card: {
        width: 640,
        height: 480,
    },
    cardMobile: {
        height: "100%",
        width: "calc(100vw - 20px)",
        display: "flex",
        alignItems: "center",
        padding: 10,
        borderRadius: 20,
        boxShadow: theme.shadows[3],
        cursor: 'pointer',
        "&:hover": {
            boxShadow: theme.shadows[8],
            borderRadius: 20
        }
    },
    headerRow: {
        height: 40,
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    cardContent: {
        height: '100%',
    },
    title: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: 0,
        maxWidth: "calc(100% - 40px)",
        color: 'black',
        fontSize: 16,
        fontWeight: 700,
        marginLeft: 20,
        marginTop: 2
    },
    sourceElement: {
        display: "-webkit-box",
        height: "100%",
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: 0,
        maxWidth: "calc(100% - 55px)",
        wordBreak: 'break-word',
        "&:hover": {
            textDecoration: "underline"
        },
        fontSize: 14,
        fontWeight: 600,
        color: 'black'
    },
    fileIcon: {
        marginRight: 10,
        marginLeft: 10,
    },
    summaryValue: {
        display: '-webkit-box',
        boxOrient: 'vertical',
        overflowWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 2,
    }
}));

const DashboardItemCard: React.FC<{
    readOnly?: boolean,
    dashboard: Dashboard,
    item: DashboardDocument | DashboardScreenshot | DashboardSelection,
    onItemRemoved?: (id: string) => void,
    onDashboardUpdated: (data: Dashboard) => void,
    files: CompanyFile[],
    setOpenItem: (item: DashboardDocument | DashboardScreenshot | DashboardSelection | undefined) => void,
    setDetailsDialogOpen: (item: boolean) => void,
    setDeleteItemConfirmDialogOpen?: (item: boolean) => void,
    setItemToDelete?: (item: DashboardDocument | DashboardScreenshot | DashboardSelection | undefined) => void,
    externalComments: ExtendedComment[],
    setHighlightedComments: ()=>void
}> = ({ externalComments, readOnly, item, dashboard, setDeleteItemConfirmDialogOpen, setItemToDelete, files, setOpenItem, setDetailsDialogOpen, setHighlightedComments }) => {
    const classes = useStyles();
    const {
        attributes,
        listeners,
    } = useSortable({ id: item.id });
    const history = useHistory();
    const [comments, setComments] = useState<ExtendedComment[]>([]);
    useEffect(() => {
        setComments(externalComments);
    }, [externalComments]);

    const getDashboardItemComponent = (item: DashboardDocument | DashboardScreenshot | DashboardSelection) => {
        let itemComponent: any;
        if ("searchTerms" in item) {
            itemComponent = <DashboardFileComponent
                dashboardId={dashboard.id}
                dashboardItemType={SaveDashboardItemType.Page}
                data={item as DashboardDocument}
                comments={[]}
                selectedCommentId={''}
                selectedCommentParentId={''}
                onCommentAdded={() => { }}
            />;
        } else if ("selectedText" in item) {
            itemComponent = <DashboardSelectionComponent
                dashboardId={dashboard.id}
                dashboardItemType={SaveDashboardItemType.Text}
                data={item as DashboardSelection}
                comments={[]}
                selectedCommentId={''}
                selectedCommentParentId={''}
                onCommentAdded={() => { }}
                defaultHeight="100%"
                enableComments={false}
            />;
        } else {
            itemComponent = <DashboardScreenshotComponent
                dashboardItem={{
                    id: dashboard.id,
                    dashboardItemId: item.id,
                    dashboardItemType: SaveDashboardItemType.ScreenCapture,
                } as DashboardUid}
                data={item as DashboardSelection}
                isSelected={false}
                comments={[]}
                selectedCommentId={''}
                onCommentAdded={() => { }}
                adjustSizes={true}
            />
        }

        return <div style={{ height: "100%", overflow: "auto" }}>
            {itemComponent}
        </div>;
    }

    const getSourceElement = () => {
        if (item.key) {
            const keyFileName = item.key.split('/').slice(-1)[0];
            const document = files.find(e => e.key === item.key);
            return (<Box
                sx={{ display: 'flex', width: '100%', alignItems: "center" }}
                onClick={(e) => {
                    if (document) {
                        e.preventDefault();
                        e.stopPropagation();
                        onSourceClick();
                    }
                }}>
                {document ? getFolderFileIcon(document, classes.fileIcon, classes.fileIcon) : getFileIcon(keyFileName, classes.fileIcon)}
                {document ? <Typography className={classes.sourceElement}>{document?.name}</Typography> : <Typography className={classes.sourceElement}>{keyFileName}</Typography>}
            </Box>);
        } else {
            return <Box
                sx={{ display: 'flex', width: '100%', alignItems: "center" }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSourceClick();
                }}>
                <LanguageIcon className={classes.fileIcon} />
                <Typography className={classes.sourceElement}>{(item as DashboardScreenshot).url}</Typography>
            </Box>;
        }
    }

    const onSourceClick = () => {
        if (!item) {
            return;
        }

        const pageNumber = ((item as any)?.page || (item as any)?.page === 0) ?
            (item as any)?.page :
            (item as any)?.pages && (item as any)?.pages[0];

        const documentId = item.key;
        const url = (item as DashboardScreenshot).url;

        if (documentId && (pageNumber || pageNumber === 0)) {
            history.push(`/folders?documentId=${encodeURIComponent(documentId)}&page=${pageNumber}`);
        } else if (url) {
            window.open(url);
        }
    }

    const { title } = item;
    const titleIsDefault = isTitleDefault(title);
    const displayedTitle = titleIsDefault ? 'Add a title...' : item?.title;

    return (
        <div
            className={ classes.card }
            data-scrollid={item.id}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenItem(item);
                setDetailsDialogOpen(true);
            }}>
            <div className={classes.cardContent}>
                <div className={classes.headerRow}>
                    {<div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        {!readOnly && (<IconButton {...attributes} {...listeners} style={{color: '#AEB5BC'}}>
                            <DragIndicatorIcon />
                        </IconButton>)}
                        <Typography className={classes.title} variant="h6" style={titleIsDefault ? {color: 'lightgray' } : {}}>{readOnly && titleIsDefault ? '' : displayedTitle}</Typography>
                    </div>}
                    {!readOnly && <Grid item width={40}>
                        <IconButton onClick={(e) => {
                            if (setItemToDelete && setDeleteItemConfirmDialogOpen) {
                                setItemToDelete(item);
                                setDeleteItemConfirmDialogOpen(true);
                            }
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            <TrashIcon />
                        </IconButton>
                    </Grid>}
                </div>
                <div style={{minHeight: 208, height: 'calc(100% - 110px)'}}>
                    {getDashboardItemComponent(item)}
                </div>
                <div style={{width: `calc(100% - ${readOnly ? 0 : 100}px)`}}>
                    {getSourceElement()}
                </div>
                <CommentAvatars comments={comments} />
            </div>
        </div>
    );
};

export default DashboardItemCard;
