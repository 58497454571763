import React from "react";
import { Page, Document } from '@react-pdf/renderer';
import PDFHeader from "./PDFHeader";
import PDFBody from "./PDFBody";
import PDFFooter from "./PDFFooter";
import styles from "./styles";
import moment from "moment";
import { DashboardQueriesType } from "../../../types/files";

const ExportDocument: React.FC<{
    bulkDashboards: DashboardQueriesType[],
    author: string,
}> = ({ bulkDashboards, author }) => {
    const subject = 'Notissia: Automatically organize all your deal fow data.';
    const keywords = bulkDashboards.map(exportable => exportable.dashboard.title).join(', ');

    if (bulkDashboards.length > 1) {
        const documentName = `Notissia Dashboard Export ${moment().format('(M-D-YYYY)')}`;

        return (<>
            <Document
                title={documentName}
                author={author}
                subject={subject}
                keywords={keywords}
                producer="Notissia"
                language="en-US"
                pageMode ="useThumbs"
                pdfVersion="1.7"
                pageLayout="singlePage">
                {bulkDashboards.map((exportable, i) => (
                    <Page
                        size="A4"
                        orientation="portrait"
                        style={styles.page}
                        key={'export-document-37' + i}>
                        <PDFHeader dashboard={exportable.dashboard} />
                        <PDFBody queries={exportable.queries} />
                        <PDFFooter />
                    </Page>
                ))}
            </Document>
        </>);
    } else if (bulkDashboards.length === 1) {
        const {dashboard, queries} = bulkDashboards[0];

        return (<>
            <Document
                title={dashboard.title}
                author={author}
                subject={subject}
                keywords={keywords}
                producer="Notissia"
                language="en-US"
                pageMode ="useThumbs"
                pdfVersion="1.7"
                pageLayout="singlePage">
                <Page
                    size="A4"
                    orientation="portrait"
                    style={styles.page}>
                    <PDFHeader dashboard={dashboard} />
                    <PDFBody queries={queries} />
                    <PDFFooter />
                </Page>
            </Document>
        </>);
    }

    return (<>
        <Document />
    </>);
}

export default ExportDocument;