import React, { useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import WorkspacesBulkShareEmailModal from "../../modals/investors/WorkspacesBulkShareEmailModal";
import { collectionKey } from "../../pages/InvestorsPage";

const useStyles = makeStyles(() => ({
    commonButton: {
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
    icon: {
        width: 20,
        height: 20,
    }
}));

const WorkspacesBulkShareEmail: React.FC<{ showAlways?: boolean }> = ({ showAlways }) => {
    const classes = useStyles();
    const {loading, getBulkWorkspaces} = useBulkWorkspaces();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const noBulkAction = useMemo(() => !getBulkWorkspaces(collectionKey).length, [getBulkWorkspaces]);

    return (<>{
        (!noBulkAction || showAlways) && (
            <Tooltip placement="top"
                title={<Typography fontSize="0.85rem">{'Share workspace with selected investors'}</Typography>}
                componentsProps={{tooltip:{sx:{
                    paddingLeft: 2, paddingRight: 2,
                    backgroundColor: 'rgba(97, 97, 97)',
                }}}}>
                <LoadingButton variant="contained"
                    className={classes.commonButton}
                    startIcon={
                        <ShareIcon className={classes.icon}
                            style={{ stroke: noBulkAction? 'darkgray' : 'white' }}/>
                    }
                    disabled={noBulkAction || loading}
                    onClick={() => setModalOpen(true)}
                    sx={{ width: 190 }}>{'Share with selected'}</LoadingButton>
            </Tooltip>
        )}
        {modalOpen && (
            <WorkspacesBulkShareEmailModal
                isOpen={modalOpen}
                collectionKey={collectionKey}
                onClose={() => setModalOpen(false)} />
        )}
    </>);
}

export default WorkspacesBulkShareEmail;