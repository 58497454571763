import { useCallback, useContext } from 'react';
import { updateWorkspaceFunc } from '../lib/helper';
import {GroupSettingsContext} from '../contexts/GroupSettingsContext';
import {DashboardsContext} from '../contexts/DashboardsContext';
import { Workspace } from '../types/files';
import useBackwardsCompatible from './useBackwardsCompatible';

const useWorkspace = () => {
  const { setWorkspaces } = useContext(GroupSettingsContext);
  const { workspace, setWorkspace, setDashboards } = useContext(DashboardsContext);
  const { dashboardWorkspaces } = useBackwardsCompatible();

  const sharePublicly = useCallback(async (sharing: boolean, localWorkspace?: Workspace) => {
    const updatedWorkspace = { ...(localWorkspace ?? workspace)!, isPublic: sharing };

    return new Promise((resolve, reject) => {
      updateWorkspaceFunc(updatedWorkspace)
        .then((updatedWorkspace?: Workspace) => {
          if (!!updatedWorkspace?.id) {
            setWorkspaces(prev => prev.map(ws =>
              ws.id === updatedWorkspace?.id ? {...updatedWorkspace} : ws
            ));
            if (!sharing) {
              setDashboards(prev => prev.map(dashboard => ({...dashboard,
                isPublic: dashboardWorkspaces(dashboard)?.includes(updatedWorkspace?.id) || dashboard.isPublic,
              })));
            }
            setWorkspace(updatedWorkspace);
            resolve(updatedWorkspace);
          } else {
            resolve(null);
          }
      }).catch(() => reject(null));
    });
  // eslint-disable-next-line
  }, [workspace]);

  return { sharePublicly };
};

export default useWorkspace;
