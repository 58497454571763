import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { CompanyFile } from '../../../types/files';
import Modal from '../../../components/templates/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'relative',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  deleteButton: {
    color: theme.palette.common.white,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  button: {
    color: theme.palette.text.primary,
    '&:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  folderName: {
    fontWeight: 'bold',
  },
}));

interface Props {
  fileToDelete: CompanyFile;
  onClose?: () => void;
  onConfirm?: (file: CompanyFile) => Promise<void>;
}

const FileDeletionModal = ({ fileToDelete, onClose, onConfirm }: Props) => {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const deleteFile = async () => {
    setLoading(true);
    if (onConfirm) {
      await onConfirm(fileToDelete);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal className={classes.modal} open onClose={onClose}>
      <>
        {onClose && <IconButton className={classes.closeIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>}
        <Typography align="center" paragraph>
          Are you sure you want to delete{' '}
          <span className={classes.folderName}>{fileToDelete.name}</span>
          {fileToDelete.isDirectory && ' and all the files and folders in it'}?
        </Typography>
        <Box mt={1} mx="auto" textAlign="center">
          {loading ? (
            <CircularProgress size={32} />
          ) : (
            <>
              <Button
                className={classes.deleteButton}
                variant="contained"
                onClick={deleteFile}
              >
                Delete
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                onClick={onClose}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>
      </>
    </Modal>
  );
};

export default FileDeletionModal;
