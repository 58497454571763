import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {AnsweredQuestion, DashboardQueriesContext, DashboardQueryAnswer} from '../contexts/DashboardQueriesContext';
import ObjectUtils from '../utils/ObjectUtils';
import { AnswerQuestionResponse } from '../types/search';
import { DashboardQuery } from '../types/files';

const useDashboardQuery = (queryTitle: string) => {
  const { queries, defaultQueryTemplateValues, modifyQuery, removeQuery } = useContext(DashboardQueriesContext);
  const [queryAnswer, setQueryAnswer] = useState<DashboardQueryAnswer>({
    answeredQuestion: { question: queryTitle } as AnsweredQuestion,
  } as DashboardQueryAnswer);
  const queryRef = useRef<DashboardQuery|null>(queryAnswer);

  const updateQuery = useCallback((answer: AnswerQuestionResponse) => {
    modifyQuery(queryAnswer, answer);
    setQueryAnswer(prev => ({...prev,
      answeredQuestion: {
        ...prev.answeredQuestion,
        ...answer,
      } as AnsweredQuestion,
    }));
  }, [modifyQuery, queryAnswer]);

  useEffect(() => {
    const lookUpQuery = queries.find(query => query.title === queryTitle);
    const queryDefaults = defaultQueryTemplateValues.find(defQuery => defQuery?.title === queryTitle);

    if (!!lookUpQuery) {
      if (!ObjectUtils.equalObjects(lookUpQuery, queryRef.current)) {
        const answer = !!lookUpQuery.answer ? JSON.parse(lookUpQuery.answer) : null;
        const history = !!lookUpQuery.history ? JSON.parse(lookUpQuery.history) : null;

        setQueryAnswer(prev => {
          queryRef.current = prev as DashboardQuery;

          return {
            ...lookUpQuery,
            answeredQuestion: {
              ...answer,
              history: history,
              question: queryDefaults?.displayTitle || queryTitle,
              time: new Date(lookUpQuery.updatedAt),
            },
          } as DashboardQueryAnswer;
        });
      }
    } else if (!queryRef?.current?.deletedAt) {
      queryRef.current = {
        ...queryRef.current!,
        title: queryTitle,
        query: queryDefaults?.queryTemplate || '{dashboard_name}',
        order: queryDefaults!.order,
        answeredQuestion: {
          question: queryDefaults?.displayTitle || queryTitle,
        },
      } as DashboardQueryAnswer;
      setQueryAnswer(prev => ({...prev, ...queryRef.current}));
    }
  }, [queries, defaultQueryTemplateValues, queryTitle]);

  return { queryAnswer, updateQuery, removeQuery };
};

export default useDashboardQuery;
