import React, { createContext, ReactNode, useMemo, useState } from 'react';
import { DashboardQueriesType, Workspace } from '../types/files';

type BulkActionsContextProps = {
  loading: boolean;
  bulkDashboardsMap: Map<string, DashboardQueriesType[]>;
  bulkWorkspacesMap: Map<string, Workspace[]>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setBulkDashboardsMap: React.Dispatch<React.SetStateAction<Map<string, DashboardQueriesType[]>>>;
  setBulkWorkspacesMap: React.Dispatch<React.SetStateAction<Map<string, Workspace[]>>>;
};

export const BulkActionsContext = createContext<BulkActionsContextProps>({
  loading: false,
  bulkDashboardsMap: new Map([]),
  bulkWorkspacesMap: new Map([]),
  setLoading: () => {},
  setBulkDashboardsMap: () => {},
  setBulkWorkspacesMap: () => {},
});

const BulkActionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [bulkDashboardsMap, setBulkDashboardsMap] = useState<Map<string, DashboardQueriesType[]>>(new Map([]));
  const [bulkWorkspacesMap, setBulkWorkspacesMap] = useState<Map<string, Workspace[]>>(new Map([]));
  const [loading, setLoading] = useState<boolean>(false);

  const contextValue = useMemo(() => ({
    loading,
    bulkDashboardsMap,
    bulkWorkspacesMap,
    setLoading,
    setBulkDashboardsMap,
    setBulkWorkspacesMap,
  }), [
    loading,
    bulkDashboardsMap,
    bulkWorkspacesMap,
    setLoading,
    setBulkDashboardsMap,
    setBulkWorkspacesMap,
  ]);

  return (
    <BulkActionsContext.Provider value={contextValue}>
      {children}
    </BulkActionsContext.Provider>
  );
};

export default BulkActionsProvider;
