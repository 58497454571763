import pathModule, { sep } from 'path';
import { CompanyFile, S3File } from '../types/files';
import { SearchDocumentType } from '../types/search';

const getDirs = (path: string): [string, string[]] => {
  const dir = pathModule.dirname(path)
  const splitDir = dir.split(sep)
  const dirToReturn = splitDir.length > 0 ? splitDir.filter(d => d !== "") : []
  return [dir, dirToReturn]
}
const getDirectoryAtIndex = (index: number, v: string[]) => {
  return v.slice(0, index + 1).join(sep);
}
//companyFiles is a direct return from
//the graphql schema.
export const getFoldersFromFiles = (companyFiles: S3File[]) => {
  const folderStructure = companyFiles.reduce((aggr: { [key: string]: CompanyFile }, companyFile) => {
    const { displayPath, key, ...rest } = companyFile;
    const [dir, eachDir] = getDirs(displayPath);

    const fileCreatedAt = (companyFile as any)?.createdAt ? (companyFile as any)?.createdAt : undefined;
    const fileUpdatedAt = (companyFile as any)?.updatedAt ? (companyFile as any)?.createdAt : undefined;

    const [hashDir, eachHashDir] = getDirs(key);
    for (let i = 0; i < eachDir.length; ++i) {
      const localDir = getDirectoryAtIndex(i, eachDir);
      const localHashDir = getDirectoryAtIndex(i + 2, eachHashDir); //i+2 since have to get passed the "group" and "public"

      if (!aggr[localDir]) {
        aggr[localDir] = {
          isDirectory: true,
          path: localDir,
          key: localHashDir, //includes group
          name: eachDir[i],
          id: localHashDir,
          parentId: i > 0 ? aggr[getDirectoryAtIndex(i - 1, eachDir)].id : "root",
          documentCategory: "",
          createdAt: fileCreatedAt,
          updatedAt: fileUpdatedAt
        };
      } else {
        if (fileCreatedAt) {
          if (aggr[localDir].createdAt) {
            if (fileCreatedAt < aggr[localDir].createdAt!) {
              aggr[localDir].createdAt = fileCreatedAt;
            }
          } else {
            aggr[localDir].createdAt = fileCreatedAt;
          }
        }

        if (fileUpdatedAt) {
          if (aggr[localDir].updatedAt) {
            if (fileUpdatedAt > aggr[localDir].updatedAt!) {
              aggr[localDir].updatedAt = fileUpdatedAt;
            }
          } else {
            aggr[localDir].updatedAt = fileUpdatedAt;
          }
        }
      }
    }
    aggr[displayPath] = { parentId: dir === sep ? "root" : hashDir, ...rest, documentCategory: (rest as any).documentCategory || "Other", key, path: displayPath, isDirectory: false };
    return aggr;
  }, {});
  return Object.values(folderStructure);
}

export interface SplitFolders {
  files: CompanyFile[]
  folders: CompanyFile[]
}
export interface UniqueFolders {
  [key: string]: CompanyFile
}

export const getUniqueFolder = (folders: CompanyFile[]) => {
  return Object.values(folders.reduce<UniqueFolders>((agg, curr) => {
    if (!agg[curr.key]) {
      agg[curr.key] = curr
    }
    return agg
  }, {}))
}

export const splitFolders = (fileStructure: CompanyFile[]) => {
  const { files, folders } = fileStructure.reduce<SplitFolders>(({ files, folders }, companyFile) => {
    if (companyFile.isDirectory) {
      return {
        files,
        folders: [...folders, companyFile]
      }
    }
    else {
      return {
        folders,
        files: [...files, companyFile]
      }
    }
  }, { files: [], folders: [] })
  return [files, getUniqueFolder(folders)]
}

export const getSearchFileKeys = (
  searchFile: CompanyFile | null | undefined,
  searchFolder: CompanyFile | null | undefined,
  fileStructure: CompanyFile[],
  includeExcels: boolean
) => {
  let allFiles: CompanyFile[] = [];
  if (searchFile) {
    allFiles = [searchFile];
  }
  else if (searchFolder) {
    allFiles = fileStructure.filter(folder => !folder.isDirectory && folder.key.startsWith(searchFolder.key));
  }
  else {
    allFiles = fileStructure.filter(folder => !folder.isDirectory);
  }
  if (!includeExcels) {
    allFiles = allFiles.filter(x => !x.path.toLowerCase().endsWith(".xlsx") && !x.path.toLowerCase().endsWith(".xls"));
  }
  return allFiles.map(f => f.key);
}

export const getSearchFileKeysForMultiple = (
  searchFiles: CompanyFile[],
  searchFolders: CompanyFile[],
  fileStructure: CompanyFile[],
  types: SearchDocumentType[] | null | undefined
) => {
  let allFiles: CompanyFile[] = [];
  if (searchFiles && searchFiles.length) {
    allFiles = [...searchFiles];
  }
  if (searchFolders && searchFolders.length) {
    const files = searchFolders.map(searchFolder => {
      return fileStructure.filter(folder => !folder.isDirectory && folder.key.startsWith(searchFolder.key));
    });

    allFiles = [...allFiles, ...files.flat()];
  }

  if (!allFiles.length) {
    allFiles = fileStructure.filter(folder => !folder.isDirectory);
  }

  if (types?.length) {
    const typeExtensions = {
      [SearchDocumentType.Pdf]: ["pdf"],
      [SearchDocumentType.Excel]: ["xlsx", "xls"],
      [SearchDocumentType.Word]: ["doc", "docx"],
      [SearchDocumentType.PowerPoint]: ["ppt", "pptx"],
      [SearchDocumentType.Presentation]: undefined,
      [SearchDocumentType.Legal]: undefined,
      [SearchDocumentType.Financial]: undefined,
      [SearchDocumentType.Other]: undefined,
    };
    const filterExtensions: string[][] = types.map(t => typeExtensions[t] || []).filter(t => t.length > 0)
    if (filterExtensions.length > 0) {
      allFiles = allFiles.filter(x => {
        return filterExtensions.find(ext => ext.find(e => x.path.toLowerCase().endsWith(e)));
      });
    }
  } else {
    const excludedExtensions = ["xlsx", "xls"];
    allFiles = allFiles.filter(x => {
      return excludedExtensions.every(ext => !x.path.toLowerCase().endsWith(ext));
    });
  }

  const uniqueFiles = new Set(allFiles.map(f => f.key));
  return Array.from(uniqueFiles);
}

export const getFolderAncestors = (folderPath: string | undefined) => {
  if (!folderPath) {
    return [];
  }
  const result = [];
  const elements = folderPath.split("/");

  for (let i = 0; i < elements.length; i++) {
    const newElements = elements.slice(0, elements.length - i - 1);
    result.push(newElements.join("/"));
  }

  return result;
}

export const getRootFolder = (path: string) => {
  if (!path) {
    return "";
  }

  const elements = path.split("/");

  return elements?.length ? elements[0] : "";
}

export const getSearchTermsPhraseDisplay = (phrase: string[]) => {
  return phrase.map(term => {
    if (["[or]", "[and]"].includes(term.toLowerCase())) {
      return term.replace("[", "").replace("]", "").toUpperCase();
    }

    return term;
  }).join(" ");
}

export const getFileDisplayPath = (file: CompanyFile) => {
  const filePath = file.path;

  const filePathElements = filePath?.split("/");
  filePathElements?.shift();

  return filePathElements.join(" > ");
}