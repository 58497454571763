import React, { useContext } from "react";
import { Button, Stack } from "@mui/material";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import makeStyles from "@mui/styles/makeStyles";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import useRoute from "../../../hooks/useRoute";
import StageSelector from "../../molecules/crunchbase-modal/StageSelector";
import MultiSelector from "../MultiSelector";
import { Dashboard } from "../../../types/files";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '8px 4px',
        background: `#fff`,
    },
    title: {
        minWidth: 'unset !important',
        padding: 'unset !important',
        fontFamily: 'Inter',
        fontSize: '1rem !important',
        fontWeight: '700 !important',
        color: theme.palette.primary.main,
        textDecoration: 'none !important',
        textTransform: 'none !important' as any,
        "&:hover": {
            background: 'transparent',
            textDecoration: 'none !important',
        }
    },
    icon: {
        width: 20,
        height: 20,
    },
    largeIcon: {
        width: 30,
        height: 30,
        fill: theme.colors.neutral['400'],
    },
}));

const DashboardCardAssignItem: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const { tags } = useContext(GroupSettingsContext);
    const { redirectToDashboard } = useRoute();

    if (!dashboard)
        return (<></>);

    return (<>
        <Stack direction="row" className={classes.container}
            spacing={1} alignItems="flex-start" width="100%">
            <Stack width="fit-content">
                <DashboardIcon className={classes.largeIcon} />
            </Stack>
            <Stack direction="column" spacing={1} alignItems="flex-start" width="100%" paddingLeft={1}>
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <Button variant="text" className={classes.title} onClick={() => redirectToDashboard(dashboard.id)}>
                            {dashboard!.title}
                        </Button>
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="flex-start">
                        <StageSelector
                            defaultStage={dashboard?.investmentStage ?? undefined}
                            readOnly filled />
                        <MultiSelector
                            mainOptions={tags}
                            selections={dashboard.tags || []}
                            limit={3}
                            readOnly fullSpan />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    </>);
}

export default DashboardCardAssignItem;
