import React from 'react';
import { ExtFile, FileInputButton } from "@files-ui/react";
import { Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as FileUploadIcon } from "../../../assets/icons/upload-browse.svg"

const useStyles = makeStyles(() => ({
    uploadIcon: {
        width: '80px',
        height: '80px',
        marginTop: '-16px',
        marginBottom: '8px',
    },
    browseButton: {
        width: '120px',
        height: '40px',
        marginTop: '16px !important',
        fontSize: '16px',
        fontWeight: '500',
        borderRadius: '20px',
        background: '#048290 !important',
        textTransform: 'none !important' as any,
    },
}));

const FileInput: React.FC<{
    extFiles: Array<ExtFile>,
    validFile?: {
        maxFileSize?: number,
        allowedFileTypes?: Array<string>,
    },
    onUpdate: (extFiles: ExtFile[]) => void,
}> = ({ extFiles, validFile, onUpdate }) => {
    const classes = useStyles();

    return (<>
        <Stack spacing="4px" alignItems="center" justifyContent="center">
            <FileUploadIcon className={classes.uploadIcon} />
            <Typography variant="h6-bold" color="black">
                Drag and drop files here
            </Typography>
            <Typography color="black">
                Upload PDFs up to 100mb each
                <br/>or
            </Typography>
            <FileInputButton className={classes.browseButton}
                onChange={onUpdate}
                value={extFiles}
                maxFileSize={validFile?.maxFileSize}
                accept={!!validFile?.allowedFileTypes ? validFile.allowedFileTypes.join(',') : undefined}
                behaviour="replace"
                label="Browse"
            />
        </Stack>
    </>);
}

export default FileInput;