import React, { useContext, useState } from "react";
import { Container, Divider, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import IntegrationIcons from "../atoms/IntegrationIcons";
import { DashboardsContext } from "../../contexts/DashboardsContext";
import MarketInsightsMetrics from "../../components/organisms/market-insights/MarketInsightsMetrics";
import { scrollbarStyle } from "../../shared/dashboard";
import MarketInsightsSelectors from "../../components/organisms/market-insights/MarketInsightsSelectors";
import { FallbackLoading } from "../templates/loader";
import { GroupSettingsContext } from "../../contexts/GroupSettingsContext";
import ProPlanModal from "../modals/subscriptions/ProPlanModal";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
}));

const Periods = ['3 months', '6 months', '9 months', '12 months', '24 months'];

const MarketInsightsPage: React.FC = () => {
    const classes = useStyles();
    const { tags, stages } = useContext(GroupSettingsContext);
    const { dashboards, dashboardsLoaded } = useContext(DashboardsContext);

    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<number>(0);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={2} pt={4} pb={2}>
                <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant="h4-bold">Market Insights</Typography>
                    <IntegrationIcons />
                </Stack>
                <Divider sx={{ width: '100%' }} />
                {!dashboardsLoaded ? (
                    <FallbackLoading height={`calc(100vh - 250px)`} />
                ) : (<>
                    <MarketInsightsSelectors
                        tags={tags}
                        stages={stages}
                        period={Periods}
                        onSelectTags={(selection: string[]) => setSelectedTags(selection)}
                        onSelectStages={(selection: string[]) => setSelectedStages(selection)}
                        onSelectPeriod={(selection: number) => setSelectedPeriod(selection)}
                        onApplyFilter={() => setApplyFilter(true)}
                    />
                    <Divider sx={{ width: '100%' }} />
                    <MarketInsightsMetrics
                        dashboards={dashboards}
                        tags={selectedTags.map(tag => tag.toLowerCase()).sort()}
                        stages={selectedStages.map(stage => stage.toLowerCase()).sort()}
                        period={selectedPeriod}
                        applyFilter={applyFilter}
                        onResetApplyFilter={() => setApplyFilter(false)}
                    />
                </>)}
            </Stack>
        </Container>
        <ProPlanModal variant="upgrade" exceptPlan={['free', 'alpha']}  />
    </>);
}

export default MarketInsightsPage;