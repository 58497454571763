import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, Workspace } from "../../../types/files";
import DashboardListItem from "../../atoms/dashboards/DashboardListItem";
import { FallbackLoading } from "../../templates/loader";
import NoDashboardsFoundView from "../dashboards-empty/NoDashboardsFoundView";
import useDashboards from "../../../hooks/useDashboards";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(100% - 30px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: 20,
        ...scrollbarStyle,
    },
    previousSection: {
        width: '100%',
        marginBottom: 8,
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.colors.neutral['500'],
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    sharedTitle: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: '600',
        color: theme.colors.neutral['700'],
    },
}));

const SavedMatches: React.FC<{
    workspace?: Workspace,
    search?: string,
 }> = ({ workspace, search }) => {
    const classes = useStyles();
    const { workspace: globalWorkspace, isPublicView } = useContext(DashboardsContext);
    const { dashboardsLoaded, filterDashboardList } = useDashboards();

    const [latelySharedArray, setLatelySharedArray] = useState<Dashboard[]>([]);
    const [previouslySharedArray, setPreviouslySharedArray] = useState<Dashboard[]>([]);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const getMembership = useCallback((dashboard: Dashboard) =>
        dashboard?.workspaceMemberships?.find(wsMem => wsMem.workspaceId === localWorkspace?.id)
    , [localWorkspace?.id]);

    const filteredDashboards = useMemo(() =>
        filterDashboardList(search || '', localWorkspace?.id)
            .sort((prev: Dashboard, next: Dashboard) => {
                const prevDate = getMembership(prev)?.updatedAt || prev.createdAt;
                const nextDate =getMembership(next)?.updatedAt || next.createdAt;

                return nextDate.localeCompare(prevDate);
            })
        // eslint-disable-next-line
    , [filterDashboardList, localWorkspace, search]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setLatelySharedArray(filteredDashboards.filter(dashboard => {
                const wsMembership = getMembership(dashboard);

                return moment().diff(!!wsMembership ? wsMembership.updatedAt : dashboard.updatedAt, 'days') < 7;
            }));
            setPreviouslySharedArray(filteredDashboards.filter(dashboard => {
                const wsMembership = getMembership(dashboard);

                return moment().diff(!!wsMembership ? wsMembership.updatedAt : dashboard.updatedAt, 'days') >= 7;
            }));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [filteredDashboards]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        {(!dashboardsLoaded || (!!filteredDashboards.length && (!latelySharedArray.length && !previouslySharedArray.length))) ? (
            <FallbackLoading />
        ) : (<>
            {isPublicView && (<>
                <Typography className={classes.sharedTitle} pl={1} py={1}>
                    {`Deals shared:`}
                </Typography>
                <Divider sx={{ width: '100%' }} />
            </>)}
            <Stack className={classes.container} spacing={1} alignItems="flex-start" justifyContent="stretch" width="100%" height="100%">
                {(!!latelySharedArray.length || !!previouslySharedArray.length) ? (<>
                    <Box width="100%" height="100%">
                        {latelySharedArray?.map((dashboard: Dashboard, i) => (
                            <DashboardListItem
                                dashboard={dashboard}
                                workspaceId={localWorkspace.id}
                                queryTitles={['Description', 'Location']}
                                timestamp={getMembership(dashboard)?.updatedAt}
                                lastItem={latelySharedArray.length === (i + 1)}
                                key={'saved-matches-114-' + dashboard.id}
                            />
                        ))}
                        {!!previouslySharedArray.length && (<Divider className={classes.previousSection}>{'Previously shared'}</Divider>)}
                        {previouslySharedArray?.map((dashboard: Dashboard, i) => (
                            <DashboardListItem
                                dashboard={dashboard}
                                workspaceId={localWorkspace.id}
                                queryTitles={['Description', 'Location']}
                                timestamp={getMembership(dashboard)?.updatedAt}
                                lastItem={previouslySharedArray.length === (i + 1)}
                                key={'saved-matches-124-' + dashboard.id}
                                prevShared
                            />
                        ))}
                    </Box>
                </>) : (<NoDashboardsFoundView noResult isPublic={isPublicView} />)}
            </Stack>
        </>)}
    </>);
}

export default SavedMatches;