import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, RadioGroup } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useContext, useEffect, useState } from "react";
import ActionButtons from "../../molecules/crunchbase-modal/ActionButtons";
import CrunchbaseSelection, { CrunchbaseOption } from "../../molecules/crunchbase-modal/CrunchbaseSelection";
import WebsiteUrlInput from "../../molecules/crunchbase-modal/WebsiteUrlInput";
import TitleEditor from "../../molecules/crunchbase-modal/TitleEditor";
import { listCrunchbaseCompaniesFunc } from "../../../lib/helper";
import StageSelector from "../../molecules/crunchbase-modal/StageSelector";
import { DashboardContext } from "../../../contexts/DashboardContext";
import useDashboard from "../../../hooks/useDashboard";

const useStyles = makeStyles(() => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: '500px',
            height: 'auto',
            borderRadius: 20,
            boxShadow: 'none',
            color: '#000',
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '20px 20px 0 60px',
        width: '100%',
    },
    dialogContent: {
        height: '100%',
        width: '100%',
        padding: '0 24px 20px 24px',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 20px 20px 20px',
    },
    divider: {
        margin: '8px 0',
        maxWidth: '100%',
        color: 'gray',
    },
}));

const CrunchbaseModal: React.FC<{
    open: boolean,
    onSave: (permalink: string, customUrl?: boolean) => void,
}> = ({ open, onSave }) => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);
    const { updateTitle, updateStage } = useDashboard();

    const [crunchbases, setCrunchbases] = useState<CrunchbaseOption[]>([]);
    const [title, setTitle] = useState<string>("");
    const [permalink, setPermalink] = useState<string>("");
    const [customUrl, setCustomUrl] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const handleDeny = useCallback(() => {
        window.location.assign("/dashboards");
    }, []);

    const handleConfirm = useCallback(() => {
        const link = customUrl || permalink;

        if (/^https?:\/\//i.test(link))
            onSave(link.replace(/^https?:\/\/(?:www\.)?/, "https://www."), Boolean(customUrl));
        else if (/^(www\.)?\S+\.\S+$/.test(link))
            onSave("https://www." + link.replace(/^www\./, ''), Boolean(customUrl));
        else if (/^\S+\.\S+$/.test(link))
            onSave("https://www." + link.replace(/^www\./, ''), Boolean(customUrl));
        else
            onSave(link, Boolean(customUrl));
    }, [customUrl, permalink, onSave]);

    useEffect(() => {
        if (open && !!dashboard)
            setTitle(dashboard.title);
    }, [dashboard, open]);

    useEffect(() => {
        if (!!title) {
            setIsLoaded(false);
            listCrunchbaseCompaniesFunc(title).then((data: CrunchbaseOption[]) => {
                setCrunchbases(data);
                setIsLoaded(true);
            });
        }
    }, [title]);

    if (!dashboard)
        return (<></>);

    return (
        <Dialog className={classes.dialog}
            open={open}
            onClose={handleDeny}>
            <DialogTitle className={classes.dialogTitle}>
                <TitleEditor title={title} onUpdateTitle={(title) => {
                    if (updateTitle(title)) {
                        setTitle(title);
                        return true;
                    }

                    return false;
                }}/>
            </DialogTitle>
            <Divider className={classes.divider} />
            <DialogContent className={classes.dialogContent}>
                <FormControl component="fieldset" sx={{ width: '100%' }} disabled={Boolean(customUrl)}>
                    <RadioGroup
                        defaultValue={undefined}
                        onChange={(e) => {
                            e.preventDefault();
                            setPermalink(e.target.value as string);
                        }}>
                        <CrunchbaseSelection crunchbases={crunchbases} loaded={isLoaded} />
                    </RadioGroup>
                    <Divider className={classes.divider}>or</Divider>
                    <Box my={2}>
                        <WebsiteUrlInput customUrl={customUrl} onCustomUrl={setCustomUrl} />
                    </Box>
                    <Divider className={classes.divider} />
                    <StageSelector defaultStage={dashboard.investmentStage ?? undefined} onSelectStage={updateStage} />
                </FormControl>
            </DialogContent>
            <DialogActions className={classes.dialogActions} sx={{ justifyContent: 'flex-end' }}>
                <ActionButtons onDeny={handleDeny} onConfirm={handleConfirm} disableSave={!permalink && !customUrl} />
            </DialogActions>
        </Dialog>
    );
}

export default CrunchbaseModal;