import React, { useContext, useEffect, useMemo, useState } from "react";
import LinkedQueryContainer from "../dashboard-query-container/LinkedQueryContainer";
import LinkedInPostsAnswer from "../dashboard-query-answer/LinkedInPostsAnswer";
import ConfirmDialog from "../../modals/ConfirmDialog";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import {DashboardContext} from "../../../contexts/DashboardContext";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";

const LinkedInPostsQuery: React.FC<{}> = () => {
    const { isPublicView } = useContext(DashboardContext);
    const { isLoading, defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { queryAnswer: linkedInAnswer, updateQuery, removeQuery } = useDashboardQuery('LinkedIn');
    const { queryAnswer: postsAnswer } = useDashboardQuery('LinkedIn Posts');
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const isVisibleByDefault = useMemo(() =>
        defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'LinkedIn')?.visibleByDefault ?? true
    , [defaultQueryTemplateValues]);

    const isPresent = useMemo(() => 'answer' in linkedInAnswer && !!linkedInAnswer.answer, [linkedInAnswer]);
    const isDeleted = useMemo(() => 'deletedAt' in linkedInAnswer && !!linkedInAnswer.deletedAt, [linkedInAnswer]);
    const isHidden = useMemo(() => isDeleted || (!isVisibleByDefault && !isPresent), [isDeleted, isPresent, isVisibleByDefault]);

    useEffect(() => {
        if (!isDeleted && (!isVisibleByDefault && isPresent) && !linkedInAnswer.manualOverride)
            removeQuery(linkedInAnswer);
    // eslint-disable-next-line
    }, [isDeleted, isPresent, isVisibleByDefault, linkedInAnswer]);

    useEffect(() => {
        if (isDeleteDialogOpen && isDeleted)
            setDeleteDialogOpen(false);
    }, [isDeleteDialogOpen, isDeleted]);

    if (isHidden)
        return (<></>);

    return (<>
        <LinkedQueryContainer
            queryAnswer={linkedInAnswer}
            isPublic={isPublicView}
            onEdit={updateQuery}
            onDelete={() => setDeleteDialogOpen(true)}>
            <LinkedInPostsAnswer recentPosts={postsAnswer?.answeredQuestion} />
        </LinkedQueryContainer>
        <ConfirmDialog
            title="Delete Confirmation"
            content="Are you sure you want to delete this query?"
            open={isDeleteDialogOpen}
            loading={isLoading}
            confirmCallback={() => removeQuery(linkedInAnswer)}
            cancelCallback={() => setDeleteDialogOpen(false)}
        />
    </>);
}

export default LinkedInPostsQuery;
