import { Box, Button, Divider, IconButton, Stack, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../../../../helpers/editor-js";
import { Dashboard, DashboardNote, DashboardNoteType, RefreshDataEnum } from "../../../../types/files";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { scrollbarStyle } from "../../../../shared/dashboard";
import { ReactComponent as EditIcon } from '../../../../../public/svgs/edit.svg';
import { NotesContent } from "../../../modals/dashboard-notes/DashboardNoteDialog";
import { LoadingButton } from "@mui/lab";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import {AuthContext} from "../../../../contexts/AuthContext";
import DeleteIcon from '@mui/icons-material/Delete';
import { refreshDashboardQueryFunc, updateDashboardFunc } from "../../../../lib/helper";
import { getUserEmail } from "../../../../helpers/authUser";
import { v4 as uuidv4 } from "uuid";
import ConfirmDialog from '../../../modals/ConfirmDialog';
import defaultDashboardQueries from "../../../../helpers/defaultDashboardQueryTemplates.json";
import { ReactComponent as ExpandIcon } from "../../../../assets/icons/expand.svg";

const ReactEditorJS = createReactEditorJS();

const useStyles = makeStyles((theme) => ({
    noteContainer: {
        minWidth: 600,
        width: 600,
        height: '100%',
        borderRadius: 32,
        backgroundColor: '#fff',
        border: `1px solid ${theme.colors.neutral['100']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        transition: '0.05s ease-in-out',
        "&:hover": {
            boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
        },
        "& .codex-editor__redactor": {
            paddingBottom: '0 !important',
            marginRight: '0 !important',
        },
    },
    contentBlock: {
        height: '100%',
        width: '100%',
        padding: 0,
        marginBottom: 20,
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    editModeBlock: {
        paddingLeft: 32,
        marginBottom: 0,
    },
    editStateIcon: {
        height: '24px',
        width: '24px',
        fill: 'gray',
    },
    editControl: {
        background: '#fff',
        border: 'none',
        borderRadius: '50%',
        margin: '8px 16px 0 0',
    },
    editorContainer: {
        height: 'auto',
        paddingTop: 2,
        paddingRight: 24,
        "& > div": {},
        "& .codex-editor": {},
        "& .codex-editor__redactor": {
            paddingBottom: '0px !important',
        },
        "& .ce-settings": {
            left: '30px',
        },
        "& .ce-toolbox": {
            left: '2px',
        },
    },
    divider: {
        marginLeft: '24px',
        marginRight: '20px',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '8px 20px',
    },
    commonButton: {
        height: 32,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        height: 32,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        marginRight: 10,
    },
    deleteButton: {
        marginRight: 10,
    },
}));

const NoteEditor: React.FC<{
    preselectedDashboard?: Dashboard,
    predefinedNote?: DashboardNote,
    newNote?: boolean,
    onSelect?: (editNote: DashboardNote) => void,
    onCloseNewNote?: () => void,
}> = ({
    preselectedDashboard, predefinedNote, newNote, onSelect, onCloseNewNote,
}) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isEditing, setEditing] = useState<boolean>(false);
    const [dashboardEditorInstance, setDashboardEditorInstance] = useState<any>(null);
    const [dashboardNotesContent, setDashboardNotesContent] = useState<NotesContent[]>([]);
    const [dashboardNoteTitle, setDashboardNoteTitle] = useState<string>('');
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

    const dashboardNotesEditorRef = useRef<any>(null);

    const formatHeaderData = (value: string) => ({ type: 'header', data: {level: 2,  text: value, },});

    const formatFieldData = (displayTitle: string, value: string) => ({ type: 'paragraph',
        data: { text: (`${(!!displayTitle ? "<b>" + displayTitle + ":</b>&nbsp;" : "") + "<span>" + value + "</span>"}`), },
    });

    const otherUserNote = useMemo(() => (
        !!predefinedNote && user.attributes.email !== predefinedNote?.createdBy
    ), [predefinedNote, user]);

    const handleInitializeEditor = useCallback(async (instance: any) => {
        await instance._editorJS?.isReady;
        dashboardNotesEditorRef.current = instance;
        setDashboardEditorInstance(instance);
    }, []);

    const reloadNote = useCallback((note: DashboardNote) => {
        const notes = JSON.parse(note.data);
        if (Array.isArray(notes)) {
            const [header, ...rest] = notes;
            setDashboardNoteTitle((!!header && header.type === 'header') ? header.data.text: '');
            setDashboardNotesContent(rest);
        } else {
            let unifiedNotes = [];
            if (!!notes?.dashboard) {
                unifiedNotes.push(...notes?.dashboard);
            }
            if (!!notes?.user) {
                unifiedNotes.push(...notes?.user);
            }
            setDashboardNoteTitle('');
            setDashboardNotesContent(unifiedNotes);
        }
    }, []);

    const saveDashboardNotes = useCallback(async() => {
        if (!otherUserNote) {
            const savedData = await dashboardNotesEditorRef.current?.save();
            if (savedData) {
                const regex = /<b>([^<]+):<\/b>\s+/;
                const notesContent = savedData.blocks.map((block: NotesContent) => {
                    if (block.type === 'paragraph' && !!block.data.text) {
                        const match = block.data.text.match(regex);
                        if (match && match[1].length)
                            return formatFieldData(match[1], block.data.text.replace(regex, ''));
                    }

                    return {type: block.type, data: block.data,};
                });

                setDashboardNotesContent(notesContent);

                return notesContent;
            }
        }

        return dashboardNotesContent;
    }, [dashboardNotesContent, otherUserNote]);

    const saveNote = useCallback((closeOnSave?: boolean) => {
        setSaveLoading(true);
        saveDashboardNotes().then(async (results) => {
            const noteData = JSON.stringify([formatHeaderData(dashboardNoteTitle), ...results,]);

            let newNote: DashboardNote = {
                id: uuidv4(),
                title: dashboardNoteTitle,
                data: noteData,
                type: DashboardNoteType.External,
                createdBy: getUserEmail(user),
                updatedBy: getUserEmail(user),
                createdAt: new Date(),
                updatedAt: new Date(),
            };
            let oldNotes: DashboardNote[] = preselectedDashboard?.notes || [];
            let newNotes: DashboardNote[] = [];

            if (predefinedNote) {
                newNotes = oldNotes.map(note => {
                    if (note.id === predefinedNote.id)
                        return newNote;
                    return note;
                }) || [];
            } else {
                newNotes = [...oldNotes, newNote];
            }

            const dashboardUpdate: Dashboard = {
                ...preselectedDashboard!,
                notes: newNotes,
                refreshData: {
                    lastUpdatedBy: 'user',
                    shouldRefresh: true,
                    modifiedData: RefreshDataEnum.Notes,
                }
            };

            const isSuccess = await updateDashboardFunc(dashboardUpdate);

            if (isSuccess) {
                enqueueSnackbar(`Note successfully saved to ${dashboardUpdate.title}`, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
                });

                await Promise.all(
                    Object.values(defaultDashboardQueries)
                        .filter((val: any) => val.referencesFreehandText && val.isPrimaryQuery)
                        .map((val: any) => refreshDashboardQueryFunc(
                            dashboardUpdate.id,
                            val.title,
                            dashboardUpdate.group,
                            val.queryTemplate.replace('{dashboard_name}', dashboardUpdate.title),
                            val.order,
                            val.source
                        ))
                );
            } else {
                enqueueSnackbar(`Note not saved due to unknown error.`, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
                });
            }
            setSaveLoading(false);
            if (closeOnSave) {
                setEditing(false);
                setDashboardEditorInstance(null);
                dashboardNotesEditorRef.current = null;
                onCloseNewNote?.();
            }
        });
        // eslint-disable-next-line
    }, [predefinedNote, preselectedDashboard, dashboardNoteTitle, user]);

    const deleteNote = useCallback(async () => {
        if (!preselectedDashboard || !predefinedNote)
            return;

        setDeleteLoading(true);

        let newNotes: DashboardNote[] = preselectedDashboard.notes
            ?.filter(note => note.id !== predefinedNote.id) || [];

        const dashboardUpdate: Dashboard = {
            ...preselectedDashboard!,
            notes: newNotes,
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: true,
                modifiedData: RefreshDataEnum.Notes,
            }
        };

        const isSuccess = await updateDashboardFunc(dashboardUpdate);

        if (isSuccess) {
            enqueueSnackbar(`Note successfully deleted from ${dashboardUpdate.title}`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
        } else {
            enqueueSnackbar(`Note not deleted due to unknown error.`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
        }

        setDeleteLoading(false);
        setDeleteOpen(false);
        setEditing(false);
        // eslint-disable-next-line
    }, [predefinedNote, preselectedDashboard]);

    useEffect(() => {
        if (predefinedNote)
            reloadNote(predefinedNote);
        // eslint-disable-next-line
    }, [predefinedNote]);

    useEffect(() => {
        if (dashboardEditorInstance && dashboardEditorInstance._editorJS) {
            (async () => {
                if (!!dashboardNotesContent.length)
                    await dashboardEditorInstance._editorJS?.blocks.render({blocks: dashboardNotesContent});
                await dashboardEditorInstance._editorJS?.caret.setToLastBlock('end');
            })();
        }
    }, [dashboardEditorInstance, dashboardNotesContent]);

    const ReactEditor = useCallback(() => {
        return (
            <ReactEditorJS
                holder={'note-editor-' + predefinedNote?.id || 'new'}
                readOnly={newNote ? false : (!isEditing || otherUserNote)}
                onInitialize={handleInitializeEditor}
                tools={EDITOR_JS_TOOLS}
                hideToolbar
            />
        );
        // eslint-disable-next-line
    }, [isEditing, otherUserNote, newNote]);

    return (<>
        <Stack className={classes.noteContainer}>
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                {(otherUserNote || isEditing || newNote) ?  (
                    <IconButton size="small" className={classes.editControl}
                        onClick={() => {
                            if (predefinedNote) {
                                saveDashboardNotes().then(async (results) => {
                                    const editedNote = structuredClone(predefinedNote);
                                    editedNote.data = JSON.stringify([formatHeaderData(dashboardNoteTitle), ...results,]);
                                    onSelect?.(editedNote);
                                    reloadNote(predefinedNote);
                                });
                            } else {
                                saveDashboardNotes().then(async (results) =>
                                    onSelect?.({
                                        id: uuidv4(),
                                        title: dashboardNoteTitle,
                                        data: JSON.stringify([formatHeaderData(dashboardNoteTitle), ...results,]),
                                        createdBy: getUserEmail(user),
                                        updatedBy: getUserEmail(user),
                                        createdAt: new Date(),
                                        updatedAt: new Date(),
                                    } as DashboardNote)
                                );
                            }
                            setEditing(false);
                            setDashboardEditorInstance(null);
                            dashboardNotesEditorRef.current = null;
                            onCloseNewNote?.();
                        }}>
                        <ExpandIcon className={classes.editStateIcon} style={{ stroke: '#666666' }}/>
                    </IconButton>
                ) : (
                    <IconButton size="small" className={classes.editControl}
                        onClick={() => setEditing(true)}>
                        <EditIcon className={classes.editStateIcon} />
                    </IconButton>
                )}
            </Stack>
            <Box className={classNames(classes.contentBlock, (isEditing || newNote) && classes.editModeBlock)}
                onDoubleClick={() => {
                    if (!(isEditing || newNote))
                        onSelect?.(predefinedNote!);
                }}>
                {((predefinedNote && !!dashboardNoteTitle) || isEditing || newNote) && (
                    <TextField
                        autoComplete="off"
                        value={dashboardNoteTitle}
                        onChange={(e) => setDashboardNoteTitle(e.target.value)}
                        placeholder="Add title ..."
                        variant="standard"
                        disabled={!(isEditing || newNote) || otherUserNote}
                        InputProps={{ fullWidth: true, disableUnderline: true, }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.code === 'Enter')
                                dashboardNotesEditorRef.current?._editorJS?.caret.focus();
                        }}
                        sx={{
                            marginLeft: '30px',
                            width: 'calc(100% - 32px)',
                            "& .MuiInputBase-input": { fontSize: '1.5rem', fontWeight: 'bold' },
                        }}
                    />
                )}
                <Box className={classNames(classes.editorContainer, "large-grey-scrollbar-editor", "ce-custom-full-width")}>
                    <ReactEditor />
                </Box>
            </Box>
            {(isEditing || newNote) && (<>
                <Divider className={classes.divider} />
                <Stack className={classes.actions}>
                    <Stack direction="row" alignItems="center">
                        {!!predefinedNote && (
                            <IconButton
                                className={classes.deleteButton}
                                onClick={async () => setDeleteOpen(true)}
                                disabled={saveLoading || !predefinedNote || otherUserNote}
                            > <DeleteIcon /> </IconButton>
                        )}
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={() => {
                                if (predefinedNote)
                                    reloadNote(predefinedNote);
                                setEditing(false);
                                setDashboardEditorInstance(null);
                                dashboardNotesEditorRef.current = null;
                                onCloseNewNote?.();
                            }}
                            disabled={saveLoading}
                        > Cancel </Button>
                        <LoadingButton
                            variant="contained"
                            className={classes.commonButton}
                            disabled={!preselectedDashboard || otherUserNote}
                            loading={saveLoading}
                            onClick={() => saveNote(true)}
                        > Save </LoadingButton>
                    </Stack>
                </Stack>
            </>)}
        </Stack>
        <ConfirmDialog
            title="Delete confirmation"
            content={'Are you sure you want to delete this note?'}
            open={deleteOpen}
            loading={deleteLoading}
            confirmCallback={() => deleteNote()}
            cancelCallback={() => setDeleteOpen(false)}
        />
    </>);
}

export default NoteEditor;