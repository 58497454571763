import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import { defaultStages } from "../../../shared/dashboard";

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    subtext: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontStyle: 'italic',
        color: 'darkgray',
    },
    formContainer: {
        minWidth: '150px',
        paddingBottom: '8px',
        "& > .MuiInputBase-root > div": {
            backgroundColor: 'unset !important',
        }
    },
    filledForm: {
        minWidth: '105px',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            height: '30px',
            width: 'auto',
            borderRadius: '32px',
            background: '#E0F1F1',
            "& > .MuiSelect-select": {
                fontSize: '0.9rem',
                fontWeight: 'bold',
                color: '#048290',
                paddingTop: '0 !important',
                paddingBottom: '0 !important',
                '&.Mui-disabled': {
                    WebkitTextFillColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                },
            },
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                fill: theme.palette.primary.main,
                '&.Mui-disabled': {
                    display: 'none',
                },
            },
            "& fieldset": {
              border: 0,
            },
        },
    },
    disabled: {
        "& > .MuiInputBase-root":{
            backgroundColor: '#F1F3F5',
            "& > .MuiSelect-select": {
                color: theme.colors.neutral['600'],
                '&.Mui-disabled': {
                    WebkitTextFillColor: theme.colors.neutral['600'],
                    color: theme.colors.neutral['600'],
                },
            },
        },
    },
}));

const StageSelector: React.FC<{
    defaultStage?: string,
    filled?: boolean,
    readOnly?: boolean,
    disabled?: boolean,
    onSelectStage?: (stage: string) => void,
}> = ({ defaultStage, filled, readOnly, disabled, onSelectStage }) => {
    const classes = useStyles();
    const [selectedStage, setSelectedStage] = useState<string>('');

    const handleChange = useCallback((event: SelectChangeEvent) => {
        const stage = event.target.value;
        setSelectedStage(stage);
        onSelectStage?.(stage);
    }, [onSelectStage]);

    useEffect(()=>{
        if (defaultStage)
            setSelectedStage(defaultStage);
    }, [defaultStage]);

    return (<>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            {!filled && (
                <Typography component="p" className={classes.text}>
                    {'Select stage'}
                    <Typography component="span" className={classes.subtext} display="inline"> {'(optional)'}</Typography>
                </Typography>
            )}
            <FormControl className={classnames(classes.formContainer, filled && classes.filledForm, disabled && classes.disabled)}
                variant={filled ? 'outlined' : 'standard'} disabled={readOnly}>
                {!filled && (<InputLabel sx={{ color: 'darkgray' }}>{'Stage'}</InputLabel>)}
                <Select value={selectedStage}
                    onChange={handleChange}
                    renderValue={(selected) => {
                        if (filled && selected.length === 0)
                            return readOnly ? 'No Stage' : 'Select stage...';
                        return selected;
                    }}
                    displayEmpty={filled}>
                    {defaultStages.map((stage, i) => (
                        <MenuItem value={stage}
                            sx={{ fontSize: '0.9rem', color: (selectedStage === stage) ? '#7bd4d4' : 'gray', }}
                            key={"stage-selection-102-" + i}>
                            {stage}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    </>);
}

export default StageSelector;