import React, { useState } from "react";
import { Divider, Typography, Tooltip, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FundingModal from "../../modals/dashboard-details/FundingModal";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    urlIcon: {
        width: '36px',
        height: '36px',
        color: theme.colors.neutral['600'],
    },
}));

const FundingElement: React.FC<{items: any[]}> = ({items}) => {
    const classes = useStyles();
    const [fundingModalOpen, setFundingModalOpen] = useState<boolean>(false);
    const [fundingModalContent, setFundingModalContent] = useState<{funding_type: string, money_raised?: string, investor_list: string}[]|null>([]);

    if (!!items.length) {
        return (<>
            <Stack spacing={1} alignItems="center" justifyContent="center" minHeight="200px">
                <MonetizationOnIcon className={classes.urlIcon} />
                <Typography fontFamily="Inter" fontWeight="bold" fontSize="1.1rem">
                    {'Financing'}
                </Typography>
                <Stack alignItems="center"
                    sx={{ cursor: 'pointer', paddingX: '6px' }}
                    onClick={(e)=>{
                        e.preventDefault();
                        setFundingModalContent(items);
                        setFundingModalOpen(true);
                    }}>
                    {items.map((item: any, i) => (
                        <Stack direction="column" spacing={1} alignItems="center" key={'dashboard-timeline-258-' + i}>
                            <Typography sx={{
                                color: theme.palette.primary.main,
                                fontSize: '0.85rem',
                                fontWeight: 'bold',
                            }}> {item.funding_type} </Typography>
                            <Typography sx={{
                                color: '#666666',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                            }}> {item.money_raised} </Typography>
                            {!!item.investor_list &&
                                <Tooltip title={item.investor_list.length > 40 ? item.investor_list: ''} placement="top">
                                    <Typography
                                        component="span"
                                        sx={{
                                            color: "#666666",
                                            fontSize: "0.85rem",
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                            wordBreak: 'break-word',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            textAlign: 'center',
                                    }}> <Typography
                                            component="span"
                                            display="inline"
                                            sx={{
                                                color: "#666666",
                                                fontSize: '0.85rem',
                                                fontWeight: 'bold',
                                        }}>{'Investors: '}</Typography>
                                        {item.investor_list}
                                    </Typography>
                                </Tooltip>
                            }
                        </Stack>
                    ))}
                </Stack>
            </Stack>
            <Divider sx={{ width: '64px', margin: '4px', borderColor: "#7bd4d4" }} />
            <FundingModal
                funding={fundingModalContent!}
                open={fundingModalOpen}
                onClose={() => {setFundingModalOpen(false); setFundingModalContent([]);}}
            />
        </>);
    }

    return (<></>);
}

export default FundingElement;