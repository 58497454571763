import { Container, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from "classnames";
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';

const useStyles = makeStyles(() => ({
    progressContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        flexDirection: 'column',
        height: '75%',
    },
    loading: {
        marginBottom: '8px',
        width: '300px',
        height: '300px',
    },    
    caption: {
        marginBottom: '20px',
        color: '#7bd4d4',
    }
}));

const DashboardLoading: React.FC<{}> = () => {
    const classes = useStyles();
    
    return (
        <Container className={classnames(classes.progressContainer)}>
            <LottiePlayer className={classes.loading} src={"/lottie/dashboard-loading.json"} autoplay loop />
            <Typography variant="h6-bold" className={classes.caption} >Just a minute while we prepare the dashboard...</Typography>
        </Container>
    );
}

export default DashboardLoading;
