import { useContext, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import FolderNavigationContainer from './FolderNavigationContainer';
import SearchResultsContainer from './SearchResultsContainer';
import { CompanyFile } from '../../../types/files';
import { SearchResult, SearchType } from '../../../types/search';
import SearchBarContainer from './SearchBarContainer';
import { splitFolders } from '../../../helpers/fileStructure';
import { SearchNavigationContext } from '../../../contexts/SearchNavigationContext';
import { Box, Fab, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CATEGORY_TYPES } from '../components/search-bar/NewAdvancedSearch';

const useStyles = makeStyles(() => ({
  searchToggleIcon: {
    position: "absolute",
    right: -20,
    top: -20,
    height: 75,
    width: 75,
    zIndex: 6,
  },
}));

const SearchContainer: React.FC<{ tabId: number }> = ({ tabId }) => {
  const { contextSelectedFile,
    contextSetSelectedFile,
    contextSearchFolder,
    contextSetSearchFolder,
    contextSetSearchFile,
    contextSearchResults,
    contextSetSearchResults,
    contextTitleSearchResults,
    contextSetTitleSearchResults,
    contextShowSearchResults,
    contextSetShowSearchResults,
    contextSelectedSearchType,
    contextSelectedDocTypes,
  } = useContext(SearchNavigationContext);

  const classes = useStyles();
  const [useViewportHeight, setUseViewPortHeight] = useState<boolean>(true);
  const [searchBarHeight, setSearchBarHeight] = useState<number>(68);
  const [selectedFile, setSelectedFile] = useState<CompanyFile | null>(null);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
  const [searchFolder, setSearchFolder] = useState<CompanyFile | null>(null);
  const [selectedSearchType, setSelectedSearchType] = useState<SearchType>(SearchType.Keyword);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [titleSearchResults, setTitleSearchResults] = useState<CompanyFile[]>([]);
  const [loading, setLoading] = useState(false);
  const { fileStructure } = useContext(FileStructureContext);
  const [displaySearchBar, setDisplaySearchBar] = useState<boolean>(false);
  const docTypes = contextSelectedDocTypes[tabId];
  const documentCategories = docTypes?.length && CATEGORY_TYPES.find(v => docTypes?.includes(v)) ? docTypes : [];

  useEffect(() => {
    setSelectedFile(contextSelectedFile[tabId] || null);
    setShowSearchResults(contextShowSearchResults[tabId] || false);
    setSearchFolder(contextSearchFolder[tabId] || null);
    setSearchResults(contextSearchResults[tabId] || []);
    setTitleSearchResults(contextTitleSearchResults[tabId] || []);
    //eslint-disable-next-line
  }, [tabId]);

  useEffect(() => {
    if (contextSelectedSearchType[tabId]) {
      setSelectedSearchType(contextSelectedSearchType[tabId]);
    }
    //eslint-disable-next-line
  }, [tabId, contextSelectedSearchType]);

  const hideSearchResults = () => {
    setShowSearchResults(false);
    contextSetShowSearchResults(false);
  }

  //these are used for autocompletion purposes
  const [fileOptions, folderOptions] = splitFolders(fileStructure);
  folderOptions.sort();
  fileOptions.sort();

  if (selectedFile && !fileOptions.includes(selectedFile)) {
    setSelectedFile(null);
    contextSetSelectedFile(null);
  }

  return (
    <Box sx={{ position: "relative", height: "100%", display: "flex", flexDirection: "column" }}>
      <Fab
        color='primary'
        className={classes.searchToggleIcon}
        onClick={() => {
          setDisplaySearchBar((prevState) => {
            return !prevState;
          });
        }}
      >
        <SearchIcon />
      </Fab>
      <Collapse
        in={displaySearchBar}
        onEnter={() => {
          setUseViewPortHeight(false);
        }}
        onEntered={() => {
          setSearchBarHeight(224);
          setUseViewPortHeight(true);
        }}
        onExit={() => {
          setUseViewPortHeight(false);
        }}
        onExited={() => {
          setSearchBarHeight(68);
          setUseViewPortHeight(true);
        }}
        timeout={300}
      >
        <Box sx={{ position: "relative", height: 156, zIndex: 5 }}>
          <SearchBarContainer
            tabId={tabId}
            setTitleSearchResults={(res) => {
              setSearchResults([]);
              setTitleSearchResults(res);
              contextSetTitleSearchResults(res);
            }}
            setKeywordsSearchResults={(res) => {
              setTitleSearchResults([]);
              contextSetTitleSearchResults([]);
              setSearchResults(res);
              contextSetSearchResults(res);
            }}
            setSearchKeywords={(res) => { setSearchKeywords(res); }}
            setLoading={setLoading}
            setShowSearchResults={(res: boolean) => { setShowSearchResults(res); contextSetShowSearchResults(res); }}
            searchFolder={searchFolder}
            documentCategories={documentCategories || undefined} //if an empty string, make undefined
          />
        </Box>
      </Collapse>
      <Box sx={{ height: 'calc(100% - 156px)', flexGrow: 1 }} >
        {showSearchResults ? (
          <SearchResultsContainer
            tabId={tabId}
            keywords={searchKeywords}
            searchResults={searchResults}
            loading={loading}
            searchFolder={searchFolder}
            titleSearchResults={titleSearchResults}
            onClose={hideSearchResults}
            searchType={selectedSearchType}
            heightToSubtract={searchBarHeight}
          />
        ) : (
          <FolderNavigationContainer
            tabId={tabId}
            onFolderSelected={(res) => { setSearchFolder(res); contextSetSearchFolder(res); }}
            onFileSelected={(res) => { contextSetSearchFile(res); }}
            heightToSubtract={searchBarHeight}
            useVh={useViewportHeight}
          />
        )}
      </Box>
    </Box>
  );
};

export default SearchContainer;
