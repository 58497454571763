import React, { useContext, useEffect, useState } from "react";
import { Avatar, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ProPlan from "../../molecules/subscription-plans/ProPlan";
import FreePlan from "../../molecules/subscription-plans/FreePlan";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import classNames from "classnames";
import {SubscriptionContext} from "../../../contexts/SubscriptionContext";
import moment from "moment";
import ManageSubscription from "../../molecules/subscription-plans/ManageSubscription";

const useStyles = makeStyles((theme) => ({
    planCard: {
        position: 'relative',
        display: 'inline-block',
        width: 448,
        height: 528,
        marginTop: 8,
        borderRadius: 32,
        padding: 16,
        border: `2px solid ${theme.colors.neutral['200']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        transition: '0.05s ease-in-out',
        "&:hover": {
            boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40, 0.14), 0px 1px 14px 0px rgba(16, 24, 40, 0.12)',
        },
    },
    selectedCard: {
        border: `2px solid ${theme.colors.cyan['400']}`,
        "&:hover": {
            border: `2px solid ${theme.colors.cyan['400']}`,
        }
    },
    selectionMark: {
        position: 'relative',
        width: 60,
        height: 60,
        bottom: `calc(100% + calc(${2 * 60}px / 3))`,
        left: `calc(100% - calc(${60}px / 3))`,
        background: theme.colors.cyan[50],
        border: `2px solid ${theme.colors.cyan['400']}`,
    },
    selectionMarkIcon :{
        padding: 0,
        width: 50,
        height: 50,
        fill: theme.colors.primary[400],
    }
}));

const SubscriptionSelector: React.FC<{
    onSelectSubscription: () => void,
}> = ({ onSelectSubscription }) => {
    const classes = useStyles();
    const { subscription } = useContext(SubscriptionContext);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [freePlanSelect, setFreePlanSelect] = useState<boolean>(false);
    const [proPlanSelect, setProPlanSelect] = useState<boolean>(false);
    const [cancelled, setCancelled] = useState<boolean>(false);
    const [paymentReceived, setPaymentReceived] = useState<boolean>(false);
    const [trialDays, setTrialDays] = useState<number | undefined>(undefined);
    const [admin, setAdmin] = useState<string>('');

    useEffect(() => {
        if (subscription) {
            switch (subscription.planName?.toLowerCase()) {
                case 'charlie':
                case 'beta':
                    if (subscription?.cancelAt) {
                        const fromCancelDiff = moment(subscription?.cancelAt).diff(moment(), 'days');
                        if (fromCancelDiff >= 0)
                            setTrialDays(fromCancelDiff);
                        setProPlanSelect(true);
                        setCancelled(true);
                    } else if (subscription.status === 'trialing') {
                        let trialDaysCount = 0;
                        if (subscription?.trialEndedAt)
                            trialDaysCount = moment(subscription?.trialEndedAt).diff(moment(), 'days');

                        if (trialDaysCount >= 0 && subscription?.paymentMethodId) {
                            setTrialDays(trialDaysCount);
                            setProPlanSelect(true);
                            setPaymentReceived(true);
                        } else if (trialDaysCount >= 0) {
                            setTrialDays(trialDaysCount);
                            setProPlanSelect(true);
                        } else if (subscription?.paymentMethodId) {
                            setProPlanSelect(true);
                            setPaymentReceived(true);
                        } else {
                            setFreePlanSelect(true);
                        }
                    } else if (subscription.status === 'active') {
                        setProPlanSelect(true);
                    } break;
                case 'alpha':
                case 'free':
                    setFreePlanSelect(true);
                    break;
                default:
                    break;
            }
            setAdmin(subscription?.user!);
            setLoaded(true);
        }
    }, [subscription]);

    return (<>
        <Stack direction="column" alignItems="center" justifyContent="center" pt={5} width="100%">
            <Stack direction="row" spacing={8} alignItems="center" justifyContent="center">
                <Stack direction="column" className={classNames(classes.planCard, freePlanSelect && classes.selectedCard)}>
                    <FreePlan selected={freePlanSelect}
                        admin={admin}
                        daysLeft={trialDays}
                        hasPayment={paymentReceived}
                        loaded={loaded} />
                    {freePlanSelect && (
                        <Avatar className={classes.selectionMark}>
                            <CheckRoundedIcon className={classes.selectionMarkIcon} />
                        </Avatar>
                    )}
                </Stack>
                <Stack direction="column" className={classNames(classes.planCard, proPlanSelect && classes.selectedCard)}>
                    <ProPlan selected={proPlanSelect}
                        admin={admin}
                        daysLeft={trialDays}
                        cancelled={cancelled}
                        hasPayment={paymentReceived}
                        loaded={loaded}
                        onSelectPlan={onSelectSubscription} />
                    {proPlanSelect && (
                        <Avatar className={classes.selectionMark}>
                            <CheckRoundedIcon className={classes.selectionMarkIcon} />
                        </Avatar>
                    )}
                </Stack>
            </Stack>
            <ManageSubscription selected={proPlanSelect}
                admin={admin}
                daysLeft={trialDays}
                cancelled={cancelled}
                hasPayment={paymentReceived}
                loaded={loaded}
                onSelectPlan={onSelectSubscription} />
        </Stack>
    </>);
};

export default SubscriptionSelector;