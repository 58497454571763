import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Viewer, DocumentLoadEvent, LoadError, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { searchPlugin } from '@react-pdf-viewer/search';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import { s3DownloadExternalBucket, s3Download } from '../../../helpers/s3';
import { Backdrop, Button, Fab, Grid, IconButton, Tooltip } from '@mui/material';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import AddCommentPane from "./file-preview/AddCommentPane";
import HighlightAreaActions from "./file-preview/HighlightAreaActions";
import CommentsArea from './file-preview/CommentsArea';
import CommentsHighlightArea, { CommentHightlightAreas } from "./file-preview/CommentsHighlightArea";
import { PreviewHighlightAction, SaveDashboardItemType, User } from '../../../types/common';
import { Comment, CommentOriginType, ExtendedComment } from '../../../types/comments';
import { getCommentsFunc, getDashboardById, updateDashboardFunc } from '../../../lib/helper';
import { RenderZoomOutProps, zoomPlugin } from '@react-pdf-viewer/zoom';
import AddIcon from '@mui/icons-material/Add';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinTwoToneIcon from '@mui/icons-material/PushPinTwoTone';
import RemoveIcon from '@mui/icons-material/Remove';
// import DashboardIcon from '@mui/icons-material/Dashboard';
import CropIcon from '@mui/icons-material/Crop';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classNames from 'classnames';
import CustomPageRender from './file-preview/CustomPageRender';
import AddToDashboardPane from './file-preview/dashboard/AddToDashboardPane';
import ScreenCaptureFC, { HighlightAreaCoordinates } from './file-preview/dashboard/ScreenCaptureFC';
import { UsersContext } from '../../../contexts/UsersContext';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { DashboardUid } from '../../../types/files';
import { AuthContext } from '../../../contexts/AuthContext';
import { basename, join, dirname } from 'path'
import awsConfig from '../../../aws-exports';
import { scrollbarStyle } from '../../../shared/dashboard';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    "& .rpv-core__inner-pages": scrollbarStyle
  },
  fileNameContainer: {
    display: "flex",
    flexDirection: "row",
    margin: 5,
  },
  fileContainer: {
    height: '96%',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fileNameElement: {
    margin: "0 5px",
  },
  buttonsExcel: {
    fontSize: "0.5rem",
    margin: "0 5px",
    borderRadius: 5,
    width: 90,
    backgroundColor: "#84D0D1",
    color: 'black',
  },
  buttonsExcelActive: {
    fontSize: "0.5rem",
    margin: "0 5px",
    borderRadius: 5,
    width: 90,
    "&:hover": {
      backgroundColor: "#1797A6",
      color: 'white',
    },
    backgroundColor: 'white',
    color: theme.palette.primary.contrastText,
  },
  commentsArea: {
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
  },
  commentsLoader: {
    margin: 'auto',
  },
  filePreviewContainer: {
    height: 'inherit',
  },
  iconButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    height: 56,
    width: 56,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  zoomButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    height: 40,
    width: 40,
    marginBottom: 10,
  },
  zoomButtonsContainer: {
    position: "absolute",
    top: 25,
    right: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  openIcon: {
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  topLoadButton: {
    position: "absolute",
    top: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1
  },
  bottomLoadButton: {
    position: "absolute",
    bottom: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1
  },
  dashboardButtonContainer: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1
  },
  cropButtonContainer: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1
  },
  cropHighlightArea: {
    border: `2px solid ${theme.palette.secondary.light}`,
    position: "fixed"
  }
}));

interface Props {
  commentOriginType: CommentOriginType;
  filePath?: string;
  fileKey?: string;
  displayFromSource?: boolean;
  sourceUrl?: string;
  page?: number;
  textOnly?: boolean;
  highlightedKeywords?: string[];
  enableDashboard?: boolean;
  onGoToCategories?: () => void;
  openFullScreen?: boolean;
  onOpenInFullScreen?: () => void;
  sourceComments?: Comment[];
  enableComments?: boolean;
  enableCommentsPane?: boolean;
  onCommentAdded?: (item: Comment) => void;
  onCommentUpdated?: (item: Comment) => void,
  onCommentRemoved?: (documentId: string, id: string, parentId?: string | undefined) => void;
  onCommentReplyAdded?: (iten: ExtendedComment) => void;
  selectedCommentId?: string | null;
  selectedExtendedComment?: ExtendedComment;
  selectedCommentParentId?: string | null | undefined;
  dashboardItem?: DashboardUid;
  allowPartialLoad?: boolean;
  showCategories?: boolean;
  showDiscussion?: boolean;
  showAddCrop?: boolean;
  showZoomInOut?: boolean;
  onSetDashboardDeck?: ({ fileKey, page }: { fileKey: string, page?: number }) => Promise<void>;
  dashboardId?: string;
  loadFromCacheBucket?: boolean;
  placeholder?: string;
  singlePage?: boolean;
  onLoaded?: () => void;
}


//TODO consider moving this up to a different level
const getCacheBucketName = (exports: any) => {
  return exports.aws_cache_bucket
}


const PAGE_WINDOW = 3
const getPageForSnippet = (pageNumber: number) => pageNumber < PAGE_WINDOW ? pageNumber : PAGE_WINDOW


const FilePreview = ({
  commentOriginType, fileKey, page, filePath, dashboardItem, allowPartialLoad = false, displayFromSource = false,
  sourceUrl, textOnly = false, highlightedKeywords = [], enableDashboard = true, openFullScreen = false,
  onOpenInFullScreen, onGoToCategories, sourceComments, enableComments = false, enableCommentsPane = true,
  onCommentAdded, onCommentUpdated, onCommentRemoved, onCommentReplyAdded, onSetDashboardDeck, dashboardId,
  selectedCommentId, selectedExtendedComment, selectedCommentParentId, showCategories = true, showDiscussion = true, showAddCrop = true, showZoomInOut = true, loadFromCacheBucket = false,
  placeholder = "Searching...", singlePage = false, onLoaded }: Props) => {

  const classes = useStyles();
  const [fileUrl, setFileUrl] = useState('');
  const [numberPages, setNumberPages] = useState([]);
  const [numberPdfPages, setNumberPdfPages] = useState(0);
  const [sheetsNames, setSheetsNames] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentGlobalPage, setCurrentGlobalPage] = useState(0);
  const [pageNavigationPluginInstance] = useState(pageNavigationPlugin());
  const [highlightCommentAreas, setHighlightCommentAreas] = useState<any[]>([]);
  const [displayHighlightCommentAreas, setDisplayHighlightCommentAreas] = useState<any[]>([]);
  const [selectedComment, setSelectedComment] = useState<CommentHightlightAreas | undefined>();
  const [mentionUsers, setMentionUsers] = useState<User[]>([]);
  const [comments, setComments] = useState<ExtendedComment[]>([]);
  const [loadingComments, setLoadingComments] = useState<boolean>(false);
  const [dashboardPaneOpen, setDashboardPaneOpen] = useState<boolean>(false);
  const [documentLoaded, setDocumentLoaded] = useState<boolean>(false);
  const [highlightAction, setHighlightAction] = useState<PreviewHighlightAction>(PreviewHighlightAction.Comment);
  const [saveDashboardItem, setSaveDashboardItem] = useState<SaveDashboardItemType>(SaveDashboardItemType.Page);
  const [screenCapture, setScreenCapture] = useState<string>("");
  const [screenCaptureLoading, setScreenCaptureLoading] = useState<boolean>(false);
  const [screenCaptureCoordinates, setScreenCaptureCoordinates] = useState<HighlightAreaCoordinates | null>(null);
  const [selectedText, setSelectedText] = useState<string>("");
  const [cachedPdf, setCachedPdf] = useState<Uint8Array | undefined>()
  const [pdfBytes, setPdfBytes] = useState<Uint8Array | undefined>()
  const [showSnippet, setShowSnippet] = useState<boolean>(allowPartialLoad)
  const [pinningDeckInProgress, setPinningDeckInProgress] = useState<boolean>(false)
  const { userGroup } = useContext(AuthContext);
  const { users, usersLoading } = useContext(UsersContext);

  useEffect(() => {
    return () => {
      setDocumentLoaded(false);
    }
  }, [fileKey]);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
      setCurrentGlobalPage(page);
    }
    setNumberPages([])
    setNumberPdfPages(0)
    setCachedPdf(undefined)
    setPdfBytes(undefined)
    setShowSnippet(allowPartialLoad)
  }, [fileKey, page, allowPartialLoad]);

  const regexKeywords = highlightedKeywords.flat().map(v => new RegExp(v.replaceAll("  ", " ").split(" ").join("[ \t]+"), 'i'));

  const searchPluginInstance = searchPlugin({ keyword: regexKeywords });
  const zoomPluginInstance = zoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget: (props) => <HighlightAreaActions enableDashboard={enableDashboard} onOpen={(action) => { setHighlightAction(action); setSelectedText(window.getSelection()?.toString().trim() || ""); }} {...props} />,
    renderHighlightContent: (props) => {
      return highlightAction === PreviewHighlightAction.Comment ?
        <AddCommentPane
          commentOriginType={commentOriginType}
          documentId={fileKey || ""}
          users={mentionUsers}
          onAdded={addComment(dashboardId)}
          dashboardItem={dashboardItem}
          newEditor
          {...props} />
        :
        <AddToDashboardPane
          loading={false}
          documentKey={fileKey || ""}
          keywords={highlightedKeywords}
          page={currentGlobalPage}
          closeDashboardPane={() => { setDashboardPaneOpen(false); setSelectedText(""); }}
          saveType={SaveDashboardItemType.Text}
          highlightData={{ ...props, selectedText: highlightedKeywords.flat().length ? selectedText : props.selectedText }}
        />
    },
    renderHighlights: (props) => <CommentsHighlightArea
      commentAreas={displayHighlightCommentAreas}
      comments={comments}
      selectedComment={selectedComment}
      onUpdate={updateComment}
      onRemove={(id) => { removeComment(id, undefined); }}
      {...props}
    />
  });
  const { jumpToHighlightArea } = highlightPluginInstance;

  let viewerPlugins: any[] = [];

  if (!textOnly && !singlePage) {
    viewerPlugins = [searchPluginInstance, pageNavigationPluginInstance, zoomPluginInstance];
  } else {
    viewerPlugins = [searchPluginInstance, zoomPluginInstance];
  }

  if (enableComments) {
    viewerPlugins.push(highlightPluginInstance);
  }

  useEffect(() => {
    if (!selectedExtendedComment || selectedExtendedComment.documentId !== fileKey) {
      setCurrentPage(page || 0);
      setCurrentGlobalPage(page || 0);
      pageNavigationPluginInstance.jumpToPage(page || 0);
    }
  }, [fileKey, selectedExtendedComment, pageNavigationPluginInstance, page]);

  useEffect(() => {
    if (!usersLoading) {
      const filteredUsers = users.filter(user => user.group === userGroup);
      setMentionUsers(filteredUsers);
    }
  }, [users, usersLoading, userGroup]);

  useEffect(() => {
    if (enableComments && enableCommentsPane) {
      setSelectedComment(undefined);
      setLoadingComments(true);
      getCommentsFunc(fileKey!).then((result: Comment[]) => {
        const filteredComments = result
          .filter((comment: Comment) => !comment.parentId)
          .sort((first, second) => (first.commentedItem[0].pageIndex > second.commentedItem[0].pageIndex) ? 1 :
            ((second.commentedItem[0].pageIndex > first.commentedItem[0].pageIndex) ? -1 : 0));

        setComments(filteredComments.map(element => {
          const newElement = { ...element } as ExtendedComment;
          newElement.children = result.filter((child: Comment) => element.id === child.parentId);
          return newElement;
        }));

        const highlighted = filteredComments
          .map((comment: Comment) => ({
            id: comment.id,
            highlightAreas: comment.commentedItem,
          } as CommentHightlightAreas));

        setHighlightCommentAreas(highlighted);
        setDisplayHighlightCommentAreas(highlighted);
        setLoadingComments(false);
      });
    } else if (enableComments && sourceComments) {
      setComments(sourceComments);
      const highlighted = sourceComments
        .map((comment: Comment) => ({
          id: comment.id,
          highlightAreas: comment.commentedItem,
        } as CommentHightlightAreas));

      setHighlightCommentAreas(highlighted);
      setDisplayHighlightCommentAreas(highlighted);
    }
  }, [enableComments, enableCommentsPane, sourceComments, fileKey]);

  useEffect(() => {
    if (selectedCommentId && highlightCommentAreas.length) {
      const highlightedCommentId = selectedCommentParentId || selectedCommentId;
      const newSelectedComment = highlightCommentAreas.find((highlight: any) => highlight.id === highlightedCommentId);
      setSelectedComment(newSelectedComment);
    } else {
      setSelectedComment(undefined);
    }

  }, [highlightCommentAreas, selectedCommentId, selectedCommentParentId]);

  useEffect(() => {
    if (documentLoaded) {
      if (selectedExtendedComment && selectedComment) {
        setDisplayHighlightCommentAreas(highlightCommentAreas.filter((area: any) => area.id !== selectedComment.id));
        jumpToHighlightArea(selectedComment.highlightAreas[0]);
        // setTimeout(() => {
        //   const docPage = document.getElementsByClassName('rpv-core__inner-pages')[0]
        //   if (docPage) {
        //     docPage.scrollBy({
        //       top: -300,
        //       left: 0,
        //       behavior: "smooth"
        //     });
        //   }
        // }, 100);
      } else {
        setDisplayHighlightCommentAreas(highlightCommentAreas);
      }
    }
    // eslint-disable-next-line
  }, [selectedExtendedComment, selectedComment, documentLoaded]);

  //TODO consider putting this logic outside this component.  This is an implementation detail
  //that we are stuffing into here with all possible options rather than having the calling
  //program/component provide the implementation
  useEffect(() => {
    setFileUrl('');
    if (fileKey && loadFromCacheBucket) {
      const cacheBucket = getCacheBucketName(awsConfig);
      s3DownloadExternalBucket({ key: fileKey, bucket: cacheBucket }).then(setPdfBytes);
    } else if (page && fileKey) { //get the snippet rather than the whole pdf
      const cacheBucket = getCacheBucketName(awsConfig);
      const pg = page + 1 //I'm still confused why we need to increment the page by one.  The page should already be incremented by one, see the process lambda
      const pageFileKey = join(dirname(fileKey), `${basename(fileKey, ".pdf")}_${pg}.pdf`)
      Promise.all([
        s3DownloadExternalBucket({ key: pageFileKey, bucket: cacheBucket }).then(setPdfBytes),
        s3Download({ key: fileKey }).then(setCachedPdf) //download full pdf while displaying snippet.  Should I just always download?  May be quicker than getting the url and then downloading
      ])
    }
    else if (fileKey && !displayFromSource) {
      console.log([fileKey]);
      s3Download({ key: fileKey }).then(setPdfBytes)
        .catch(() => {
          fetch(fileKey).then(data => {
            data.arrayBuffer().then(r => setPdfBytes(new Uint8Array(r)))
          });
        });
    } else if (sourceUrl) {
      setFileUrl(sourceUrl);
    }

  }, [fileKey, page, sourceUrl, displayFromSource, loadFromCacheBucket]);

  useEffect(() => {
    if (page && pageNavigationPluginInstance) {
      pageNavigationPluginInstance.jumpToPage(page);
    }
  }, [page, pageNavigationPluginInstance]);


  const addComment = (dashboardId?: string) => async (item: Comment) => {
    if (enableCommentsPane) {
      setHighlightCommentAreas(highlightCommentAreas.concat([{ id: item.id, highlightAreas: item.commentedItem }]));
      setDisplayHighlightCommentAreas(highlightCommentAreas.concat([{ id: item.id, highlightAreas: item.commentedItem }]));

      const nextCommentIndex = comments.findIndex((comment) => {
        return comment.commentedItem[0].pageIndex >= item.commentedItem[0].pageIndex + 1
      });

      const newComments = [...comments];
      newComments.splice(nextCommentIndex === -1 ? comments.length : nextCommentIndex, 0, item);

      setComments(newComments);
    }
    if (dashboardId) {
      const dashboard = await getDashboardById(dashboardId);
      if (dashboard) {
        updateDashboardFunc({ ...dashboard, updatedAt: new Date() });
      }
    }
    if (onCommentAdded) {
      onCommentAdded(item);
    }
  }

  const updateComment = (item: Comment) => {
    setComments(comments.map(x => {
      if (x.id === item.id) {
        x.text = item.text;
        x.title = item.title;
        x.usersMentioned = item.usersMentioned;
        x.description = item.description;
      } else if (x.children) {
        x.children = x.children.map(reply => {
          if (reply.id === item.id) {
            reply.text = item.text;
            reply.title = item.title;
            reply.usersMentioned = item.usersMentioned;
            reply.description = item.description;
          }

          return reply;
        });
      }

      return x;
    }));

    if (onCommentUpdated) {
      onCommentUpdated(item);
    }
  }

  const createExcelButtons = (e: DocumentLoadEvent) => {
    e.doc.getMetadata().then(meta => {
      const sheetsNamesArray = meta.info.Title && meta.info.Title.split('-pageSizes:')[0] ? meta.info.Title.split('-pageSizes:')[0] : ''
      const sheetsLengthArray = meta.info.Title && meta.info.Title.split('-pageSizes:')[1] ? meta.info.Title.split('-pageSizes:')[1] : ''

      const sheets = sheetsNamesArray.split(',-,') as any;
      const sheetsPages = sheetsLengthArray.split(',-,') as any;
      let sheetNumberPages: number[] = []
      if (sheetsLengthArray !== '') {
        sheetNumberPages = sheetsPages.reduce((previousValue: number[], currentValue: any, index: number) => {
          previousValue.push(parseInt(currentValue) + (index > 0 ? previousValue[index - 1] : 0))
          return previousValue;
        }, []);
      }
      setNumberPages(sheetNumberPages as any);
      setSheetsNames(sheets);
    });
  };

  const removeComment = (commentId: string, parentId: string | undefined) => {
    if (enableCommentsPane) {
      if (parentId) {
        setComments(comments.map((comment: ExtendedComment) => {
          if (comment.id === parentId) {
            comment.children = comment.children?.filter((child: Comment) => child.id !== commentId);
          }
          return comment;
        }));
      }
      setComments(comments.filter((comment: Comment) => comment.id !== commentId));
      setHighlightCommentAreas(highlightCommentAreas.filter((element: any) => element.id !== commentId));
      setDisplayHighlightCommentAreas(highlightCommentAreas.filter((element: any) => element.id !== commentId));
      if (selectedComment?.id === commentId) {
        setSelectedComment(undefined);
      }
    }

    if (onCommentRemoved) {
      onCommentRemoved(fileKey || "", commentId, parentId);
    }
  }

  const defaultScale = filePath?.includes('.xlsx') || filePath?.includes('.xls') ? 1 : SpecialZoomLevel.PageWidth;

  const renderError = (error: LoadError) => {
    console.log(error)
    let message = 'Error loading the document';
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#e53e3e',
            borderRadius: '0.25rem',
            color: '#fff',
            padding: '0.5rem',
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  const handleScreenCapture = (url: string, coordinates: HighlightAreaCoordinates) => {
    setScreenCapture(url);
    setScreenCaptureCoordinates(coordinates);
    setSaveDashboardItem(SaveDashboardItemType.ScreenCapture);
    setScreenCaptureLoading(false);
  }

  const viewerPayload = displayFromSource ?
    fileUrl
    :
    fileUrl || (!showSnippet && cachedPdf)
      ?
      cachedPdf
      :
      pdfBytes;
  const initialPage = page && showSnippet ? getPageForSnippet(page) : currentGlobalPage;
  const highlightPage = page && showSnippet ? getPageForSnippet(page) : page;

  const content = viewerPayload ? (
    <Viewer
      fileUrl={viewerPayload}
      defaultScale={defaultScale}
      initialPage={initialPage} //initialPage is zero based.  But search results are not zero based?
      plugins={viewerPlugins}
      onDocumentLoad={(e) => {
        createExcelButtons(e);
        setDocumentLoaded(true);
        setNumberPdfPages(e.doc.numPages);
        if (onLoaded) {
          onLoaded();
        }
      }}
      onPageChange={({ currentPage: newCurrentPage }) => {
        if (newCurrentPage > 0) {
          setCurrentPage(newCurrentPage);
          if (showSnippet && currentPage) {
            const offset = getPageForSnippet(currentPage);
            const globalPage = currentPage + newCurrentPage - offset;
            setCurrentGlobalPage(globalPage);
          }
          else {
            setCurrentGlobalPage(newCurrentPage);
          }
        }
      }}
      renderError={renderError}
      renderPage={(props) => <CustomPageRender renderPageProps={props} initialPage={highlightPage} />}
    />
  ) : (
    <CircularProgress />
  );

  const folderPathElements = filePath?.split("/");
  folderPathElements?.shift();

  const addCommentReply = (reply: ExtendedComment) => {
    if (enableCommentsPane) {
      setComments(comments.map((comment: ExtendedComment) => {
        if (comment.id === reply.parentId) {
          if (!comment.children) {
            comment.children = [reply];
          } else {
            comment.children.push(reply);
          }
        }
        return comment;
      }));
    }

    if (onCommentReplyAdded) {
      onCommentReplyAdded(reply);
    }
  }

  const fileContainerHeight = (): string => {
    let pathHeight = 18;

    if (filePath?.includes(".xls")) {
      return `calc(90% - ${pathHeight}px)`;
    }

    return `100%`;
  }

  const setDashboardDeck = async (page?: number) => {
    setPinningDeckInProgress(true);
    onSetDashboardDeck && await onSetDashboardDeck({ fileKey: fileKey as string, page });
    setPinningDeckInProgress(false);
  };

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid item xs={showDiscussion && enableComments && enableCommentsPane && !loadingComments && !!comments.length ? 9 : 12} className={classes.filePreviewContainer}>
        {
          !!folderPathElements && enableComments && showCategories &&
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <div className={classes.fileNameContainer}>
                {
                  folderPathElements?.map((e: string, index: number) =>
                    <Typography color="textPrimary" align="center" key={'file-preview-652-' + index}>
                      <span className={classes.fileNameElement}>{e}</span>
                      {index < folderPathElements.length - 1 && <span className={classes.fileNameElement}>{'>'}</span>}
                    </Typography>
                  )
                }
              </div>
            </Grid>
            <Grid item sx={{ marginRight: "10px" }}>
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={onGoToCategories}
              >
                Document Categories
              </Button>
            </Grid>

          </Grid>
        }
        <Box
          height={fileContainerHeight()}
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
          className={classNames({ "file-preview-text-only": textOnly })}
        >
          {showSnippet && currentPage === 0 &&
            <div className={classes.topLoadButton}>
              <Tooltip title="Load full document" placement="top-start">
                <Fab
                  className={classes.iconButton}
                  onClick={() => setShowSnippet(false)}
                >
                  <KeyboardArrowUpIcon />
                </Fab>
              </Tooltip>
            </div>
          }
          {showSnippet && currentPage === (numberPdfPages - 1) &&
            <div className={classes.bottomLoadButton}>
              <Tooltip title="Load full document" placement="top-start">
                <Fab
                  className={classes.iconButton}
                  onClick={() => setShowSnippet(false)}
                >
                  <KeyboardArrowDownIcon />
                </Fab>
              </Tooltip>
            </div>
          }
          {(fileKey || sourceUrl) ? content : <Typography>{placeholder}</Typography>}
          {
            (fileKey || sourceUrl) &&
            <>
              {
                !textOnly &&
                <div className={classes.zoomButtonsContainer}>
                  {
                    enableDashboard &&
                    <>
                      {/* <div className={classes.dashboardButtonContainer}>
                        <Tooltip title="Add page to dashboard" placement="right">
                          <Fab className={classes.iconButton} onClick={() => { setSaveDashboardItem(SaveDashboardItemType.Page); setDashboardPaneOpen(!dashboardPaneOpen); }}>
                            <DashboardIcon />
                          </Fab>
                        </Tooltip>
                      </div> */}
                      <ScreenCaptureFC
                        onEndCapture={handleScreenCapture}
                        onLoadCapture={() => {
                          setScreenCaptureLoading(true);
                          setDashboardPaneOpen(true);
                          setSaveDashboardItem(SaveDashboardItemType.ScreenCapture);
                        }}
                        onExit={() => {
                          setDashboardPaneOpen(false);
                          setScreenCapture("");
                          setScreenCaptureCoordinates(null);
                          setScreenCaptureLoading(false);
                        }}
                      >
                        {({ onStartCapture }: any) => (
                          showAddCrop && <div className={classes.cropButtonContainer}>
                            <Tooltip title="Add crop to dashboard" placement="right">
                              <Fab className={classes.iconButton}
                                onClick={() => {
                                  if (!dashboardPaneOpen) {
                                    onStartCapture();
                                  } else {
                                    setDashboardPaneOpen(false);
                                    setScreenCapture("");
                                    setScreenCaptureCoordinates(null);
                                    setScreenCaptureLoading(false);
                                  }
                                }}>
                                <CropIcon />
                              </Fab>
                            </Tooltip>
                          </div>
                        )}
                      </ScreenCaptureFC>
                    </>
                  }

                  {
                    onSetDashboardDeck && <>
                      <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={pinningDeckInProgress}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                      <Tooltip title="Pin File" placement="left">
                        <Fab onClick={() => { setDashboardDeck() }} className={classes.zoomButton}>
                          <PushPinIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Pin Page" placement="left">
                        <Fab onClick={() => { setDashboardDeck(currentPage + 1) }} className={classes.zoomButton}>
                          <PushPinTwoToneIcon />
                        </Fab>
                      </Tooltip>
                    </>
                  }
                  {
                    showZoomInOut &&
                    <>
                      <ZoomIn>
                        {
                          (props: RenderZoomOutProps) => (
                            <Fab onClick={props.onClick} className={classes.zoomButton}>
                              <AddIcon />
                            </Fab>
                          )
                        }
                      </ZoomIn>
                      <ZoomOut>
                        {
                          (props: RenderZoomOutProps) => (
                            <Fab className={classNames(classes.zoomButton)} onClick={props.onClick}>
                              <RemoveIcon />
                            </Fab>
                          )
                        }
                      </ZoomOut>
                    </>
                  }
                </div>
              }
              {
                openFullScreen &&
                <IconButton
                  className={classes.openIcon}
                  onClick={() => {
                    if (onOpenInFullScreen) {
                      onOpenInFullScreen();
                    }
                  }}
                >
                  <OpenInFullIcon />
                </IconButton>
              }

              {
                !!(dashboardPaneOpen && screenCaptureCoordinates) &&
                <div
                  className={classes.cropHighlightArea}
                  style={{
                    top: `${screenCaptureCoordinates.top}px`,
                    left: `${screenCaptureCoordinates.left}px`,
                    width: `${screenCaptureCoordinates.width}px`,
                    height: `${screenCaptureCoordinates.height}px`
                  }}>
                </div>
              }
              {
                (dashboardPaneOpen || screenCaptureLoading) &&
                <AddToDashboardPane
                  loading={screenCaptureLoading}
                  documentKey={fileKey || ""}
                  closeDashboardPane={() => {
                    setDashboardPaneOpen(false);
                    setScreenCapture("");
                    setScreenCaptureCoordinates(null);
                  }}
                  saveType={saveDashboardItem}
                  keywords={highlightedKeywords}
                  page={currentGlobalPage}
                  screenCapture={screenCapture}
                  // positionTop={saveDashboardItem === SaveDashboardItemType.Page ? "15px" : "80px"}
                  positionTop="25px"
                />
              }
            </>
          }
        </Box>
        {filePath?.includes('.xls') && numberPages.length > 0 && <Box
          height="10%"
          alignItems="center"
          overflow="scroll"
          textAlign="center"
          display="flex"
        >
          {Array.from(Array(numberPages.length).keys()).map(idx =>
            <Button
              variant="outlined"
              color="primary"
              className={currentPage < numberPages[idx] && (idx - 1 < 0 || currentPage >= numberPages[idx - 1])
                ? classes.buttonsExcel : classes.buttonsExcelActive}
              onClick={() => pageNavigationPluginInstance.jumpToPage(idx - 1 >= 0 ? numberPages[idx - 1] : 0)}
              key={'file-preview-865-' + idx}>
              {sheetsNames[idx]}
            </Button>
          )}
        </Box>}
      </Grid>
      {
        enableComments && enableCommentsPane && !loadingComments && !!comments.length &&
        <Grid item xs={3} className={classes.commentsArea}>
          {
            loadingComments ?
              <CircularProgress className={classes.commentsLoader} /> :
              <CommentsArea
                selectedCommentId={selectedCommentId}
                fileKey={fileKey}
                comments={comments}
                setSelectedHighlightAreas={setSelectedComment}
                onCommentRemoved={removeComment}
                onCommentUpdated={onCommentUpdated}
                onReplyAdded={addCommentReply}
                commentOriginType={commentOriginType}
                dashboardItem={dashboardItem}
              />
          }
        </Grid>
      }
    </Grid>
  );
};

export default FilePreview;
