import { v4 as uuidv4 } from "uuid";


export interface IInstanceHelper {
    getId: () => string;
}

class InstanceHelper implements IInstanceHelper {
    private id: string;

    constructor() {
        this.id = uuidv4();
    }

    getId(): string {
        return this.id;
    }
}

const instanceHelper = new InstanceHelper();

export default instanceHelper;