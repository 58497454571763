import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DrivePlatformIcons, DrivePlatformsTitles, getGroupedFolders } from "../utils/folders";
import { CompanyFile } from "../../../types/files";
import FoldersList from "./FoldersList";

const FoldersGroupedList: React.FC<{
    parentId?: string;
    folders: CompanyFile[];
    selectedFolder?: CompanyFile;
    onFolderSelect: (folder: CompanyFile) => void;
    selectedFile?: CompanyFile | null;
    onFileSelect: (file: CompanyFile) => void;
    currentUploadingFolderName: string | null;
    toggleFileDelete?: () => void;
    toggleFolderDelete?: () => void;
    openDeleteFileModal: boolean;
    openDeleteFolderModal: boolean;
    deleteCurrentlySelectedFile?: (file: CompanyFile) => Promise<void>;
    deleteCurrentlySelectedFolder?: (folder: CompanyFile) => Promise<void>;
    showRoot?: boolean
}> = ({ parentId, folders, selectedFolder, onFolderSelect, selectedFile, onFileSelect, currentUploadingFolderName, toggleFileDelete, toggleFolderDelete, openDeleteFileModal, openDeleteFolderModal, deleteCurrentlySelectedFile, deleteCurrentlySelectedFolder, showRoot = true }) => {

    const [rootFolders, setRootFolders] = useState<any[]>([]);

    useEffect(() => {
        setRootFolders(getGroupedFolders(folders, parentId));
    }, [folders, parentId]);

    return (
        <Box width="100%">
            {
                rootFolders.map((folder, i) => (
                    <Box width="100%" key={'folders-grouped-list-34-' + i}>
                        {showRoot && <Box pb={1} display="flex" alignItems="center">
                            <Typography mr={1}>{DrivePlatformsTitles[folder.name]?.toUpperCase()}</Typography>
                            {DrivePlatformIcons[folder.name]}
                        </Box>}
                        <FoldersList
                            folders={folder.folders}
                            parentId={folder.parentId}
                            originalParentId={folder.parentId}
                            selectedFolder={selectedFolder}
                            onFolderSelect={onFolderSelect}
                            selectedFile={selectedFile}
                            onFileSelect={onFileSelect}
                            currentUploadingFolderName={currentUploadingFolderName}
                            openDeleteFileModal={openDeleteFileModal}
                            openDeleteFolderModal={openDeleteFolderModal}
                            toggleFileDelete={toggleFileDelete}
                            toggleFolderDelete={toggleFolderDelete}
                            deleteCurrentlySelectedFile={deleteCurrentlySelectedFile}
                            deleteCurrentlySelectedFolder={deleteCurrentlySelectedFolder}
                        />
                    </Box>
                ))
            }
        </Box>
    )
};

export default FoldersGroupedList;