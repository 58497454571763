import React from "react";
import { Button, Dialog, DialogActions, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as ExportIcon } from "../../../assets/icons/download.svg";
import AddIcon from '@mui/icons-material/Add';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: 500,
            height: 'fit-content',
            padding: 16,
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonCard: {
        width: '100%',
        height: 64,
        paddingLeft: 16,
        justifyContent: 'flex-start',
        border: '1px solid transparent',
        borderRadius: 8,
        background: theme.colors.neutral['50'],
        "&:hover": {
            border: `1px solid ${theme.colors.primary['500']}`
        }
    },
    selectionText: {
        fontFamily: 'Lato',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        textTransform: 'none',
        transition: 'ease-in-out 0.3s',
    },
    commonButton: {
        width: 180,
        borderRadius: 24,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    icon: {
        width: 32,
        height: 32,
        stroke: theme.palette.primary.main,
    },
    stopIcon: {
        width: 24,
        height: 24,
        fill: theme.palette.error.main,
    },
}));

const DashboardsBulkActionModal: React.FC<{
    isOpen: boolean,
    onClose: (mode: string) => void,
}> = ({isOpen, onClose}) => {
    const classes = useStyles();

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose('close')}>
            <DialogTitle className={classes.dialogTitle}>
                {'Bulk Action'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack spacing={2} alignItems="flex-start" justifyContent="center" width="100%">
                    <Stack direction="column" className={classes.buttonCard} spacing={2} height="auto !important" py={2} pr={2} pl={1}>
                        <Typography component={Stack} className={classes.selectionText}
                            direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                            <ExportIcon className={classes.icon} />
                            <span>{'Download as PDFs'}</span>
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="space-around" width="100%">
                            <Button variant="contained"
                                className={classes.commonButton}
                                onClick={() => onClose('export-pdf-zipped')}>
                                {'Separate PDFs'}
                            </Button>
                            <Button variant="contained"
                                className={classes.commonButton}
                                onClick={() => onClose('export-pdf')}>
                                {'Combined PDFs'}
                            </Button>
                        </Stack>
                    </Stack>
                    <Button className={classnames(classes.buttonCard, classes.selectionText)}
                        startIcon={<AddIcon className={classes.icon} />}
                        onClick={() => onClose('add-to-workspace')}>
                        {'Add to investor'}
                    </Button>
                    <Button className={classnames(classes.buttonCard, classes.selectionText)}
                        startIcon={<FiberManualRecordIcon className={classes.stopIcon} />}
                        onClick={() => onClose('stop-sharing')}
                        sx={{ "& > span": { marginLeft: '2px', marginRight: '14px' }}}>
                        {'Stop sharing'}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default DashboardsBulkActionModal;