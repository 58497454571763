import React, { SetStateAction } from "react";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import WorldGridIcon from '@mui/icons-material/Language';
import { useDebouncedCallback } from "use-debounce";

const useStyles = makeStyles((theme) => ({
    form: {
        padding: '16px 4px',
        width: '100%',
    },
    icon: {
        width: 24,
        height: 24,
		fill: theme.palette.primary.main,
    },
    inputField: {
        width: '100%',
        margin: 0,
        "& .MuiOutlinedInput-root": {
            width: '100%',
            height: '40px',
            borderRadius: 32,
            background: '#fff',
            "& > input": {
                fontSize: 16,
            },
            "& fieldset": {
              borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: '#048290 !important',
            },
        },
    },
}));

const WebsiteUrlInput: React.FC<{
	customUrl: string,
  	onCustomUrl: React.Dispatch<SetStateAction<string>>,
  	inlineCaption?: boolean,
 }> = ({ customUrl, onCustomUrl, inlineCaption })=> {
    const classes = useStyles();
    const debounced = useDebouncedCallback(value => onCustomUrl(value.trim()), 30, { maxWait: 100 });

    return (<>
        {!inlineCaption && (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                <WorldGridIcon className={classes.icon} />
                <Typography variant="h6-bold">Enter a website URL</Typography>
            </Stack>
        )}
        <TextField variant="outlined"
            className={classes.inputField}
            placeholder="Enter a website URL..."
			value={customUrl}
            onChange={(e) => debounced(e.target.value as string)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WorldGridIcon className={classes.icon} />
                </InputAdornment>
              ),
            }}
        />
    </>);
}

export default WebsiteUrlInput;