import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, SvgIcon, Typography } from "@mui/material";
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFileIcon } from "../../folders/utils/files";
import { CompanyFile } from "../../../types/files";

const useStyles = makeStyles((theme) => ({
    accordionSummary: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    accordionContent: {
        flexGrow: 'unset',
        overflow: 'hidden',
    },
    accordionDetails: {
        paddingBottom: 0,
        paddingTop: 0,
    },
    accordionContentContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    categoryName: {
        fontWeight: 'bold',
        marginRight: 5,
        color: theme.palette.text.disabled,
    },
    fileNumber: {
        fontSize: 12,
        marginRight: 5,
        whiteSpace: 'nowrap'
    },
    fileNameContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F7F5F2',
        borderRadius: 16,
        padding: '5px 10px',
        margin: 5,
        cursor: 'pointer',
    },
    selected: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.primary.main,
    },
    fileName: {
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 1,
        padding: 5,
        whiteSpace: 'nowrap',
    },
    accordion: {
        boxShadow: 'none',
        margin: '0 !important',
        "&::before": {
            opacity: 0,
        },
    }
}));

interface Props {
    category: string,
    expanded: boolean,
    setExpanded: (newExpanded: string) => void;
    onFileSelect: (file: CompanyFile | null) => void;
    files: CompanyFile[] | null | undefined;
    selectedFile: CompanyFile | null | undefined;
}

const DashboardCategoryAccordion = ({
    category,
    expanded,
    setExpanded,
    onFileSelect,
    files,
    selectedFile,
}: Props) => {
    const classes = useStyles();
    const [file, setFile] = useState<CompanyFile | null | undefined>();

    useEffect(() => {
        if (selectedFile !== file) {
            setFile(selectedFile);
        }
        // eslint-disable-next-line
    }, [selectedFile]);

    return (
        <Accordion className={classes.accordion} onChange={() => setExpanded(category)} expanded={expanded}>
            <AccordionSummary
                className={classes.accordionSummary}
                classes={{
                    content: classes.accordionContent,
                }}
                expandIcon={<ExpandMoreIcon />}
                id={category}
            >
                <Box className={classes.accordionContentContainer}>
                    <Typography className={classes.categoryName}>{category}</Typography>
                    {files && <Typography className={classes.fileNumber}>{`(${files.length} files)`}</Typography>}
                </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {
                    files?.map((e: CompanyFile, i) => {
                        return <Box className={classNames([classes.fileNameContainer, e.name === file?.name && classes.selected])} onClick={() => {
                            setFile(e);
                            onFileSelect(e);
                        }}
                        key={'dashboard-category-accordion-116-' + i}>
                            <SvgIcon>
                                {getFileIcon(e.name || "")}
                            </SvgIcon>
                            <Typography className={classes.fileName}>{e.name}</Typography>
                        </Box>
                    })
                }
            </AccordionDetails>
        </Accordion>
    );
};

export default DashboardCategoryAccordion;
