import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { scrollbarStyle } from '../../../shared/dashboard';
import FileDropzone from './FileDropzone';
import useFileUploader from '../../../hooks/useFileUploader';
import { Box, Stack, Typography } from '@mui/material';
import { Dashboard } from '../../../types/files';

const useStyles = makeStyles(() => ({
    container: {
        width: 400,
        height: '100%',
    },
    content: {
        width: '100%',
        height: 365,
        padding: 0,
        border: '1px solid #048290',
        background: 'rgb(240,254,254)',
        borderRadius: 8,
        ...scrollbarStyle,
    },
}));

const DEF_VALID_FILE = { maxFileSize: 100 * 1024 * 1024, allowedFileTypes: ['application/pdf'], };

const FileUploader: React.FC<{ dashboard?: Dashboard|null }> = ({ dashboard }) => {
    const classes = useStyles();
    const { files, hasUploaded, uploadFiles, excludeFile } = useFileUploader({
        validFile: DEF_VALID_FILE,
        validPath: !!dashboard ? [dashboard.id, dashboard.title].join("/") : undefined,
    });

    if (!dashboard)
        return (<></>);

    return (
        <Box className={classes.container}>
            <Stack className={classes.content} alignItems="center" justifyContent="center">
                <FileDropzone extFiles={files} validFile={DEF_VALID_FILE} onUpdate={uploadFiles} onDelete={excludeFile} />
            </Stack>
            <Stack alignItems="center" justifyContent="center" width="100%" mt={2}>
                {hasUploaded && (<>
                    <Typography fontSize="1rem" color="#666666">{'Your file(s) will appear on the dashboard shortly'}</Typography>
                    <Typography fontSize="1rem" color="#666666">{'(may take up to 1 min).'}</Typography>
                </>)}
            </Stack>
        </Box>
    );
}

export default FileUploader;