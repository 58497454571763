import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Backdrop, Button, CircularProgress, Dialog, Divider, Grid, Typography } from "@mui/material";
import { Drive, SiteDrive, GroupDrive, IntegrationSelectionOptions, integration, integrationFriendlyNames, DropboxFolderDrive } from "../../../types/integrations";
import { ReactComponent as BoxIcon } from "../../../assets/icons/boxNew.svg";
import { ReactComponent as OneDriveIcon } from "../../../assets/icons/onedrive.svg";
import { ReactComponent as DropboxIcon } from "../../../assets/icons/dropbox.svg";
import { ReactComponent as GoogleDriveIcon } from "../../../assets/icons/google-drive.svg";
import { CompanyFile } from "../../../types/files";
import { listIntegrationSubfoldersFunc } from '../../../lib/helper';
import FoldersList from "../../folders/components/FoldersList";

const IntegrationSelectionModal: React.FC<{
    isOpen: boolean,
    isLoading: boolean,
    options: IntegrationSelectionOptions | undefined,
    integrationToConnect: integration | undefined,
    onSelected: (drive: DropboxFolderDrive, integrationToConnect: integration) => void,
    onClose: () => void
}> = ({ isOpen, isLoading, options, integrationToConnect, onSelected, onClose }) => {

    const { userGroup } = useContext(AuthContext);

    const [folders, setFolders] = useState<CompanyFile[]>([]);
    const [expandedFolder, setExpandedFolder] = useState<CompanyFile | undefined>();
    const [selectedFolder, setSelectedFolder] = useState<CompanyFile | undefined>();
    const [pathsRetrieved, setPathsRetrieved] = useState<string[]>([]);
    const [loadingSubfolders, setLoadingSubfolders] = useState<boolean>(false);

    useEffect(() => {
        if (options) {
            let newFolders: CompanyFile[] = [];

            if (options.drives?.length) {
                options.drives.forEach((drive: Drive) => {
                    if (drive) {
                        const path = `${drive.driveId}/root`;

                        newFolders.push({
                            id: path,
                            name: drive.driveName,
                            rootFolderName: drive.driveName,
                            key: path,
                            parentId: 'root',
                            documentCategory: 'Drive',
                            isDirectory: true,
                            path,
                        });
                    }
                });
            }

            if (options.siteDrives?.length) {
                options.siteDrives.forEach((site: SiteDrive) => {
                    if (site) {
                        const path = `${site.driveId}/root`;
                        const name = `${site.siteName} - ${site.driveName}`;

                        newFolders.push({
                            id: path,
                            name: name,
                            rootFolderName: name,
                            key: path,
                            parentId: 'root',
                            documentCategory: 'SiteDrive',
                            isDirectory: true,
                            path,
                        });
                    }
                });
            }

            if (options.groupDrives?.length) {
                options.groupDrives.forEach((groupDrive: GroupDrive) => {
                    if (groupDrive) {
                        const path = `${groupDrive.driveId}/root`;
                        const name = `${groupDrive.groupName} - ${groupDrive.driveName}`;

                        newFolders.push({
                            id: path,
                            name,
                            rootFolderName: name,
                            key: path,
                            parentId: 'root',
                            documentCategory: 'GroupDrive',
                            isDirectory: true,
                            path,
                        });
                    }
                });
            }

            if (options.namespaces?.length) {
                options.namespaces.forEach(x => {
                    if (x) {
                        if (x.children) {
                            newFolders.push(...x.children);
                        }
                    }
                });
            }

            setFolders(newFolders);
        }
    }, [options]);

    const selectFolder = () => {
        if (!selectedFolder) {
            return;
        }

        onSelected({
            path: selectedFolder.path,
            rootFolderName: selectedFolder.rootFolderName,
            id: selectedFolder.id,
        }, integrationToConnect as integration);

        setExpandedFolder(undefined);
        setSelectedFolder(undefined);
        setPathsRetrieved([]);
        setFolders([]);
    }

    const friendlyName = integrationToConnect ? integrationFriendlyNames[integrationToConnect] : 'integration';

    const integrationIcons = {
        [integration.BOX]: <BoxIcon style={{ width: 50, height: 50 }} />,
        [integration.DROPBOX]: <DropboxIcon style={{ width: 50, height: 50 }} />,
        [integration.GOOGLE]: <GoogleDriveIcon style={{ width: 50, height: 50 }} />,
        [integration.MICROSOFT]: <OneDriveIcon style={{ width: 50, height: 50 }} />,
    };

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '50%',
                    height: '75%',
                    position: 'relative',
                    overflow: 'unset',
                    borderRadius: "10px"
                }
            }}
            maxWidth="xs"
            open={isOpen}
            onBackdropClick={(e) => { e.preventDefault(); e.stopPropagation(); onClose(); }}
            onClose={() => { onClose(); }}
        >
            {
                isLoading ?
                    <Grid container flexDirection="column" height="100%" justifyContent="center" alignItems="center">
                        <Grid item p={2}>
                            {integrationToConnect ? integrationIcons[integrationToConnect] : <></>}
                        </Grid>
                        <Grid item p={3}>
                            <Typography variant="h5">Connecting to your {friendlyName} account...</Typography>
                        </Grid>
                        <Grid item>
                            <CircularProgress size={60} />
                        </Grid>
                    </Grid>
                    :
                    <Grid container flexDirection="column" height="100%">
                        <Grid item display="flex" alignItems="center" justifyContent="center" p={1}>
                            {integrationToConnect ? integrationIcons[integrationToConnect] : <></>}
                        </Grid>
                        <Grid item display="flex" alignItems="center" justifyContent="center" p={1}>
                            <Typography variant="h5">Select where your files will be uploaded:</Typography>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item py={1} px={3} height="calc(100% - 120px)" overflow="auto" mr={1}>
                            {
                                <Grid container height="100%" flexDirection="column">
                                    <Grid item height="calc(100% - 50px)" overflow="auto">
                                        <Backdrop
                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                            open={loadingSubfolders}
                                        >
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                        <FoldersList
                                            folders={folders}
                                            selectedFolder={expandedFolder}
                                            onFileSelect={() => { }}
                                            onFolderSelect={async (newFolder) => {
                                                // Prevent fetching the same subfolder path multiple times
                                                // to avoid duplicate items in folders
                                                if (integrationToConnect && !pathsRetrieved.includes(newFolder.path)) {
                                                    setLoadingSubfolders(true);

                                                    const newPaths = pathsRetrieved.slice();
                                                    newPaths.push(newFolder.path);

                                                    const newFolders = folders.slice();
                                                    let additionalFolders = await listIntegrationSubfoldersFunc({
                                                        group: userGroup,
                                                        integration: integrationToConnect,
                                                        path: newFolder.path,
                                                        parentId: newFolder.id,
                                                    });

                                                    if (newFolder.rootFolderName) {
                                                        additionalFolders = additionalFolders.map((folder: CompanyFile) => {
                                                            folder.rootFolderName = newFolder.rootFolderName;

                                                            return folder;
                                                        });
                                                    }

                                                    newFolders.push(...additionalFolders);

                                                    setPathsRetrieved(newPaths);
                                                    setFolders(newFolders);
                                                    setLoadingSubfolders(false);
                                                }

                                                if (newFolder?.id === expandedFolder?.id) {
                                                    const parent = folders.find(x => x.id === newFolder.parentId);
                                                    if (parent) {
                                                        setExpandedFolder(parent);
                                                    } else {
                                                        setExpandedFolder(undefined);
                                                    }
                                                } else {
                                                    setExpandedFolder(newFolder);
                                                }
                                            }}
                                            selectedUploadFolders={selectedFolder ? [selectedFolder] : undefined}
                                            onSelectUploadFolder={(newFolder) => {
                                                if (newFolder?.id === selectedFolder?.id) {
                                                    setSelectedFolder(undefined);
                                                } else {
                                                    setSelectedFolder(newFolder);
                                                }
                                            }}
                                            openDeleteFileModal={false}
                                            openDeleteFolderModal={false}
                                            currentUploadingFolderName={null}
                                            allowMultipleSelection={true}
                                        />
                                    </Grid>
                                    <Grid item display="flex" justifyContent="flex-end" pt={1}>
                                        <Button variant="contained" disabled={!selectedFolder} onClick={selectFolder}>Save</Button>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
            }
        </Dialog>
    )

}

export default IntegrationSelectionModal;