import React, { useCallback, useState } from 'react';
import { Collapse, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 32,
        height: 32,
        transition: 'transform .5s',
    },
    sectionName: {
        fontFamily: 'Inter !important',
        fontSize: '0.9rem',
        color: 'gray',
    },
    content: {
        fontFamily: 'Inter !important',
        fontSize: '0.9rem',
        color: '#666666',
    },
}));

const CollapsableKeyPeople: React.FC<{ rawHTML: string }> = ({ rawHTML }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    const handleCollapse = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setIsExpanded((prev) => !prev);
    }, []);

    return (<>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
            <Typography className={classes.sectionName}>
                {'Professional background'}
            </Typography>
            <IconButton className={classes.icon} onClick={handleCollapse}
                sx={{ transform: `rotate(${isExpanded ? 90 : 0}deg)` }}>
                <AddIcon />
            </IconButton>
        </Stack>
        <Collapse in={isExpanded}>
            <Typography className={classes.content} dangerouslySetInnerHTML={{ __html: `${rawHTML}`}} />
        </Collapse>
    </>);
}

export default CollapsableKeyPeople;
