import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import Selector from "../../molecules/market-insights/Selector";
import { makeStyles } from "@mui/styles";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const useStyles = makeStyles((theme) => ({
    applyButton: {
        height: 36,
        minWidth: 100,
        borderRadius: 32,
        padding: '6px 20px',
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'none',
    },
}));

const MarketInsightsSelectors: React.FC<{
    flags?: any,
    tags?: string[],
    stages?: string[],
    period?: string[],
    onSelectTags?: (selection: string[]) => void,
    onSelectStages?: (selection: string[]) => void,
    onSelectPeriod?: (selection: number) => void,
    onApplyFilter?: () => void,
}> = ({flags, tags, stages, period, onSelectTags, onSelectStages, onSelectPeriod, onApplyFilter}) => {
    const classes = useStyles();
    const [withTag, setWithTag] = useState<boolean>(false);
    const [withStage, setWithStage] = useState<boolean>(false);
    const [withPeriod, setWithPeriod] = useState<boolean>(false);

    return (<>
        <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="center">
            {!!tags?.length && (
                <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%">
                    <Typography variant="h6-bold" minWidth="fit-content" mr="16px">Select tags:</Typography>
                    <Selector options={tags} disableSelection={flags.marketInsightsPlan === false}
                        onSelection={selected => {
                            const allowed = selected.length <= 3;
                            if (allowed)
                                onSelectTags?.([...selected]);
                            setWithTag(true);

                            return allowed;
                        }} />
                </Stack>
            )}
            {!!stages?.length && (
                <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%">
                    <Typography variant="h6-bold" minWidth="fit-content" mr="16px">Select stages:</Typography>
                    <Selector options={stages} disableSelection={flags.marketInsightsPlan === false}
                        onSelection={selected => {
                            onSelectStages?.([...selected]);
                            setWithStage(true);
                            return true;
                        }} />
                </Stack>
            )}
            {!!period?.length && (
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Stack direction="row" alignItems="center" justifyContent="flex-start">
                        <Typography variant="h6-bold" minWidth="fit-content" mr="16px">Select period:</Typography>
                        <Selector options={period} singleSelect disableSelection={flags.marketInsightsPlan === false}
                            onSelection={selected => {
                                onSelectPeriod?.(Number(selected[0].replace(/[^0-9]/g, '')));
                                setWithPeriod(true);
                                return true;
                            }} />
                    </Stack>
                    {flags.marketInsightsPlan && (
                        <Button
                            variant="contained"
                            className={classes.applyButton}
                            onClick={() => {
                                onApplyFilter?.();
                                setWithTag(false);
                                setWithStage(false);
                            }}
                            disabled={!withPeriod || (!withTag && !withStage)}
                        > Apply </Button>
                    )}
                </Stack>
            )}
        </Stack>
    </>);
}

export default withLDConsumer()(MarketInsightsSelectors);