import React, { useCallback, useState } from "react";
import { Button, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from '@mui/icons-material/Add';
import { Dashboard } from "../../types/files";
import DashboardNoteDialog from "../modals/dashboard-notes/DashboardNoteDialog";
import DashboardAddDataModal from "../modals/dashboard-details/DashboardAddDataModal";
import FileUploaderModal from "../modals/dashboard-details/FileUploaderModal";
import { DEF_VALID_FILE } from "./dashboards/DashboardFileUploader";
const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: 120,
        width: 'auto',
        height: 'fit-content',
        borderRadius: 32,
        border: `1px solid ${theme.colors.primary['200']}`,
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    addIcon: {
        height: 24,
        width: 24,
        color: theme.palette.primary.main,
    },
}));

const AddDataButton: React.FC<{ dashboard?: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const [addDataModalOpen, setAddDataModalOpen] = useState<boolean>(false);
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState<boolean>(false);
    const [noteDialogOpen, setNoteDialogOpen] = useState<boolean>(false);

    const handleAction = useCallback((mode: string) => {
        switch (mode) {
            case 'file-upload':
                setFileUploadModalOpen(true);
                break;
            case 'note-dialog':
                setNoteDialogOpen(true);
                break;
            case 'close':
            default:
                break;
        }
        setAddDataModalOpen(false);
    }, []);

    if (!dashboard)
        return (<></>);

    return (<>
        <Button variant="outlined"
            className={classes.button}
            startIcon={<AddIcon className={classes.addIcon} />}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAddDataModalOpen(true);
            }}
        >{`Add data...`} </Button>
        <DashboardAddDataModal
            isOpen={addDataModalOpen}
            dashboard={dashboard}
            onClose={handleAction} />
        <FileUploaderModal
            open={fileUploadModalOpen}
            validFile={DEF_VALID_FILE}
            validPath={!!dashboard ? [dashboard.id, dashboard.title].join("/") : undefined}
            onClose={() => setFileUploadModalOpen(false)}
        />
        <DashboardNoteDialog
            preselectedDashboard={dashboard}
            isOpen={noteDialogOpen}
            onClose={() => setNoteDialogOpen(false)} />
    </>);
};

export default AddDataButton;
