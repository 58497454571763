import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, useForm } from 'react-hook-form';
import Modal from '../../../components/templates/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'relative',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  button: {
    display: 'block',
    margin: `${theme.spacing(2)} auto 0`,
    color: theme.palette.text.primary,
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  inputLabel: {
    color: theme.palette.text.primary,
  },
}));

interface Props {
  onClose: () => void;
}

const FolderCreationModal = ({ onClose }: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' });

  const classes = useStyles();

  const onSubmit = handleSubmit((formData) => {
    console.log(formData); // TODO
    setValue('name', '');
  });

  return (
    <Modal className={classes.modal} open onClose={onClose}>
      <>
        <IconButton className={classes.closeIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <form onSubmit={onSubmit}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            defaultValue=""
            render={({
              field: { value, onChange },
              fieldState: { invalid },
            }) => (
              <TextField
                variant="standard"
                value={value}
                label="Folder name"
                helperText={errors.name?.message}
                error={invalid}
                onChange={onChange}
                fullWidth
                InputLabelProps={{ className: classes.inputLabel }}
              />
            )}
          />
          <Button className={classes.button} type="submit" variant="contained">
            Create
          </Button>
        </form>
      </>
    </Modal>
  );
};

export default FolderCreationModal;
