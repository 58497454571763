
declare module "@mui/material/styles" {
  interface TypographyVariants {
    "b1-regular": React.CSSProperties;
    "b1-semibold": React.CSSProperties;
    "b2-bold": React.CSSProperties;
    "b2-regular": React.CSSProperties;
    "b2-semibold": React.CSSProperties;
    "b3-regular": React.CSSProperties;
    "b3-semibold": React.CSSProperties;
    "body-small-bold": React.CSSProperties;
    "h4-bold": React.CSSProperties;
    "h5-bold": React.CSSProperties;
    "h6-bold": React.CSSProperties;
    "subtitle-bold": React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    "b1-regular"?: React.CSSProperties;
    "b1-semibold"?: React.CSSProperties;
    "b2-bold"?: React.CSSProperties;
    "b2-regular"?: React.CSSProperties;
    "b2-semibold"?: React.CSSProperties;
    "b3-regular"?: React.CSSProperties;
    "b3-semibold"?: React.CSSProperties;
    "body-small-bold"?: React.CSSProperties;
    "h4-bold"?: React.CSSProperties;
    "h5-bold"?: React.CSSProperties;
    "h6-bold"?: React.CSSProperties;
    "subtitle-bold"?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    "b1-regular": true;
    "b1-semibold": true;
    "b2-bold": true;
    "b2-regular": true;
    "b2-semibold": true;
    "b3-regular": true;
    "b3-semibold": true;
    "body-small-bold": true;
    "h4-bold": true;
    "h5-bold": true;
    "h6-bold": true;
    "subtitle-bold": true;
  }
}

const color = "#111827";
const fontFamily = 'Inter'
const lineHeight = "155%" // 18.6px

const TYPOGRAPHY = {

  "b1-regular": {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight,
    fontFamily
  },

  "b1-semibold": {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight,
    fontFamily
  },

  "b2-regular": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight,
    fontFamily
  },

  "b2-bold": {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight,
    fontFamily
  },

  "b2-semibold": {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight,
    fontFamily,

  },

  "b3-regular": {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight,
    fontFamily
  },

  "b3-semibold": {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight,
    fontFamily
  },

  "body-small-bold": {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight,
    color,
    fontFamily
  },
  "h4-bold": {
    fontSize: "34px",
    fontWeight: "700",
    lineHeight,
    letterSpacing: "-0.02em",
    color,
    fontFamily: "Inter",
  },
  "h5-bold": {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "37.2px",
    letterSpacing: "-0.02em",
    fontFamily
  },

  "h6-bold": {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "37.2px",
    letterSpacing: "-0.02em",
    fontFamily
  },

  "subtitle-bold": {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "27.9px",
    fontFamily
  }

};


export default TYPOGRAPHY;
