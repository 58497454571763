import React, { useState } from "react";
import { Chip, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classNames from "classnames";
import { scrollbarStyle } from "../../../shared/dashboard";

const useStyles = makeStyles((theme) => ({
    chip: {
        minWidth: "fit-content",
        padding: "8px",
        borderRadius: 24,
        background: "#E0F1F1",
        fontSize: '12px',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        color: theme.palette.primary.main,
        "&.MuiChip-root > span": {
            padding: '8px !important',
        },
        "&:hover" : {
            background: "rgba(4, 130, 144, 0.04)",
        },
        cursor: "pointer",
    },
    selectedChip: {
        background: theme.palette.primary.main,
        color: "#fff",
        "&:hover" : {
            background: "rgb(2, 91, 100)",
        },
    },
    denySelectChip: {
        cursor: "not-allowed",
    },
    optionRow: {
        position: 'relative',
        width: '100%',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        ...scrollbarStyle,
    },
}));

const Selector: React.FC<{
    options: string[],
    singleSelect?: boolean,
    disableSelection?: boolean,
    onSelection: (selectedOptions: string[]) => boolean,
}> = ({ options, singleSelect, disableSelection, onSelection }) => {
    const classes = useStyles();
    const [optionsLimitReached, setOptionsLimitReached] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const handleSelect = (option: string) => {
        let newOptions = [];

        if (disableSelection)
            return;

        if (singleSelect)
            newOptions = [option];
        else if (selectedOptions.includes(option))
            newOptions = selectedOptions.filter(selection => selection !== option);
        else
            newOptions = [...selectedOptions, option];

        if (onSelection(newOptions)) {
            setSelectedOptions(newOptions);
            setOptionsLimitReached(false);
        } else {
            setOptionsLimitReached(true);
        }
    }

    return (<>
        <Stack direction="row" className={classes.optionRow} spacing={1} alignItems="center" justifyContent="flex-start">
            {options.map((option, i) => (
                <Chip className={classNames(
                    classes.chip,
                    selectedOptions.includes(option) && classes.selectedChip,
                    (disableSelection || (optionsLimitReached && !selectedOptions.includes(option))) && classes.denySelectChip,
                )} label={option} onClick={() => handleSelect(option)} key={'selector-73-' + i} />
            ))}
        </Stack>
    </>);
}

export default Selector;