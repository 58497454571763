import { useEffect, useState, useContext } from 'react';
import { CompanyFile } from '../../../types/files';
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import path from "path"
import { AuthContext } from '../../../contexts/AuthContext';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import ProviderContext, { useAppContext } from '../../../contexts/SharepointContext';
import { ReactComponent as SharePointIcon } from "../../../assets/icons/microsoft-sharepoint.svg";
import { MsalProvider } from '@azure/msal-react'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult
} from '@azure/msal-browser';

import { getDocumentStrorageFromSharepoint, getFileListFromSharepoint, getSubFolderFromSharepoint } from '../../../services/graphService';
import FoldersList from './FoldersList';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import Modal from '../../../components/templates/Modal';

export interface SharepointFile extends CompanyFile {
    driveId: string;
}

const iterateOverSubFolders = async (
    provider: any, siteId: string, driveId: string,
    rootFolders: any, parent: string | null,
    folders: SharepointFile[],
    cb: (folder: any, driveId: string, parent: string | null) => SharepointFile
) => {
    return Promise.all(rootFolders.map((folder: any) => {
        folders.push(cb(folder, driveId, parent))

        if (!folder.file) {
            const parent = folder.parentReference.path.split("root:")[1]
            const currDir = path.join("/", parent, folder.name)
            return getSubFolderFromSharepoint(provider, siteId, driveId, currDir).then(res => {
                return iterateOverSubFolders(provider, siteId, driveId, res, currDir, folders, cb)
            })
        }
        return Promise.resolve()
    }))
}

const convertFolderToCompanyFile: (folder: any, driveId: string, parent: string | null) => SharepointFile = (folder: any, driveId: string, parent: string | null) => {
    const companyFilePath = path.join(parent || "/", folder.name)
    return {
        id: companyFilePath,
        name: folder.name,
        key: companyFilePath, //shouldn't matter
        documentCategory: "dummy", //not needed
        isDirectory: !folder.file,
        path: companyFilePath,
        driveId,
        parentId: parent || "root" //see FoldersList
    }
}

const getAllFilesAndFoldersFromSP = async (provider: AuthCodeMSALBrowserAuthenticationProvider, siteId: string) => {
    const folders: SharepointFile[] = []
    await getDocumentStrorageFromSharepoint(provider, siteId).then(results => {

        return Promise.all(results.map((result: { id: string, name: string }) => {
            return getFileListFromSharepoint(provider, siteId, result.id).then(lists => {
                return iterateOverSubFolders(provider, siteId, result.id, lists, null, folders, convertFolderToCompanyFile)

            })
        }))
    })
    return folders
}

interface Props {
    stagingBucket: string;
    currentFolder?: CompanyFile;
    onClose: () => void;
    sharepointLoading: boolean;
    uploadFiles: (
        provider: AuthCodeMSALBrowserAuthenticationProvider,
        siteId: string,
        filesToUpload: SharepointFile[],
        userGroup: string,
        location: string,
        stagingBucket: string
    ) => void;
}

const FileSPWrapper = ({
    currentFolder,
    onClose,
    stagingBucket,
    sharepointLoading,
    uploadFiles,
}: Props) => {
    // <MsalInstanceSnippet>
    const msalInstance = new PublicClientApplication({
        auth: {
            clientId: process.env.REACT_APP_SHAREPOINT_CLIENT_ID || "123",
            redirectUri: window.location.host.includes("localhost") ? `http://${window.location.host}` : `https://${window.location.host}`
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: true
        }
    });

    // Check if there are already accounts in the browser session
    // If so, set the first account as the active account
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            // Set the active account - this simplifies token acquisition
            const authResult = event.payload as AuthenticationResult;
            msalInstance.setActiveAccount(authResult.account);
        }
    });
    return <MsalProvider instance={msalInstance}>
        <ProviderContext>
            <FileUploadSPModal sharepointLoading={sharepointLoading} uploadFiles={uploadFiles} currentFolder={currentFolder} onClose={onClose} stagingBucket={stagingBucket} />
        </ProviderContext>
    </MsalProvider>
}

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'relative',
        padding: theme.spacing(3),
        width: "600px",
        height: "500px",
        borderRadius: "20px"
    },
    button: {
        color: theme.palette.text.primary,
    },
    closeIcon: {
        position: 'absolute',
        top: -12,
        right: -30,
        color: theme.palette.common.white,
    },
    input: {
        display: 'none',
    },
    folderName: {
        fontWeight: 'bold',
    },
    inputLabelContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    label: {
        position: 'unset',
        color: theme.palette.text.primary,
        width: '50%'
    },
    inputField: {
        width: '50%'
    },
    signInLogOutButton: {
        marginTop: 20,
        borderRadius: "20px"
    },
    contentContainer: {
        height: "100%",

    },
    actionButtonsContainer: {
        height: 40,
        display: "flex",
        justifyContent: "end",
    },
    headerContainer: {
        width: "100%"
    },
    sharepointIcon: {
        height: 52,
        width: 52,
        margin: 10,
    },
    titleContainer: {
        selfAlign: "center",
        width: "calc(100% - 165px)"
    },
    foldersContainer: {
        height: "calc(100% - 136px)",
        marginTop: 10,
        marginBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        overflow: "auto",
    },
    loaderCotainer: {
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    }
}));

const FileUploadSPModal = ({
    currentFolder,
    onClose,
    stagingBucket,
    sharepointLoading,
    uploadFiles,
}: Props) => {
    const location = currentFolder?.path || ""
    const { userGroup } = useContext(AuthContext);
    const [folders, setFolders] = useState<SharepointFile[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedFolder, setSelectedFolder] = useState<CompanyFile | undefined>(undefined);
    const [selectedUploadFolders, setSelectedUploadFolders] = useState<CompanyFile[]>([]);
    const classes = useStyles();
    const app = useAppContext();

    useEffect(() => { setLoading(sharepointLoading) }, [sharepointLoading]);

    useEffect(() => {
        if (app.siteId) {
            getAllFilesAndFoldersFromSP(app.authProvider!, app.siteId).then(setFolders)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app.siteId])

    const getUploadFiles = () => {
        let uploadFiles: SharepointFile[] = [];
        selectedUploadFolders.forEach((uploadFolder) => {
            const newFolders = folders.filter(folder => !folder.isDirectory && folder.id.startsWith(uploadFolder.id));
            uploadFiles = uploadFiles.concat(newFolders);
        });
        return uploadFiles;
    }

    const upload = () => {
        const files = getUploadFiles();
        uploadFiles(app.authProvider!, app.siteId!, files, userGroup, location, stagingBucket);
    }

    return <Modal className={classes.modal} open onClose={onClose}>
        <>
            <IconButton className={classes.closeIcon} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <AuthenticatedTemplate>
                <Grid container direction="column" className={classes.contentContainer}>
                    <Grid item className={classes.headerContainer}>
                        <Grid container className={classes.headerContainer}>
                            <Grid item className={classes.titleContainer}>
                                <Typography variant="h5">FILES IN SHAREPOINT</Typography>
                                <Typography variant="body1">Select the files you'd like to upload:</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" className={classes.signInLogOutButton} onClick={app.signOut!}>Log Out</Button>
                            </Grid>
                            <Grid item>
                                <SharePointIcon className={classes.sharepointIcon} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.foldersContainer}>
                        {folders.length === 0 ?
                            <div className={classes.loaderCotainer}>
                                <CircularProgress />
                            </div> :
                            <FoldersList
                                folders={folders}
                                selectedFolder={selectedFolder}
                                onFileSelect={(newFolder) => {
                                    if (newFolder === selectedFolder) {
                                        setSelectedFolder(undefined);
                                    } else {
                                        setSelectedFolder(newFolder);
                                    }
                                }}
                                onFolderSelect={(newFolder) => {
                                    if (newFolder === selectedFolder) {
                                        setSelectedFolder(undefined);
                                    } else {
                                        setSelectedFolder(newFolder);
                                    }
                                }}
                                selectedUploadFolders={selectedUploadFolders}
                                onSelectUploadFolder={(newFolder) => {
                                    if (selectedUploadFolders.find(e => e.id === newFolder.id)) {
                                        setSelectedUploadFolders(selectedUploadFolders.filter(e => e.id !== newFolder.id));
                                    } else {
                                        setSelectedUploadFolders([...selectedUploadFolders, newFolder]);
                                    }
                                }}
                                openDeleteFileModal={false}
                                openDeleteFolderModal={false}
                                currentUploadingFolderName={null}
                                allowMultipleSelection={true}
                            />
                        }
                    </Grid>
                    <Grid item className={classes.actionButtonsContainer}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                {loading &&
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography>Upload in progress...</Typography>
                                        <Typography variant="caption">You can close this window or wait for the upload to finish.</Typography>
                                    </Box>}
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={upload} disabled={loading}>Upload</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Grid container justifyContent="center" alignContent="center" height="100%" textAlign="center">
                    <Grid item xs={12}>
                        <Typography variant="h5">Sign into Sharepoint to access files</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button className={classes.signInLogOutButton} onClick={app.signIn!} variant="contained">Sign in here</Button>
                    </Grid>
                </Grid>
            </UnauthenticatedTemplate>
        </>
    </Modal>

};

export default FileSPWrapper;
