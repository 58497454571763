import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Fade, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getActivityFeedEventIcon } from '../atoms/activityFeed';
import { ArticleCategory } from '../../types/search';
import { AnsweredQuestion } from '../../contexts/DashboardQueriesContext';

const useStyles = makeStyles((theme) => ({
    urlIcon: {
        width: '24px',
        height: '24px',
        borderRadius: '8px',
    },
}));

const parseRelativeTime = (relativeTime: string, timeStamp: Date) => {
    const currentDate = new Date();
    const year = timeStamp.getFullYear();
    const month = timeStamp.getMonth();
    const day = timeStamp.getDate();
    const hour = timeStamp.getHours();

    let elapsed = new Date();
    let timeFactor = parseInt(relativeTime);
    let timeDiff = 0;
    let format = "#$%";

    if (relativeTime.endsWith("yr")) {
        elapsed = new Date(currentDate.getTime() - new Date(year - timeFactor, month, day, hour).getTime());
    } else if (relativeTime.endsWith("mo")) {
        elapsed = new Date(currentDate.getTime() - new Date(year, month - timeFactor, day, hour).getTime());
    } else if (relativeTime.endsWith("w")) {
        elapsed = new Date(currentDate.getTime() - new Date(year, month, day - (7 * timeFactor), hour).getTime());
    } else if (relativeTime.endsWith("d")) {
        elapsed = new Date(currentDate.getTime() - new Date(year, month, day - timeFactor, hour).getTime());
    } else if (relativeTime.endsWith("h")) {
        elapsed = new Date(currentDate.getTime() - new Date(year, month, day, hour - timeFactor).getTime());
    } else {
        elapsed = new Date(currentDate.getTime() - new Date(year, month, day, hour).getTime());
    }

    timeDiff = (elapsed.getUTCFullYear() - 1970);
    if (timeDiff > 0) {
        return format.replace("#", `${timeDiff}`).replace("$%", "yr");
    }

    timeDiff = elapsed.getMonth();
    if (timeDiff > 0) {
        return format.replace("#", `${timeDiff}`).replace("$%", "mo");
    }

    timeDiff = elapsed.getDate();
    if (timeDiff >= 7) {
        return format.replace("#", `${Math.trunc(timeDiff / 7)}`).replace("$%", "w");
    }

    timeDiff = elapsed.getDate();
    if (timeDiff < 7) {
        return format.replace("#", `${Math.trunc(timeDiff % 7)}`).replace("$%", "d");
    }

    timeDiff = elapsed.getHours();
    if (timeDiff > 0) {
        return format.replace("#", `${timeDiff}`).replace("$%", "h");
    }

    return relativeTime;
}

const RecentPostsAccordion: React.FC<{element: AnsweredQuestion, timeStamp?: Date}> = ({ element, timeStamp }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    const text = (element as { content?: string })?.content || "";
    const parsed = new DOMParser().parseFromString(text, 'text/html');
    parsed.querySelectorAll('a')?.forEach((link) => {
        link?.setAttribute('target', '_blank');
        link?.setAttribute('rel', 'noopener noreferrer');
        link?.setAttribute('style', `color: #048290; font-weight: bold; text-decoration: none;`);
    });
    const rawHTML = new XMLSerializer().serializeToString(parsed);
    const relativeTime = (element as { date?: string; })?.date || "";
    const contentType = (element as { category?: ArticleCategory; })?.category || ArticleCategory.OTHER;

    const handleAccordionChange = () => {
        setIsExpanded(!isExpanded);
    }

    const postTime = parseRelativeTime(relativeTime, timeStamp!);

    return (
        <Accordion elevation={0} expanded={isExpanded} disableGutters
            sx={{ background: "transparent", '&:before': { display: 'none', } }}>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                {!isExpanded && (<>
                    <Stack direction="row">
                        {[ArticleCategory.ANNOUNCEMENT, ArticleCategory.FUNDRAISING].includes(contentType) &&
                            <Box mr={1} display="flex">
                                <Fade in={!isExpanded} timeout={300}>
                                    {getActivityFeedEventIcon(contentType, classes.urlIcon, '#7bd4d4')}
                                </Fade>
                            </Box>}
                        <Typography dangerouslySetInnerHTML={{ __html: `${rawHTML}`}}
                            sx={{
                                color: '#666666',
                                fontSize: '0.9rem',
                                fontFamily: 'Inter !important',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: '2',
                                wordBreak: 'break-word',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            onClick={(e)=>{
                                e.preventDefault();
                                setIsExpanded(true);
                            }} />
                    </Stack>
                    <AccordionSummary
                        expandIcon={<Stack alignItems="center" justifyContent="space-between">
                            <Typography fontSize="0.75rem">{`${postTime}`}</Typography>
                            <ExpandMoreIcon />
                        </Stack>}
                        sx={{ padding: '0 4px', }}
                        onClick={handleAccordionChange}
                    />
                </>)}
            </Stack>
            <AccordionDetails sx={{ padding: 0, "& .MuiButtonBase-root": {alignItems: "stretch"} }}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row">
                        {[ArticleCategory.ANNOUNCEMENT, ArticleCategory.FUNDRAISING].includes(contentType) &&
                            <Box mr={1} display="flex">
                                <Fade in={isExpanded} timeout={300}>
                                    {getActivityFeedEventIcon(contentType, classes.urlIcon, '#7bd4d4')}
                                </Fade>
                            </Box>}
                        <Typography dangerouslySetInnerHTML={{ __html: `${rawHTML}`}}
                            sx={{
                                color: '#666666',
                                fontSize: '0.9rem',
                                fontFamily: 'Inter !important',
                            }} />
                    </Stack>
                    {isExpanded && (
                        <AccordionSummary
                            expandIcon={<Stack alignItems="center" justifyContent="space-between">
                                <Typography fontSize="0.75rem">{`${postTime}`}</Typography>
                                <ExpandMoreIcon sx={{ transform: 'rotate(180deg)' }}/>
                            </Stack>}
                            sx={{ padding: '0 4px', }}
                            onClick={handleAccordionChange}
                        />
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export default RecentPostsAccordion;
