import { createTheme, Theme } from '@mui/material/styles';
import colors, { ColorsType } from './colors';
import TYPOGRAPHY from './typography';
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: ColorsType
  }
  interface ThemeOptions {
    colors?: ColorsType
  }
}

declare module '@mui/material/Typography' {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}


const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
  palette: {
    primary: {
      main: '#048290',
      light: '#00BCD4'
    },
    secondary: {
       main: '#048290',
    },
    text: {
      primary: '#696E70',
      secondary: '#787878',
    },
    info: {
      main: '#E0F7FA'
    },
  },
  typography: {
    fontFamily: '"Lato", sans-serif',
    ...TYPOGRAPHY
  },

  colors,
});

export default theme;
