import { FC, useCallback, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from "@mui/styles";
import ConfirmDialog from "../../modals/ConfirmDialog";
import { deleteWorkspaceFunc } from "../../../lib/helper";
import CreateEditInvestorModal from "../../modals/investors/CreateEditInvestorModal";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import { Workspace } from "../../../types/files";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    icon: {
        "& > svg": {
            width: 24,
            height: 24,
        },
    },
    liveIcon: {
        padding: 'unset',
        color: theme.colors.neutral['600'],
        "& > svg": {
            width: 24,
            height: 24,
        },
    },
}));

const WorkspaceMenu: FC<{ workspace: Workspace, forLive?: boolean }> = ({ workspace, forLive }) => {
    const classes = useStyles();
    const history = useHistory();
    const params: {[key: string]: string} = useParams();
    const { setWorkspaces } = useContext(GroupSettingsContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [deleteInvestorLoading, setDeleteInvestorLoading] = useState<boolean>(false);
    const [createInvestorOpen, setCreateInvestorOpen] = useState<boolean>(false);

    const openDeleteDialog = useCallback(() => {
        setConfirmDeleteOpen(true);
        setAnchorEl(null);
    }, []);

    const closeDeleteDialog = useCallback(() => {
        setDeleteInvestorLoading(false);
        setConfirmDeleteOpen(false);
    }, []);

    const handleDelete = useCallback(() => {
        setDeleteInvestorLoading(true);
        deleteWorkspaceFunc({ id: workspace.id }).then(() => {
            setWorkspaces(prev => prev.filter(ws => ws.id !== workspace.id));
            setDeleteInvestorLoading(false);
            closeDeleteDialog();

            if (params.workspaceId === workspace.id)
                history.push('/dashboards');
        });
    // eslint-disable-next-line
    }, [params.workspaceId]);

    return (<>
        <Box onClick={(e) => e.stopPropagation()}>
            <IconButton className={forLive ? classes.liveIcon : classes.icon}
                onClick={(e) => setAnchorEl(e.currentTarget)} disableRipple>
                <MoreVertIcon />
            </IconButton>
            <Menu open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: forLive ? 'center': 'left' }}
                onClose={() => setAnchorEl(null)}>
                {!forLive && (
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        setCreateInvestorOpen(true);}}>
                        {'Edit investor'}
                    </MenuItem>
                )}
                <MenuItem onClick={openDeleteDialog}
                    sx={{ color: theme.colors.error['500']}}>
                    {'Delete'}
                </MenuItem>
            </Menu>
            {confirmDeleteOpen && (
                <ConfirmDialog
                    title="Delete confirmation"
                    content="Are you sure you want to delete this investor?"
                    open={confirmDeleteOpen}
                    loading={deleteInvestorLoading}
                    confirmCallback={handleDelete}
                    cancelCallback={closeDeleteDialog}
                />
            )}
            {createInvestorOpen && (
                <CreateEditInvestorModal
                    isOpen={createInvestorOpen}
                    workspaceId={workspace.id}
                    onClose={() => setCreateInvestorOpen(false)}
                />
            )}
        </Box>
    </>);
}

export default WorkspaceMenu;