import { Box, Divider, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CompanyDetailsColumnQueries from './CompanyDetailsColumnQueries';
import KeyMetricsColumnQueries from './KeyMetricsColumnQueries';
import PeopleColumnQueries from './PeopleColumnQueries';
import RecentActivityColumnQueries from './RecentActivityColumnQueries';
import DigestMultiSpanQueries from "./DigestMultiSpanQueries";

const useStyles = makeStyles(() => ({
    queriesContainer: {
        marginBottom: 24,
        overflowX: 'hidden',
        position: 'relative',
    },
}));

const DashboardQueries: React.FC<{}> = () => {
    const classes = useStyles();

    return (<>
        <Box className={classes.queriesContainer}>
            <Stack direction="column" spacing={2}>
                <DigestMultiSpanQueries />
                <Divider />
                <Stack direction="row" spacing={2}>
                    <CompanyDetailsColumnQueries />
                    <KeyMetricsColumnQueries />
                    <PeopleColumnQueries />
                    <RecentActivityColumnQueries />
                </Stack>
            </Stack>
        </Box>
    </>);
}

export default DashboardQueries;
