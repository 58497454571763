import { Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { scrollbarStyle } from "../../../../shared/dashboard";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const useStyles = makeStyles((theme) => ({
    summaryContainer: {
        display: 'inline-block',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(90deg, rgba(212,236,237,1) 0%, rgba(240,254,254,1) 100%)',
        border: `1px solid ${theme.colors.neutral['100']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        borderRadius: 16,
        padding: 16,
        breakInside: 'avoid',
        transition: '0.05s ease-in-out',
        "&:hover": {
            boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
        },
    },
    contentBlock: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    title: {
        color: '#7bd4d4',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontFamily: 'Inter',
    },
    text: {
        height: 336,
        color: '#048290',
        fontSize: 15,
        fontWeight: 'bold',
    },
    aiGeneratedIcon: {
        color: '#7bd4d4',
        height: '20px',
        width: '20px',
        marginLeft: '16px',
    },
}));

const NoteSummary: React.FC<{ summary: string }> = ({ summary }) => {
    const classes = useStyles();

    return (<>
        <Box className={classes.summaryContainer}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={1}>
                <Typography className={classes.title}>Summary</Typography>
                <AutoAwesomeIcon className={classes.aiGeneratedIcon} />
            </Stack>
            <Box className={classes.contentBlock}>
                <Typography className={classes.text}>
                    {summary as string}
                </Typography>
            </Box>
        </Box>
    </>);
}

export default NoteSummary;