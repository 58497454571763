import { useCallback, useMemo, useRef, useState } from "react";
import { Autocomplete, Box, Button, Chip, CircularProgress, ClickAwayListener, FormControl, Grow, MenuItem, Paper, Popper, Select, Stack, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import classNames from "classnames";
import { scrollbarStyle } from "../../shared/dashboard";

const useStyles = makeStyles((theme) => ({
    label: {
        minWidth: 100,
        marginTop: 4,
        fontFamily: 'Poppins',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    formContainer: {
        minWidth: 'fit-content',
        "& > .MuiInputBase-root":{
            width: 'auto',
            height: 32,
            borderRadius: 32,
            background: '#E0F1F1',
            "& > .MuiSelect-select": {
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                padding: '5px 10px 0 10px !important',
            },
            "& > .MuiSvgIcon-root": {
                display: 'none',
            },
            "& fieldset": {
              border: 0,
            },
        },
    },
    menu: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 400,
        color: 'gray',
    },
    cropped: {
        width: 'auto',
        height: 40,
        paddingRight: 8,
        overflowY: 'hidden',
        overflowX: 'auto',
        ...scrollbarStyle,
    },
    fullSpan: {
        width: 'auto',
        height: 'unset',
        paddingRight: 8,
    },
    chip: {
        height: 30,
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '0.8rem',
    },
    badge: {
        backgroundColor: '#F1F3F5',
        color: '#4A5056',
    },
    disabled: {
        color: theme.colors.neutral['600'],
    },
    loading: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        minWidth: 32,
        color: theme.colors.neutral['400'],
        padding: 'unset !important',
        "& > .MuiButton-startIcon": {
            width: 'auto',
            height: 32,
            padding: 6,
            margin: 'unset',
            borderRadius: '50%',
            background: '#E0F1F1',
            "& > .MuiSvgIcon-root": {
                fontSize: 22,
                color: theme.palette.primary.main,
            }
        },
        "&:hover": {
            color: theme.colors.neutral['600'],
            backgroundColor: 'inherit',
        },
    },
    inputField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            width: 180,
            height: 32,
            padding: '0 8px !important',
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    optionContainer: {
        padding: '2px 12px',
        transition: '0.5s ease-in-out',
        "&:hover": {
            backgroundColor: '#EFF6FF',
            boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        },
        cursor: 'pointer',
    },
    optionItem: {
        fontFamily: 'Inter',
        fontSize: '0.9rem !important',
        fontWeight: '500 !important',
        color: `${theme.colors.neutral['700']} !important`,
    },
}));

const MultiSelector: React.FC<{
    mainOptions: string[],
    selections: string[],
    label?: string,
    placeholder?: string,
    cropped?: number,
    fullSpan?: boolean,
    limit?: number,
    readOnly?: boolean,
    disabled?: boolean,
    loading?: boolean,
    withSearch?: boolean,
    onSelection?: (selections: string[]) => void,
}> = ({ mainOptions, selections, label, placeholder, cropped, fullSpan, limit, readOnly, disabled, loading, withSearch, onSelection }) => {
    const classes = useStyles();

    const [popperOpen, setPopperOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const options = useMemo(() => mainOptions.filter(option => !selections.includes(option)), [mainOptions, selections]);

    const handleEnlist = useCallback((selection: string) => {
        onSelection?.([...selections, selection]);
    }, [onSelection, selections]);

    const handleDelist = useCallback((selection: string) => {
        onSelection?.(selections.filter(s => s !== selection));
    }, [onSelection, selections]);

    return (<>
        <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-start" width="100%">
            {label && (<Typography className={classes.label}>{label}</Typography>)}
            <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-start"
                className={classNames(!!cropped && classes.cropped, fullSpan && classes.fullSpan)}
                flexWrap={!!cropped ? 'nowrap' : 'wrap'}
                sx={{ maxWidth: cropped ?? 'auto' }}>
                {selections.map((selection, i) => (
                    <Chip className={classNames(classes.chip, (!!cropped || fullSpan) && classes.badge, disabled && classes.disabled)}
                        label={selection}
                        deleteIcon={(!readOnly && !loading) ? <ClearIcon /> : undefined}
                        onDelete={(!readOnly && !loading) ? (() => handleDelist(selection)) : undefined}
                        key={'multi-selector-114-' + i} />
                ))}
                {loading ? (
                    <Box className={classes.loading}>
                        <CircularProgress size="20px" />
                    </Box>
                ) : (!readOnly && (!limit || selections.length < Number(limit))) && (
                    withSearch ? (
                        <Button className={classes.button} ref={anchorRef}
                            startIcon={<AddIcon />}
                            onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setPopperOpen(true);
                            }}
                        />
                    ) : (
                        <FormControl variant="outlined" className={classes.formContainer} disabled={!options.length}>
                            <Select value={''} renderValue={() => placeholder || '+'}
                                onChange={(e) => handleEnlist(e.target.value)} displayEmpty>
                                <MenuItem
                                    value={''}
                                    sx={{ display: 'none' }}
                                    key="multi-selector-127-empty" />
                                {mainOptions.map((option, i) => (
                                    <MenuItem className={classes.menu}
                                        value={option}
                                        sx={{ display: options.includes(option) ? 'inherit' : 'none'}}
                                        key={"multi-selector-132-" + i}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                )}
            </Stack>
        </Stack>
        <Popper open={popperOpen}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            sx={{ zIndex: 2000 }}
            transition>
            {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                    <Paper>
                        <ClickAwayListener
                            onClickAway={() => setPopperOpen(false)}>
                            <Autocomplete
                                open
                                openOnFocus
                                disableClearable
                                autoHighlight
                                autoComplete
                                disablePortal
                                freeSolo
                                forcePopupIcon={false}
                                options={options}
                                onChange={(_, option: string) => handleEnlist(option)}
                                filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt?.toLowerCase().includes(inputValue.toLowerCase()) : !!opt)}
                                renderOption={(props: any, option: string) => (
                                    <Box {...props} className={classes.optionContainer}>
                                        <Typography className={classes.optionItem}>{option}</Typography>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        variant="outlined"
                                        className={classes.inputField}
                                        InputProps={{ ...params.InputProps }}
                                        placeholder={'Search ...'}
                                        autoFocus
                                    />
                                )}
                            />
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>);
}

export default MultiSelector;