import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Divider, Fade, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { defaultGeographies, defaultStages, defaultTags, scrollbarStyle } from "../../../shared/dashboard";
import MultiSelector from "../../atoms/MultiSelector";
import { InvestmentThesis, Workspace } from "../../../types/files";
import theme from "../../../theme";
import mailProviders from "../../../helpers/mailProviders.json";
import {ReactComponent as WorldGridIcon} from "../../../assets/icons/world.svg";
import { useDebouncedCallback } from "use-debounce";
import HoverEditable from "../../atoms/HoverEditable";
import CreateEditInvestorModal from "../../modals/investors/CreateEditInvestorModal";

const useStyles = makeStyles((theme) => ({
    container: {
        minWidth: 400,
        width: 'min-content',
        height: '100%',
        overflow: 'hidden',
    },
    scrollable: {
        width: '100%',
        height: '100%',
        marginBottom: '16px !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    groupTitle: {
        fontFamily: 'Poppins',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        color: theme.colors.neutral['500'],
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    inputField: {
        minWidth: 'fit-content',
        width: '100%',
        marginBottom: '8px !important',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            borderRadius: 8,
            padding: '8px !important',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    icon: {
        height: 20,
        width: 20,
    },
    addtlButton: {
        width: 'fit-content',
        padding: '6px 0 !important',
        textTransform: 'none',
        "&:hover": {
            background: 'transparent !important',
        }
    },
    commonButton: {
        width: 100,
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        width: 100,
        height: 36,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const Preferences: React.FC<{
    workspace?: Workspace,
    modal?: boolean,
    independent?: boolean,
    onSave: (investmentThesis: InvestmentThesis) => Promise<boolean>,
    onClose?: () => void,
}> = ({ workspace, modal, independent, onSave, onClose }) => {
    const classes = useStyles();
    const { workspace: globalWorkspace, isPublicView } = useContext(DashboardsContext);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedGeography, setSelectedGeography] = useState<string[]>([]);
    const [addDetails, setAddDetails] = useState<string>();
    const [addDetailsRows, setAddDetailsRows] = useState<number>(0);
    const [modifyFields, setModifyFields] = useState<string[] | undefined>(undefined);
    const [createInvestorOpen, setCreateInvestorOpen] = useState<boolean>(false);

    const profileRef = useRef<HTMLDivElement|null>(null);
    const addDetailsRef = useRef<TextFieldProps>(null);

    const debounced = useDebouncedCallback(value => handleSave([value], 'additionalDetails'), 1000, { maxWait: 2000 });

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const commonDomainsList = useMemo(() =>
        Object.entries(mailProviders as { [key: string]: string | string [] }).find(([key, _]) => key === 'result')?.pop() ?? []
    , []);

    const website = useMemo(() => {
        if (!localWorkspace!.website && !!localWorkspace!.email && localWorkspace!.email.indexOf('@') !== -1) {
            const domain = (localWorkspace!.email.split('@').pop() || '')?.trim().toLowerCase();

            return !commonDomainsList.includes(domain) ? domain : '';
        }

        return localWorkspace!.website?.replace(/(^\w+:|^)\/\//, '').replace('www.', '');
    }, [commonDomainsList, localWorkspace]);

    const handleSave = useCallback((selections: string[], type: string) => {
        const focusArea = { tags: type === 'focusArea' ? selections : selectedTags };
        const stage = { stages: type === 'stage' ? selections : selectedStages };
        const geography = { regions: type === 'geography' ? selections : selectedGeography };
        const additionalDetails = { freeText: type === 'additionalDetails' ? selections[0] : addDetails };

        switch (type) {
            case 'focusArea':
                setSelectedTags(focusArea.tags ?? []);
                break;
            case 'stage':
                setSelectedStages(stage.stages ?? []);
                break;
            case 'geography':
                setSelectedGeography(geography.regions ?? []);
                break;
            case 'additionalDetails':
                setAddDetails(additionalDetails.freeText || '');
                break;
        }

        if (loaded)
            onSave?.({ focusArea, stage, geography,  additionalDetails } as InvestmentThesis);
    }, [selectedTags, selectedStages, selectedGeography, addDetails, loaded, onSave]);

    useEffect(() => {
        if (!!localWorkspace && !loaded) {
            setSelectedTags(localWorkspace.investmentThesis?.focusArea?.tags ?? []);
            setSelectedStages(localWorkspace.investmentThesis?.stage?.stages ?? []);
            setSelectedGeography(localWorkspace.investmentThesis?.geography?.regions ?? []);
            setAddDetails(localWorkspace.investmentThesis?.additionalDetails?.freeText || '');
            setLoaded(true);
        }
    // eslint-disable-next-line
    }, [localWorkspace]);

    useEffect(() => {
        setAddDetailsRows(((profileRef?.current?.clientHeight || 0) / 26) || 5);
    }, [profileRef?.current?.clientHeight]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        <Stack className={classes.container} alignItems="space-between" justifyContent="stretch">
            {(modal && independent) && (<>
                <Stack direction="column" spacing={1} pb={2}>
                    <Typography component="span" className={classes.groupTitle} fontSize="1.5rem !important" display="inline">
                        {`Profile for ${workspace?.name}`}
                    </Typography>
                    <Divider sx={{ width: '100%' }} />
                </Stack>
            </>)}
            <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="stretch" width="100%" height={`calc(100% - ${modal ? 36 : 4}px)`}>
                {!(modal && independent) && (
                    <Fade in timeout={250}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                            <Typography component="span" className={classes.groupTitle} display="inline">
                                {'Profile'}
                            </Typography>
                            {!!website && (<>
                                <HoverEditable variant="small"
                                    onClick={() => {
                                        setCreateInvestorOpen(true);
                                        setModifyFields(['website']);
                                    }} disableEdit={isPublicView || modal}>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                        <WorldGridIcon className={classes.icon} fill="#7bd4d4" stroke="#7bd4d4" />
                                        <Typography component="a"
                                            className={classes.label}
                                            color={`${theme.colors.neutral['700']} !important`}
                                            href={`https://${website}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ cursor: 'pointer', textDecoration: 'none' }}>
                                            {website}
                                        </Typography>
                                    </Stack>
                                </HoverEditable>
                            </>)}
                        </Stack>
                    </Fade>
                )}
                <Fade in timeout={300}>
                    <Stack direction="row" className={classes.scrollable} alignItems="flex-start">
                        <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="flex-start"
                            width={(modal && independent) ? "300px" : '100%'} height="100%" ref={profileRef}>
                            <MultiSelector
                                mainOptions={defaultTags}
                                selections={selectedTags}
                                label="Sector"
                                onSelection={(selections) => handleSave(selections as string[], 'focusArea')}
                                withSearch />
                            <MultiSelector
                                mainOptions={defaultStages}
                                selections={selectedStages}
                                label="Stage"
                                onSelection={(selections) => handleSave(selections as string[], 'stage')} />
                            <MultiSelector
                                mainOptions={defaultGeographies}
                                selections={selectedGeography}
                                label="Geography"
                                onSelection={(selections) => handleSave(selections as string[], 'geography')} />
                            {!(modal && independent) && (<>
                                <Stack direction="column" spacing={1} alignItems="flex-start" width="100%">
                                    <Typography fontFamily="Poppins" fontSize="1rem" fontWeight="bold" color={theme.colors.neutral['800']}>
                                        {`Additional details`}
                                    </Typography>
                                    <Typography fontSize="0.8rem" color={`${theme.colors.neutral['500']} !important`}>
                                        {`Add notes or additional details on ${localWorkspace?.name}'s preferences.`}
                                        <br />
                                        {'This will help refine the top matches.'}
                                    </Typography>
                                </Stack>
                                <TextField variant="outlined"
                                    className={classes.inputField}
                                    placeholder={''}
                                    inputRef={addDetailsRef}
                                    defaultValue={addDetails}
                                    onChange={(e) => debounced(e.target.value)}
                                    rows={5}
                                    multiline
                                    fullWidth
                                />
                            </>)}
                            <Box />
                        </Stack>
                        {(modal && independent) && (<>
                            <Stack direction="column" spacing={1} alignItems="flex-start" width={(modal && independent) ? "300px" : undefined}>
                                <Typography fontFamily="Poppins" fontSize="1rem" fontWeight="bold" color={theme.colors.neutral['800']}>
                                    {`Additional details`}
                                </Typography>
                                <TextField variant="outlined"
                                    className={classes.inputField}
                                    placeholder={`Add notes or additional details on ${localWorkspace?.name}'s preferences.`}
                                    inputRef={addDetailsRef}
                                    defaultValue={addDetails}
                                    onChange={(e) => debounced(e.target.value)}
                                    rows={addDetailsRows}
                                    multiline
                                    fullWidth
                                />
                            </Stack>
                        </>)}
                    </Stack>
                </Fade>
            </Stack>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-end" width="100%">
                {modal && (
                    <Button variant="outlined"
                        className={classes.cancelButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onClose?.();
                    }}>{'Cancel'}</Button>
                )}
            </Stack>
        </Stack>
        {createInvestorOpen && (
            <CreateEditInvestorModal
                isOpen={createInvestorOpen}
                workspaceId={localWorkspace?.id}
                fields={modifyFields}
                onClose={() => setCreateInvestorOpen(false)}
            />
        )}
    </>);
}

export default Preferences;