import { Autocomplete, Button, Checkbox, Chip, Fab, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { v4 as uuidv4 } from "uuid";
import { SearchDocumentType, AdvancedSearchObject, SearchEntity } from '../../../types/search';
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import { splitFolders } from '../../../helpers/fileStructure';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AllFileTypesExcludingExcel, getDropdownSelectLabel, AllFileTypesIncludingExcel } from '../../../shared/search';
import { SearchContext } from '../../../contexts/SearchContext';
import { useHistory } from "react-router-dom";
import ArrayUtils from '../../../utils/ArrayUtils';
import { grey } from '@mui/material/colors';
import { parseKeywords } from '../../folders/components/search-terms/SearchTermUtils';
import SearchTerms from '../../folders/components/search-terms/SearchTerms';

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        width: "85%",
        marginLeft: "auto",
        marginRight: "auto"
    },
    headerText: {
        color: theme.palette.text.primary,
        alignSelf: "start",
    },
    searchBar: {
        marginTop: 20,
        backgroundColor: theme.palette.common.white,
        borderRadius: 10,
        padding: 10,
        "&:focus-within" : {
            boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main} !important`,
        },
        "&:hover": {
            boxShadow: `inset 0px 0px 0px 1px ${grey[400]}`,
        }
    },
    content: {
        marginTop: 12,
    },
    typeOfDocTextField: {
        height: 40,
        overflow: 'hidden',
        paddingTop: 0,
        paddingBottom: 0,
    },
    typeOfDocInput: {
        height: 40,
    },
    headerContainer: {
        width: "100%",
        height: 37,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
}));

interface Props {
    setLoading: (loading: SetStateAction<boolean>) => void;
}

const HomePageSearch: React.FC<Props> = () => {
    const classes = useStyles();
    const history = useHistory();
    const {
        contextSearchEntity,
        setContextSearchEntity,
        contextSearchKeywordsAndOperations,
        setContextSearchKeywordsAndOperations,
        contextAdvancedSearchObject,
        setContextAdvancedSearchObject,
        contextSelectedDocTypes,
        setContextSelectedDocTypes,
        setContextSelectedSearchResult,
        setContextSearchResults,
    } = useContext(SearchContext);
    const { fileStructure } = useContext(FileStructureContext);

    const [searchEntity, setSearchEntity] = useState<SearchEntity>(SearchEntity.All);
    const [selectedDocTypes, setSelectedDocTypes] = useState<SearchDocumentType[] | null | undefined>(AllFileTypesExcludingExcel);
    const [fileOptions, setFileOptions] = useState<AdvancedSearchObject[]>([]);
    const [folderOptions, setFolderOptions] = useState<AdvancedSearchObject[]>([]);
    const [advancedSearchObject, setAdvancedSearchObject] = useState<AdvancedSearchObject>({
        id: uuidv4(),
        file: null,
        fileValue: null,
        folder: null,
        value: null,
    } as AdvancedSearchObject);
    const [keywords, setKeywords] = useState<string[][]>([]);
    const [keywordsAndOperations, setKeywordsAndOperations] = useState<string[]>([]);
    const [finishedEditing, setFinishedEditing] = useState<boolean>(false);
    const [shouldSearch, setShouldSearch] = useState<boolean>(false);
    const [hasSearched, setHasSearched] = useState<boolean>(true);
    const [initFinished, setInitFinished] = useState<boolean>(false);

    useEffect(() => {
        if (contextSearchKeywordsAndOperations.length) {
            setKeywords(parseKeywords(contextSearchKeywordsAndOperations));
            setHasSearched(true);
            search();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setSearchEntity(contextSearchEntity);
    }, [contextSearchEntity]);

    useEffect(() => {
        setKeywordsAndOperations(contextSearchKeywordsAndOperations);
    }, [contextSearchKeywordsAndOperations]);

    useEffect(() => {
        setSelectedDocTypes(contextSelectedDocTypes);
    }, [contextSelectedDocTypes]);

    useEffect(() => {
        if (shouldSearch) {
            search();
            setShouldSearch(false);
            setHasSearched(true);
        }
        // eslint-disable-next-line
    }, [shouldSearch]);


    useEffect(() => {
        if (fileStructure.length && contextAdvancedSearchObject) {
            const [files, folders] = splitFolders(fileStructure);
            folders.sort();
            files.sort();

            const newFolderOptions = folders.map(e => {
                return {
                    id: uuidv4(),
                    file: null,
                    fileValue: null,
                    folder: e,
                    value: e.path.split('/').join(" > "),
                } as AdvancedSearchObject
            });

            const newFileOptions = files.map(e => {
                const parent = folders.find(f => f.id === e.parentId);
                return {
                    id: uuidv4(),
                    file: e,
                    fileValue: e.path.split('/').join(" > "),
                    folder: parent,
                    value: parent?.path.split('/').join(" > "),
                } as AdvancedSearchObject
            });

            if (!contextAdvancedSearchObject?.file && !contextAdvancedSearchObject?.fileValue && !contextAdvancedSearchObject?.folder && !contextAdvancedSearchObject?.value) {
                newFolderOptions.unshift(contextAdvancedSearchObject);
                newFileOptions.unshift(contextAdvancedSearchObject);
                setAdvancedSearchObject({ ...advancedSearchObject, id: contextAdvancedSearchObject?.id || uuidv4() });
            } else {
                setAdvancedSearchObject(contextAdvancedSearchObject);
            }

            setFolderOptions(newFolderOptions);
            setFileOptions(newFileOptions);

        }
        // eslint-disable-next-line
    }, [fileStructure, contextAdvancedSearchObject]);

    const search = () => {};

    const getFileOptions = (folder: AdvancedSearchObject | null | undefined) => {
        let filteredFileOptions = folder?.value ?
            fileOptions.filter((e) => {
                return e.file?.path === `${folder.value?.split(' > ').join("/")}/${e.file?.name}`
            })
            :
            fileOptions;

        filteredFileOptions = filteredFileOptions.filter(e => !!filteredFileOptions.find(x => x.fileValue === e.fileValue && x.id !== folder?.id));

        if (folder) {
            filteredFileOptions.unshift(folder);
        }
        return filteredFileOptions;
    }

    const getFolderAutocompleteElements = (folder: AdvancedSearchObject | null | undefined) => {
        return <Grid
            container
            width="100%"
        >
            <Grid item xs={6} mr="10px">
                <Autocomplete
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    options={folderOptions}
                    filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt?.value?.toLowerCase().includes(inputValue.toLowerCase()) : !!opt.value)}
                    getOptionLabel={option => option?.value || ""}
                    value={folder}
                    autoComplete
                    clearOnEscape
                    openOnFocus
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff"
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <FolderIcon color="secondary" sx={{ marginRight: 1 }} />
                            {option?.value || ""}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="Folder"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start" >
                                            <FolderIcon color="secondary" />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    onChange={(_, newValue) => {
                        if (!newValue) {
                            const newObject = {
                                id: advancedSearchObject?.id,
                                file: null,
                                fileValue: null,
                                folder: null,
                                value: null,
                            } as AdvancedSearchObject;
                            setAdvancedSearchObject(newObject);
                            setContextAdvancedSearchObject(newObject);
                        } else {
                            setAdvancedSearchObject(newValue);
                            setContextAdvancedSearchObject(newValue);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={5.5} mr="10px">
                <Autocomplete
                    isOptionEqualToValue={(option, value) => option?.fileValue === value?.fileValue}
                    options={folder?.value ? getFileOptions(folder) : fileOptions}
                    filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt?.fileValue?.toLowerCase().includes(inputValue.toLowerCase()) : !!opt.fileValue)}
                    getOptionLabel={option => option?.fileValue || ""}
                    value={folder}
                    autoComplete
                    clearOnEscape
                    openOnFocus
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff"
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <InsertDriveFileIcon color="secondary" sx={{ marginRight: 1 }} />
                            {option?.fileValue || ""}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="File"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start" >
                                            <InsertDriveFileIcon color="secondary" />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    onChange={(_, newValue) => {

                        if (!newValue) {
                            const newObject = {
                                id: advancedSearchObject?.id,
                                file: null,
                                fileValue: null,
                                folder: advancedSearchObject?.folder,
                                value: advancedSearchObject?.value,
                            } as AdvancedSearchObject;
                            setAdvancedSearchObject(newObject);
                            setContextAdvancedSearchObject(newObject);
                        } else {
                            setAdvancedSearchObject(newValue);
                            setContextAdvancedSearchObject(newValue);
                        }
                    }}
                />
            </Grid>
        </Grid>
    }

    const onBackClick = () => {
        setContextSearchEntity(SearchEntity.All);
        setContextSearchKeywordsAndOperations([]);
        setContextAdvancedSearchObject({
            id: uuidv4(),
            file: null,
            fileValue: null,
            folder: null,
            value: null,
        } as AdvancedSearchObject);
        setContextSelectedSearchResult(undefined);
        setContextSearchResults([]);
        setContextSelectedDocTypes(AllFileTypesExcludingExcel);
        setTimeout(() => {
            history.push({
                pathname: '/home',
                hash: "",
            });
        }, 200);
    }

    return (
        <Box className={classes.rootContainer}>
            <Box className={classes.headerContainer}>
                <Typography variant="h5" className={classes.headerText}>What would you like to search:</Typography>
                <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={onBackClick}>Back to home</Button>
            </Box>
            <Grid container className={classes.searchBar}>
                <Grid item xs={0.5} display="flex" alignItems="center">
                    <SearchIcon color="primary" />
                </Grid>
                <Grid item xs={10}>
                    <SearchTerms
                        inputId="search-terms"
                        sourceKeywords={keywords}
                        sourceSearchPhrase={keywordsAndOperations}
                        placeholder={"Search in files"}
                        addPlaceholder={"Add keyword"}
                        finishedEditing={finishedEditing}
                        primaryBackground
                        appearDisabled={hasSearched}
                        onChange={(k, ko) => {
                            if (ArrayUtils.areEqual(ko, contextSearchKeywordsAndOperations) && !initFinished) {
                                setInitFinished(true);

                                setHasSearched(true);
                            } else {
                                setKeywords(k);
                                setKeywordsAndOperations(ko);
                                setContextSearchKeywordsAndOperations(ko);

                                setHasSearched(false);
                                if (finishedEditing) {
                                    setFinishedEditing(false);
                                    setShouldSearch(true);
                                }
                            }
                        }}
                        onInputChange={() => {
                            setHasSearched(false);
                        }}
                        onSearch={() => {
                            setFinishedEditing(true);
                        }}
                    />
                </Grid>
                <Grid item xs={1.5} textAlign="end">
                    <Fab
                        color="secondary"
                        size="medium"
                        aria-label="search"
                        variant="extended"
                        sx={{ color: "#fff", marginRight: 1 }}
                        onClick={() => {
                            const searchTermsInput = document.getElementById('search-terms');
                            const event = new KeyboardEvent('keydown', {
                                altKey: false,
                                bubbles: true,
                                cancelable: true,
                                charCode: 0,
                                code: "Enter",
                                key: "Enter",
                                keyCode: 13,
                                which: 13
                            });
                            searchTermsInput?.dispatchEvent(event);

                            setFinishedEditing(true);
                        }}
                    >
                        Search
                    </Fab>
                </Grid>
            </Grid>
            <Grid container width="100%" className={classes.content}>
                <Grid item xs={2.2} mr="10px">
                    <Autocomplete
                        isOptionEqualToValue={(option, value) => option && option === value}
                        options={[SearchEntity.All, SearchEntity.Documents, SearchEntity.Dashboards]}
                        value={searchEntity}
                        clearOnEscape
                        openOnFocus
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                backgroundColor: "#fff"
                            },
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <SpellcheckIcon color="secondary" sx={{ marginRight: 1 }} />
                                {option || ""}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                placeholder="Type of search"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            <InputAdornment position="start" >
                                                <SpellcheckIcon color="secondary" />
                                            </InputAdornment>
                                            {params.InputProps.startAdornment}
                                        </>
                                    )
                                }}
                            />
                        )}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setSearchEntity(newValue as SearchEntity);
                                setContextSearchEntity(newValue as SearchEntity);
                            }
                        }}
                    />
                </Grid>
                {searchEntity === SearchEntity.Documents && <>
                    <Grid item xs={7}>
                        {getFolderAutocompleteElements(advancedSearchObject)}
                    </Grid>
                    <Grid item xs={2.5} mr="10px">
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => option === value}
                            options={["", ...AllFileTypesIncludingExcel]}
                            filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt.toLowerCase().includes(inputValue.toLowerCase()) : opt !== "")}
                            value={selectedDocTypes || []}
                            clearOnEscape
                            openOnFocus
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "#fff"
                                },
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 1 }}
                                        checked={selected}
                                    />
                                    <FileCopyOutlinedIcon color="secondary" sx={{ marginRight: 1 }} />
                                    {option || ""}
                                </li>
                            )}
                            renderTags={(tagValue, getTagProps) => {
                                if (tagValue.length < 2) {
                                    return tagValue.map((option, index) => (
                                        <Chip
                                            label={option}
                                            {...getTagProps({ index })}
                                            key={'home-page-search-506-' + index}
                                        />
                                    ))
                                } else {
                                    return [<Chip
                                        label={getDropdownSelectLabel(tagValue)}
                                        sx={{ fontSize: 12 }}
                                        key={'home-page-search-513-'}
                                    />];
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    className={classes.typeOfDocTextField}
                                    rows={1}
                                    variant="outlined"
                                    size="small"
                                    placeholder={selectedDocTypes?.length ? "" : "Type of doc"}
                                    InputProps={{
                                        ...params.InputProps,
                                        className: classes.typeOfDocInput,
                                        startAdornment: (
                                            <>
                                                <InputAdornment position="start" >
                                                    <FileCopyOutlinedIcon color="secondary" />
                                                </InputAdornment>
                                                {params.InputProps.startAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    setSelectedDocTypes(newValue as SearchDocumentType[]);
                                    setContextSelectedDocTypes(newValue as SearchDocumentType[]);
                                }
                            }}
                        />
                    </Grid>
                </>}
                {searchEntity === SearchEntity.Dashboards && <>
                    <Grid item xs={5.5}>
                        <Autocomplete
                            options={[]}
                            autoComplete
                            clearOnEscape
                            openOnFocus
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "#fff"
                                },
                            }}
                            renderOption={(props, option) => (
                                <Box component="li" {...props}>

                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Dashboard"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <InputAdornment position="start" >
                                                    <FolderIcon color="secondary" />
                                                </InputAdornment>
                                                {params.InputProps.startAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                            onChange={(_, newValue) => {
                            }}
                        />
                    </Grid>
                </>}
            </Grid>
        </Box>
    );
};

export default HomePageSearch;

