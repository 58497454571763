import { CircularProgress, Stack } from '@mui/material';
import asyncComponentLoader from './loader';
import type { AnyProps, LoadComponent, LoaderDefaultOptions } from './types';

export const loaderDefaultOptions = { delay: 3000,  minimumLoading: 3000, };

export const FallbackLoading: React.FC<{ height?: string }> = ({ height }) => {
  return (<>
      <Stack direction="column" alignItems="center" justifyContent="center" width="100%" height={height || '100%'}>
          <CircularProgress />
      </Stack>
  </>);
}

const configuredAsyncComponentLoader = (
  loadComponent: LoadComponent,
  additionalProps: AnyProps = {},
  loaderOptions: LoaderDefaultOptions = loaderDefaultOptions,
  FallbackWaiting = FallbackLoading,
) => asyncComponentLoader(loadComponent, additionalProps, loaderOptions, FallbackWaiting);

export default configuredAsyncComponentLoader;
