import { IconButton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import DashboardTitleEditor from "../dashboard-header/DashboardTitleEditor";
import {ReactComponent as EditPencilIcon} from "../../../assets/icons/editPencil.svg";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { scrollbarStyle } from "../../../shared/dashboard";

const useStyles = makeStyles(() => ({
    header: {
        width: '100%',
        marginBottom: '16px',
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '2rem',
        fontWeight: 'bold',
        overflowX: 'auto',
        ...scrollbarStyle,
    },
    icon: {
        width: '30px',
        height: '30px',
    },
}));

const TitleEditor: React.FC<{
    title: string,
    onUpdateTitle?: (title: string) => boolean,
}> = ({ title, onUpdateTitle }) => {
    const classes = useStyles();
    const [isExisting, setIsExisting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    return (<>
        <Stack direction="row" className={classes.header} alignItems="center" justifyContent="space-between">
            <Typography color="gray">Company name</Typography>
            { isEditing && isExisting && (
                <Typography color="red" fontSize="0.8rem">
                    <Stack direction="row" alignItems="center">
                        <ErrorOutlineIcon sx={{ width: '0.8em', height: '0.8em', mr: '4px', }}/>
                        Dashboard already exists!
                    </Stack>
                </Typography>
            )}
        </Stack>
        {isEditing ? (
            <DashboardTitleEditor variant="h4"
                title={title}
                onUpdate={(title) => {
                    if (onUpdateTitle?.(title)) {
                        setIsEditing(false);
                        setIsExisting(false);
                        return true;
                    } else {
                        setIsExisting(true);
                        return false;
                    }
                }}
                onCancel={() => {setIsEditing(false); setIsExisting(false);}}
                edit={isEditing}
            />
        ) : (
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <Typography className={classes.title}
                    onDoubleClick={() => setIsEditing(true)}
                > {title} </Typography>
                <IconButton onClick={() => setIsEditing(true)}>
                    <EditPencilIcon className={classes.icon} />
                </IconButton>
            </Stack>
        )}
    </>);
}

export default TitleEditor;
