import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Dashboard } from "../../../types/files";
import { isDashboardNew } from "../../../shared/dashboard";

const New: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    return isDashboardNew(dashboard) ? (
        <>
            <Box
                className={classes.dashboardNewBubble}
            >
                <Typography variant="body1">NEW</Typography>
            </Box>
        </>
    ) : <div />
}

export default New;

const useStyles = makeStyles(() => ({
    dashboardNewBubble: {
        display: "inline-flex",
        padding: "5px 10px",
        borderRadius: 15,
        color: "white",
        backgroundColor: "#B6D7A8",
        height: 35,
        margin: 6,
        marginLeft: 12
    },
}));
