import { ArticleCategory } from "../../types/search";
import { ReactComponent as AnnouncementIcon } from "../../assets/icons/announcement.svg";
import { ReactComponent as FinancingIcon } from "../../assets/icons/financing.svg";
import { ReactComponent as NewsIcon } from "../../assets/icons/news.svg";
import { ReactComponent as SocialMediaIcon } from "../../assets/icons/social-media.svg";

export const getActivityFeedEventIcon = (category?: ArticleCategory | undefined, cssClass?: string, color?: string) => {
    switch (category) {
        case ArticleCategory.ANNOUNCEMENT:
            return <AnnouncementIcon className={cssClass} stroke={color || "#666666"} />;

        case ArticleCategory.FUNDRAISING:
            return <FinancingIcon className={cssClass} stroke={color || "#666666"} />;

        case ArticleCategory.SOCIAL_MEDIA:
            return <SocialMediaIcon className={cssClass} stroke={color || "#666666"} />;

        case ArticleCategory.OTHER:
        default:
            return <NewsIcon className={cssClass} fill={color || "#666666"} />;
    }
}