import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Logo10 from "../../../public/images/Notissia logos/Notissia/Ativo 10.png";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        background: 'white',
    },
    logoContainer: {
        position: 'fixed',
        top: '30%',
    },
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '1.5rem',
        fontWeight: 700,
        textTransform: 'none',
        color: theme.colors.neutral['900'],
    },
    bottomTextContainer: {
        position: 'fixed',
        bottom: 32,
        display: "flex",
        justifyContent: "center",
    },
    campain: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 400,
        color: theme.colors.neutral['700'],
    },
    invalid: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.error['500'],
    },
    button :{
        width: 120,
        height: 40,
        borderRadius: 24,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const MobileAccessPage: React.FC<{}> = () => {
    const classes = useStyles();

    return (<>
        <Stack spacing={3} className={classes.container} alignItems="center" justifyContent="flex-start" mt='30vh'>
            <img src={Logo10} alt="Notissia logo" loading="eager" width="150px" style={{ marginLeft: '20px' }} />
            <Stack spacing={1} alignItems="center" justifyContent="center" pt={3}>
                <Typography className={classes.mainTitle}>
                    {'Notissia is only available on desktop.'}
                </Typography>
            </Stack>
            <Box className={classes.bottomTextContainer}>
                <Typography className={classes.campain} >
                    <Typography component="a"
                        className={classes.campain}
                        color={`${theme.palette.primary.main} !important`}
                        href="https://notissia.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        display="inline">
                        {'Notissia'}
                    </Typography>
                    &nbsp;{'is an AI-powered deal flow database for investors.'}
                </Typography>
            </Box>
        </Stack>
    </>);

};

export default MobileAccessPage;