import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import AnswerSources from '../dashboard-query-answer/AnswerSources';
import { scrollbarStyle } from '../../../shared/dashboard';
import SkeletonLoader from '../../atoms/SkeletonLoader';
import { DashboardQueryAnswer } from '../../../contexts/DashboardQueriesContext';
import useContentCopy from '../../../hooks/useContentCopy';
import AnswerHeader from '../dashboard-query-answer/AnswerHeader';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.5s ease',
    },
    contentOnly: {
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 8,
        paddingRight: 8,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerBlock: {
        width: '100%',
        height: 'auto',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    icon: {
        width: 40,
        height: 40,
        padding: 'unset',
    },
}));

const MaxWaitLoadingTime = 15;

const CustomQueryContainer: React.FC<{
    queryAnswer: DashboardQueryAnswer,
    extraTooltip?: ReactNode,
    displayMenu?: boolean,
    expandableContent?: boolean
    hideAiGenerated?: boolean,
    contentOnly?: boolean,
}> = ({ queryAnswer, children, extraTooltip, displayMenu, expandableContent, hideAiGenerated, contentOnly }) => {
    const classes = useStyles();
    const { getCopiableId } = useContentCopy();

    const [contentExpanded, setContentExpanded] = useState<boolean>(false);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const answeredQuestion = queryAnswer.answeredQuestion;
    const manualOverride = queryAnswer.manualOverride;
    const lastUpdatedDate = queryAnswer.updatedAt;
    const copiableId = getCopiableId(answeredQuestion?.question);

    useEffect(() => {
        if ('answer' in answeredQuestion)
            setShowAnswer((!!answeredQuestion?.answer || !!answeredQuestion?.type)
                || (!!answeredQuestion?.time && moment().diff(answeredQuestion.time, "seconds") > MaxWaitLoadingTime));
        else
            setShowAnswer(true);
    }, [answeredQuestion]);

    return (<>
        <Box className={classnames(classes.masonryElement, contentOnly && classes.contentOnly)}>
            <AnswerHeader
                answeredQuestion={answeredQuestion}
                optionalChild={extraTooltip}
                lastUpdatedDate={lastUpdatedDate}
                manualOverride={manualOverride}
                hideAiGenerated={hideAiGenerated}
                hideCopy={contentOnly} />
            <Stack direction="row" className={classes.answerContainer} justifyContent="space-between">
                <Box id={copiableId} className={classes.answerBlock}
                    style={{ height: (!contentExpanded && answeredQuestion.question === 'Funding') ? 130: 'auto' }}>
                    {showAnswer ? ( children ) : (
                        <SkeletonLoader variant="regular" rowCount={4} cropLast />
                    )}
                </Box>
                {(!!answeredQuestion.answer && expandableContent) && (<>
                    <Stack direction="column" alignItems="center" justifyContent="flex-end" width="40px">
                        <IconButton size="small"
                            className={classes.icon}
                            ref={anchorRef}
                            onClick={(e) => {
                                e.stopPropagation();
                                setContentExpanded(prev => !prev);
                            }}
                            style={{ display: contentExpanded && (displayMenu && !!answeredQuestion?.sources) ? 'none' : 'inline-flex' }}
                        > <ExpandMoreIcon fontSize="small" style={{ transform: !contentExpanded ? 'none' : 'rotate(180deg)' }} />
                        </IconButton>
                    </Stack>
                </>)}
            </Stack>
            {(displayMenu && contentExpanded) && (
                <AnswerSources
                    answeredQuestion={answeredQuestion}
                    collapseElement={
                        <Stack justifyContent="center" width="40px">
                            <IconButton size="small"
                                className={classes.icon}
                                ref={anchorRef}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setContentExpanded(false);
                                }}
                            > <ExpandMoreIcon fontSize="small" style={{ transform: 'rotate(180deg)' }} />
                            </IconButton>
                        </Stack>
                    }
                />
            )}
        </Box>
    </>);
}

export default CustomQueryContainer;
