import React, { SetStateAction } from "react";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import { useDebouncedCallback } from "use-debounce";

const useStyles = makeStyles((theme) => ({
    form: {
        padding: '16px 4px',
        width: '100%',
    },
    icon: {
        width: 24,
        height: 24,
		fill: theme.palette.primary.main,
    },
    inputField: {
        width: '100%',
        margin: '16px 0',
        "& .MuiOutlinedInput-root": {
            width: '100%',
            height: '40px',
            borderRadius: 32,
            background: '#fff',
            "& > input": {
                fontSize: 16,
            },
            "& fieldset": {
              borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: '#048290 !important',
            },
        },
    },
}));

const InvestorEmailInput: React.FC<{
	email: string,
	placeholder?: string,
    onEmail: React.Dispatch<SetStateAction<string>>,
    inlineCaption?: boolean,
 }> = ({ email, placeholder, onEmail, inlineCaption })=> {
    const classes = useStyles();
    const debounced = useDebouncedCallback(value => onEmail(value.trim()), 30, { maxWait: 100 });

    return (<>
        {!inlineCaption && (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                <EmailIcon className={classes.icon}/>
                <Typography variant="h6-bold">{placeholder || 'Enter investor email'}</Typography>
            </Stack>
        )}
        <TextField variant="outlined"
            className={classes.inputField}
            placeholder={placeholder || "Enter investor email..."}
			value={email}
            onChange={(e) => debounced(e.target.value as string)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon className={classes.icon} />
                </InputAdornment>
              ),
            }}
        />
    </>);
}

export default InvestorEmailInput;