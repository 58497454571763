import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Workspace } from "../../../types/files";
import {ReactComponent as InvestorsIcon} from "../../../assets/icons/investors.svg";
import { useDebouncedCallback } from "use-debounce";
import theme from "../../../theme";
import HoverEditable from "../HoverEditable";

const useStyles = makeStyles((theme) => ({
    emailAdd: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        color: '#696E70',
    },
    inputField: {
        "& .MuiInputBase-root": {
            width: 180,
            height: 40,
            padding: '0 8px !important',
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    button: {
        width: 80,
        height: 36,
        borderRadius: 24,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    icon: {
        color: theme.colors.neutral['500'],
    },
    name: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
}));

const InvestorsIconFC: React.FC<{ color: string }> = ({ color }) => (
    <InvestorsIcon width={32} height={32} stroke={color} />
);

const WorkspaceEmailEditor: React.FC<{
    workspace?: Workspace,
    onUpdate?: (workspace: Workspace) => void,
}> = ({ workspace, onUpdate }) => {
    const classes = useStyles();
    const [edit, setEdit] = useState<boolean>(false);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const emailRef = useRef<TextFieldProps>(null);

    const debouncedEmail = useDebouncedCallback((value) => {
        if (!!emailRef.current)
            emailRef.current.value = value;
        setValidEmail(!!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));
    }, 100, { maxWait: 500 });

    const handleUpdate = useCallback(() => {
        if (!!emailRef.current?.value)
            onUpdate?.({ ...workspace!, email: emailRef.current?.value as string});
        setEdit(false);
    }, [onUpdate, workspace]);

    useEffect(() => {
        if (!workspace!.email)
            setEdit(true);
        else
            setValidEmail(!!workspace!.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));
    }, [workspace]);

    if (!workspace)
        return (<></>);

    return (<>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" width="100%">
            <InvestorsIconFC color={theme.colors.neutral['500']} />
            {edit ? (<>
                <Stack direction="row" alignItems="center">
                    <TextField variant="standard"
                        className={classes.inputField}
                        placeholder="Enter email..."
                        defaultValue={workspace.email}
                        onChange={(e) => debouncedEmail(e.target.value)}
                        inputRef={emailRef}
                        sx={{ width: '100%' }} />
                    <Button variant="outlined"
                        className={classes.button}
                        disabled={!validEmail}
                        onClick={handleUpdate}>{'Save'}</Button>
                </Stack>
            </>) : (<>
                <Stack direction="column" alignItems="flex-start" width="100%">
                    <Typography className={classes.name}>{workspace.name}</Typography>
                    <HoverEditable variant="small" onClick={() => setEdit(true)}>
                        <Typography className={classes.emailAdd}>{workspace.email}</Typography>
                    </HoverEditable>
                </Stack>
            </>)}
        </Stack>
    </>);
}

export default WorkspaceEmailEditor;
