import { useContext, useEffect, useState } from 'react';
import { SearchContext } from '../../../contexts/SearchContext';
import { CompanyFile } from '../../../types/files';
import { SearchType, SearchResult } from '../../../types/search';
import SearchResultsLayout from '../../folders/components/SearchResultsLayout';

interface Props {
    loading: boolean
    heightToSubtract: number;
}

const HomeSearchResultsContainer = ({
    loading,
    heightToSubtract,
}: Props) => {
    const [selectedSearchResult, setSelectedSearchResult] = useState<SearchResult | undefined>();
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const {
        contextSelectedSearchResult,
        setContextSelectedSearchResult,
        contextSearchKeywords,
        contextAdvancedSearchObject,
        contextSearchResults,
    } = useContext(SearchContext);

    useEffect(() => {
        setSearchResults(contextSearchResults);
        setSelectedSearchResult(undefined);
        setContextSelectedSearchResult(undefined);
        // eslint-disable-next-line
    }, [contextSearchResults]);

    useEffect(() => {
        setSelectedSearchResult(contextSelectedSearchResult);
    }, [contextSelectedSearchResult]);

    const onResultSelection = (result: SearchResult | CompanyFile) => {
        setSelectedSearchResult(result as SearchResult);
        setContextSelectedSearchResult(result as SearchResult);
    }

    const getSearchFolder = () => {
        return contextAdvancedSearchObject?.value ? contextAdvancedSearchObject?.folder as CompanyFile : null;
    }

    return (
        <SearchResultsLayout
            keywords={contextSearchKeywords}
            loading={loading}
            searchResults={searchResults}
            titleSearchResults={[]}
            selectedSearchResult={selectedSearchResult}
            onClose={() => { }}
            onResultSelection={onResultSelection}
            searchType={SearchType.Keyword}
            searchFolder={getSearchFolder()}
            heightToSubtract={heightToSubtract}
            isHomeSearch={true}
        />
    );
};

export default HomeSearchResultsContainer;
