import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import jsPDF from "jspdf";
import { CommentOriginType, Comment } from "../../../types/comments";
import { SaveDashboardItemType } from "../../../types/common";
import { DashboardSelection, DashboardUid } from "../../../types/files";
import FilePreview from "../../folders/components/FilePreview";

const useStyles = makeStyles((theme) => ({
    viewerPaperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: "100%",
        overflow: "hidden",
        justifyContent: "center",
        borderRadius: 4,
        position: 'relative',
    },
    titleContainer: {
        cursor: "pointer"
    },
}));

const DashboardSelectionComponent: React.FC<{
    dashboardId: string,
    data: DashboardSelection,
    dashboardItemType: SaveDashboardItemType,
    comments: Comment[],
    onCommentAdded: (item: Comment) => void,
    selectedCommentId: string | null | undefined,
    selectedCommentParentId: string | null | undefined,
    defaultHeight?: string,
    enableComments?: boolean
}> = ({ dashboardId, data, dashboardItemType, comments, onCommentAdded, selectedCommentId, selectedCommentParentId, defaultHeight, enableComments = true }) => {
    const classes = useStyles();

    const [fileUrl, setFileUrl] = useState<string>("");

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(fileUrl);
        }
    }, [fileUrl]);

    useEffect(() => {
        if (data.selectedText) {
            const distanceFromTop = 10;
            const pageWidth = 210; // A4
            const pageHeight = 297; // A4

            let doc = new jsPDF("portrait", "mm", "a4");

            const fullText = `"${data.selectedText}"`;
            var splitText = doc.splitTextToSize(fullText, pageWidth - 10, { fontSize: 19 });
            const textSize = doc.getTextDimensions(splitText, { fontSize: 19 });


            if (textSize.h > pageHeight) {
                doc = new jsPDF("p", "mm", [pageWidth, textSize.h + distanceFromTop]);
            } else {
                doc = new jsPDF("l", "mm", [pageWidth, textSize.h + distanceFromTop]);
            }

            doc.setFont("Helvetica");
            doc.setFontSize(19);
            doc.text(splitText, 5, distanceFromTop, { maxWidth: pageWidth - 10 });

            const pdfBlob = doc.output("blob");
            const url = URL.createObjectURL(pdfBlob);

            setFileUrl(url);
        }
    }, [data]);

    return (
        <div style={{ flexDirection: "column" }}>
            <div>
                <Paper className={classes.viewerPaperContainer} sx={{ height: defaultHeight || "460px" }}>
                    <FilePreview
                        fileKey={data.id}
                        displayFromSource
                        sourceUrl={fileUrl}
                        textOnly
                        enableDashboard={false}
                        sourceComments={comments}
                        enableComments={enableComments}
                        enableCommentsPane={false}
                        onCommentAdded={onCommentAdded}
                        selectedCommentId={selectedCommentId}
                        selectedCommentParentId={selectedCommentParentId}
                        commentOriginType={CommentOriginType.Dashboard}
                        dashboardItem={{
                            id: dashboardId,
                            dashboardItemId: data.id,
                            dashboardItemType: dashboardItemType,
                            documentKey: data.key
                        } as DashboardUid}
                    />
                </Paper>
            </div>
        </div>
    )
};

export default DashboardSelectionComponent;