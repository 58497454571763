import { useContext, useEffect, useState } from 'react';
import { SearchNavigationContext } from '../../contexts/SearchNavigationContext';
import { FolderTab } from '../../types/files';
import FolderTabs from '../folders/components/FolderTabs';

const FolderTabsContainer = () => {
  const { contextSetFolderTabs,
    contextSetSelectedFolderTab,
    contextSelectedTab,
    contextTabs,
  } = useContext(SearchNavigationContext);

  const [tabs, setTabs] = useState<FolderTab[]>(contextTabs);
  const [selectedTab, setSelectedTab] = useState<number>(contextSelectedTab);
  const [nextIndex, setNextIndex] = useState(contextTabs.length ? contextTabs.length : 1);

  useEffect(() => {
    if (tabs.length <= 0) {
      setTabs([
        { name: 'Tab 1', value: 0 },
      ]);
      contextSetFolderTabs([
        { name: 'Tab 1', value: 0 },
      ]);
      setSelectedTab(0);
      contextSetSelectedFolderTab(0);
      setNextIndex(1);
    }
    //eslint-disable-next-line
  }, [tabs]);

  const closeTab = (tab: number) => {
    setTabs((prevState) => {
      const newState = prevState.filter(({ value }) => value !== tab);
      if (tab === selectedTab) {
        setSelectedTab(newState[0]?.value);
        contextSetSelectedFolderTab(newState[0]?.value);
      }
      return newState;
    });
    contextSetFolderTabs(contextTabs.filter(x => x.value !== tab));
  };

  const addNewTab = () => {
    const newTab = {
      name: `Tab ${nextIndex + 1}`,
      value: nextIndex,
    };
    const newTabs = [...tabs, newTab];
    setTabs(newTabs);
    contextSetFolderTabs(newTabs);
    setNextIndex((prevState) => prevState + 1);
    setSelectedTab(newTab.value);
    contextSetSelectedFolderTab(newTab.value);
  };

  return (
    <FolderTabs
      tabs={tabs}
      selectedTab={selectedTab}
      onTabChange={(res) => { setSelectedTab(res); contextSetSelectedFolderTab(res); }}
      closeTab={closeTab}
      addNewTab={addNewTab}
    />
  );
};

export default FolderTabsContainer;
