import React, { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import { DashboardQueriesContext } from "../../../contexts/DashboardQueriesContext";
import { DashboardQuery } from "../../../types/files";
import { addDashboardQueryFunc } from "../../../lib/helper";
import { Column } from "../../organisms/dashboard-queries/CompanyDetailsColumnQueries";
import { CompanyDetailsQueries } from "../../atoms/AddSection";

type SelectionType = {
    displayTitle: string;
    query: DashboardQuery;
    checked: boolean;
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 430,
            height: 430,
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.25rem',
    },
    dialogContent: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px 0',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
        gap: 8,
    },
    checklist: {
        height: '100%',
        width: '100%',
        overflowY: 'auto',
    },
    listItem: {
       "& > .MuiTypography-root": {
            fontFamily: 'Inter',
            fontSize: '0.95rem',
            fontWeight: 700,
       }
    },
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        minWidth: 80,
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const AddSectionModal: React.FC<{
    isOpen: boolean,
    children?: ReactNode,
    onClose: () => void,
}> = ({ isOpen, children, onClose }) => {
    const classes = useStyles();
    const { queries, defaultQueryTemplateValues, removeQuery } = useContext(DashboardQueriesContext);

    const [selectedQueries, setSelectedQueries] = useState<SelectionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleFilterQueries = useCallback((displayTitle: string) => () => {
        setSelectedQueries(prev => prev.map(selection =>
            selection.displayTitle === displayTitle ? ({...selection, checked: !selection.checked}) : selection));
    }, []);

    const handleSaveFilteredQueries = useCallback(() => {
        const promises: Promise<any>[] = [];

        setLoading(true);
        selectedQueries.forEach(({ query, checked }) => {
            if (checked) {
                if (!!query.deletedAt) {
                    promises.push(addDashboardQueryFunc(
                        query.dashboardId,
                        query.title,
                        query.group,
                        query.query,
                        query.answer || '',
                        query.history,
                        query.order,
                        query.manualOverride || true,
                        query.source,
                        false,
                    ));
                }
            } else {
                if (!query.deletedAt) {
                    promises.push(new Promise((resolve) => {
                        removeQuery(query);
                        resolve({});
                    }));

                }
            }
        });

        Promise.all(promises).then(() => {
            setLoading(false);
            onClose();
        });
    // eslint-disable-next-line
    }, [selectedQueries]);

    useEffect(() => {
        const customQueries: string[] = queries
            .filter(query => !defaultQueryTemplateValues.find(defQuery => defQuery?.title === query.title))
            .map(query => query.title);

        setSelectedQueries(
            queries.filter(query => [...CompanyDetailsQueries, ...customQueries].includes(query.title))
                .map(query => ({query,
                    displayTitle: defaultQueryTemplateValues
                        .find(defQuery => defQuery?.title === query.title)?.displayTitle || query.title,
                    checked: !query.deletedAt,
                })) as SelectionType[]
        );
    }, [defaultQueryTemplateValues, queries]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {`Add section in ${Column}`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Typography fontWeight="bold">{'Select the information to be shown:'}</Typography>
                <List className={classes.checklist} >
                    {selectedQueries.map(({displayTitle, checked}, i) => (
                        <ListItem key={'add-section-modal-151-' + i} disablePadding>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" mr={2}>
                                <ListItemButton role={undefined} onClick={handleFilterQueries(displayTitle)} dense>
                                    <ListItemIcon>
                                        <Checkbox edge="start" checked={checked} disableRipple />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listItem} primary={displayTitle} />
                                </ListItemButton>
                            </Stack>
                        </ListItem>
                    ))}
                </List>
                {children}
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onClose()}
                > Cancel </Button>
                <LoadingButton
                    variant="contained"
                    className={classes.commonButton}
                    loading={loading}
                    onClick={handleSaveFilteredQueries}
                > Save </LoadingButton>
            </DialogActions>
        </Dialog>
    </>);
}

export default AddSectionModal;