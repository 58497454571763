import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, Chip, Divider, Fade, InputAdornment, Paper, Popper, Stack, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ArrowDropDown as ActiveDropDownIcon} from '@mui/icons-material';
import {ChangeHistory as InactiveDropDownIcon} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import classnames from 'classnames';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
    popover: {
        width: '92% !important',
        zIndex: 1300,
        '& > .MuiPaper-root': {
            padding: '20px 16px 10px 16px',
            marginTop: '10px',
            borderRadius: 16,
        }
    },
    modalTitle: {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '1rem',
    },
    popoverButton: {
        width: '150px',
        height: '40px',
        borderRadius: 20,
        padding: '16px',
        paddingRight: '16px',
        backgroundColor: '#E0F1F1',
        fontSize: 14,
        textTransform: 'none',
    },
    inputField: {
        minWidth: '300px',
        backgroundColor: 'rgb(247, 249, 250)',
        borderColor: 'rgb(223, 226, 230)',
        "& input::placeholder": {
            fontSize: 14
        },
        "& .MuiOutlinedInput-root": {
            "& > input": {
                fontWeight: 'bold',
                color: 'rgb(0, 0, 0)',
            },
            "&:hover fieldset": {
              borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: '#048290 !important',
            },
        },
    },
    chip: {
        minWidth: '96px',
        height: '36px',
        borderRadius: '32px',
        padding: '8px',
        marginRight: '10px',
        marginBottom: '10px',
        fontWeight: 'lighter',
        fontSize: '0.8rem',
        textTransform: 'none',
        backgroundColor: '#E0F1F1',
        color: '#048290',
        "&:hover": {
            backgroundColor: "rgba(4, 130, 144, 0.04)",
        },
    },
    activeChip: {
        minWidth: '96px',
        height: '36px',
        borderRadius: '32px',
        padding: '8px',
        marginRight: '10px',
        marginBottom: '10px',
        fontWeight: 'bolder',
        fontSize: '0.8rem',
        textTransform: 'none',
        backgroundColor: '#048290',
        color: '#fff',
        "&:hover": {
            backgroundColor: "rgb(2, 91, 100)",
        },
    },
    addButton: {
        textTransform: 'none',
        minWidth: '0 !important',
        fontWeight: 'bold',
        color: '#048290',
    },
    saveButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        borderRadius: '20px',
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    cancelButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        backgroundColor: '#EAECEF',
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        color: '#AEB5BC',
    },
}));

export interface NoteSelectionField {
    label: string, selected: boolean, custom: boolean,
}

const SelectionFieldModal: React.FC<{
    fields: Array<NoteSelectionField>,
    defaultFields: Array<NoteSelectionField>,
    disabled: boolean,
    onSave: (fields: Array<NoteSelectionField>) => void,
}> = ({ fields, defaultFields, disabled, onSave }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedFields, setSelectedFields] = useState<Array<NoteSelectionField>>([]);
    const [inputVal, setInputValue] = useState<string>("");
    const [isSaving, setSaving] = useState<boolean>(false);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => !previousOpen);
    }

    const handleChipSelect = (field: NoteSelectionField) => {
        setSelectedFields(prev => prev.map(selectedField => {
            if (selectedField.label === field.label)
                return {...selectedField, selected: !selectedField.selected};
            return selectedField;
        }));
    }

    const handleDelete = (field: NoteSelectionField) => {
        setSelectedFields(prev => prev.filter(selectedField => (selectedField.label !== field.label)));
    }

    useEffect(() => {
        if (open)
            setSelectedFields([...defaultFields]);
    }, [defaultFields, open]);

    return (
        <Box>
            <Button
                endIcon={open ? <ActiveDropDownIcon style={{transform: 'scale(1.5, 2.5)'}}/>
                    : <InactiveDropDownIcon style={{transform: 'scale(0.8) rotate(180deg)'}}/>}
                className={classes.popoverButton}
                onClick={handleOpen}
                disabled={disabled}
            > Add fields... </Button>
            <Popper
                open={open}
                anchorEl={anchorEl}
                className={classes.popover}
                placement="bottom-end"
                transition
                disablePortal>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={50}>
                        <Paper elevation={6}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                                <Typography className={classes.modalTitle}>Select data from dashboard:</Typography>
                                <TextField
                                    className={classes.inputField}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Add custom field..."
                                    value={inputVal}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    InputProps={{ endAdornment: inputVal && (
                                        <InputAdornment position="end" >
                                            <Button variant="text"
                                                className={classes.addButton}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setSelectedFields(prev => {
                                                        if (inputVal && !prev.some((field) => field.label === inputVal))
                                                            prev.push({label: inputVal, selected: true, custom: true});
                                                        return prev;
                                                    });
                                                    setInputValue("");
                                                }}
                                            > Add </Button>
                                        </InputAdornment>),
                                    }}
                                />
                            </Stack>
                            <Box sx={{minHeight: '15vh', height: 'auto'}}>
                                <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                                    {selectedFields?.map((field, i) => field.custom ? (
                                        <Chip className={classnames(field.selected ? classes.activeChip : classes.chip)}
                                            label={field.label}
                                            onDelete={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleDelete(field);
                                            }}
                                            deleteIcon={<ClearIcon />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleChipSelect(field);
                                            }}
                                            sx={{ "& .MuiChip-deleteIcon": {margin: 0, color: 'darkgray', "&:hover": {color: 'inherit'}} }}
                                            key={'selection-field-modal-217-' + i}/>
                                    ) : (
                                        <Chip className={classnames(field.selected ? classes.activeChip : classes.chip)}
                                            label={field.label}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                handleChipSelect(field);
                                            }}
                                            key={'selection-field-modal-226-' + i}/>
                                    ))}
                                </Stack>
                            </Box>
                            <Divider />
                            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" pt={2} pb={1}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={(e) => setOpen(false)}
                                > Cancel </Button>
                                <LoadingButton
                                    variant="contained"
                                    className={classes.saveButton}
                                    loading={isSaving}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setSaving(true);
                                        onSave([...selectedFields]);
                                        setSelectedFields([...defaultFields]);
                                        setOpen(false);
                                        setSaving(false);
                                    }}
                                > Add </LoadingButton>
                            </Stack>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Box>
    );
}

export default SelectionFieldModal;
