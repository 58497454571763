import { Button, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from "classnames";

const useStyles = makeStyles(() => ({
    commonButton: {
        width: '130px',
        height: '38px',
        paddingLeft: '16px',
        paddingRight: '16px',
        borderRadius: 20,
        fontSize: 16,
        fontWeight: 700,
        textTransform: 'capitalize',
        "&.Mui-disabled": {
            border: '1px solid lightgray',
        }
    },
    cancelButton: {
        border: '1px solid #048290',
        color: '#048290',
    },
    closeButton: {
        color: '#fff',
    },
}));

const ActionButtons: React.FC<{
    isUploading?: boolean,
    hasUploaded: boolean,
    onClose?: () => void,
}> = ({ isUploading, hasUploaded, onClose }) => {
    const classes = useStyles();

    return (<>
        <Stack direction={hasUploaded ? 'column' : 'row'}
            spacing={2}
            alignItems="center"
            justifyContent={hasUploaded ? 'center' : 'flex-end'}
            width="100%"
            padding={hasUploaded ? '8px' : '24px 0 8px'}>
            {hasUploaded && (
                <Stack alignItems="center" justifyContent="center">
                    <Typography fontSize="1.2rem" fontWeight="bold" color="#048290">You can close this window.</Typography>
                    <Typography fontSize="0.9rem" color="#666666">Your file(s) will appear on the dashboard shortly (may take up to 1 min).</Typography>
                </Stack>
            )}
            <Button variant={hasUploaded ? 'contained' : 'outlined'}
                className={classnames(classes.commonButton, hasUploaded ? classes.closeButton : classes.cancelButton)}
                disabled={isUploading}
                onClick={onClose}>
                {hasUploaded ? 'Close' : 'Cancel'}
            </Button>
        </Stack>
    </>);
}

export default ActionButtons;