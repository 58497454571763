import React, { useContext } from "react";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { Dashboard, DashboardQuery } from "../../../types/files";
import ExportDocument from "./ExportDocument";
import { AuthContext } from "../../../contexts/AuthContext";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
}));

const ExportPDF: React.FC<{
    dashboard: Dashboard|null,
    queries: DashboardQuery[],
    preview?: boolean,
    onDownload?: () => void,
}> = ({ dashboard, queries, preview, onDownload }) => {
    const classes = useStyles();
    const { userGroup: author } = useContext(AuthContext);

    if (!dashboard)
        return (<></>);

    if (preview) {
        return (<>
            <PDFViewer width="100%" height="100%">
                <ExportDocument bulkDashboards={[{dashboard, queries}]} author={author} />
            </PDFViewer>
        </>);
    }

    return (<>
        <PDFDownloadLink document={
            <ExportDocument bulkDashboards={[{dashboard, queries}]} author={author} />
        } fileName={`${dashboard!.title}.pdf`} onClick={onDownload}>
            {() => (
                <Button
                    variant="contained"
                    className={classes.commonButton}
                    disabled={!dashboard}
                > Download </Button>
            )}
        </PDFDownloadLink>
    </>);
}

export default ExportPDF;