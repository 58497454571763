import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Container, Divider, Stack, Typography } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import IntegrationIcons from "../atoms/IntegrationIcons";
import { scrollbarStyle } from "../../shared/dashboard";
import { useParams, useHistory } from "react-router-dom";
import GroupsTable from "../organisms/admin/GroupsTable";
import UsersTable from "../organisms/admin/UsersTable";

const useStyles = makeStyles(() => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    button: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderWidth: 3,
        textTransform: 'none',
        padding: '8px 24px',
        fontSize: '1.25rem',
        fontWeight: 'bold',
    },
}));

const AdminPage: React.FC<{}> = () => {
    const classes = useStyles();
    const params: {[key: string]: string} = useParams();
    const history = useHistory();
    const { isNotissiaAdmin, userGroup } = useContext(AuthContext);

    const [selectedGroup, setSelectedGroup] = useState<string | undefined>(params?.group);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!!params) {
            if (params?.group)
                setSelectedGroup(params?.group);
            else
                setSelectedGroup(undefined);
            setLoaded(true);
        }
    }, [params]);

    if (!loaded)
        return (<></>);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={2} pt={4} pb={2}>
                {!isNotissiaAdmin && (!selectedGroup || selectedGroup !== userGroup) ? (
                    <Typography variant="h4-bold">Permission Denied!</Typography>
                ) : (<>
                    <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" width="100%">
                        <Typography variant="h4-bold">{`Add ${!!selectedGroup ? 'user' : 'group'}s to the workspace`}</Typography>
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                            <Button variant="contained" className={classes.button} onClick={() => history.push('/subscriptions')}>
                                {'Manage Subscription'}
                            </Button>
                            <IntegrationIcons />
                        </Stack>
                    </Stack>
                    <Divider sx={{ width: '100%' }} />
                    {!selectedGroup ? (
                        <GroupsTable onSelectGroup={setSelectedGroup} />
                    ): (
                        <UsersTable selectedGroup={selectedGroup} />
                    )}
                </>)}
            </Stack>
        </Container>
    </>);
}

export default AdminPage;