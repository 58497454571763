import React, { useContext, useEffect, useMemo } from "react";
import KeyPeopleAnswer from "../dashboard-query-answer/KeyPeopleAnswer";
import { Stack } from "@mui/material";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import useContentCopy from "../../../hooks/useContentCopy";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";

const KeyPeopleQuery: React.FC<{}> = () => {
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { queryAnswer, removeQuery } = useDashboardQuery('Key People');

    const { getCopiableId } = useContentCopy();
    const copiableId = getCopiableId('Key People');

    const isVisibleByDefault = useMemo(() =>
        defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Key People')?.visibleByDefault ?? true
    , [defaultQueryTemplateValues]);

    const isPresent = useMemo(() => 'answer' in queryAnswer && !!queryAnswer.answer, [queryAnswer]);
    const isDeleted = useMemo(() => 'deletedAt' in queryAnswer && !!queryAnswer.deletedAt, [queryAnswer]);
    const isHidden = useMemo(() => isDeleted || (!isVisibleByDefault && !isPresent), [isDeleted, isPresent, isVisibleByDefault]);

    useEffect(() => {
        if (!isDeleted && (!isVisibleByDefault && isPresent) && !queryAnswer.manualOverride)
            removeQuery(queryAnswer);
    // eslint-disable-next-line
    }, [isDeleted, isPresent, isVisibleByDefault, queryAnswer]);

    if (isHidden)
        return (<></>);

    return (<>
        <Stack id={copiableId} width="100%">
            <KeyPeopleAnswer keyPeople={queryAnswer?.answeredQuestion} />
        </Stack>
    </>);
}

export default KeyPeopleQuery;
