import React, { createContext, useEffect, useState, useContext } from "react";
import { getAllUsersFunc } from "../lib/helper";
import { User } from "../types/common";
import { AuthContext } from "./AuthContext";
interface Context {
    users: User[];
    usersLoading: boolean;
    refetch: () => void;
}

export const UsersContext = createContext<Context>({
    users: [],
    usersLoading: false,
    refetch: () => { },
});

interface Props {
    children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
    const { userGroup } = useContext(AuthContext);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userGroup]);

    const fetch = () => {
        if (userGroup) {
            setLoading(true);

            getAllUsersFunc(userGroup).then(
                (result) => {
                    setLoading(false);
                    setUsers(result);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }

    return (
        <UsersContext.Provider
            value={{ users, usersLoading: loading, refetch: fetch }}
        >
            {children}
        </UsersContext.Provider>
    );
};

export default Provider;
