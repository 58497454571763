import { FC, useContext } from 'react';
import SingleStatusBadge from './SingleStatusBadge';
import { GroupSettingsContext } from '../../../contexts/GroupSettingsContext';

const StatusBadge: FC<{ title: string, callback?: () => void }> = ({ title, callback }) => {
    const { statuses } = useContext(GroupSettingsContext);

    return statuses.map(q => q.toLowerCase()).includes(title.toLowerCase())
        ? <SingleStatusBadge title={title} callback={callback} />
        : null;
};

export default StatusBadge;