import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Checkbox, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import { Dashboard, InvestmentThesis as InvestmentThesisType, Workspace } from "../../../types/files";
import PreferencesModal from "../../modals/workspaces/PreferencesModal";
import WorkspaceMenu from "../workspace-menus/WorkspaceMenu";
import {ReactComponent as WorldGridIcon} from "../../../assets/icons/world.svg";
import mailProviders from "../../../helpers/mailProviders.json";
import InvestmentThesisModal from "../../modals/workspaces/InvestmentThesisModal";
import DashboardAddToWorkspaceButton from "../../atoms/dashboards/DashboardAddToWorkspaceButton";
import WorkspaceShareEmail from "../../atoms/workspaces/WorkspaceShareEmail";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import ThesisScore from "../../atoms/ThesisScore";
import useRoute from "../../../hooks/useRoute";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.primary['300']}`,
        background: `#fff`,
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    solo: {
        padding: 8,
        background: `rgb(246, 251, 251, 0.8)`,
        borderRadius: 16,
        border: `1px solid ${theme.colors.cyan['500']}`,
    },
    cell: {
        minWidth: 'calc(20vw - 20px)',
        maxWidth: 'calc(20vw - 20px)',
        minHeight: 50,
        maxHeight: 'none',
        padding: '2px 10px',
    },
    fitCell: {
        minWidth: 'unset',
        maxWidth: 'unset',
        width: 'fit-content',
    },
    wideCell: {
        minWidth: 'calc(30vw + 8px)',
        maxWidth: 'calc(30vw + 8px)',
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    hiddenCell: {
        display: 'none',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    label: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    soloTitle: {
        fontSize: '1.1em !important',
        fontWeight: 'bold !important',
        color: theme.palette.common.black,
    },
    sublabel: {
        fontWeight: '500',
        color: theme.colors.neutral['500'],
    },
    value: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
    icon: {
        width: 20,
        height: 20,
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
}));

const InvestorsTableRow: React.FC<{
    workspace: Workspace,
    dashboard?: Dashboard,
    collectionKey?: string,
    forSharing?: boolean,
    noMailing?: boolean,
    solo?: boolean,
}> = ({ workspace, dashboard, collectionKey, forSharing, noMailing, solo }) => {
    const classes = useStyles();
    const { mappedOverviewQueries } = useContext(DashboardsContext);
    const { getBulkWorkspaces, addToBulk, removeFromBulk } = useBulkWorkspaces();
    const { redirectToWorkspace } = useRoute();

    const [hover, setHover] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);

    const getTags = (investmentThesis: InvestmentThesisType) => investmentThesis?.focusArea?.tags?.join(', ') || 'N/A';
    const getStages = (investmentThesis: InvestmentThesisType) => investmentThesis?.stage?.stages?.join(', ') || 'N/A';
    const getRegions = (investmentThesis: InvestmentThesisType) => investmentThesis?.geography?.regions?.join(', ') || 'N/A';

    const commonDomainsList = useMemo(() =>
        Object.entries(mailProviders as { [key: string]: string | string [] }).find(([key, _]) => key === 'result')?.pop() ?? []
    , []);

    const website = useMemo(() => {
        if (!workspace.website && !!workspace.email && workspace.email?.indexOf('@') !== -1) {
            const domain = (workspace.email?.split('@').pop() || '')?.trim().toLowerCase();

            return !commonDomainsList.includes(domain) ? domain : '';
        }

        return (workspace.website || '').replace(/(^\w+:|^)\/\//, '').replace('www.', '');
    }, [commonDomainsList, workspace]);

    const getQueryAnswer = useCallback((title: string) => (
        mappedOverviewQueries.get(`${dashboard?.id}:${title}`)
    ), [dashboard, mappedOverviewQueries]);

    const handleCheck = useCallback((e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (!getBulkWorkspaces(collectionKey || '').find(item => item.id === workspace.id)) {
            setChecked(true);
            addToBulk(collectionKey || '', workspace);
        } else {
            setChecked(false);
            removeFromBulk(collectionKey || '', workspace);
        }
    // eslint-disable-next-line
    }, [getBulkWorkspaces, workspace]);

    useEffect(() => {
        setChecked(!!getBulkWorkspaces(collectionKey || '').find(item => item.id === workspace.id));
    // eslint-disable-next-line
    }, []);

    return (<>
        <Stack className={classnames(classes.row, solo && classes.solo)}
            direction="column" alignItems="stretch" justifyContent="flex-start"
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            onClick={forSharing ? handleCheck : undefined} sx={{ cursor: forSharing ? 'pointer' : 'auto' }}>
            <Stack direction="row" alignItems="stretch" justifyContent="flex-start" width="100%">
                {!solo && (
                    <Stack minWidth="24px" width="min-content" mt={!workspace.email ? '30px' : '20px'} >
                        <Checkbox edge="end" className={classes.checkBox} checked={checked} onClick={handleCheck} />
                    </Stack>
                )}
                <Stack direction="column" className={classes.cell} spacing={1} alignItems="flex-start" justifyContent="center"
                    onClick={() => !forSharing ? redirectToWorkspace(workspace.id) : undefined}
                    sx={{ cursor: !forSharing ? 'pointer' : 'auto' }}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <Typography className={classnames(classes.label, solo && classes.soloTitle)}
                            sx={{ textDecoration: (!solo && hover) ? 'underline' : 'none' }}>
                            {workspace.name}
                        </Typography>
                        {forSharing && (<ThesisScore workspaceId={workspace.id} investmentTheses={getQueryAnswer('Investment Thesis') || []} />)}
                    </Stack>
                    <Typography className={classnames(classes.label, classes.sublabel)} >
                        {workspace.email}
                    </Typography>
                </Stack>
                {!forSharing && (
                    <Stack direction="column" className={classes.cell} alignItems="flex-start" justifyContent="center"
                        onClick={() => !!website ? window.open(`https://${website}`, '_blank', 'noopener, noreferrer') : undefined}
                        sx={{ cursor: 'pointer' }}>
                        {!!website ? (<>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                <WorldGridIcon className={classes.icon} fill="#7bd4d4" stroke="#7bd4d4" />
                                <Typography component="a"
                                    className={classes.label}
                                    color="#7bd4d4 !important"
                                    href={`https://${website}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ textDecoration: hover ? 'underline' : 'none'}}>
                                    {website}
                                </Typography>
                            </Stack>
                        </>) : (<>
                            <Typography className={classes.label} color="#7bd4d4 !important"> {'N/A'} </Typography>
                        </>)}
                    </Stack>
                )}
                <Stack className={classnames(classes.cell, classes.fullWidthCell)}>
                    <PreferencesModal workspace={workspace} forSharing={forSharing}>
                        <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" py={1}>
                            <Typography className={classes.label} fontWeight="500 !important">
                                {'Sector: '}
                                <Typography component="span" className={classes.value} display="inline">
                                    {getTags(workspace.investmentThesis)}
                                </Typography>
                            </Typography>
                            <Typography className={classes.label} fontWeight="500 !important">
                                {'Geography: '}
                                <Typography component="span" className={classes.value} display="inline">
                                    {getRegions(workspace.investmentThesis)}
                                </Typography>
                            </Typography>
                            <Typography className={classes.label} fontWeight="500 !important">
                                {'Stage: '}
                                <Typography component="span" className={classes.value} display="inline">
                                    {getStages(workspace.investmentThesis)}
                                </Typography>
                            </Typography>
                        </Stack>
                    </PreferencesModal>
                </Stack>
                {!solo && (
                    <Stack direction="row" className={classnames(classes.cell, classes.fullWidthCell)}
                        spacing={1} alignItems="center" justifyContent="flex-end">
                        {!forSharing ? (<>
                            <InvestmentThesisModal workspace={workspace} />
                            <WorkspaceShareEmail workspace={workspace} />
                            <WorkspaceMenu workspace={workspace} />
                        </>) : (!!dashboard) && (<>
                            <DashboardAddToWorkspaceButton dashboard={dashboard} workspaceId={workspace.id} />
                            {!noMailing && (<WorkspaceShareEmail dashboard={dashboard} workspace={workspace} />)}
                        </>)}
                    </Stack>
                )}
                <Stack className={classnames(classes.cell, classes.endCell)} />
            </Stack>
        </Stack>
    </>);
}

export default memo(InvestorsTableRow);