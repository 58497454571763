import React from "react";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const DashboardItem: React.FC<{ id: string }> = ({ id, children }) => {
    const {
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        height: "100%"
    };

    return (
        <div ref={setNodeRef} style={style}>
            {children}
        </div>
    );
};

export default DashboardItem;