import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Checkbox, Chip, Grid, InputAdornment, TextField } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import makeStyles from '@mui/styles/makeStyles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { v4 as uuidv4 } from "uuid";
import { AdvancedSearchObject, SearchDocumentType, SearchType } from "../../../../types/search";
import { FileStructureContext } from "../../../../contexts/FileStructureContext";
import { splitFolders } from "../../../../helpers/fileStructure";
import { SearchNavigationContext } from "../../../../contexts/SearchNavigationContext";
import { AllFileTypesExcludingExcel, AllFileTypesIncludingExcel, getDropdownSelectLabel } from "../../../../shared/search";

export const CATEGORY_TYPES: SearchDocumentType[] = [SearchDocumentType.Legal, SearchDocumentType.Financial, SearchDocumentType.Presentation, SearchDocumentType.Other] //see process/utils.py

const useStyles = makeStyles((theme) => ({
    typeOfDocTextField: {
        height: 40,
        overflow: 'hidden',
        paddingTop: 0,
        paddingBottom: 0,
    },
    typeOfDocInput: {
        height: 40,
    }
}));

const NewAdvancedSearch: React.FC = () => {
    const classes = useStyles();
    const [fileOptions, setFileOptions] = useState<AdvancedSearchObject[]>([]);
    const [folderOptions, setFolderOptions] = useState<AdvancedSearchObject[]>([]);
    const { fileStructure } = useContext(FileStructureContext);
    const {
        contextAdvancedSearchObject,
        contextSetAdvancedSearchObject,
        contextSelectedTab,
        contextSelectedSearchType,
        contextSetSelectedSearchType,
        contextSelectedDocTypes,
        contextSetSelectedDocTypes,
    } = useContext(SearchNavigationContext);

    useEffect(() => {
        if (fileStructure.length && contextAdvancedSearchObject) {
            const [files, folders] = splitFolders(fileStructure);
            folders.sort();
            files.sort();

            const newFolderOptions = folders.map(e => {
                return {
                    id: uuidv4(),
                    file: null,
                    fileValue: null,
                    folder: e,
                    value: e.path.split('/').join(" > "),
                } as AdvancedSearchObject
            });

            const contextFolders = contextAdvancedSearchObject[contextSelectedTab];
            const emptyObject = contextFolders.find(e => !e?.file && !e?.fileValue && !e?.folder && !e?.value);

            const newFileOptions = files.map(e => {
                const parent = folders.find(f => f.id === e.parentId);
                return {
                    id: uuidv4(),
                    file: e,
                    fileValue: e.path.split('/').join(" > "),
                    folder: parent,
                    value: parent?.path.split('/').join(" > "),
                } as AdvancedSearchObject
            });

            if (emptyObject) {
                newFolderOptions.unshift(emptyObject);
                newFileOptions.unshift(emptyObject);
            }

            setFolderOptions(newFolderOptions);
            setFileOptions(newFileOptions);

            if (!contextSelectedDocTypes[contextSelectedTab]) {
                contextSetSelectedDocTypes(AllFileTypesExcludingExcel);
            }
        }
        // eslint-disable-next-line
    }, [fileStructure, contextAdvancedSearchObject]);

    const folders = contextAdvancedSearchObject[contextSelectedTab];

    const getFileOptions = (folder: AdvancedSearchObject | null | undefined) => {
        let filteredFileOptions = folder?.value ?
            fileOptions.filter((e) => {
                return e.file?.path === `${folder.value?.split(' > ').join("/")}/${e.file?.name}`
            })
            :
            fileOptions;

        filteredFileOptions = filteredFileOptions.filter(e => !!filteredFileOptions.find(x => x.fileValue === e.fileValue && x.id !== folder?.id));

        if (folder) {
            filteredFileOptions.unshift(folder);
        }
        return filteredFileOptions;
    }

    const getFolderAutocompleteElements = (folder: AdvancedSearchObject | null | undefined, index: number) => {
        return <Grid
            container
            width="100%"
            mb={1}
            key={'new-advanced-search-116-' + index}
        >
            <Grid item xs={5}>
                <Autocomplete
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                    options={folderOptions}
                    filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt?.value?.toLowerCase().includes(inputValue.toLowerCase()) : !!opt.value)}
                    getOptionLabel={option => option?.value || ""}
                    value={folder}
                    autoComplete
                    clearOnEscape
                    openOnFocus
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff"
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <FolderIcon color="secondary" sx={{ marginRight: 1 }} />
                            {option?.value || ""}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="Folder"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start" >
                                            <FolderIcon color="secondary" />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    onChange={(_, newValue) => {
                        contextSetAdvancedSearchObject(folders.map(x => {
                            if (x && x?.id === folder?.id) {
                                if (!newValue) {
                                    x = {
                                        id: folder.id,
                                        file: null,
                                        fileValue: null,
                                        folder: null,
                                        value: null,
                                    } as AdvancedSearchObject;
                                } else {
                                    x = newValue;
                                }
                            }

                            return x;
                        }));
                    }}
                />
            </Grid>
            <Grid
                item
                xs={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {
                    (index === folders?.length - 1) ?
                        <AddCircleOutlineIcon
                            sx={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                                contextSetAdvancedSearchObject([...folders, {
                                    id: uuidv4(),
                                    value: null,
                                    folder: null,
                                    file: null,
                                    fileValue: null
                                } as AdvancedSearchObject
                                ]);
                            }}
                        />
                        :
                        <ClearIcon
                            sx={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                                contextSetAdvancedSearchObject(folders?.filter(x => x?.id !== folder?.id));
                            }}
                        />
                }
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    isOptionEqualToValue={(option, value) => option?.fileValue === value?.fileValue}
                    options={folder?.value ? getFileOptions(folder) : fileOptions}
                    filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt?.fileValue?.toLowerCase().includes(inputValue.toLowerCase()) : !!opt.fileValue)}
                    getOptionLabel={option => option?.fileValue || ""}
                    value={folder}
                    autoComplete
                    clearOnEscape
                    openOnFocus
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff"
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <InsertDriveFileIcon color="secondary" sx={{ marginRight: 1 }} />
                            {option?.fileValue || ""}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="File"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start" >
                                            <InsertDriveFileIcon color="secondary" />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    onChange={(_, newValue) => {
                        contextSetAdvancedSearchObject(folders?.map(x => {
                            if (x && x?.id === folder?.id) {
                                if (!newValue) {
                                    x = {
                                        id: folder.id,
                                        file: null,
                                        fileValue: null,
                                        folder: folder.folder,
                                        value: folder.value,
                                    } as AdvancedSearchObject;
                                } else {
                                    x = newValue;
                                }
                            }
                            return x;
                        }));
                    }}
                />
            </Grid>
        </Grid>
    }

    return (
        <Grid container justifyContent="space-between">
            <Grid item xs={2} pr={2}>
                <Autocomplete
                    disableClearable
                    isOptionEqualToValue={(option, value) => option && option === value}
                    options={[SearchType.Keyword, SearchType.Title]}
                    value={contextSelectedSearchType[contextSelectedTab]}
                    clearOnEscape
                    openOnFocus
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff"
                        }
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <SpellcheckIcon color="secondary" sx={{ marginRight: 1 }} />
                            {option || ""}
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="Type of search"
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start" >
                                            <SpellcheckIcon color="secondary" />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    onChange={(_, newValue) => {
                        contextSetSelectedSearchType(newValue as SearchType);
                    }}
                />
            </Grid>
            <Grid item xs={6} pr={2}>
                {
                    folders?.map((folder, index) => {
                        return getFolderAutocompleteElements(folder, index);
                    })
                }

            </Grid>
            <Grid item xs={2.8} pr={2}>
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    isOptionEqualToValue={(option, value) => option === value}
                    options={["", ...AllFileTypesIncludingExcel]}
                    filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt.toLowerCase().includes(inputValue.toLowerCase()) : opt !== "")}
                    value={contextSelectedDocTypes[contextSelectedTab] || []}
                    clearOnEscape
                    openOnFocus
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: "#fff"
                        }
                    }}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 1 }}
                                checked={selected}
                            />
                            <FileCopyOutlinedIcon color="secondary" sx={{ marginRight: 1 }} />
                            {option || ""}
                        </li>
                    )}
                    renderTags={(tagValue, getTagProps) => {
                        if (tagValue.length < 2) {
                            return tagValue.map((option, index) => (
                                <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                    key={'new-advanced-search-355-' + index}
                                />
                            ))
                        } else {
                            return [<Chip
                                label={getDropdownSelectLabel(tagValue)}
                                sx={{ fontSize: 12 }}
                                key="type-of-doc-multiple-selected"
                            />];
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.typeOfDocTextField}
                            rows={1}
                            variant="outlined"
                            size="small"
                            placeholder={contextSelectedDocTypes[contextSelectedTab]?.length ? "" : "Type of doc"}
                            InputProps={{
                                ...params.InputProps,
                                className: classes.typeOfDocInput,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start" >
                                            <FileCopyOutlinedIcon color="secondary" />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                )
                            }}
                        />
                    )}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            contextSetSelectedDocTypes(newValue as SearchDocumentType[]);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={1.2} textAlign="end">
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ backgroundColor: "#fff", borderRadius: 54 }}
                    onClick={() => {
                        contextSetSelectedSearchType(SearchType.Keyword);
                        contextSetAdvancedSearchObject([{
                            id: uuidv4(),
                            file: null,
                            fileValue: null,
                            folder: null,
                            value: null,
                        }]);
                        contextSetSelectedDocTypes(undefined);
                    }}
                >
                    Clear All
                </Button>
            </Grid>
        </Grid>
    )
};

export default NewAdvancedSearch;