import { SearchDocumentType } from "../types/search";
import ArrayUtils from "../utils/ArrayUtils";

export const AllFileTypesExcludingExcel = [
    // SearchDocumentType.Pdf,  
    // SearchDocumentType.Word, 
    // SearchDocumentType.PowerPoint, 
    SearchDocumentType.Financial, 
    SearchDocumentType.Legal, 
    SearchDocumentType.Presentation, 
    SearchDocumentType.Other
];

export const AllFileTypesIncludingExcel = [
    ...AllFileTypesExcludingExcel,
    SearchDocumentType.Excel
];

export const getDropdownSelectLabel = (values: string[]): string => {
    if (ArrayUtils.areEqual(values, AllFileTypesExcludingExcel)) {
        return "All file types (excl. Excels)";
    }

    if (ArrayUtils.areEqual(values, AllFileTypesIncludingExcel)) {
        return "All file types (incl. Excels)";
    }

    return `${values.length} options selected`;
}