import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from "react";
import { CompanyFile } from '../../../../types/files';
import { FileStructureContext } from "../../../../contexts/FileStructureContext";
import FileCategoryCard from "./FileCategoryCard";
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    cardsContainer: {
        width: "-webkit-fill-available",
        paddingRight: 20,
    },
    cards: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        position: 'relative',
        alignContent: 'flex-start'
    },
    cardContainer: {
        minHeight: 280,
        maxHeight: 280,
        marginBottom: 20,
        marginLeft: 20,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            width: 'calc(50% - 20px)',
        },
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 20px)',
        },
    },
    searchInput: {
        marginBottom: 20,
        width: '100%',
    },
    emtpyState: {
        textAlign: 'center',
    },
    fileIcon: {
        marginRight: theme.spacing(1),
        height: 28,
        width: 28,
    },
    spacer: {
        height: 20,
        background: 'transparent',
    }
}));

const otherLabel = "Other";
const otherObjectProp = "zzzOther";

const FileCategories: React.FC<{ folder: string, onFileSelect: (file: CompanyFile) => void }> = ({ folder, onFileSelect }) => {
    const classes = useStyles();
    const { fileStructure } = useContext(FileStructureContext);

    const [groupedFiles, setGroupedFiles] = useState<any>({});
    const [expandedElements, setExpandedElements] = useState<{ id: number, height: number }[]>([]);

    useEffect(() => {
        if (folder) {
            const files = fileStructure.filter(x => !x.isDirectory && x.path.toLocaleLowerCase().startsWith(folder.toLocaleLowerCase()));
            const grouped = files.reduce<{ [key: string]: CompanyFile[] }>((aggr, file) => {
                return {
                    ...aggr,
                    [file.documentCategory]: [...(aggr[file.documentCategory] || []), file]
                }
            }, {})
            setGroupedFiles(grouped);
        }
    }, [fileStructure, folder]);

    const categories = Object.keys(groupedFiles).sort();

    const sortedElements = expandedElements.sort((a, b) => {
        return b.height - a.height;
    });

    return (
        <div className={classes.cardsContainer}>
            <Typography padding="20px" variant="h6">Overview of this folder</Typography>
            <div className={classes.cards} style={{ height: sortedElements.length ? sortedElements[0].height + 300 * Math.floor(sortedElements[0].id / 2) : 'auto' }}>
                {
                    categories?.map((prop, index) =>
                        <div className={classes.cardContainer}
                            style={{ height: `${groupedFiles[prop]?.length >= 6 ? '280px' : `calc(55px + ${groupedFiles[prop]?.length} * 50px)`}` }} key={'old-file-categories-87-' + index}>
                            <FileCategoryCard
                                title={prop === otherObjectProp ? otherLabel : prop}
                                categoryIndex={index}
                                files={groupedFiles[prop]}
                                onFileSelect={onFileSelect}
                                onFileExpand={(categoryIndex, newExpandedHeight) => {
                                    if (!newExpandedHeight) {
                                        setExpandedElements(expandedElements.filter(e => e.id !== categoryIndex));
                                    } else {
                                        setExpandedElements([...expandedElements, {
                                            id: categoryIndex,
                                            height: newExpandedHeight,
                                        }]);
                                    }
                                }}
                            />
                        </div>)
                }
            </div>
            {!!sortedElements.length && <div className={classes.spacer}></div>}
        </div>
    );
};

export default FileCategories;