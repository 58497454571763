import { Box, Divider, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useMemo } from 'react';
import { scrollbarStyle } from '../../../shared/dashboard';
import React from 'react';
import moment from 'moment';
import { LineChart } from '@mui/x-charts/LineChart';
import ArrayUtils from '../../../utils/ArrayUtils';
import { StatMetricsCategory } from './MonthlySummary';

type StatMetrics = {
    method: string;
    value: Array<number>;
};

const useStyles = makeStyles(() => ({
    cardsGroupBlock: {
        height: '250px',
        overflowY: 'auto',
        borderRadius: '10px',
        marginTop: '8px',
        ...scrollbarStyle,
    },
}));

const StatMetricsColorSet = [
    'rgba(225, 87, 89, 0.75)',
    'rgba(89, 161, 79, 0.75)',
    'rgba(242, 142, 44, 0.75)',
    'rgba(118, 183, 178, 0.75)',
    'rgba(237, 201, 73, 0.75)'
];

const TrendsChart: React.FC<{
    title: string,
    months: number,
    data: Array<{date: string, value: number}>,
}> = ({ title, months, data }) => {
    const classes = useStyles();

    const trendsInfo: StatMetrics[] = useMemo(() => {
        if (!!data.length) {
            const groupedData = [...ArrayUtils.sortByAscending(data, 'date')]
                .reduce((group, item) => {
                    group[item.date] = group[item.date] ?? [];
                    group[item.date].push(item);
                    return group;
                }, Object.create(null));

            if (Math.min(...Object.keys(groupedData).map(dateKey => moment().diff(moment(dateKey), 'months'))) >= months)
                return [];

            const trendsMetrics: StatMetrics[] = StatMetricsCategory.map(category => ({
                method: category,
                value: Array(months).fill(0),
            }));

            trendsMetrics.forEach(metric => {
                Object.entries(groupedData).forEach(([dateKey, orgValue]) => {
                    const monthDif = moment().diff(moment(dateKey), 'months');
                    const monthValues = (orgValue as Array<{value: number}>).map(v => Number(v.value));
                    let value = 0;
                    switch (metric.method) {
                        case 'Average':
                            value = monthValues.reduce((sum, val) => sum += val, 0) / monthValues.length;
                            break;
                        case 'Median':
                            value = (() => {
                                let values = [...monthValues].sort((a, b) => a - b);
                                const half = Math.floor(values.length / 2);
                                return (values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2);
                            })();
                            break;
                        case 'Min':
                            value = Math.min(...monthValues);
                            break;
                        case 'Max':
                            value = Math.max(...monthValues);
                            break;
                        default:
                            break;
                    }
                    metric.value = metric.value.fill(value, 0, monthDif + 1);
                });
                metric.value = metric.value.reverse();
            });

            return trendsMetrics;
        }

        return [];
    }, [data, months]);

    const formatNumber = useCallback((value: number, metric: string, decimals?: number) => {
        const unit = ['retention', 'margin'].includes(metric.toLowerCase()) ? '%' : '$';
        const stringValue = Intl.NumberFormat('en-US', {
            notation: 'compact',
            style: (unit === '%') ? 'percent' : 'currency',
            currency: 'USD',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }).format(value / ((unit === '%') ? 100 : 1));

        return `${stringValue}`;
    }, []);

    return (<>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography fontSize="1.1rem" fontWeight="bold" color="#7bd4d4">{title + ' Trends'}</Typography>
        </Stack>
        <Box className={classes.cardsGroupBlock} sx={{ margin: '0px !important' }}>
            {!!trendsInfo.length ? (<>
                <LineChart height={230} margin={{ top: 8, bottom: 8, left: 46, right: 0 }}
                    xAxis={[{
                        data: Array(months).fill(moment()).map((time: moment.Moment, i) => time.subtract(Number(!!i), 'months').toDate()).reverse(),
                        valueFormatter: (date: Date) =>  `Month: ${date.toLocaleString('en-US', { month: 'short', year: 'numeric', })}`,
                    }]}
                    yAxis={[{
                        valueFormatter: (value: any) =>  formatNumber(value, title),
                    }]}
                    series={trendsInfo.map((trend, i) => ({
                        color: StatMetricsColorSet[i % StatMetricsColorSet.length],
                        data: trend.value,
                        label: trend.method,
                        showMark: false,
                        highlightScope: { highlighted: 'item', faded: 'global', },
                        valueFormatter: (value: any) =>  formatNumber(value, title, 1),
                    })) as any}
                    bottomAxis={null}
                    slotProps={{legend: { hidden: true }}}
                    sx={{ "& > path": { stroke: 'lightgrey', }}}
                />
                <Divider sx={{ borderColor: 'lightgrey'}} />
                <Stack alignItems="center" justifyContent="center">
                    <Typography fontSize="0.8rem" color="darkgray">{`last ${months} months`}</Typography>
                </Stack>
            </>) : (<>
                <Stack alignItems="center" justifyContent="center" height="230px">
                    <Typography fontSize="1rem" color="#666666">No data yet.</Typography>
                </Stack>
                <Divider sx={{ borderColor: 'lightgrey'}} />
                <Stack alignItems="center" justifyContent="center">
                    <Typography fontSize="0.8rem" color="darkgray">{'last months'}</Typography>
                </Stack>
            </>)}
        </Box>
    </>);
}

export {StatMetricsColorSet};
export default TrendsChart;