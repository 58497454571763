import React from "react";
import { Button, Dialog, DialogActions, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Dashboard } from "../../../types/files";
import { ReactComponent as FileFeatIcon } from "../../../assets/icons/upload-browse.svg";
import { ReactComponent as NotesIcon } from "../../../assets/icons/notes.svg";
import { ReactComponent as SendEmail } from "../../../assets/icons/sendEmail.svg";
import SampleEmailBody from "../../atoms/SampleEmailBody";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 600,
            height: 'fit-content',
            padding: 16,
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    featCard: {
        padding: '24px 16px',
        borderRadius: 16,
        background: 'rgba(240,254,254,1)',
        border: `1px solid ${theme.colors.neutral['100']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        transition: '0.05s ease-in-out',
        "&:hover": {
            boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
        },
    },
    addIcon: {
        width: 40,
        height: 40,
        fill: theme.palette.primary.main,
        marginRight: 8,
    },
    uploadIcon: {
        width: 60,
        height: 60,
    },
    notesIcon: {
        width: 120,
        height: 60,
    },
    sendEmail: {
        width: 60,
        height: 60,
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.primary['600'],
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        color: theme.colors.neutral['600'],
    },
    commonButton: {
        minWidth: 100,
        borderRadius: 24,
        textTransform: 'none',
        fontWeight: 'bold',
    },
}));

const UploadFilesCard: React.FC<{ onClick: React.MouseEventHandler<HTMLButtonElement> }> = ({ onClick }) => {
    const classes = useStyles();

    return (<>
        <Stack direction="row" className={classes.featCard} alignItems="center" justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-start" width="auto">
                <FileFeatIcon className={classes.uploadIcon} />
                <Stack direction="column" spacing={1} alignItems="flex-start">
                    <Typography className={classes.title}>
                        {'Upload files'}
                    </Typography>
                    <Typography className={classes.subtitle}>
                        {'Decks, investor updates, etc.'}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction="column" alignItems="flex-end" width="fit-content" px={1}>
                <Button variant="contained"
                    className={classes.commonButton}
                    onClick={onClick}>
                    {'Upload'}
                </Button>
            </Stack>
        </Stack>
    </>);
}

const ForwardEmailsCard: React.FC<{}> = () => {
    const classes = useStyles();

    return (<>
        <Stack direction="row" className={classes.featCard} spacing={3} width="100%">
            <SendEmail className={classes.sendEmail} />
            <Stack direction="column" spacing={1} alignItems="flex-start">
                <Typography className={classes.title}>
                    {'Forward emails'}
                </Typography>
                <Typography className={classes.subtitle}>
                    {`We'll capture important data from it like metrics, go to market, business model, etc.`}
                </Typography>
                <Typography className={classes.subtitle}>
                    {'As follows: '}
                </Typography>
                <SampleEmailBody />
            </Stack>
        </Stack>
    </>);
}

const TakeNoteCard: React.FC<{ onClick: React.MouseEventHandler<HTMLButtonElement> }> = ({ onClick }) => {
    const classes = useStyles();

    return (<>
        <Stack direction="row" className={classes.featCard} alignItems="center" justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-start" width="auto">
                <NotesIcon className={classes.notesIcon} />
                <Stack direction="column" spacing={1} alignItems="flex-start">
                    <Typography className={classes.title}>
                        {'Add notes'}
                    </Typography>
                    <Typography className={classes.subtitle}>
                        {`We'll capture important data from it like metrics, go to market, business model, etc.`}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction="column" alignItems="flex-end" width="fit-content" px={1}>
                <Button variant="contained"
                    className={classes.commonButton}
                    onClick={onClick}>
                    {'New note'}
                </Button>
            </Stack>
        </Stack>
    </>);
}

const DashboardAddDataModal: React.FC<{
    isOpen: boolean,
    dashboard: Dashboard,
    onClose: (mode: string) => void,
}> = ({isOpen, dashboard, onClose}) => {
    const classes = useStyles();

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose('close')}>
            <DialogTitle className={classes.dialogTitle}>
                <AddCircleIcon className={classes.addIcon} />
                {`Add data to ${dashboard.title}`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack direction="column" spacing={2} alignItems="flex-start" width="100%">
                    <UploadFilesCard onClick={() => onClose('file-upload')} />
                    <ForwardEmailsCard />
                    <TakeNoteCard onClick={() => onClose('note-dialog')} />
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default DashboardAddDataModal;