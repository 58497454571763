import React, { useCallback, useContext, useState } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from '@mui/icons-material/Add';
import AddSectionModal from "../modals/dashboard-queries/AddSectionModal";
import { Column as CompanyDetailsSection } from "../organisms/dashboard-queries/CompanyDetailsColumnQueries";
import { Column as KeyMetricsSection } from "../organisms/dashboard-queries/KeyMetricsColumnQueries";
import { Column as PeopleSection } from "../organisms/dashboard-queries/PeopleColumnQueries";
import AddCustomSectionModal from "../modals/dashboard-queries/AddCustomSectionModal";
import classnames from "classnames";
import AddCustomMetricsModal from "../modals/dashboard-queries/AddCustomMetricsModal";
import AddCustomPeopleModal from "../modals/dashboard-queries/AddCustomPeopleModal";
import AddMetricsModal from "../modals/dashboard-queries/AddMetricsModal";
import AddPeopleModal from "../modals/dashboard-queries/AddPeopleModal";
import {DashboardContext} from "../../contexts/DashboardContext";

const useStyles = makeStyles((theme) => ({
    icon: {
        width: 16,
        height: 'auto',
        stroke: theme.palette.primary.light,
    },
    customIcon: {
        width: 16,
        height: 'auto',
        stroke: theme.palette.primary.main,
    },
    iconButton: {
        minWidth: 32,
        padding: 'unset !important',
        "& > .MuiButton-startIcon": {
            width: 'auto',
            height: 32,
            padding: 6,
            margin: 'unset',
            borderRadius: '50%',
            background: '#E0F1F1',
            "& > .MuiSvgIcon-root": {
                fontSize: 22,
                color: theme.palette.primary.main,
            }
        },
        "&:hover": {
            color: theme.colors.neutral['600'],
            backgroundColor: 'inherit',
        },
    },
    addSectionButton: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: '500 !important',
        color: theme.palette.primary.light,
        background: 'transparent',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
        "&:hover": {
            background: 'transparent',
        }
    },
    customButton: {
        fontWeight: 'bold !important',
        color: theme.palette.primary.main,
    },
}));

export const CompanyDetailsQueries = ['Description', 'Market', 'Funding', 'Product', 'Go To Market', 'Business Model', 'Possible Risks', 'Similar Companies 2.0', 'Recent News',];
export const KeyMetricsQueries = ['Note Metrics',];
export const PeopleQueries = ['Team', 'Key People',];
export const GeneralInfoQueries = ['Location', 'Founded', 'Headcount', 'Website', 'Crunchbase Page', 'LinkedIn',];

const AddSection: React.FC<{
    column: string,
    label?: string,
}> = ({ column, label }) => {
    const classes = useStyles();
    const { isPublicView } = useContext(DashboardContext);
    const [addSectionModalOpen, setAddSectionModalOpen] = useState<boolean>(false);
    const [addMetricsModalOpen, setAddMetricsModalOpen] = useState<boolean>(false);
    const [addPeopleModalOpen, setAddPeopleModalOpen] = useState<boolean>(false);
    const [addCustomSectionModalOpen, setAddCustomSectionModalOpen] = useState<boolean>(false);
    const [addCustomMetricsModalOpen, setAddCustomMetricsModalOpen] = useState<boolean>(false);
    const [addCustomPeopleModalOpen, setAddCustomPeopleModalOpen] = useState<boolean>(false);

    const handleModalOpen = useCallback(() => {
        switch (column) {
            case CompanyDetailsSection:
                setAddSectionModalOpen(true);
                break;
            case KeyMetricsSection:
                // setAddMetricsModalOpen(true);
                setAddCustomMetricsModalOpen(true);
                break;
            case PeopleSection:
                setAddPeopleModalOpen(true);
                break;
            default:
                break;
        }
    }, [column]);

    const handleCustomModalOpen = useCallback(() => {
        switch (column) {
            case CompanyDetailsSection:
                setAddCustomSectionModalOpen(true);
                break;
            case KeyMetricsSection:
                setAddCustomMetricsModalOpen(true);
                break;
            case PeopleSection:
                setAddCustomPeopleModalOpen(true);
                break;
            default:
                break;
        }
    }, [column]);

    if (isPublicView || ![CompanyDetailsSection, KeyMetricsSection, PeopleSection].includes(column))
        return (<></>);

    return (<>
        <Tooltip placement="top"
            title={<Typography fontSize="0.85rem">
                {`Add section in ${column.toLowerCase()}`}
            </Typography>}
            componentsProps={{tooltip:{sx:{
                paddingLeft: 2, paddingRight: 2,
                backgroundColor: 'rgba(97, 97, 97)',
            }}}}>
            {label ? (<>
                <Button variant="text"
                    className={classes.addSectionButton}
                    startIcon={<AddIcon className={classes.icon} />}
                    onClick={handleModalOpen}>
                    {label}
                </Button>
            </>) : (<>
                <Button className={classes.iconButton}
                    startIcon={<AddIcon />}
                    onClick={handleModalOpen} />
            </>)}
        </Tooltip>
        {(addSectionModalOpen && !addCustomSectionModalOpen) && (
            <AddSectionModal
                isOpen={addSectionModalOpen && !addCustomSectionModalOpen}
                onClose={() => setAddSectionModalOpen(false)}>
                <Button variant="text"
                    className={classnames(classes.addSectionButton, classes.customButton)}
                    startIcon={<AddIcon className={classes.customIcon} />}
                    onClick={handleCustomModalOpen}>
                    {'Add custom section'}
                </Button>
            </AddSectionModal>
        )}
        {(addMetricsModalOpen && !addCustomMetricsModalOpen) && (
            <AddMetricsModal
                isOpen={addMetricsModalOpen && !addCustomMetricsModalOpen}
                onClose={() => setAddMetricsModalOpen(false)}>
                <Button variant="text"
                    className={classnames(classes.addSectionButton, classes.customButton)}
                    startIcon={<AddIcon className={classes.customIcon} />}
                    onClick={handleCustomModalOpen}>
                    {'Add custom section'}
                </Button>
            </AddMetricsModal>
        )}
        {(addPeopleModalOpen && !addCustomPeopleModalOpen) && (
            <AddPeopleModal
                isOpen={addPeopleModalOpen && !addCustomPeopleModalOpen}
                onClose={() => setAddPeopleModalOpen(false)}>
                <Button variant="text"
                    className={classnames(classes.addSectionButton, classes.customButton)}
                    startIcon={<AddIcon className={classes.customIcon} />}
                    onClick={handleCustomModalOpen}>
                    {'Add custom section'}
                </Button>
            </AddPeopleModal>
        )}
        {addCustomSectionModalOpen && (
            <AddCustomSectionModal
                isOpen={addCustomSectionModalOpen}
                onClose={() => {
                    setAddSectionModalOpen(false);
                    setAddCustomSectionModalOpen(false);
                }}>
            </AddCustomSectionModal>
        )}
        {addCustomMetricsModalOpen && (
            <AddCustomMetricsModal
                isOpen={addCustomMetricsModalOpen}
                onClose={() => {
                    setAddMetricsModalOpen(false);
                    setAddCustomMetricsModalOpen(false);
                }}>
            </AddCustomMetricsModal>
        )}
        {addCustomPeopleModalOpen && (
            <AddCustomPeopleModal
                isOpen={addCustomPeopleModalOpen}
                onClose={() => {
                    setAddPeopleModalOpen(false);
                    setAddCustomPeopleModalOpen(false);
                }}>
            </AddCustomPeopleModal>
        )}
    </>);
}

export default AddSection;