import React, { useCallback, useContext, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, TextFieldProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import classnames from "classnames";
import { Column } from "../../organisms/dashboard-queries/CompanyDetailsColumnQueries";
import { DashboardQuerySource } from "../../../types/files";
import { AnswerQuestionResponseType } from "../../../types/search";
import {AuthContext} from "../../../contexts/AuthContext";
import { DashboardContext } from "../../../contexts/DashboardContext";
import { addDashboardQueryFunc } from "../../../lib/helper";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 500,
            height: 350,
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.25rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
        gap: 8,
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
        gap: 8,
    },
    inputField: {
        border: `1px solid ${theme.colors.neutral[400]}`,
        borderRadius: 8,
        padding: 8,
        "& .MuiOutlinedInput-root": {
            width: '100%',
            padding: 'unset',
            "& > input, > textarea": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                padding: 'unset',
            },
        },
    },
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        minWidth: 80,
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const AddCustomSectionModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { dashboard } = useContext(DashboardContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [saveReady, setSaveReady] = useState<boolean>(false);

    const titleRef = useRef<TextFieldProps>(null);
    const contentRef = useRef<TextFieldProps>(null);

    const handleSave = useCallback(() => {
        setLoading(true);
        addDashboardQueryFunc(
            dashboard!.id,
            (titleRef.current?.value as string) || 'query',
            userGroup!,
            dashboard!.title,
            JSON.stringify({
                answer: contentRef.current?.value,
                type: AnswerQuestionResponseType.TEXT,
            }),
            undefined,
            1000,
            true,
            DashboardQuerySource.MANUAL,
            false,
        ).then(() => {
            setLoading(false);
            onClose();
        });
    // eslint-disable-next-line
    }, [dashboard, userGroup]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {`Add a new section in ${Column}`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <TextField variant="outlined"
                    className={classnames('no-border', classes.inputField)}
                    inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                    placeholder={'Section title ...'}
                    onChange={() => setSaveReady(!!titleRef.current?.value)}
                    inputRef={titleRef}
                    disabled={loading}
                    rows={1}
                />
                <TextField variant="outlined"
                    className={classnames('no-border', classes.inputField)}
                    inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                    placeholder={'Section content...'}
                    onChange={() => setSaveReady(!!titleRef.current?.value && !!contentRef.current?.value)}
                    inputRef={contentRef}
                    disabled={loading}
                    rows={5}
                    multiline
                />
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onClose()}
                > Cancel </Button>
                <LoadingButton
                    variant="contained"
                    className={classes.commonButton}
                    loading={loading}
                    disabled={!saveReady}
                    onClick={handleSave}
                > Save </LoadingButton>
            </DialogActions>
        </Dialog>
    </>);
}

export default AddCustomSectionModal;