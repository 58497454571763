import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Box, IconButton, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AnswerQuestionResponse } from '../../../types/search';
import moment from 'moment';
import classnames from 'classnames';
import AnswerEditor, { RowLineHeight } from '../dashboard-query-answer/AnswerEditor';
import AnswerSources from '../dashboard-query-answer/AnswerSources';
import { scrollbarStyle } from '../../../shared/dashboard';
import SkeletonLoader from '../../atoms/SkeletonLoader';
import { DashboardQueryAnswer } from '../../../contexts/DashboardQueriesContext';
import useContentCopy from '../../../hooks/useContentCopy';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AnswerHeader from '../dashboard-query-answer/AnswerHeader';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.5s ease',
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 8,
        paddingRight: 8,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerHover: {
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    answerBlock: {
        width: '100%',
        height: 'auto',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    icon: {
        width: 40,
        height: 40,
        padding: 'unset',
    },
    editTeamButton: {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        border: 'none',
        marginTop: '10px',
        padding: 0,
        fontWeight: 'bold',
        textDecoration: 'none',
        cursor: 'pointer',
    },
}));

const MaxWaitLoadingTime = 15;

const BasicTextQueryContainer: React.FC<{
    queryAnswer: DashboardQueryAnswer,
    children: ReactNode,
    extraChild?: ReactNode,
    displayMenu?: boolean,
    customQuery?: boolean,
    onEdit?: (answer: AnswerQuestionResponse, history?: any) => void,
    onDelete?: () => void,
}> = ({ queryAnswer, children, extraChild, displayMenu, customQuery, onEdit, onDelete }) => {
    const classes = useStyles();
    const { getCopiableId } = useContentCopy();

    const [contentExpanded, setContentExpanded] = useState<boolean>(false);
    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | null>(null);
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);

    const anchorRef = useRef<HTMLButtonElement>(null);
    const answerElemRef = useRef<HTMLElement|null>(null);

    const { answeredQuestion, manualOverride, updatedAt } = queryAnswer;
    const copiableId = getCopiableId(answeredQuestion?.question);
    const isTeamQuery = answeredQuestion.question === 'Team';
    const showAnswerMenu = isTeamQuery && !(answeredQuestion.answer as string)?.includes("couldn't find the answer");
    const rows = answerElemRef?.current?.clientHeight && (answerElemRef?.current?.clientHeight / RowLineHeight);

    const saveEditAnswer = useCallback((answer: string) => {
        onEdit?.({answer: answer, sources: answeredQuestion.sources, type: answeredQuestion.type});
        setIsEditingAnswer(false);
        setEditAnswer(null);
    }, [answeredQuestion.sources, answeredQuestion.type, onEdit]);

    useEffect(() => {
        if ('answer' in answeredQuestion)
            setShowAnswer((!!answeredQuestion?.answer || !!answeredQuestion?.type)
                || (!!answeredQuestion?.time && moment().diff(answeredQuestion.time, "seconds") > MaxWaitLoadingTime));
        else
            setShowAnswer(true);
    }, [answeredQuestion]);

    return (<>
        <Box className={classes.masonryElement}>
            <AnswerHeader
                answeredQuestion={answeredQuestion}
                lastUpdatedDate={updatedAt}
                manualOverride={!customQuery ? manualOverride : false}
                hideAiGenerated={customQuery}
                displayMenu={displayMenu}
                onSetIsEditingAnswer={() => setIsEditingAnswer(true)}
                onSetEditAnswer={(answer: any) => setEditAnswer(answer as string)}
                onDelete={onDelete} />
            {isEditingAnswer ? (
                <Box className={classes.answerBlock} px={2}>
                    <AnswerEditor
                        currentAnswer={editAnswer || ''}
                        placeHolder={isTeamQuery ? 'Add founders or executives...' : 'Add answer...'}
                        handleCancel={() => {
                            setIsEditingAnswer(false);
                            setEditAnswer(null);
                            setHoverAnswer(false);
                        }}
                        handleSave={saveEditAnswer}
                        rows={rows} />
                </Box>
            ) : (
                <Stack direction="row" justifyContent="space-between"
                    className={classnames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                    onMouseLeave={() => setHoverAnswer(false)}>
                    <Box id={copiableId} className={classes.answerBlock} ref={answerElemRef}
                        onClick={(e) => { e.stopPropagation(); setContentExpanded(true); }}
                        onMouseEnter={() => setHoverAnswer(displayMenu || false)}
                        style={{ height: 'auto', maxHeight: contentExpanded ? 'unset' : 130 }}>
                        {showAnswer ? ( children ) : (<SkeletonLoader variant="regular" rowCount={4} cropLast />)}
                    </Box>
                    {showAnswer && ((!isTeamQuery || showAnswerMenu) && (
                        <Stack direction="column" alignItems="center" justifyContent="space-between" width="40px">
                            {hoverAnswer ? (
                                <IconButton size="small"
                                    className={classes.icon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsEditingAnswer(true);
                                        setEditAnswer(answeredQuestion.answer! as string);
                                    }}
                                > <EditOutlinedIcon fontSize="small" /> </IconButton>
                            ) : (<Box />)}
                            <IconButton size="small"
                                className={classes.icon}
                                ref={anchorRef}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setContentExpanded(prev => !prev);
                                }}
                                style={{ display: contentExpanded && (displayMenu && !!answeredQuestion?.sources) ? 'none' : 'inline-flex' }}
                            > <ExpandMoreIcon fontSize="small" style={{ transform: !contentExpanded ? 'none' : 'rotate(180deg)' }} />
                            </IconButton>
                        </Stack>
                    ))}
                </Stack>
            )}
            {(displayMenu && contentExpanded) && (
                <AnswerSources
                    answeredQuestion={answeredQuestion}
                    collapseElement={
                        <Stack justifyContent="center" width="40px">
                            <IconButton size="small"
                                className={classes.icon}
                                ref={anchorRef}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setContentExpanded(false);
                                }}
                            > <ExpandMoreIcon fontSize="small" style={{ transform: 'rotate(180deg)' }} />
                            </IconButton>
                        </Stack>
                    }
                />
            )}
            {extraChild}
        </Box>
    </>);
}

export default BasicTextQueryContainer;
