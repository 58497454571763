import React, { useCallback, useContext, useState } from "react";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LocationQuery from "./LocationQuery";
import FoundedQuery from "./FoundedQuery";
import HeadcountQuery from "./HeadcountQuery";
import {DashboardContext} from "../../../contexts/DashboardContext";
import StageSelector from "../crunchbase-modal/StageSelector";
import useDashboard from "../../../hooks/useDashboard";
import DigestQuery from "./DigestQuery";
import MultiSelector from "../../atoms/MultiSelector";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import ObjectUtils from "../../../utils/ObjectUtils";
import UrlQuery from "./UrlQuery";
import { updateDashboardFunc } from "../../../lib/helper";
import DocsendLink from "./DocsendLink";

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.5s ease',
    },
}));

const DigestMultiQuery: React.FC<{}> = () => {
    const classes = useStyles();
    const { tags } = useContext(GroupSettingsContext);
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const { updateStage } = useDashboard();

    const [updating, setUpdating] = useState<boolean>(false);
    const [editing, setEditing] = useState<string | undefined>(undefined);

    const handleSelectTags = useCallback(async (selections: string[]) => {
        if (!ObjectUtils.equalObjects(dashboard!.tags, selections)) {
            dashboard!.tags = selections;
            setUpdating(true);
            updateDashboardFunc(dashboard).then(() => setUpdating(false));
        }
    }, [dashboard]);

    return (<>
        <Stack direction="row" className={classes.masonryElement}>
            <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-start" width="45%" height="100%">
                <DigestQuery />
                <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="flex-start" p="0 16px" width="100%">
                    <StageSelector
                        defaultStage={dashboard?.investmentStage ?? undefined}
                        onSelectStage={updateStage}
                        readOnly={isPublicView}
                        filled />
                    <MultiSelector
                        mainOptions={tags}
                        selections={dashboard!.tags || []}
                        onSelection={handleSelectTags}
                        readOnly={isPublicView}
                        loading={updating}
                        limit={3}
                        fullSpan />
                </Stack>
            </Stack>
            <Stack direction="row" spacing={1} width="55%" height="fit-content">
                <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="flex-start" width="50%">
                    <LocationQuery />
                    <FoundedQuery />
                    <HeadcountQuery />
                </Stack>
                <Stack direction="column" spacing={editing ? 1  : 3} alignItems="stretch" justifyContent="flex-start" width="50%">
                    <UrlQuery queryTitle="Website" onEditing={setEditing} />
                    <UrlQuery queryTitle="Crunchbase Page" onEditing={setEditing} />
                    <UrlQuery queryTitle="LinkedIn" onEditing={setEditing} />
                    <DocsendLink dashboard={dashboard!} />
                </Stack>
            </Stack>
        </Stack>
    </>);
}

export default DigestMultiQuery;
