import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {DashboardQueriesContext} from '../contexts/DashboardQueriesContext';
import { AnswerQuestionResponseType } from '../types/search';
import { DashboardQuery, DashboardQuerySource } from '../types/files';
import {AuthContext} from '../contexts/AuthContext';
import { getCrunchbaseEntityFunc, addDashboardQueryFunc, refreshDashboardQueryFunc } from '../lib/helper';
import defaultDashboardQueries from "../helpers/defaultDashboardQueryTemplates.json";
import {DashboardContext} from '../contexts/DashboardContext';
import useDashboard from './useDashboard';

const InitialQueryItems = Object.entries(defaultDashboardQueries as any)
  .filter(([key]) => (defaultDashboardQueries as any)[key].visibleByDefault)
  .map(([_, value]) => ({
      title: (value as { title: string }).title,
      dashboardId: '',
      query: (value as { queryTemplate: string }).queryTemplate
        .replace('{dashboard_name}', (value as { title: string }).title),
      answer: null,
      history: '',
      updatedAt: new Date(),
    } as DashboardQuery
  ));

const LoadedQueryCriteria = ['Description', 'Team', 'Digest', 'Location', 'Questions'];

const useCrunchbaseLoader = () => {
  const { userGroup } = useContext(AuthContext);
  const { dashboard } = useContext(DashboardContext);
  const { queries, crunchbaseFound, defaultQueryTemplateValues,
    setQueries, refreshQuery } = useContext(DashboardQueriesContext);
  const { autoSync } = useDashboard();

  const [queriesLoaded, setQueriesLoaded] = useState<boolean>(false);
  const [crunchbaseLoaded, setCrunchbaseLoaded] = useState<boolean>(false);
  const [crunchbaseLookup, setCrunchbaseLookup] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [timer, setTimer] = useState<string | number | NodeJS.Timeout | undefined>(undefined);
  const timeOutRef = useRef<boolean>(false);

  const saveCrunchbase = useCallback(async (permalink: string, customUrl?: boolean) => {
    const globalUrlPattern = /^(?:(?:(?:https?):\/\/)|(?:www\.|(?!www))[A-Za-z0-9.-]+[A-Za-z]{2,}\/?)?[A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=%]+$/i;
    let promises: any[] = [];

    promises.push(autoSync());

    if (!customUrl) {
      getCrunchbaseEntityFunc(permalink, dashboard!.title).then(async (q) => {
        promises = [
          await addDashboardQueryFunc(
            dashboard!.id,
            defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Location')?.title || 'Location',
            userGroup,
            dashboard!.title,
            JSON.stringify({
              answer: q?.location || 'No information yet.',
              type: AnswerQuestionResponseType.TEXT,
            }),
            undefined,
            defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Location')?.order ?? 0,
            true,
            DashboardQuerySource.CRUNCHBASE
          ),
          await addDashboardQueryFunc(
            dashboard!.id,
            defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Crunchbase Page')?.title || 'Crunchbase',
            userGroup,
            dashboard!.title,
            JSON.stringify({
              answer: `https://www.crunchbase.com/organization/${permalink}`,
              type: AnswerQuestionResponseType.URL,
            }),
            undefined,
            defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Crunchbase Page')?.order ?? 0,
            true,
            DashboardQuerySource.CRUNCHBASE
          ),
          q?.website &&
            await addDashboardQueryFunc(
              dashboard!.id,
              defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Website')?.title || 'Website',
              userGroup,
              dashboard!.title,
              JSON.stringify({
                answer: q?.website,
                type: AnswerQuestionResponseType.URL,
              }),
              undefined,
              defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Website')?.order ?? 0,
              true,
              DashboardQuerySource.CRUNCHBASE
            ),
          q?.linkedin &&
            await addDashboardQueryFunc(
              dashboard!.id,
              defaultQueryTemplateValues.find(defQuery => defQuery.title === 'LinkedIn')?.title || 'LinkedIn',
              userGroup,
              dashboard!.title,
              JSON.stringify({
                answer: q?.linkedin,
                type: AnswerQuestionResponseType.URL,
              }),
              undefined,
              defaultQueryTemplateValues.find(defQuery => defQuery.title === 'LinkedIn')?.order ?? 0,
              true,
              DashboardQuerySource.CRUNCHBASE
            ),
        ];
      });
      setQueries(InitialQueryItems);
    } else if (globalUrlPattern.test(permalink)) {
      promises = [
        await addDashboardQueryFunc(
          dashboard!.id,
          defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Website')?.title || 'Website',
          userGroup,
          dashboard!.title,
          JSON.stringify({
            answer: permalink,
            type: AnswerQuestionResponseType.URL,
          }),
          undefined,
          defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Website')?.order ?? 0,
          true,
          DashboardQuerySource.CRUNCHBASE
        ),
        await refreshDashboardQueryFunc(
          dashboard!.id,
          defaultQueryTemplateValues.find(defQuery => defQuery.title === 'LinkedIn')?.title || 'LinkedIn',
          userGroup,
          dashboard!.title,
          defaultQueryTemplateValues.find(defQuery => defQuery.title === 'LinkedIn')?.order ?? 0,
          DashboardQuerySource.CRUNCHBASE
        ),
      ];
      setQueries(InitialQueryItems.filter((query) => query.title !== 'Crunchbase Page'));
    } else {
      // if there are no companies in Crunchbase, we refresh the below queries which will check other sources as a backup
      promises = [
        await refreshDashboardQueryFunc(
          dashboard!.id,
          defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Website')?.title || 'Website',
          userGroup,
          dashboard!.title,
          defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Website')?.order ?? 0,
          DashboardQuerySource.CRUNCHBASE
        ),
        await refreshDashboardQueryFunc(
          dashboard!.id,
          defaultQueryTemplateValues.find(defQuery => defQuery.title === 'LinkedIn')?.title || 'LinkedIn',
          userGroup,
          dashboard!.title,
          defaultQueryTemplateValues.find(defQuery => defQuery.title === 'LinkedIn')?.order ?? 0,
          DashboardQuerySource.CRUNCHBASE
        ),
      ];
      setQueries(InitialQueryItems.filter((query) => query.title !== 'Crunchbase Page'));
    }

    setCrunchbaseLoaded(true);
    setCrunchbaseLookup(false);
    // eslint-disable-next-line
  }, [dashboard, defaultQueryTemplateValues, userGroup, refreshQuery, autoSync]);

  useEffect(() => {
    if (!queriesLoaded) {
      if (crunchbaseFound) {
        setCrunchbaseLoaded(true);
        setCrunchbaseLookup(false);
      } else if (crunchbaseFound === false) {
        setCrunchbaseLoaded(false);
        setCrunchbaseLookup(true);
      }
    }
  }, [queriesLoaded, crunchbaseFound]);

  useEffect(() => {
    if (crunchbaseLoaded) {
      if (queries.filter((query) => LoadedQueryCriteria.includes(query.title) && !!query.answer)?.length > 2) {
        timeOutRef.current = true;
      } else if (count === 0) {
        setTimer(setInterval(() => setCount(prev => prev + 1), 1000));
      } else if (count > 4) {
        timeOutRef.current = true;
      }
    }

    if (timeOutRef.current) {
      setQueriesLoaded(true);

      return () => {
        clearInterval(timer);
      }
    }
    //eslint-disable-next-line
  }, [count, crunchbaseLoaded]);

  return { queriesLoaded, crunchbaseLoaded, crunchbaseLookup, saveCrunchbase };
};

export default useCrunchbaseLoader;