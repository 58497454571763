import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import { SearchNavigationContext } from '../../../contexts/SearchNavigationContext';
import { getPathHash } from '../../../shared/files';
import { DrivePlatforms } from '../utils/folders';
import { CompanyFile } from '../../../types/files';
import FolderNavigationLayout from '../components/FolderNavigationLayout';
import { AdvancedSearchObject } from '../../../types/search';

interface Props {
  onFolderSelected: (folder: CompanyFile | null) => void;
  onFileSelected: (file: CompanyFile | null) => void;
  heightToSubtract: number;
  tabId: number;
  useVh?: boolean;
}

const FolderNavigationContainer = ({ onFolderSelected, onFileSelected, heightToSubtract, tabId, useVh = true }: Props) => {
  const { contextSelectedFile,
    contextSetSelectedFile,
    contextSelectedFolder,
    contextSetSelectedFolder,
    contextSetAdvancedSearchObject,
  } = useContext(SearchNavigationContext);
  const history = useHistory();
  const { fileStructure, initialLoading } = useContext(FileStructureContext);
  const [selectedFolder, setSelectedFolder] = useState<CompanyFile | null>(null);
  const [selectedFile, setSelectedFile] = useState<CompanyFile | null>(null);
  const [selectedCommentId, setSelectedCommentId] = useState<string | null>();
  const [selectedCommentParentId, setSelectedCommentParentId] = useState<string | null | undefined>();
  const [selectedPage, setSelectedPage] = useState<number | undefined>();
  const location = useLocation();

  useEffect(() => {
    setSelectedFolder(contextSelectedFolder[tabId]);
    setSelectedFile(contextSelectedFile[tabId]);
    //eslint-disable-next-line
  }, [tabId]);

  useEffect(() => {
    if (!initialLoading && fileStructure.length) {
      const params: any = new URLSearchParams(location.search);
      const documentId = params.get("documentId");
      const commentId = params.get("commentId");
      const parentId = params.get("parentId");
      const page = params.get("page");

      if (location) {
        updateFolderAndFile();
      }

      setSelectedCommentParentId(parentId);
      if (documentId && commentId) {
        setSelectedCommentId(commentId);
        const file = fileStructure.find((file: CompanyFile) => file.key === documentId);
        setSelectedFile(file || null);
        contextSetSelectedFile(file || null);
        onFileSelected(file || null);
        const parentFolder = fileStructure.find((folder: CompanyFile) => folder.key === file?.parentId!);
        setSelectedFolder(parentFolder || null);
        onFolderSelected(parentFolder || null);
      } else if (documentId && page && !Number.isNaN(page)) {
        const file = fileStructure.find((file: CompanyFile) => file.key === documentId);
        setSelectedFile(file || null);
        contextSetSelectedFile(file || null);
        onFileSelected(file || null);
        const parentFolder = fileStructure.find((folder: CompanyFile) => folder.key === file?.parentId!);
        setSelectedFolder(parentFolder || null);
        onFolderSelected(parentFolder || null);
        setSelectedPage(page);
      }
    }
    //eslint-disable-next-line
  }, [location, initialLoading, fileStructure]);

  const updateFolderAndFile = () => {
    const element = fileStructure.find((file: CompanyFile) => getPathHash(file.path) === location.hash.replace("#", ""));
    if (element?.isDirectory) {
      setSelectedFolder(element || null);
      onFolderSelected(element || null);
      contextSetSelectedFolder(element || null);
    } else {
      setSelectedFile(element || null);
      onFileSelected(element || null);
      contextSetSelectedFile(element || null);
      const parentFolder = fileStructure.find((folder: CompanyFile) => folder.key === element?.parentId!);
      setSelectedFolder(parentFolder || null);
      onFolderSelected(parentFolder || null);
      contextSetSelectedFolder(parentFolder || null);
    }
  }

  const onFolderSelect = (folder: CompanyFile | null) => {
    onFileSelected(null);
    if (folder && folder.id === (selectedFolder?.id || "")) {
      if (folder.parentId === "root" || DrivePlatforms.includes(folder.path.toLowerCase())) {
        onFolderSelected(null);
        setSelectedFolder(null);
        setSelectedFile(null);
        contextSetSelectedFile(null);
        contextSetSelectedFolder(null);
        history.push({
          pathname: `/folders`,
          hash: ""
        });
      } else {
        const parentFolder = fileStructure.find((x: CompanyFile) => x.key === folder?.parentId!);
        const drive = DrivePlatforms.find(x => x === parentFolder?.path.toLowerCase());
        if (parentFolder && drive) {
          onFolderSelected(parentFolder);
          setSelectedFolder(parentFolder);
          contextSetSelectedFolder(parentFolder);
          history.push({
            pathname: `/folders`,
            hash: `${getPathHash(parentFolder?.path.toLowerCase().replace(drive, "") || "")}`
          });
        } else if (parentFolder && !drive) {
          onFolderSelected(parentFolder);
          setSelectedFolder(parentFolder);
          contextSetSelectedFolder(parentFolder);
          history.push({
            pathname: `/folders`,
            hash: `${getPathHash(parentFolder?.path || "")}`
          });
        }
      }
    } else {
      setSelectedFolder(folder);
      contextSetSelectedFolder(folder);
      history.push({
        pathname: `/folders`,
        hash: `${getPathHash(folder?.path || "")}`
      });
    }
  };

  const onFileSelect = (file: CompanyFile | null) => {
    setSelectedFile(file);
    contextSetSelectedFile(file);
    onFileSelected(file || null);
    if (file) {
      const parentFolder = fileStructure.find((folder: CompanyFile) => folder.key === file?.parentId!);
      setSelectedFolder(parentFolder || null);
      contextSetSelectedFolder(parentFolder || null);
      onFolderSelected(parentFolder || null);
      contextSetAdvancedSearchObject([{
        file,
        fileValue: file.path.split('/').join(" > "),
        folder: parentFolder,
        value: parentFolder?.path.split('/').join(" > "),
        id: "id"
      } as AdvancedSearchObject]);
      history.push({
        pathname: `/folders`,
        hash: `${getPathHash(file?.path || "")}`
      });
    }
  }

  const onFileDeselect = () => {
    setSelectedFile(null);
    contextSetSelectedFile(null);
    onFileSelected(null);
    onFolderSelected(null);
  }

  return (
    <FolderNavigationLayout
      folders={fileStructure}
      selectedFolder={selectedFolder || undefined}
      isLoading={initialLoading}
      selectedFile={selectedFile}
      onFolderSelect={onFolderSelect}
      onFileSelect={onFileSelect}
      onFileDeselect={onFileDeselect}
      selectedCommentId={selectedCommentId}
      selectedCommentParentId={selectedCommentParentId}
      selectedPage={selectedPage}
      heightToSubtract={heightToSubtract}
      useVh={useVh}
    />
  );
};

export default FolderNavigationContainer;
