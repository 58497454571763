import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Box, Divider, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ArticleCategory } from '../../../types/search';
import { getActivityFeedEventIcon } from '../../atoms/activityFeed';

const useStyles = makeStyles(() => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: '30vw',
            height: 'fit-content',
            padding: 16,
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 'unset',
        color: '#7bd4d4',
        fontWeight: 'bold',
        fontSize: '1.1rem',
    },
    dialogContent: {
        display: 'flex',
        height: '40vh',
        flexDirection: 'column',
        padding: 8,
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeButton: {
        borderRadius: 16,
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 'bold',
        width: 120,
    },
    urlIcon: {
        width: 32,
        height: 32,
    },
}));

const FundingModal: React.FC<{
    funding: {
        funding_type: string,
        money_raised?: string,
        investor_list: string,
    }[],
    open: boolean,
    onClose: () => void,
}> = ({ funding, open, onClose }) => {
    const classes = useStyles();

    return (
        <Dialog className={classes.dialog} open={open} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                <Box display="flex">
                    {getActivityFeedEventIcon(ArticleCategory.FUNDRAISING, classes.urlIcon, '#7bd4d4')}
                </Box>
                {'Funding'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                {funding?.map((item: any, i) => (
                    <Stack direction="column" spacing={1} width="100%" key={'dashboard-timeline-258-' + i}>
                        <Stack alignItems="center" mt={1}>
                            <Typography sx={{
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                            }}> {item.money_raised} </Typography>
                            <Typography sx={{
                                fontSize: '1rem',
                            }}> {item.funding_type} </Typography>
                        </Stack>
                        <Stack alignItems="flex-start">
                            {!!item.investor_list && (<>
                                <Typography
                                    component="span"
                                    display="inline"
                                    sx={{
                                        fontSize: '1.1rem',
                                        fontWeight: 'bold',
                                }}>{'Investors: '}</Typography>
                                <Box ml={2} mt={1}>
                                    {(item.investor_list as string).split(',').map((investor, i) => (
                                        <Stack direction="row" alignItems="center" justifyContent="flex-start" key={'search-result-list-977-' + i}>
                                            <Typography fontSize="1rem" sx={{ minWidth: 'fit-content', marginRight: '16px', transform: 'translateY(-2px)' }}>
                                                &#x2022;
                                            </Typography>
                                            <Typography>{investor}</Typography>
                                        </Stack>
                                    ))}
                                </Box>
                            </>)}
                        </Stack>
                    </Stack>
                ))}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button className={classes.closeButton}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClose();
                }}> Back </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FundingModal;