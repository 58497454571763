import React, { useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import classnames from "classnames";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import WorkspacesBulkShareEmail from "../../atoms/workspaces/WorkspacesBulkShareEmail";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.primary['300']}`,
        background: `#fff`,
    },
    header: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['900'],
    },
    checkbox: {
        minWidth: 24,
        maxWidth: 24,
    },
    cell: {
        minWidth: 'calc(20vw - 20px)',
        maxWidth: 'calc(20vw - 20px)',
        minHeight: 50,
        maxHeight: 'none',
        padding: '0 10px',
    },
    wideCell: {
        minWidth: 'calc(30vw + 8px)',
        maxWidth: 'calc(30vw + 8px)',
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    hiddenCell: {
        display: 'none',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    commonButton: {
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
    icon: {
        width: 20,
        height: 20,
    }
}));

const TableHeaders = ['Name/Email', 'Firm/Website', 'Preferences', '', ];

const InvestorsTableHeader: React.FC<{
    collectionKey?: string,
    forSharing?: boolean
}> = ({ collectionKey, forSharing }) => {
    const classes = useStyles();
    const {loading, getBulkWorkspaces, deleteWorkspaces} = useBulkWorkspaces();

    const noBulkAction = useMemo(() => !getBulkWorkspaces(collectionKey || '').length, [collectionKey, getBulkWorkspaces]);

    return (<>
        <Stack direction="row" className={classes.row} alignItems="flex-start" justifyContent="flex-start">
            <Stack className={classes.checkbox} />
            {TableHeaders.map((header, i) => (
                <Stack direction="column" className={classnames(classes.cell,
                    (i === 1 && forSharing) && classes.hiddenCell,
                    (i === 2) && classes.wideCell,
                    (i === 3) && classes.fullWidthCell,
                    (i === 4) && classes.endCell)}
                    alignItems={(i === 3) ? 'flex-end' : 'flex-start'}
                    justifyContent="center"
                    key={'investors-table-header-81-' + i}>
                    {(i === 3) ? !forSharing && (
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                            <LoadingButton variant="contained"
                                className={classes.commonButton}
                                startIcon={<DeleteOutlineIcon className={classes.icon} />}
                                disabled={noBulkAction || loading}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    await deleteWorkspaces(collectionKey || '');
                                }}
                                sx={{ width: 170 }}>{'Delete selected'}</LoadingButton>
                            <WorkspacesBulkShareEmail />
                        </Stack>
                    ) : (<Typography className={classes.header}>{header}</Typography>)}
                </Stack>
            ))}
            <Stack className={classnames(classes.cell, classes.endCell)} />
        </Stack>
    </>);
}

export default InvestorsTableHeader;