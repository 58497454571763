import React from 'react';
import { Chip, Divider, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SimilarCompanyComponentAnswer from './SimilarCompanyComponentAnswer';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import { AnswerQuestionResponseUrlList, NoInfoYet } from '../../../types/search';

const useStyles = makeStyles((theme) => ({
    urlContainer: {
        margin: '8px 0',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
}));

const SimilarCompaniesAnswer: React.FC<{ answeredQuestion: AnsweredQuestion }> = ({ answeredQuestion }) => {
    const classes = useStyles();

    return (<>
        <Stack direction="column" maxHeight={400} height='auto' overflow="auto">
            {Array.isArray(answeredQuestion?.answer) && !!(answeredQuestion?.answer as AnsweredQuestion[])?.length ?
                (answeredQuestion as AnswerQuestionResponseUrlList)?.answer.sort((a, b) => a.date ? (b.date ? 0 : -1) : (b.date ? 1 : 0))
                .map((curr, i) => (
                    <Stack direction="column" className={classes.urlContainer} key={'search-result-list-1046-' + i}>
                        <Divider />
                        <SimilarCompanyComponentAnswer answer={curr} />
                        {!!curr.investors && (
                            <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ flexWrap: 'wrap' }}>
                                {curr.investors.map((investor, i) => (
                                    <Chip label={`Funded by ${investor}`} size="small" sx={{
                                        padding: '0 12px',
                                        margin: '4px 4px 0 0',
                                        color: '#048290',
                                        backgroundColor: '#E0F1F1',
                                        fontSize: 13,
                                        fontWeight: 'bold',
                                    }} key={'search-result-list-231-' + i} />
                                ))}
                            </Stack>
                        )}
                    </Stack>
            )) : (
                <Stack className={classes.urlContainer}>
                    <Stack direction="column" height={124} overflow="hidden">
                        <Typography>{NoInfoYet.answer}</Typography>
                    </Stack>
                </Stack>
            )}
        </Stack>
    </>);
}

export default SimilarCompaniesAnswer;
