import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import TabPanel from './TabPanel';
import { FolderTab } from '../../../types/files';
import SearchContainer from '../containers/SearchContainer';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    margin: '15px 30px',
  },
  icon: {
    padding: theme.spacing(0.25),
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  addIcon: {
    color: theme.palette.primary.main,
  },
  indicator: {
    display: 'none',
  },
  selectedTab: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    border: "none !important",
    "&::before": {
      boxShadow: `2px 2px 0 ${theme.palette.primary.main} !important`,
      border: "none !important",
      left: "-10px !important",
      bottom: "0 !important",
    },
    "&::after": {
      boxShadow: `-2px 2px 0 ${theme.palette.primary.main} !important`,
      border: "none !important",
      right: "-10px !important",
      bottom: "0 !important",
    },
  },
  tab: {
    color: theme.palette.primary.light,
    backgroundColor: "#F7F5F2",
    display: "flex",
    justifyContent: "space-between",
    minHeight: 0,
    minWidth: theme.spacing(20),
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
    textTransform: 'none',
    marginLeft: theme.spacing(2.5),
    overflow: "visible",
    border: `1px solid ${grey[300]}`,
    borderBottom: "none",
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    "&::before": {
      content: '" "',
      position: "absolute",
      left: -11,
      bottom: -1,
      boxShadow: `2px 2px 0 #F7F5F2`,
      width: 10,
      height: 10,
      borderBottomRightRadius: 7,
      borderRadius: "0 1px 1px 0",
      border: `1px solid ${grey[300]}`,
      borderWidth: "0 1px 1px 0"
    },
    "&::after": {
      content: '" "',
      position: "absolute",
      right: -11,
      bottom: -1,
      boxShadow: `-2px 2px 0 #F7F5F2`,
      width: 10,
      height: 10,
      borderBottomLeftRadius: 7,
      borderRadius: "0 0 1px 1px",
      border: `1px solid ${grey[300]}`,
      borderWidth: "0 0 1px 1px"
    },
  },
  newTabButton: {
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(0.5),
    textTransform: 'none',
  },
  tabs: {
    marginTop: theme.spacing(0.25),
    minHeight: 36,
  },
  paperContainer: {
    height: '100%',
    flex: '1',
    borderRadius: 10,
  }
}));

interface Props {
  tabs: FolderTab[];
  selectedTab: number;
  onTabChange: (newTab: number) => void;
  closeTab: (tab: number) => void;
  addNewTab: () => void;
}

const FolderTabs = ({
  tabs,
  selectedTab,
  onTabChange,
  closeTab,
  addNewTab,
}: Props) => {
  const classes = useStyles();

  return (
    <Box height="calc(100% - 30px)" display="flex" flexDirection="column" className={classes.tabContainer}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={selectedTab}
        onChange={(_, value) => onTabChange(value)}
        classes={{ root: classes.tabs, indicator: classes.indicator }}
      >
        {tabs.map(({ value, name }, i) => (
          <Tab
            value={value}
            label={
              <Typography variant="subtitle1" color="inherit">
                {name}
              </Typography>
            }
            icon={
              <CloseIcon
                className={classNames(classes.icon)}
                fontSize="medium"
                onClick={(event) => {
                  event.stopPropagation();
                  closeTab(value);
                }}
            key={'folder-tabs-150-' + i} />
            }
            iconPosition="end"
            classes={{ root: classes.tab, selected: classes.selectedTab }}
          />
        ))}
        <Tab
          value={0}
          onClick={(event) => {
            event.stopPropagation();
            addNewTab();
          }}
          icon={<AddCircleOutlineIcon fontSize="medium" className={classNames(classes.icon, classes.addIcon)} />}
          classes={{ root: classes.newTabButton }}
        />
      </Tabs>
      <Box flex={1} maxHeight="calc(100% - 36px)">
        <Paper className={classes.paperContainer}>
          {tabs.map(({ value }, i) => (
            <TabPanel hidden={value !== selectedTab} key={'folder-tabs-169-' + i}>
              <SearchContainer tabId={value} />
            </TabPanel>
          ))}
        </Paper>
      </Box>
    </Box>
  );
};

export default FolderTabs;
