import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, List, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CompanyFile } from '../../../../types/files';
import { FileStructureContext } from "../../../../contexts/FileStructureContext";
import theme from '../../../../theme';
import FileCategoryRow from "./FileCategoryRow";
import { DashboardContext } from "../../../../contexts/DashboardContext";
import FileUploader from "../../../molecules/file-uploader/FileUploader";
import { updateFileFunc } from "../../../../lib/helper";
import { AuthContext } from "../../../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
    filesContainer: {
        width: "-webkit-fill-available",
        padding: 20,
    },
    categoriesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        position: 'relative',
        alignContent: 'flex-start'
    },
    categoryContainer: {
        width: '100%'
    },
    emtpyState: {
        textAlign: 'center',
    },
    fileIcon: {
        marginRight: theme.spacing(1),
        height: 28,
        width: 28,
    },
    headerContainer: {
        marginBottom: 20,
    },
    categoryName: {
        fontWeight: 'bold',
        color: theme.palette.text.disabled,
        marginBottom: 15,
    },
    categoryContent: {
        marginBottom: 20,
    },
}));

const FileCategories: React.FC<{ dashboardId: string, onFileSelect: (file: CompanyFile) => void }> = ({ dashboardId, onFileSelect }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { dashboard }  = useContext(DashboardContext);
    const { fileStructure, reloadFileStructure } = useContext(FileStructureContext);
    const [files, setFiles] = useState<CompanyFile[]>();
    const [groupedFiles, setGroupedFiles] = useState<any>({});

    const handleSetDeck = useCallback(async (file: CompanyFile) => {
        const deckFiles = files?.filter(f => f.isDeck);
        const promises: Promise<CompanyFile>[] = [];

        deckFiles?.forEach(dF => {
            promises.push(updateFileFunc({
                id: dF.id,
                group: userGroup,
                documentCategory: dF.documentCategory,
                status: dF.status,
                dashboardId: dF.dashboardId,
                marketMetrics: dF.marketMetrics,
                isDeck: false,
            }))
        });

        promises.push(updateFileFunc({
            id: file.id,
            group: userGroup,
            documentCategory: file.documentCategory,
            status: file.status,
            dashboardId: file.dashboardId,
            marketMetrics: file.marketMetrics,
            isDeck: true,
        }));

        await Promise.all(promises);
    }, [files, userGroup]);

    useEffect(() => {
        const files = fileStructure.filter(x => !x.isDirectory && x?.dashboardId === dashboardId);
        setFiles(files);
        const grouped = files.reduce<{ [key: string]: CompanyFile[] }>((aggr, file) => {
            return {
                ...aggr,
                [file.documentCategory]: [...(aggr[file.documentCategory] || []), file]
            }
        }, {});
        setGroupedFiles(grouped);

        if (files.length === 1 && !files[0].isDeck)
            handleSetDeck(files[0]);
        //eslint-disable-next-line
    }, [fileStructure]);

    const categories = Object.keys(groupedFiles).sort();

    return (
        <div className={classes.filesContainer}>
            <Box className={classes.headerContainer}>
                <Typography variant="h5" fontWeight="bold">All Documents</Typography>
                <Typography color={theme.palette.grey['500']}>{files?.length} files</Typography>
            </Box>
            <div className={classes.categoriesContainer}>
                {!!categories?.length ? (
                    categories?.map((prop, i) => {
                        const categorizedFiles = groupedFiles[prop];

                        return <div className={classes.categoryContainer} key={'file-categories-77-' + i}>
                            <Typography className={classes.categoryName}>{prop}</Typography>
                            <List className={classes.categoryContent}>
                                {categorizedFiles?.map((file: CompanyFile) => {
                                    return <FileCategoryRow
                                        file={file}
                                        refetch={reloadFileStructure}
                                        onFileSelect={onFileSelect}
                                        onSetDeck={handleSetDeck}
                                        key={'file-categories-121-' + file.id} />
                                })}
                            </List>
                        </div>
                    })
                ) :(
                    <Stack spacing={4} alignItems="center" justifyContent="center" width="100%" height="100%" mt={3}>
                        <Typography variant="h6" color="#666666">{'No files yet.'}</Typography>
                        <FileUploader dashboard={dashboard} />
                    </Stack>
                )}
            </div>
        </div>
    );
};

export default FileCategories;