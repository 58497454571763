import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DashboardQuery, DashboardQuerySource } from '../types/files';
import { deleteDashboardQueryFunc, addDashboardQueryFunc, getCrunchbaseEntityFunc, refreshDashboardQueryFunc, getPublicDashboardQueriesFunc, getDashboardQueriesFunc } from '../lib/helper';
import {AuthContext} from './AuthContext';
import { AnswerQuestionResponse, AnswerQuestionResponseType } from '../types/search';
import defaultDashboardQueries from "../helpers/defaultDashboardQueryTemplates.json";
import { API, graphqlOperation } from 'aws-amplify';
import { onDashboardQuery } from '../graphql/subscriptions';
import ObjectUtils from '../utils/ObjectUtils';
import { DashboardContext } from './DashboardContext';
import { DashboardsContext, OverviewQueryTitles } from './DashboardsContext';

export type AnsweredQuestion = AnswerQuestionResponse & { question: string, time: Date };
export type DashboardQueryAnswer = DashboardQuery & {answeredQuestion: AnsweredQuestion};

export type DefaultQueryTemplate = {
  isPrimaryQuery: boolean,
  title: string,
  displayTitle: string,
  queryTemplate: string,
  source: DashboardQuerySource,
  order: number,
  visibleByDefault: boolean,
};

type DashboardQueriesContextProps = {
  queries: DashboardQuery[];
  isLoading: boolean;
  crunchbaseFound: boolean|null;
  defaultQueryTemplateValues: DefaultQueryTemplate[];
  setQueries: Dispatch<SetStateAction<DashboardQuery[]>>;
  refreshQuery: (title: string, parameter?: string) => void;
  removeQuery: (query: DashboardQuery) => void;
  modifyQuery: (query: DashboardQuery, result: AnswerQuestionResponse) => void;
}

export const DashboardQueriesContext = createContext<DashboardQueriesContextProps>({
  queries: [],
  isLoading: false,
  crunchbaseFound: null,
  defaultQueryTemplateValues: [],
  setQueries: () => {},
  refreshQuery: () => {},
  removeQuery: () => {},
  modifyQuery: () => {},
});

const DashboardQueriesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { userGroup } = useContext(AuthContext);
  const { dashboard, isPublicView } = useContext(DashboardContext);
  const { setDashboardsQueries } = useContext(DashboardsContext);

  const [queries, setQueries] = useState<DashboardQuery[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [crunchbaseFound, setCrunchbaseFound] = useState<boolean|null>(null);

  const dashboardQuerySubscriptionRef = useRef<any>(null);
  const queriesRef = useRef<DashboardQuery[]>([]);

  const defaultQueryTemplateValues: DefaultQueryTemplate[] = useMemo(() =>
    Object.values(defaultDashboardQueries as { [key: string]: DefaultQueryTemplate })
  , []);

  const updateSubscribedQuery = useCallback((updatedQuery?: DashboardQuery|null) => {
    if (!updatedQuery?.dashboardId)
      return;

    if (updatedQuery?.dashboardId !== dashboard?.id)
      return;

    const lookUpQuery = queriesRef.current.find((query) => query.title === updatedQuery.title);

    if (!lookUpQuery) {
      queriesRef.current.push(updatedQuery);
      setQueries(queriesRef.current.slice());
    } else if (!ObjectUtils.equalObjects(lookUpQuery, updatedQuery)) {
      queriesRef.current = queriesRef.current.map((query) =>
        query.title === updatedQuery.title ? updatedQuery : query
      );
      setQueries(queriesRef.current.slice());
    }

    if (OverviewQueryTitles.includes(updatedQuery.title)) {
      setDashboardsQueries(prev => [updatedQuery, ...prev.filter(query =>
        !(query.dashboardId === updatedQuery.dashboardId && query.title === updatedQuery.title)
      )]);
    }
  // eslint-disable-next-line
  }, [dashboard]);

  const subscribeDashboardQueries = useCallback(async (id: string) => {
    if (dashboardQuerySubscriptionRef.current)
        dashboardQuerySubscriptionRef.current.unsubscribe();

    if (userGroup) {
      dashboardQuerySubscriptionRef.current = await (API.graphql(
        graphqlOperation(onDashboardQuery, { group: userGroup, dashboardId: id })) as any)
          .subscribe({ next: ({ value }: any) => updateSubscribedQuery(value?.data?.onDashboardQuery)});
    }
  }, [userGroup, updateSubscribedQuery]);

  const refreshQuery = useCallback(async (queryTitle: string, parameter?: string) => {
    const queryDefaultValues = defaultQueryTemplateValues.find(defQuery => defQuery.title === queryTitle);

    if (!dashboard || !queryDefaultValues)
      return;

    const { isPrimaryQuery, queryTemplate, order, source } = queryDefaultValues;

    if ((!isPrimaryQuery || source === DashboardQuerySource.CRUNCHBASE) && source !== DashboardQuerySource.LINKEDIN)
      return;

    const lookUpQuery = queries.find(query => query.title === queryTitle);
    const manualOverride = lookUpQuery?.manualOverride ?? null;
    const deletedAt = lookUpQuery?.deletedAt ?? null;

    if((manualOverride === true) || (deletedAt !== null))
      return;

    if (queryTitle === 'Location') {
      const permalink = !!parameter ? JSON.parse(parameter)?.match( /\/company\/(.+)$/)?.[1] : null;

      if (permalink) {
        getCrunchbaseEntityFunc(permalink, dashboard.title)
          .then(async (result) => {
            if (result?.location) {
              await addDashboardQueryFunc(
                dashboard!.id,
                queryTitle,
                userGroup,
                dashboard!.title,
                JSON.stringify({ answer: result?.location, type: AnswerQuestionResponseType.TEXT }),
                undefined,
                order,
                true,
                DashboardQuerySource.CRUNCHBASE,
              );
            }
          });
      }
    } else {
      await refreshDashboardQueryFunc(dashboard.id, queryTitle, userGroup, queryTemplate.replace('{dashboard_name}', dashboard.title), order, source);
    }
  }, [defaultQueryTemplateValues, dashboard, queries, userGroup]);

  const removeQuery = useCallback((query: DashboardQuery) => {
    if (!!dashboard) {
      setLoading(true);
      deleteDashboardQueryFunc(dashboard.id, query.title, userGroup)
        .then((updatedQuery) => {
          queriesRef.current = queriesRef.current.map((query) =>
            query.title === updatedQuery.title ? updatedQuery : query
          );
          setQueries(queriesRef.current.slice());
        }).finally(() => setLoading(false));
    }
  }, [userGroup, dashboard]);

  const modifyQuery = useCallback((query: DashboardQuery, answer: AnswerQuestionResponse) => {
    if (!!dashboard) {
      setLoading(true);
      addDashboardQueryFunc(
        dashboard.id,
        query.title,
        userGroup,
        query.query,
        JSON.stringify({answer: answer.answer, type: answer.type}),
        !!answer?.history ? JSON.stringify(answer.history) : query.history,
        query.order,
        true,
        DashboardQuerySource.MANUAL)
        .then((updatedQuery) => {
          queriesRef.current = queriesRef.current.map((query) =>
            query.title === updatedQuery.title ? updatedQuery : query
          );
          setQueries(queriesRef.current.slice());
        }).finally(() => setLoading(false));
    }
  }, [userGroup, dashboard]);

  const fetchDashboardQueries = useCallback(async () => {
    const queriesData: DashboardQuery[] = (isPublicView && dashboard!.isPublic)
      ? await getPublicDashboardQueriesFunc(dashboard!.id)
      : await getDashboardQueriesFunc(dashboard!.id);

    if (!queriesData.some((query: DashboardQuery) => (query.isRefreshing || !!query.answer)
      && query.source === DashboardQuerySource.CRUNCHBASE)) {
      setCrunchbaseFound(false);
    } else if (!!queriesData?.length) {
      queriesRef.current = [...queriesData];

      if (!queriesRef.current.find((query) => query.title === 'Location')) {
        queriesRef.current.push({
          title: 'Location',
          order: defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Location')?.order ?? 0,
          query: defaultQueryTemplateValues.find(defQuery => defQuery.title === 'Location')?.queryTemplate
            ?.replace('{dashboard_name}', 'Location') || '{dashboard_name}',
          updatedAt: new Date(),
          source: DashboardQuerySource.CRUNCHBASE,
          isRefreshing: false,
        } as DashboardQuery);
      }
      setQueries(queriesRef.current.slice());
      setCrunchbaseFound(true);
    }
  }, [dashboard, defaultQueryTemplateValues, isPublicView]);

  useEffect(() => {
    if (!dashboard?.id) {
      queriesRef.current = [];
      setQueries(queriesRef.current.slice());
      setLoading(false);
      setCrunchbaseFound(null);
    } else if (!queries.length) {
      setLoading(true);
      fetchDashboardQueries().then(() => setLoading(false));
    }
  // eslint-disable-next-line
  }, [dashboard?.id]);

  useEffect(() => {
    if (!!userGroup && !!dashboard)
      subscribeDashboardQueries(dashboard.id);
    else
      dashboardQuerySubscriptionRef.current?.unsubscribe();

    return () => {
      dashboardQuerySubscriptionRef.current?.unsubscribe();
    }
    // eslint-disable-next-line
  }, [userGroup, dashboard]);

  const contextValue = useMemo(() => ({
    queries,
    isLoading,
    crunchbaseFound,
    defaultQueryTemplateValues,
    setQueries,
    refreshQuery,
    removeQuery,
    modifyQuery,
  }), [
    queries,
    isLoading,
    crunchbaseFound,
    defaultQueryTemplateValues,
    setQueries,
    refreshQuery,
    removeQuery,
    modifyQuery,
  ]);

  return (
    <DashboardQueriesContext.Provider value={contextValue}>
      {children}
    </DashboardQueriesContext.Provider>
  );
};

export default DashboardQueriesProvider;
