import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';

const useMutation = <T>(mutation: {
  name: string;
  statement: string;
}): [
  (params?: object) => Promise<T>,
  { loading: boolean; data?: T; error?: Error },
] => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error>();

  const mutationFunction = async (params?: object): Promise<T> => {
    setLoading(true);
    try {
      const response = (await API.graphql(
        graphqlOperation(mutation.statement, params),
      )) as any;
      setData(response.data[mutation.name]);
      setLoading(false);
      return response.data[mutation.name];
    } catch (e) {
      setLoading(false);
      setError(e as Error);
      throw e;
    }
  };

  return [mutationFunction, { loading, data, error }];
};

export default useMutation;
