import { useState, useContext, useCallback } from 'react';
import { CircularProgress, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useDropzone } from 'react-dropzone';
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { CompanyFile } from '../../../types/files';
import FileManagementContainer from '../containers/FileManagementContainer';
import FilePreview from './FilePreview';
import classNames from "classnames";
import { CommentOriginType, ExtendedComment } from '../../../types/comments';
import awsConfig from '../../../aws-exports';
import useMutation from '../../../hooks/useMutation';
import { deleteFile } from '../../../graphql/mutations';
import { deleteCommentsFunc } from '../../../lib/helper';
import FileCategories from './categories/OldFileCategories';
import FoldersGroupedList from './FoldersGroupedList';
import { scrollbarStyle } from '../../../shared/dashboard';
import { s3Delete } from '../../../helpers/s3';

const useStyles = makeStyles((theme) => ({
  content: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "#F7F5F2",
    '-moz-transition': 'width .3s',
    '-ms-transition': 'width .3s',
    '-o-transition': 'width .3s',
    '-webkit-transition': 'width .3s',
    transition: 'width .3s ease-out',
    ...scrollbarStyle
  },
  folderListContainer: {
    height: '100%',
    paddingRight: 20,
    paddingTop: 10
  },
  folderListProgress: {
    display: "flex",
    justifyContent: "center"
  },
  dropzoneContainer: {
    height: 'calc(100% - 130px)',
    border: `5px solid ${grey[300]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 10px',
  },
  folderUploadError: {
    padding: '0 24px',
  },
  fileLoaderContainer: {
    margin: '15px 24px 0 15px',
    width: 'calc(100% - 48px)',
  },
  fileLoader: {
    width: '100%',
  },
  fileLoaderText: {
    fontSize: '0.8rem',
  },
  snackbarProgress: {
    color: theme.palette.primary.dark
  },
  dropLink: {
    color: theme.palette.secondary.main,
    fontWeight: '900',
    marginLeft: 5,
    marginRight: 5,
    cursor: 'pointer'
  },
  root: {
    borderRight: `2px solid #F7F5F2`,
    overflow: 'auto',
    marginLeft: 30,
    position: 'relative',
    '-moz-transition': 'width .3s',
    '-ms-transition': 'width .3s',
    '-o-transition': 'width .3s',
    '-webkit-transition': 'width .3s',
    transition: 'width .3s ease-out',
  },
  addButton: {
    border: "none",
    "&:hover": {
      border: "none",
    }
  },
  noFileSelected: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));


const getStageBucketName = (exports: any) => {
  return exports.aws_stage_bucket
}

interface Props {
  folders: CompanyFile[];
  selectedFolder?: CompanyFile;
  isLoading: boolean;
  selectedFile?: CompanyFile | null;
  onFolderSelect: (folder: CompanyFile | null) => void;
  onFileSelect: (file: CompanyFile | null) => void;
  onFileDeselect: () => void;
  onSetDashboardDeck?: ({ fileKey, page }: { fileKey: string, page?: number }) => Promise<void>;
  selectedCommentId?: string | null;
  selectedCommentParentId?: string | null;
  selectedExtendedComment?: ExtendedComment;
  selectedPage?: number | undefined;
  heightToSubtract: number;
  useVh?: boolean;
  showFileManagement?: boolean;
  dashboardId?: string
}

const FolderNavigationLayout = ({
  folders,
  selectedFolder,
  isLoading,
  selectedFile,
  onFolderSelect,
  onFileSelect,
  onFileDeselect,
  onSetDashboardDeck,
  selectedCommentId,
  selectedCommentParentId,
  selectedExtendedComment,
  selectedPage,
  heightToSubtract,
  useVh = true,
  showFileManagement = true,
  dashboardId
}: Props) => {
  const classes = useStyles();
  const { userGroup } = useContext(AuthContext);
  const { fileStructure } = useContext(FileStructureContext);
  const [folderUploadAllowed, setFolderUploadAllowed] = useState<boolean>(true);
  const [showFileDeleteModal, setShowFileDeleteModal] = useState<boolean>(false);
  const [showFolderDeleteModal, setShowFolderDeleteModal] = useState<boolean>(false);
  const [menuExpanded, setMenuExpanded] = useState<boolean>(false);

  const [currentUploadingFolder, setCurrentUploadingFolder] = useState<string | null>(null);

  const [droppedFolder, setDroppedFolder] = useState<File[] | undefined>(undefined);
  const [droppedFile, setDroppedFile] = useState<File | undefined>(undefined);

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length
      && (acceptedFiles.length > 1 || acceptedFiles[0].path.split('/').length > 2)
    ) {
      setDroppedFolder(acceptedFiles);
      setCurrentUploadingFolder(getDroppedFolderName(acceptedFiles));
    }
  }, []);

  const clearFiles = () => {
    setDroppedFile(undefined);
    setDroppedFolder(undefined);
  }

  const getDroppedFolderName = (files: any): string => {
    if (!files || !files.length) {
      return "";
    }

    let path: string = files[0].webkitRelativePath;

    if (!path) {
      path = files[0].path;
    }

    return path.split('/').filter(x => x)[0];
  }

  const stagingBucket = getStageBucketName(awsConfig);

  const { getRootProps, isDragActive, fileRejections } = useDropzone({ onDrop });

  const [deleteFileGql] = useMutation({ statement: deleteFile, name: "deleteFile" });

  const goToCategories = () => {
    onFileDeselect();
  }

  const getFoldersHeight = (): string => {
    return "calc(100% - 70px)";
  }

  const deleteFiles = async (files: CompanyFile[]) => {
    await Promise.all(
      files.map((file) => {
        return Promise.all([
          s3Delete({ path: file.key }),
          deleteCommentsFunc(file.key),
          deleteFileGql({ id: file.id, group: userGroup }),
        ]);
      })
    );
  }

  const deleteCurrentlySelectedFile = async (file: CompanyFile) => {
    await deleteFiles([file]);

    onFileSelect(null);
  };

  const deleteCurrentlySelectedFolder = async (folder: CompanyFile) => {
    const filesToDelete = fileStructure.filter((file) => !file.isDirectory && file.key.includes(folder?.id || ''));
    onFolderSelect(null);
    await deleteFiles(filesToDelete);
  };

  const toggleFileDeleteModal = () => setShowFileDeleteModal(v => !v);
  const toggleFolderDeleteModal = () => setShowFolderDeleteModal(v => !v);

  const filePreview = (
    <FilePreview
      filePath={selectedFile?.path}
      fileKey={selectedFile?.key || ""}
      page={selectedPage}
      selectedExtendedComment={selectedExtendedComment}
      selectedCommentId={selectedCommentId}
      selectedCommentParentId={selectedCommentParentId}
      enableComments={true}
      enableDashboard={false}
      showCategories={false}
      showDiscussion={false}
      commentOriginType={CommentOriginType.Folder}
      onGoToCategories={goToCategories}
      onCommentAdded={(x) => {
        if (selectedFile) {
          onFileSelect({ ...selectedFile });
        }
      }}
      onSetDashboardDeck={onSetDashboardDeck}
      showAddCrop={false}
      dashboardId={dashboardId}
    />
  );

  const fileCategories = (
    <FileCategories
      folder={selectedFolder?.path || ''}
      onFileSelect={(file: CompanyFile) => {
        onFileSelect(file);
      }}
    />
  );

  const content = (selectedFolder && selectedFile) ? filePreview : fileCategories;

  return (
    <Box
      position="relative"
      width="100%"
      height={`calc(${useVh ? `100vh - ${heightToSubtract}px` : '100%'})`}
      maxWidth="calc(100vw - 84px)"
      maxHeight={`calc(${useVh ? `100vh - ${heightToSubtract}px` : '100%'})`}
      display="flex"
      flexDirection="row"
    >
      <Box
        className={classes.root}
        height="100%"
        width={menuExpanded ? 400 : 84}
        onMouseEnter={() => setMenuExpanded(true)}
        onMouseLeave={() => setMenuExpanded(false)}
      >
        <div
          {...getRootProps()}
          className={classNames(classes.folderListContainer, "no-scrollbar")}
          style={{ height: getFoldersHeight(), overflow: useVh ? "auto" : "hidden", position: 'relative' }}
        >
          {
            isDragActive ?
              <div className={classes.dropzoneContainer}>
                <Typography color="textPrimary" align="center">Drop files in {selectedFolder ? selectedFolder.name : "root"} </Typography>
              </div>
              :
              <div>
                {
                  isLoading ?
                    <div className={classes.folderListProgress}>
                      <CircularProgress />
                    </div>
                    :
                    <FoldersGroupedList
                      folders={folders}
                      selectedFolder={selectedFolder}
                      onFolderSelect={(folder) => {
                        onFolderSelect(folder);
                        setFolderUploadAllowed(true);
                      }}
                      selectedFile={selectedFile}
                      onFileSelect={onFileSelect}
                      currentUploadingFolderName={currentUploadingFolder}
                      openDeleteFileModal={showFileDeleteModal}
                      openDeleteFolderModal={showFolderDeleteModal}
                      toggleFileDelete={toggleFileDeleteModal}
                      toggleFolderDelete={toggleFolderDeleteModal}
                      deleteCurrentlySelectedFile={deleteCurrentlySelectedFile}
                      deleteCurrentlySelectedFolder={deleteCurrentlySelectedFolder}
                      showRoot={showFileManagement}
                    />
                }
                {!!fileRejections.length && <Typography className={classes.folderUploadError} color='error'>
                  Folder not accepted
                </Typography>}
                {!folderUploadAllowed && <Typography className={classes.folderUploadError} color='error'>
                  Folder with specified name already exists in {selectedFolder?.name || "root"}
                </Typography>}
              </div>
          }
        </div>
        {showFileManagement && <FileManagementContainer
          openUploadFolderModal={false}
          stagingBucket={stagingBucket}
          currentFolder={selectedFolder}
          currentFile={selectedFile}
          droppedFolder={droppedFolder}
          droppedFile={droppedFile}
          onClearFiles={clearFiles}
        />}
        <IconButton
          style={{ position: 'absolute', bottom: '20px', right: '0' }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setMenuExpanded(!menuExpanded)
          }}
        >
          {menuExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </Box>
      <Box
        className={classes.content}
      >
        {(selectedFolder && content) ? content : (
          <div className={classes.noFileSelected}><Typography>No file selected.</Typography></div>
        )}
      </Box>
    </Box>
  );
};

export default FolderNavigationLayout;
