import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { CommentOriginType, Comment } from "../../../types/comments";
import { SaveDashboardItemType } from "../../../types/common";
import { DashboardDocument, DashboardUid } from "../../../types/files";
import FilePreview from "../../folders/components/FilePreview";

const useStyles = makeStyles((theme) => ({
    viewerPaperContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: "100%",
        height: 460,
        overflow: "hidden",
        justifyContent: "center",
        borderRadius: 4,
        position: 'relative',
    },
    titleContainer: {
        cursor: "pointer"
    },
    iconButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
    zoomOutButton: {
        marginTop: 10
    },
    zoomButtonsContainer: {
        position: "absolute",
        top: 15,
        right: 15,
        display: "flex",
        flexDirection: "column"
    }
}));

const DashboardFileComponent: React.FC<{
    dashboardId: string,
    dashboardItemType: SaveDashboardItemType,
    data: DashboardDocument,
    comments: Comment[],
    onCommentAdded: (item: Comment) => void,
    selectedCommentId: string | null | undefined,
    selectedCommentParentId: string | null | undefined
}> = ({ dashboardItemType, dashboardId, data, comments, onCommentAdded, selectedCommentId, selectedCommentParentId }) => {
    const classes = useStyles();

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>
                <div className={classes.viewerPaperContainer}>
                    <FilePreview
                        fileKey={data.key}
                        page={data.pages[0]}
                        highlightedKeywords={data.searchTerms}
                        enableDashboard={false}
                        enableComments
                        enableCommentsPane={false}
                        sourceComments={comments}
                        onCommentAdded={onCommentAdded}
                        selectedCommentId={selectedCommentId}
                        selectedCommentParentId={selectedCommentParentId}
                        commentOriginType={CommentOriginType.Dashboard}
                        dashboardItem={{
                            id: dashboardId,
                            dashboardItemId: data.id,
                            dashboardItemType: dashboardItemType,
                            documentKey: data.key,
                        } as DashboardUid}
                        dashboardId={dashboardId}
                    />
                </div>
            </div>
        </div>
    )
};

export default DashboardFileComponent;
