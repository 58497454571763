import React from 'react';
import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import classnames from 'classnames';
import { NoInfoYet } from '../../../types/search';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        breakInside: 'avoid',
    },
    searchResultDigest: {
        background: 'linear-gradient(90deg, rgba(212,236,237,1) 0%, rgba(240,254,254,1) 100%)',
        borderRadius: '24px',
        padding: '24px',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    answerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textContainerWideGradient: {
        width: '100%',
        height: 'auto !important',
    },
    text: {
        paddingTop: 8,
        color: '#666666',
        height: '80px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));

const DifferentiatingFactorAnswer: React.FC<{ answeredQuestion: AnsweredQuestion }> = ({ answeredQuestion }) => {
    const classes = useStyles();

    return (<>
        <Box className={classnames(classes.masonryElement, classes.searchResultDigest)} mt="16px !important">
            <Box className={classes.titleContainer}>
                <Typography fontSize="16px" fontWeight="bold" fontFamily="Inter" color="#7bd4d4">
                    {`Competitive Advantage`}
                </Typography>
            </Box>
            <Box className={classnames(classes.answerContainer, classes.textContainerWideGradient)}>
                <Box className={classnames(classes.text, classes.textContainerWideGradient)}>
                    <Typography mb={1} sx={{ color: '#048290', fontSize: '15px', fontWeight: 'bold', lineHeight: 'unset' }}>
                        {answeredQuestion?.answer as string || NoInfoYet.answer}
                    </Typography>
                </Box>
            </Box>
        </Box>
    </>);
}

export default DifferentiatingFactorAnswer;
