import { useCallback, useContext, useMemo, useState } from 'react';
import { ExtFile } from "@files-ui/react";
import Uppy, { ErrorResponse, FileProgress, SuccessResponse, UploadResult, UppyFile } from "@uppy/core";
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';
import awsConfig from '../aws-exports';
import path from 'path';
import Transloadit from '@uppy/transloadit';
import {AuthContext} from '../contexts/AuthContext';
import { SingleFilesFolderName } from '../shared/files';
import { Dashboard } from '../types/files';
import { getUserEmail } from '../helpers/authUser';
import { s3UploadToExternalBucket } from '../helpers/s3';
import { createDocSendIngestionFunc } from '../lib/helper';
import { User } from '../types/auth';

const { environment, aws_file_uploads_bucket } = awsConfig;

const DocsendRegex = [/.*:\/\/docsend\.com\/view\/.*/];

const useFileUploader = ({ validFile, validPath }: {
        validFile?: {
            maxFileSize?: number,
            allowedFileTypes?: Array<string>,
        },
        validPath?: string,
    }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { user, userGroup } = useContext(AuthContext);

    const [files, setFiles] = useState<ExtFile[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [hasUploaded, setHasUploaded] = useState<boolean>(false);

    const uppy = useMemo(() => {
        const uppyInstance = new Uppy({
            meta: { type: 'avatar' },
            restrictions: {
                maxFileSize: validFile?.maxFileSize,
                allowedFileTypes: validFile?.allowedFileTypes,
            },
        });

        uppyInstance.use(Transloadit, {
            getAssemblyOptions(file) {
              return {
                params: {
                    auth: {
                        key: process.env.REACT_APP_TRANSLOADIT_KEY || "fakekey",
                    },
                    steps: {
                        ":original": {
                            "robot": "/upload/handle"
                        },
                        "export_uploaded_file": {
                            "use": [
                                ":original"
                            ],
                            "robot": "/s3/store",
                            "credentials": environment,
                            // eslint-disable-next-line
                            "path": path.join("/", userGroup, "${fields.full_path_name}")
                        },
                    },
                },
                fields: {
                    full_path_name: path.join("/", validPath || SingleFilesFolderName, file.name),
                },
              }
            },
        });

        // uppyInstance.use(Tus, { endpoint: "https://tusd.tusdemo.net/files/" }); // upload test only

        uppyInstance.on('files-added', (files: UppyFile[]) => uppyInstance.upload());

        uppyInstance.on('upload', (data: { id: string, fileIDs: string[] }) => {
            setUploading(true);
            data.fileIDs.forEach(fileId => {
                const uppyFile = uppyInstance.getFiles().find(uppyFile => uppyFile.id === fileId);
                if (!!uppyFile) {
                    setFiles(prev => prev.map(extFile => {
                        if (extFile.name === uppyFile.name)
                            extFile.uploadStatus = 'preparing';
                            extFile.progress = undefined;
                            extFile.uploadMessage = undefined;

                        return extFile;
                    }));
                }
            });
        });

        uppyInstance.on('upload-progress', (file: UppyFile|undefined, progress: FileProgress) => {
            const uppyFile = uppyInstance.getFiles().find(uppyFile => uppyFile.id === file?.id);
            if (!!uppyFile && uppyFile.progress!.percentage > 0) {
                setFiles(prev => prev.map(extFile => {
                    if (extFile.name === uppyFile.name)
                        extFile.uploadStatus = 'uploading';
                        extFile.progress = uppyFile.progress!.percentage;
                        extFile.uploadMessage = undefined;

                    return extFile;
                }));
            }
        });

        uppyInstance.on('upload-success', (file: UppyFile|undefined, response: SuccessResponse) => {
            setFiles(prev => prev.map(extFile => {
                if (extFile.name === file?.name) {
                    extFile.uploadStatus = 'success';
                    extFile.progress = undefined;
                    extFile.uploadMessage = 'File uploaded successfully!';
                }

                return extFile;
            }));
        });

        uppyInstance.on('upload-error', (file: UppyFile|undefined, error: Error, response?: ErrorResponse) => {
            setFiles(prev => prev.map(extFile => {
                if (extFile.name === file?.name) {
                    extFile.uploadStatus = 'error';
                    extFile.progress = undefined;
                    extFile.uploadMessage = `File upload failed! ${error.message}`;
                }

                return extFile;
            }));
        });

        uppyInstance.on('complete', (result: UploadResult) => {
            const state = uppyInstance.getFiles().every(uppyFile => uppyFile.progress?.percentage === 100);
            setUploading(!state);
            setHasUploaded(prev => prev || state);
        });

        uppyInstance.on('restriction-failed', (file: UppyFile|undefined, error: Error) => {
            enqueueSnackbar(error.message, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
        });

        uppyInstance.on('error', (error: Error) => console.log(error));

        return uppyInstance;
        // eslint-disable-next-line
    }, [files]);

    const debouncedUpload = useDebouncedCallback(() => {
        try {
            files.forEach(extFile => uppy.addFile({
                data: extFile.file!,
                name: extFile.name,
                type: extFile.type,
                meta: {
                    name: extFile.name,
                    type: extFile.type,
                },
                source: 'local',
                isRemote: false,
            }));
        } catch(error: any) {
            console.log(error);
        }
    }, 1000, { maxWait: 2000 });

    const uploadFiles = useCallback((extFiles: ExtFile[]) => {
        setFiles(extFiles);
        debouncedUpload();
    }, [debouncedUpload]);

    const excludeFile = useCallback((fileId) => {
        setFiles(prev => prev.filter(extFile => extFile.id !== fileId));
        uppy.removeFile(uppy.getFiles().find(uppyFile => uppyFile.name ===
            files.find(extFile => extFile.id === fileId)?.name || '')?.id || '');
    }, [files, uppy]);

    const handleDeleteAll = useCallback(() => {
        setFiles([]);
        uppy.getFiles().map(uppyFile => uppy.removeFile(uppyFile.id));
    }, [uppy]);

    const resetUploader = useCallback((onReset?: () => void) => {
        if (!uploading) {
            handleDeleteAll();
            setHasUploaded(false);
            onReset?.();
        }
    }, [handleDeleteAll, uploading]);

    const uploadToS3 = useCallback(async (dashboard: Dashboard, uploadables: { blob: Blob, filename: string }[]) => {
        return new Promise<string>((resolve) => {
            const promises: Promise<any>[] = [];

            if (Array.isArray(uploadables) && !!uploadables?.length) {
                uploadables.forEach(uploadFile => {
                    if (!!uploadFile.blob && uploadFile.filename !== 'timed-out.file') {
                        const file = new File([uploadFile.blob], uploadFile.filename, { type: uploadFile.blob.type, lastModified: Date.now() });
                        const extFile = { file: file, name: file.name, type: file.type, size: file.size, valid: true, };
                        const fileName = decodeURIComponent(extFile.name!).replace(/\s+/g, '_').replace(/[^a-zA-Z0-9.]+/g, '-').replace('--', '-');

                        promises.push(s3UploadToExternalBucket({
                            path: [dashboard.id || Date.now(), fileName].join('/'),
                            file: extFile.file,
                            bucket: aws_file_uploads_bucket,
                        }));
                    }
                });

                Promise.all(promises)
                    .then(() => resolve(JSON.stringify(promises.length)))
                    .catch(e => resolve(JSON.stringify(e)));
            } else {
                resolve('0');
            }
        });
    }, []);

    const docsendIngestion = useCallback(async (dashboard: Dashboard, externalDeck?: string) => {
        return new Promise<boolean>((resolve) => {
          if (DocsendRegex.some(regex => regex.test(externalDeck || ''))) {
            resolve(createDocSendIngestionFunc({
              url: externalDeck,
              email: getUserEmail(user as User),
              group: userGroup,
              existingDashboardId: dashboard?.id,
            }));
          } else {
            resolve(false);
          }
        });
    }, [user, userGroup]);

    return { files, uploading, hasUploaded, uploadFiles, excludeFile, resetUploader, uploadToS3, docsendIngestion };
};

export default useFileUploader;