import { Box, Card, CardActions, CardContent, Grid, IconButton, styled, SvgIcon, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';
import React, { useState } from "react";
import { CompanyFile } from '../../../../types/files';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classnames from 'classnames';
import { grey } from "@mui/material/colors";
import { getFileIcon } from "../../utils/files";

const ExpandMore = styled(({ expand, ...other }: any) => {
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const useStyles = makeStyles((theme) => ({
    title: {
        margin: 10,
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: "95%",
        padding: 0,
        fontSize: 16,
    },
    fileNumber: {
        margin: 10,
        color: grey[400]
    },
    item: {
        marginTop: 5,
        marginBottom: 5,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.light, 0.3)
        }
    },
    name: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: 0,
        maxWidth: "calc(100% - 40px)"
    },
    fileIcon: {
        marginRight: theme.spacing(1),
        height: 28,
        width: 28,
    },
    card: {
        minHeight: "280px",
        borderRadius: 10,
        padding: '0 20px',
        width: "100%",
        float: 'left',
        zIndex: 0,
        overflow: 'hidden',
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    expanded: {
        overflow: 'visible',
        zIndex: 100,
    },
    cardContent: {
        width: "100%",
        padding: '0 !important',
        overflowY: 'auto',
        '-moz-transition': 'height .1s',
        '-ms-transition': 'height .1s',
        '-o-transition': 'height .1s',
        '-webkit-transition': 'height .1s',
        transition: 'height .1s ease',
        overflow: 'hidden',
        position: 'absolute',
        marginRight: 20,
    },
    cardActions: {
        padding: '0 5px',
        overflowY: 'auto',
        '-moz-transition': 'height .1s',
        '-ms-transition': 'height .1s',
        '-o-transition': 'height .1s',
        '-webkit-transition': 'height .1s',
        transition: 'height .1s ease',
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
    contentExpanded: {
        zIndex: 100,
        overflow: 'hidden'
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: "center"
    },
}));

const FileCategoryCard: React.FC<{ title: string, categoryIndex: number, files: CompanyFile[], onFileSelect: (file: CompanyFile) => void, onFileExpand: (categoryIndex: number, expandedHeight: number | undefined) => void }> =
    ({ title, files, categoryIndex, onFileSelect, onFileExpand }) => {
        const classes = useStyles();
        const [isCardExpanded, setIsCardExpanded] = useState(false);

        const cardExpandHandler = () => {
            if (isCardExpanded) {
                onFileExpand(categoryIndex, undefined);
            } else {
                const expandedFilesLength = !isCardExpanded ? files?.length : 4; // gets expanded now
                const computedHeight = files?.length < 4 ? 44 + files?.length * 50 : 84 + 50 * expandedFilesLength;
                onFileExpand(categoryIndex, computedHeight);
            }
            setIsCardExpanded((prev) => !prev);
        };

        return (
            <Card className={classnames(classes.card, { [classes.expanded]: isCardExpanded })}
                style={{ height: `${files?.length < 4 ? `calc(44px + ${files?.length} * 50px)` : `calc(84px + 50px * ${isCardExpanded ? files?.length : '4'})`}` }}>
                <div className={classes.headerContainer}>
                    <Typography className={classes.title}>{title}</Typography>
                    <Typography className={classes.fileNumber}>{files.length} {files.length > 1 ? "files" : "file"}</Typography>
                </div>
                <CardContent
                    className={classnames(classes.cardContent, { [classes.contentExpanded]: isCardExpanded })}
                    style={{ height: `${files?.length === 1 ? '44px' : `calc(50px * ${isCardExpanded ? files?.length : '4'})`}` }}>
                    <Grid container>
                        {
                            files.map((file, i) => <Grid item xs={11} key={'file-category-card-141-' + i}>
                                <Box
                                    className={classes.item}
                                    pl={3}
                                    pr={1}
                                    py={1}
                                    display="flex"
                                    justifyContent="space-between"
                                    onClick={() => { onFileSelect(file); }}
                                >
                                    <Box display="flex" width="100%" minWidth={0}>
                                        <SvgIcon viewBox="0 0 30 30">
                                            {getFileIcon(file.name, classes.fileIcon)}
                                        </SvgIcon>
                                        <Typography
                                            className={classes.name}
                                            color='textPrimary'
                                            title={file.name}
                                            variant="body1"
                                        >
                                            {file.name}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            )
                        }
                    </Grid>
                </CardContent>
                {files?.length > 4 && <CardActions className={classes.cardActions}>
                    <ExpandMore
                        expand={isCardExpanded}
                        onClick={cardExpandHandler}
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>}
            </Card>
        );
    };

export default FileCategoryCard;