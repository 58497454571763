import React, { createContext, useContext, useEffect, useState, useMemo } from "react";
import { getFiles } from "../graphql/queries";
import { getFoldersFromFiles } from "../helpers/fileStructure";
import usePaginatedQuery from "../hooks/usePaginatedQuery";
import { CompanyFile, S3File } from "../types/files";
import { AuthContext } from "./AuthContext";

interface Context {
  fileStructure: CompanyFile[]
  initialLoading: boolean
  reloadFileStructure: () => void
  setFileStructure: (companiesFiles: CompanyFile[] | ((v: CompanyFile[]) => CompanyFile[])) => void
}

export const FileStructureContext = createContext<Context>({
  fileStructure: [],
  initialLoading: false,
  reloadFileStructure: () => { },
  setFileStructure: (companiesFiles: CompanyFile[] | ((v: CompanyFile[]) => CompanyFile[])) => { }
});

interface Props {
  children: React.ReactNode;
}

const companyQuery = { statement: getFiles, name: "getFiles" }

export const Provider = ({ children }: Props) => {
  const [fileStructure, setFileStructure] = useState<CompanyFile[]>([]);
  const { userGroup } = useContext(AuthContext);
  //this is needed to prevent params from being re-calculated 
  //every time which causes an infinite loop in usePaginatedQuery
  const params = useMemo(() => {
    return {
      group: userGroup
    }
  }, [userGroup])
  const { data, refetch, initialLoading } = usePaginatedQuery<S3File>(
    companyQuery,
    params
  );

  useEffect(() => {
    if (data) {
      setFileStructure(getFoldersFromFiles(data.filter(v => v.status === "processed_by_backend")));
    }
  }, [data]);


  return (
    <FileStructureContext.Provider
      value={{ fileStructure, initialLoading, reloadFileStructure: refetch, setFileStructure }}
    >
      {children}
    </FileStructureContext.Provider>
  );
};

export default Provider;
