import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack } from "@mui/material";
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { AnswerQuestionResponse, AnswerQuestionResponseType, NoteMetric } from '../../../types/search';
import MetricComponentAnswer from './MetricComponentAnswer';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.5s ease',
    },
}));

export const RevenueSubcategories = ['MRR', 'ARR', 'Gross Revenue', 'Net Revenue'];
export const DollarMetrics = ['Fundraising', 'Valuation', 'Revenue'];
export const PercentMetrics = ['Retention', 'Margin'];
export const Units = new Map([['#', 'Number'], ['$', 'Dollar'], ['%', 'Percent']]);
export const DateFmt = 'MMM YYYY';

const MetricsAnswer: React.FC<{
    answeredQuestion: AnsweredQuestion,
    readOnly?: boolean,
    onUpdate?: (answer: AnswerQuestionResponse) => void,
}> = ({ answeredQuestion, readOnly, onUpdate }) => {
    const classes = useStyles();
    const [metricsHistory, setMetricsHistory] = useState<NoteMetric[]>([]);
    const [metricsHistoryList, setMetricsHistoryList] = useState<{
        metric: string,
        history: {
            date?: string|null,
            units?: string|null,
            value?: number|null,
            visible?: boolean|null,
        }[],
    }[]>([]);

    const metricsCategory = useMemo(() => {
        const filteredMetrics = metricsHistoryList.filter(({history, metric}) => ['Valuation', 'Revenue'].includes(metric) || !!history?.[0].value);
        const metrics = filteredMetrics.map(metrics => metrics.metric);
        const sanitizedMetrics = metrics.map(metric => metric.trim().toLowerCase());
        const newCategories: string[] = [];

        newCategories.push(...DollarMetrics);
        newCategories.push(...PercentMetrics.filter(metric => sanitizedMetrics.includes(metric.trim().toLowerCase())));
        newCategories.push(...metrics.filter(metric => ![...DollarMetrics, ...PercentMetrics].includes(metric)));

        return newCategories;
    }, [metricsHistoryList]);

    const toNumber = (value: string) => {
        if (!value)
            return null;

        let cleanInput = value!.replace(/[^0-9bmkt.$%]/gi, '').toLowerCase();
        let multiplier = 1;

        if (cleanInput.includes('t'))
            multiplier = Math.pow(10, 12);
        else if (cleanInput.includes('b'))
            multiplier = Math.pow(10, 9);
        else if (cleanInput.includes('m'))
            multiplier = Math.pow(10, 6);
        else if (cleanInput.includes('k'))
            multiplier = 1000;

        return (multiplier * parseFloat(cleanInput.replace(/[^0-9.]/g, '')));
    }

    const handleUpdateMetrics = useCallback((category: string, newValue: string, newDate: string, units: string) => {
        const date = moment(newDate, DateFmt).toISOString();
        const value = toNumber(newValue);
        let updatedMetrics: any = {};
        let updatedHistory: any[] = [];
        let metricsSnapshot: any = {};

        metricsHistoryList.forEach(({metric, history}) => {
            if (metric === category) {
                updatedMetrics[metric] = { date, units, value };
                metricsSnapshot[metric] = {...history[0]};
            } else {
                updatedMetrics[metric] = {...history[0]};
                metricsSnapshot[metric] = {date: null, value: null, units: null};
            }
        });

        if (!updatedMetrics[category]) {
            updatedMetrics[category] = { date, units, value };
            metricsSnapshot[category] = {date: null, value: null, units: null};
        }

        [metricsSnapshot, ...metricsHistory].map((history: any) => history?.answer ?? history)
            ?.forEach((metrics: NoteMetric) => {
                if (!Object.entries(metrics).find(([metric, {date, units, value}]) => (metric === category &&
                    !!date && !!units && !!value && (moment(date).format(DateFmt) === moment(newDate).format(DateFmt)))))
                        updatedHistory.push({answer: metrics, type: AnswerQuestionResponseType.METRICS} as AnswerQuestionResponse);
            });

        onUpdate?.({
            answer: updatedMetrics,
            history: updatedHistory,
            type: AnswerQuestionResponseType.METRICS,
        } as AnswerQuestionResponse);
    }, [metricsHistory, metricsHistoryList, onUpdate]);

    const handleDeleteMetric = useCallback((category: string) => {
        let updatedMetrics: any = {};
        let updatedHistory: any[] = [];

        metricsHistoryList.forEach(({metric, history}) => {
            if (metric !== category)
                updatedMetrics[metric] = {...history[0]};
        });

        metricsHistory.map((history: any) => history?.answer ?? history)
            ?.forEach((metrics: NoteMetric) => {
                let newMetrics: any = {};

                Object.entries(metrics).forEach(([metric, values]) => {
                    if (metric !== category)
                        newMetrics[metric] = values;
                });
                updatedHistory.push({answer: newMetrics, type: AnswerQuestionResponseType.METRICS} as AnswerQuestionResponse);
            });

        onUpdate?.({
            answer: updatedMetrics,
            history: updatedHistory,
            type: AnswerQuestionResponseType.METRICS,
        } as AnswerQuestionResponse);
    // eslint-disable-next-line
    }, [metricsHistory, metricsHistoryList]);

    useEffect(() => {
        const historyRecord = answeredQuestion.history?.map((historyAnswer: any) => historyAnswer?.answer ?? historyAnswer) ?? [];
        const historyList = Object.entries(answeredQuestion.answer ?? {})
            .map(([metric, value]) => {
                const filteredHistory = historyRecord
                    .map(history => history[metric as keyof NoteMetric])
                    .filter(values => !!values && Object.values(values).every(value => !!value));

                return ({metric, history: [{...value as any}, ...filteredHistory]});
            });

        const mainRevenue = historyList.find(metrics => metrics.metric === 'Revenue')?.history;
        const subCategories = historyList.filter(metrics => RevenueSubcategories.map(cat => cat.toLowerCase()).includes(metrics.metric.trim().toLowerCase()))?.map(metrics => metrics.history);

        if (!!subCategories.length) {
            const deletables: number[] = [];

            mainRevenue?.forEach((main, i) => {
                subCategories.forEach(cat => {
                    cat.forEach(history => {
                        if (!!history?.value && !!main?.value
                            && (history?.value === main?.value)
                            && (moment(history?.date).format(DateFmt) === moment(main?.date).format(DateFmt)))
                            deletables.push(i);
                    });
                });
            });
            deletables?.reverse().forEach(i => mainRevenue?.splice(i, 1));
        }

        setMetricsHistory(historyRecord);
        setMetricsHistoryList(historyList);
    }, [answeredQuestion.history, answeredQuestion.answer]);

    return (<>
        <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="center" width="100%">
            {metricsCategory.map((metric, i)=> {
                const metrics = metricsHistoryList.find(metrics => metrics.metric === metric);
                const history = metrics?.history ?? [];

                if (metric === 'Revenue' && !history?.some(item => !!item.date && !!item.value))
                    return (<></>);

                return (
                    <Box className={classes.masonryElement} key={'metrics-answer-128-' + i}>
                        <MetricComponentAnswer
                            metric={metric}
                            history={history}
                            onUpdateMetric={handleUpdateMetrics}
                            onDeleteMetric={handleDeleteMetric}
                            readOnly={readOnly} />
                    </Box>
                );
            })}
        </Stack>
    </>);
}

export default MetricsAnswer;
