import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, CircularProgress, Typography } from "@mui/material";
import { SearchEntity } from "../../types/search";
import { SearchContext } from "../../contexts/SearchContext";
import HomePageSearch from "../organisms/home-search/HomePageSearch";
import HomeSearchResultsContainer from "../organisms/home-search/HomeSearchResultsContainer";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        margin: '15px 30px',
        height: 'calc(100% - 30px)',
    },
    searchContainer: {
        minHeight: 190
    },
    divider: {
        marginTop: 15,
        marginBottom: 15,
    },
    elementContainer: {
        height: `calc(100% - 210px)`,
        margin: "0px 20px 20px 20px",
    },
    progressContainer: {
        display: "flex",
        justifyContent: "center",
        height: '100%',
        alignItems: 'center',
    },
}));

const Search: React.FC = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState<boolean>(false);

    const {
        contextSearchEntity,
    } = useContext(SearchContext);

    return (
        <Box className={classes.rootContainer}>
            <Box className={classes.searchContainer}>
                <HomePageSearch setLoading={setLoading} />
            </Box>
            <Box className={classes.elementContainer}>
                {
                    loading ?
                        <Box className={classes.progressContainer}>
                            <CircularProgress />
                        </Box>
                        :
                        <Box sx={{ height: '100%' }}>
                            {(contextSearchEntity === SearchEntity.Documents || contextSearchEntity === SearchEntity.All)
                                && <HomeSearchResultsContainer
                                    loading={loading}
                                    heightToSubtract={234}
                                />}
                            {contextSearchEntity === SearchEntity.Dashboards &&
                                <Typography className={classes.progressContainer}>
                                    No results found
                                </Typography>}
                        </Box>
                }
            </Box>
        </Box>
    )
};

export default Search;