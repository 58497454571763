import React, { useContext } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Logo6 from "../../../../public/images/Notissia logos/Notissia/Ativo 2.svg";
import { SignUpLink } from "../../molecules/dashboard-header/DashboardTitle";
import { AuthContext } from "../../../contexts/AuthContext";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        height: '100%',
        width: '100%',
        textAlign: "center",
        background: 'white',
    },
    logoContainer: {
        position: 'fixed',
        top: '30%',
    },
    bottomTextContainer: {
        position: 'fixed',
        bottom: 20,
        display: "flex",
        justifyContent: "center",
    },
    signUpButton: {
        width: '100%',
        height: 40,
        borderRadius: 20,
        backgroundImage: 'linear-gradient(to right, #3ABDC6 0%, #6FEEF6  15%, #3ABDC6  50%, #6FEEF6  80%, #3ABDC6 100%)',
        backgroundSize: '105% auto',
        boxShadow: '2px 2px 4px #9CCED3, -1px -1px 3px #fff',
        color: 'white',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const DashboardNotFound: React.FC = () => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);

    return (<>
        <Stack className={classes.container} alignItems="center" justifyContent="center">
            <Box className={classes.logoContainer}>
                <img src={Logo6} alt="Notissia logo" loading="eager" width="100px" />
            </Box>
            <Typography variant="h5" color={theme.palette.primary.main} fontWeight="bold">
                {'This dashboard is no longer available.'}
            </Typography>
            <Box className={classes.bottomTextContainer} marginTop="40px">
                <Stack spacing={3} alignItems="center" justifyContent="center" width={290} mb={2}>
                    <Typography fontFamily="Inter" fontSize="1rem" color={theme.palette.primary.main}>
                        {'Notissia organizes your deal flow data and curates it for your network.'}
                    </Typography>
                    {!userGroup && (
                        <Button variant="contained" className={classes.signUpButton}
                            onClick={() => window.open(SignUpLink, '_blank', 'noopener, noreferrer')}>
                            {'Get Notissia'}
                        </Button>
                    )}
                </Stack>
            </Box>
        </Stack>
    </>);

};

export default DashboardNotFound;