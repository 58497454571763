import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, IconButton, Menu, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import classnames from 'classnames';
import AnswerEditor from './AnswerEditor';
import { DateFmt, DollarMetrics, PercentMetrics, RevenueSubcategories, Units } from './MetricsAnswer';
import theme from '../../../theme';
import ConfirmDialog from '../../modals/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        color: theme.colors.neutral['600'],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    historyDateSelectorForm: {
        minWidth: 'fit-content',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            height: 30,
            width: 'auto',
            "& > div": {
                fontSize: '0.9rem',
                color: '#7bd4d4',
                padding: 0,
                paddingRight: 32,
            },
            "& > svg": {
                fill: '#7bd4d4',
            },
            "& fieldset": {
              border: 0,
            },
        }
    },
    unitSelectorForm: {
        minWidth: 'fit-content',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            height: 30,
            width: 'auto',
            "& > div": {
                fontSize: '0.9rem',
                color: theme.colors.neutral['600'],
                padding: 0,
                paddingRight: 32,
            },
            "& > svg": {
                fill: theme.colors.neutral['600'],
            },
            "& fieldset": {
              border: 0,
            },
        }
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        border: `1px solid transparent`,
        borderRadius: 12,
    },
    answerHover: {
        "&:hover": {
            border: `1px solid ${theme.colors.neutral['400']}`,
            borderRadius: 12,
        }
    },
    answerBlock: {
        width: '100%',
        height: 'auto',
        padding: 4,
        color: '#666666',
    },
    inputField: {
        "& .MuiOutlinedInput-root": {
            width: '100%',
            height: '40px',
            borderRadius: 8,
            background: '#fff',
            padding: 0,
            "& fieldset": {
              borderColor: 'lightgray !important',
            },
            "&:hover fieldset": {
              borderColor: 'darkgray !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: 'gray !important',
            },
        },
    },
    recentDateSelectorForm: {
        minWidth: 'fit-content',
        padding: 0,
        border: 0,
        "& > .MuiInputBase-root":{
            height: 40,
            width: 140,
        },
        "& fieldset": {
          border: 0,
        },
    },
    commonEditButton: {
        borderRadius: 12,
        textTransform: 'none',
        fontSize: '0.9rem',
        height: 30,
    },
    cancelEditButton: {
        borderRadius: 12,
        textTransform: 'none',
        fontSize: '0.9rem',
        marginRight: 10,
        height: 30,
    },
    editControl: {
        border: 'none',
        borderRadius: '50%',
    },
    editStateIcon: {
        height: '20px',
        width: '20px',
        fill: 'darkgray',
    },
    editicon: {
        width: 40,
        height: 40,
        padding: 'unset',
    },
    moreIcon: {
        padding: 'unset',
        color: theme.colors.neutral['500'],
        "& > svg": {
            width: 20,
            height: 20,
        },
    },
}));

const MetricComponentAnswer: React.FC<{
    metric: string,
    history: {
        value?: number|null,
        units?: string|null,
        date?: string|null,
    }[],
    readOnly?: boolean,
    onUpdateMetric?: (category: string, value: string, date: string, units: string) => void,
    onDeleteMetric?: (category: string, softDelete?: boolean) => void,
}> = ({ metric, history, readOnly, onUpdateMetric, onDeleteMetric }) => {
    const classes = useStyles();

    const [edit, setEdit] = useState<boolean>(false);
    const [historyOptions, setHistoryOptions] = useState<{date: string, units: string, value: number}[]>([]);
    const [historyIndex, setHistoryIndex] = useState<string>('0');
    const [recentDateOptions, setRecentDateOptions] = useState<string[]>([]);
    const [recentDate, setRecentDate] = useState<string>('');
    const [selectedUnit, setSelectedUnit] = useState<string>('#');
    const [recentValue, setRecentValue] = useState<string>("");
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

    const handleSave = useCallback((recentValue: string) => {
        onUpdateMetric?.(metric, recentValue, recentDate || recentDateOptions[recentDateOptions.length - 1], selectedUnit);
        setHistoryIndex('0');
        setEdit(false);
    // eslint-disable-next-line
    }, [metric, recentDate, recentDateOptions, selectedUnit]);

    useEffect(() => {
        if (!recentDateOptions.length)
            setRecentDateOptions(Array(12).fill(moment()).map((time: moment.Moment, i) => time.subtract(Number(!!i), 'months').format(DateFmt)).reverse());

        setHistoryOptions(history.filter(({date, value}) => !!date && typeof value === 'number')
            .map(({date, units, value}) => ({
                date: moment(date).format(DateFmt), units, value
            })) as {date: string, units: string, value: number}[]);
    }, [history, recentDateOptions]);

    useEffect(() => {
        const value = historyOptions?.[Number(historyIndex)]?.value ?? null;
        const unit = historyOptions?.[Number(historyIndex)]?.units;

        if (value === null) {
            setSelectedUnit('#');
            setRecentValue(`N/A`);
            return;
        }

        let cleanInput = String(value!).replace(/[^0-9bmkt.$%]/gi, '').toLowerCase();
        let multiplier = 1;

        if (cleanInput.includes('t'))
            multiplier = Math.pow(10, 12);
        else if (cleanInput.includes('b'))
            multiplier = Math.pow(10, 9);
        else if (cleanInput.includes('m'))
            multiplier = Math.pow(10, 6);
        else if (cleanInput.includes('k'))
            multiplier = 1000;

        const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.]/g, ''));

        if (!unit && DollarMetrics.includes(metric)) {
            setSelectedUnit('$');
            setRecentValue(`$${numericValue.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        } else if (!unit && PercentMetrics.includes(metric)) {
            setSelectedUnit('%');
            setRecentValue(`${numericValue.toFixed(1)}%`);
        } else {
            setSelectedUnit(unit);
            if (unit === '$')
                setRecentValue(`$${numericValue.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            else if (unit === '%')
                setRecentValue(`${numericValue.toFixed(1)}%`);
            else
                setRecentValue(`${numericValue}`);
        }
    }, [historyIndex, historyOptions, metric]);

    return (<>
        <Stack direction="column" width="100%">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {RevenueSubcategories.map(cat => cat.toLowerCase()).includes(metric.trim().toLowerCase()) ? (
                    <Typography className={classes.title}>
                        {'Revenue '}
                        <Typography component="span" className={classes.subtitle} display="inline"> ({metric})</Typography>
                    </Typography>
                ): (
                    <Typography className={classes.title}>
                        {metric}
                    </Typography>
                )}
                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    <FormControl className={classes.historyDateSelectorForm} variant="outlined">
                        {edit ? (<>
                            <Select value={recentDate}
                                renderValue={(selected) => !selected ? 'Select date...' : `${selected}`}
                                onChange={(e: SelectChangeEvent) => setRecentDate(e.target.value)}
                                displayEmpty>
                                {recentDateOptions.map((date, i) => (
                                    <MenuItem value={date} key={"metric-component-answer-239-" + i}>{date}</MenuItem>
                                ))}
                            </Select>
                        </>) : (!!historyOptions.length) && (
                            <Select value={historyIndex}
                                renderValue={(selected) => `${historyOptions?.[Number(selected)]?.date}`}
                                onChange={(e) => setHistoryIndex(e.target.value)}>
                                {historyOptions.map(({date}, i) => (
                                    <MenuItem value={`${i}`}
                                        sx={{
                                            fontSize: '0.85rem',
                                            fontStyle: 'normal',
                                            color: (historyIndex === `${i}`) ? '#7bd4d4' : 'gray',
                                        }}
                                        key={'metric-component-answer-230-' + i}>
                                        {date}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </FormControl>
                    <IconButton className={classes.moreIcon}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        disableRipple>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        onClose={() => setAnchorEl(null)}>
                        <MenuItem onClick={() => {setConfirmDeleteOpen(true); setAnchorEl(null);}}
                            sx={{ color: theme.colors.error['500']}}>
                            {'Delete'}
                        </MenuItem>
                    </Menu>
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between"
                className={classnames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                onMouseLeave={() => setHoverAnswer(false)}>
                <Box className={classes.answerBlock} onMouseEnter={() => setHoverAnswer(!edit && !readOnly)}>
                    {!edit ? (
                        <Typography fontSize="1.3rem" color="#666666">{recentValue}</Typography>
                    ) : (<>
                        <AnswerEditor
                            currentAnswer=""
                            placeHolder="Enter metric here..."
                            externalControl={edit && (<>
                                <FormControl className={classes.unitSelectorForm} variant="outlined">
                                    <Select value={selectedUnit}
                                        renderValue={(selected) => `${Units.get(selected)}`}
                                        onChange={(e: SelectChangeEvent) => setSelectedUnit(e.target.value)}
                                        displayEmpty>
                                        {Array.from(Units).map(([symbol, label], i) => (
                                            <MenuItem value={symbol} key={"metric-component-answer-280-" + i}>{label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>)}
                            handleCancel={() => setEdit(false)}
                            handleSave={handleSave}
                            rows={2} />
                    </>)}
                </Box>
                {hoverAnswer && (
                    <Stack direction="column" alignItems="center" justifyContent="flex-end" width="40px">
                        <IconButton size="small"
                            className={classes.editicon}
                            onClick={(e) => {
                                e.stopPropagation();
                                setEdit(true);
                                setSelectedUnit(DollarMetrics.includes(metric) ? '$'
                                    : PercentMetrics.includes(metric) ? '%' : '#');
                                setHoverAnswer(false);
                            }}
                        > <EditOutlinedIcon fontSize="small" /> </IconButton>
                    </Stack>
                )}
            </Stack>
        </Stack>
        {confirmDeleteOpen && (
            <ConfirmDialog
                title="Delete confirmation"
                content="Are you sure you want to delete this metric?"
                open={confirmDeleteOpen}
                confirmCallback={() => {onDeleteMetric?.(metric); setConfirmDeleteOpen(false);}}
                cancelCallback={() => setConfirmDeleteOpen(false)}
            />
        )}
    </>);
}

export default MetricComponentAnswer;
