import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Fab, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {ReactComponent as UnassignedContentIcon} from "../../assets/icons/unassigned-content.svg"
import theme from "../../theme";
import UnassignedContentsModal from "../modals/dashboard-unassigned-contents/UnassignedContentsModal";
import { DocSendIngestion, UnassignedContent, UnassignedContentType } from "../../types/files";
import {AuthContext} from "../../contexts/AuthContext";
import { assignContentToDashboardFunc, createDocSendIngestionFunc, deleteUnassignedContentFunc, getUnassignedContentItemsFunc } from "../../lib/helper";
import { API, graphqlOperation } from "aws-amplify";
import { onDocSendIngestionCreation, onUnassignedContent } from "../../graphql/subscriptions";
import { DashboardsContext } from "../../contexts/DashboardsContext";
import { getUserEmail } from "../../helpers/authUser";
import { User } from "../../types/auth";

const useStyles = makeStyles((theme) => ({
    badge: {
        '& .MuiBadge-badge': {
            position: 'absolute',
            top: 8,
            right: 8,
            width: 24,
            height: 24,
            padding: '12px 6px',
            borderRadius: '50%',
            background: theme.colors.orange['400'],
            color: 'white',
            fontSize: '0.85rem',
            fontWeight: 'bold',
            zIndex: 2000,
        },
    },
    incomingContentsButton: {
        textTransform: 'none',
        boxShadow: theme.shadows[5],
        height: 60,
        width: 60,
        "&:hover": {
            background: theme.colors.primary['300'],
        }
    },
    icon: {
        height: 40,
        width: 40,
        fill: theme.palette.common.white,
    },
}));

const UnassignedContentsBadge: React.FC<{}> = () => {
    const classes = useStyles();
    const {user, userGroup} = useContext(AuthContext);
    const {dashboards} = useContext(DashboardsContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [incomingContents, setIncomingContents] = useState<UnassignedContent[]>([]);
    const [outgoingContents, setOutgoingContents] = useState<UnassignedContent[]>([]);
    const unassignedContentSubscriptionRef = useRef<any>(null);
    const onDocSendIngestionCreationRef = useRef<any>(null);
    const menuOpen = Boolean(anchorEl);

    const filteredContents = useMemo(() => incomingContents.filter(content => {
        if (content?.type === UnassignedContentType.DOCSEND) {
            const parsed = JSON.parse(content?.body || '{}') as DocSendIngestion;
            const lookUpDashboard = dashboards.find(dashboard => parsed?.existingDashboardId ?
                dashboard.id === parsed?.existingDashboardId : dashboard.title === parsed?.newDashboardTitle);

            return !!lookUpDashboard;
        }

        return true;
    }), [dashboards, incomingContents]);

    const updateSubscribedContents = useCallback(async (
        incomingContents: UnassignedContent[],
        outgoingContents: UnassignedContent[],
        updatedContent?: UnassignedContent|null
    ) => {
        if (!updatedContent?.id)
            return;

        const lookUpIncomingContent = incomingContents.find(content => content.id === updatedContent.id);
        const lookUpOutgoingContent = outgoingContents.find(content => content.id === updatedContent.id);

        if (!lookUpOutgoingContent && !lookUpIncomingContent)
            setIncomingContents(prev => [updatedContent, ...prev]);
    }, []);

    const removedSubscribedContents = useCallback(async (updatedContent?: UnassignedContent|null) => {
        if (!updatedContent?.id)
            return;

        setIncomingContents(prev => [...prev].filter(content => content.id !== updatedContent!.id));
    }, []);

    const subscribeUnassignedContents = useCallback(async (
        incomingContents: UnassignedContent[],
        outgoingContents: UnassignedContent[]
    ) => {
        if (unassignedContentSubscriptionRef.current)
            unassignedContentSubscriptionRef.current.unsubscribe();

        if (userGroup) {
            unassignedContentSubscriptionRef.current = await (API.graphql(
                graphqlOperation(onUnassignedContent, { group: userGroup })) as any)
                .subscribe({ next: ({ value }: any) => updateSubscribedContents(
                    incomingContents, outgoingContents, value?.data?.onUnassignedContent
                )});
        }
    }, [userGroup, updateSubscribedContents]);

    const subscribeDocsendIngestionCreation = useCallback(async () => {
        if (onDocSendIngestionCreationRef.current)
            onDocSendIngestionCreationRef.current.unsubscribe();

        if (userGroup) {
            onDocSendIngestionCreationRef.current = await (API.graphql(
                graphqlOperation(onDocSendIngestionCreation, { group: userGroup })) as any)
                .subscribe({ next: ({ value }: any) => removedSubscribedContents(
                    value?.data?.onDocSendIngestionCreation
                )});
        }
    }, [userGroup, removedSubscribedContents]);

    const moveContent = useCallback((contentId: string) => {
        const lookUpContent = incomingContents.find(content => content.id === contentId);

        if (!!lookUpContent) {
            setOutgoingContents(prev => [lookUpContent, ...prev]);
            setIncomingContents(prev => [...prev]
                .filter(content => content.id !== lookUpContent.id));
        }
    }, [incomingContents]);

    const handleAssignContent = useCallback(async (dashboardId: string, unassignedContentId: string) => {
        moveContent(unassignedContentId);
        await assignContentToDashboardFunc({unassignedContentId, dashboardId});
    }, [moveContent]);

    const handleDocsendIngestion = useCallback(async (unassignedContentId: string, docsend: DocSendIngestion, password: string) => {
        moveContent(unassignedContentId);
        return await createDocSendIngestionFunc({ ...docsend,
            email: getUserEmail(user as User),
            group: userGroup,
            password,
        });
    }, [moveContent, user, userGroup]);

    const handleRemoveContent = useCallback(async (unassignedContentId: string) => {
        moveContent(unassignedContentId);
        await deleteUnassignedContentFunc({id: unassignedContentId, group: userGroup});
    }, [moveContent, userGroup]);

    useEffect(() => {
        if (userGroup) {
            getUnassignedContentItemsFunc({group: userGroup})
                .then((unassignedContents: UnassignedContent[]|undefined) => {
                    setIncomingContents(unassignedContents ?? []);
                });
        }
    }, [userGroup]);

    useEffect(() => {
        subscribeUnassignedContents(incomingContents, outgoingContents);
        subscribeDocsendIngestionCreation();

        return () => {
            unassignedContentSubscriptionRef.current?.unsubscribe();
            onDocSendIngestionCreationRef.current?.unsubscribe();
        }
        // eslint-disable-next-line
    }, [incomingContents, subscribeUnassignedContents]);

    return (<>
        {(menuOpen || !!filteredContents.length) && (
            <Badge className={classes.badge} badgeContent={filteredContents.length}>
                <Fab className={classes.incomingContentsButton}
                    sx={{ background: theme.colors.primary[menuOpen ? '300' : '400'] }}
                    onClick={event => setAnchorEl(event.currentTarget)}>
                    <UnassignedContentIcon className={classes.icon} />
                </Fab>
            </Badge>
        )}
        <UnassignedContentsModal
            unassignedContents={filteredContents}
            anchorEl={anchorEl}
            onAnchorEl={setAnchorEl}
            onAssignContent={handleAssignContent}
            onDocsendIngestion={handleDocsendIngestion}
            onRemoveContent={handleRemoveContent} />
    </>);
};

export default UnassignedContentsBadge;
