import React, { useContext, useEffect, useMemo } from "react";
import CustomQueryContainer from "../dashboard-query-container/CustomQueryContainer";
import FundingListAnswer, { FundingAnsweredQuestion } from "../dashboard-query-answer/FundingListAnswer";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import { DashboardContext } from "../../../contexts/DashboardContext";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";

const FundingQuery: React.FC<{}> = () => {
    const { isPublicView } = useContext(DashboardContext);
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { queryAnswer, removeQuery } = useDashboardQuery('Funding');

    const isVisibleByDefault = useMemo(() =>
        defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Funding')?.visibleByDefault ?? true
    , [defaultQueryTemplateValues]);

    const isPresent = useMemo(() => 'answer' in queryAnswer && !!queryAnswer.answer, [queryAnswer]);
    const isDeleted = useMemo(() => 'deletedAt' in queryAnswer && !!queryAnswer.deletedAt, [queryAnswer]);
    const isHidden = useMemo(() => isDeleted || (!isVisibleByDefault && !isPresent), [isDeleted, isPresent, isVisibleByDefault]);

    useEffect(() => {
        if (!isDeleted && (!isVisibleByDefault && isPresent) && !queryAnswer.manualOverride)
            removeQuery(queryAnswer);
    // eslint-disable-next-line
    }, [isDeleted, isPresent, isVisibleByDefault, queryAnswer]);

    if (isHidden)
        return (<></>);

    return (<>
        <CustomQueryContainer
            queryAnswer={queryAnswer}
            displayMenu={!isPublicView}
            expandableContent
            hideAiGenerated>
            <FundingListAnswer answeredQuestion={queryAnswer.answeredQuestion as FundingAnsweredQuestion} showExplanation />
        </CustomQueryContainer>
    </>);
}

export default FundingQuery;
