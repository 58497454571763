import { CompanyFile } from './files';

export enum integration {
    BOX       = 'BOX',
    DROPBOX   = 'DROPBOX',
    GOOGLE    = 'GOOGLE',
    MICROSOFT = 'MICROSOFT',
}

export const integrationFriendlyNames = {
    [integration.BOX]: 'Box',
    [integration.DROPBOX]: 'Dropbox',
    [integration.GOOGLE]: 'Google Drive',
    [integration.MICROSOFT]: 'OneDrive',
}

export enum integrationStatus {
    CONNECTED = 'CONNECTED',
    DISCONNECTED = 'DISCONNECTED',
    ERROR = 'ERROR'
}

export interface OAuth2CredsRequest {
    group: string;
    code?: string;
    integration: integration;
    redirectUri?: string;
}

export interface ListIntegrationSubfoldersRequest {
    group: string;
    integration: integration;
    path?: string;
    parentId?: string;
}

export interface SiteDrive {
    siteId: string;
    siteName: string;
    driveId: string;
    driveName: string;
}

export interface Drive {
    driveId: string;
    driveName: string;
}

export interface GroupDrive {
    groupId: string;
    groupName: string;
    driveId: string;
    driveName: string;
}

export interface IntegrationSelectionOptions {
    drives?: Drive[];
    groupDrives?: GroupDrive[];
    siteDrives?: SiteDrive[];
    namespaces?: DropboxNamespace[];
}

export interface IntegrationConnectionResponse {
    drives?: [Drive?];
    groupDrives?: [GroupDrive?];
    siteDrives?: [SiteDrive?];
    namespaces?: [DropboxNamespace?];
}

export interface DropboxNamespace {
    children: CompanyFile[];
}

export interface DropboxFolderDrive {
    path: string;
    rootFolderName?: string;
    id?: string;
}

export interface BoxFolder {
    path: string;
    id: string;
}

export interface IntegrationState {
    group: string;
    integration: integration;
    status: integrationStatus;
    externalConfig: string;
    lastSyncAt?: Date;
    lastSuccessfulSyncAt?: Date;
    createdAt?: Date;
    updatedAt?: Date;
}