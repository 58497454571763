import React from "react";
import { Box, Divider, Typography, Stack } from "@mui/material";
import { ReactComponent as FoundedIcon } from "../../../assets/icons/founded.svg";

const FoundedElement: React.FC<{items: any[]}> = ({items}) => {
    if (!!items.length) {
        return (<>
            <Stack direction="column" spacing={1} alignItems="center">
                <Box display="flex">
                    <FoundedIcon width="36px" height="36px" fill="#7bd4d4" />
                </Box>
                <Stack alignItems="center">
                    <Typography sx={{
                        color: '#9e9e9e',
                        fontWeight: 'bold',
                        fontSize: '0.9rem',
                    }}>Founded</Typography>
                    <Typography sx={{
                        color: '#666666',
                        fontSize: '0.95rem',
                    }}>{items[0].year}</Typography>
                </Stack>
                <Divider orientation="vertical" sx={{ height: '80px', borderColor: 'lightgrey' }} />
            </Stack>
        </>);
    }

    return (<></>);
}

export default FoundedElement;