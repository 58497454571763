import { SingleFilesFolderName } from '../shared/files';
import { CompanyFile } from '../types/files';

const sortFilesByName = (a: CompanyFile, b: CompanyFile) => {
  const sameType = a.isDirectory === b.isDirectory;

  if (sameType) {
    if (a.isDirectory && a.name === SingleFilesFolderName) {
      return -1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  } else if (a.isDirectory) {
    return -1;
  } else {
    return 1;
  }
};

export default sortFilesByName;
