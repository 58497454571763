import { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import DashboardCategoriesNavigation from './DashboardCategoriesNavigation';
import { CommentOriginType, ExtendedComment } from '../../../types/comments';
import { CompanyFile } from '../../../types/files';
import { scrollbarStyle } from '../../../shared/dashboard';
import FileCategories from '../../folders/components/categories/FileCategories';
import FilePreview from '../../folders/components/FilePreview';
// import { AuthContext } from '../../../contexts/AuthContext';
// import { setDashboardDeckFunc } from '../../../lib/helper';

const useStyles = makeStyles(() => ({
    root: {
        borderRight: `2px solid #F7F5F2`,
        overflow: 'auto',
        marginLeft: 16,
        position: 'relative',
        '-moz-transition': 'width .3s',
        '-ms-transition': 'width .3s',
        '-o-transition': 'width .3s',
        '-webkit-transition': 'width .3s',
        transition: 'width .3s ease-out',
    },
    container: {
        width: "40%"
    },
    header: {
        marginLeft: 66
    },
    folderListProgress: {
        display: "flex",
        justifyContent: "center"
    },
    noFileSelected: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        width: "100%",
        height: "100%",
        overflow: "auto",
        '-moz-transition': 'width .3s',
        '-ms-transition': 'width .3s',
        '-o-transition': 'width .3s',
        '-webkit-transition': 'width .3s',
        transition: 'width .3s ease-out',
        ...scrollbarStyle
    },
}));

const DashboardCategoriesLayout: React.FC<{
    dashboardId: string
    selectedComment?: ExtendedComment,
    selectedFile?: CompanyFile,
    selectedFolder?: CompanyFile,
    selectedPage?: number,
    isPublic?: boolean,
    onSelectFile: (f: CompanyFile | null) => void
    onSelectFolder: () => void,
    loading: boolean
}> = ({ dashboardId, selectedComment, selectedFile, selectedFolder, selectedPage, isPublic, onSelectFile, loading }) => {
    const classes = useStyles();
    const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
    const [selectedCommentId, setSelectedCommentId] = useState<string | null>();
    const [selectedCommentParentId] = useState<string | null | undefined>();
    // const { userGroup } = useContext(AuthContext);
    const [, setSelectedCategory] = useState<string>();

    useEffect(() => {
        if (selectedComment) {
            setSelectedCommentId(selectedComment.id);
        } else {
            setSelectedCommentId(undefined);
        }
    }, [selectedComment]);

    // const onSetDashboardDeck = async ({ fileKey, page }: { fileKey: string, page?: number }) => {
    //     await setDashboardDeckFunc({ dashboardId, group: userGroup, fileKey, page })
    // };

    const filePreview = (
        <FilePreview
            filePath={selectedFile?.path}
            fileKey={selectedFile?.key || ""}
            page={selectedPage}
            selectedExtendedComment={selectedComment ? { ...selectedComment } : undefined}
            selectedCommentId={selectedCommentId}
            selectedCommentParentId={selectedCommentParentId}
            enableComments={true}
            enableDashboard={false}
            showCategories={false}
            showDiscussion={false}
            commentOriginType={CommentOriginType.Folder}
            onGoToCategories={() => onSelectFile(null)}
            onCommentAdded={(x) => {
                if (selectedFile) {
                    onSelectFile({ ...selectedFile });
                }
            }}
            // onSetDashboardDeck={onSetDashboardDeck}
            showAddCrop={false}
            dashboardId={dashboardId}
        />
    );

    const fileCategories = (
        <FileCategories
            dashboardId={dashboardId}
            onFileSelect={(file: CompanyFile) => {
                onSelectFile(file);
            }}
        />
    );

    const content = (selectedFolder && selectedFile) ? filePreview : fileCategories;

    return (
        <Box
            position="relative"
            width="100%"
            maxWidth="calc(100vw - 114px)"
            height='calc(100vh - 70px)'
            maxHeight='calc(100vh - 70px)'
            display="flex"
            flexDirection="row"
        >
            <Box
                className={classNames(classes.root, "no-scrollbar")}
                height="100%"
                width={menuExpanded ? 400 : 114}
                onMouseEnter={() => setMenuExpanded(true)}
                onMouseLeave={() => setMenuExpanded(false)}
            >
                {!isPublic && (
                    loading ?
                        <div className={classes.folderListProgress}>
                            <CircularProgress />
                        </div>
                        :
                        <DashboardCategoriesNavigation
                            dashboardId={dashboardId}
                            onFileSelected={onSelectFile}
                            selectedFile={selectedFile}
                            isMenuExpanded={menuExpanded}
                            onExpandMenu={setMenuExpanded}
                            setSelectedCategory={setSelectedCategory}
                        />
                )}
            </Box>
            <Box className={classes.content}>
                {content ? content : (
                    <div className={classes.noFileSelected}><Typography>No file selected.</Typography></div>
                )}
            </Box>
        </Box>
    )
}

export default DashboardCategoriesLayout;
