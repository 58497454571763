import { Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/HighlightOff';
import { ExtFile, FileInputButton } from "@files-ui/react";
import { useContext, useEffect } from "react";
import { CSVLink } from "react-csv";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as BulkIcon } from "../../../assets/icons/stack.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { s3UploadToExternalBucket } from "../../../helpers/s3";
import { AuthContext } from "../../../contexts/AuthContext";
import CircularProgressPercent from "../../atoms/CircularProgressPercent";
import awsExports from "../../../aws-exports";
import theme from "../../../theme";
import useS3FileUploader from "../../../hooks/useS3FileUploader";
import { makeId } from "../../../utils/makeId";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: 550,
            height: 'fit-content',
            padding: 16,
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 16,
    },
    bulkIcon: {
        width: 40,
        height: 40,
        strokeWidth: 12,
        marginRight: 12,
    },
    subtitle: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    text: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: theme.colors.neutral['600'],
    },
    subtext: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#000',
    },
    requiredColumn: {
        fontSize: '1rem',
        fontWeight: 600,
        fontStyle: 'italic',
        color: theme.colors.orange['400'],
        padding: '4px 8px',
    },
    tableHeader: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: '#666666',
        padding: '4px 8px',
        border: `1px solid ${theme.colors.neutral['300']}`,
    },
    tableRowExample: {
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
        padding: '4px 8px',
        border: `1px solid ${theme.colors.neutral['300']}`,
    },
    donwloadIcon: {
        width: 20,
        height: 20,
        stroke: theme.palette.primary.light,
    },
    downloadLink: {
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none !important',
        }
    },
    downloadText: {
        fontSize: '1rem',
        color: theme.palette.primary.light,
    },
    uploadButton: {
        width: 150,
        height: 40,
        borderRadius: 20,
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: 'white',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
    closeButton: {
        width: 100,
        height: 36,
        borderRadius: 20,
        fontSize: '0.95rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
}));

const { aws_dashboard_bulk_uploads_bucket } = awsExports;

const BULK_ADD_VALID_FILE = { maxFileSize: 100 * 1024 * 1024, allowedFileTypes: ['text/csv'], };

const BulkAddWorkspacesModal: React.FC<{
    isOpen: boolean,
    onClose: () => void,
}> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);

    const { uploading, progress, hasUploaded, uploadFiles, resetUploader } = useS3FileUploader(
        (extFile: ExtFile) => s3UploadToExternalBucket({
            path: ['workspaces', userGroup, `${uuidv4()}.csv`].join('/'),
            file: extFile.file,
            bucket: aws_dashboard_bulk_uploads_bucket,
        })
    );

    useEffect(() => {
        if (isOpen)
            resetUploader();
    // eslint-disable-next-line
    }, [isOpen]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                <BulkIcon className={classes.bulkIcon} />
                {'Add multiple investors'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            {uploading ? (<>
                <Stack alignItems="center" justifyContent="center" width="100%" height="100%" py={10}>
                    <CircularProgressPercent value={progress} />
                </Stack>
            </>) : hasUploaded ? (<>
                <DialogContent className={classes.dialogContent}>
                    <Stack alignItems="center" justifyContent="center" width="100%" height="100%" py={8}>
                        <CheckIcon sx={{ fontSize: '3rem', color: theme.palette.primary.main }} />
                        <Typography variant="body1" color="black" fontWeight="bold" fontSize="1.25rem !important"
                            sx={{ marginTop: '20px !important' }}>
                            {'Saved to Notissia'}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button variant="outlined"
                        className={classes.closeButton}
                        onClick={onClose}>{'Close'}</Button>
                </DialogActions>
            </>) : (progress > 0) ? (<>
                <DialogContent className={classes.dialogContent}>
                    <Stack alignItems="center" justifyContent="center" width="100%" height="100%" py={8}>
                        <CancelIcon sx={{ fontSize: '3rem', color: 'red' }}  />
                        <Typography variant="body1" color="black" fontWeight="bold" fontSize="1.25rem !important"
                            sx={{ marginTop: '20px !important' }}>
                            {'File upload error!'}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button variant="outlined"
                        className={classes.closeButton}
                        onClick={onClose}>{'Close'}</Button>
                </DialogActions>
            </>) : (<>
                <DialogContent className={classes.dialogContent}>
                    <Stack spacing={2} alignItems="flex-start" justifyContent="center">
                        <Stack spacing={1}>
                            <Typography className={classes.subtitle}>
                                {`Upload a CSV to add multiple investors`}
                            </Typography>
                            <Typography className={classes.text}>
                                {`Notissia will gather preliminary data on each investor.`}
                            </Typography>
                        </Stack>
                        <Stack spacing={1}>
                            <Typography className={classes.subtext}>
                                {'Use the following format:'}
                            </Typography>
                            <Grid container width="100%">
                                <Grid container item>
                                    <Grid item className={classes.requiredColumn} xs={3.5}>
                                        {'(required)'}
                                    </Grid>
                                    <Grid item className={classes.requiredColumn}  xs={8.5}>
                                        {'(either work email or website is required)'}
                                    </Grid>
                                </Grid>
                                <Grid container item>
                                    <Grid item className={classes.tableHeader} xs={3.5} sx={{ borderTopLeftRadius: 16 }}>
                                        {'Name'}
                                    </Grid>
                                    <Grid item className={classes.tableHeader} xs={4.5} sx={{ borderTopRadius: 16 }}>
                                        {'Email'}
                                    </Grid>
                                    <Grid item className={classes.tableHeader} xs={4} sx={{ borderTopRightRadius: 16 }}>
                                        {'Website'}
                                    </Grid>
                                </Grid>
                                <Grid container item>
                                    <Grid item className={classes.tableRowExample} xs={3.5}>
                                        {'Investor 1'}
                                    </Grid>
                                    <Grid item className={classes.tableRowExample} xs={4.5}>
                                        {'investor1@yourfund.com'}
                                    </Grid>
                                    <Grid item className={classes.tableRowExample} xs={4}>
                                        {'yourfund.com'}
                                    </Grid>
                                </Grid>
                                <Grid container item>
                                    <Grid item className={classes.tableRowExample} xs={3.5} sx={{ borderBottomLeftRadius: 16 }}>
                                        {'Investor 2'}
                                    </Grid>
                                    <Grid item className={classes.tableRowExample} xs={4.5} sx={{ borderBottomRadius: 16 }}>
                                        {'investor2@yourfund.com'}
                                    </Grid>
                                    <Grid item className={classes.tableRowExample} xs={4} sx={{ borderBottomRightRadius: 16 }}>
                                        {'yourfund.com'}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                        <CSVLink uFEFF={false} className={classes.downloadLink}
                            headers={["Name", "Email", "Website"]} data={[]}
                            filename={`list-of-investors-template-${makeId(5)}.csv`}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <DownloadIcon className={classes.donwloadIcon} />
                                <Typography className={classes.downloadText}>
                                    {'Download CSV template'}
                                </Typography>
                            </Stack>
                        </CSVLink>
                    </Stack>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    {!uploading && (
                        <FileInputButton
                            className={classes.uploadButton}
                            onChange={uploadFiles}
                            maxFileSize={BULK_ADD_VALID_FILE.maxFileSize}
                            accept={BULK_ADD_VALID_FILE.allowedFileTypes.join(',')}
                            behaviour="replace"
                            label="Upload CSV"
                        />
                    )}
                </DialogActions>
            </>)}
        </Dialog>
    </>);
}
export default BulkAddWorkspacesModal;