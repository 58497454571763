import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { Variant } from "@mui/material/styles/createTypography";
import { makeStyles } from "@mui/styles";
import { Dashboard } from "../../../types/files";
import New from "./New";
import { isTitleDefault } from "../../../shared/dashboard";

const useStyles = makeStyles(() => ({
    title: {
        width: "fit-content",
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const placeholder = 'Add a title...';

const DashboardTitleEditor: React.FC<{
    title: string,
    variant: Variant,
    sharing?: JSX.Element,
    dashboard?: Dashboard,
    edit?: boolean,
    style?: CSSProperties,
    readonly?: boolean,
    onUpdate: (value: string) => boolean,
    onCancel?: (value: string) => void,
}> = ({ title, variant, sharing, dashboard, edit, style, readonly, onUpdate, onCancel}) => {
    const classes = useStyles();
    const titleRef = useRef<any>(null);

    const titleIsDefault = isTitleDefault(title);
    const displayedTitle = titleIsDefault ? placeholder : title?.toString() || '';

    const [titleWidth, setTitleWidth] = useState<number>(0);
    const [data, setData] = useState<string>(displayedTitle);
    const [internalEdit, setInternalEdit] = useState<boolean>(!!edit);

    useEffect(() => {
        if (titleRef) {
            setTitleWidth(titleRef?.current?.scrollWidth + 80);
        }
    }, [titleRef]);

    useEffect(() => {
        if (displayedTitle) {
            setData(displayedTitle);
        }
    }, [displayedTitle]);

    const handleDoubleClick = (e: any) => {
        if (e.detail === 2) {
            setInternalEdit(true);
        }
    };

    const handleKeyPress = (e: any) => {
        if (e.charCode === 13) {
            setInternalEdit(false);
            handleUpdate();
        }
    }

    const handleUpdate = () => {
        if (onUpdate(data.trim())) {
            setInternalEdit(false);
            return true;
        } else {
            setData(title);
            setInternalEdit(true);
            return false;
        }
    }


    const getFontSizeForVariant = (): number => {
        switch (variant) {
            case "h4":
                return 34;
            case "h6":
                return 20;

            default:
                return 20;
        }
    }

    return (
        <>
            {
                readonly || !internalEdit ?
                    <Tooltip title={data}>
                        <Grid container width="100%" ref={titleRef}>
                            <Grid item display="flex" xs={12}>
                                <Typography style={{color: titleIsDefault ? 'lightgrey' : 'black', ...style}} onClick={handleDoubleClick} color="primary" variant={variant} className={classes.title}>{readonly && data===placeholder ? '' : data}</Typography>
                                {dashboard && <New dashboard={dashboard} />}
                                { sharing }
                            </Grid>
                        </Grid>
                    </Tooltip>
                    :
                    <Grid container width={`${titleWidth}px`} minWidth={400} maxWidth="100%">
                        <Grid item display="flex" xs={12}>
                            <TextField
                                variant="standard"
                                autoFocus
                                type="text"
                                value={data}
                                style={{ width: "100%" }}
                                inputProps={{ style: { fontSize: getFontSizeForVariant(), padding: 0, minHeight: "auto" } }}
                                onChange={(e) => setData(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            <IconButton onClick={() => handleUpdate()}>
                                <CheckIcon fontSize="small" />
                            </IconButton>
                            <IconButton onClick={() => {
                                setData(title);
                                setInternalEdit(false);
                                onCancel?.(data);
                            }}>
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
            }
        </>
    )
};

export default DashboardTitleEditor;