import makeStyles from '@mui/styles/makeStyles';
import { createReactEditorJS } from 'react-editor-js';
import { Avatar, Box, Button, CardActions, CardContent, IconButton, LinearProgress, Typography } from '@mui/material';
import { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import classNames from 'classnames';
import { User } from '../../../../types/common';
import { Comment, CommentOriginType, ExtendedComment, Mention as MentionModel } from '../../../../types/comments';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as ReplyIcon } from '../../../../assets/icons/replyNew.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/editNew.svg';
import AddReplyPane from "./AddReplyPane";
import { getFileIcon } from "../../../../components/folders/utils/files";
import { AuthContext } from '../../../../contexts/AuthContext';
import { getMentionsFunc, updateCommentFunc, updateMentionFunc } from '../../../../lib/helper';
import { DashboardUid } from '../../../../types/files';
import moment from 'moment';
import { EDITOR_JS_TOOLS } from '../../../../helpers/editor-js';

const edjsParser = require("editorjs-parser").default;
const parser = new edjsParser();

// const ExpandMore = styled(forwardRef(({ expand, ...other }: any, ref) => {
//     return <IconButton {...other} ref={ref} />;
// }))(({ theme, expand }) => ({
//     transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//         duration: theme.transitions.duration.shortest,
//     }),
// }));

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 6,
        paddingTop: 10,
        paddingBottom: 10,
        '& #cardHeadingActions': {
            visibility: "collapse",
            height: 0,
            width: 0,
            minWidth: 0
        },
        "&:hover #cardHeadingActions": {
            visibility: "visible",
            width: 100,
            minWidth: 100
        },
        '& .updatedAt': {
            visibility: "visible",
            color: '#878E95',
            fontSize: 12,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
            lineClamp: 1,
            lineHeight: "1.4",
            wordBreak: "break-word",
            alignSelf: 'center',
        },
        "&:hover .updatedAt": {
            visibility: "collapse",
            height: 0,
            width: 0,
            minWidth: 0
        }
    },
    cardContent: {
        padding: '0px 10px 5px 10px !important',
        '-moz-transition': 'height .5s',
        '-ms-transition': 'height .5s',
        '-o-transition': 'height .5s',
        '-webkit-transition': 'height .5s',
        transition: 'height .5s ease',
        overflow: 'hidden',
        '& .codex-editor__redactor': {
            paddingBottom: '0px !important',
        },
        '& .ce-settings': {
            left: '-150px',
        },
        '& .ce-popover.ce-popover--opened': {
            height: 200,
        }
    },
    cardActions: {
        padding: '0 !important',
        '-moz-transition': 'height .5s',
        '-ms-transition': 'height .5s',
        '-o-transition': 'height .5s',
        '-webkit-transition': 'height .5s',
        transition: 'height .5s ease',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        visibility: "hidden"
    },
    cardActionsEditable: {
        visibility: "visible"
    },
    visibleCardActions: {
        padding: '0 !important',
        '-moz-transition': 'height .5s',
        '-ms-transition': 'height .5s',
        '-o-transition': 'height .5s',
        '-webkit-transition': 'height .5s',
        transition: 'height .5s ease',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    commentTextContainer: {
        backgroundColor: theme.palette.info.light,
        padding: 5,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 4,
    },
    commentText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        lineClamp: 1,
        lineHeight: "1.4",
        wordBreak: "break-word",
    },
    activeComment: {
        boxShadow: theme.shadows[4]
    },
    commentHeading: {
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    userHeadingContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    userHeading: {
        fontSize: 14,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 1,
        color: 'black',
        width: '100%',
        marginRight: 10,
    },
    textArea: {
        marginTop: 5,
        fontFamily: theme.typography.fontFamily,
        marginBottom: 10,
        padding: 0,
        width: '100%',
        "& > div": {
            "& textarea": {
                borderRadius: 4,
                border: `2px solid ${theme.palette.text.disabled}`
            },
        },
    },
    listItem: {
        padding: 0,
    },
    mention: {
        fontWeight: 'bold !important',
        color: theme.palette.secondary.main,
        position: "relative",
        zIndex: 1,
        textShadow: "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
        pointerEvents: "none"
    },
    mentionFocused: {
        backgroundColor: theme.palette.action.hover,
    },
    bullet: {
        display: "inline-block",
        width: 20,
        height: 20,
        fontSize: 12,
        background: theme.palette.primary.light,
        color: "#fff",
        borderRadius: "50%",
        textAlign: "center",
        verticalAlign: "middle",
        marginRight: 5,
        padding: 4,
    },
    bulletSelected: {
        background: theme.palette.secondary.light
    },
    actionIcon: {
        height: 15,
        width: 15,
        stroke: '#048290'
    },
    actionIconButton: {
        padding: 4,
    },
    fileNameContainer: {
        backgroundColor: '#F7F5F2',
        borderRadius: 4,
        width: '100%',
        padding: '6px 8px',
        display: 'flex',
    },
    bubble: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontSize: 13,
        fontWeight: '500',
        marginLeft: 5,
    },
    leftCommentHeading: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'baseline',
        width: 'calc(100% - 90px)'
    },
    rightCommentHeading: {
        display: 'flex',
        flexDirection: 'row',
        width: 90,
        whiteSpace: 'nowrap',
        justifyContent: 'end'
    },
    infoHeading: {
        marginLeft: 5,
        fontSize: 12,
        textDecoration: 'none',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        alignSelf: 'center'
    },
    cardHeadingActions: {
        minWidth: 78,
        paddingLeft: 8
    },
    avatar: {
        border: 'none',
        fontSize: 12,
        fontWeight: 600,
        height: 32,
        width: 32,
        backgroundColor: theme.palette.info.main,
        color: theme.palette.text.primary,
    },
    actionButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    actionButton: {
        marginRight: 10,
        borderRadius: 20,
    },
    editorContainer: {
        margin: '10px 0',
    }
}));

// const MENTION_REGEX = '\\[|\\]|\\([a-zA-Za0-9]*-[a-zA-Za0-9]*-[a-zA-Za0-9]*-[a-zA-Za0-9]*-[a-zA-Za0-9]*\\)';

export enum CommentType {
    Comment,
    Mention,
    Reply
}

const CommentCard: React.FC<{
    readOnly?: boolean | undefined,
    comment: ExtendedComment,
    users: User[],
    setSelectedHighlightAreas?: (highlightAreas: any) => void,
    setSelectedComment?: any,
    selectedComment?: any,
    type: CommentType,
    onDelete: (comment: Comment) => void,
    onUpdate?: (comment: Comment) => void,
    fileKey: string | undefined,
    onReplyAdded: (reply: ExtendedComment) => void,
    parentComment?: ExtendedComment,
    displayIndexBullet?: boolean,
    commentOriginType: CommentOriginType,
    dashboardItem?: DashboardUid,
    bubbleText?: string,
    customStyle?: CSSProperties,
    customContentStyle?: CSSProperties,
    simpleHeader?: boolean,
    onClick?: (e: any) => void,
    fileName?: string,
}> =
    ({ readOnly, comment, parentComment, users, setSelectedHighlightAreas,
        setSelectedComment, selectedComment, type, onDelete, onUpdate, fileKey,
        onReplyAdded, displayIndexBullet = false, commentOriginType, dashboardItem,
        bubbleText, customStyle, customContentStyle, simpleHeader = true, onClick, fileName }) => {
        const classes = useStyles();
        const [replyActive, setReplyActive] = useState<boolean>(false);
        const [isEditable, setIsEditable] = useState<boolean>(false);
        const ref = useRef<HTMLInputElement>(null);
        const [content, setContent] = useState<string>("");
        const [mentions, setMentions] = useState<string[]>([]);
        const [loading, setLoading] = useState<boolean>(false);
        const [dbMentions, setDbMentions] = useState<MentionModel[]>([]);
        const [isExpanded, setIsExpanded] = useState<boolean>(false);
        const [isExpandable, setIsExpandable] = useState<boolean>(false);
        const [hasUpdated, setHasUpdated] = useState<boolean>(false);
        const { userGroup } = useContext(AuthContext);
        const ReactEditorJS = createReactEditorJS();
        const editorCore = useRef<any>(null);

        const reportingUser = users.find((user: User) => user.email === comment.email);
        const isSelected = selectedComment && selectedComment === comment.id;

        const commentDescription = comment.description;
        const commentUserId = comment.email;
        const commentId = comment.id;

        const handleInitialize = useCallback(async (instance) => {
            editorCore.current = instance;
            await instance._editorJS.isReady;
            // eslint-disable-next-line
        }, [content, isEditable]);

        const update = async (newComment: string) => {
            if (!newComment) {
                return;
            }

            setLoading(true);
            const newMentions = buildMentionsToUpdate();

            const item = {
                id: comment.id,
                parentId: comment.parentId,
                commentedItem: comment.commentedItem,
                documentId: comment.documentId,
                dashboardId: comment.dashboardId,
                group: comment.group,
                text: comment.text,
                title: comment.title,
                email: comment.email,
                usersMentioned: mentions,
                description: newComment,
            } as Comment;

            updateCommentFunc(item).then(() => {
                Promise.all(newMentions.map(async (mention: MentionModel) => {
                    await updateMentionFunc(mention);
                })).then(() => {
                    setContent(newComment);
                    setLoading(false);
                    setIsEditable(false);
                    setHasUpdated(true);
                    if (onUpdate) {
                        onUpdate(item);
                    }
                });
            });
        }

        const handleSave = async () => {
            const savedData = await editorCore.current?.save();
            const html = JSON.stringify(savedData);
            update(html || '');
        };

        useEffect(() => {
            if (comment) {
                setMentions(comment.usersMentioned);
            }
        }, [comment]);

        useEffect(() => {
            setContent(commentDescription);
            setHasUpdated(true);
            getMentionsFunc(commentUserId).then((data: MentionModel[]) => {
                setDbMentions(data.filter((element: MentionModel) => element.commentId === commentId));
            });
        }, [commentDescription, commentUserId, commentId]);

        useEffect(() => {
            if (hasUpdated) {
                setIsExpandable(ref?.current?.scrollHeight! > 24 * 3); // line height * number of lines
                setHasUpdated(false);
            }
        }, [hasUpdated]);

        const buildMentionsToUpdate = () => {
            const filteredExistingMentions = dbMentions.filter((mention: MentionModel) => mentions.find((e) => e === mention.email));

            const addedMentions = mentions.map((e) => ({
                email: comment.email,
                group: userGroup,
                emailOfPersonWhoMentioned: e,
                commentId: comment.id
            } as MentionModel));
            return [...filteredExistingMentions, ...addedMentions];
        }

        // const searchUsers = (query: string, callback: (data: SuggestionDataItem[]) => void) => {
        //     if (!query) {
        //         callback([]);
        //         return;
        //     }

        //     callback(users
        //         .filter(x => x.email?.includes(query) || x.firstName?.includes(query) || x.lastName?.includes(query))
        //         .slice(0, 5)
        //         .map(x => ({ id: x.email, display: getUserDisplayName(x) })));
        // }

        // const getTextHighlighted = (content: string) => {
        //     content = content.replace(new RegExp(MENTION_REGEX, "gm"), "");

        //     const mentions = content.match(/(@\w+)/g);

        //     mentions?.forEach(user => {
        //         const index = content.indexOf(user);
        //         if (index >= 0) {
        //             content = content.substring(0, index) + `<span class='${classes.mention}'>` + content.substring(index, index + user.length) + "</span>" + content.substring(index + user.length);
        //         }
        //     });

        //     return content;
        // }

        // Increase image screenshot markers
        const scaleUpCommentBullet = () => {
            if (comment.index || parentComment?.index) {
                const bulletElem = document.querySelector(`[data-bulletmarkerid='${comment.index || parentComment?.index}']`);

                if (bulletElem && !bulletElem.classList.contains("image-marker-scale-up-selected")) {
                    bulletElem.classList.add("image-marker-scale-up");
                }
            }
        }

        const scaleDownCommentBullet = () => {
            if (comment.index || parentComment?.index) {
                const bulletElem = document.querySelector(`[data-bulletmarkerid='${comment.index || parentComment?.index}']`);

                if (bulletElem && !bulletElem.classList.contains("image-marker-scale-up-selected")) {
                    bulletElem.classList.remove("image-marker-scale-up");
                }
            }
        }

        const cardHeadingActions = (
            <div id="cardHeadingActions" className={classes.cardHeadingActions}>
                {!readOnly && !isEditable && <div>
                    <IconButton className={classes.actionIconButton} onClick={(e) => {
                        e.stopPropagation();
                        onDelete(comment);
                    }} title="Delete">
                        <CloseIcon className={classes.actionIcon} />
                    </IconButton>
                    <IconButton className={classes.actionIconButton} onClick={(e) => {
                        e.stopPropagation();
                        setIsEditable(true); setIsExpandable(true); setIsExpanded(true);
                    }} title="Edit">
                        <EditIcon className={classes.actionIcon} />
                    </IconButton>
                    <IconButton className={classes.actionIconButton} onClick={(e) => {
                        e.stopPropagation();
                        setReplyActive(true);
                    }} title="Reply">
                        <ReplyIcon className={classes.actionIcon} />
                    </IconButton>
                </div>}
            </div>
        );

        const renderEditorText = async () => {
            try {
                const json = JSON.parse(content);
                await editorCore.current?._editorJS.blocks.render(json);
            } catch {
                await editorCore.current?._editorJS.blocks.renderFromHTML(content);
            }
        }

        const renderTypographyText = () => {
            let returnValue = null;

            try {
                returnValue = parser.parse(JSON.parse(content));
            } catch {
                returnValue = content;
            }

            return returnValue;
        }

        const commentTitle = reportingUser?.firstName || comment?.email;

        return (
            <div style={customStyle || { width: type === CommentType.Reply ? '81%' : 'calc(100% - 30px)', margin: 5, marginBottom: 8, marginLeft: type === CommentType.Reply ? 'auto' : 10, }}>
                <div className={classNames(classes.card, { [classes.activeComment]: isSelected })} onMouseOver={scaleUpCommentBullet} onMouseOut={scaleDownCommentBullet}>
                    <CardContent className={classes.cardContent}
                        style={customContentStyle || {
                            height: `${isExpandable ?
                                isExpanded ? ref?.current?.scrollHeight! + 33 : 33 + (24 * 3) // 3 lines before expand
                                : ref?.current?.scrollHeight! + 37}px`,
                            cursor: isEditable ? 'default' : 'pointer'
                        }}
                        onClick={(e) => {
                            if (onClick && !isEditable) {
                                onClick(e);
                                return;
                            }
                            if (parentComment) {
                                if (setSelectedHighlightAreas) {
                                    if (selectedComment && selectedComment === comment.id) {
                                        setSelectedHighlightAreas(undefined);
                                    } else {
                                        setSelectedHighlightAreas({
                                            id: parentComment.id,
                                            highlightAreas: parentComment.commentedItem,
                                        });
                                    }
                                }
                            } else {
                                if (setSelectedHighlightAreas) {
                                    if (selectedComment && selectedComment === comment.id) {
                                        setSelectedHighlightAreas(undefined);
                                    } else {
                                        setSelectedHighlightAreas({
                                            id: comment.id,
                                            highlightAreas: comment.commentedItem,
                                        });
                                    }
                                }
                            }

                            if (setSelectedComment) {
                                if (selectedComment && selectedComment === comment.id) {
                                    setSelectedComment(undefined);
                                } else {
                                    setSelectedComment(comment.id);
                                }
                            }
                        }}>
                        {simpleHeader ? (
                            <div className={classes.commentHeading}>
                                <Box style={{ width: 'calc(100% - 70px)', }} className={classes.userHeadingContainer} title={commentTitle}>
                                    {
                                        displayIndexBullet &&
                                        <span className={classNames(classes.bullet, { [classes.bulletSelected]: isSelected })}>{comment.index}</span>
                                    }
                                    <Typography style={{ width: displayIndexBullet ? 'calc(100% - 25px)' : '100%', }} className={classes.userHeading}>{reportingUser?.firstName || comment?.email || 'None'}</Typography>
                                </Box>
                                {cardHeadingActions}
                            </div>
                        ) : (
                            <div className={classes.commentHeading}>
                                <div className={classes.leftCommentHeading}>
                                    <Avatar className={classes.avatar}>{commentTitle.slice(0, 2).toUpperCase()}</Avatar>
                                    <Typography className={classes.userHeading} title={commentTitle}>
                                        {displayIndexBullet && <span className={classNames(classes.bullet)}>{comment.index}</span>}
                                        {comment.email || 'None'}
                                    </Typography>
                                </div>
                                <div className={classes.rightCommentHeading}>
                                    <Typography className='updatedAt'>{moment(comment.updatedAt).fromNow()}</Typography>
                                    {cardHeadingActions}
                                </div>
                            </div>)}
                        {bubbleText &&
                            <Box className={classes.fileNameContainer}>
                                {fileName && <>{getFileIcon(fileName)}</>}
                                <Typography className={classes.bubble}>{
                                    bubbleText
                                }</Typography>
                            </Box>
                        }
                        {
                            !isEditable &&
                            <Box className={classes.commentTextContainer}>
                                <Typography
                                    variant="subtitle1"
                                    ref={ref}
                                    className={classes.commentText}
                                    marginLeft={displayIndexBullet ? "30px" : "5px"}
                                    dangerouslySetInnerHTML={{ __html: renderTypographyText() }}
                                />
                            </Box>
                        }
                        {/* {isEditable && <MentionsInput
                            value={content}
                            onChange={(e, _1, _2, mentions) => {
                                setContent(e.target.value);
                                setMentions(mentions.map(x => x.id));
                            }}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                            singleLine={false}
                            rows={3}
                            className={classes.textArea}
                        >
                            <Mention
                                trigger="@"
                                data={searchUsers}
                                displayTransform={(_, display) => `@${display}`}
                                className={classes.mention}
                                renderSuggestion={(suggestion: SuggestionDataItem, _1, _2, _3, focused) => (
                                    <Grid alignItems="flex-start" className={classNames(classes.listItem, { [classes.mentionFocused]: focused })}>
                                        <ListItemText
                                            primary={suggestion.display}
                                        />
                                    </Grid>
                                )}
                            />
                        </MentionsInput>} */}
                        {isEditable &&
                            <div className={classes.editorContainer}>
                                <ReactEditorJS
                                    hideToolbar={true}
                                    onInitialize={handleInitialize}
                                    onReady={renderEditorText}
                                    tools={EDITOR_JS_TOOLS}
                                />
                            </div>}
                        {loading && <LinearProgress />}
                    </CardContent>
                    <CardActions className={classNames(classes.visibleCardActions)}>
                        {!readOnly && isEditable && <div className={classes.actionButtonsContainer}>
                            <Button size="small" className={classes.actionButton} variant="outlined" onClick={() => { setIsEditable(false); setIsExpanded(false); }}>
                                Cancel
                            </Button>
                            <Button size="small" className={classes.actionButton} variant="contained" onClick={handleSave} startIcon={<SendIcon width="12px" height="12px" />}>
                                Post
                            </Button>
                        </div>}
                        {/* {isExpandable && !readOnly && <Tooltip title="Read more" placement="bottom-start">
                            <ExpandMore
                                expand={isExpanded}
                                size="small"
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                <ExpandMoreIcon className={classes.actionIcon} />
                            </ExpandMore>
                        </Tooltip>} */}
                    </CardActions>
                </div>
                {
                    replyActive &&
                    <AddReplyPane
                        documentId={comment.documentId || fileKey || ''}
                        users={users}
                        onAdded={onReplyAdded}
                        onCancel={() => setReplyActive(false)}
                        parentId={comment.parentId || comment.id}
                        parentCommentedItem={comment.commentedItem}
                        commentOriginType={commentOriginType}
                        dashboardItem={dashboardItem}
                        isSecondReply={!!comment.parentId}
                    />
                }
            </div >
        )
    };

export default CommentCard;
