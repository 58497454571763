import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Comment, CommentOriginType, ExtendedComment } from "../../../types/comments";
import { CompanyFile, DashboardDocument, DashboardScreenshot, DashboardSelection, } from "../../../types/files";
import { grey } from "@mui/material/colors";
import { mobileWidth } from "../../App";
import { useCurrentWidth } from "react-socks";
import TagModes from "../../atoms/TagModes";
import { deleteCommentFunc, deleteMentionFunc, getCommentsFunc } from "../../../lib/helper";
import ConfirmDialog from "../../modals/ConfirmDialog";
import { getDashboardItemType, isTitleDefault } from "../../../shared/dashboard";
import CommentCard, { CommentType } from "../../folders/components/file-preview/CommentCard";

const useStyles = makeStyles((theme) => ({
    dashboardTagsContainer: {
        margin: 10,
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        height: 'calc(100% - 60px)'
    },
    dashboardTagsContainerMobile: {
        padding: "20px 5px 10px 5px",
        marginBottom: 70,
        overflowY: 'auto',
    },
    dashboardTagsBoxItems: {
        borderRadius: 10,
        padding: '0 5px',
        margin: '0 5px',
        height: 'calc(100% - 53px)',
        overflowY: 'auto',
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-track": {
            background: theme.palette.common.white,
        },
        "&::-webkit-scrollbar-thumb": {
            background: grey[400],
            borderRadius: 4,
        },
    },
    dashboardTagsBoxItemsMobile: {
        border: `1px solid ${grey[400]}`,
        borderRadius: 10,
        padding: 5,
        margin: 0
    },
    dashboardTagsItems: {
    },
    mention: {
        fontWeight: 'bold !important',
        color: theme.palette.secondary.main,
        position: "relative",
        zIndex: 1,
        textShadow: "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
        pointerEvents: "none"
    },
    commentHeading: {
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    commentText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: 1,
        lineHeight: "1.4",
        wordBreak: "break-word"
    },
    leftCommentHeading: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 100px)'
    },
    rightCommentHeading: {
        display: 'flex',
        flexDirection: 'row',
        width: 100,
        whiteSpace: 'nowrap',
        justifyContent: 'end'
    },
    userHeading: {
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 1,
        color: theme.palette.primary.light,
        padding: '5px 5px 0px 5px',
        whiteSpace: 'nowrap',
    },
    pageTitleHeading: {
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 1,
        padding: 0,
        whiteSpace: 'nowrap',
        marginLeft: 28,
        marginBottom: 4,
        color: '#999999'
    },
    infoHeading: {
        marginLeft: 5,
        fontSize: 12,
        textDecoration: 'none',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        alignSelf: 'center'
    },
    tagCard: {
        paddingBottom: 5,
        '&:hover': {
            boxShadow: `inset 0px -3px 3px -3px ${theme.palette.primary.light} !important`,
        },
    },
    bullet: {
        display: "inline-block",
        width: 20,
        height: 20,
        fontSize: 12,
        background: theme.palette.primary.light,
        color: "#fff",
        borderRadius: "50%",
        textAlign: "center",
        verticalAlign: "middle",
        marginRight: 5
    },
    actionIcon: {
        height: 15,
        width: 15,
    },
    actionIconButton: {
        padding: 4,
    },
}));

const DashboardTags: React.FC<{
    dashboardId?: string,
    dashboardItems: (DashboardDocument | DashboardScreenshot | DashboardSelection)[],
    comments: ExtendedComment[],
    highlightedComments: number[],
    selectedFile?: CompanyFile,
    otherFiles: CompanyFile[],
    preSelectedCommentId?: string,
    setHighlightedComments: (list: number[]) => void,
    onSelectComment: (comment?: ExtendedComment, dashboardElement?: DashboardDocument | DashboardScreenshot | DashboardSelection) => void
    onReplyAdded: (reply: ExtendedComment) => void
}> = ({ dashboardId, dashboardItems, comments, selectedFile, otherFiles, preSelectedCommentId, setHighlightedComments, onSelectComment, onReplyAdded }) => {
    const [selectedTagMode, setSelectedTagMode] = useState('All');
    const [fileComments, setFileComments] = useState<ExtendedComment[]>([]);
    const [otherFilesComments, setOtherFilesComments] = useState<ExtendedComment[]>([]);
    const [commentToDelete, setCommentToDelete] = useState<ExtendedComment | undefined>();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState<string | undefined>();

    const onDelete = (comment: ExtendedComment) => {
        setCommentToDelete(comment);
        setConfirmationOpen(true);
    }

    const onDeleteConfirm = () => {
        if (commentToDelete && commentToDelete.id) {
            setDeleteLoading(true);
            deleteCommentFunc(commentToDelete.id)
                .then(
                    () => {
                        let childPromises: any[] = [];

                        commentToDelete.usersMentioned.forEach((mentionUserId) => {
                            childPromises.push(deleteMentionFunc(mentionUserId));
                        });

                        if (commentToDelete.children?.length! > 0) {
                            commentToDelete.children?.forEach((reply: Comment) => {
                                if (reply.id) {
                                    childPromises.push(deleteCommentFunc(reply.id));
                                }
                            });
                        }

                        Promise.all(childPromises).then(() => {
                            setCommentToDelete(undefined);
                            setConfirmationOpen(false);
                            setDeleteLoading(false);
                            onSelectComment();
                            setFileComments(fileComments.filter(c => c.id !== commentToDelete.id));
                        });
                    },
                    (error) => {
                        setCommentToDelete(undefined);
                        setDeleteLoading(false);
                        setConfirmationOpen(false);
                        console.log(error);
                    });
        }
    }

    useEffect(() => {
        if (preSelectedCommentId) {
            setSelectedCommentId(preSelectedCommentId);
        }
    }, [preSelectedCommentId]);

    useEffect(() => {
        const getFileComments = async (key: string) => {
            const comments = await getCommentsFunc(key);
            if (selectedFile && key === selectedFile.key) {
                setFileComments(comments);
            }
            return comments;
        }
        const getOtherFilesComments = async (otherFiles: CompanyFile[]) => {
            const otherComments = await Promise.all(otherFiles
                .filter(f => f.dashboardId === dashboardId)
                .map(async (file) => {
                    const otherFileComment = await getFileComments(file.key);
                    return otherFileComment;
                }));
            setOtherFilesComments(otherComments.flatMap(x => x));
        }
        if (selectedFile?.key) {
            getFileComments(selectedFile.key);
            setSelectedTagMode(selectedFile.name);
        }
        else {
            setSelectedTagMode('All');
        }
        getOtherFilesComments(otherFiles);
    }, [selectedFile, selectedFile?.key, otherFiles, dashboardId]);

    const classes = useStyles();
    const dashboardComments = comments.filter(c => c.dashboardId && c.dashboardId === dashboardId);
    const allFilesComments = fileComments.concat(otherFilesComments.filter(
        x => !fileComments.find(y => y.id === x.id)
    ));
    const allComments = dashboardComments.concat(allFilesComments.filter(
        x => !dashboardComments.find(y => y.id === x.id)
    ));
    const displayedComments = selectedTagMode === 'All' ? allComments :
        (selectedTagMode === 'Pins' ? dashboardComments :
            (selectedTagMode === 'Files' ? allFilesComments : fileComments));

    const currentWidth = useCurrentWidth();
    const isMobile = currentWidth && currentWidth < 9999 ? currentWidth <= mobileWidth : window?.innerWidth <= mobileWidth;

    const findDashboardElement = (comment: ExtendedComment) => dashboardItems.find((
        e: DashboardDocument | DashboardScreenshot | DashboardSelection
    ) => e.id === comment.documentId);

    const findFile = (comment: ExtendedComment) => {
        const files = selectedFile ? [selectedFile].concat(otherFiles) : otherFiles;
        return files.find((f) => f.key === comment.documentId);
    }

    const sortComments = ['All', 'Files'].includes(selectedTagMode) ? (a: ExtendedComment, b: ExtendedComment) => {
        if (!a.updatedAt || !b.updatedAt) {
            return 0;
        }
        return b.updatedAt.localeCompare(a.updatedAt);
    } : (selectedTagMode === 'Pins' ?
        (a: ExtendedComment, b: ExtendedComment) => {
            const dashboardElementA = findDashboardElement(a);
            const dashboardElementB = findDashboardElement(b);
            if (typeof dashboardElementA?.order === 'number' && dashboardElementA?.order >= 0 && typeof dashboardElementB?.order === 'number' && dashboardElementB?.order >= 0) {
                return dashboardElementA?.order - dashboardElementB?.order;
            }
            return 0;
        } :
        (a: ExtendedComment, b: ExtendedComment) => {
            if (!a.commentedItem[0] || !b.commentedItem[0]) {
                return 0;
            }
            if (a.commentedItem[0].pageIndex >= 0 && b.commentedItem[0].pageIndex >= 0) {
                return a.commentedItem[0].pageIndex - b.commentedItem[0].pageIndex;
            }
            return a.commentedItem[0].top - b.commentedItem[0].top;
        }
    );

    return (
        <Box className={isMobile ? classes.dashboardTagsContainerMobile : classes.dashboardTagsContainer}>
            {isMobile && <Typography variant="h5" fontWeight="bold" px={5} mx={2} mb={2}>Comments</Typography>}
            <TagModes selectedMode={selectedTagMode} selectedFile={selectedFile} onSelectMode={(mode: string) => setSelectedTagMode(mode)} />
            {
                displayedComments.length ?
                    <Box className={isMobile ? classes.dashboardTagsBoxItemsMobile : classes.dashboardTagsBoxItems}>
                        <Box className={classes.dashboardTagsItems}>
                            {
                                displayedComments?.filter(c => c.id && !c.parentId).sort(sortComments).map((comment: ExtendedComment, index: number) => {
                                    const dashboardElement = findDashboardElement(comment);

                                    const { documentId } = comment;
                                    const fileName = documentId.split('/').pop();
                                    const title = dashboardElement?.title || '';
                                    const titleIsDefault = isTitleDefault(title);
                                    const displayedTitle = titleIsDefault ? '' : title;
                                    const bubbleText = title ? `${displayedTitle}` : fileName;

                                    return (
                                        <div key={'dashboard-tags-307-' + index}>
                                            <CommentCard
                                                comment={comment}
                                                users={[]}
                                                type={CommentType.Comment}
                                                onDelete={onDelete}
                                                fileKey={dashboardElement?.key || findFile(comment)?.key || ''}
                                                commentOriginType={CommentOriginType.Dashboard}
                                                bubbleText={bubbleText}
                                                fileName={fileName}
                                                customStyle={{}}
                                                customContentStyle={{}}
                                                simpleHeader={false}
                                                onClick={() => {
                                                    setSelectedCommentId(comment.id);
                                                    onSelectComment(comment, dashboardElement);
                                                    if (isMobile && comment.index) {
                                                        setHighlightedComments([comment.index]);
                                                    }
                                                }}
                                                onReplyAdded={onReplyAdded}
                                                dashboardItem={{
                                                    id: dashboardId || "",
                                                    dashboardItemId: dashboardElement?.id || "",
                                                    dashboardItemType: dashboardElement ? getDashboardItemType(dashboardElement) : undefined,
                                                    documentKey: dashboardElement?.key || "",
                                                }}
                                                selectedComment={selectedCommentId}
                                                setSelectedComment={setSelectedCommentId}
                                            />
                                            {allComments.filter(c => c.parentId === comment.id).map((child: ExtendedComment, i) => {
                                                return <div className={classes.tagCard} style={{ width: 'calc(100% - 16px)', marginLeft: 0, marginRight: 15, paddingLeft: 35, cursor: 'pointer' }} key={'dashboard-tags-338-' + i}>
                                                    <CommentCard
                                                        comment={child}
                                                        users={[]}
                                                        type={CommentType.Comment}
                                                        onDelete={onDelete}
                                                        fileKey={dashboardElement?.key || findFile(child)?.key || ''}
                                                        commentOriginType={CommentOriginType.Dashboard}
                                                        bubbleText={bubbleText}
                                                        customStyle={{}}
                                                        customContentStyle={{}}
                                                        simpleHeader={false}
                                                        onClick={() => {
                                                            setSelectedCommentId(comment.id);
                                                            onSelectComment(child, dashboardElement);
                                                            if (isMobile && child.index) {
                                                                setHighlightedComments([child.index]);
                                                            }
                                                        }}
                                                        onReplyAdded={onReplyAdded}
                                                        selectedComment={selectedCommentId}
                                                        setSelectedComment={setSelectedCommentId}
                                                    />
                                                </div>
                                            })}
                                        </div>
                                    );
                                })
                            }
                        </Box>
                        <ConfirmDialog
                            title="Delete confirmation"
                            content={'Are you sure you want to delete this entry?'}
                            open={confirmationOpen}
                            loading={deleteLoading}
                            confirmCallback={onDeleteConfirm}
                            cancelCallback={() => setConfirmationOpen(false)}
                        />
                    </Box>
                    :
                    <Box sx={{ textAlign: 'center' }}><Typography>No comments yet.</Typography></Box>
            }
        </Box>
    )
};

export default DashboardTags;
