import { Button, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from "@mui/styles";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { Workspace } from "../../../types/files";
import useRoute from "../../../hooks/useRoute";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: 600,
            height: 'fit-content',
            padding: 20,
            borderRadius: 32,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    text: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: theme.colors.neutral['800'],
    },
    textLink: {
        minWidth: 'fit-content',
        width: '100%',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            height: 45,
            borderRadius: 8,
            padding: '0 8px !important',
            "& > input": {
                padding: 'unset !important',
                fontSize: '0.95rem',
                textFillColor: theme.colors.neutral['700'],
            },
        }
    },
    shareIcon: {
        width: 32,
        height: 32,
        stroke: theme.colors.primary['400'],
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    shareButton: {
        width: 'fit-content',
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const WorkspaceShareModal: React.FC<{
    isOpen: boolean,
    workspace: Workspace,
    onShare: () => void,
    onClose: () => void,
}> = ({ isOpen, workspace, onClose, onShare }) => {
    const classes = useStyles();
    const { generateWorkspaceSharingURL } = useRoute();

    if (!workspace)
        return (<></>);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                {'Share this workspace'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack spacing={2} alignItems="flex-start" justifyContent="center">
                    <Typography className={classes.text}>
                        {`Anyone with access to this link will be able to view this workspace and each dashboard within this workspace.
                        Files, emails, notes, and clipboard will remain private and not viewable.`}
                    </Typography>
                    <TextField variant="outlined" className={classes.textLink} disabled
                        defaultValue={generateWorkspaceSharingURL(workspace.id)}
                        InputProps={{startAdornment: (
                            <InputAdornment position="start">
                                <ShareIcon  className={classes.shareIcon}/>
                            </InputAdornment>
                        )}}/>
                    <Typography className={classes.text}>
                        {'You can deactivate this link anytime which will end access to the workspace overview. '}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    <Button className={classes.cancelButton}
                        onClick={onClose}> Cancel </Button>
                    <CopyToClipboard
                        text={generateWorkspaceSharingURL(workspace.id)}
                        options={{ format: "text/plain" }}
                        onCopy={onShare}>
                        <Button variant="contained" className={classes.shareButton}>
                            {'Confirm share & copy link'}
                        </Button>
                    </CopyToClipboard>
                    </Stack>
            </DialogActions>
        </Dialog>
    </>);
}
export default WorkspaceShareModal;