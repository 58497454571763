import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MarkerComponentProps } from "react-image-marker";
import { Comment, ExtendedComment } from "../../../types/comments";
import PopupEditor from "../../atoms/PopupEditor";
import { AuthContext } from "../../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  marker: {
    display: "table-cell",
    width: 32,
    height: 32,
    background: '#319AA4',
    color: "#fff",
    borderRadius: "50%",
    textAlign: "center",
    verticalAlign: "middle",
    fontSize: 12,
    transition: "all 0.1s ease",
    "&:hover": {
      "-webkit-transform": "scale(1.4)",
      "-ms-transform": "scale(1.4)",
      transform: "scale(1.4)",
    },
  },
  selected: {
    background: theme.palette.secondary.light,
  },
}));

const DashboardScreenshotCustomMarker: React.FC<
  {
    isModalOpen: boolean;
    canOpenNew: boolean;
    selectedCommentId: string;
    setSelectedCommentId: (id: string) => void;
    comment: ExtendedComment;
    onMarkerActive: (
      markerLeft: number,
      markerTop: number,
      left: number,
      top: number
    ) => void;
    updateComment?: (c: Comment) => void;
    deleteComment?: (id: string, parentId?: string) => void
  } & MarkerComponentProps
> = ({
  isModalOpen,
  canOpenNew,
  selectedCommentId,
  setSelectedCommentId,
  onMarkerActive,
  itemNumber,
  top,
  left,
  comment,
  updateComment,
  deleteComment
}) => {
    const classes = useStyles();
    const paragraphRef = useRef<HTMLParagraphElement>(null);
    const isSelected = comment?.id === selectedCommentId;
    const [isOpen, setIsOpen] = useState(isSelected);
    const [hovered, setHovered] = useState(false);
    const [canClose, setCanClose] = useState(true);
    const { user } = useContext(AuthContext);
    const userName = comment?.email || user.username;

    useEffect(() => {
      if (isModalOpen && paragraphRef.current) {
        const dimensions = paragraphRef.current.getBoundingClientRect();
        onMarkerActive(
          left as number,
          top as number,
          (dimensions.left / window.innerWidth) * 100,
          (dimensions.top / window.innerHeight) * 100
        );
      }
    }, [isModalOpen, paragraphRef, onMarkerActive, left, top]);

    // data-bulletmarkerid used in CommentCard.tsx to increase the marker when hovering over comment
    return (
      <div
        onMouseEnter={() => {
          setHovered(true);
          if (!isOpen && canOpenNew) {
            setSelectedCommentId(comment?.id || '');
            setIsOpen(true);
            setCanClose(true);
          }
        }}
        onMouseLeave={() => {
          setHovered(false);
          if (canClose) {
            setIsOpen(false);
          }
        }}
      >
        <Typography
          ref={paragraphRef}
          className={classNames(classes.marker, {
            [classes.selected]: isSelected,
            "image-marker-scale-up-selected": isSelected,
          })}
          style={isSelected ? { transform: 'scale(1.3)' } : {}}
          id={selectedCommentId || 'new-editor'}
          data-bulletmarkerid={(itemNumber as number) + 1}
        >
          {userName ? userName.slice(0, 2).toUpperCase() : (itemNumber as number) + 1}
        </Typography>
        {isOpen && <PopupEditor
          close={() => setIsOpen(false)}
          onEdit={() => { setCanClose(false) }}
          onMouseLeave={() => {
            if (!hovered) {
              setIsOpen(false);
            }
          }}
          comment={comment}
          updateComment={updateComment}
          deleteComment={deleteComment} />
        }
      </div>
    );
  };

export default DashboardScreenshotCustomMarker;
