import { useCallback } from "react";
import { TabList } from "@mui/lab";
import { Tab, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import {ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import {ReactComponent as FilesIcon } from "../../../assets/icons/document.svg";
import {ReactComponent as NotesIcon } from "../../../../public/svgs/edit.svg";
// import {ReactComponent as ScreenshotIcon } from "../../../assets/icons/screenshot.svg";
// import {ReactComponent as FolderIcon} from '../../../assets/icons/folder.svg';
import {ReactComponent as CallIcon} from '../../../assets/icons/call.svg';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import theme from "../../../theme";

export enum ViewModes {
    Overview = "overview",
    Files = "files",
    Emails = "emails",
    Notes = "notes",
    // Clipboard = "clipboard",
    Calls = "calls",
    // Others = "others",
}

const useStyles = makeStyles((theme) => ({
    tabs: {
        marginBottom: '8px !important',
        "& .MuiTabs-indicator": {
            background: theme.colors.primary['700']
        }
    },
    tab: {
        minWidth: 100,
        minHeight: 48,
        padding: '6px 8px',
    },
    tabIcon: {
        height: 24,
        width: 24,
        fill: theme.colors.neutral['600'],
    },
    selectedTabIcon: {
        fill: theme.colors.cyan['600'],
    },
    tabText: {
        color: '#878E95',
        textTransform: 'none',
    },
    selectedTabText: {
        color: 'black',
        fontWeight: 'bold',
    },
}));

const DashboardTabs: React.FC<{
    selectedMode: string,
    isPublic?: boolean,
    hasDeck?: boolean,
    onSelectMode: (mode: string) => void,
}> = ({selectedMode, isPublic, hasDeck, onSelectMode}) => {
    const classes = useStyles();

    const getIcon = useCallback((mode: ViewModes) => {
        const className = classnames(classes.tabIcon, selectedMode === mode && classes.selectedTabIcon);
        const callIconStyle = { fill: 'transparent',
            stroke: selectedMode === mode ? theme.colors.cyan['600'] : theme.colors.neutral['600'] };

        switch (mode) {
            case ViewModes.Overview:
                return <DashboardIcon className={className} />;
            case ViewModes.Files:
                return <FilesIcon className={className} />;
            case ViewModes.Emails:
                return <EmailIcon className={className} />;
            case ViewModes.Notes:
                return <NotesIcon className={className} />;
            // case ViewModes.Clipboard:
            //     return <ScreenshotIcon className={className} />;
            case ViewModes.Calls:
                return <CallIcon className={className} style={callIconStyle} />;
            // case ViewModes.Others:
            //     return <FolderIcon className={className} />;
            default:
                return (<></>);
        }
        // eslint-disable-next-line
    }, [selectedMode]);

    const getLabel = useCallback((mode: ViewModes) => {
        const className = classnames(classes.tabText, selectedMode === mode && classes.selectedTabText);

        return (<>
            <Typography className={className}>
                {mode.charAt(0).toUpperCase() + mode.slice(1)}
            </Typography>
        </>);
        // eslint-disable-next-line
    }, [selectedMode]);

    if (isPublic) {
        return (<>
            <TabList className={classes.tabs} onChange={(_, value) => onSelectMode(value)}>
                <Tab className={classnames(classes.tab)}
                    icon={getIcon(ViewModes.Overview)}
                    label={getLabel(ViewModes.Overview)}
                    iconPosition="start"
                    value={ViewModes.Overview} />
                {hasDeck && (
                    <Tab className={classnames(classes.tab)}
                        icon={getIcon(ViewModes.Files)}
                        label={getLabel(ViewModes.Files)}
                        iconPosition="start"
                        value={ViewModes.Files} />
                )}
            </TabList>
        </>);
    }

    return (<>
        <TabList className={classes.tabs} onChange={(_, value) => onSelectMode(value)}>
            {Object.values(ViewModes).map((mode, i) => (
                <Tab className={classnames(classes.tab)}
                    icon={getIcon(mode)}
                    label={getLabel(mode)}
                    iconPosition="start"
                    value={mode}
                    key={'dashboard-tabs-118-' + i}/>
            ))}
        </TabList>
    </>);
}

export default DashboardTabs;