import { Stack, Typography } from "@mui/material";
import AddSection from "../../atoms/AddSection";
import theme from "../../../theme";
import TeamQuery from "../../molecules/dashboard-queries/TeamQuery";
import KeyPeopleQuery from "../../molecules/dashboard-queries/KeyPeopleQuery";

export const Column = 'People';

const PeopleColumnQueries: React.FC<{}> = () => {
    return (<>
        <Stack spacing={2} alignItems="flex-start" width="25%">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                <Typography variant="b1-semibold" color={theme.colors.neutral['400']}>
                    {Column}
                </Typography>
                <AddSection column={Column} />
            </Stack>
            <TeamQuery />
            <KeyPeopleQuery />
        </Stack>
    </>);
}

export default PeopleColumnQueries;