import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { createReactEditorJS } from 'react-editor-js';
import classNames from 'classnames';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from "uuid";
import { createDashboardFunc, getDashboardQueriesFunc, refreshDashboardQueryFunc, updateDashboardFunc } from "../../../lib/helper";
import { getDashboards } from '../../../lib/helper';
import ArrayUtils from '../../../utils/ArrayUtils';
import { AuthContext } from '../../../contexts/AuthContext';
import SearchBar from '../../molecules/dashboard-notes/dialog/SearchBar';
import { Dashboard, DashboardNote, DashboardNoteType, DashboardQuery, RefreshDataEnum } from '../../../types/files';
import { EDITOR_JS_SANITIZE, EDITOR_JS_TOOLS } from '../../../helpers/editor-js';
import { getUserEmail } from '../../../helpers/authUser';
import ConfirmDialog from '../ConfirmDialog';
import { useSnackbar } from 'notistack';
import SelectionFieldModal, { NoteSelectionField } from '../../molecules/dashboard-notes/dialog/SelectionFieldModal';
import defaultDashboardQueries from "../../../helpers/defaultDashboardQueryTemplates.json";
import { AnswerQuestionResponseType, NoInfoYet, NoteMetric } from '../../../types/search';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import WhatsExtracted from '../../atoms/WhatsExtracted';

const edjsParser = require("editorjs-parser").default;
const parser = new edjsParser();
const ReactEditorJS = createReactEditorJS();

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: '60%',
            height: '95%',
            borderRadius: 32,
            boxShadow: 'none',
        }
    },
    dialogTitle: {
        color: '#000',
        fontWeight: 'bold',
        fontFamily: 'Poppins',
        fontSize: '1.2rem',
        padding: '24px 20px 20px 36px',
    },
    dialogContent: {
        height: '100%',
        padding: 0,
        overflowY: 'auto',
    },
    dialogContainer: {
        width: '85%',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
    },
    dialogDivider: {
        marginLeft: '24px',
        marginRight: '20px',
    },
    commonFields: {
        width: '220px',
        height: '36px',
        padding: 0,
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
        borderColor: 'lightgrey',
        fontSize: '0.95rem',
        fontWeight: 'bolder',
        textTransform: 'none',
        "&:hover": {
            borderColor: 'lightgrey',
        },
    },
    hiddenFields: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: 'rgba(0, 0, 0, 0.5)',
        "&:hover": {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    visibleFields: {
        backgroundColor: '#E0F1F1',
    },
    editorContainer: {
        height: 'auto',
        paddingTop: 2,
        paddingRight: 24,
        '& > div': {
        },
        '& .codex-editor': {
        },
        '& .codex-editor__redactor': {
            paddingBottom: '0px !important',
        },
        '& .ce-settings': {
            left: '30px',
        },
        '& .ce-toolbox': {
            left: '2px',
        },
    },
    userNoteHeader: {
        margin: "8px 0 0 30px",
        color: 'rgb(0, 0, 0, 0.3)',
        fontSize: '1rem',
        fontWeight: 'bolder',
        fontStyle: 'italic',
        fontFamily: 'Inter',
    },
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        marginRight: 10,
    },
    deleteButton: {
        marginRight: 10,
    },
}));

export interface NotesContent {
    type: string,
    data: {
        // type: paragraph | heading
        text?: string,
        // type: table
        content?: Array<Array<string>>, // row[column[]]
        withHeadings?: boolean,
        // type: list
        items?: Array<string>,
        style?: string, // ordered, unordered
        // type: code
        code?: string,
        // type: header
        level?: number
        // type: raw
        html?: string,
    }
}

const DashboardNoteDialog: React.FC<{
    isOpen: boolean,
    onClose: () => void,
    preselectedDashboard?: Dashboard,
    predefinedNote?: DashboardNote,
}> = ({ isOpen, onClose, preselectedDashboard, predefinedNote }) => {
    const classes = useStyles();
    const { userGroup, user } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    // eslint-disable-next-line
    const [isEditing, _] = useState<boolean>(true);
    const [dashboardEditorInstance, setDashboardEditorInstance] = useState<any>(null);
    const [dashboardNotesContent, setDashboardNotesContent] = useState<NotesContent[]>([]);
    const [dashboardNoteTitle, setDashboardNoteTitle] = useState<string>('');
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [, setError] = useState<string>("");
    const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null | undefined>();
    const [queries, setQueries] = useState<DashboardQuery[]>([]);
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [fields, setFields] = useState<Array<NoteSelectionField>>([]);

    const dashboardNotesEditorRef = useRef<any>(null);

    const QUERY_FIELDS: NoteSelectionField[] = Object.entries(defaultDashboardQueries as any)
        .filter(([key]) => ((defaultDashboardQueries as any)[key].visibleByDefault &&
            ((defaultDashboardQueries as any)[key].size !== 'L'
                || (defaultDashboardQueries as any)[key].source === 'CUSTOM'
                    || (defaultDashboardQueries as any)[key].referencesFreehandText === true)))
        .map(([_, value]) => ({ label: (value as { displayTitle: string }).displayTitle, selected: false, custom: false }) as NoteSelectionField)
        .map((field: NoteSelectionField) => (field.label.includes('List of questions') ? {...field, label: 'Questions'} : field));

    const getDisplayTitle = ((query: DashboardQuery) => (
        Object.values(defaultDashboardQueries as { [key: string]: { title: string, displayTitle?: string } })
            .find((dafault) => dafault?.title === query.title)?.displayTitle || query.title
    ));

    const formatHeaderData = (value: string) => ({ type: 'header', data: {level: 2,  text: value, },});

    const formatFieldData = (displayTitle: string, value: string) => ({ type: 'paragraph',
        data: { text: (`${(!!displayTitle ? "<b>" + displayTitle + ":</b>&nbsp;" : "") + "<span>" + value + "</span>"}`), },
    });

    const otherUserNote = useMemo(() => (
        !!predefinedNote && user.attributes.email !== predefinedNote?.createdBy
    ), [predefinedNote, user]);

    const handleInitializeEditor = useCallback(async (instance: any) => {
        await instance._editorJS.isReady;
        dashboardNotesEditorRef.current = instance;
        setDashboardEditorInstance(instance);
    }, []);

    const loadQueries = useCallback((dashboard: Dashboard) => {
        getDashboardQueriesFunc(dashboard.id).then(data => setQueries(!!data?.length ? data : []));
    }, []);

    const cleanUp = useCallback(() => {
        setQueries([]);
        setDashboardNoteTitle('');
        setDashboardNotesContent([]);
        setDashboardEditorInstance(null);
        setSelectedDashboard(undefined);
    }, []);

    const saveDashboardNotes = useCallback(async() => {
        if (!otherUserNote) {
            const savedData = await dashboardNotesEditorRef.current?.save();
            if (savedData) {
                const regex = /<b>([^<]+):<\/b>\s+/;
                const notesContent = savedData.blocks.map((block: NotesContent) => {
                    if (block.type === 'paragraph' && !!block.data.text) {
                        const match = block.data.text.match(regex);
                        if (match && match[1].length)
                            return formatFieldData(match[1], block.data.text.replace(regex, ''));
                    }

                    return {type: block.type, data: block.data,};
                });

                setDashboardNotesContent(notesContent);

                return notesContent;
            }
        }

        return dashboardNotesContent;
    }, [dashboardNotesContent, otherUserNote]);

    const copyNote = useCallback(() => {
        if (!selectedDashboard)
            return;

        saveDashboardNotes().then(async (results) => {
            const parsed = new DOMParser().parseFromString(parser.parse({blocks: [
                formatHeaderData(dashboardNoteTitle), ...results,
            ]}), 'text/html');

            parsed.querySelectorAll('h2')?.forEach((header) =>
                header?.setAttribute('class', `paragraph`)
            );
            parsed.querySelectorAll('a')?.forEach((link) =>
                link?.setAttribute('style', `color: #048290; text-decoration: none;`)
            );

            const copiedElement: HTMLDivElement = document.createElement("div");
            copiedElement.innerHTML = new XMLSerializer().serializeToString(parsed);
            (copiedElement.style as any).element = 0;
            document.body.appendChild(copiedElement);

            const block_level_elements = ['P','H1', 'H2', 'H3', 'H4', 'H5', 'H6','OL', 'UL','DIV','FORM','HR','TABLE'];
            Array.from(copiedElement.getElementsByTagName('*') as HTMLCollectionOf<HTMLElement>).forEach((element) => {
                const display = window.getComputedStyle(element).getPropertyValue('display');

                if (display.includes("inline") && block_level_elements.includes(element.tagName)) {
                    const defaultCSS = (document.defaultView as any).getComputedStyle(element, "").cssText;
                    element.outerHTML = element.outerHTML.replace(new RegExp(element.tagName, "ig"), "span");
                    element.style.cssText = defaultCSS;
                }
            });

            if (window.getSelection) {
                const selection = window.getSelection();
                if (selection) {
                    const range = document.createRange();
                    range.selectNodeContents(copiedElement);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }

            document.execCommand('copy');
            document.body.removeChild(copiedElement);
            window.getSelection()?.removeAllRanges();

            enqueueSnackbar(`Successfully copied to clipboard.`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
            });
        });
        // eslint-disable-next-line
    }, [dashboardNoteTitle, selectedDashboard]);

    const saveNote = useCallback((closeOnSave?: boolean) => {
        if (!selectedDashboard)
            return;

        setSaveLoading(true);
        saveDashboardNotes().then(async (results) => {
            const existingDashboard = dashboards.find(dashboard => dashboard.id === selectedDashboard.id);
            const noteData = JSON.stringify([formatHeaderData(dashboardNoteTitle), ...results,]);

            let newNote: DashboardNote = {
                id: uuidv4(),
                title: dashboardNoteTitle,
                data: noteData,
                type: DashboardNoteType.External,
                createdBy: getUserEmail(user),
                updatedBy: getUserEmail(user),
                createdAt: new Date(),
                updatedAt: new Date(),
            };
            let oldNotes: DashboardNote[] = selectedDashboard?.notes || [];
            let newNotes: DashboardNote[] = [];
            if (predefinedNote) {
                predefinedNote.title = newNote.title;
                predefinedNote.data = newNote.data;
            }
            if (predefinedNote && oldNotes.some(note => note.id === predefinedNote.id)) {
                newNotes = oldNotes.map(note => {
                    if (note.id === predefinedNote.id)
                        return newNote;
                    return note;
                }) || [];
            } else {
                newNotes = [...oldNotes, newNote];
            }

            const dashboardUpdate: Dashboard = {
                ...selectedDashboard!,
                notes: newNotes,
                refreshData: {
                    lastUpdatedBy: 'user',
                    shouldRefresh: true,
                    modifiedData: RefreshDataEnum.Notes,
                }
            };
            const updatedDashboards = dashboards.map((dashboard) => {
                if (dashboard.id === selectedDashboard.id) {
                    return dashboardUpdate;
                }
                return dashboard;
            });
            const isSuccess = !existingDashboard
                ? await createDashboardFunc(dashboardUpdate)
                    : await updateDashboardFunc(dashboardUpdate);

            if (isSuccess) {
                setDashboards(updatedDashboards);
                enqueueSnackbar(`Note successfully saved to ${dashboardUpdate.title}`, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
                });

                await Promise.all(
                    Object.values(defaultDashboardQueries)
                        .filter((val: any) => val.referencesFreehandText && val.isPrimaryQuery)
                        .map((val: any) => refreshDashboardQueryFunc(
                            dashboardUpdate.id,
                            val.title,
                            dashboardUpdate.group,
                            val.queryTemplate.replace('{dashboard_name}', dashboardUpdate.title),
                            val.order,
                            val.source
                        ))
                );
            } else {
                enqueueSnackbar(`Note not saved due to unknown error.`, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    }
                });
            }
            setSaveLoading(false);
            if (closeOnSave) {
                cleanUp();
                onClose();
            }
        });
        // eslint-disable-next-line
    }, [dashboardNoteTitle, dashboards, predefinedNote, selectedDashboard, user]);

    const deleteNote = useCallback(async () => {
        if (!selectedDashboard || !predefinedNote)
            return;

        setDeleteLoading(true);

        let newNotes: DashboardNote[] = selectedDashboard.notes
            ?.filter(note => note.id !== predefinedNote.id) || [];

        const dashboardUpdate: Dashboard = {
            ...selectedDashboard!,
            notes: newNotes,
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: true,
                modifiedData: RefreshDataEnum.Notes,
            }
        };
        const updatedDashboards = dashboards.map((dashboard) => {
            if (dashboard.id === selectedDashboard.id) {
                return dashboardUpdate;
            }
            return dashboard;
        });

        const isSuccess = await updateDashboardFunc(dashboardUpdate);

        if (isSuccess) {
            setDashboards(updatedDashboards);
            enqueueSnackbar(`Note successfully deleted from ${dashboardUpdate.title}`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
        } else {
            enqueueSnackbar(`Note not deleted due to unknown error.`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                }
            });
        }

        setDeleteLoading(false);
        setDeleteOpen(false);
        cleanUp();
        onClose();
        // eslint-disable-next-line
    }, [dashboards, predefinedNote, selectedDashboard]);

    useEffect(() => {
        if (isOpen) {
            setFields(QUERY_FIELDS);
            if (userGroup) {
                setLoading(true);
                getDashboards(userGroup).then(
                    (data: Dashboard[]) => {
                        setLoading(false);
                        // TODO: dashboard not sorted
                        setDashboards(data);
                    },
                    error => {
                        setError(error);
                        setLoading(false);
                    });
            }
            if (preselectedDashboard) {
                setSelectedDashboard(preselectedDashboard);
                loadQueries(preselectedDashboard);
            }
            if (predefinedNote) {
                const notes = JSON.parse(predefinedNote.data);
                if (Array.isArray(notes)) {
                    const [header, ...rest] = notes;
                    setDashboardNoteTitle((!!header && header.type === 'header') ? header.data.text: '');
                    setDashboardNotesContent(rest);
                } else {
                    let unifiedNotes = [];
                    if (!!notes?.dashboard) {
                        unifiedNotes.push(...notes?.dashboard);
                    }
                    if (!!notes?.user) {
                        unifiedNotes.push(...notes?.user);
                    }
                    setDashboardNoteTitle('');
                    setDashboardNotesContent(unifiedNotes);
                }
            } else {
                setDashboardNoteTitle('');
                setDashboardNotesContent([]);
            }
        }
        // eslint-disable-next-line
    }, [isOpen, preselectedDashboard, predefinedNote, userGroup]);

    useEffect(() => {
        const queryNotes: NotesContent[] = queries
            .filter((query) => fields.some((field) => (
                field.label === getDisplayTitle(query) || (field.label === 'Questions' && query.title === 'Questions')
            ) && field.selected))
            .reduce((notes: NotesContent[], query: DashboardQuery) => {
                const displayTitle = query.title === 'Questions' ? 'Questions' : getDisplayTitle(query);
                const answer = query.answer ? JSON.parse(query.answer) : null;

                if (Array.isArray(answer?.answer) && answer?.type === AnswerQuestionResponseType.URL_LIST)
                    answer.answer = ArrayUtils.sortByDateCastDescending(answer.answer, "date", new Date());

                if (!answer || query.isRefreshing) {
                    notes.push(formatFieldData(displayTitle, "<i>Loading...</i>"));
                } else {
                    switch (displayTitle) {
                        case 'Team': (() => {
                            const keyPeople = queries.find((query) => (getDisplayTitle(query) === 'Key People'));
                            let teamAnswer = answer?.answer;
                            let peopleList: Array<string> = [];

                            if (keyPeople) {
                                const keyPeopleAnswer = keyPeople.answer ? JSON.parse(keyPeople.answer) : null;

                                if (typeof keyPeopleAnswer?.answer === 'string') {
                                    teamAnswer = NoInfoYet.answer;
                                } else {
                                    keyPeopleAnswer?.answer?.forEach((element: any) => {
                                        peopleList.push(`<a href="${element.link}" target="_blank" rel="noopener noreferrer"><b>${element.title.split(' |')[0]}</b></a>${!!element.content ? `, ` + element.content : ``}`);
                                    });
                                }
                            }

                            notes.push(formatFieldData(displayTitle, teamAnswer));
                            peopleList.forEach((people) => notes.push(formatFieldData("", people)));
                        })(); break;
                        case 'Funding': (() => {
                            const fundingRounds = answer?.answer;
                            let fundingAnswer = '';
                            let fundingRoundsList: Array<string> = [];

                            if (!Array.isArray(fundingRounds) || !fundingRounds?.length) {
                                fundingAnswer = 'No funding data available.';
                            } else {
                                fundingRounds?.sort((curr: any, next: any) => (
                                    (!!curr.announced_date && !!next.announced_date) ? (moment({
                                        year: curr.announced_date.year,
                                        month: curr.announced_date.month - 1,
                                        day: curr.announced_date.day,
                                    }).isBefore(moment({
                                        year: next.announced_date.year,
                                        month: next.announced_date.month - 1,
                                        day: next.announced_date.day,
                                    })) ? 1 : 0) : 0
                                ))?.forEach((funding: any, index: number) => {
                                    const dateString = !!funding.announced_date ? (moment({
                                        year: funding.announced_date.year,
                                        month: funding.announced_date.month - 1,
                                        day: funding.announced_date.day,
                                    }).format('MMM D, YYYY')) : null;
                                    const fundingMoney = funding.money_raised ? `$${(funding.money_raised >= 1e6)
                                        ? (funding.money_raised / 1e6).toFixed(1) + 'M': (funding.money_raised >= 1e3)
                                            ? (funding.money_raised / 1e3).toFixed(1) + 'K': funding.money_raised.toString()}`
                                                .replace('.0', '') : '';
                                    const investorList = funding.investor_list?.map((investor: any) => investor.name)?.join(", ");
                                    const fundingDateType = `${funding.funding_type}${dateString ? ' - ' : ''}${dateString || ''}`;
                                    const fundingRoundAnswerStatic = (index: number) => ReactDOMServer.renderToStaticMarkup(
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'flex-start' }}>
                                            {index <= 1 && <span style={{ marginRight: '1em', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                {index === 0 ? "Latest round: " : (index === 1 && "Prior rounds: ")}
                                            </span>}
                                            {index > 1 && <span style={{ marginRight: '7.3em' }}></span>}
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
                                                {!!fundingMoney && <span style={{display: 'inline-block'}}>{fundingMoney}</span>}
                                                <span style={{display: !fundingMoney ? 'inline-block' : 'block', color: '#048290', fontWeight: '500'}}>{fundingDateType}</span>
                                                {!!investorList && <span style={{display: 'block'}}>{investorList}</span>}
                                            </div>
                                        </div>
                                    );

                                    fundingRoundsList.push(fundingRoundAnswerStatic(index));
                                });
                            }

                            notes.push(formatFieldData(displayTitle, fundingAnswer));
                            fundingRoundsList.forEach((funding) => notes.push({type: 'paragraph', data: { text: funding }}));
                        })(); break;
                        case 'Similar Companies': (() => {
                            const similarCompanies = answer?.answer;
                            let similarCompaniesAnswer = '';
                            let similarCompaniesList: string[] = [];

                            if (!Array.isArray(similarCompanies) || !similarCompanies?.length) {
                                similarCompaniesAnswer = 'No similar companies available.';
                            } else {
                                similarCompanies.forEach((company: any) => {
                                    similarCompaniesList.push(`
                                        <a href="${company.link}" target="_blank" rel="noopener noreferrer"><b>${company.title.trim()}</b></a>${!!company.content ? `, ` + company.content : ``}
                                        ${!!company.investors?.length && `<p style="color: #048290;">Funded by ${company.investors.join(", ")}</p>`}
                                    `);
                                });
                            }

                            notes.push(formatFieldData(displayTitle, similarCompaniesAnswer));
                            similarCompaniesList.forEach((company) => notes.push({type: 'paragraph', data: { text: company }}));
                        })(); break;
                        case 'Metrics': (() => {
                            const metrics = answer?.answer as NoteMetric;
                            let metricsAnswer = '';
                            let metricsList: string[] = [];

                            if (Object.values(metrics).every(metric => metric.value === null)) {
                                metricsAnswer = 'No metrics data available.';
                            } else {
                                Object.entries(metrics)
                                    .forEach(([metricName, metricValues]) => {
                                        if (metricValues.value !== null) {
                                            const date = !!metricValues.date ? moment(metricValues.date).format('MMM YYYY') : '';
                                            const cleanInput = String(metricValues.value!).replace(/[^0-9bmkt.$%]/gi, '').toLowerCase();
                                            let multiplier = 1;
                                            let displayValue = '0';

                                            if (cleanInput.includes('t'))
                                                multiplier = Math.pow(10, 12);
                                            else if (cleanInput.includes('b'))
                                                multiplier = Math.pow(10, 9);
                                            else if (cleanInput.includes('m'))
                                                multiplier = Math.pow(10, 6);
                                            else if (cleanInput.includes('k'))
                                                multiplier = 1000;

                                            const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.]/g, ''));

                                            if (metricValues.units === '%')
                                                displayValue = `${numericValue.toFixed(1)}%`;
                                            else if (metricValues.units === '$')
                                                displayValue = `$${numericValue.toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                            else
                                                displayValue = `${numericValue}`;

                                            metricsList.push(`<b>${metricName}:</b>&nbsp;<span>${displayValue}${!!date ? ` (${date})` : ''}</span>`);
                                        }
                                });
                            }

                            notes.push(formatFieldData(displayTitle, metricsAnswer));
                            metricsList.forEach((metric) => notes.push({type: 'paragraph', data: { text: metric }}));
                        })(); break;
                        case 'Possible Risks':
                        case 'Questions': (() => {
                            const introValidator = /(?::\s*1\.|:1\.|^1\.)/;
                            let paragraphs: Array<string> = [];

                            if ((answer?.answer as string).indexOf('\n') !== -1) {
                                const answers = (answer?.answer as string).replace(/\n+/g, "\n").split("\n");

                                if (/^(?:\d+\.\s*)/g.test(answers[0]))
                                    paragraphs.push('');
                                answers.forEach((answer) => paragraphs.push(answer));
                                paragraphs.forEach((paragraph, index) => notes.push(formatFieldData(!index ? displayTitle : "", paragraph)));
                            } else if (introValidator.test(answer?.answer as string)) {
                                const answers = (answer?.answer as string).split(introValidator);

                                paragraphs.push(`${answers[0] + (!!answers[0] ? ':' : '')}`);
                                if (!!answers[1]) {
                                    answers[1].split(/(?:^|\.)\s*\d+\./g).forEach((content, index) =>
                                        paragraphs.push(`${index + 1}.${content + (content.endsWith('.') ? '' : '.')}`)
                                    );
                                }
                                paragraphs.forEach((paragraph, index) => notes.push(formatFieldData(!index ? displayTitle : "", paragraph)));
                            } else {
                                notes.push(formatFieldData(displayTitle, answer?.answer));
                            }
                        })(); break;
                        default: (() => {
                            if (answer?.type === AnswerQuestionResponseType.URL)
                                notes.push(formatFieldData(displayTitle, `<a href="${answer?.answer}" target="_blank" rel="noopener noreferrer">${answer?.answer.replace(/^https?:\/\//, '')}</a>`));
                            else
                                notes.push(formatFieldData(displayTitle, answer?.answer || NoInfoYet.answer));
                        })(); break;
                    }
                }

                return notes;
            }, []);

        const fieldNotes: NotesContent[] = fields
            .filter((field) => !queries.map((query) => query.title === 'Questions' ? 'Questions' : getDisplayTitle(query)).includes(field.label) && field.selected)
            .map((field: NoteSelectionField) => formatFieldData(field.label, ""));

        if (!!queryNotes.length || !!fieldNotes.length) {
            saveDashboardNotes().then(async (results) => {
                const index = dashboardNotesEditorRef.current?._editorJS.blocks?.getCurrentBlockIndex();

                setDashboardNotesContent([
                    ...results.slice(0, index + 1),
                    ...queryNotes,
                    ...fieldNotes,
                    ...results.slice(index + 1),
                ]);
            });
        }
        // eslint-disable-next-line
    }, [fields, queries, preselectedDashboard]);

    useEffect(() => {
        if (dashboardEditorInstance && dashboardEditorInstance._editorJS) {
            (async () => {
                if (!!dashboardNotesContent.length)
                    await dashboardEditorInstance._editorJS?.blocks.render({blocks: dashboardNotesContent});
                await dashboardEditorInstance._editorJS?.caret.setToLastBlock('end');
            })();
        }
    }, [dashboardEditorInstance, dashboardNotesContent]);

    const ReactEditor = useCallback(() => {
        return (
            <ReactEditorJS
                holder="note-dialog"
                readOnly={!isEditing || otherUserNote}
                onInitialize={handleInitializeEditor}
                tools={EDITOR_JS_TOOLS}
                sanitizer={EDITOR_JS_SANITIZE}
                hideToolbar
            />
        );
    }, [isEditing, otherUserNote, handleInitializeEditor]);

    return createPortal(<>
        <Dialog className={classes.dialog}
            open={isOpen}
            onClose={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                cleanUp();
                onClose();
            }}>
                <DialogTitle className={classes.dialogTitle}>
                    <Stack spacing={3} direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                        <SearchBar
                            dashboards={dashboards}
                            preselectedDashboard={preselectedDashboard}
                            onSelectDashboard={async (d) => {
                                setSelectedDashboard(d);
                                loadQueries(d!);
                            }}
                            disabled={loading || saveLoading || !!preselectedDashboard}
                        />
                        <SelectionFieldModal
                            fields={fields}
                            defaultFields={QUERY_FIELDS}
                            onSave={(fields: Array<NoteSelectionField>) => setFields(fields)}
                            disabled={loading || saveLoading || otherUserNote}
                        />
                    </Stack>
                </DialogTitle>
                <Divider className={classes.dialogDivider} />
                <DialogContent id="notes-content" className={classes.dialogContent}>
                    <Container className={classes.dialogContainer}>
                        {isEditing && (
                            <TextField
                                autoComplete="off"
                                value={dashboardNoteTitle}
                                onChange={(e) => setDashboardNoteTitle(e.target.value)}
                                placeholder="Add title ..."
                                variant="standard"
                                disabled={otherUserNote}
                                InputProps={{ fullWidth: true, disableUnderline: true, }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.code === 'Enter')
                                        dashboardNotesEditorRef.current?._editorJS?.caret.focus();
                                }}
                                sx={{
                                    width: '100%',
                                    margin: '24px 0 8px 28px',
                                    "& .MuiInputBase-input": { fontSize: '1.5rem', fontWeight: 'bold' },
                                }}
                            />
                        )}
                        <Box className={classNames(classes.editorContainer, "large-grey-scrollbar-editor", "ce-custom-full-width")}>
                            <ReactEditor />
                        </Box>
                    </Container>
                </DialogContent>
                <Box ml={2} mb={1}>
                    <WhatsExtracted source="notes" iconAtStart />
                </Box>
                <Divider className={classes.dialogDivider} />
                <DialogActions className={classes.dialogActions}>
                    <LoadingButton
                        variant="contained"
                        className={classes.commonButton}
                        disabled={!selectedDashboard}
                        onClick={() => copyNote()}
                    > Copy text </LoadingButton>
                    <Stack direction="row" alignItems="center">
                        {!!predefinedNote && (
                            <IconButton
                                className={classes.deleteButton}
                                onClick={async () => setDeleteOpen(true)}
                                disabled={saveLoading || !predefinedNote || otherUserNote}
                            > <DeleteIcon /> </IconButton>
                        )}
                        <Button
                            variant="outlined"
                            className={classes.cancelButton}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                cleanUp();
                                onClose();
                            }}
                            disabled={saveLoading}
                        > Cancel </Button>
                        <LoadingButton
                            variant="contained"
                            className={classes.commonButton}
                            disabled={!selectedDashboard || otherUserNote}
                            loading={saveLoading}
                            onClick={() => saveNote(true)}
                        > Save </LoadingButton>
                    </Stack>
                </DialogActions>
        </Dialog>
        <ConfirmDialog
            title="Delete confirmation"
            content={'Are you sure you want to delete this note?'}
            open={deleteOpen}
            loading={deleteLoading}
            confirmCallback={() => deleteNote()}
            cancelCallback={() => setDeleteOpen(false)}
        />
    </>,
    document.body);
}

export default DashboardNoteDialog;

