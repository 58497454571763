import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import IntegrationIcons from "../../atoms/IntegrationIcons";
import { useContext, useState } from "react";
import DashboardTitleEditor from "./DashboardTitleEditor";
import { DashboardContext } from "../../../contexts/DashboardContext";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useDashboard from "../../../hooks/useDashboard";
import theme from "../../../theme";
import { AuthContext } from "../../../contexts/AuthContext";
import useRoute from "../../../hooks/useRoute";
import AddDataButton from "../../atoms/AddDataButton";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import StatusBadge from "../status/StatusBadge";
import CampainSection from "../../atoms/CampainSection";
import ShareDealButton from "../../atoms/ShareDealButton";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";

const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'Poppins',
        fontSize: '2.2rem',
        fontWeight: 'bolder',
    },
    link: {
        "&:hover": {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    next: {
        width: 14,
        height: 14,
    },
    chip: {
        color: '#D97706',
        backgroundColor: '#FEF3C7',
        height: 30,
        "&:hover": {
            cursor: 'pointer',
        }
    },
    signUpButton: {
        width: '100%',
        height: 40,
        borderRadius: 20,
        backgroundImage: 'linear-gradient(to right, #3ABDC6 0%, #6FEEF6  15%, #3ABDC6  50%, #6FEEF6  80%, #3ABDC6 100%)',
        backgroundSize: '105% auto',
        boxShadow: '2px 2px 4px #9CCED3, -1px -1px 3px #fff',
        color: 'white',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    breadcrumbs: {
        "& .MuiBreadcrumbs-separator, & a": {
            fontFamily: 'Inter',
            fontSize: '1em !important',
            color: theme.colors.cyan['500'],
            textDecoration: 'none !important',
            cursor: 'auto',
        },
        '& li:first-child a:hover': {
            textDecoration: 'underline !important',
            cursor: 'pointer',
        },
    }
}));

export const SignUpLink = 'https://form.typeform.com/to/xV9TrSLL?typeform-source=www.notissia.com';

export const DashboardTitle: React.FC<{ readonly?: boolean }> = ({ readonly }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { workspace } = useContext(DashboardsContext);
    const { dashboard, isPublicView } = useContext(DashboardContext);
    const { validateFrom, redirectFromDashboard } = useRoute();
    const { updateTitle } = useDashboard();
    const { dashboardWorkspaces } = useBackwardsCompatible();
    const [isEditable, setIsEditable] = useState(false);

    if (!dashboard)
        return (<></>);

    return (<>
        <Stack direction="row" justifyContent="space-between" width="100%" my={1}>
            <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center">
                {(validateFrom(dashboardWorkspaces(dashboard) || []) && (!isPublicView || !!workspace)) ? (
                    <Breadcrumbs className={classes.breadcrumbs}
                        separator={<ArrowForwardIosIcon className={classes.next} />}>
                            {[
                                <Link onClick={redirectFromDashboard}
                                    key="dashboard-title-88">{workspace?.name || 'All companies'}</Link>,
                                <Link key="dashboard-title-89">{dashboard.title}</Link>,
                            ]}
                    </Breadcrumbs>
                ) : (<Box mt={1} />)}
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    {isEditable ? (
                        <DashboardTitleEditor
                            title={dashboard.title}
                            variant="h4"
                            onUpdate={(title) => {
                                if (updateTitle(title)) {
                                    setIsEditable(false);
                                    return true;
                                }

                                return false;
                            }}
                            onCancel={() => {
                                setIsEditable(false);
                            }}
                            edit={!readonly}
                            readonly={readonly}
                        />
                    ) : (
                        <Typography variant="h3" className={classes.title}
                            onClick={() => setIsEditable(!readonly)}>{dashboard.title}</Typography>
                    )}
                    {!isPublicView && !!dashboard?.status && (
                        <StatusBadge title={dashboard.status || '<rename this status>'} />
                    )}
                </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="center">
                {isPublicView ? (!userGroup ? (<CampainSection />) : (
                    <Typography fontFamily="Inter" fontSize="1rem" fontWeight={600} color={theme.colors.cyan['400']}>
                        {'This dashboard is from an external user.'}
                    </Typography>
                )) : (
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                        <IntegrationIcons />
                        <AddDataButton dashboard={dashboard} />
                        <ShareDealButton dashboard={dashboard} />
                    </Stack>
                )}
            </Stack>
        </Stack>
    </>);
}
