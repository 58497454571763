import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';

const fetchData = async <T>(query: { name: string; statement: string }, params?: object, token: string | null = null): Promise<T[]> => {
  const { data } = (await API.graphql(
    graphqlOperation(query.statement, {
      ...params,
      limit: 1000, //over 1000 causes graphql error
      nextToken: token,
    }),
  )) as any;
  const { items, nextToken } = data[query.name];
  if (nextToken) {
    return [...items, ...(await fetchData(query, params, nextToken))];
  }
  return items;
};

const usePaginatedQuery = <T>(
  query: { name: string; statement: string },
  params?: object,
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [data, setData] = useState<T[]>();
  const [error, setError] = useState<Error>();
  const executeQuery = useCallback(() => {
    setLoading(true);
    fetchData<T>(query, params).then(setData).catch(setError).finally(
      () => {
        setLoading(false);
        setInitialLoading(false)
      });
  }, [query, params])

  useEffect(() => {
    executeQuery();
  }, [executeQuery]);

  return { loading, initialLoading: initialLoading ? loading : false, data, error, refetch: executeQuery };
};

export default usePaginatedQuery;
