import Box from '@mui/material/Box';

interface Props {
  hidden: boolean;
  children: React.ReactNode;
}

const TabPanel = ({ hidden, children }: Props) => (
  <Box height="100%" hidden={hidden} className={hidden ? "hidden-tab" : "active-tab"}>
    {children}
  </Box>
);

export default TabPanel;
