import React, { useCallback, useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ImageMarker, { Marker } from "react-image-marker";
import { HighlightArea } from "@react-pdf-viewer/highlight";
import awsConfig from "../../../aws-exports";
import { AuthContext } from "../../../contexts/AuthContext";
import { FileStructureContext } from "../../../contexts/FileStructureContext";
import { UsersContext } from "../../../contexts/UsersContext";
import { s3UrlVerify } from "../../../helpers/s3";
import { ExtendedComment, CommentOriginType, Comment } from "../../../types/comments";
import { User } from "../../../types/common";
import { DashboardUid, DashboardScreenshot } from "../../../types/files";
import DashboardScreenshotCustomMarker from "./DashboardScreenshotCustomMarker";
import AddCommentPane from "../../folders/components/file-preview/AddCommentPane";

const useStyles = makeStyles(() => ({
    hideScroll: {
        "&::-webkit-scrollbar-track": {
            background: "none"
        },
        "&::-webkit-scrollbar-thumb": {
            visibility: 'hidden',
        },
        "&:hover": {
            "&::-webkit-scrollbar-thumb": {
                visibility: "visible"
            }
        }
    }
}))

export const getImageElementId = (id: string) => `dashboard-screenshot-${id}`;

const DashboardScreenshotComponent: React.FC<{
    dashboardItem: DashboardUid,
    data: DashboardScreenshot,
    isSelected: boolean,
    comments: ExtendedComment[],
    onCommentAdded?: (item: Comment) => void,
    selectedCommentId: string | null | undefined,
    readonly?: boolean,
    adjustSizes?: boolean
    updateComment?: (c: Comment) => void
    deleteComment?: (id: string, parentId?: string) => void
}> = ({ dashboardItem, data, isSelected, comments, onCommentAdded, selectedCommentId, readonly, adjustSizes, updateComment, deleteComment }) => {
    const { fileStructure } = useContext(FileStructureContext);
    const [fileUrl, setFileUrl] = useState<string>("");
    const [mentionUsers, setMentionUsers] = useState<User[]>([]);
    const [markers, setMarkers] = useState<Marker[]>([]);
    const [selectedId, setSelectedId] = useState(selectedCommentId || '');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isMarkerActive, setIsMarkerActive] = useState<boolean>(false);
    const [markerPosition, setMarkerPosition] = useState<{ markerLeft: number, markerTop: number, left: number, top: number }>({ markerLeft: 0, markerTop: 0, left: 0, top: 0 });

    const { users, usersLoading } = useContext(UsersContext);
    const { userGroup } = useContext(AuthContext);

    useEffect(() => {
        if (!usersLoading) {
            const filteredUsers = users.filter(user => user.group === userGroup);
            setMentionUsers(filteredUsers);
        }
    }, [users, usersLoading, userGroup]);

    const getFileName = useCallback((): string => {
        if (!data || !fileStructure) {
            return "";
        }

        for (var file of fileStructure) {
            if (file.key === data.key) {
                return file.name;
            }
        }

        return "";
    }, [data, fileStructure]);

    const classes = useStyles();

    useEffect(() => {
        const fetchFileUrl = async () => {
            setFileUrl('');
            if (data && data.id) {
                const imageKey = data.id;
                // Locally we expect app_domain to be an empty string, so we'll fetch images directly from S3. In our running environments
                // we'll fetch images from CloudFront to take advantage of the CDN
                const fileUrl = awsConfig.app_domain ? `https://${awsConfig.app_domain}/${imageKey}` : (await s3UrlVerify({ key: imageKey }));
                setFileUrl(fileUrl);

                if (navigator.vendor === 'Google Inc.') {
                    // adjust image size to fit the container
                    let imageElem: HTMLElement | null = null;

                    if (document.getElementsByClassName("MuiDialog-container")?.length) {
                        imageElem = document.getElementsByClassName("MuiDialog-container")[0].getElementsByClassName(getImageElementId(data.id))[0] as HTMLElement;
                    }

                    if (!imageElem) {
                        imageElem = document.getElementsByClassName(getImageElementId(data.id))[0] as HTMLElement;
                    }

                    if (imageElem) {
                        imageElem.onload = (e) => {
                            const width = ((e?.target) as any).clientWidth;
                            const height = ((e?.target) as any).clientHeight;

                            if (!imageElem || !adjustSizes) {
                                return;
                            }
                            imageElem.style.width = '640px';
                            if (imageElem.offsetWidth > imageElem.offsetHeight) {
                                imageElem.style.height = '366px';
                            }

                            const container = imageElem.closest(".image-marker-container") as HTMLElement;
                            const containerParent = container.parentElement;

                            if (!containerParent) {
                                return;
                            }

                            const containerWidth = containerParent.clientWidth - 20;
                            const containerHeight = containerParent.clientHeight - 20;

                            const widthDiff = containerWidth - width;
                            const heightDiff = containerHeight - height;

                            if (widthDiff < 0 && heightDiff < 0) {
                                if (widthDiff < heightDiff) {
                                    imageElem.style.height = `${containerHeight}px`;
                                    imageElem.style.width = "fit-content";
                                } else {
                                    imageElem.style.width = `${containerWidth}px`;
                                    imageElem.style.height = `fit-content`;
                                }
                            } else if (widthDiff >= 0 && heightDiff >= 0) {
                                if (widthDiff < heightDiff) {
                                    imageElem.style.width = `${containerWidth}px`;
                                    imageElem.style.height = `fit-content`;
                                } else {
                                    imageElem.style.height = `${containerHeight}px`;
                                    imageElem.style.width = "fit-content";
                                }
                            } else if (widthDiff < 0) {
                                imageElem.style.width = `${containerWidth}px`;
                                imageElem.style.height = `fit-content`;
                            } else {
                                imageElem.style.height = `${containerHeight}px`;
                                imageElem.style.width = "fit-content";
                            }
                        }
                    }
                }
            }
        };
        fetchFileUrl();
    }, [data, adjustSizes]);

    useEffect(() => {
        if (comments) {
            computeMarkers();
        }

        // eslint-disable-next-line
    }, [comments]);

    useEffect(() => {
        if (selectedCommentId && comments.length) {
            setSelectedId(selectedCommentId);
        }
    }, [selectedCommentId, comments]);

    useEffect(() => {
        if (!isSelected) {
            setIsModalOpen(false);
            setIsMarkerActive(false);
        }
    }, [isSelected]);

    const computeMarkers = (newMarker?: Marker | undefined) => {
        let commentMarkers = comments.map(comment => ({ left: comment.commentedItem[0].left, top: comment.commentedItem[0].top }));

        if (newMarker) {
            setMarkers([...commentMarkers, newMarker]);
        } else {
            setMarkers([...commentMarkers]);
        }
    }

    const getHighlightAreaForMarker = (): HighlightArea => {
        return {
            pageIndex: 0,
            width: 1,
            height: 1,
            left: markerPosition.markerLeft,
            top: markerPosition.markerTop
        };
    };

    const addMarker = (marker: Marker) => {
        if (isSelected) {
            computeMarkers(marker);
            setIsModalOpen(true);
            setIsMarkerActive(false);
        }
    }

    const activateMarker = (markerLeft: number, markerTop: number, left: number, top: number) => {
        if (!isMarkerActive) {
            setSelectedId('');
            setIsMarkerActive(true);
            setMarkerPosition({ markerLeft, markerTop, left, top });
        }
    }

    return (
        <Stack alignItems="center" height="100%">
            <div className={classes.hideScroll} style={{ height: "100%", overflowY: 'auto' }}>
                <Stack
                    minHeight="256px"
                    overflow="hidden"
                    position="relative"
                    width="fit-content"
                    height="fit-content"
                    className="image-marker-container"
                    style={{ pointerEvents: readonly ? "none" : undefined }}
                    sx={{
                        transform: 'scale(0.98)',
                        border: '2px solid #F1F3F5',
                    }}
                >
                    <ImageMarker
                        src={fileUrl}
                        alt={`${getFileName()} - screenshot`}
                        extraClass={getImageElementId(data.id)}
                        markers={markers}
                        onAddMarker={onCommentAdded ? addMarker : undefined}
                        markerComponent={(props) => <DashboardScreenshotCustomMarker
                            isModalOpen={props.itemNumber === markers.length - 1 && isModalOpen && !isMarkerActive}
                            canOpenNew={!isMarkerActive}
                            selectedCommentId={selectedId}
                            setSelectedCommentId={setSelectedId}
                            onMarkerActive={(l1, t1, l2, t2) => {
                                setIsMarkerActive(false);
                                setIsModalOpen(false);
                                activateMarker(l1, t1, l2, t2);
                            }}
                            comment={comments[props.itemNumber as number]}
                            updateComment={updateComment}
                            deleteComment={deleteComment}
                            {...props}
                        />
                        }
                    >
                    </ImageMarker>
                    {
                        isMarkerActive && onCommentAdded &&
                        <AddCommentPane
                            documentId={data.id}
                            users={mentionUsers}
                            onAdded={(item) => { onCommentAdded(item); setIsModalOpen(false); setIsMarkerActive(false); }}
                            cancel={() => { setIsModalOpen(false); setIsMarkerActive(false); computeMarkers(); }}
                            triggerCancelAfterAdd={false}
                            forScreenshot
                            containerClass="image-marker"
                            highlightAreas={[{
                                pageIndex: 0,
                                width: 1,
                                height: 1,
                                left: markerPosition.markerLeft,
                                top: markerPosition.markerTop
                            }]}
                            selectedText=""
                            selectionData={{
                                endDivIndex: 0,
                                endOffset: 0,
                                endPageIndex: 0,
                                startDivIndex: 0,
                                startOffset: 0,
                                startPageIndex: 0
                            }}
                            selectionRegion={getHighlightAreaForMarker()}
                            commentOriginType={CommentOriginType.Dashboard}
                            dashboardItem={dashboardItem}
                            newEditor={true}
                        />
                    }
                </Stack>
            </div>
        </Stack>
    )
};

export default DashboardScreenshotComponent;