import { ReactElement } from "react";
import { GridAlignment, GridRowParams } from '@mui/x-data-grid';
import { Typography } from "@mui/material";
import theme from "../../../theme";

export const defineTextColumn = (field: string, label?: string, width?: number) => ({
    field: field,
    type: 'string',
    headerAlign: 'left' as GridAlignment,
    align: 'left' as GridAlignment,
    width: width ?? undefined,
    flex: !width ? 1 : undefined,
    editable: true,
    renderHeader: () => (
      <Typography
        fontFamily="Inter"
        fontWeight="bold"
        fontSize="1.1rem"
        color={theme.colors.neutral['900']}>
            {label}
        </Typography>
    ),
});

export const defineActionColumn = (field: string, handleAction: (params: GridRowParams<any>) => ReactElement<any>[]) => ({
    ...defineTextColumn(field),
    type: 'actions',
    align: 'right' as GridAlignment,
    editable: false,
    getActions: handleAction,
});
