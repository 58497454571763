import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Divider, Stack, Tab, Typography, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import classnames from "classnames";
import { getGroupDashboardQueriesFunc, refreshWorkspaceScoreFunc, updateInvestmentThesisFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { DashboardQuery, InvestmentThesis as InvestmentThesisType, Workspace } from "../../../types/files";
import {AuthContext} from "../../../contexts/AuthContext";
import {DashboardsContext, OverviewQueryTitles} from "../../../contexts/DashboardsContext";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import { DashboardQueriesContext } from "../../../contexts/DashboardQueriesContext";
import { FallbackLoading } from "../../templates/loader";
import useDashboards from "../../../hooks/useDashboards";
import Preferences from "../../molecules/workspaces/Preferences";
import AllMatches from "../../molecules/workspaces/AllMatches";
import SavedMatches from "../../molecules/workspaces/SavedMatches";
import useRoute from "../../../hooks/useRoute";

export enum InvestmentThesisTabs {
    AllCompanies = 'All companies',
    SavedCompanies = 'Saved companies',
}

const useStyles = makeStyles((theme) => ({
    matches: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    tabs: {
        "& .MuiTabs-indicator": {
            height: 3,
            background: theme.colors.primary['700'],
        }
    },
    tab: {
        minWidth: 150,
        minHeight: 48,
        padding: '6px 8px',
    },
    tabText: {
        color: '#878E95',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    selectedTabText: {
        color: 'black',
    },
    tabPanel: {
        height: '100%',
        width: '100%',
        padding: 'unset',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1.75rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    refreshButton: {
        width: 'auto',
        height: 36,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    updateButton: {
        width: 'auto',
        height: 36,
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const InvestmentThesis: React.FC<{
    workspace?: Workspace,
    collectionKey?: string,
    search?: string,
    modal?: boolean,
    onClose?: () => void,
}> = ({ workspace, collectionKey, search, modal, onClose }) => {
    const classes = useStyles();
     const { userGroup } = useContext(AuthContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { isPublicView, workspace: globalWorkspace, setWorkspace, dashboards, setDashboardsQueries } = useContext(DashboardsContext);
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { dashboardsLoaded } = useDashboards();
    const { getURLFrom } = useRoute();

    const [searching, setSearching] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<InvestmentThesisTabs>(InvestmentThesisTabs.AllCompanies);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const height = `calc(100% - ${modal ? 60 : 140}px)`;

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const getClassForText = useCallback((tab: InvestmentThesisTabs) =>
        (classnames(classes.tabText, selectedTab === tab && classes.selectedTabText))
        // eslint-disable-next-line
    , [selectedTab]);

    const handleSave = useCallback((investmentThesis: InvestmentThesisType) => {
        return new Promise<boolean>((resolve) => {
            if (!!localWorkspace) {
                updateInvestmentThesisFunc({
                    id: localWorkspace.id,
                    group: getURLFrom()?.originGroup || userGroup,
                    investmentThesis: investmentThesis,
                }).then((updatedWorkspace?: Workspace) => {
                    setWorkspaces(prev => prev.map(ws =>
                        ws.id === localWorkspace.id ? {...updatedWorkspace!} : ws
                    ));
                    setWorkspace({...updatedWorkspace!});
                }).finally(() =>  resolve(true));
            } else {
                resolve(false);
            }
        });
    // eslint-disable-next-line
    }, [getURLFrom, localWorkspace, userGroup]);

    const handleUpdate = useCallback((refresh?: boolean) => {
        if (!!localWorkspace) {
            const promises: Promise<any>[] = [];
            const queryDefaults = defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Investment Thesis');

            if (refresh)
                updateWorkspaceFunc(localWorkspace).then((updatedWorkspace?: Workspace) => setWorkspace({...updatedWorkspace!}));

            dashboards.forEach(dashboard => promises.push(
                refreshWorkspaceScoreFunc({
                    dashboardId: dashboard.id,
                    workspaceId: localWorkspace.id,
                    group: userGroup,
                    query: queryDefaults?.queryTemplate.replace('{dashboard_name}', dashboard.title) || '{dashboard_name}',
                })
            ));

            Promise.all(promises).then(() => {
                setSearching(true);
                setSelectedTab(InvestmentThesisTabs.AllCompanies);
            });
        }
    // eslint-disable-next-line
    }, [dashboards, userGroup, localWorkspace]);

    useEffect(() => {
        if (searching) {
            timerRef.current = setTimeout(() => {
                clearInterval(timerRef.current);
                getGroupDashboardQueriesFunc(userGroup).then((dashboardQueriesData) => {
                    setDashboardsQueries(dashboardQueriesData.filter((query: DashboardQuery) =>
                        OverviewQueryTitles.includes(query.title)
                    ));
                    setSearching(false);
                });
                timerRef.current = setTimeout(() => {
                    setSearching(false);
                    clearInterval(timerRef.current);
                }, 70000);
            }, 3000);
        }

        return () => {
            clearInterval(timerRef.current);
        }
    // eslint-disable-next-line
    }, [searching]);

    useEffect(() => {
        if (isPublicView)
            setSelectedTab(InvestmentThesisTabs.SavedCompanies);
    }, [isPublicView]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        {modal && (<>
            <Stack direction="column" pb={1}>
                <Typography className={classes.title}>
                    {workspace?.name}
                </Typography>
                <Divider sx={{ width: '100%' }} />
            </Stack>
        </>)}
        {!dashboardsLoaded ? (
            <FallbackLoading height={`calc(100vh - 250px)`} />
        ) : (
            <Stack direction="row" spacing={1} alignItems="stretch" width="100%" height={height}>
                <Preferences workspace={workspace} modal={modal} onSave={handleSave} onClose={onClose} />
                <Divider orientation="vertical" sx={{ height: '100%', marginTop: '-8px !important' }} />
                <Box className={classes.matches}>
                    <TabContext value={selectedTab}>
                        {!isPublicView && (<>
                            <Stack direction="row" justifyContent="space-between" width="100%">
                                <Stack direction="row" alignItems="center" justifyContent="flex-start">
                                    <TabList className={classes.tabs} onChange={(_, value) => setSelectedTab(value)}>
                                        <Tab className={classes.tab} value={InvestmentThesisTabs.AllCompanies} label={
                                            <Typography className={getClassForText(InvestmentThesisTabs.AllCompanies)}>
                                                {InvestmentThesisTabs.AllCompanies}
                                            </Typography>
                                        } />
                                        <Tab className={classes.tab} value={InvestmentThesisTabs.SavedCompanies} label={
                                            <Typography className={getClassForText(InvestmentThesisTabs.SavedCompanies)}>
                                                {InvestmentThesisTabs.SavedCompanies}
                                            </Typography>
                                        } />
                                    </TabList>
                                </Stack>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                                    <Button variant="contained"
                                        className={classes.updateButton}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            handleUpdate();
                                    }}> {`Generate top matches`} </Button>
                                </Stack>
                            </Stack>
                            <Divider sx={{ width: '100%' }} />
                        </>)}
                        {!isPublicView && (
                            <TabPanel className={classes.tabPanel} value={InvestmentThesisTabs.AllCompanies}>
                                <AllMatches
                                    workspace={workspace}
                                    collectionKey={collectionKey}
                                    search={search}
                                    searching={searching}
                                    modal={modal} />
                            </TabPanel>
                        )}
                        <TabPanel className={classes.tabPanel} value={InvestmentThesisTabs.SavedCompanies}
                            sx={{ paddingBottom: '40px !important' }}>
                            <SavedMatches workspace={workspace} search={search} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Stack>
        )}
    </>);
};

export default memo(InvestmentThesis);