import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from "notistack";
import { Dashboard, Workspace } from "../../../types/files";
import { addWorkspaceToDashboardFunc, sendShareWorkspaceEmailFunc, updateWorkspaceFunc } from "../../../lib/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import {DashboardContext} from "../../../contexts/DashboardContext";
import ArrayUtils from "../../../utils/ArrayUtils";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import BulkShareDeals from "../../atoms/home-bulk-share-deals";
import { FallbackLoading } from "../../templates/loader";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: 'fit-content',
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        padding: '8px 0',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainContent: {
        width: '45vw',
        height: 'max-content',
        maxHeight: '50vh',
        padding: 'unset',
        overflow: 'hidden',
    },
    scrollable: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
    },
    sharedIcon: {
        width: 40,
        height: 40,
        color: theme.palette.primary.main,
    },
    successText : {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    emailAdd: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    subText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['800'],
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    commonButton: {
        minWidth: 80,
        width: 'fit-content',
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const WorkspaceShareEmailModal: React.FC<{
    isOpen: boolean,
    dashboard?: Dashboard,
    workspace: Workspace,
    onClose: () => void,
}> = ({ isOpen, dashboard, workspace, onClose }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { user, userGroup } = useContext(AuthContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { dashboards } = useContext(DashboardsContext);
    const { dashboard: globalDashboard, setDashboard } = useContext(DashboardContext);
    const { workspace: globalWorkspace, setWorkspace, setDashboards } = useContext(DashboardsContext);
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [localWorkspace, setLocalWorkspace] = useState<Workspace|undefined>(undefined);
    const [shared, setShared] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const localDashboard = useMemo(() => dashboard ?? globalDashboard, [dashboard, globalDashboard]);

    const handleShareEmail = useCallback(async () => {
        const dashboardIds = !!localDashboard ? [localDashboard.id] :
            dashboards.filter(dashboard => dashboardWorkspaces(dashboard)?.includes(workspace.id))
                .map(dashboard => dashboard.id);

        if (!!localDashboard) {
            const dashboardData: Dashboard = await addWorkspaceToDashboardFunc({
                dashboardId: localDashboard.id, workspaceId: workspace.id, group: userGroup,
            });

            if (!!dashboardData) {
                // force update dashboards list
                const updatedDashboards = [...dashboards].map(current =>
                    (dashboardData.id === current.id) ? dashboardData : current
                );

                setDashboard(dashboardData);
                setDashboards(ArrayUtils.sortByDescending(updatedDashboards, 'createdAt'));
            }
        }

        await sendShareWorkspaceEmailFunc({
            workspaceId: workspace!.id,
            toAddress: localWorkspace!.email ?? workspace.email,
            replyToAddress: user.attributes.email,
            dashboardIds: dashboardIds,
        });
    // eslint-disable-next-line
    }, [localDashboard, dashboards, localWorkspace, user, userGroup, workspace]);

    const handleShare = useCallback(async () => {
        setLoading(true);
        if (!workspace!.isPublic) {
            updateWorkspaceFunc({...workspace!, isPublic: true })
                .then((updatedWorkspace?: Workspace) => {
                    if (!!updatedWorkspace?.id) {
                        if (workspace!.id === globalWorkspace?.id)
                            setWorkspace(updatedWorkspace);
                        setWorkspaces(prev => prev.map(ws =>
                            ws.id === updatedWorkspace?.id ? {...updatedWorkspace} : ws
                        ));
                        setDashboards(prev => prev.map(dashboard => ({...dashboard,
                            isPublic: dashboardWorkspaces(dashboard)?.includes(updatedWorkspace?.id) || dashboard.isPublic,
                        })));
                        handleShareEmail().then(() => setShared(true));
                        enqueueSnackbar('Workspace updated successfully', {
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right',
                            },
                            autoHideDuration: 4000,
                        });
                    }
                });
        } else {
            handleShareEmail().then(() => setShared(true));
        }
    // eslint-disable-next-line
    }, [workspace, globalWorkspace, handleShareEmail]);

    useEffect(() => {
        if (!localWorkspace?.email)
            setLocalWorkspace(structuredClone(workspace));
    // eslint-disable-next-line
    }, [workspace]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            {shared ? (<>
                <DialogContent className={classes.dialogTitle}>
                    <Stack spacing={2} alignItems="center" justifyContent="center" width="100%" py={3}>
                        <CheckCircleIcon className={classes.sharedIcon} />
                        <Typography className={classes.successText}>
                            {'Workspace successfully shared with:'}
                        </Typography>
                        <Typography className={classes.emailAdd}>
                            {localWorkspace?.email}
                        </Typography>
                    </Stack>
                </DialogContent>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogActions className={classes.dialogActions}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
                        <Button variant="contained"
                            className={classes.commonButton}
                            onClick={onClose}> {'Close'} </Button>
                    </Stack>
                </DialogActions>
            </>) : (<>
                <DialogTitle className={classes.dialogTitle}>
                    {`Share Deals`}
                </DialogTitle>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogContent className={classes.dialogContent}>
                    <Stack spacing={2} alignItems="flex-start" justifyContent="center">
                        <Typography className={classes.mainText}>
                            {`You are about to share the following deals with these investors:`}
                        </Typography>
                        <Typography className={classes.subText}>
                            {'The recepient(s) will receive an email where they will be able to access their shared workspace'}<br/>
                            {'using their email, edit their investment preferences, and view deals you share.'}<br/>
                        </Typography>
                        <Stack direction="column" className={classes.mainContent} alignItems="flex-start" justifyContent="stretch">
                            {!localWorkspace ? (<FallbackLoading />) : (
                                <Stack direction="column" className={classes.scrollable} alignItems="flex-start" justifyContent="stretch">
                                    <BulkShareDeals.AssignCards
                                        workspace={localWorkspace}
                                        dashboards={!!localDashboard ? [localDashboard]
                                            : dashboards.filter(dashboard => dashboardWorkspaces(dashboard).includes(workspace.id))}
                                        onWorkspace={setLocalWorkspace} />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </DialogContent>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogActions className={classes.dialogActions}>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                        <Button className={classes.cancelButton}
                            onClick={onClose}> {'Cancel'} </Button>
                        <LoadingButton variant="contained"
                            className={classes.commonButton}
                            loading={loading}
                            disabled={!localWorkspace?.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)}
                            onClick={handleShare}>
                                {'Send'}
                        </LoadingButton>
                    </Stack>
                </DialogActions>
            </>)}
        </Dialog>
    </>);
}
export default WorkspaceShareEmailModal;