/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addFile = /* GraphQL */ `mutation AddFile(
  $id: String!
  $group: String!
  $documentCategory: String
  $key: String!
  $name: String
  $displayPath: String
  $status: String!
  $dashboardId: String
  $marketMetrics: [MarketMetricInput]
  $isDeck: Boolean
) {
  addFile(
    id: $id
    group: $group
    documentCategory: $documentCategory
    key: $key
    name: $name
    displayPath: $displayPath
    status: $status
    dashboardId: $dashboardId
    marketMetrics: $marketMetrics
    isDeck: $isDeck
  ) {
    id
    documentCategory
    key
    displayPath
    status
    name
    group
    project
    dashboardId
    marketMetrics {
      page
      pageKey
      title
    }
    isDeck
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.AddFileMutationVariables,
  APITypes.AddFileMutation
>;
export const updateFile = /* GraphQL */ `mutation UpdateFile(
  $id: String!
  $group: String!
  $documentCategory: String
  $status: String!
  $dashboardId: String
  $marketMetrics: [MarketMetricInput]
  $isDeck: Boolean
) {
  updateFile(
    id: $id
    group: $group
    documentCategory: $documentCategory
    status: $status
    dashboardId: $dashboardId
    marketMetrics: $marketMetrics
    isDeck: $isDeck
  ) {
    id
    documentCategory
    key
    displayPath
    status
    name
    group
    project
    dashboardId
    marketMetrics {
      page
      pageKey
      title
    }
    isDeck
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateFileMutationVariables,
  APITypes.UpdateFileMutation
>;
export const deleteFile = /* GraphQL */ `mutation DeleteFile($id: String!, $group: String!) {
  deleteFile(id: $id, group: $group) {
    id
    documentCategory
    key
    displayPath
    status
    name
    group
    project
    dashboardId
    marketMetrics {
      page
      pageKey
      title
    }
    isDeck
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteFileMutationVariables,
  APITypes.DeleteFileMutation
>;
export const addUser = /* GraphQL */ `mutation AddUser(
  $email: String!
  $firstName: String
  $lastName: String
  $group: String!
  $username: String!
) {
  addUser(
    email: $email
    firstName: $firstName
    lastName: $lastName
    group: $group
    username: $username
  ) {
    email
    firstName
    lastName
    group
    project
    username
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.AddUserMutationVariables,
  APITypes.AddUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($email: String!, $group: String!) {
  updateUser(email: $email, group: $group) {
    email
    firstName
    lastName
    group
    project
    username
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser($email: String!, $username: String!, $group: String!) {
  deleteUser(email: $email, username: $username, group: $group) {
    email
    firstName
    lastName
    group
    project
    username
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const resendUserInvite = /* GraphQL */ `mutation ResendUserInvite($email: String!, $username: String!) {
  resendUserInvite(email: $email, username: $username)
}
` as GeneratedMutation<
  APITypes.ResendUserInviteMutationVariables,
  APITypes.ResendUserInviteMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $parentId: String
  $email: String
  $title: String
  $text: String
  $group: String!
  $dashboardId: String
  $documentId: String!
  $commentedItem: [HighlightAreaInput]
  $usersMentioned: [String]
  $originType: String
  $originUrl: String
  $description: String
) {
  createComment(
    parentId: $parentId
    email: $email
    title: $title
    text: $text
    group: $group
    dashboardId: $dashboardId
    documentId: $documentId
    commentedItem: $commentedItem
    usersMentioned: $usersMentioned
    originType: $originType
    originUrl: $originUrl
    description: $description
  ) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $id: ID!
  $usersMentioned: [String]
  $description: String
) {
  updateComment(
    id: $id
    usersMentioned: $usersMentioned
    description: $description
  ) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment($id: String!) {
  deleteComment(id: $id) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const createDashboard = /* GraphQL */ `mutation CreateDashboard(
  $id: ID!
  $email: String
  $title: String
  $group: String
  $documents: [DocumentInput]
  $selections: [SelectionInput]
  $screenshots: [ScreenshotInput]
  $notes: [NoteInput]
  $emailBodies: [EmailBodyInput]
  $summary: String
  $lastUpdatedBy: String
  $status: String
  $investmentStage: String
  $tags: [String]
  $shouldRefresh: Boolean
  $shouldSyncExternally: Boolean
  $workspaces: [ID]
  $externalLinks: [String]
  $refreshData: DashboardRefreshDataInput
) {
  createDashboard(
    id: $id
    email: $email
    title: $title
    group: $group
    documents: $documents
    selections: $selections
    screenshots: $screenshots
    notes: $notes
    emailBodies: $emailBodies
    summary: $summary
    lastUpdatedBy: $lastUpdatedBy
    status: $status
    investmentStage: $investmentStage
    tags: $tags
    shouldRefresh: $shouldRefresh
    shouldSyncExternally: $shouldSyncExternally
    workspaces: $workspaces
    externalLinks: $externalLinks
    refreshData: $refreshData
  ) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
  }
}
` as GeneratedMutation<
  APITypes.CreateDashboardMutationVariables,
  APITypes.CreateDashboardMutation
>;
export const deleteDashboard = /* GraphQL */ `mutation DeleteDashboard($id: String!) {
  deleteDashboard(id: $id) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
  }
}
` as GeneratedMutation<
  APITypes.DeleteDashboardMutationVariables,
  APITypes.DeleteDashboardMutation
>;
export const updateDashboard = /* GraphQL */ `mutation UpdateDashboard(
  $id: String!
  $title: String
  $documents: [DocumentInput]
  $selections: [SelectionInput]
  $screenshots: [ScreenshotInput]
  $notes: [NoteInput]
  $emailBodies: [EmailBodyInput]
  $isPublic: Boolean
  $summary: String
  $lastUpdatedBy: String
  $status: String
  $investmentStage: String
  $tags: [String]
  $shouldRefresh: Boolean
  $shouldSyncExternally: Boolean
  $workspaces: [ID]
  $externalLinks: [String]
  $refreshData: DashboardRefreshDataInput
  $group: String!
) {
  updateDashboard(
    id: $id
    title: $title
    documents: $documents
    selections: $selections
    screenshots: $screenshots
    notes: $notes
    emailBodies: $emailBodies
    isPublic: $isPublic
    summary: $summary
    lastUpdatedBy: $lastUpdatedBy
    status: $status
    investmentStage: $investmentStage
    tags: $tags
    shouldRefresh: $shouldRefresh
    shouldSyncExternally: $shouldSyncExternally
    workspaces: $workspaces
    externalLinks: $externalLinks
    refreshData: $refreshData
    group: $group
  ) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
  }
}
` as GeneratedMutation<
  APITypes.UpdateDashboardMutationVariables,
  APITypes.UpdateDashboardMutation
>;
export const addEmailBodyToDashboard = /* GraphQL */ `mutation AddEmailBodyToDashboard($id: ID!, $emailBody: EmailBodyInput!) {
  addEmailBodyToDashboard(id: $id, emailBody: $emailBody) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
  }
}
` as GeneratedMutation<
  APITypes.AddEmailBodyToDashboardMutationVariables,
  APITypes.AddEmailBodyToDashboardMutation
>;
export const createUploadAction = /* GraphQL */ `mutation CreateUploadAction(
  $uploadName: String
  $uploadType: String
  $group: String
  $uploadSource: String
) {
  createUploadAction(
    uploadName: $uploadName
    uploadType: $uploadType
    group: $group
    uploadSource: $uploadSource
  ) {
    id
    uploadName
    uploadType
    uploadSource
    group
    project
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateUploadActionMutationVariables,
  APITypes.CreateUploadActionMutation
>;
export const createMention = /* GraphQL */ `mutation CreateMention(
  $email: String
  $emailOfPersonWhoMentioned: String
  $commentId: String
  $originType: String
  $originUrl: String
  $group: String
) {
  createMention(
    email: $email
    emailOfPersonWhoMentioned: $emailOfPersonWhoMentioned
    commentId: $commentId
    originType: $originType
    originUrl: $originUrl
    group: $group
  ) {
    id
    email
    emailOfPersonWhoMentioned
    commentId
    originType
    originUrl
    group
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateMentionMutationVariables,
  APITypes.CreateMentionMutation
>;
export const deleteMention = /* GraphQL */ `mutation DeleteMention($id: String!) {
  deleteMention(id: $id) {
    id
    email
    emailOfPersonWhoMentioned
    commentId
    originType
    originUrl
    group
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteMentionMutationVariables,
  APITypes.DeleteMentionMutation
>;
export const updateMention = /* GraphQL */ `mutation UpdateMention(
  $id: String!
  $emailOfPersonWhoMentioned: String
  $commentId: String
) {
  updateMention(
    id: $id
    emailOfPersonWhoMentioned: $emailOfPersonWhoMentioned
    commentId: $commentId
  ) {
    id
    email
    emailOfPersonWhoMentioned
    commentId
    originType
    originUrl
    group
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateMentionMutationVariables,
  APITypes.UpdateMentionMutation
>;
export const addEmail = /* GraphQL */ `mutation AddEmail($id: String!, $email: String, $group: String!) {
  addEmail(id: $id, email: $email, group: $group) {
    id
    email
    project
    group
    isUploading
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.AddEmailMutationVariables,
  APITypes.AddEmailMutation
>;
export const updateEmail = /* GraphQL */ `mutation UpdateEmail($id: String!, $group: String!) {
  updateEmail(id: $id, group: $group) {
    id
    email
    project
    group
    isUploading
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmailMutationVariables,
  APITypes.UpdateEmailMutation
>;
export const createDocSendIngestion = /* GraphQL */ `mutation CreateDocSendIngestion(
  $url: String
  $email: String!
  $group: String!
  $newDashboardTitle: String
  $existingDashboardId: String
  $docsendIngestionId: String
  $tags: [String]
  $password: String
) {
  createDocSendIngestion(
    url: $url
    email: $email
    group: $group
    newDashboardTitle: $newDashboardTitle
    existingDashboardId: $existingDashboardId
    docsendIngestionId: $docsendIngestionId
    tags: $tags
    password: $password
  ) {
    url
    email
    group
    newDashboardTitle
  }
}
` as GeneratedMutation<
  APITypes.CreateDocSendIngestionMutationVariables,
  APITypes.CreateDocSendIngestionMutation
>;
export const createOauth2Creds = /* GraphQL */ `mutation CreateOauth2Creds(
  $group: String!
  $code: String!
  $integration: String!
  $redirectUri: String
) {
  createOauth2Creds(
    group: $group
    code: $code
    integration: $integration
    redirectUri: $redirectUri
  ) {
    drives {
      driveId
      driveName
    }
    groupDrives {
      groupId
      groupName
      driveId
      driveName
    }
    siteDrives {
      siteId
      siteName
      driveId
      driveName
    }
    namespaces {
      children {
        documentCategory
        id
        isDirectory
        name
        parentId
        path
        key
      }
    }
  }
}
` as GeneratedMutation<
  APITypes.CreateOauth2CredsMutationVariables,
  APITypes.CreateOauth2CredsMutation
>;
export const createIntegration = /* GraphQL */ `mutation CreateIntegration(
  $group: String!
  $integration: String!
  $externalConfig: String!
) {
  createIntegration(
    group: $group
    integration: $integration
    externalConfig: $externalConfig
  ) {
    group
    integration
    externalConfig
    status
    lastSyncAt
    lastSuccessfulSyncAt
    createdAt
    updatedAt
    syncInProgress
    syncWaiting
  }
}
` as GeneratedMutation<
  APITypes.CreateIntegrationMutationVariables,
  APITypes.CreateIntegrationMutation
>;
export const updateIntegration = /* GraphQL */ `mutation UpdateIntegration(
  $group: String!
  $integration: String!
  $externalConfig: String
  $status: String!
  $lastSyncAt: AWSDateTime
  $lastSuccessfulSyncAt: AWSDateTime
) {
  updateIntegration(
    group: $group
    integration: $integration
    externalConfig: $externalConfig
    status: $status
    lastSyncAt: $lastSyncAt
    lastSuccessfulSyncAt: $lastSuccessfulSyncAt
  ) {
    group
    integration
    externalConfig
    status
    lastSyncAt
    lastSuccessfulSyncAt
    createdAt
    updatedAt
    syncInProgress
    syncWaiting
  }
}
` as GeneratedMutation<
  APITypes.UpdateIntegrationMutationVariables,
  APITypes.UpdateIntegrationMutation
>;
export const acquireIntegrationLock = /* GraphQL */ `mutation AcquireIntegrationLock($group: String!, $integration: String!) {
  acquireIntegrationLock(group: $group, integration: $integration) {
    group
    integration
    externalConfig
    status
    lastSyncAt
    lastSuccessfulSyncAt
    createdAt
    updatedAt
    syncInProgress
    syncWaiting
  }
}
` as GeneratedMutation<
  APITypes.AcquireIntegrationLockMutationVariables,
  APITypes.AcquireIntegrationLockMutation
>;
export const releaseIntegrationLock = /* GraphQL */ `mutation ReleaseIntegrationLock($group: String!, $integration: String!) {
  releaseIntegrationLock(group: $group, integration: $integration) {
    group
    integration
    externalConfig
    status
    lastSyncAt
    lastSuccessfulSyncAt
    createdAt
    updatedAt
    syncInProgress
    syncWaiting
  }
}
` as GeneratedMutation<
  APITypes.ReleaseIntegrationLockMutationVariables,
  APITypes.ReleaseIntegrationLockMutation
>;
export const runFileSync = /* GraphQL */ `mutation RunFileSync($group: String!) {
  runFileSync(group: $group)
}
` as GeneratedMutation<
  APITypes.RunFileSyncMutationVariables,
  APITypes.RunFileSyncMutation
>;
export const createFileChunk = /* GraphQL */ `mutation CreateFileChunk(
  $id: ID!
  $group: String!
  $fileId: String!
  $text: String!
) {
  createFileChunk(id: $id, group: $group, fileId: $fileId, text: $text) {
    id
    searchGroup
    fileId
    text
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateFileChunkMutationVariables,
  APITypes.CreateFileChunkMutation
>;
export const batchDeleteFileChunk = /* GraphQL */ `mutation BatchDeleteFileChunk($ids: [ID!], $group: String!) {
  batchDeleteFileChunk(ids: $ids, group: $group) {
    id
    searchGroup
  }
}
` as GeneratedMutation<
  APITypes.BatchDeleteFileChunkMutationVariables,
  APITypes.BatchDeleteFileChunkMutation
>;
export const deleteFileChunks = /* GraphQL */ `mutation DeleteFileChunks($fileId: ID!, $group: String!) {
  deleteFileChunks(fileId: $fileId, group: $group)
}
` as GeneratedMutation<
  APITypes.DeleteFileChunksMutationVariables,
  APITypes.DeleteFileChunksMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup(
  $group: String!
  $statuses: [String]
  $investmentStages: [String]
  $tags: [String]
  $shouldSyncAllDashboardsExternally: Boolean
) {
  createGroup(
    group: $group
    statuses: $statuses
    investmentStages: $investmentStages
    tags: $tags
    shouldSyncAllDashboardsExternally: $shouldSyncAllDashboardsExternally
  ) {
    group
    statuses
    investmentStages
    tags
    workspaces {
      id
      name
      email
      website
      isPublic
      investmentThesis {
        focusArea {
          tags
        }
        stage {
          stages
        }
        geography {
          regions
        }
        additionalDetails {
          freeText
        }
      }
      createdAt
      updatedAt
    }
    shouldSyncAllDashboardsExternally
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateGroupSettings = /* GraphQL */ `mutation UpdateGroupSettings(
  $group: String!
  $statuses: [String]
  $investmentStages: [String]
  $tags: [String]
  $shouldSyncAllDashboardsExternally: Boolean
) {
  updateGroupSettings(
    group: $group
    statuses: $statuses
    investmentStages: $investmentStages
    tags: $tags
    shouldSyncAllDashboardsExternally: $shouldSyncAllDashboardsExternally
  ) {
    group
    statuses
    investmentStages
    tags
    workspaces {
      id
      name
      email
      website
      isPublic
      investmentThesis {
        focusArea {
          tags
        }
        stage {
          stages
        }
        geography {
          regions
        }
        additionalDetails {
          freeText
        }
      }
      createdAt
      updatedAt
    }
    shouldSyncAllDashboardsExternally
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupSettingsMutationVariables,
  APITypes.UpdateGroupSettingsMutation
>;
export const updateVectorMetadata = /* GraphQL */ `mutation UpdateVectorMetadata(
  $group: String!
  $fileId: String!
  $dashboardId: String!
) {
  updateVectorMetadata(
    group: $group
    fileId: $fileId
    dashboardId: $dashboardId
  )
}
` as GeneratedMutation<
  APITypes.UpdateVectorMetadataMutationVariables,
  APITypes.UpdateVectorMetadataMutation
>;
export const addDashboardQuery = /* GraphQL */ `mutation AddDashboardQuery(
  $dashboardId: ID!
  $title: String!
  $group: String
  $query: String
  $answer: AWSJSON
  $history: AWSJSON
  $order: Int!
  $columnOverride: Int
  $source: String
  $manualOverride: Boolean
  $allowOverwritingManualQuery: Boolean
  $deletedAt: AWSDateTime
) {
  addDashboardQuery(
    dashboardId: $dashboardId
    title: $title
    group: $group
    query: $query
    answer: $answer
    history: $history
    order: $order
    columnOverride: $columnOverride
    source: $source
    manualOverride: $manualOverride
    allowOverwritingManualQuery: $allowOverwritingManualQuery
    deletedAt: $deletedAt
  ) {
    dashboardId
    title
    group
    query
    answer
    order
    columnOverride
    manualOverride
    isRefreshing
    source
    history
    status
    tags
    updatedAt
    deletedAt
  }
}
` as GeneratedMutation<
  APITypes.AddDashboardQueryMutationVariables,
  APITypes.AddDashboardQueryMutation
>;
export const refreshDashboardQuery = /* GraphQL */ `mutation RefreshDashboardQuery(
  $dashboardId: ID!
  $title: String!
  $group: String!
  $order: Int!
  $source: String
  $query: String
) {
  refreshDashboardQuery(
    dashboardId: $dashboardId
    title: $title
    group: $group
    order: $order
    source: $source
    query: $query
  ) {
    dashboardId
    title
    group
    query
    answer
    order
    columnOverride
    manualOverride
    isRefreshing
    source
    history
    status
    tags
    updatedAt
    deletedAt
  }
}
` as GeneratedMutation<
  APITypes.RefreshDashboardQueryMutationVariables,
  APITypes.RefreshDashboardQueryMutation
>;
export const refreshWorkspaceScore = /* GraphQL */ `mutation RefreshWorkspaceScore(
  $dashboardId: ID!
  $workspaceId: ID!
  $group: String!
  $query: String
) {
  refreshWorkspaceScore(
    dashboardId: $dashboardId
    workspaceId: $workspaceId
    group: $group
    query: $query
  ) {
    dashboardId
    title
    group
    query
    answer
    order
    columnOverride
    manualOverride
    isRefreshing
    source
    history
    status
    tags
    updatedAt
    deletedAt
  }
}
` as GeneratedMutation<
  APITypes.RefreshWorkspaceScoreMutationVariables,
  APITypes.RefreshWorkspaceScoreMutation
>;
export const indicateDashboardQueryRefreshingChange = /* GraphQL */ `mutation IndicateDashboardQueryRefreshingChange(
  $dashboardId: ID!
  $title: String!
  $isRefreshing: Boolean!
) {
  indicateDashboardQueryRefreshingChange(
    dashboardId: $dashboardId
    title: $title
    isRefreshing: $isRefreshing
  ) {
    dashboardId
    title
    group
    query
    answer
    order
    columnOverride
    manualOverride
    isRefreshing
    source
    history
    status
    tags
    updatedAt
    deletedAt
  }
}
` as GeneratedMutation<
  APITypes.IndicateDashboardQueryRefreshingChangeMutationVariables,
  APITypes.IndicateDashboardQueryRefreshingChangeMutation
>;
export const deleteDashboardQuery = /* GraphQL */ `mutation DeleteDashboardQuery(
  $id: String!
  $title: String!
  $group: String!
  $onlyDeleteAutomated: Boolean
) {
  deleteDashboardQuery(
    id: $id
    title: $title
    group: $group
    onlyDeleteAutomated: $onlyDeleteAutomated
  ) {
    dashboardId
    title
    group
    query
    answer
    order
    columnOverride
    manualOverride
    isRefreshing
    source
    history
    status
    tags
    updatedAt
    deletedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteDashboardQueryMutationVariables,
  APITypes.DeleteDashboardQueryMutation
>;
export const addActivityFeedEvent = /* GraphQL */ `mutation AddActivityFeedEvent(
  $dashboardId: ID!
  $group: String!
  $eventType: String!
  $events: AWSJSON
  $eventAt: AWSDateTime!
) {
  addActivityFeedEvent(
    dashboardId: $dashboardId
    group: $group
    eventType: $eventType
    events: $events
    eventAt: $eventAt
  ) {
    id
    dashboardId
    group
    eventType
    events
    eventAt
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.AddActivityFeedEventMutationVariables,
  APITypes.AddActivityFeedEventMutation
>;
export const addLinkedin = /* GraphQL */ `mutation AddLinkedin($url: String!, $data: AWSJSON) {
  addLinkedin(url: $url, data: $data) {
    url
    data
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.AddLinkedinMutationVariables,
  APITypes.AddLinkedinMutation
>;
export const addSimilarCompany = /* GraphQL */ `mutation AddSimilarCompany(
  $displayName: String!
  $website: String
  $description: String
  $market: String
  $investors: [String]
  $product: String
  $linkedinUrl: String
  $location: String
  $headcount: String
  $founded: String
  $funding: AWSJSON
) {
  addSimilarCompany(
    displayName: $displayName
    website: $website
    description: $description
    market: $market
    investors: $investors
    product: $product
    linkedinUrl: $linkedinUrl
    location: $location
    headcount: $headcount
    founded: $founded
    funding: $funding
  ) {
    displayName
    website
    description
    market
    investors
    product
    linkedinUrl
    location
    headcount
    founded
    funding
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.AddSimilarCompanyMutationVariables,
  APITypes.AddSimilarCompanyMutation
>;
export const updateSimilarCompany = /* GraphQL */ `mutation UpdateSimilarCompany(
  $displayName: String!
  $website: String
  $description: String
  $market: String
  $investors: [String]
  $product: String
  $linkedinUrl: String
  $location: String
  $headcount: String
  $founded: String
  $funding: AWSJSON
) {
  updateSimilarCompany(
    displayName: $displayName
    website: $website
    description: $description
    market: $market
    investors: $investors
    product: $product
    linkedinUrl: $linkedinUrl
    location: $location
    headcount: $headcount
    founded: $founded
    funding: $funding
  ) {
    displayName
    website
    description
    market
    investors
    product
    linkedinUrl
    location
    headcount
    founded
    funding
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSimilarCompanyMutationVariables,
  APITypes.UpdateSimilarCompanyMutation
>;
export const upsertSubscription = /* GraphQL */ `mutation UpsertSubscription(
  $id: String!
  $group: String!
  $user: String!
  $status: String!
  $seatCount: Int!
  $pricePerSeatCents: Int!
  $planId: String!
  $planName: String!
  $paymentMethodId: String
  $startedAt: AWSDateTime
  $endedAt: AWSDateTime
  $currentPeriodStartedAt: AWSDateTime
  $currentPeriodEndedAt: AWSDateTime
  $trialStartedAt: AWSDateTime
  $trialEndedAt: AWSDateTime
  $cancelAt: AWSDateTime
) {
  upsertSubscription(
    id: $id
    group: $group
    user: $user
    status: $status
    seatCount: $seatCount
    pricePerSeatCents: $pricePerSeatCents
    planId: $planId
    planName: $planName
    paymentMethodId: $paymentMethodId
    startedAt: $startedAt
    endedAt: $endedAt
    currentPeriodStartedAt: $currentPeriodStartedAt
    currentPeriodEndedAt: $currentPeriodEndedAt
    trialStartedAt: $trialStartedAt
    trialEndedAt: $trialEndedAt
    cancelAt: $cancelAt
  ) {
    id
    group
    user
    status
    seatCount
    pricePerSeatCents
    planId
    planName
    paymentMethodId
    startedAt
    endedAt
    currentPeriodStartedAt
    currentPeriodEndedAt
    trialStartedAt
    trialEndedAt
    cancelAt
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpsertSubscriptionMutationVariables,
  APITypes.UpsertSubscriptionMutation
>;
export const incrementUsage = /* GraphQL */ `mutation IncrementUsage(
  $group: String!
  $dashboardsCreatedLimit: Int!
  $resetsAt: AWSDateTime!
) {
  incrementUsage(
    group: $group
    dashboardsCreatedLimit: $dashboardsCreatedLimit
    resetsAt: $resetsAt
  ) {
    group
    dashboardsCreated
    resetsAt
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.IncrementUsageMutationVariables,
  APITypes.IncrementUsageMutation
>;
export const createUnassignedContent = /* GraphQL */ `mutation CreateUnassignedContent(
  $group: String
  $type: String!
  $key: String
  $subject: String!
  $body: String!
) {
  createUnassignedContent(
    group: $group
    type: $type
    key: $key
    subject: $subject
    body: $body
  ) {
    id
    group
    type
    key
    subject
    body
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.CreateUnassignedContentMutationVariables,
  APITypes.CreateUnassignedContentMutation
>;
export const assignContentToDashboard = /* GraphQL */ `mutation AssignContentToDashboard(
  $unassignedContentId: ID!
  $dashboardId: ID!
) {
  assignContentToDashboard(
    unassignedContentId: $unassignedContentId
    dashboardId: $dashboardId
  ) {
    id
    group
    type
    key
    subject
    body
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.AssignContentToDashboardMutationVariables,
  APITypes.AssignContentToDashboardMutation
>;
export const deleteUnassignedContent = /* GraphQL */ `mutation DeleteUnassignedContent($id: ID!, $group: String!) {
  deleteUnassignedContent(id: $id, group: $group) {
    id
    group
    type
    key
    subject
    body
    createdAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteUnassignedContentMutationVariables,
  APITypes.DeleteUnassignedContentMutation
>;
export const createWorkspace = /* GraphQL */ `mutation CreateWorkspace(
  $name: String!
  $group: String
  $email: String
  $website: String
  $isPublic: Boolean
) {
  createWorkspace(
    name: $name
    group: $group
    email: $email
    website: $website
    isPublic: $isPublic
  ) {
    id
    name
    email
    website
    isPublic
    investmentThesis {
      focusArea {
        tags
      }
      stage {
        stages
      }
      geography {
        regions
      }
      additionalDetails {
        freeText
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateWorkspaceMutationVariables,
  APITypes.CreateWorkspaceMutation
>;
export const updateWorkspace = /* GraphQL */ `mutation UpdateWorkspace(
  $id: ID!
  $group: String
  $name: String
  $email: String
  $website: String
  $isPublic: Boolean
  $investmentThesis: InvestmentThesisInput
) {
  updateWorkspace(
    id: $id
    group: $group
    name: $name
    email: $email
    website: $website
    isPublic: $isPublic
    investmentThesis: $investmentThesis
  ) {
    id
    name
    email
    website
    isPublic
    investmentThesis {
      focusArea {
        tags
      }
      stage {
        stages
      }
      geography {
        regions
      }
      additionalDetails {
        freeText
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorkspaceMutationVariables,
  APITypes.UpdateWorkspaceMutation
>;
export const updateInvestmentThesis = /* GraphQL */ `mutation UpdateInvestmentThesis(
  $id: ID!
  $group: String!
  $investmentThesis: InvestmentThesisInput!
) {
  updateInvestmentThesis(
    id: $id
    group: $group
    investmentThesis: $investmentThesis
  ) {
    id
    name
    email
    website
    isPublic
    investmentThesis {
      focusArea {
        tags
      }
      stage {
        stages
      }
      geography {
        regions
      }
      additionalDetails {
        freeText
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvestmentThesisMutationVariables,
  APITypes.UpdateInvestmentThesisMutation
>;
export const deleteWorkspace = /* GraphQL */ `mutation DeleteWorkspace($id: ID!) {
  deleteWorkspace(id: $id) {
    id
    name
    email
    website
    isPublic
    investmentThesis {
      focusArea {
        tags
      }
      stage {
        stages
      }
      geography {
        regions
      }
      additionalDetails {
        freeText
      }
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteWorkspaceMutationVariables,
  APITypes.DeleteWorkspaceMutation
>;
export const addWorkspaceToDashboard = /* GraphQL */ `mutation AddWorkspaceToDashboard(
  $dashboardId: ID!
  $workspaceId: ID!
  $group: String
) {
  addWorkspaceToDashboard(
    dashboardId: $dashboardId
    workspaceId: $workspaceId
    group: $group
  ) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
  }
}
` as GeneratedMutation<
  APITypes.AddWorkspaceToDashboardMutationVariables,
  APITypes.AddWorkspaceToDashboardMutation
>;
export const removeWorkspaceFromDashboard = /* GraphQL */ `mutation RemoveWorkspaceFromDashboard(
  $dashboardId: ID!
  $workspaceId: ID!
  $group: String
) {
  removeWorkspaceFromDashboard(
    dashboardId: $dashboardId
    workspaceId: $workspaceId
    group: $group
  ) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
  }
}
` as GeneratedMutation<
  APITypes.RemoveWorkspaceFromDashboardMutationVariables,
  APITypes.RemoveWorkspaceFromDashboardMutation
>;
export const sendShareWorkspaceEmail = /* GraphQL */ `mutation SendShareWorkspaceEmail(
  $workspaceId: ID!
  $toAddress: String!
  $replyToAddress: String!
  $dashboardIds: [String]
) {
  sendShareWorkspaceEmail(
    workspaceId: $workspaceId
    toAddress: $toAddress
    replyToAddress: $replyToAddress
    dashboardIds: $dashboardIds
  )
}
` as GeneratedMutation<
  APITypes.SendShareWorkspaceEmailMutationVariables,
  APITypes.SendShareWorkspaceEmailMutation
>;
