import { useContext, useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import { CompanyFile } from '../../../types/files';
import DashboardCategoryAccordion from './DashboardCategoryAccordion';

const useStyles = makeStyles((theme) => ({
    categoriesContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    iconContainer: {
        width: 'calc(100% - 40px)',
        margin: '12px 10px 12px 20px',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        color: theme.colors.neutral["400"],
    },
    menuExpandButton: {
        position: 'absolute',
        top: '4px',
        right: '0'
    }
}));

interface Props {
    dashboardId: string;
    onFileSelected: (file: CompanyFile | null) => void;
    selectedFile: CompanyFile | null | undefined;
    isMenuExpanded: boolean;
    onExpandMenu: (isExpanded: boolean) => void;
    setSelectedCategory: (newCategory: string | undefined) => void;
}

const DashboardCategoriesNavigation = ({
    dashboardId,
    selectedFile,
    onFileSelected,
    isMenuExpanded,
    onExpandMenu,
    setSelectedCategory
}: Props) => {
    const classes = useStyles();
    const { fileStructure } = useContext(FileStructureContext);
    const [menuExpanded, setMenuExpanded] = useState<boolean>(false);
    const [groupedFiles, setGroupedFiles] = useState<any>({});
    const [expandedCategories, setExpandedCategories] = useState<string[]>([]);

    useEffect(() => {
        setMenuExpanded(isMenuExpanded);
    }, [isMenuExpanded]);

    useEffect(() => {
        const files = fileStructure.filter(x => !x.isDirectory && dashboardId === x.dashboardId);
        const grouped = files.reduce<{ [key: string]: CompanyFile[] }>((aggr, file) => {
            return {
                ...aggr,
                [file.documentCategory]: [...(aggr[file.documentCategory] || []), file]
            }
        }, {})
        setGroupedFiles(grouped);
        //eslint-disable-next-line
    }, [fileStructure]);

    useEffect(() => {
        const categories = Object.keys(groupedFiles).sort();
        let category: string | null = null;
        categories.forEach((c) => {
            if (!category && groupedFiles[c].find((f: CompanyFile) => f.name === selectedFile?.name)) {
                category = c;
            }
        });
        if (category) {
            setExpandedCategories([...expandedCategories, category]);
        }
        // eslint-disable-next-line
    }, [selectedFile]);

    const categories = Object.keys(groupedFiles).sort();

    return (
        <div className={classes.categoriesContainer}>
            {
                menuExpanded ?
                    categories?.map(
                        (category: string, i) => {
                            return <DashboardCategoryAccordion
                                category={category}
                                expanded={expandedCategories?.some(e => e === category)}
                                setExpanded={(newCategory: string) => {
                                    if (expandedCategories?.some(e => e === category)) {
                                        setExpandedCategories(expandedCategories.filter(e => e !== newCategory));
                                        setSelectedCategory(undefined);
                                    } else {
                                        setExpandedCategories([...expandedCategories, newCategory]);
                                        setSelectedCategory(newCategory);
                                    }
                                }}
                                files={groupedFiles[category]}
                                onFileSelect={onFileSelected}
                                selectedFile={selectedFile}
                                key={'dashboard-categories-navigation-108-' + i}
                            />
                        }
                    ) :
                    <Box className={classes.iconContainer}>
                        <FileCopyIcon className={classes.icon} />
                    </Box>
            }
            <IconButton
                className={classes.menuExpandButton}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onExpandMenu(!menuExpanded);
                }}
            >
                {menuExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
        </div>
    );
};

export default DashboardCategoriesNavigation;
