import React from "react";
import { NoInfoYet } from "../../../types/search";
import { Text, View } from '@react-pdf/renderer';
import { DashboardQuery } from "../../../types/files";
import styles from '../dashboard-export/styles';

const IntroValidator = /(?::\s*1\.|:1\.|^1\.)/;

const TextAnswer: React.FC<{ query: DashboardQuery }> = ({ query }) => {
    const answer = !!query.answer ? JSON.parse(query.answer) : null;
    const parsed = !!answer ? answer?.answer : null;
    let sentences: string[] = [];

    switch (typeof parsed) {
        case 'number':
                sentences = [String(parsed)];
                break;
        case 'string':
            if (parsed.indexOf('\n') !== -1) {
                const answers = parsed.replace(/\n+/g, '\n').split('\n');

                if (/^(?:\d+\.\s*)/g.test(answers[0]))
                    sentences.push('');
                answers.forEach((parsed: string) => sentences.push(parsed));
            } else if (IntroValidator.test(parsed as string)) {
                const answers = parsed.split(IntroValidator);

                sentences.push(`${answers[0] + (!!answers[0] ? ':' : '')}`);
                if (!!answers[1]) {
                    answers[1].split(/(?:^|\.)\s*\d+\./g).forEach((content, index) =>
                        sentences.push(`${index + 1}.${content + (content.endsWith('.') ? '' : '.')}`)
                    );
                }
            } else {
                sentences = [parsed];
            } break;
        default:
            sentences = [NoInfoYet.answer];
            break;
    }

    return (<>
        {!!sentences.length ? (
            sentences?.map((sentence, i) => (!!sentence && (
                <View key={'text-answer-43-' + i}>
                    <Text style={styles.sentence}>{sentence}</Text>
                    {(i < sentences.length - 1) && (<Text style={styles.sentence}>&nbsp;</Text>)}
                </View>
        )))) : (
            <Text style={styles.sentence}>{NoInfoYet.answer}</Text>
        )}
    </>);
}

export default TextAnswer;
