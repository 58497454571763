import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
    title?: string;
    content?: string;
    confirmCallback: any;
    cancelCallback: any;
    open: boolean;
    loading?: boolean;
}

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 'bold',
        paddingLeft: 0,
        paddingTop: 0,
        fontSize: 18,
        color: 'black'
    },
    text: {
        fontSize: 14,
        color: 'black'
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'center',
        gap: 32,
        marginBottom: 16
    },
    cancelButton: {
        borderRadius: 20,
        textTransform: 'none',
        fontWeight: 'bold',
        width: 110
    },
    redButton: {
        backgroundColor: '#DC2626',
        borderRadius: 20,
        textTransform: 'none',
        fontWeight: 'bold',
        width: 110
    }
}));

const ConfirmDialog = ({ title, content, confirmCallback, cancelCallback, open = false, loading }: Props) => {
    const handleCancel = () => {
        cancelCallback();
    };

    const confirm = () => {
        confirmCallback();
    };

    const classes = useStyles();

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            onBackdropClick={(e) => { e.preventDefault(); e.stopPropagation(); handleCancel(); }}
        >
            {content &&
                <DialogContent>
                    {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
                    <Typography className={classes.text} variant="body2">{content}</Typography>
                </DialogContent>}
            <DialogActions className={classes.buttonRow}>
                <Button className={classes.cancelButton} autoFocus variant="text" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleCancel(); }}>
                    Cancel
                </Button>
                {loading ?
                    <LoadingButton loading={loading} variant="contained" onClick={(e) => { e.preventDefault(); e.stopPropagation(); confirm(); }}>Confirm</LoadingButton> :
                    <Button className={classes.redButton} variant="contained" onClick={(e) => { e.preventDefault(); e.stopPropagation(); confirm(); }}>Delete</Button>}
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;