import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { API, graphqlOperation } from "aws-amplify";
import { AuthContext } from "../../../contexts/AuthContext";
import { FileStructureContext } from "../../../contexts/FileStructureContext";
import { onCommentsAll, onCommentsAllDeleted } from "../../../graphql/subscriptions";
import { preProcessTopCommentsForDashboard, processTopCommentsForDashboard } from "../../../helpers/processComments";
import { getDashboardCommentsFunc, updateDashboardFunc } from "../../../lib/helper";
import { getDashboardItemType, scrollbarStyle } from '../../../shared/dashboard';
import { ExtendedComment, } from "../../../types/comments";
import { CompanyFile, Dashboard, DashboardDocument, DashboardScreenshot, DashboardSelection, RefreshDataEnum } from "../../../types/files";
import ArrayUtils from "../../../utils/ArrayUtils";
import DashboardCategoriesLayout from "../../molecules/dashboard-category/DashboardCategoriesLayout";
import DashboardTags from "../../molecules/dashboard-tags/DashboardTags";
import { TagsPanel } from "../../molecules/dashboard-tags/TagsPanel";
import { Box, Stack } from "@mui/material";
import DashboardItemDetailsDialog from "../../modals/dashboard-details/DashboardItemDetailsDialog";
import { SaveDashboardItemType } from "../../../types/common";
import { DashboardContext } from "../../../contexts/DashboardContext";

const useStyles = makeStyles((theme) => ({
    panel: {
        transition: 'width .3s ease',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
        ...scrollbarStyle,
    },
}));

const DashboardFiles: React.FC<{
    selectedFile?: CompanyFile,
    onSetSelectedFile: (file?: CompanyFile) => void,
}> = ({ selectedFile,  onSetSelectedFile}) => {
    const classes = useStyles();
    const history = useHistory();
    const params: any = useParams();
    const { search } = useLocation();
    const { userGroup } = useContext(AuthContext);
    const { fileStructure, initialLoading } = useContext(FileStructureContext);
    const { dashboard, isPublicView: isPublic } = useContext(DashboardContext);

    const [dashboardUpToDate, setDashboardUpToDate] = useState<Dashboard>();
    const [items, setItems] = useState<(DashboardDocument | DashboardScreenshot | DashboardSelection)[]>([]);
    const [comments, setComments] = useState<ExtendedComment[]>([]);
    const [openItem, setOpenItem] = useState<DashboardDocument | DashboardScreenshot | DashboardSelection | undefined>();
    const [detailsDialogOpen, setDetailsDialogOpen] = useState<boolean>(false);
    const [selectedComment, setSelectedComment] = useState<ExtendedComment | undefined>();
    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [tagsExpanded, setTagsExpanded] = useState<boolean>(false);

    const commentSubscriptionRef = useRef<any>(null);
    const commentDeletedSubscriptionRef = useRef<any>(null);

    const dashboardFiles = fileStructure.filter(item => (
        !item.isDirectory && item.dashboardId && item.key !== selectedFile?.key
    ))

    const subscribeComments = useCallback(async (localComments: ExtendedComment[]) => {
        if (commentSubscriptionRef.current) {
            commentSubscriptionRef.current.unsubscribe();
        }

        if (userGroup && dashboard) {
            commentSubscriptionRef.current = await (API.graphql(
                graphqlOperation(
                    onCommentsAll, { group: userGroup }
                )) as any)
                .subscribe({
                    next: async ({ value }: any) => {
                        if (!value?.data?.onCommentsAll?.id) {
                            return;
                        }

                        const localDashboardItems = [...dashboard.selections?.map(x => x.id) || [], ...dashboard.screenshots?.map(x => x.id) || []];

                        if (!localDashboardItems.includes(value.data.onCommentsAll.documentId)) {
                            return;
                        }

                        if (localComments.find(x => x.id === value.data.onCommentsAll.id)) {
                            setComments(localComments.map(x => {
                                if (x.id === value.data.onCommentsAll.id) {
                                    return value.data.onCommentsAll;
                                }

                                return x;
                            }));
                        } else {
                            if (value.data.onCommentsAll?.parentId) {
                                setComments(localComments.map(x => {
                                    if (x.id === value.data.onCommentsAll.parentId) {
                                        if (!x.children) {
                                            x.children = [value.data.onCommentsAll];
                                        } else if (x.children.find(c => c.id === value.data.onCommentsAll.id)) {
                                            x.children = x.children.map(c => {
                                                if (c.id === value.data.onCommentsAll.id) {
                                                    return value.data.onCommentsAll;
                                                }

                                                return c;
                                            });
                                        } else {
                                            x.children.push(value.data.onCommentsAll);
                                        }
                                    }

                                    return x;
                                }));
                            } else {
                                if (!dashboard) {
                                    return;
                                }
                                const newComment = { ...value.data.onCommentsAll, lastUpdatedAt: value.data.onCommentsAll.createdAt } as ExtendedComment;
                                const ascendingComments = ArrayUtils.sortByAscending([{ ...newComment }, ...localComments], "lastUpdatedAt");
                                const processedComments = processTopCommentsForDashboard(ascendingComments, dashboard);
                                setComments(ArrayUtils.sortByDescending(processedComments, "lastUpdatedAt"));
                            }
                        }
                    }
                });
        }
    }, [userGroup, dashboard]);

    const subscribeCommentDeleted = useCallback(async (localComments: ExtendedComment[]) => {
        if (commentDeletedSubscriptionRef.current) {
            commentDeletedSubscriptionRef.current.unsubscribe();
        }

        if (userGroup && dashboard) {
            commentDeletedSubscriptionRef.current = await (API.graphql(
                graphqlOperation(
                    onCommentsAllDeleted, { group: userGroup }
                )) as any)
                .subscribe({
                    next: async ({ value }: any) => {
                        if (!value?.data?.onCommentsAllDeleted?.id) {
                            return;
                        }

                        const localDashboardItems = [...dashboard.selections?.map(x => x.id) || [], ...dashboard.screenshots?.map(x => x.id) || []];

                        if (!localDashboardItems.includes(value.data.onCommentsAllDeleted.documentId)) {
                            return;
                        }

                        if (value.data.onCommentsAllDeleted.parentId) {
                            setComments(localComments.map(x => {
                                if (x.id === value.data.onCommentsAllDeleted.parentId && x.children) {
                                    x.children = x.children.filter(c => c.id !== value.data.onCommentsAllDeleted.id);
                                }

                                return x;
                            }));
                        } else {
                            setComments(localComments.filter(x => x.id !== value.data.onCommentsAllDeleted.id));
                        }
                    }
                });
        }
    }, [userGroup, dashboard]);

    const onNext = () => {
        if (!openItem) {
            return;
        }

        const oldOpenIndex = items.findIndex(x => x === openItem);
        if (oldOpenIndex === items.length - 1) {
            const newItem = items[0];
            setOpenItem(newItem);
        } else {
            const newItem = items[oldOpenIndex + 1];
            setOpenItem(newItem);
        }
    }

    const onBack = () => {
        if (!openItem) {
            return;
        }

        const oldOpenIndex = items.findIndex(x => x === openItem);
        if (oldOpenIndex === 0) {
            const newItem = items[oldOpenIndex + 1];
            setOpenItem(newItem);
        } else {
            const newItem = items[oldOpenIndex - 1];
            setOpenItem(newItem);
        }
    }

    const updateItem = (item: DashboardDocument | DashboardScreenshot | DashboardSelection) => {
        if (!dashboard)
            return;

        const newDashboard = {
            ...dashboardUpToDate!,
            updatedAt: (new Date()).toISOString(),
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: false,
                modifiedData: RefreshDataEnum.Items
            }
        } as Dashboard;

        const itemType = getDashboardItemType(item);
        if (itemType === SaveDashboardItemType.Page && newDashboard.documents) {
            newDashboard.documents = newDashboard.documents?.map(element => {
                if (element.id === item.id) {
                    return item as DashboardDocument;
                }
                return element;
            });
        } else if (itemType === SaveDashboardItemType.Text && newDashboard.selections) {
            newDashboard.selections = newDashboard.selections?.map(element => {
                if (element.id === item.id) {
                    return item as DashboardSelection;
                }
                return element;
            });
        } else if (newDashboard.screenshots) {
            newDashboard.screenshots = newDashboard.screenshots?.map(element => {
                if (element.id === item.id) {
                    return item;
                }
                return element;
            });
        }

        updateDashboardFunc(newDashboard).then(() => setDashboardUpToDate({ ...newDashboard }));
    }

    useEffect(() => {
        const viewMode = params.viewMode;
        const fileId = params.fileId ?? '';
        const urlFile = fileStructure.find(fS => fS.id === fileId);
        const urlPage = (new URLSearchParams(search)).get('page');

        if (viewMode === 'files' && !!urlFile) {
            onSetSelectedFile(urlFile);
            // page numbering starts from 0
            setSelectedPage(Number(urlPage || '1') - 1);
        }

        return () => {
            onSetSelectedFile(isPublic ? selectedFile : undefined);
            setSelectedPage(0);
        }
        //eslint-disable-next-line
    }, [params.dashboardId, params.viewMode, params.fileId, isPublic, fileStructure]);

    useEffect(() => {
        let newItems: any[] = [];

        if (dashboard) {
            if (dashboard.documents && dashboard.documents.length) {
                newItems = [...newItems, ...dashboard.documents];
            }
            if (dashboard.screenshots && dashboard.screenshots.length) {
                newItems = [...newItems, ...dashboard.screenshots];
            }
            if (dashboard.selections && dashboard.selections.length) {
                newItems = [...newItems, ...dashboard.selections];
            }

            newItems = newItems.sort((a, b) => (a === null) ? 1 : (b === null) ? -1 : a.order - b.order);
            newItems.map((el, index) => {
                if (el.order === null) {
                    el.order = index;
                }
                return el;
            });

            getDashboardCommentsFunc(dashboard.id).then(results => {
                const preProcessedComments = preProcessTopCommentsForDashboard(results);
                const ascendingComments = ArrayUtils.sortByAscending(preProcessedComments, "lastUpdatedAt");
                const processedComments = processTopCommentsForDashboard(ascendingComments, dashboard);
                setComments(ArrayUtils.sortByDescending(processedComments, "lastUpdatedAt"));
            });
            setItems(newItems);
            if (openItem) {
                const newOpenItem = newItems.find(e => e.id === openItem.id);
                setOpenItem(newOpenItem);
            }
        }
        // eslint-disable-next-line
    }, [dashboard]);

    useEffect(() => {
        subscribeComments(comments);
        subscribeCommentDeleted(comments);

        return () => {
            commentSubscriptionRef?.current?.unsubscribe();
            commentDeletedSubscriptionRef?.current?.unsubscribe();
        }
    }, [comments, subscribeComments, subscribeCommentDeleted]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" height="100%">
            <Box className={classes.panel} sx={{ width: tagsExpanded ? '75%' : 'calc(100% - 64px)' }}>
                <DashboardCategoriesLayout
                    dashboardId={dashboard.id}
                    selectedComment={selectedComment}
                    selectedFile={selectedFile}
                    selectedFolder={selectedFile}
                    selectedPage={selectedPage}
                    loading={initialLoading}
                    isPublic={isPublic}
                    onSelectFile={(file: CompanyFile | null) => {
                        if (file) {
                            history.push('/dashboards/' +dashboard!.id +'/files/' + file.id);
                            onSetSelectedFile(file);
                        }
                    }}
                    onSelectFolder={() => onSetSelectedFile(undefined)}
                />
            </Box>
            {isPublic ? (<Box width={100} />) : (
                <TagsPanel tagsExpanded={tagsExpanded}
                    onClick={() => setTagsExpanded(!tagsExpanded)}>
                    <DashboardTags
                        dashboardId={dashboard?.id}
                        dashboardItems={items}
                        comments={comments}
                        highlightedComments={[]}
                        setHighlightedComments={() => {}}
                        selectedFile={selectedFile}
                        otherFiles={dashboardFiles}
                        onSelectComment={(comment?: ExtendedComment, dashboardElement?: DashboardDocument | DashboardScreenshot | DashboardSelection | undefined) => {
                            if (!comment) {
                                setSelectedComment(undefined);
                                return;
                            }

                            if (comment.dashboardId) {
                                onSetSelectedFile(undefined);
                                setOpenItem(dashboardElement);
                                setDetailsDialogOpen(true);
                            } else {
                                if (comment.documentId !== selectedFile?.key) {
                                    const newSelectedFile = dashboardFiles.find(f => f.key === comment.documentId);
                                    if (newSelectedFile) {
                                        setTimeout(() => {
                                            onSetSelectedFile(newSelectedFile);
                                        }, 100)
                                    }
                                }
                            }
                            setSelectedComment(comment);
                        }}
                        onReplyAdded={(reply: ExtendedComment) => {
                            setComments(comments.map((comment: ExtendedComment) => {
                                if (comment.id === reply.parentId) {
                                    if (!comment.children) {
                                        comment.children = [reply];
                                    } else {
                                        comment.children.push(reply);
                                    }
                                }

                                return comment;
                            }));
                        }}
                    />
                </TagsPanel>
            )}

        </Stack>
        {openItem && (
            <DashboardItemDetailsDialog
                externalComments={comments.filter(e => e.documentId === openItem.id)}
                dashboard={dashboard!}
                item={openItem}
                itemIndex={items.findIndex(x => x === openItem)}
                itemsCount={items.length}
                isOpen={detailsDialogOpen}
                onSummaryUpdated={updateItem}
                onItemTitleUpdated={updateItem}
                onClose={() => {
                    setDetailsDialogOpen(false);
                    setOpenItem(undefined);
                    setSelectedComment(undefined);
                }}
                files={fileStructure}
                onNext={onNext}
                onBack={onBack}
                preselectedCommentId={selectedComment?.id}
            />
        )}
    </>);
}

export default DashboardFiles;