import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Stack, Typography } from "@mui/material";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import InvestorsTableRow from "../../molecules/investors/InvestorsTableRow";
import InvestorsTableHeader from "../../molecules/investors/InvestorsTableHeader";
import { Dashboard, Workspace } from "../../../types/files";
import { getUniqueElements } from "../../../utils/getUniqueElements";
import ArrayUtils from "../../../utils/ArrayUtils";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import InvestorsTableFilter from "../../molecules/investors/InvestorsTableFilter";

const useStyles = makeStyles(() => ({
    table: {
        width: '100%',
        height: 'calc(100vh - 140px)',
        overflow: 'hidden',
    },
    rows: {
        height: '100%',
        overflowY: 'auto',
    },
}));

const InvestorsTable: React.FC<{
    dashboard?: Dashboard,
    collectionKey?: string,
    search: string,
    forSharing?: boolean,
    noMailing?: boolean,
    withFilter?: boolean,
}> = ({ dashboard, collectionKey, search, forSharing, noMailing, withFilter }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
    const { mappedOverviewQueries } = useContext(DashboardsContext);

    const [ investors, setInvestors] = useState<Workspace[]>([]);
    const [sector, setSector] = useState<string[]>([]);
    const [stage, setStage] = useState<string[]>([]);
    const [geography, setGeography] = useState<string[]>([]);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const workspaceScoreMap: Map<Workspace, number> = useMemo(() => new Map(
        workspaces.map(workspace => {
            const investmentThesis: { workspaceId: string, score: number}[]
                = mappedOverviewQueries.get(`${dashboard?.id}:Investment Thesis`) || [];
            const score = investmentThesis?.find(iT => iT.workspaceId === workspace!.id)?.score ?? 0;

            return [workspace, score];
        }))
    , [dashboard, mappedOverviewQueries, workspaces]);

    const searchWorkspaces = useMemo(() => {
        const searchText = search.trim().toLowerCase();
        const keywords = searchText.split(',').map(token => token.trim());
        let filtered: Workspace[] = Array.from(workspaceScoreMap)
            .sort((a, b) => a[0].name.localeCompare(b[0].name))
            .sort((a, b) => b[1] - a[1]).map(entry => entry[0]);

        if (keywords.some(keyword => keyword.length > 0)) {
            if (keywords.length === 1) {
                // if keyword is only one, search in name, email, and website
                filtered = filtered.filter(workspace =>
                    workspace.name?.toLowerCase().includes(searchText) ||
                    workspace.email?.toLowerCase().includes(searchText) ||
                    workspace.website?.toLowerCase().includes(searchText)
                );
            } else {
                // match keywords in sector, stage, geography
                filtered = filtered.filter(workspace => {
                    const tags = workspace.investmentThesis?.focusArea?.tags?.map(sector =>sector.toLowerCase()) || [];
                    const stages = workspace.investmentThesis?.stage?.stages?.map(stage =>stage.toLowerCase()) || [];
                    const regions = workspace.investmentThesis?.geography?.regions?.map(geo =>geo.toLowerCase()) || [];

                    return keywords.map(key =>
                        [...tags, ...stages, ...regions].map(word => word.includes(key) ? key : null)
                    ).flat().filter(getUniqueElements).filter(Boolean)[0];
                });
            }
        } else if (!dashboard) {
            filtered = ArrayUtils.sortByAscending([...workspaces], 'createdAt');
        }

        return filtered;
    }, [dashboard, search, workspaceScoreMap, workspaces]);

    const filteredWorkspaces = useMemo(() => {
        let filtered: Workspace[] = searchWorkspaces;

        // filter by sector
        if (!!sector.length){
            filtered = filtered.filter(workspace => {
                const tags = workspace.investmentThesis?.focusArea?.tags || [];

                return sector.every(key => tags.includes(key));
            });
        }

        // filter by stage
        if (!!stage.length){
            filtered = filtered.filter(workspace => {
                const stages = workspace.investmentThesis?.stage?.stages || [];

                return stage.every(key => stages.includes(key));
            });
        }

        // filter by geography
        if (!!geography.length){
            filtered = filtered.filter(workspace => {
                const regions = workspace.investmentThesis?.geography?.regions || [];

                return geography.every(key => regions.includes(key));
            });
        }

        return filtered;
    }, [searchWorkspaces, sector, stage, geography]);

    const handleFilter = useCallback(({ focusArea, stage, geography } : {
        focusArea: string[], stage: string[], geography: string[],
    }) => { setSector(focusArea); setStage(stage); setGeography(geography); }, []);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setInvestors(filteredWorkspaces);
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    }, [filteredWorkspaces]);

    return (<>
        <Stack className={classes.table}>
            {withFilter && (<InvestorsTableFilter count={investors?.length} onFilter={handleFilter}/>)}
            <InvestorsTableHeader collectionKey={collectionKey} forSharing={forSharing} />
            <Box className={classes.rows}>
                {!!investors.length ? investors.map(investor => (
                    <InvestorsTableRow
                        workspace={investor}
                        dashboard={dashboard}
                        collectionKey={collectionKey}
                        forSharing={forSharing}
                        noMailing={noMailing}
                        key={'investors-table-86-' + investor.id} />
                )) : (!!search) && (
                    <Stack alignItems="center" justifyContent="center" width="100%" height="100%">
                        <Typography fontSize="1rem" color="#666666">{'No results found.'}</Typography>
                    </Stack>
                )}
            </Box>
        </Stack>
    </>);
}

export default InvestorsTable;