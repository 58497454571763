import { Divider, IconButton, Link, Stack, Typography, } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from "react";
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import theme from "../../../theme";
import {AuthContext} from "../../../contexts/AuthContext";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from "notistack";
import { UnassignedContentType } from "../../../types/files";
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder.svg';
import {ReactComponent as CallIcon} from '../../../assets/icons/call.svg';
import {ReactComponent as FathomLogo} from '../../../assets/logo/fathom.svg';
import {ReactComponent as GongLogo} from '../../../assets/logo/gong.svg';
import {ReactComponent as FirefliesLogo} from '../../../assets/logo/fireflies.svg';
import {ReactComponent as TLDVLogo} from '../../../assets/logo/tldv.svg';

const useStyles = makeStyles(() => ({
    logo: {
        width: 'auto',
        height: 32,
    },
    urlIcon: {
        width: 64,
        height: 64,
    },
}));

const NoEmails: React.FC<{}> = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userGroup } = useContext(AuthContext);
    const emailAddress = `${userGroup}@notissia.awsapps.com`;

    return (<>
        <Stack spacing={4} alignItems="center" width="100%">
            <MailOutlineRoundedIcon className={classes.urlIcon} style={{ fill: theme.colors.neutral['400'] }} />
            <Typography variant="h6" color={theme.colors.neutral['500']} fontFamily="Inter">
                {`To add emails, forward them to: `}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <Typography
                    component="a"
                    href={`mailto:${emailAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="h6"
                    fontWeight="bold"
                    fontFamily="Inter"
                    color={theme.colors.cyan['500']}
                    sx={{
                        textDecoration: 'none',
                        "&:hover": {
                            textDecoration: 'none !important',
                        }
                    }}
                > {emailAddress} </Typography>
                <CopyToClipboard text={`${userGroup}@notissia.awsapps.com`}
                    options={{ format: "text/plain" }}
                    onCopy={() => {
                        enqueueSnackbar(`Successfully copied to clipboard.`,
                            { anchorOrigin: { vertical: "bottom", horizontal: "right" }, });
                    }}>
                    <IconButton size="small" sx={{ color: theme.colors.cyan['500'] }}>
                        <ContentCopyIcon sx={{ width: '20px', height: '20px', }} />
                    </IconButton>
                </CopyToClipboard>
            </Stack>
            <Divider sx={{ margin: '16px 0 8px !important', width: '500px' }}>
                <Typography variant="h6" fontSize="1rem" color={theme.colors.neutral['500']} fontFamily="Inter">
                    AND
                </Typography>
            </Divider>
            <Typography variant="h6" color={theme.colors.neutral['500']} fontFamily="Inter">
                {'Enter the company name in the body as follows: '}
            </Typography>
            <Typography variant="h6" color={theme.colors.neutral['700']} fontFamily="Inter">
                {'>>>company name'}
            </Typography>
        </Stack>
    </>);
}

const NoCalls: React.FC<{}> = () => {
    const classes = useStyles();

    return (<>
         <Stack spacing={5} alignItems="center" width="100%">
            <CallIcon className={classes.urlIcon} style={{ stroke: theme.colors.neutral['400'] }} />
            <Typography component={Stack}
                variant="h6-bold"
                color={theme.colors.neutral['500']}
                lineHeight="30px"
                fontFamily="Inter"
                textAlign="center">
                {'Sync your calls to this dashboard.'}<br />
                {'The data will update the dashboard overview.'}
            </Typography>
            <Divider sx={{ width: '200px', borderBottomWidth: 'medium' }} />
            <Typography variant="h6-bold" color={theme.colors.neutral['500']} fontFamily="Inter">
                {'Connect any AI note taking app via '}
                <Typography
                    component="a"
                    href="https://zapier.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="h6"
                    fontWeight="bold"
                    fontFamily="Inter"
                    color={theme.palette.primary.main}
                    sx={{
                        textDecoration: 'none',
                        "&:hover": {
                            textDecoration: 'none !important',
                        }
                    }}
                >Zapier</Typography>.
            </Typography>
            <Stack spacing={2} alignItems="center">
                <Typography variant="h6"
                    color={theme.colors.neutral['500']}
                    lineHeight="30px"
                    fontFamily="Inter"
                    fontStyle="italic"
                    textAlign="center">
                    {'Popular AI note taking apps supported by Zapier:'}
                </Typography>
                <Stack direction="row" spacing={5} alignItems="center" justifyContent="center">
                    <Link href="https://fireflies.ai" target="_blank" rel="noopener noreferrer" sx={{ color: 'unset' }}>
                        <FirefliesLogo className={classes.logo} />
                    </Link>
                    <Link href="https://gong.io" target="_blank" rel="noopener noreferrer" sx={{ color: 'unset' }}>
                        <GongLogo className={classes.logo} />
                    </Link>
                    <Link href="https://fathom.video" target="_blank" rel="noopener noreferrer" sx={{ color: 'unset' }}>
                        <FathomLogo className={classes.logo} />
                    </Link>
                    <Link href="https://tldv.io" target="_blank" rel="noopener noreferrer" sx={{ color: 'unset' }}>
                        <TLDVLogo className={classes.logo} />
                    </Link>
                </Stack>
                <Typography variant="h6"
                    color={theme.colors.neutral['500']}
                    lineHeight="30px"
                    fontFamily="Inter"
                    fontStyle="italic"
                    textAlign="center">
                    {'among others ...'}
                </Typography>
            </Stack>
        </Stack>
    </>);
}

const NoOthers: React.FC<{}> = () => {
    const classes = useStyles();

    return (<>
        <Stack spacing={4} alignItems="center" width="100%">
            <FolderIcon className={classes.urlIcon} style={{ fill: theme.colors.neutral['400'] }} />
                <Typography component={Stack}
                    variant="h6-bold"
                    color={theme.colors.neutral['500']}
                    lineHeight="30px"
                    fontFamily="Inter"
                    textAlign="center">
                    {'Sync other apps you use regularly to this dashboard.'}<br />
                    {'The data will update the dashboard overview.'}
                </Typography>
            <Divider sx={{ width: '200px', borderBottomWidth: 'medium' }} />
            <Typography variant="h6-bold" color={theme.colors.neutral['500']} fontFamily="Inter">
                {'Connect any app via '}
                <Typography
                    component="a"
                    href="https://zapier.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="h6"
                    fontWeight="bold"
                    fontFamily="Inter"
                    color={theme.palette.primary.main}
                    sx={{
                        textDecoration: 'none',
                        "&:hover": {
                            textDecoration: 'none !important',
                        }
                    }}
                >Zapier</Typography>.
            </Typography>
        </Stack>
    </>);
}

const NoDashboardContent: React.FC<{ type: UnassignedContentType }> = ({ type }) => {
    switch (type) {
        case UnassignedContentType.EMAIL:
        case UnassignedContentType.FULL_EMAIL:
            return (<NoEmails />);
        case UnassignedContentType.CALL:
            return (<NoCalls />);
        case UnassignedContentType.OTHER:
            return (<NoOthers />);
        default:
            return (<></>);
    }
}

export default NoDashboardContent;
