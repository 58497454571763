import { Stack, Typography } from "@mui/material";
import LinkedInPostsQuery from "../../molecules/dashboard-queries/LinkedInPostsQuery";
import theme from "../../../theme";

export const Column = 'Recent Activity';

const RecentActivityColumnQueries: React.FC<{}> = () => {
    return (<>
        <Stack spacing={2} alignItems="flex-start" width="20%">
            <Typography variant="b1-semibold" color={theme.colors.neutral['400']}>
                {Column}
            </Typography>
            <LinkedInPostsQuery />
        </Stack>
    </>);
}

export default RecentActivityColumnQueries;