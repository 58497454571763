import React, { useCallback, useContext } from "react";
import { pdf } from '@react-pdf/renderer';
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from "moment";
import { AuthContext } from "../../../contexts/AuthContext";
import ExportDocument from "./ExportDocument";
import { DashboardQueriesType } from "../../../types/files";
import useBulkDashboards from "../../../hooks/useBulkDashboards";

const useStyles = makeStyles((theme) => ({
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
}));

const BulkExportPDFs: React.FC<{
    filteredTitles: string[],
    multiZipped?: boolean,
    onClose?: () => void,
 }> = ({ filteredTitles, multiZipped, onClose }) => {
    const classes = useStyles();
    const { userGroup: author } = useContext(AuthContext);
    const { loading, buildExportable } = useBulkDashboards();

    const documentName = `Notissia Dashboard Export ${moment().format('(M-D-YYYY)')}`

    const handleBulkDownload = useCallback(() => {
        buildExportable('bulk-action', filteredTitles)
            .then((bulkDashboards: DashboardQueriesType[]) => {
                if (multiZipped) {
                    const promises: Promise<Blob>[] = [];
                    const zip = new JSZip();

                    bulkDashboards.forEach(({dashboard, queries}, i) => {
                        promises.push(pdf(
                            <ExportDocument
                                bulkDashboards={[{dashboard, queries}]}
                                author={author}
                                key={'bulk-export-pdfs-37' + i} />
                        ).toBlob());
                    });

                    Promise.all(promises).then(blobs => {
                        bulkDashboards.forEach(({dashboard}, i) =>
                            zip.file(`${dashboard!.title}.pdf`, blobs[i])
                        );

                        zip.generateAsync({type: 'blob', compression: 'STORE'})
                            .then(blob => saveAs(blob, `${documentName}.zip`));
                    });
                } else {
                    pdf(
                        <ExportDocument bulkDashboards={bulkDashboards} author={author} />
                    ).toBlob().then(blob => saveAs(blob, `${documentName}.pdf`));
                }
            }).finally(() => onClose?.());
    // eslint-disable-next-line
    }, [author, filteredTitles, buildExportable]);

    return (<>
        <LoadingButton
            variant="contained"
            className={classes.commonButton}
            loading={loading}
            onClick={handleBulkDownload}
        > Download </LoadingButton>
    </>);
}

export default BulkExportPDFs;