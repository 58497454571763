import React, { createContext, useState } from "react";
import { AdvancedSearchObject, SearchDocumentType, SearchEntity, SearchResult } from "../types/search";
import { v4 as uuidv4 } from "uuid";
import { AllFileTypesExcludingExcel } from "../shared/search";

interface Context {
    contextSearchEntity: SearchEntity;
    setContextSearchEntity: (newSearchEntity: SearchEntity) => void;
    contextSearchKeywordsAndOperations: string[];
    setContextSearchKeywordsAndOperations: (newKeywordsAndOperations: string[]) => void;
    contextAdvancedSearchObject: AdvancedSearchObject | null | undefined;
    setContextAdvancedSearchObject: (res: AdvancedSearchObject | null | undefined) => void;
    contextSelectedDocTypes: SearchDocumentType[] | null | undefined;
    setContextSelectedDocTypes: (res: SearchDocumentType[] | null | undefined) => void;
    contextSelectedSearchResult: SearchResult | undefined;
    setContextSelectedSearchResult: (file: SearchResult | undefined) => void;
    contextSearchKeywords: string[];
    setContextSearchKeywords: (keywords: string[]) => void;
    contextSearchResults: SearchResult[];
    setContextSearchResults: (files: SearchResult[]) => void;
}

export const SearchContext = createContext<Context>({
    contextSearchEntity: SearchEntity.All,
    setContextSearchEntity: () => { },
    contextSearchKeywordsAndOperations: [],
    setContextSearchKeywordsAndOperations: () => { },
    contextAdvancedSearchObject: {
        id: uuidv4(),
        file: null,
        fileValue: null,
        folder: null,
        value: null,
    } as AdvancedSearchObject,
    setContextAdvancedSearchObject: () => { },
    contextSelectedDocTypes: [],
    setContextSelectedDocTypes: () => { },
    contextSelectedSearchResult: undefined,
    setContextSelectedSearchResult: () => { },
    contextSearchKeywords: [],
    setContextSearchKeywords: () => { },
    contextSearchResults: [],
    setContextSearchResults: () => { },
});

interface Props {
    children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
    const [contextSearchEntity, setContextSearchEntity] = useState<SearchEntity>(SearchEntity.All);
    const [contextSearchKeywordsAndOperations, setContextSearchKeywordsAndOperations] = useState<string[]>([]);
    const [contextAdvancedSearchObject, setContextAdvancedSearchObject] = useState<AdvancedSearchObject | null | undefined>({
        id: uuidv4(),
        file: null,
        fileValue: null,
        folder: null,
        value: null,
    } as AdvancedSearchObject);
    const [contextSelectedDocTypes, setContextSelectedDocTypes] = useState<SearchDocumentType[] | null | undefined>(AllFileTypesExcludingExcel);
    const [contextSelectedSearchResult, setContextSelectedSearchResult] = useState<SearchResult | undefined>();
    const [contextSearchKeywords, setContextSearchKeywords] = useState<string[]>([]);
    const [contextSearchResults, setContextSearchResults] = useState<SearchResult[]>([]);

    return (
        <SearchContext.Provider
            value={{
                contextSearchEntity,
                setContextSearchEntity,
                contextSearchKeywordsAndOperations,
                setContextSearchKeywordsAndOperations,
                contextAdvancedSearchObject,
                setContextAdvancedSearchObject,
                contextSelectedDocTypes,
                setContextSelectedDocTypes,
                contextSelectedSearchResult,
                setContextSelectedSearchResult,
                contextSearchKeywords,
                setContextSearchKeywords,
                contextSearchResults,
                setContextSearchResults,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export default Provider;
