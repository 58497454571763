import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getGroupSettingsFunc, updateGroupSettingsFunc } from '../lib/helper';
import { AuthContext } from './AuthContext';
import ObjectUtils from '../utils/ObjectUtils';
import { Workspace } from '../types/files';

type GroupSettingsContextProps = {
  loaded: boolean;
  group: string;
  statuses: string[];
  setStatuses: Dispatch<SetStateAction<string[]>>;
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
  stages: string[];
  setStages: Dispatch<SetStateAction<string[]>>;
  externalSync: boolean;
  setExternalSync: Dispatch<SetStateAction<boolean>>;
  workspaces: Workspace[];
  setWorkspaces: Dispatch<SetStateAction<Workspace[]>>;
};

export const GroupSettingsContext = createContext<GroupSettingsContextProps>({
  loaded: false,
  group: '',
  statuses: [],
  setStatuses: () => {},
  tags: [],
  setTags: () => {},
  stages: [],
  setStages: () => {},
  externalSync: false,
  setExternalSync: () => {},
  workspaces: [],
  setWorkspaces: () => {},
});

const GroupSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { userGroup } = useContext(AuthContext);

  const [loaded, setLoaded] = useState<boolean>(false);
  const [group, setGroup] = useState<string>('');
  const [statuses, setStatuses] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [stages, setStages] = useState<string[]>([]);
  const [externalSync, setExternalSync] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);

  const updatedStatusRef = useRef<string[]>([]);
  const updatedTagsRef = useRef<string[]>([]);
  const updatedStagesRef = useRef<string[]>([]);
  const updatedAutoSyncRef = useRef<boolean>(false);
  const updatedWorkspacesRef = useRef<Workspace[]>([]);

  useEffect(() => {
    if (userGroup) {
      getGroupSettingsFunc({}).then((groupSettingsData) => {
        updatedTagsRef.current = groupSettingsData?.tags?.sort((a, b) => a > b ? 1 : 0) || [];
        updatedStagesRef.current = groupSettingsData?.investmentStages?.sort((a, b) => a > b ? 1 : 0) || [];
        updatedStatusRef.current = groupSettingsData?.statuses?.map(statuses => !!statuses ? statuses.trim().toLowerCase() : '<rename this statuses>') || [];
        updatedAutoSyncRef.current = groupSettingsData?.shouldSyncAllDashboardsExternally || false;
        updatedWorkspacesRef.current = groupSettingsData?.workspaces || [];

        setGroup(groupSettingsData?.group as string);
        setTags(updatedTagsRef.current);
        setStages(updatedStagesRef.current);
        setStatuses(updatedStatusRef.current);
        setExternalSync(updatedAutoSyncRef.current);
        setWorkspaces(updatedWorkspacesRef.current);
      }).finally(() => setLoaded(true));
    }
  }, [userGroup]);

  useEffect(() => {
    if (!ObjectUtils.equalObjects(statuses, updatedStatusRef.current)) {
      (async () => await updateGroupSettingsFunc({ group: userGroup, statuses: statuses, }))();
      updatedStatusRef.current = structuredClone(statuses);
    }
  }, [statuses, userGroup]);

  useEffect(() => {
    if (!ObjectUtils.equalObjects(tags, updatedTagsRef.current)) {
      (async () => await updateGroupSettingsFunc({ group: userGroup, tags: tags, }))();
      updatedTagsRef.current = structuredClone(tags);
    }
  }, [tags, userGroup]);

  useEffect(() => {
    if (!ObjectUtils.equalObjects(stages, updatedStagesRef.current)) {
      (async () => await updateGroupSettingsFunc({ group: userGroup, investmentStages: stages, }))();
      updatedStagesRef.current = structuredClone(stages);
    }
  }, [stages, userGroup]);

  useEffect(() => {
    if (!ObjectUtils.equalObjects(externalSync, updatedAutoSyncRef.current)) {
      (async () => await updateGroupSettingsFunc({ group: userGroup, shouldSyncAllDashboardsExternally: externalSync, }))();
      updatedAutoSyncRef.current = structuredClone(externalSync);
    }
  }, [externalSync, userGroup]);

  useEffect(() => {
    if (!ObjectUtils.equalObjects(workspaces, updatedWorkspacesRef.current)) {
      updatedWorkspacesRef.current = structuredClone(workspaces);
    }
  }, [workspaces, userGroup]);

  const contextValue = useMemo(() => ({
    loaded,
    group,
    statuses,
    setStatuses,
    tags,
    setTags,
    stages,
    setStages,
    externalSync,
    setExternalSync,
    workspaces,
    setWorkspaces,
  }), [
    loaded,
    group,
    statuses,
    setStatuses,
    tags,
    setTags,
    stages,
    setStages,
    externalSync,
    setExternalSync,
    workspaces,
    setWorkspaces,
  ]);

  return (
    <GroupSettingsContext.Provider value={contextValue}>
      {children}
    </GroupSettingsContext.Provider>
  );
};

export default GroupSettingsProvider;
