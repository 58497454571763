import React, { Fragment, useCallback, useContext, useState } from "react";
import { Box, Button, Collapse, Link, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import classNames from "classnames";
import {ReactComponent as CrunchBaseIcon} from "../../../assets/icons/crunchbase.svg";
import {ReactComponent as LinkedInIcon} from "../../../assets/icons/linkedin.svg";
import {ReactComponent as WorldGridIcon} from "../../../assets/icons/world.svg";
import { DashboardsContext, DisplayQueryTitles } from "../../../contexts/DashboardsContext";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import useRoute from "../../../hooks/useRoute";
import { updateDashboardFunc } from "../../../lib/helper";
import { scrollbarStyle } from "../../../shared/dashboard";
import ObjectUtils from "../../../utils/ObjectUtils";
import StageSelector from "../../molecules/crunchbase-modal/StageSelector";
import MultiSelector from "../MultiSelector";
import QueryAnswer from "../QueryAnswer";
import ThesisScore from "../ThesisScore";
import DashboardAddToWorkspaceButton from "./DashboardAddToWorkspaceButton";
import DashboardSelect from "./DashboardSelect";
import DashboardShare from "./DashboardShare";
import { Dashboard } from "../../../types/files";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: 'auto',
        padding: '8px 4px',
        background: `#fff`,
        borderBottom: `2px solid ${theme.colors.neutral['200']}`,
        transition: '300ms ease-in-out',
        "&:hover": {
            background: `rgb(246, 251, 251, 0.8)`,
        },
    },
    cardView: {
        width: 'calc(100% - 8px)',
        padding: '8px 12px 0 12px',
        marginLeft: '4px !important',
        marginRight: '4px !important',
        borderRadius: 16,
        border: `1px solid ${theme.colors.neutral['100']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        "&:hover": {
            boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
        },
    },
    solo: {
        padding: 8,
        background: `rgb(246, 251, 251, 0.8)`,
        borderRadius: 16,
        border: `1px solid ${theme.colors.cyan['500']}`,
    },
    lastItem: {
        borderBottom: 'unset',
    },
    event: {
        fontFamily: 'Lato',
        fontSize: '0.95rem !important',
        color: theme.colors.neutral['600'],
    },
    title: {
        minWidth: 'unset !important',
        padding: 'unset !important',
        fontFamily: 'Inter',
        fontSize: '1rem !important',
        fontWeight: '700 !important',
        color: theme.palette.primary.main,
        textDecoration: 'none !important',
        textTransform: 'none !important' as any,
        "&:hover": {
            background: 'transparent',
            textDecoration: 'none !important',
        }
    },
    soloTitle: {
        fontSize: '1.1em !important',
        fontWeight: 'bold !important',
        color: theme.palette.common.black,
    },
    prevSharedTitle: {
        color: theme.colors.neutral['600'],
    },
    date: {
        minWidth: 80,
        paddingBottom: 8,
        fontFamily: 'Lato',
        fontSize: '0.9rem !important',
        color: theme.colors.neutral['500'],
        textAlign: 'right',
    },
    icon: {
        width: 20,
        height: 20,
    },
    largeIcon: {
        width: 30,
        height: 30,
        fill: theme.colors.neutral['400'],
    },
    answerBlock: {
        width: '100%',
        height: 'auto',
        padding: 'unset',
        color: '#666666',
        overflowY: 'auto',
        cursor: 'text',
        ...scrollbarStyle,
    },
    queryWaveAnimation: {
        borderRadius: '4px !important',
        height: '20px !important',
    },
    expandIcon: {
        fill: '#666666 !important',
        alignItems: 'center !important',
        justifyContent: 'right',
        cursor: 'pointer',
    },
}));

const DashboardListItem: React.FC<{
    workspaceId?: string,
    dashboard: Dashboard,
    queryTitles?: string[],
    collectionKey?: string,
    timestamp?: string,
    lastItem?: boolean,
    prevShared?: boolean,
    noSharing?: boolean,
    noSaving?: boolean,
    noExpansion?: boolean,
    solo?: boolean,
    cardView?: boolean,
 }> = ({ workspaceId, dashboard, queryTitles, collectionKey, timestamp, lastItem, prevShared, noSharing, noSaving, noExpansion = true, solo, cardView }) => {
    const classes = useStyles();
    const { tags } = useContext(GroupSettingsContext);
    const { isPublicView, dashboardsQueries, mappedOverviewQueries } = useContext(DashboardsContext);
    const { redirectToDashboard } = useRoute();

    const [isExpanded, setIsExpanded] = useState<boolean>(noExpansion);
    const [updating, setUpdating] = useState<boolean>(false);

    const getQueryAnswer = useCallback((title: string) => (
        mappedOverviewQueries.get(`${dashboard.id}:${title}`)
    ), [dashboard, mappedOverviewQueries]);

    const getIcon = useCallback((title: string | null) => {
        const color = prevShared ? theme.colors.neutral['400'] : '#7bd4d4';

        if (title) {
            if (title.toLowerCase().includes('crunchbase')) {
                return <CrunchBaseIcon className={classes.icon} fill={color} />;
            } else if (['linkedin', 'company', 'people'].reduce((found: boolean, keyword: string) => found || title.toLowerCase().includes(keyword), false)) {
                return <LinkedInIcon className={classes.icon} fill={color} />;
            } else if (title.toLowerCase().includes('website')) {
                return <WorldGridIcon className={classes.icon} fill={color} stroke={color} />;
            } else if (title.toLowerCase().includes('dashboard')) {
                return <DashboardIcon className={classes.largeIcon} />;
            }
        }

        return (<></>);
        // eslint-disable-next-line
    }, []);

    const handleSelectStage = useCallback(async (value: string) => {
        if (dashboard.investmentStage !== value) {
            dashboard.investmentStage = value;
            await updateDashboardFunc(dashboard);
        }
    }, [dashboard]);

    const handleSelectTags = useCallback(async (selections: string[]) => {
        if (!ObjectUtils.equalObjects(dashboard.tags, selections)) {
            dashboard.tags = selections;
            setUpdating(true);
            updateDashboardFunc(dashboard).then(() => setUpdating(false));
        }
    }, [dashboard]);

    return (<>
        <Stack direction="row"  className={classNames(
                classes.container,
                cardView && classes.cardView,
                solo && classes.solo,
                lastItem && classes.lastItem,
            )} spacing={1} alignItems="flex-start">
            {!!collectionKey ? (
                <Stack alignItems="center" justifyContent="center" mt="4px">
                    <DashboardSelect collection={collectionKey} dashboard={dashboard} />
                </Stack>
            ) : (!solo) && (<Stack width="fit-content">{getIcon('dashboard')}</Stack>)}
            <Stack direction="column" spacing={1} alignItems="flex-start" width="100%" paddingLeft={1}>
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Stack direction="row" spacing={2} alignItems={solo ? 'flex-start' : 'center'} justifyContent="flex-start">
                        <Button variant="text" className={classNames(
                                classes.title, solo && classes.soloTitle, prevShared && classes.prevSharedTitle,
                            )} onClick={() => redirectToDashboard(dashboard.id)}>
                            {dashboard.title}
                        </Button>
                        {solo ? (
                            <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="flex-start">
                                <StageSelector
                                    defaultStage={dashboard?.investmentStage ?? undefined}
                                    onSelectStage={handleSelectStage}
                                    readOnly={isPublicView || noSaving || prevShared}
                                    disabled={prevShared}
                                    filled />
                                <MultiSelector
                                    mainOptions={tags}
                                    selections={dashboard.tags || []}
                                    onSelection={handleSelectTags}
                                    readOnly={isPublicView || noSaving || prevShared}
                                    disabled={prevShared}
                                    loading={updating}
                                    limit={3}
                                    fullSpan />
                            </Stack>
                        ) : (<ThesisScore workspaceId={workspaceId} investmentTheses={getQueryAnswer('Investment Thesis') || []} disabled={prevShared} />)}
                    </Stack>
                    {!solo && (
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            {!!(getQueryAnswer('Website') || '') && (
                                <Tooltip title="Website">
                                    <Link
                                        underline="none"
                                        target="_blank"
                                        sx={{
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                            color: "inherit"
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            window.open((getQueryAnswer('Website') || ''), '_blank', 'noopener, noreferrer');
                                        }}
                                    >
                                        {getIcon('website')}
                                    </Link>
                                </Tooltip>
                            )}
                            {!!(getQueryAnswer('Crunchbase Page') || '') && (
                                <Tooltip title="Crunchbase">
                                    <Link
                                        underline="none"
                                        target="_blank"
                                        sx={{
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                            color: "inherit"
                                        }}
                                        mr={1}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            window.open((getQueryAnswer('Crunchbase Page') || ''), '_blank', 'noopener, noreferrer');
                                        }}
                                    >
                                        {getIcon('crunchbase')}
                                    </Link>
                                </Tooltip>
                            )}
                            {!!(getQueryAnswer('LinkedIn') || '') && (
                                <Tooltip title="LinkedIn">
                                    <Link
                                        underline="none"
                                        target="_blank"
                                        sx={{
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                            color: "inherit"
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            window.open((getQueryAnswer('LinkedIn') || '') as string, '_blank', 'noopener, noreferrer');
                                        }}
                                    >
                                        {getIcon('linkedin')}
                                    </Link>
                                </Tooltip>
                            )}
                            <Box minWidth="100px">
                                <Typography className={classes.date}>{moment(timestamp ?? dashboard.createdAt).fromNow()}</Typography>
                            </Box>
                        </Stack>
                    )}
                </Stack>
                {!solo && (
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="flex-start">
                            <StageSelector
                                defaultStage={dashboard?.investmentStage ?? undefined}
                                onSelectStage={handleSelectStage}
                                readOnly={isPublicView || noSaving || prevShared}
                                disabled={prevShared}
                                filled />
                            <MultiSelector
                                mainOptions={tags}
                                selections={dashboard.tags || []}
                                onSelection={handleSelectTags}
                                readOnly={isPublicView || noSaving || prevShared}
                                disabled={prevShared}
                                loading={updating}
                                limit={3}
                                fullSpan />
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                            {(!isPublicView && !noSaving) && (
                                <DashboardAddToWorkspaceButton dashboard={dashboard} workspaceId={workspaceId} />
                            )}
                            {(!isPublicView && !noSharing) && (
                                <DashboardShare dashboard={dashboard} />
                            )}
                        </Stack>
                    </Stack>
                )}
                {!!queryTitles?.length && (
                    <Collapse in={isExpanded} collapsedSize="auto" sx={{ width: '100%', marginBottom: '8px !important' }}>
                        <Stack direction="row" alignItems={isExpanded ? 'flex-end' : 'flex-start'} justifyContent="space-between">
                            <Box className={classes.answerBlock}>
                                {!dashboardsQueries.length ? (<>
                                    {[...Array(3)].map((_, i) => (
                                        <Skeleton animation="wave"
                                            className={classes.queryWaveAnimation}
                                            key={'dashboard-card-match-168-' + i} />
                                    ))}
                                </>) : (
                                    <Stack direction="column" spacing={1} alignItems="flex-start">
                                        {DisplayQueryTitles.map((displayTitle, i) => (
                                            <Fragment key={'dashboard-card-match-169-' + i}>
                                                {queryTitles.includes(displayTitle) && (
                                                    <QueryAnswer dashboard={dashboard} title={displayTitle} disabled={prevShared} />
                                                )}
                                            </Fragment>
                                        ))}
                                    </Stack>
                                )}
                            </Box>
                            {(!solo && !noExpansion && !!dashboardsQueries.length) && (<>
                                <Stack direction="row" justifyContent="flex-end" minWidth="100px" margin="-8px 4px" mt={isExpanded ? 'unset': '45px' }>
                                    <ExpandMoreIcon
                                        className={classes.expandIcon}
                                        sx={isExpanded ? { transform: 'rotate(180deg) !important' } : {}}
                                        onClick={(e)=> {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setIsExpanded((prev) => !prev);
                                        }} />
                                </Stack>
                            </>)}
                        </Stack>
                    </Collapse>
                )}
            </Stack>
        </Stack>
    </>);
}

export default DashboardListItem;
