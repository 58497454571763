import { makeStyles } from "@mui/styles";
import { useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Tooltip, Typography } from "@mui/material";
import { ReactComponent as ExportIcon } from "../../../assets/icons/download.svg";
import {DashboardContext} from "../../../contexts/DashboardContext";
import DashboardExportPDFModal from "../../modals/dashboard-details/DashboardExportPDFModal";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        marginBottom: 4,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 20,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        transition: 'ease-in-out 300ms',
    },
    downloadIcon: {
        width: 24,
        height: 24,
        stroke: theme.palette.primary.main,
    },
}));

const DashboardExport: React.FC<React.PropsWithChildren<{ hidden?: boolean }>> = ({ hidden }) => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);
    const [exportPDFModalOpen, setExportPDFModalOpen] = useState<boolean>(false);

    if (hidden || !dashboard)
        return (<></>);

    return (<>
        <Tooltip
            placement="top"
            title={<Typography fontSize="0.85rem">{'Download dashboard as PDF'}</Typography>}
            componentsProps={{tooltip:{sx:{
                paddingLeft: 2, paddingRight: 2,
                backgroundColor: 'rgba(97, 97, 97)',
            }}}}>
            <LoadingButton
                variant="outlined"
                className={classes.iconButton}
                onClick={() => setExportPDFModalOpen(true)}
            > <ExportIcon className={classes.downloadIcon} /> </LoadingButton>
        </Tooltip>
        <DashboardExportPDFModal
            isOpen={exportPDFModalOpen}
            onClose={() => setExportPDFModalOpen(false)}
        />
    </>);
}

export default DashboardExport;