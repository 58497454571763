import { CardActions, CardContent, Chip, IconButton, styled, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classNames from "classnames";
import { Comment, CommentOriginType } from '../../../types/comments';
import { SaveDashboardItemType, User } from "../../../types/common";
import moment from "moment";
import { useRef, useState, useEffect, forwardRef, useContext } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
import { FileStructureContext } from "../../../contexts/FileStructureContext";
import { Dashboard } from "../../../types/files";
import { MENTION_REGEX } from "../../../shared/comments";

const ExpandMore = styled(forwardRef(({ expand, ...other }: any, ref) => {
    return <IconButton {...other} ref={ref} />;
}))(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 4,
    },
    cardContent: {
        padding: '10px !important',
        cursor: 'pointer',
        '-moz-transition': 'height .5s',
        '-ms-transition': 'height .5s',
        '-o-transition': 'height .5s',
        '-webkit-transition': 'height .5s',
        transition: 'height .5s ease',
        overflow: 'hidden',
    },
    cardActions: {
        padding: '0 !important',
        '-moz-transition': 'height .5s',
        '-ms-transition': 'height .5s',
        '-o-transition': 'height .5s',
        '-webkit-transition': 'height .5s',
        transition: 'height .5s ease',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
    },
    commentText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        lineClamp: 1,
        textDecoration: 'none',
        color: theme.palette.text.primary,
        wordBreak: "break-word",
    },
    commentHeading: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        textDecoration: 'none',
    },
    leftCommentHeading: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
    },
    rightCommentHeading: {
        display: 'flex',
        flexDirection: 'row',
    },
    documentTextHeading: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
        marginTop: 10,
    },
    userHeading: {
        fontSize: 12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 1,
        color: theme.palette.text.disabled,
        border: `1px solid ${theme.palette.text.disabled}`,
        padding: 5,
        borderRadius: 70,
    },
    infoHeading: {
        marginLeft: 5,
        fontSize: 12,
        textDecoration: 'none',
        color: theme.palette.text.primary,
        fontWeight: 'bold'
    },
    mention: {
        fontWeight: 'bold !important',
        color: theme.palette.secondary.main,
        position: "relative",
        zIndex: 1,
        textShadow: "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
        pointerEvents: "none"
    },
    link: {
        textDecoration: 'none',
    },
    chip: {
        fontSize: 10,
        height: 'auto',
        maxHeight: 18,
    },
}));

const RecentActivityCard: React.FC<{
    comment: Comment,
    reportingUser: User | undefined,
    dashboards: Dashboard[],
}> = ({ comment, reportingUser, dashboards }) => {
    const classes = useStyles();
    const ref = useRef<HTMLInputElement>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isExpandable, setIsExpandable] = useState<boolean>(false);

    const { fileStructure } = useContext(FileStructureContext);

    useEffect(() => {
        setIsExpandable(ref?.current?.scrollHeight! > 60);
    }, []);

    const getTextHighlighted = (content: string) => {
        content = content.replace(new RegExp(MENTION_REGEX, "gm"), "");

        const mentions = content.match(/(@[\w.]+)/g);

        mentions?.forEach(user => {
            const index = content.indexOf(user);
            if (index >= 0) {
                content = content.substring(0, index) + `<span class='${classes.mention}'>` + content.substring(index, index + user.length) + "</span>" + content.substring(index + user.length);
            }
        });

        return content;
    }

    const getHeadText = (comment: Comment, wrap: boolean = false) => {
        let fileName: string = fileStructure.find(x => x.key === comment.documentId)?.path?.split("/").join(" / ") || "";

        let documentPhrase = "";

        if (comment.originUrl && comment.originType === CommentOriginType.Dashboard) {
            const dashboardItem = JSON.parse(comment.originUrl);
            const dashboard = dashboards.find((e) => e.id === dashboardItem.id);
            let docKey = fileStructure.find(x => x.key === dashboardItem.documentKey)?.path?.split("/").join(" / ") || "";
            if (dashboard) {
                documentPhrase = `${dashboard?.title}`;
            }
            else if (docKey) {
                if (wrap && docKey.length > 50) {
                    docKey = docKey.substring(0, 50) + "...";
                }

                documentPhrase = docKey;
            }
        }
        else if ((!comment.originType || comment.originType === CommentOriginType.Folder) && fileName) {
            if (wrap && fileName.length > 50) {
                fileName = fileName.substring(0, 50) + "...";
            }

            documentPhrase = fileName;
        }

        return documentPhrase;
    }

    const buildSearch = (comment: Comment): string => {
        if (comment.originType === CommentOriginType.Dashboard && comment.originUrl) {
            const dashboardItem = JSON.parse(comment.originUrl);
            const dashboard = dashboards.find((e) => e.id === dashboardItem.id);

            if (shouldGoToDashboard()) {
                return comment.parentId ?
                    `?dashboardid=${dashboard?.id}&dashboardItemId=${encodeURIComponent(dashboardItem.dashboardItemId)}&dashboardItemType=${dashboardItem.dashboardItemType}&documentKey=${encodeURIComponent(dashboardItem.documentKey)}&commentId=${encodeURIComponent(comment?.id || "")}&parentId=${encodeURIComponent(comment.parentId)}`
                    :
                    `?dashboardid=${dashboard?.id}&dashboardItemId=${encodeURIComponent(dashboardItem.dashboardItemId)}&dashboardItemType=${dashboardItem.dashboardItemType}&documentKey=${encodeURIComponent(dashboardItem.documentKey)}&commentId=${encodeURIComponent(comment?.id || "")}`;
            } else {
                return comment.parentId ?
                    `?dashboardid=${dashboard?.id}&documentId=${encodeURIComponent(dashboardItem.documentKey)}&commentId=${encodeURIComponent(comment?.id || "")}&parentId=${encodeURIComponent(comment.parentId)}`
                    :
                    `?dashboardid=${dashboard?.id}&documentId=${encodeURIComponent(dashboardItem.documentKey)}&commentId=${encodeURIComponent(comment?.id || "")}`;
            }
        }
        else {
            return comment?.parentId ?
                `?documentId=${encodeURIComponent(comment.documentId)}&commentId=${encodeURIComponent(comment?.id || "")}&parentId=${encodeURIComponent(comment.parentId)}`
                :
                `?documentId=${encodeURIComponent(comment.documentId)}&commentId=${encodeURIComponent(comment?.id || "")}`;
        }
    }

    const getPath = (comment: Comment): string => {
        if (comment.originType === CommentOriginType.Dashboard && comment.originUrl) {
            if (shouldGoToDashboard()) {
                return `/dashboards`;
            } else {
                return "/folders";
            }
        }
        else {
            return "/folders";
        }
    }

    const shouldGoToDashboard = () => {
        if (comment.originType !== CommentOriginType.Dashboard) {
            return false;
        }

        const commentOriginData: any = JSON.parse(comment.originUrl);

        if (commentOriginData?.dashboardItemType !== SaveDashboardItemType.Page) {
            return true;
        }

        return dashboards
            .filter(x => x.id === commentOriginData.id)
            .map(x => x?.documents?.filter(y => y.id === commentOriginData.dashboardItemId).map(y => y.key))
            .flat()
            .includes(comment.documentId);
    }

    return (
        <div style={{ width: 'calc(100% - 10px)', margin: '10px 10px 10px 0', }}>
            <div className={classNames(classes.card)}>
                <CardContent
                    className={classes.cardContent}
                    style={{
                        height: `${isExpandable ?
                            isExpanded ? ref?.current?.scrollHeight! + 60 : 100
                            : ref?.current?.scrollHeight! + 60}px`
                    }}
                >
                    <Link
                        className={classes.link}
                        to={{
                            pathname: getPath(comment),
                            state: { selectedFileId: comment.documentId, },
                            search: buildSearch(comment)
                        }}>
                        <div className={classes.commentHeading}>
                            <div className={classes.leftCommentHeading}>
                                <Typography className={classes.userHeading} title={reportingUser?.firstName || reportingUser?.email}>
                                    {reportingUser?.firstName || reportingUser?.email || "None"}
                                </Typography>
                            </div>
                            <div className={classes.rightCommentHeading}>
                                {shouldGoToDashboard() && <Chip className={classes.chip} label={`Dashboard: ${getHeadText(comment)}`} size="small" color="primary" />}
                                <Typography className={classes.infoHeading}>
                                    {moment(comment.createdAt).fromNow()}
                                </Typography>
                            </div>
                        </div>
                        {
                            !shouldGoToDashboard() &&
                            <div className={classes.documentTextHeading}>
                                <Typography className={classes.infoHeading}>{getHeadText(comment)}</Typography>
                            </div>
                        }
                        <Typography
                            ref={ref}
                            variant="subtitle1"
                            className={classes.commentText}
                            dangerouslySetInnerHTML={{ __html: getTextHighlighted(comment.description) }}
                        />
                    </Link>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    {isExpandable && <Tooltip title="Read more" placement="bottom-start">
                        <ExpandMore
                            expand={isExpanded}
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Tooltip>}
                </CardActions>
            </div>
        </div>
    );
};

export default RecentActivityCard;
