import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { SelectWorkspaceType } from "./DashboardAddToInvestorModal";
import { useSnackbar } from "notistack";
import WorkspaceShare from "../../atoms/workspaces/WorkspaceShare";
import useBulkDashboards from "../../../hooks/useBulkDashboards";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 430,
            height: 430,
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.25rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
    },
    checklist: {
        height: '100%',
        overflowY: 'auto',
    },
    listItem: {
       "& > .MuiTypography-root": {
            fontFamily: 'Inter',
            fontSize: '0.95rem',
            fontWeight: 700,
       }
    },
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        minWidth: 80,
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const DashboardsBulkAddToInvestorModal: React.FC<{
    isOpen: boolean,
    onClose: (createWorkspace?: boolean) => void,
}> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
    const { enqueueSnackbar } = useSnackbar();
    const { loading, assignToWorkspaces } = useBulkDashboards();
    const [selectedWorkspaces, setSelectedWorkspaces] = useState<SelectWorkspaceType[]>([]);

    const hasSelection = useMemo(() => selectedWorkspaces.some(selection => selection.checked), [selectedWorkspaces]);

    const handleFilterWorkspaces = useCallback((id: string) => () => {
        setSelectedWorkspaces(prev => prev.map(selection =>
            selection.workspace.id === id ? ({...selection, checked: !selection.checked}) : selection));
    }, []);

    const handleAssigntoWorkspace = useCallback(() => {
        assignToWorkspaces('bulk-action', selectedWorkspaces
            .filter(selection => selection.checked)
            .map(selection => selection.workspace.id))
            .then(() => onClose());
        enqueueSnackbar(`Dashboards have been successfully added to workspace(s)`, {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
            autoHideDuration: 4000,
        });
    // eslint-disable-next-line
    }, [selectedWorkspaces]);

    useEffect(() => {
        setSelectedWorkspaces(prev => workspaces.map(workspace => {
            const selected = prev.find(selection => selection.workspace.name === workspace.name)?.checked ?? false;

            return { workspace,
                checked: selected,
                mutable: true,
            };
        }));
    // eslint-disable-next-line
    }, [workspaces]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {'Add to investor'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Typography fontWeight="bold">{'Select the investor to add:'}</Typography>
                <List className={classes.checklist} >
                    {selectedWorkspaces.map(({workspace, checked}, i) => (
                        <ListItem key={'dashboards-bulk-add-to-investor-modal-127-' + i} disablePadding>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" mr={2}>
                                <ListItemButton role={undefined} onClick={handleFilterWorkspaces(workspace.id)} dense>
                                    <ListItemIcon>
                                        <Checkbox edge="start" checked={checked} disableRipple />
                                    </ListItemIcon>
                                    <ListItemText id={workspace.id} className={classes.listItem} primary={workspace.name} />
                                </ListItemButton>
                                <WorkspaceShare workspace={workspace} hiddenOnPrivate slim />
                            </Stack>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    <Button
                        variant="contained"
                        className={classes.commonButton}
                        onClick={() => onClose(true)}
                    > {'Add an investor'} </Button>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                    <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() => onClose()}
                    > {'Cancel'} </Button>
                    <LoadingButton
                        variant="contained"
                        className={classes.commonButton}
                        loading={loading}
                        disabled={!hasSelection}
                        onClick={handleAssigntoWorkspace}
                    > {'Save'} </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default DashboardsBulkAddToInvestorModal;