import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import { Column } from "../../organisms/dashboard-queries/KeyMetricsColumnQueries";
import { DollarMetrics, PercentMetrics } from "../../molecules/dashboard-query-answer/MetricsAnswer";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import { NoteMetric } from "../../../types/search";

export type SelectionType = {
    metric: string;
    checked: boolean;
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 430,
            height: 430,
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.25rem',
    },
    dialogContent: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '8px 0',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
        gap: 8,
    },
    checklist: {
        height: '100%',
        width: '100%',
        overflowY: 'auto',
    },
    listItem: {
       "& > .MuiTypography-root": {
            fontFamily: 'Inter',
            fontSize: '0.95rem',
            fontWeight: 700,
       }
    },
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        minWidth: 80,
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const AddMetricsModal: React.FC<{
    isOpen: boolean,
    children?: ReactNode,
    onClose: () => void,
}> = ({ isOpen, children, onClose }) => {
    const classes = useStyles();
    const { queryAnswer } = useDashboardQuery('Note Metrics');

    const [selectedQueries, setSelectedQueries] = useState<SelectionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleFilterQueries = useCallback((metric: string) => () => {
        setSelectedQueries(prev => prev.map(selection =>
            selection.metric === metric ? ({...selection, checked: !selection.checked}) : selection));
    }, []);

    const handleSaveFilteredQueries = useCallback(() => {
        const promises: Promise<any>[] = [];

        setLoading(true);
        Promise.all(promises).then(() => {
            setLoading(false);
            onClose();
        });
    // eslint-disable-next-line
    }, [selectedQueries]);

    useEffect(() => {
        const answeredQuestion = queryAnswer.answeredQuestion;
        const historyRecord = answeredQuestion.history?.map((historyAnswer: any) => historyAnswer?.answer ?? historyAnswer) ?? [];
        const historyList = Object.entries(answeredQuestion.answer ?? {})
            .map(([metric, value]) => {
                const unit = PercentMetrics.includes(metric) ? '%' : '$';
                const filteredHistory = historyRecord
                    .map(history => history[metric as keyof NoteMetric])
                    .filter(values => !!values && Object.values(values).every(value => !!value));

                return ({metric, value, history: [{...value as any}, ...filteredHistory], unit: unit});
            });

        const metrics = historyList.map(metrics => metrics.metric);
        const sanitizedMetrics = metrics.map(metric => metric.trim().toLowerCase());
        const newCategories: string[] = [];

        newCategories.push(...DollarMetrics);
        newCategories.push(...PercentMetrics.filter(metric => sanitizedMetrics.includes(metric.trim().toLowerCase())));
        newCategories.push(...metrics.filter(metric => ![...DollarMetrics, ...PercentMetrics].includes(metric)));
        setSelectedQueries(newCategories.map(category => ({ metric: category, checked: true, })) as SelectionType[]);
    }, [queryAnswer]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {`Add section in ${Column}`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Typography fontWeight="bold">{'Select the information to be shown:'}</Typography>
                <List className={classes.checklist} >
                    {selectedQueries.map(({metric, checked}, i) => (
                        <ListItem key={'add-section-modal-151-' + i} disablePadding>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" mr={2}>
                                <ListItemButton role={undefined} onClick={handleFilterQueries(metric)} dense>
                                    <ListItemIcon>
                                        <Checkbox edge="start" checked={checked} disableRipple />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listItem} primary={metric} />
                                </ListItemButton>
                            </Stack>
                        </ListItem>
                    ))}
                </List>
                {children}
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    className={classes.cancelButton}
                    onClick={() => onClose()}
                > Cancel </Button>
                <LoadingButton
                    variant="contained"
                    className={classes.commonButton}
                    loading={loading}
                    onClick={handleSaveFilteredQueries}
                > Save </LoadingButton>
            </DialogActions>
        </Dialog>
    </>);
}

export default AddMetricsModal;