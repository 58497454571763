import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FC, useContext } from "react";
import theme from "../../../theme";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";

const useStyles = makeStyles((theme) => ({
    badge: {
        borderRadius: 32,
        padding: '5.5px 8px'
    }
}))

const Tag: FC<{
    title: string;
    color?: string;
    readOnly?: boolean;
}> = ({ title, color, readOnly }) => {
    const styles = useStyles();
    const { tags } = useContext(GroupSettingsContext);

    const isValid = readOnly || tags.map(q => q.toLowerCase()).includes(title.toLowerCase());

    if (!isValid)
        return (<></>);

    return (
        <Box className={styles.badge} sx={{ backgroundColor: color ?? theme.colors.neutral['100'] }}>
            <Typography variant="b3-semibold" color={theme.colors.neutral['700']} component="h1">{title}</Typography>
        </Box>
    )
}

export default Tag;