import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Logo10 from "../../../../public/images/Notissia logos/Notissia/Ativo 10.png";
import theme from "../../../theme";
import InvestorEmailInput from "../../molecules/workspaces/InvestorEmailInput";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        background: 'white',
    },
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '1.25rem',
        fontWeight: 700,
        textTransform: 'none',
        color: theme.colors.neutral['900'],
    },
    subTitle: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        textTransform: 'none',
        color: theme.colors.neutral['600'],
    },
    bottomTextContainer: {
        position: 'fixed',
        bottom: 32,
        display: "flex",
        justifyContent: "center",
    },
    campain: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 400,
        color: theme.colors.neutral['700'],
    },
    invalid: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.error['500'],
    },
    button :{
        width: 120,
        height: 40,
        borderRadius: 24,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const DashboardAllowAccess: React.FC<{ invalid?: boolean, onConfirm: (email: string) => void }> = ({ invalid, onConfirm }) => {
    const classes = useStyles();
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [localInvalid, setLocalInvalid] = useState<boolean>(false);

    useEffect(() => {
        setEmail('');
        setLoading(false);
        setLocalInvalid(!!invalid);
    }, [invalid]);

    useEffect(() => {
        if (localInvalid && !!email)
            setLocalInvalid(false);
    }, [email, localInvalid]);

    return (<>
        <Stack spacing={3} className={classes.container} alignItems="center" justifyContent="flex-start" mt='20vh'>
            <img src={Logo10} alt="Notissia logo" loading="eager" width="150px" style={{ marginLeft: '20px' }} />
            <Stack spacing={1} alignItems="center" justifyContent="center">
                <Typography className={classes.mainTitle}>
                    {'Notissia helps investors share curated deal flow.'}
                </Typography>
                <Typography className={classes.subTitle}>
                    {'Enter your email to access your shared deal.'}
                </Typography>
            </Stack>
            <Stack spacing={1} alignItems="center" justifyContent="center" width="400px">
                <InvestorEmailInput email={email} onEmail={setEmail} placeholder="Enter your email..." inlineCaption />
                <LoadingButton
                    variant="contained"
                    className={classes.button}
                    loading={loading}
                    disabled={!email}
                    onClick={() =>  {setLoading(true); onConfirm(email.trim());}}>
                    {'Access'}
                </LoadingButton>
            </Stack>
            {localInvalid && (
                <Typography component="span" className={classes.invalid} display="inline" pt={4}>
                   {'Wrong email address entered. Try again.'}
                </Typography>
            )}
            <Box className={classes.bottomTextContainer}>
                <Typography component="a"
                    className={classes.campain}
                    color={`${theme.palette.primary.main} !important`}
                    href="https://notissia.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    {'Notissia'}
                </Typography>
                <Typography component="span" className={classes.campain} display="inline">
                    &nbsp;{'is an AI-powered deal flow database for investors.'}
                </Typography>
            </Box>
        </Stack>
    </>);

};

export default DashboardAllowAccess;