import React, { useContext, useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Viewer, ScrollMode } from "@react-pdf-viewer/core";
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';

import awsConfig from '../../../aws-exports';
import { DashboardContext } from "../../../contexts/DashboardContext";
import { FileStructureContext } from "../../../contexts/FileStructureContext";
import { s3DownloadExternalBucket, s3Download } from "../../../helpers/s3";
import { getPublicFilesForDashboardFunc } from "../../../lib/helper";
import { scrollbarStyle } from '../../../shared/dashboard';
import { CompanyFile } from "../../../types/files";
import CustomPageRender from "../../folders/components/file-preview/CustomPageRender";
import theme from "../../../theme";
import FileUploaderModal from "../../modals/dashboard-details/FileUploaderModal";
import { DEF_VALID_FILE } from "../../atoms/dashboards/DashboardFileUploader";
import DashboardDeckSelectModal from "../../modals/dashboard-details/DashboardDeckSelectModal";

const useStyles = makeStyles(() => ({
    viewer: {
      height: '100%',
      width: '100%',
      transition: 'width .3s ease-out',
      overflow: 'hidden',
      "& .rpv-core__inner-pages": {...scrollbarStyle},
      "& .rpv-core__page-layer": {
        '&::after': { all: 'revert' },
      },
      "& .rpv-core__canvas-layer": {
        boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
        scale: '0.96',
        zIndex: 1000,
        transition: 'scale 200ms',
        "&:hover": {
            scale: '0.98',
        },
      }
    },
    error: {
        backgroundColor: '#e53e3e',
        borderRadius: '0.25rem',
        color: '#fff',
        padding: '0.5rem',
    },
    button: {
        minWidth: 80,
        width: 'auto',
        height: 32,
        borderRadius: 32,
        border: `1px solid ${theme.colors.primary['200']}`,
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '0.85rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    modal: {
        width: '600px',
        height: 'fit-content',
        padding: 20,
        borderRadius: 32,
        marginTop: 8,
    },
}));

const DashboardDeck: React.FC<{ loadFromCacheBucket?: boolean }> = ({ loadFromCacheBucket = false }) => {
    const classes = useStyles();
    const { fileStructure, initialLoading } = useContext(FileStructureContext);
    const { dashboard, isPublicView } = useContext(DashboardContext);

    const [defaultDeck, setDefaultDeck] = useState<CompanyFile | undefined>(undefined);
    const [pdfBytes, setPdfBytes] = useState<Uint8Array | undefined>(undefined);
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState<boolean>(false);
    const [deckSelectModalOpen, setDeckSelectModalOpen] = useState<boolean>(false);

    const scrollModePluginInstance = scrollModePlugin();
    const viewerPlugins = [scrollModePluginInstance];

    const files = useMemo(() =>
        fileStructure.filter(file => !file.isDirectory && file.dashboardId === dashboard?.id) as CompanyFile[]
    , [dashboard, fileStructure]);

    useEffect(() => {
        if (!initialLoading && !!dashboard?.id) {
            if (isPublicView) {
                getPublicFilesForDashboardFunc(dashboard.id).then((files?: CompanyFile[]) => {
                    setDefaultDeck(files?.find(file => file.isDeck));
                });
            } else {
                setDefaultDeck(fileStructure.find(file => !file.isDirectory && file.dashboardId === dashboard.id && file.isDeck));
            }
        }
    }, [dashboard, fileStructure, initialLoading, isPublicView]);

    useEffect(() => {
      if (!!defaultDeck?.key) {
          if (loadFromCacheBucket) {
            s3DownloadExternalBucket({ key: defaultDeck?.key, bucket: awsConfig.aws_cache_bucket }).then(setPdfBytes);
          } else {
            s3Download({ key: defaultDeck?.key }).then(setPdfBytes)
              .catch(() => {
                fetch(defaultDeck?.key).then(data => {
                  data.arrayBuffer().then(r => setPdfBytes(new Uint8Array(r)))
                });
              });
          }
      }
    }, [defaultDeck, loadFromCacheBucket]);

    return (<>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" width="100%" marginLeft={4} marginBottom={2}>
            <Typography fontFamily="Inter" fontWeight="bold" fontSize="1.2rem" color={theme.colors.neutral['500']}>
                {'Deck'}
            </Typography>
            {!!files.length ? (<>
                <Button variant="outlined"
                    className={classes.button}
                    onClick={() => {
                        setDeckSelectModalOpen(true);
                    }}> {'Update...'} </Button>
            </>) : (<>
                <Button variant="outlined"
                    className={classes.button}
                    onClick={() => {
                        setFileUploadModalOpen(true);
                    }}> {'Add...'} </Button>
            </>)}
        </Stack>
        {defaultDeck && (<>
            <Stack className={classes.viewer} alignItems="center" justifyContent="center">
                {!!defaultDeck?.key && (pdfBytes ? (
                    <Viewer fileUrl={pdfBytes}
                        defaultScale={0.5}
                        plugins={viewerPlugins as any[]}
                        scrollMode={ScrollMode.Horizontal}
                        renderPage={(props) => <CustomPageRender renderPageProps={props} initialPage={0} />}
                        renderError={() => (
                            <Stack alignItems="center" justifyContent="center" height="100%">
                                <Typography className={classes.error}> {'Error loading the document'} </Typography>
                            </Stack>
                        )}
                    />
                ) : (
                    <CircularProgress />
                ))}
            </Stack>
        </>)}
        {deckSelectModalOpen && (
            <DashboardDeckSelectModal
                isOpen={deckSelectModalOpen}
                dashboard={dashboard!}
                onFileSelect={setDefaultDeck}
                onClose={() => setDeckSelectModalOpen(false)}/>
        )}
        {fileUploadModalOpen && (
            <FileUploaderModal
                open={fileUploadModalOpen}
                validFile={DEF_VALID_FILE}
                validPath={!!dashboard ? [dashboard.id, dashboard.title].join("/") : undefined}
                onClose={() => setFileUploadModalOpen(false)}
            />
        )}
    </>);
}

export default DashboardDeck;
