import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Select, MenuItem, SelectChangeEvent, Stack, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { CommentOriginType } from '../../../types/comments';
import { CompanyFile } from '../../../types/files';
import { GroupByEnum, SearchResult, SearchType } from '../../../types/search';

import FilePreview from './FilePreview';
import FileStructureNavigator from './FileStructureNavigator';
import SearchResultsList from './SearchResultsList';

const useStyles = makeStyles((theme) => ({
  resultNumber: {
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: 12
  },
  backButtonContainer: {
    "&:hover": {
      backgroundColor: theme.palette.info.main,
    },
    padding: 15,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    cursor: "pointer"
  },
  backButton: {
    color: theme.palette.primary.light,
    marginRight: 10,
    height: 20,
    width: 20,
  },
  backText: {
    color: theme.palette.text.primary
  },
  groupContainer: {
    marginBottom: 12
  },
  groupingSelect: {
    borderRadius: 4,
  },
  homePreviewArea: {
    width: "100%",
    marginLeft: 10
  }
}));

interface Props {
  keywords: string[];
  loading: boolean;
  searchResults: SearchResult[];// SearchResultsPerFile;
  titleSearchResults: CompanyFile[];
  selectedSearchResult?: SearchResult | CompanyFile;
  onClose: () => void;
  onResultSelection: (result: SearchResult | CompanyFile) => void;
  searchType: SearchType;
  searchFolder: CompanyFile | null;
  heightToSubtract: number;
  isHomeSearch?: boolean;
}

const SearchResultsLayout = ({
  keywords,
  loading,
  searchResults,
  titleSearchResults,
  selectedSearchResult,
  onClose,
  onResultSelection,
  searchType,
  searchFolder,
  heightToSubtract,
  isHomeSearch = false,
}: Props) => {
  const classes = useStyles();
  const [groupByType, setGroupByType] = useState<GroupByEnum>(GroupByEnum.Document);
  const searchResultsCount = searchResults.length > 0 ? searchResults.length : titleSearchResults.length;
  const noResults = searchResultsCount === 0 && !loading;

  const getFilePreviewArea = () => {
    return (
      <>
        {
          searchType === SearchType.Keyword ?
            <>
              {
                !loading && !selectedSearchResult ?
                  <Typography
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    Select result to view
                  </Typography>
                  :
                  <FilePreview
                    allowPartialLoad={true}
                    fileKey={(selectedSearchResult as SearchResult)?.fileKey || ""}
                    page={(selectedSearchResult as SearchResult)?.page}
                    highlightedKeywords={keywords}
                    enableComments
                    commentOriginType={CommentOriginType.Folder}
                  />
              }
            </>
            :
            <>
              {
                !loading && !selectedSearchResult ?
                  <Typography
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center">
                    Select result to view
                  </Typography>
                  :
                  <FilePreview
                    allowPartialLoad={true}
                    filePath={(selectedSearchResult as CompanyFile)?.path}
                    fileKey={(selectedSearchResult as CompanyFile)?.key}
                    enableComments
                    commentOriginType={CommentOriginType.Folder}
                  />
              }
            </>
        }
      </>
    )
  }

  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      maxWidth="calc(100vw - 84px)"
      maxHeight={`calc(100vh - ${heightToSubtract}px)`}
      display="flex"
      flexDirection="row"
    >
      <FileStructureNavigator isHomeSearch={isHomeSearch}>
        <Typography color="textPrimary" className={classes.resultNumber}>
          {searchResultsCount === 1
            ? '1 result'
            : `${searchResultsCount} results`}
        </Typography>
        {!isHomeSearch && <Paper className={classes.backButtonContainer} elevation={3} onClick={onClose}>
          <IconButton size="small" className={classes.backButton}>
            <ArrowBackIosNewIcon fontSize="inherit" />
          </IconButton>
          <Typography color="textPrimary" className={classes.backText}>
            Back to all files
          </Typography>
        </Paper>}
        {searchType === SearchType.Keyword &&
          <Stack direction="row" spacing={2} alignItems="center" className={classes.groupContainer}>
            <Typography color="textPrimary" sx={{ width: "23%" }}>Group by</Typography>
            <Select
              className={classes.groupingSelect}
              sx={{ width: "77%" }}
              size="small"
              variant="outlined"
              value={groupByType}
              onChange={(e: SelectChangeEvent) => {
                if (e.target.value === GroupByEnum.Document) {
                  setGroupByType(GroupByEnum.Document);
                } else {
                  setGroupByType(GroupByEnum.Content);
                }
              }}
            >
              <MenuItem value={GroupByEnum.Document}>Document</MenuItem>
              <MenuItem value={GroupByEnum.Content}>Content</MenuItem>
            </Select>
          </Stack>}
        <Box display="flex" alignItems="center" mt={1} mb={1}>
          {loading && (
            <Box ml={1} display="flex" justifyContent={"space-around"}>
              <CircularProgress size={16} />
              <Typography color="textPrimary" fontSize={12} marginLeft="5px">This may take a few seconds...</Typography>
            </Box>
          )}
        </Box>
        {
          !loading && <SearchResultsList
            searchFolder={searchFolder}
            searchResults={searchResults}
            titleSearchResults={titleSearchResults}
            selectedSearchResult={selectedSearchResult}
            onResultSelection={onResultSelection}
            groupByType={groupByType}
          />
        }
        {
          noResults &&
          <Typography align="center">No search results.</Typography>
        }
      </FileStructureNavigator>
      {
        isHomeSearch ?
          <Paper className={classes.homePreviewArea}>
            {getFilePreviewArea()}
          </Paper>
          :
          <>
            {getFilePreviewArea()}
          </>
      }
    </Box>
  );
};

export default SearchResultsLayout;
