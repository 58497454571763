import { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { Tooltip, Typography, Divider, Stack, Button } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LinkIcon from '@mui/icons-material/Link';
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import WorkspaceShareModal from "../../modals/workspaces/WorkspaceShareModal";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { Workspace } from "../../../types/files";
import useRoute from "../../../hooks/useRoute";
import theme from "../../../theme";
import useWorkspace from "../../../hooks/useWorkspace";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        width: 60,
        height: 36,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 20,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        transition: 'ease-in-out 300ms',
        "& > span": {
            margin: 'unset',
        },
    },
    linkIcon: {
        width: 26,
        height: 26,
        color: theme.palette.primary.main,
    },
    sharedIcon: {
        width: 28,
        height: 28,
        color: theme.colors.warning['500'],
    },
    sharedIconButton: {
        "&:hover": {
            background: theme.colors.primary['50'],
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    slimButton: {
        minWidth: 40,
        padding: 'unset',
        margin: 'unset',
        background: 'unset',
        "& > svg": {
            color: theme.colors.warning['500'],
        },
        "&:hover": {
            background: 'unset',
        }
    },
    inProgressButton: {
        "& > svg": {
            color: 'transparent',
        },
    },
    sharingText: {
        color: theme.colors.orange['500'],
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        textDecoration: 'none',
    },
    copyText: {
        color: theme.colors.neutral['600'],
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        textDecoration: 'none',
        textTransform: 'none',
    },
    stopSharingText: {
        color: theme.colors.error['500'],
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        textDecoration: 'none',
        textTransform: 'none',
    },
}));

const WorkspaceShare: React.FC<{
    workspace?: Workspace,
    hidden?: boolean,
    hiddenOnPrivate?: boolean,
    slim?: boolean,
}> = ({workspace, hidden, hiddenOnPrivate, slim}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { workspace: globalWorkspace } = useContext(DashboardsContext);
    const { generateWorkspaceSharingURL } = useRoute();
    const { sharePublicly } = useWorkspace();

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
    const [shared, setShared] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [workspace, globalWorkspace]);

    const handleSharePublic = useCallback(async () => {
        if (!!localWorkspace) {
            setShareModalOpen(false);
            setInProgress(true);
            await sharePublicly(true, localWorkspace);
        }
    // eslint-disable-next-line
    }, [localWorkspace, shared]);

    const handleCopy = useCallback(() => {
        enqueueSnackbar('Link copied to clipboard', {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            autoHideDuration: 4000,
        });
        setShow(false);
    // eslint-disable-next-line
    }, []);

    const handleSwitchSharing = useCallback(async (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (shared) {
            setInProgress(true);
            await sharePublicly(false, localWorkspace!);
        } else {
            setShareModalOpen(true);
        }
    // eslint-disable-next-line
    }, [localWorkspace, shared]);

    useEffect(() => {
        if (!!localWorkspace) {
            setShared(localWorkspace!.isPublic || false);
            setInProgress(false);
        }
    }, [localWorkspace]);

    if (hidden || !localWorkspace)
        return (<></>);

    if (hiddenOnPrivate && !localWorkspace?.isPublic)
        return (<></>);

    return (<>
        <Tooltip open={show}
            onOpen={() => setShow(shared)}
            onClose={() => setShow(false)}
            placement="bottom-end"
            title={
                <Stack spacing={1} alignItems="flex-start" justifyContent="center"
                    onClick={(e) => e.stopPropagation()}>
                    <Typography className={classes.sharingText} fontWeight="bold">
                        {'You are currently sharing this workspace.'}
                    </Typography>
                    <Divider sx={{ width: '100%' }} />
                    <CopyToClipboard
                        text={generateWorkspaceSharingURL(localWorkspace.id)}
                        options={{ format: "text/plain" }}
                        onCopy={handleCopy}>
                        <Button variant="text" component={Stack} direction="row" spacing={1} alignItems="center" justifyContent="flex-start !important"
                            width="100%" p="4px !important">
                            <ContentCopyIcon sx={{ width: 20, height: 20, marginLeft: '4px', color: theme.colors.neutral['600'] }} />
                            <Typography className={classes.copyText}>
                                {'Copy URL to workspace'}
                            </Typography>
                        </Button>
                    </CopyToClipboard>
                    <LoadingButton variant="text" component={Stack} direction="row" spacing={1} alignItems="center" justifyContent="flex-start !important"
                        width="100%" p="4px" onClick={handleSwitchSharing}>
                        <FiberManualRecordIcon sx={{ width: 20, height: 20, color: theme.colors.error['500'] }} />
                        <Typography className={classes.stopSharingText}>
                            {'Stop sharing'}
                        </Typography>
                    </LoadingButton>
                </Stack>
            }
            componentsProps={{tooltip:{sx:{
                display: shared ? 'flex' : 'none',
                width: 240,
                height: 'auto',
                padding: 2,
                overflow: 'hidden',
                borderRadius: 1,
                backgroundColor: '#fff',
                border: `1px solid ${theme.colors.neutral['100']}`,
                boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
                transition: '0.5s all ease-in-out',
            }}}}>
                <LoadingButton
                    variant={slim ? 'text': shared ? 'contained' : 'outlined'}
                    className={classNames(
                        classes.iconButton,
                        slim && classes.slimButton,
                        shared && classes.sharedIconButton,
                        inProgress && classes.inProgressButton,
                    )}
                    loading={inProgress}
                    onClick={handleSwitchSharing}>
                    <LinkIcon className={classNames(classes.linkIcon, shared && classes.sharedIcon)} />
                </LoadingButton>
        </Tooltip>
        {shareModalOpen &&  (
            <WorkspaceShareModal
                isOpen={shareModalOpen}
                workspace={localWorkspace}
                onShare={handleSharePublic}
                onClose={() => setShareModalOpen(false)}
            />
        )}
    </>);
}

export default WorkspaceShare;