import { useContext, useState } from "react";
import { Button, Grid, ListItemText, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SendIcon from '@mui/icons-material/Send';
import { HighlightArea } from "@react-pdf-viewer/highlight";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import { AuthContext } from "../../../../contexts/AuthContext";
import { getUserEmail } from "../../../../helpers/authUser";
import { createCommentFunc, createMentionFunc } from "../../../../lib/helper";
import classNames from "classnames";
import { LoadingButton } from "@mui/lab";
import { User } from "../../../../types/common";
import { Comment, CommentOriginType } from "../../../../types/comments";
import { getUserDisplayName } from "../../../../helpers/comments";
import { DashboardUid } from "../../../../types/files";

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 4,
        padding: 8,
        marginLeft: 'auto',
        margin: 10,
    },
    textArea: {
        fontFamily: theme.typography.fontFamily,
        marginBottom: 10,
        padding: 0,
        width: '100%',
        "& > div": {
            "& textarea": {
                borderRadius: 4,
                border: `2px solid ${theme.palette.text.disabled}`
            },
        },
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    button: {
        marginRight: 10,
        borderRadius: 20,
    },
    listItem: {
        padding: 0,
    },
    mention: {
        fontWeight: 'bold !important',
        color: theme.palette.secondary.main,
        position: "relative",
        zIndex: 1,
        textShadow: "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
        pointerEvents: "none"
    },
    mentionFocused: {
        backgroundColor: theme.palette.action.hover,
    }
}));

const AddReplyPane: React.FC<
    {
        commentOriginType: CommentOriginType,
        documentId: string,
        users: User[],
        onAdded: (item: Comment) => void
        onCancel: () => void,
        dashboardItem?: DashboardUid,
        parentId: string | undefined,
        parentCommentedItem: HighlightArea[],
        isSecondReply?: boolean,
    }> = ({ commentOriginType, documentId, users, onAdded, onCancel, dashboardItem, parentId, parentCommentedItem, isSecondReply }) => {
        const classes = useStyles();
        const [loading, setLoading] = useState<boolean>(false);
        const [content, setContent] = useState<string>("");
        const [mentions, setMentions] = useState<string[]>([]);

        const { user, userGroup } = useContext(AuthContext);

        const add = () => {
            if (!content) {
                return;
            }

            setLoading(true);
            const group = userGroup;
            const currentEmail = getUserEmail(user);
            const item = {
                email: currentEmail,
                group,
                project: group,
                documentId: documentId,
                dashboardId: dashboardItem ? dashboardItem.id : null,
                usersMentioned: mentions,
                description: content,
                text: content.toLocaleLowerCase(),
                parentId: parentId,
                commentedItem: parentCommentedItem,
                originType: commentOriginType,
                originUrl: dashboardItem ? JSON.stringify(dashboardItem) : null
            } as Comment;
            createCommentFunc(item).then((result: any) => {
                Promise.all(mentions.map(async (mentionUserId) => {
                    await createMentionFunc({
                        email: currentEmail,
                        group,
                        emailOfPersonWhoMentioned: mentionUserId,
                        commentId: result.id
                    });
                })).then(() => {
                    setLoading(false);
                    if (result) {
                        onAdded(result);
                    }
                    onCancel();
                });
            });
        }

        const searchUsers = (query: string, callback: (data: SuggestionDataItem[]) => void) => {
            if (!query) {
                callback([]);
                return;
            }

            callback(users
                .filter(x => x?.email?.includes(query) || x?.firstName?.includes(query) || x?.lastName?.includes(query))
                .slice(0, 5)
                .map(x => ({ id: x.email, display: getUserDisplayName(x) })));
        }

        return <Paper
            sx={{ width: isSecondReply ? "100%" : "80%" }}
            className={classes.container}
        >
            <MentionsInput
                value={content}
                onChange={(e, _1, _2, mentions) => { setContent(e.target.value); setMentions(mentions.map(x => x.id)); }}
                singleLine={false}
                rows={3}
                className={classes.textArea}
            >
                <Mention
                    trigger="@"
                    data={searchUsers}
                    displayTransform={(_, display) => `@${display}`}
                    className={classes.mention}
                    renderSuggestion={(suggestion: SuggestionDataItem, _1, _2, _3, focused) => (
                        <Grid alignItems="flex-start" className={classNames(classes.listItem, { [classes.mentionFocused]: focused })}>
                            <ListItemText
                                primary={suggestion.display}
                            />
                        </Grid>
                    )}
                />
            </MentionsInput>
            <div className={classes.buttonsContainer}>
                <Button
                    size="small"
                    className={classes.button}
                    disabled={loading}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <LoadingButton
                    className={classes.button}
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={add}
                    startIcon={<SendIcon />}
                >
                    Post
                </LoadingButton>
            </div>
        </Paper>
    };

export default AddReplyPane;
