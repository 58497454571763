import { Stack, Typography } from "@mui/material";
import MetricsQuery from "../../molecules/dashboard-queries/MetricsQuery";
import AddSection from "../../atoms/AddSection";
import theme from "../../../theme";

export const Column = 'Latest Metrics';

const KeyMetricsColumnQueries: React.FC<{}> = () => {
    return (<>
        <Stack spacing={2} alignItems="flex-start" width="20%">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                <Typography variant="b1-semibold" color={theme.colors.neutral['400']}>
                    {Column}
                </Typography>
                <AddSection column={Column} />
            </Stack>
            <MetricsQuery />
        </Stack>
    </>);
}

export default KeyMetricsColumnQueries;