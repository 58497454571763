import { Fragment } from "react";
import { Skeleton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    queryWaveAnimation: {
        borderRadius: 4,
        height: 30,
    },
    queryWaveDigest: {
        background: 'linear-gradient(90deg, rgba(123,212,212,0.5) 10%, rgba(123,212,212,0.4) 40%, rgba(123,212,212,0.1) 90%)',
        '&:after': {
            background: 'linear-gradient(90deg, rgba(123,211,212,0.3) 10%, rgba(123,211,212,0.7) 40%, rgba(123,211,212,0.4) 80%)',
        }
    },
}));

const SkeletonLoader: React.FC<{
    variant: string,
    rowCount: number,
    cropLast?: boolean,
}> = ({variant, rowCount, cropLast}) => {
    const classes = useStyles();

    return (<>{
        [...Array(rowCount)].map((_, i) =>
            <Fragment key={'dashboard-queries-component-loading-121-' + i}>
                <Skeleton
                    animation="wave"
                    className={classnames(classes.queryWaveAnimation, variant === 'wide' && classes.queryWaveDigest)}
                    sx={{width: cropLast && (i === rowCount - 1) ? '50%' : '100%' }}
                />
            </Fragment>
        )
    }</>);
}

export default SkeletonLoader;
