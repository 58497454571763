import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useHistory } from "react-router-dom";
import { useState } from "react";
import CreateEditInvestorModal from "../../modals/investors/CreateEditInvestorModal";
import BulkAddWorkspacesModal from "../../modals/home/BulkAddWorkspacesModal";

const useStyles = makeStyles((theme) => ({
    image: {
        width: 150,
        height: 120,
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.25rem',
        fontWeight: 700,
    },
    subText: {
        fontFamily: 'Lato',
        fontSize: '1.1rem',
        fontWeight: 400,
        color: theme.colors.neutral['600'],
    },
    otherText: {
        fontFamily: 'Lato',
        fontSize: '1.1rem',
        fontWeight: 'normal',
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
    bulkIcon: {
        width: 24,
        height: 24,
    },
    bulkAddButton: {
        minWidth: 210,
        borderRadius: 24,
        fontFamily: 'Lato',
        fontSize: '0.95rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
    createButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const NoWorkspacesFoundView: React.FC<{
    noResult?: boolean,
    isPublic?: boolean,
}> = ({ noResult, isPublic }) => {
    const classes = useStyles();
    const history = useHistory();
    const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState<boolean>(false);
    const [bulkAddModalOpen, setBulkAddModalOpen] = useState<boolean>(false);

    return (<>
        <Stack alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 300px)">
            <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
            {noResult ? (<>
                {isPublic ? (<>
                    <Typography className={classes.mainText} mt={4}>{'No investors yet!'}</Typography>
                    <Stack alignItems="center" justifyContent="center" width="100%">
                        <Typography className={classes.otherText}>
                            {'Add investors from your network so you can easily share deals with them based on their preferences.'}
                        </Typography>
                    </Stack>
                </>) : (<>
                    <Typography className={classes.mainText} mt={4}>{'No investors found!'}</Typography>
                </>)}
            </>) : (<>
                <Typography className={classes.mainText} mt={4}>{'No investors yet.'}</Typography>
                <Stack spacing={1} alignItems="center" justifyContent="center" width="100%" height="fit-content" mt={3}>
                    <Typography className={classes.subText}>
                        {'Add an investor below'}
                    </Typography>
                    <Button variant="outlined"
                        className={classes.createButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setCreateWorkspaceModalOpen(true);
                        }}
                    >{`+  Add an investor`} </Button>
                </Stack>
                <Stack spacing={1} alignItems="center" justifyContent="center" width="100%" height="fit-content" mt={3}>
                    <Typography className={classes.subText}>
                        {'or add a list of investors via CSV '}
                    </Typography>
                    <Button variant="contained"
                        className={classes.bulkAddButton}
                        startIcon={<FormatListBulletedIcon className={classes.bulkIcon} />}
                        onClick={() => setBulkAddModalOpen(true)}>
                        {'Add list of investors'}
                    </Button>
                </Stack>
            </>)}
        </Stack>
        <CreateEditInvestorModal
            isOpen={createWorkspaceModalOpen}
            onClose={(workspaceId) => {
                if (workspaceId)
                    history.push(`/workspaces/${workspaceId}`);
                setCreateWorkspaceModalOpen(false);
            }}
        />
        <BulkAddWorkspacesModal
            isOpen={bulkAddModalOpen}
            onClose={() => setBulkAddModalOpen(false)}
        />
    </>);
}

export default NoWorkspacesFoundView;