
import React, {
    useContext,
    createContext,
    useState,
    MouseEventHandler,
    useEffect
} from 'react';

//import config from './Config';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { getUser, getSite } from '../services/graphService';

// <AppContextSnippet>
export interface AppUser {
    displayName?: string,
    email?: string,
    avatar?: string,
    timeZone?: string,
    timeFormat?: string,
};

export interface AppError {
    message: string,
    debug?: string
};

type AppContext = {
    siteId?: string;
    user?: AppUser;
    error?: AppError;
    signIn?: MouseEventHandler<HTMLElement>;
    signOut?: MouseEventHandler<HTMLElement>;
    displayError?: Function;
    clearError?: Function;
    authProvider?: AuthCodeMSALBrowserAuthenticationProvider;
}

const appContext = createContext<AppContext>({
    siteId: undefined,
    user: undefined,
    error: undefined,
    signIn: undefined,
    signOut: undefined,
    displayError: undefined,
    clearError: undefined,
    authProvider: undefined
});

export function useAppContext(): AppContext {
    return useContext(appContext);
}

interface ProvideAppContextProps {
    children: React.ReactNode;
}
const SCOPES = [
    'user.read',
    'sites.read.all'
]
export default function ProvideAppContext({ children }: ProvideAppContextProps) {
    const auth = useProvideAppContext();
    return (
        <appContext.Provider value={auth}>
            {children}
        </appContext.Provider>
    );
}
// </AppContextSnippet>

function useProvideAppContext() {
    const [user, setUser] = useState<AppUser | undefined>(undefined);
    const [siteId, setSiteId] = useState<string | undefined>(undefined);
    const [error, setError] = useState<AppError | undefined>(undefined);

    const msal = useMsal();

    const displayError = (message: string, debug?: string) => {
        setError({ message, debug });
    }

    const clearError = () => {
        setError(undefined);
    }
    const retrieveAndSetInformation = async () => {
        const [user, site] = await Promise.all([getUser(authProvider), getSite(authProvider)])
        setUser({
            displayName: user.displayName || '',
            email: user.mail || user.userPrincipalName || '',
            timeFormat: user.mailboxSettings?.timeFormat || 'h:mm a',
            timeZone: user.mailboxSettings?.timeZone || 'UTC',
        });
        setSiteId(site?.siteCollection?.hostname || undefined)
    }


    // <AuthProviderSnippet>
    // Used by the Graph SDK to authenticate API calls
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance as PublicClientApplication,
        {
            account: msal.instance.getActiveAccount()!,
            scopes: SCOPES,
            interactionType: InteractionType.Popup
        }
    );
    // </AuthProviderSnippet>

    // <UseEffectSnippet>
    useEffect(() => {
        const checkUser = async () => {
            if (!user) {
                try {
                    // Check if user is already signed in
                    const account = msal.instance.getActiveAccount();
                    if (account) {
                        // Get the user from Microsoft Graph
                        retrieveAndSetInformation()
                    }
                } catch (err: any) {
                    console.log(err)
                    displayError(err.message);
                }
            }
        };
        checkUser();
    });
    // </UseEffectSnippet>

    // <SignInSnippet>
    const signIn = async () => {
        await msal.instance.loginPopup({
            scopes: SCOPES,
            prompt: 'select_account'
        });

        retrieveAndSetInformation()
    };
    // </SignInSnippet>

    // <SignOutSnippet>
    const signOut = async () => {
        await msal.instance.logoutPopup();
        setUser(undefined);
        setSiteId(undefined)
    };
    // </SignOutSnippet>

    return {
        siteId,
        user,
        error,
        signIn,
        signOut,
        displayError,
        clearError,
        authProvider
    };
}