import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import { Tooltip, Typography } from "@mui/material";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useSnackbar } from "notistack";
import {AuthContext} from "../../../contexts/AuthContext";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import { addWorkspaceToDashboardFunc, removeWorkspaceFromDashboardFunc } from "../../../lib/helper";
import { Dashboard } from "../../../types/files";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        width: 60,
        height: 36,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 20,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        transition: 'ease-in-out 300ms',
        "& > span": {
            margin: 'unset',
        }
    },
    saveIcon: {
        width: 24,
        height: 24,
        color: theme.palette.primary.main,
    },
}));

const DashboardAddToWorkspaceButton: React.FC<{
    dashboard: Dashboard,
    workspaceId?: string,
}> = ({ dashboard, workspaceId }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userGroup } = useContext(AuthContext);
    const { workspaces } = useContext(GroupSettingsContext);
    const { isPublicView, workspace: globalWorkspace, dashboards } = useContext(DashboardsContext);
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [currentlyAssigned, setCurrentlyAssigned] = useState<boolean>(false);

    const localWorkspace = useMemo(() => workspaces.find(ws => ws.id === workspaceId) ?? globalWorkspace, [globalWorkspace, workspaceId, workspaces]);

    const handleAssigntoWorkspace = useCallback(() => {
        if (!localWorkspace)
            return;

        setLoading(true);
        addWorkspaceToDashboardFunc({
            dashboardId: dashboard.id,
            workspaceId: localWorkspace.id,
            group: userGroup,
        }).then((dashboardData: Dashboard) => {
            dashboard.workspaces = dashboardData.workspaces;
            dashboard.workspaceMemberships = dashboardData.workspaceMemberships;
            enqueueSnackbar(`${dashboard.title} has been successfully added to ${localWorkspace.name}`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                autoHideDuration: 4000,
            });
            setCurrentlyAssigned(prev => !prev);
            setLoading(false);
        });
    // eslint-disable-next-line
    }, [dashboard, dashboards, userGroup, localWorkspace]);

    const handleRemoveFromWorkspace = useCallback(async () => {
        if (!localWorkspace)
            return;

        setLoading(true);
        removeWorkspaceFromDashboardFunc({
            dashboardId: dashboard.id,
            workspaceId: localWorkspace?.id,
            group: userGroup,
        }).then((dashboardData: Dashboard) => {
            dashboard.workspaces = dashboardData.workspaces;
            dashboard.workspaceMemberships = dashboardData.workspaceMemberships;
            enqueueSnackbar(`${dashboard.title} has been successfully removed from ${localWorkspace.name}`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                autoHideDuration: 4000,
            });
            setCurrentlyAssigned(prev => !prev);
            setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboard, localWorkspace, userGroup]);

    useEffect(() => {
        setCurrentlyAssigned(dashboardWorkspaces(dashboard)?.includes(localWorkspace?.id || ''));
    // eslint-disable-next-line
    }, []);

    if (isPublicView || !localWorkspace)
        return (<></>);

    return (<>
        <Tooltip open={show}
            onOpen={() => setShow(true)}
            onClose={() => setShow(false)}
            placement="top"
            title={<Typography fontSize="0.85rem">
                    {currentlyAssigned ? 'Remove from saved' : 'Add to saved'}
                </Typography>}
            componentsProps={{tooltip:{sx:{
                paddingLeft: 2, paddingRight: 2,
                backgroundColor: 'rgba(97, 97, 97)',
            }}}}>
            <LoadingButton
                variant="outlined"
                className={classes.iconButton}
                startIcon={!loading && (
                    currentlyAssigned ? (<BookmarkIcon className={classes.saveIcon} />)
                        : (<BookmarkBorderIcon className={classes.saveIcon} />)
                )}
                loading={loading}
                onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShow(false);
                    if (currentlyAssigned)
                        handleRemoveFromWorkspace();
                    else
                        handleAssigntoWorkspace();

                }}
            />
        </Tooltip>
    </>);
}

export default DashboardAddToWorkspaceButton;