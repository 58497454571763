import { Box, Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardsSearchOrCreate from "../dashboards-search-create/DashboardsSearchOrCreate";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useHistory } from "react-router-dom";
import BulkAddDashboardsModal from "../../modals/home/BulkAddDashboardsModal";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    image: {
        width: 150,
        height: 120,
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.25rem',
        color: theme.colors.neutral['800'],
        fontWeight: 700,
    },
    subText: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['700'],
        textAlign: 'center',
    },
    otherText: {
        fontFamily: 'Lato',
        fontSize: '1.1rem',
        fontWeight: 'normal',
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
    bulkIcon: {
        width: 24,
        height: 24,
    },
    bulkAddButton: {
        minWidth: 210,
        borderRadius: 24,
        fontFamily: 'Lato',
        fontSize: '0.95rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
}));

const NoDashboardsFoundView: React.FC<{
    noResult?: boolean,
    noMatch?: boolean,
    isPublic?: boolean
}> = ({ noResult, noMatch, isPublic }) => {
    const classes = useStyles();
    const history = useHistory();
    const [bulkAddModalOpen, setBulkAddModalOpen] = useState<boolean>(false);

    return (<>
        <Stack alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 300px)">
            <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
            {noResult ? (<>
                {isPublic ? (<>
                    <Typography className={classes.mainText} mt={4}>{'No companies yet!'}</Typography>
                    <Stack alignItems="center" justifyContent="center" width="100%">
                        <Typography className={classes.otherText}>
                            {'Add companies from your deal flow to see fully enriched dashboards for each deal.'}
                        </Typography>
                    </Stack>
                </>) : (<>
                    <Typography className={classes.mainText} mt={4}>{'No companies found!'}</Typography>
                </>)}
            </>) : (noMatch) ? (<>
                <Typography className={classes.mainText} mt={4}>{'No top matches yet!'}</Typography>
                <Stack spacing={1} alignItems="center" justifyContent="center" width="100%" height="fit-content" mt={3}>
                    <Typography className={classes.subText}>
                        {'Update and/or save the investor profile'}
                        <br />
                        {'to view top matches.'}
                    </Typography>
                </Stack>
            </>) : (<>
                <Typography className={classes.mainText} mt={4}>{'No companies yet.'}</Typography>
                <Stack spacing={1} alignItems="center" justifyContent="center" width="100%" height="fit-content" mt={3}>
                    <Typography className={classes.subText}>
                        {'Add a company below'}
                    </Typography>
                    <Box minWidth="20%">
                        <DashboardsSearchOrCreate create mediumFit
                            onCreate={(dashboard) => history.push(`/dashboards/${dashboard.id}`)}/>
                    </Box>
                </Stack>
                <Stack spacing={1} alignItems="center" justifyContent="center" width="100%" height="fit-content" mt={3}>
                    <Typography className={classes.subText}>
                        {'or add a list of companies via CSV '}
                    </Typography>
                    <Button variant="contained"
                        className={classes.bulkAddButton}
                        startIcon={<FormatListBulletedIcon className={classes.bulkIcon} />}
                        onClick={() => setBulkAddModalOpen(true)}>
                        {'Add list of companies'}
                    </Button>
                </Stack>
            </>)}
        </Stack>
        <BulkAddDashboardsModal
            isOpen={bulkAddModalOpen}
            onClose={() => setBulkAddModalOpen(false)}
        />
    </>);
}

export default NoDashboardsFoundView;