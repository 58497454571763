import ReactPDF, { StyleSheet, Font } from '@react-pdf/renderer';
import theme from "../../../theme";

Font.register({ family: 'Lato', fonts: [
    { src: '/assets/fonts/Lato-Regular.ttf', fontWeight: 100  },
    { src: '/assets/fonts/Lato-Regular.ttf', fontWeight: 200  },
    { src: '/assets/fonts/Lato-Regular.ttf', fontWeight: 300  },
    { src: '/assets/fonts/Lato-Regular.ttf', fontWeight: 400  },
    { src: '/assets/fonts/Lato-Bold.ttf', fontWeight: 500  },
    { src: '/assets/fonts/Lato-Bold.ttf', fontWeight: 600  },
    { src: '/assets/fonts/Lato-Bold.ttf', fontWeight: 700  },
    { src: '/assets/fonts/Lato-Bold.ttf', fontWeight: 800  },
    { src: '/assets/fonts/Lato-Bold.ttf', fontWeight: 900  },
]});

Font.register({ family: 'Inter', fonts: [
    { src: '/assets/fonts/Inter/inter-v12-latin-100.ttf', fontWeight: 100 },
    { src: '/assets/fonts/Inter/inter-v12-latin-200.ttf', fontWeight: 200 },
    { src: '/assets/fonts/Inter/inter-v12-latin-300.ttf', fontWeight: 300 },
    { src: '/assets/fonts/Inter/inter-v12-latin-400.ttf', fontWeight: 400 },
    { src: '/assets/fonts/Inter/inter-v12-latin-500.ttf', fontWeight: 500 },
    { src: '/assets/fonts/Inter/inter-v12-latin-600.ttf', fontWeight: 600 },
    { src: '/assets/fonts/Inter/inter-v12-latin-700.ttf', fontWeight: 700 },
    { src: '/assets/fonts/Inter/inter-v12-latin-800.ttf', fontWeight: 800 },
    { src: '/assets/fonts/Inter/inter-v12-latin-900.ttf', fontWeight: 900 },
]});

Font.register({ family: 'Poppins', fonts: [
    { src: '/assets/fonts/Poppins/Poppins-Thin.ttf', fontWeight: 100 },
    { src: '/assets/fonts/Poppins/Poppins-ExtraLight.ttf', fontWeight: 200 },
    { src: '/assets/fonts/Poppins/Poppins-Light.ttf', fontWeight: 300 },
    { src: '/assets/fonts/Poppins/Poppins-Regular.ttf', fontWeight: 400 },
    { src: '/assets/fonts/Poppins/Poppins-Medium.ttf', fontWeight: 500 },
    { src: '/assets/fonts/Poppins/Poppins-SemiBold.ttf', fontWeight: 600 },
    { src: '/assets/fonts/Poppins/Poppins-Bold.ttf', fontWeight: 700 },
    { src: '/assets/fonts/Poppins/Poppins-ExtraBold.ttf', fontWeight: 800 },
    { src: '/assets/fonts/Poppins/Poppins-ExtraBold.ttf', fontWeight: 900 },
]});

const pageStyles: ReactPDF.Styles = {
    page: {
        paddingTop: 24,
        paddingBottom: 80,
        paddingLeft: 32,
        paddingRight: 32,
    },
};

const headerStyles: ReactPDF.Styles = {
    header: {
        position: 'relative',
        marginBottom: 8,
    },
    headerTitle: {
        fontFamily: 'Poppins',
        fontSize: 24,
        fontWeight: 700,
    },
    headerMain: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    headerSub: {
        width: '100%',
        height: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    headerChip: {
        minWidth: 64,
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 6,
        paddingBottom: 6,
        borderTopLeftRadius: 32,
        borderTopRightRadius: 32,
        borderBottomLeftRadius: 32,
        borderBottomRightRadius: 32,
    },
    headerChipText: {
        fontFamily: 'Lato',
        fontSize: 10,
        fontWeight: 700,
        color: '#666666',
        textTransform: 'capitalize',
    },
    topDivider: {
        borderBottom: `1px solid ${theme.colors.cyan['400']}`,
        marginTop: 8,
    },
};

const bodyStyles: ReactPDF.Styles = {
    body: {
        position: 'relative',
    },
    query: {
        marginVertical: 8,
    },
    queryTitle: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 600,
    },
    queryAnswer: {
        marginTop: 8,
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 400,
        lineHeight: 1.5,
    },
    sentence: {
        fontFamily: 'Inter',
        fontSize: 10,
        lineHeight: 1.5,
    },
    link: {
        fontFamily: 'Inter',
        fontWeight: 500,
        color: theme.colors.cyan['500'],
        textDecoration: 'none',
    },
    linkContent: {
        marginTop: 8,
    },
    metricsBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 4,
        marginVertical: 4,
    },
    fundingBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent:'space-between',
    },
    fundingLastRound: {
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 700,
    },
    fundingPriorRound: {
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 700,
    },
    metricText: {
        fontFamily: 'Inter',
        fontSize: 10,
        fontWeight: 700,
        color: theme.colors.cyan['500'],
    }
};

const footerStyles: ReactPDF.Styles = {
    footer: {
        position: 'absolute',
        bottom: 24,
        left: 32,
        right: 32,
        marginTop: 8,
    },
    footerLogo: {
        width: 64,
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginVertical: 8,
    },
    footerSub: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    footerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    footerSignup: {
        fontFamily: 'Lato',
        fontSize: 10,
        fontWeight: 400,
        color: theme.colors.neutral['600'],
        textDecoration: 'none',
    },
    footerText: {
        fontFamily: 'Lato',
        fontSize: 10,
        fontWeight: 400,
        color: theme.colors.cyan['400'],
        textDecoration: 'none',
    },
    bottomDivider: {
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        marginVertical: 8,
    },
};

const styles = StyleSheet.create({
    ...pageStyles,
    ...headerStyles,
    ...bodyStyles,
    ...footerStyles,
});

export default styles;