import React, { useCallback, useMemo } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CircleIcon from '@mui/icons-material/Circle';
import { useParams } from "react-router-dom";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
    scoreIcon: {
        width: 12,
        height: 12,
    },
}));

const ThesisScore: React.FC<{
    workspaceId?: string,
    investmentTheses: {
        workspaceId: string,
        score: number,
    }[],
    disabled?: boolean,
    flags?: any,
}> = ({ workspaceId, investmentTheses, disabled, flags }) => {
    const classes = useStyles();
    const params: {[key: string]: string} = useParams();

    const localWorkspaceId = useMemo(() => params?.workspaceId || workspaceId, [params?.workspaceId, workspaceId]);

    const score = useMemo(() =>
        investmentTheses?.find(iT => iT?.workspaceId === localWorkspaceId)?.score ?? 0
    , [investmentTheses, localWorkspaceId]);

    const fitnessTip = useCallback(() => {
        const points = Math.round(score || 0);

        switch (points) {
            case 4:
                return 'Strong thesis fit';
            case 3:
                return 'Good thesis fit';
            case 2:
                return 'Possible thesis fit';
            case 1:
            default:
                return 'No thesis fit';
        }
    }, [score]);

    const fitnessColor = useCallback((index: number) => {
        const points = Math.round(score || 0);

        if (index < points) {
            if (disabled) {
                if (points >= 1)
                    return theme.colors.neutral['500'];
                else
                    return 'lightgray';
            } else {
                switch (points) {
                    case 4:
                        return 'limegreen';
                    case 3:
                        return 'limegreen';
                    case 2:
                        return 'orange';
                    case 1:
                        return 'red';
                    default:
                        return 'lightgray';
                }
            }
        }

        return 'lightgray';
    }, [disabled, score]);

    return (<>
        {(flags.thesisScore && !!score) && (
            <Tooltip
                placement="top-start"
                title={<Typography fontSize="0.85rem">{fitnessTip()}</Typography>}
                componentsProps={{tooltip:{sx:{
                    paddingLeft: 2, paddingRight: 2,
                    backgroundColor: 'rgba(97, 97, 97)',
                }}}}>
                <Stack direction="row" spacing="0.25rem" alignItems="center">
                    {[...Array(4)].map((_, i) => (
                        <CircleIcon
                            className={classes.scoreIcon}
                            sx={{ fill: fitnessColor(i) }}
                            key={'thesis-score-82-' + i} />
                    ))}
                </Stack>
            </Tooltip>
        )}
    </>);
}

export default withLDConsumer()(ThesisScore);