import React, { useEffect, useRef, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import classNames from "classnames";
import { SearchKeywordOperation } from "../../../../types/search";
import { Box, Button, Chip, Input, Typography } from "@mui/material";
import { parseKeywords } from "./SearchTermUtils";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
    andOrButton: {
        alignSelf: "center",
        padding: 6,
        minWidth: "35px",
        height: "30px",
        marginRight: "5px",
        borderRadius: 54,
    },
    andOrButtonPrimary: { // TODO: check if will be necessary
        color: "#fff",
        border: "1px solid #fff",
    },
    andOrButtonAccent: { // TODO: check if will be necessary
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: "#fff",
    },
    input: {
        ...theme.typography.h6,
        position: 'relative',
        margin: `0 ${theme.spacing(0.5)}`,
        '&::before': {
            border: 'none',
        },
        '&:hover': {
            '&::before': {
                border: 'none !important',
            },
        },
        minWidth: "3px",
        fontSize: "20px !important"
    },
    inputField: {
        position: 'absolute',
        '&::placeholder': {
            color: grey[500],
            opacity: 1,
        },
        minWidth: "10px",
        fontSize: "20px !important"
    },
    initialInputField: {
        position: 'absolute',
        '&::placeholder': {
            color: grey[500],
            opacity: 1,
        },
        minWidth: "10px",
        fontSize: "20px !important"
    },
    inputFieldEmpty: {
        height: "32px",
    },
    inputSpacing: {
        visibility: 'hidden',
        whiteSpace: 'pre',
    },
    tag: {
        height: "fit-content",
        margin: `0 ${theme.spacing(0.5)}`,
        whiteSpace: 'nowrap',
    },
    andOrTag: {
        height: "fit-content",
        margin: `0 ${theme.spacing(0.5)}`,
        whiteSpace: 'nowrap',
    },
    keywordsTextContainer: {
        width: "100%",
        height: 40,
        display: "flex",
        flexDirection: "column"
    },
    textContainer: {
        display: 'flex'
    },
    hide: {
        display: "none"
    }
}));

const SearchTerms: React.FC<{
    inputId?: string,
    sourceKeywords: string[][],
    sourceSearchPhrase: string[],
    finishedEditing?: boolean,
    placeholder?: string,
    addPlaceholder?: string,
    afterMessage?: string,
    primaryBackground?: boolean,
    appearDisabled?: boolean,
    onChange: (keywords: string[][], phrase: string[]) => void,
    onInputChange?: (value: string) => void,
    onSearch?: () => void
}> = ({ inputId = '', sourceKeywords, sourceSearchPhrase, finishedEditing, placeholder = "", addPlaceholder = "", afterMessage, primaryBackground, appearDisabled = true, onChange, onInputChange, onSearch }) => {
    const classes = useStyles();

    const [inputValue, setInputValue] = useState('');
    const ref = useRef<HTMLInputElement>(null);
    const lastKeywordRef = useRef<HTMLInputElement>(null);
    const [isLastOperationMutation, setIsLastOperationMutation] = useState<boolean>(false);
    const [nextFocusKeywordIndex, setNextFocusKeywordIndex] = useState<number>(0);
    const [currentFocusKeywordIndex, setCurrentFocusKeywordIndex] = useState<number | undefined>(undefined);
    const [keywords, setKeywords] = useState<string[][]>([]);
    const [keywordsAndOperations, setKeywordsAndOperations] = useState<string[]>([]);

    useEffect(() => {
        if (sourceKeywords?.length !== keywords?.length) {
            setKeywords(sourceKeywords);
        }
        // eslint-disable-next-line
    }, [sourceKeywords]);

    useEffect(() => {
        if (sourceSearchPhrase?.length !== keywordsAndOperations?.length) {
            setKeywordsAndOperations(sourceSearchPhrase);
        }
        // eslint-disable-next-line
    }, [sourceSearchPhrase]);

    useEffect(() => {
        if (finishedEditing) {
            const added = addKeyword(undefined);
            setIsLastOperationMutation(true);

            if (!added) {
                onChange(keywords, keywordsAndOperations);
            }
        }
        // eslint-disable-next-line
    }, [finishedEditing]);

    useEffect(() => {
        if (!keywords.length &&
            !sourceKeywords.length &&
            !keywordsAndOperations.length &&
            !sourceSearchPhrase.length) {
            return;
        }

        onChange(keywords, keywordsAndOperations);
        //eslint-disable-next-line
    }, [keywords, keywordsAndOperations]);

    useEffect(() => {
        if (onInputChange) {
            onInputChange(inputValue);
        }
        // eslint-disable-next-line
    }, [inputValue]);

    const computeKeywords = (newKeywordsAndOperations: string[]) => {
        const newKeywords = parseKeywords(newKeywordsAndOperations);
        setKeywords(newKeywords);
        return newKeywords;
    }

    const addKeyword = (operation: SearchKeywordOperation | undefined): boolean => {
        if (!operation && !inputValue) {
            return false;
        }

        let insertIndex = currentFocusKeywordIndex === undefined ? undefined : currentFocusKeywordIndex + 1;
        if (insertIndex) {
            onKeywordAdd("", operation, insertIndex);
            setNextFocusKeywordIndex(insertIndex + 1);
            setIsLastOperationMutation(true);
            return true;
        }

        setIsLastOperationMutation(false);

        if (inputValue) {
            onKeywordAdd(inputValue, operation);
            setInputValue("");
        } else if (isLastItemAKeyword()) {
            onKeywordAdd("", operation || SearchKeywordOperation.And);
        }

        ref.current?.focus();

        return true;
    };

    const onKeywordAdd = (newKeyword: string, operation: SearchKeywordOperation | undefined, index?: number | undefined) => {
        let newKeywordsAndOperations = [];

        if (index !== undefined && operation) {
            newKeywordsAndOperations = [...keywordsAndOperations];

            newKeywordsAndOperations.splice(index, 0, `[${operation.toUpperCase()}]`, "");
        } else {
            if (!newKeyword && operation) {
                newKeywordsAndOperations = [...keywordsAndOperations, `[${operation.toUpperCase()}]`];
            } else if (operation) {
                newKeywordsAndOperations = [...keywordsAndOperations, newKeyword, `[${operation.toUpperCase()}]`];
            } else {
                newKeywordsAndOperations = [...keywordsAndOperations, newKeyword];
            }
        }
        setKeywordsAndOperations(newKeywordsAndOperations);
        return computeKeywords(newKeywordsAndOperations);
    }

    const updateSearchKeyword = (keywordValue: string, index: number) => {
        let newKeywordsAndOperations = [...keywordsAndOperations];

        newKeywordsAndOperations[index] = keywordValue;
        setKeywordsAndOperations(newKeywordsAndOperations);
        computeKeywords(newKeywordsAndOperations);
    }

    const removeKeyword = (index?: number) => {
        if (keywordsAndOperations && keywordsAndOperations.length) {
            if (index && index > 0) {
                keywordsAndOperations.splice(index - 1, 2);
            } else if (index === 0) {
                keywordsAndOperations.splice(index, 2);
            }
            else {
                keywordsAndOperations.splice(keywordsAndOperations.length - 1, 1);
            }
            setKeywordsAndOperations(keywordsAndOperations);
            computeKeywords(keywordsAndOperations);
        }
    }

    const isOperator = (value: string) => {
        return ["[AND]", "[OR]"].includes(value);
    }

    const onEnter = () => {
        if (onSearch) {
            addKeyword(undefined);
            setIsLastOperationMutation(false);
            onSearch();
        }
    }

    const renderKeywords = () => {
        setTimeout(() => {
            if (isLastOperationMutation) {
                lastKeywordRef.current?.focus();
                setIsLastOperationMutation(false);
            }
        }, 100);

        return keywordsAndOperations?.map((item, index) => {
            if (!isOperator(item)) {
                return <Input
                    className={classNames(classes.input, classes.tag)}
                    value={item}
                    onClick={(event) => {
                        event.stopPropagation();
                        setIsLastOperationMutation(false);
                    }}
                    onFocus={() => setCurrentFocusKeywordIndex(index)}
                    onChange={(event) => {
                        updateSearchKeyword(event.target.value, index);
                    }}
                    onKeyDown={(event) => {
                        switch (event.code) {
                            case 'Enter':
                            case "NumpadEnter":
                                onEnter();
                                break;
                            case 'Backspace':
                                if (item === '') {
                                    removeKeyword(index);
                                    setNextFocusKeywordIndex(index - 2);
                                    setIsLastOperationMutation(true);
                                    event.preventDefault();
                                }
                                break;
                            default:
                                setIsLastOperationMutation(false);
                        }
                    }}
                    startAdornment={
                        <Typography
                            className={classes.inputSpacing}
                            variant="h6"
                            component="span"
                        >
                            {item}
                        </Typography>
                    }
                    inputProps={{
                        className: classNames(classes.inputField, { [classes.inputFieldEmpty]: !item }),
                        ref: index === nextFocusKeywordIndex ? lastKeywordRef : undefined
                    }}
                    sx={{
                        "&::before": {
                            border: "none !important"
                        },
                        "&::after": {
                            border: "none !important"
                        },
                        fontSize: "20px !important",
                        "span": {
                            height: 32,
                            minWidth: 3,
                            fontSize: "20px !important"
                        }
                    }}
                    key={'search-terms-315-' + index}
                />
            }

            return <Chip
                label={item.replaceAll("[", "").replaceAll("]", "")}
                color={appearDisabled ? "default" : "secondary"}
                sx={!appearDisabled ? { color: "#fff" } : {}}
                key={'search-terms-323-' + index}
            />
        });
    }

    const isLastItemAKeyword = () => {
        return !!keywordsAndOperations?.length && (!isOperator(keywordsAndOperations[keywordsAndOperations?.length - 1]));
    }

    const getPlaceholder = (): string => {
        return keywords && keywords.length ?
            ""
            :
            keywordsAndOperations && keywordsAndOperations.length ?
                addPlaceholder
                :
                placeholder;
    }

    return (
        <div className={classes.keywordsTextContainer} onClick={() => { ref.current?.focus(); }}>
            <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                {renderKeywords()}
                <div className={classes.textContainer}>
                    <Input
                        id={inputId}
                        className={classes.input}
                        value={inputValue}
                        placeholder={getPlaceholder()}
                        disabled={isLastItemAKeyword()}
                        onClick={() => { setCurrentFocusKeywordIndex(undefined); }}
                        onChange={(event) => {
                            setInputValue(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            switch (event.code) {
                                case "Enter":
                                case "NumpadEnter":
                                    onEnter();
                                    setIsLastOperationMutation(false);
                                    break;
                                case 'Backspace':
                                    if (inputValue === '') {
                                        removeKeyword();
                                        setNextFocusKeywordIndex(keywordsAndOperations?.length - 1);
                                        setIsLastOperationMutation(true);
                                    }
                                    break;
                                default:
                                    setIsLastOperationMutation(false);
                            }
                        }}
                        startAdornment={
                            <Typography
                                className={classes.inputSpacing}
                                variant="h6"
                                component="span"
                            >
                                {inputValue || getPlaceholder()}
                            </Typography>
                        }
                        inputProps={{ className: classes.initialInputField, ref: ref }}
                        sx={{
                            "&::before": {
                                border: "none !important"
                            },
                            "&::after": {
                                border: "none !important"
                            },
                            fontSize: "20px !important",
                            span: {
                                fontSize: "20px !important"
                            }
                        }}
                    />
                    {
                        !!(inputValue || (keywordsAndOperations && keywordsAndOperations.length)) &&
                        <Box display="flex" ml={1} className={classNames(appearDisabled && classes.hide)}>
                            <Button
                                variant='outlined'
                                color='secondary'
                                onClick={(event) => { event.stopPropagation(); addKeyword(SearchKeywordOperation.And); }}
                                className={classNames(classes.andOrButton)}
                            >
                                and
                            </Button>
                            <Button
                                variant='outlined'
                                color='secondary'
                                onClick={(event) => { event.stopPropagation(); addKeyword(SearchKeywordOperation.Or); }}
                                className={classNames(classes.andOrButton)}
                            >
                                or
                            </Button>
                            {/* {
                                onSearch &&
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => { addKeyword(undefined); setIsLastOperationMutation(false); onSearch(); }}
                                    className={classNames(classes.andOrButton, primaryBackground ? classes.andOrButtonPrimary : classes.andOrButtonAccent)}
                                    endIcon={<KeyboardReturnIcon />}
                                >
                                    search
                                </Button>
                            } */}
                            {
                                afterMessage &&
                                <Typography margin="auto">{afterMessage}</Typography>
                            }
                        </Box>
                    }
                </div>
            </div>
        </div>
    )
};

export default SearchTerms;