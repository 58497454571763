import { Stack, Typography, } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { EmailBody } from "../../../types/files";

const useStyles = makeStyles((theme) => ({
    summaryContainer: {
        display: 'block',
        width: '100%',
        height: 'auto',
        background: 'linear-gradient(90deg, rgba(212,236,237,1) 0%, rgba(240,254,254,1) 100%)',
        border: `1px solid ${theme.colors.neutral['100']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        borderRadius: 16,
        padding: 16,
        breakInside: 'avoid',
        transition: '0.05s ease-in-out',
        "&:hover": {
            boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
        },
    },
    summaryContentBlock: {
        width: '100%',
        height: 'fit-content',
        maxHeight: '156px',
        overflowY: 'auto',
    },
    summaryTitle: {
        color: '#7bd4d4',
        fontSize: '1rem',
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontFamily: 'Inter',
    },
    summaryText: {
        color: '#048290',
        fontSize: '0.9rem',
    },
    aiGeneratedIcon: {
        color: '#7bd4d4',
        height: '20px',
        width: '20px',
        marginLeft: '16px',
    },
}));

const ContentSummaryAnswer: React.FC<{ content: EmailBody & {summary : string} }> = ({ content }) => {
    const classes = useStyles();

    return (<>
        <Stack className={classes.summaryContainer}>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={1}>
                <Typography className={classes.summaryTitle}>Summary</Typography>
                <AutoAwesomeIcon className={classes.aiGeneratedIcon} />
            </Stack>
            <Stack className={classes.summaryContentBlock}>
                <Typography className={classes.summaryText}>
                    {content.summary || 'No summary yet.'}
                </Typography>
            </Stack>
        </Stack>
    </>);
}

export default ContentSummaryAnswer;
