import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { scrollbarStyle } from '../../../shared/dashboard';
import ActionButtons from '../../molecules/file-uploader/ActionButtons';
import FileDropzone from '../../molecules/file-uploader/FileDropzone';
import useFileUploader from '../../../hooks/useFileUploader';

const useStyles = makeStyles(() => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: 600,
            height: 565,
            padding: 32,
            paddingBottom: 8,
            borderRadius: 32,
        },
    },
    content: {
        width: '100%',
        height: '100%',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #048290',
        background: 'rgb(240,254,254)',
        borderRadius: 8,
        ...scrollbarStyle,
    },
    actions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const FileUploaderModal: React.FC<{
    open: boolean,
    validFile?: {
        maxFileSize?: number,
        allowedFileTypes?: Array<string>,
    },
    validPath?: string,
    onClose: () => void,
}> = ({ open, validFile, validPath, onClose }) => {
    const classes = useStyles();
    const { files, uploading, hasUploaded,
        uploadFiles, excludeFile, resetUploader } = useFileUploader({ validFile, validPath });

    return (
        <Dialog className={classes.dialog} open={open} onClose={() => resetUploader(onClose)}>
            <DialogContent className={classes.content}>
                <FileDropzone extFiles={files} validFile={validFile} onUpdate={uploadFiles} onDelete={excludeFile} />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <ActionButtons isUploading={uploading} hasUploaded={hasUploaded} onClose={() => resetUploader(onClose)} />
            </DialogActions>
        </Dialog>
    );
}

export default FileUploaderModal;