import { Dispatch, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Divider, IconButton, InputAdornment, Popover, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import classNames from 'classnames';
import {ReactComponent as AttachmentIcon} from "../../../assets/icons/attachment.svg";
import {ReactComponent as CallIcon} from '../../../assets/icons/call.svg';
import {ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder.svg';
import { DashboardsContext } from '../../../contexts/DashboardsContext';
import { scrollbarStyle } from '../../../shared/dashboard';
import theme from '../../../theme';
import { Dashboard, DocSendIngestion, UnassignedContent, UnassignedContentType } from '../../../types/files';
import DashboardsSearchOrCreate from '../../molecules/dashboards-search-create/DashboardsSearchOrCreate';
import ConfirmDialog from '../ConfirmDialog';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '460px',
        height: '540px',
        left: '-32px',
        '& > .MuiPaper-root': {
            width: '100%',
            height: '100%',
            padding: 16,
            marginTop: 8,
            borderRadius: 16,
            overflow: 'hidden',
        }
    },
    bullet: {
        color: theme.colors.orange['600'],
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        lineHeight: '1.5rem',
    },
    contentIcon: {
        width: 32,
        height: 32,
    },
    notificationBlock: {
        width: '100%',
        height: 150,
        transition: 'height .3s ease',
    },
    notificationIcon: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        fill: theme.palette.primary.main,
    },
    notificationText: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    popoverButton: {
        width: '170px',
        height: '32px',
        borderRadius: 20,
        padding: '16px',
        paddingRight: '16px',
        backgroundColor: '#E0F1F1',
        fontSize: 14,
        fontWeight: 'bold',
        textTransform: 'none',
    },
    title: {
        display: '-webkit-box',
        width: 'fit-content',
        fontSize: '1rem',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: '#7bd4d4',
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        maxWidth: '32ch',
    },
    dashboardTitle: {
        fontSize: '1.1rem',
        fontFamily: 'Inter',
        color: 'black',
    },
    bodyContentBlock: {
        width: '330px',
        height: 'fit-content',
        minHeight: '60px',
        maxHeight: '96px',
        overflowY: 'auto',
    },
    bodyText: {
        color: 'black',
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        whiteSpace: 'pre-wrap',
    },
    docsendBody: {
        color: theme.colors.warning['500'],
        fontFamily: 'Inter',
        fontSize: '0.9rem',
    },
    docsendUrl: {
      display: '-webkit-box',
      overflow: 'hidden',
      maxWidth: '32ch',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1,
      wordBreak: 'break-all',
      fontFamily: 'Inter',
      fontSize: '0.9rem',
      color: '#7bd4d4',
      textOverflow: 'ellipsis',
      textTransform: 'none' as any,
      textDecoration: 'none !important',
      textAlign: 'left',
    },
    inputField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            width: '250',
            height: 32,
            borderRadius: 16,
            padding: '0 8px !important',
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    createButton: {
        height: 24,
        width: 40,
        borderRadius: '16px !important',
        borderWidth: 3,
        fontSize: '14px !important',
        textTransform: 'none !important' as any,
        fontWeight: 'bold !important',
        "&:hover": {
            borderWidth: 3,
            fontWeight: 'bold !important',
        },
    },
}));

const DocsendContent: React.FC<{
    docsend: DocSendIngestion,
    onDelete: () => void,
    onIngestion: (docsend: DocSendIngestion, password: string) => void,
}> = ({ docsend, onDelete, onIngestion }) => {
    const classes = useStyles();
    const { dashboards } = useContext(DashboardsContext);
    const [dashboard, setDashboard] = useState<Dashboard|undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const inputValue = useRef<TextFieldProps>(null);

    useEffect(() => {
        setDashboard(dashboards.find(dashboard => docsend?.existingDashboardId ?
            dashboard.id === docsend?.existingDashboardId : dashboard.title === docsend?.newDashboardTitle));
    }, [docsend, dashboards]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Stack spacing={1} width="100%" pb={1}>
            <Divider sx={{ width: '100%' }}/>
            <Stack direction="row" alignItems="flex-start" p="0 8px" width="100%">
                <Box width="40px" mt="4px">
                    <DashboardIcon className={classes.contentIcon} fill={theme.palette.primary.main} />
                </Box>
                <Stack direction="column" alignItems="flex-start" justifyContent="center" pl={1} width="100%">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Typography className={classNames(classes.title, classes.dashboardTitle)}>
                            {dashboard?.title || '<Untitled>'}
                        </Typography>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onDelete();
                        }}> <DeleteIcon /> </IconButton>
                    </Stack>
                    <Stack className={classes.bodyContentBlock}>
                        <Stack direction="column" spacing="4px" alignItems="left" justifyContent="center">
                            <Typography className={classes.docsendBody}>
                                {'Docsend password is required:'}
                            </Typography>
                            <Stack direction="row" spacing="4px" alignItems="center" justifyContent="flex-start" width="100%">
                                <Box width={24} height={24}>
                                    <AttachmentIcon width={24} height={24} stroke="#7bd4d4" />
                                </Box>
                                <Typography component="a"
                                    className={classes.docsendUrl}
                                    href={docsend?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    display="inline">
                                    {docsend?.url}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box width="100%">
                        <TextField variant="outlined"
                            className={classes.inputField}
                            placeholder={'Enter password here ...'}
                            inputRef={inputValue}
                            sx={{ width: '100%' }}
                            InputProps={{ endAdornment: (
                                <InputAdornment position="end">
                                    <LoadingButton variant="contained"
                                        className={classes.createButton}
                                        loading={loading}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setLoading(true);
                                            onIngestion(docsend, inputValue.current?.value as string);
                                        }}>
                                        {'Save'}
                                    </LoadingButton>
                                </InputAdornment>
                            )}} />
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    </>);
}

const UnassignedContentsModal: React.FC<{
    unassignedContents: UnassignedContent[],
    anchorEl: HTMLElement | null,
    onAnchorEl: Dispatch<React.SetStateAction<HTMLElement | null>>,
    onAssignContent: (dashboardId: string, unassignedContentId: string) => void,
    onDocsendIngestion: (unassignedContentId: string, docsend: DocSendIngestion, password: string) => Promise<any>,
    onRemoveContent?: (unassignedContentId: string) => Promise<any>,
}> = ({ unassignedContents, anchorEl, onAnchorEl, onAssignContent, onDocsendIngestion, onRemoveContent }) => {
    const classes = useStyles();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [deleteDashboardLoading, setDeleteDashboardLoading] = useState<boolean>(false);
    const [currentContent, setCurrentContent] = useState<UnassignedContent|null>(null);
    const [selectedDashboard, setSelectedDashboard] = useState<Dashboard|null>(null);
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);
    const menuOpen = Boolean(anchorEl);

    const getIcon = useCallback((type: UnassignedContentType) => {
        switch (type) {
            case UnassignedContentType.EMAIL:
            case UnassignedContentType.FULL_EMAIL:
                return <EmailIcon className={classes.contentIcon} sx={{ fill: '#7bd4d4 !important'}} />;
            case UnassignedContentType.CALL:
                return <CallIcon className={classes.contentIcon} stroke="#7bd4d4" />;
            case UnassignedContentType.DOCSEND:
                return  <DashboardIcon className={classes.contentIcon} fill={theme.palette.primary.main} />;
            case UnassignedContentType.OTHER:
            default:
                return <FolderIcon className={classes.contentIcon} fill="#7bd4d4" />;
        }
        // eslint-disable-next-line
    }, []);

    const getContent = useCallback((content?: UnassignedContent) => {
        switch (content?.type) {
            case UnassignedContentType.EMAIL:
            case UnassignedContentType.FULL_EMAIL:
                return 'Email';
            case UnassignedContentType.CALL:
                return 'Call';
            case UnassignedContentType.DOCSEND:
                return 'Docsend';
            case UnassignedContentType.OTHER:
            default:
                return 'Other content';
        }
        // eslint-disable-next-line
    }, []);

    const extractDocsendContent = (content?: UnassignedContent) =>
       (content ? JSON.parse(content?.body || '{}') : {}) as DocSendIngestion;

    const closeDeleteDialog = useCallback(() => {
        setCurrentContent(null);
        setDeleteDashboardLoading(false);
        setConfirmDeleteOpen(false);
    }, []);

    const handleRemove = useCallback(async () => {
        if (!currentContent || !onRemoveContent)
            return;

        setDeleteDashboardLoading(true);
        await onRemoveContent?.(currentContent.id!);
        closeDeleteDialog();
    }, [currentContent, onRemoveContent, closeDeleteDialog]);

    const handleSave = useCallback((dashboard: Dashboard, content: UnassignedContent) => {
        setSelectedDashboard(dashboard);
        setCurrentContent(content);
        setIsNotificationVisible(true);
        onAssignContent(dashboard.id, content.id!);
    }, [onAssignContent]);

    useEffect(() => {
        if (isNotificationVisible) {
            const timeoutId: any = setTimeout(() => {
                setIsNotificationVisible(false);
                clearTimeout(timeoutId);
                setCurrentContent(null);
                setSelectedDashboard(null);
            }, 3000);

            return () => {
                clearTimeout(timeoutId);
            }
        }
        // eslint-disable-next-line
    }, [isNotificationVisible, selectedDashboard]);

    return (<>
        <Popover open={menuOpen} className={classes.modal}
            anchorEl={anchorEl}
            onClose={() => onAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
            <Stack alignItems="flex-start" justifyContent="center" width="100%" height="100%">
                <Typography variant="h6-bold"
                    fontWeight="bold"
                    fontFamily="Inter"
                    color={theme.colors.primary['500']}
                    display="inline">
                    {'New incoming data'}
                </Typography>
                <Divider sx={{ width: '100%' }}/>
                <Box width="100%" height="100%" sx={{ overflow: 'auto', ...scrollbarStyle }}>
                    {isNotificationVisible && currentContent && (
                        <Stack spacing={2} className={classes.notificationBlock} alignItems="center" justifyContent="center">
                            <CheckCircleRoundedIcon className={classes.notificationIcon}/>
                            <Typography className={classes.notificationText}>
                                {`${getContent(currentContent)} successfully saved to ${selectedDashboard?.title || 'dashboard'}.`}
                            </Typography>
                        </Stack>
                    )}
                    {unassignedContents.map(content => getContent(content) === 'Docsend' ? (
                        <DocsendContent
                            docsend={extractDocsendContent(content)}
                            onDelete={() => {
                                setCurrentContent(content);
                                setConfirmDeleteOpen(true);
                            }}
                            onIngestion={(docsend, password) => onDocsendIngestion(content.id!, docsend, password)}
                            key={`unassigned-content-modal-392-${content.id}`}
                        />
                    ) : (
                        <Stack spacing={1} width="100%" pb={1} key={`unassigned-content-modal-395-${content.id}`}>
                            <Divider sx={{ width: '100%' }}/>
                            <Stack direction="row" alignItems="flex-start" p="0 8px" width="100%">
                                <Box width="40px" mt="4px">
                                    {getIcon(content.type as UnassignedContentType)}
                                </Box>
                                <Stack direction="column" alignItems="flex-start" justifyContent="center" pl={1} width="100%">
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                        <Typography className={classes.title}>
                                            {content?.subject || '<Untitled>'}
                                        </Typography>
                                        <IconButton disabled={!!currentContent}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setCurrentContent(content);
                                                setConfirmDeleteOpen(true);
                                        }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                    <Stack className={classes.bodyContentBlock}>
                                        <Typography className={classes.bodyText}>
                                            {content?.body || ''}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" mt={2} width="100%">
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" width="100%" minWidth="fit-content">
                                            <Typography className={classes.bullet} fontSize="32px !important"
                                                sx={{ minWidth: 'fit-content', transform: 'translateY(-2px)' }}>&#x2022;
                                            </Typography>
                                            <Typography
                                                fontSize="0.9rem"
                                                fontWeight="bold"
                                                color={theme.colors.neutral['500']}
                                            > {'Add To:'} </Typography>
                                        </Stack>
                                        <DashboardsSearchOrCreate search create save autoComplete smallFit
                                            onSave={(dashboard) => handleSave(dashboard, content)}
                                            onCreate={(dashboard) => handleSave(dashboard, content)} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    ))}
                </Box>
            </Stack>
        </Popover>
        <ConfirmDialog
            title="Delete confirmation"
            content="Are you sure you want to delete this data?"
            open={confirmDeleteOpen}
            loading={deleteDashboardLoading}
            confirmCallback={handleRemove}
            cancelCallback={closeDeleteDialog}
        />
    </>);
}

export default UnassignedContentsModal;
