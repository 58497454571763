import React, { useContext, useRef, useState } from "react";
import { Box, Button, CircularProgress, ClickAwayListener, Divider, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { CompanyFile } from '../../../../types/files';
import { getFileIcon } from "../../../../components/folders/utils/files";
import { s3Delete, s3Download } from "../../../../helpers/s3";
import useMutation from "../../../../hooks/useMutation";
import { deleteFile } from "../../../../graphql/mutations";
import { AuthContext } from "../../../../contexts/AuthContext";
import ConfirmDialog from "../../../modals/ConfirmDialog";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
    fileIcon: {
        marginRight: theme.spacing(1),
        height: 28,
        width: 28,
    },
    fileNameContainer: {
        display: 'flex',
        aliignItems: 'center',
    },
    fileName: {
        fontWeight: 'bold',
        marginLeft: 10,
        color: theme.palette.text.primary,
    },
    date: {
        color: theme.palette.text.primary,
    },
    fileLeftContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 8,
    },
    actionMenu: {
        height: 48,
        width: 48,
        marginRight: 10,
    },
    downloadLoader: {
        height: '20px !important',
        width: '20px !important',
    },
    latestDeck: {
        minWidth: 150,
        borderRadius: 32,
        background: `${theme.colors.cyan['400']} !important`,
        color: `${theme.palette.common.white} !important`,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    setDeck: {
        minWidth: 150,
        borderRadius: 32,
        border: `2px solid ${theme.colors.neutral['500']}`,
        color: `${theme.colors.neutral['600']} !important`,
        fontSize: '0.9rem',
        fontWeight: 'bold',
        textTransform: 'none',
        "&:hover": {
            border: `2px solid ${theme.colors.primary['500']}`,
            color: `${theme.colors.primary['600']} !important`,
        }
    }
}));

const downloadURL = (data: any, fileName: any) => {
    var a: any;
    a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
};

const downloadBlob = (data: any, fileName: string, mimeType: string) => {
    const blob = new Blob([data], {
        type: mimeType
    });
    const url = window.URL.createObjectURL(blob);
    downloadURL(url, fileName);
    setTimeout(function () {
        return window.URL.revokeObjectURL(url);
    }, 1000);
};

const FileCategoryRow: React.FC<{
    file: CompanyFile,
    forSelection?: boolean,
    refetch: () => void,
    onFileSelect: (file: CompanyFile) => void,
    onSetDeck?: (file: CompanyFile) => Promise<any>,
}> = ({ file, forSelection, refetch, onFileSelect, onSetDeck }) => {
        const classes = useStyles();
        const [deleteFileLoading, setDeleteFileLoading] = useState<boolean>(false);
        const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
        const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
        const [deleteFileGql] = useMutation({ statement: deleteFile, name: "deleteFile" });
        const { userGroup } = useContext(AuthContext);

        const [open, setOpen] = useState<boolean>(false);
        const [loading, setLoading] = useState<boolean>(false);

        const anchorRef = useRef<HTMLButtonElement>(null);

        const downloadFile = () => {
            setDownloadInProgress(true);
            s3Download({ key: file.key }).then(async x => {
                await downloadBlob(x, `${file.name}.pdf`, "application/octet-stream");
                setDownloadInProgress(false);
                setOpen(false);
            });
        }

        const onDeleteFile = () => {
            if (!file)
                return;

            setDeleteFileLoading(true);
            Promise.all([
                s3Delete({ path: file.key }),
                deleteFileGql({ id: file.id, group: userGroup }),
            ]).finally(() => {
                setDeleteFileLoading(false);
                setConfirmDeleteOpen(false);
                refetch();
            });
        }

        return (
            <Box onClick={() => onFileSelect(file)} width="100%">
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Box className={classes.fileNameContainer}>
                        {getFileIcon(file.name || "")}
                        <Typography className={classes.fileName}>{file.name}</Typography>
                    </Box>
                    <Box className={classes.fileLeftContainer}>
                        {file.isDeck ? (
                            <Button variant="contained"
                                className={classes.latestDeck}
                                disabled>{'Latest deck'}</Button>
                        ) : (
                            <LoadingButton variant="outlined"
                                className={classes.setDeck}
                                loading={loading}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setLoading(true);
                                    onSetDeck?.(file).then(() => setLoading(false));
                                }}> {'Set as latest deck'} </LoadingButton>
                        )}
                        {!forSelection && (<>
                            <IconButton
                                size="small"
                                ref={anchorRef}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpen(true);
                                }}
                                className={classes.actionMenu}
                            >
                                <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-end"
                                transition
                                disablePortal
                                style={{ zIndex: '1' }}
                            >
                                {({ TransitionProps }) => (
                                    <Grow {...TransitionProps}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={() => { setOpen(false) }}>
                                                <MenuList autoFocusItem={open}>
                                                    <MenuItem
                                                        style={{ justifyContent: 'center' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            downloadFile();
                                                        }}>
                                                        {downloadInProgress ? <CircularProgress className={classes.downloadLoader} /> : 'Download'}
                                                    </MenuItem>
                                                    <MenuItem
                                                        disabled={downloadInProgress}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            setOpen(false);
                                                            setConfirmDeleteOpen(true);
                                                        }}>Delete</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                            <Typography className={classes.date}>{moment(file.createdAt).format('MM/DD/YYYY')}</Typography>
                        </>)}
                    </Box>
                </Stack>
                <ConfirmDialog
                    title="Delete confirmation"
                    content="Are you sure you want to delete this file?"
                    open={confirmDeleteOpen}
                    loading={deleteFileLoading}
                    confirmCallback={onDeleteFile}
                    cancelCallback={() => { setConfirmDeleteOpen(false); }}
                />
                <Divider sx={{ marginTop: '8px !important'}}/>
            </Box>
        );
    };

export default FileCategoryRow;