import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: `inset ${theme.spacing(-0.5)} 0 ${theme.spacing(
      0.5
    )} rgba(0, 0, 0, 0.1)`,
    overflow: 'auto',
    padding: 10,
  },
  homeRoot: {
    height: "100%",
    width: "27%",
    maxWidth: 365,
    overflow: 'auto',
    padding: 10,
  }
}));

interface Props {
  isHomeSearch?: boolean;
  children: React.ReactNode;
}

const FileStructureNavigator = ({ isHomeSearch = false, children }: Props) => {
  const classes = useStyles();

  return (
    <>
      {
        isHomeSearch ?
          <Paper
            className={classes.homeRoot}
          >
            {children}
          </Paper>
          :
          <Box
            className={classes.root}
            height="100%"
            width="27%"
            maxWidth={365}
          >
            {children}
          </Box>
      }
    </>
  );
};

export default FileStructureNavigator;
