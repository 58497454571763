import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import { CompanyFile } from '../../../types/files';
import FileUploadModal from '../components/FileUploadModal';
import FileUploadSPModal, { SharepointFile } from '../components/FileUploadSPModal';
import FolderCreationModal from '../components/FolderCreationModal';
import { recordAction } from '../../../helpers/actions';
import { AuthContext } from '../../../contexts/AuthContext';
import { s3UploadToExternalBucket, sha256 } from '../../../helpers/s3';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import path from 'path';
import { downloadFileFromSharepoint } from '../../../services/graphService';
import { ReactComponent as SharePointIcon } from "../../../assets/icons/microsoft-sharepoint.svg";

const useStyles = makeStyles((theme) => ({
  addButton: {
    border: "none !important",
    "&:hover": {
      border: "none",
    }
  },
  spLoadingCard: {
    position: 'absolute',
    width: 'calc(100% - 20px)',
    display: 'flex',
    bottom: 70,
    zIndex: 5,
    height: 50,
    padding: 10,
    alignItems: 'center',
  },
  spIcon: {
    marginRight: 20,
  },
  message: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    lineHeight: "1.4",
    wordBreak: "break-word"
  }
}));

interface Props {
  openUploadFolderModal: boolean;
  stagingBucket: string;
  currentFolder?: CompanyFile;
  currentFile?: CompanyFile | null;
  droppedFolder?: File[] | undefined;
  droppedFile?: File | undefined;
  onClearFiles: () => void;
}

const FileManagementContainer = ({
  openUploadFolderModal, stagingBucket, currentFolder,
  droppedFolder, droppedFile, onClearFiles
}: Props) => {
  const classes = useStyles();
  const [showFileUploadModal, setShowFileUploadModal] = useState<boolean>(false);
  const [showFolderUploadSPModal, setShowFolderUploadSPModal] = useState<boolean>(false);
  const [showFolderUploadModal, setShowFolderUploadModal] = useState<boolean>(false);
  const [showFolderCreationModal, setShowFolderCreationModal] = useState<boolean>(false);
  const [sharepointLoading, setSharepointLoading] = useState<boolean>(false);
  const [sharepointUploadFiles, setSharepointUploadFiles] = useState<SharepointFile[]>([]);
  const [
    folderProcessing,
    //setFolderProcessing
  ] = useState<boolean>(false);
  const { userGroup } = useContext(AuthContext);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState<boolean>(false);
  const [displayLoadingSnackbar, setDisplayLoadingSnackbar] = useState<boolean>(false);
  const [uploadStartedOrCompleted, setUploadStartedOrCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (displayLoadingSnackbar && !(sharepointLoading || folderProcessing) && uploadStartedOrCompleted) {
      setDisplaySuccessMessage(true);
      const timeout = setTimeout(() => {
        setDisplaySuccessMessage(false);
        setDisplayLoadingSnackbar(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [displayLoadingSnackbar, sharepointLoading, folderProcessing, uploadStartedOrCompleted]);

  useEffect(() => {
    if (sharepointLoading || folderProcessing) {
      setDisplayLoadingSnackbar(true);
    }
  }, [sharepointLoading, folderProcessing]);

  useEffect(() => {
    setShowFolderUploadModal(openUploadFolderModal);
  }, [openUploadFolderModal]);

  useEffect(() => {
    if (droppedFolder) {
      toggleFolderUploadModal();
    } else if (droppedFile) {
      toggleFileUploadModal();
    }
    // eslint-disable-next-line
  }, [droppedFolder, droppedFile]);

  const toggleFileUploadModal = () => {
    setShowFileUploadModal((prevState) => !prevState);

    if (showFileUploadModal) {
      onClearFiles();
    }
  }

  const toggleShowFolderUploadSPModal = () => {
    setShowFolderUploadSPModal((prevState => !prevState))
  }
  const toggleFolderUploadModal = () => {
    setShowFolderUploadModal((prevState) => !prevState);
    if (showFolderUploadModal) {
      onClearFiles();
    }
  }

  const toggleFolderCreationModal = () =>
    setShowFolderCreationModal((prevState) => !prevState);

  const uploadFilesFromSP = (
    provider: AuthCodeMSALBrowserAuthenticationProvider,
    siteId: string,
    filesToUpload: SharepointFile[],
    userGroup: string,
    location: string,
    stagingBucket: string
  ) => {
    setUploadStartedOrCompleted(true);
    setSharepointUploadFiles(filesToUpload);
    setSharepointLoading(true);
    Promise.all(filesToUpload.map(({ driveId, path: filePath, id }) =>
      downloadFileFromSharepoint(provider, siteId, driveId, id)
        .then(fileBlob => {
          s3UploadToExternalBucket({
            path: path.join(userGroup, location, "sharepoint", filePath),
            file: fileBlob,
            bucket: stagingBucket
          });
        }
        )))
      .then(() => {
        setShowFolderUploadSPModal(false);
        setSharepointLoading(false);
      })
      .catch(() => {
        setShowFolderUploadSPModal(false);
        setSharepointLoading(false);
      });
  }

  return (
    <>
      {displayLoadingSnackbar && <Box>
        <Card className={classes.spLoadingCard}>
          <SharePointIcon className={classes.spIcon} />
          {displaySuccessMessage ?
            <Typography sx={{ width: 'calc(100% - 80px)' }} className={classes.message}>
              {sharepointUploadFiles.length === 1 ? `${sharepointUploadFiles[0].name} uploaded sucessfully` : 'Upload sucessful'}
            </Typography> :
            <><Typography sx={{ width: 'calc(100% - 80px)' }} className={classes.message}>
              {sharepointUploadFiles.length === 1 ? `${sharepointUploadFiles[0].name} upload in progress...` : 'Upload in progress...'}
            </Typography>
              <CircularProgress sx={{ width: 20 }} />
            </>}
        </Card>
      </Box>}
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" marginTop="5px">
        <Button onClick={toggleFolderUploadModal} className={classes.addButton} color="secondary" variant="outlined" startIcon={<AddIcon />}>
          Upload a folder or file
        </Button>
        {/* <Button onClick={toggleShowFolderUploadSPModal} className={classes.addButton} color="secondary" variant="outlined" startIcon={<AddIcon />}>
          Upload from Sharepoint
        </Button> */}
      </Box>
      {
        showFolderUploadModal && (
          <FileUploadModal
            currentFolder={currentFolder}
            onUpload={(uploadName, uploadType, uploadSource) => {
              sha256(uploadName + uploadSource).then(id => {
                recordAction(id, userGroup, uploadName, uploadType, uploadSource)
              });
            }}
            onClose={toggleFolderUploadModal}
          />
        )
      }
      {
        showFolderUploadSPModal && (
          <FileUploadSPModal
            currentFolder={currentFolder}
            onClose={toggleShowFolderUploadSPModal}
            stagingBucket={stagingBucket}
            sharepointLoading={sharepointLoading}
            uploadFiles={uploadFilesFromSP}
          />
        )
      }
      {
        showFolderCreationModal && (
          <FolderCreationModal onClose={toggleFolderCreationModal} />
        )
      }
    </>
  );
};

export default FileManagementContainer;
