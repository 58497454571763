import makeStyles from '@mui/styles/makeStyles';
import { User } from '../../../../types/common';
import { ExtendedComment, Comment, CommentOriginType } from '../../../../types/comments';
import { Divider, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CommentCard, { CommentType } from "./CommentCard";
import React from 'react';
import { deleteCommentFunc, deleteMentionFunc } from '../../../../lib/helper';
import { UsersContext } from '../../../../contexts/UsersContext';
import { DashboardUid } from '../../../../types/files';
import { AuthContext } from '../../../../contexts/AuthContext';
import ConfirmDialog from '../../../modals/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        overflowY: 'hidden',
    },
    mentionsContainer: {
        height: 'calc(50% - 21px)',
        marginTop: 10,
    },
    containers: {
        height: 'calc(100% - 45px)',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    commentsContainer: {
        height: 'calc(100% - 20px)',
        marginTop: 10,
    },
    commentsHeader: {
        marginLeft: 10,
    },
    mentionsHeader: {
        marginLeft: 10,
    },
    divider: {
        margin: 10,
    },
}));

const CommentsArea: React.FC<{
    readOnly?: boolean | undefined,
    fileKey: string | undefined,
    comments: ExtendedComment[],
    setSelectedHighlightAreas: (highlightAreas: any) => void,
    onCommentRemoved?: (id: string, parentId: string | undefined) => void,
    onCommentUpdated?: (comment: Comment) => void,
    onReplyAdded?: any,
    selectedCommentId?: string | undefined | null,
    displayIndexBullet?: boolean,
    commentOriginType: CommentOriginType,
    dashboardItem?: DashboardUid,
}> =
    ({ readOnly, comments, setSelectedHighlightAreas, onCommentRemoved, onCommentUpdated, fileKey, onReplyAdded, selectedCommentId, displayIndexBullet = false, commentOriginType, dashboardItem }) => {
        const classes = useStyles();
        const [open, setOpen] = useState(false);
        const [deleteLoading, setDeleteLoading] = useState(false);
        const [selectedComment, setSelectedComment] = useState<any>();
        const [commentToDelete, setCommentToDelete] = useState<ExtendedComment | undefined>();
        const [mentionUsers, setMentionUsers] = useState<User[]>([]);
        useEffect(() => {
            setSelectedComment(undefined);
        }, [fileKey]);

        const { users, usersLoading } = useContext(UsersContext);
        const { userGroup } = useContext(AuthContext);
        useEffect(() => {
            if (!usersLoading) {
                const filteredUsers = users.filter(user => user.group === userGroup);
                setMentionUsers(filteredUsers);
            }
        }, [users, usersLoading, userGroup]);

        useEffect(() => {
            setSelectedComment(selectedCommentId);
        }, [selectedCommentId]);

        const onDelete = (comment: ExtendedComment) => {
            setCommentToDelete(comment);
            setOpen(true);
        }

        const onDeleteConfirm = () => {
            if (!readOnly && commentToDelete && commentToDelete.id && onCommentRemoved) {
                setDeleteLoading(true);
                deleteCommentFunc(commentToDelete.id)
                    .then(
                        () => {
                            let childPromises: any[] = [];

                            commentToDelete.usersMentioned.forEach((mentionUserId) => {
                                childPromises.push(deleteMentionFunc(mentionUserId));
                            });

                            if (commentToDelete.children?.length! > 0) {
                                commentToDelete.children?.forEach((reply: Comment) => {
                                    if (reply.id) {
                                        childPromises.push(deleteCommentFunc(reply.id));
                                    }
                                });
                            }

                            Promise.all(childPromises).then(() => {
                                onCommentRemoved(commentToDelete.id || '', commentToDelete.parentId);
                                setCommentToDelete(undefined);
                                setOpen(false);
                                setDeleteLoading(false);
                            });
                        },
                        (error) => {
                            setCommentToDelete(undefined);
                            setDeleteLoading(false);
                            setOpen(false);
                            console.log(error);
                        });
            }
        }

        return (
            <div className={classes.container}>
                <div className={classes.commentsContainer}>
                    <Typography variant="h6" textAlign="center" className={classes.commentsHeader}>
                        Discussion
                    </Typography>
                    <div className={classes.containers}>
                        {comments.filter(c => !c.parentId).map((comment: ExtendedComment, index: number) => {
                            return <div key={'comments-area-130-' + index}>
                                <CommentCard
                                    readOnly={readOnly}
                                    comment={comment}
                                    users={mentionUsers}
                                    setSelectedHighlightAreas={setSelectedHighlightAreas}
                                    setSelectedComment={setSelectedComment}
                                    selectedComment={selectedComment}
                                    type={CommentType.Comment}
                                    onDelete={onDelete}
                                    onUpdate={onCommentUpdated}
                                    fileKey={fileKey}
                                    onReplyAdded={onReplyAdded}
                                    displayIndexBullet={displayIndexBullet}
                                    commentOriginType={commentOriginType}
                                    dashboardItem={dashboardItem}
                                />
                                {comment.children?.map((child: ExtendedComment, childIndex: number) => {
                                    return <div key={'comments-area-148-' + childIndex}>
                                        <CommentCard
                                            readOnly={readOnly}
                                            comment={child}
                                            users={mentionUsers}
                                            setSelectedHighlightAreas={setSelectedHighlightAreas}
                                            setSelectedComment={setSelectedComment}
                                            selectedComment={selectedComment}
                                            type={CommentType.Reply}
                                            onDelete={onDelete}
                                            onUpdate={onCommentUpdated}
                                            fileKey={fileKey}
                                            onReplyAdded={onReplyAdded}
                                            parentComment={comment}
                                            commentOriginType={commentOriginType}
                                            dashboardItem={dashboardItem}
                                        />
                                    </div>
                                })}
                                {index < comments.length - 1 && <Divider sx={{ width: "90%", marginLeft: '15px' }} />}
                            </div>
                        })}
                    </div>
                </div>
                <ConfirmDialog
                    title="Delete confirmation"
                    content={'Are you sure you want to delete this entry?'}
                    open={open}
                    loading={deleteLoading}
                    confirmCallback={onDeleteConfirm}
                    cancelCallback={() => setOpen(false)}
                />
            </div>
        )
    };

export default CommentsArea;
