import React, { useContext, useEffect, useMemo } from "react";
import CustomQueryContainer from "../dashboard-query-container/CustomQueryContainer";
import RecentNewsAnswer from "../dashboard-query-answer/RecentNewsAnswer";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";

const RecentNewsQuery: React.FC<{}> = () => {
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { queryAnswer, removeQuery } = useDashboardQuery('Recent News');

    const isVisibleByDefault = useMemo(() =>
        defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Recent News')?.visibleByDefault ?? true
    , [defaultQueryTemplateValues]);

    const isPresent = useMemo(() => 'answer' in queryAnswer && !!queryAnswer.answer, [queryAnswer]);
    const isDeleted = useMemo(() => 'deletedAt' in queryAnswer && !!queryAnswer.deletedAt, [queryAnswer]);
    const isHidden = useMemo(() => isDeleted || (!isVisibleByDefault && !isPresent), [isDeleted, isPresent, isVisibleByDefault]);

    useEffect(() => {
        if (!isDeleted && (!isVisibleByDefault && isPresent) && !queryAnswer.manualOverride)
            removeQuery(queryAnswer);
    // eslint-disable-next-line
    }, [isDeleted, isPresent, isVisibleByDefault, queryAnswer]);

    if (isHidden)
        return (<></>);

    return (<>
        <CustomQueryContainer queryAnswer={queryAnswer}>
            <RecentNewsAnswer answeredQuestion={queryAnswer.answeredQuestion} />
        </CustomQueryContainer>
    </>);
}

export default RecentNewsQuery;
