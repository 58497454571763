import React, { useContext, useEffect, useMemo, useState } from "react";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import {DashboardContext} from "../../../contexts/DashboardContext";
import InlineTextQueryContainer from "../dashboard-query-container/InlineTextQueryContainer";
import TextAnswer from "../dashboard-query-answer/TextAnswer";
import ConfirmDialog from "../../modals/ConfirmDialog";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";

const HeadcountQuery: React.FC<{}> = () => {
    const { isPublicView } = useContext(DashboardContext);
    const { isLoading, defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { queryAnswer, updateQuery, removeQuery } = useDashboardQuery('Headcount');
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const isVisibleByDefault = useMemo(() =>
        defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Headcount')?.visibleByDefault ?? true
    , [defaultQueryTemplateValues]);

    const isPresent = useMemo(() => 'answer' in queryAnswer && !!queryAnswer.answer, [queryAnswer]);
    const isDeleted = useMemo(() => 'deletedAt' in queryAnswer && !!queryAnswer.deletedAt, [queryAnswer]);
    const isHidden = useMemo(() => isDeleted || (!isVisibleByDefault && !isPresent), [isDeleted, isPresent, isVisibleByDefault]);

    useEffect(() => {
        if (!isDeleted && (!isVisibleByDefault && isPresent) && !queryAnswer.manualOverride)
            removeQuery(queryAnswer);
    // eslint-disable-next-line
    }, [isDeleted, isPresent, isVisibleByDefault, queryAnswer]);

    useEffect(() => {
        if (isDeleteDialogOpen && isDeleted)
            setDeleteDialogOpen(false);
    }, [isDeleteDialogOpen, isDeleted]);

    if (isHidden)
        return (<></>);

    return (<>
        <InlineTextQueryContainer
            queryAnswer={queryAnswer}
            displayMenu={!isPublicView}
            editRows={1}
            onEdit={updateQuery}
            onDelete={() => setDeleteDialogOpen(true)}
            contentOnly>
            <TextAnswer answeredQuestion={queryAnswer.answeredQuestion} />
        </InlineTextQueryContainer>
        <ConfirmDialog
            title="Delete Confirmation"
            content="Are you sure you want to delete this query?"
            open={isDeleteDialogOpen}
            loading={isLoading}
            confirmCallback={() => removeQuery(queryAnswer)}
            cancelCallback={() => setDeleteDialogOpen(false)}
        />
    </>);
}

export default HeadcountQuery;
