import { useCallback, useState } from "react";
import { ExtFile } from "@files-ui/react";
import { useDebouncedCallback } from "use-debounce";

const useS3FileUploader = (s3FileUploader: (extFile: ExtFile) => Promise<any>) => {
    const [files, setFiles] = useState<ExtFile[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const [hasUploaded, setHasUploaded] = useState<boolean>(false);

    const debouncedUpload = useDebouncedCallback(() => {
        const promises: Promise<any>[] = [];

        setUploading(true);
        setHasUploaded(false);
        files.forEach(extFile => promises.push(s3FileUploader(extFile)));

        // TODO: we can improve this later
        const interval = setInterval(() => setProgress(prev => prev < 95 ? (prev + 5) : 95), 20);

        Promise.all(promises)
            .then(() => {
                setProgress(100);
                setHasUploaded(true);
            }).finally(() => {
                setUploading(false);
                clearInterval(interval);
            });
    }, 1000, { maxWait: 2000 });

    const uploadFiles = useCallback((extFiles: ExtFile[]) => {
        setFiles(extFiles);
        debouncedUpload();
    }, [debouncedUpload]);

    const excludeFile = useCallback((fileId: string | number | undefined) => {
        setFiles(prev => prev.filter(extFile => extFile.id !== fileId));
    }, []);

    const resetUploader = useCallback((onReset?: () => void) => {
        if (!uploading) {
            setFiles([]);
            setProgress(0);
            setUploading(false);
            setHasUploaded(false);
            onReset?.();
        }
    }, [uploading]);

    return { files, progress, uploading, hasUploaded, uploadFiles, excludeFile, resetUploader };
};

export default useS3FileUploader;