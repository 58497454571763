import { StrictMode } from "react";
import { render } from "react-dom";
import RootContainer from "./containers/RootContainer";
import SharePointContext from "./contexts/SharepointContext";
import "./assets/datadog-instrumentation";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "./styles/amplify.css";
import "./styles/fonts.css";
import "./styles/image-marker.css";
import "./styles/index.css";
import "./styles/mui.css";
import "./styles/react-editor.css";
import "./styles/uppy.css";

render(
  <StrictMode>
    <SharePointContext>
      <RootContainer />
    </SharePointContext>
  </StrictMode>,
  document.getElementById("root")
);
