import React, { useEffect, useState } from "react";
import html2canvas from 'html2canvas';
import './ScreenCapture.styles.scss';

export interface HighlightAreaCoordinates {
    top: number,
    left: number;
    width: number;
    height: number;
}

const ScreenCaptureFC: React.FC<{ onLoadCapture: () => void; onEndCapture: (url: string, coordinates: HighlightAreaCoordinates) => void; onExit: () => void }> = ({ onLoadCapture, onEndCapture, onExit, children }) => {
    const [isOn, setIsOn] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [windowHeight, setWindowHeight] = useState<number>(0);
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
    const [startY, setStartY] = useState<number>(0);
    const [crossHairsTop, setCrossHairsTop] = useState<number>(0);
    const [crossHairsLeft, setCrossHairsLeft] = useState<number>(0);
    const [cropWidth, setCropWidth] = useState<number>(0);
    const [cropHeight, setCropHeight] = useState<number>(0);
    const [cropPositionTop, setCropPositionTop] = useState<number>(0);
    const [cropPositionLeft, setCropPositionLeft] = useState<number>(0);
    const [borderWidth, setBorderWidth] = useState<string>("");

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    useEffect(() => {
        if (!isOn && !isMouseDown && (cropWidth && cropHeight)) {
            handleClickTakeScreenShot();
        }
        // eslint-disable-next-line
    }, [isOn, isMouseDown]);

    useEffect(() => {
        if (isOn) {
            window.addEventListener("keyup", exitScreencaptureMode);
        } else {
            window.removeEventListener("keyup", exitScreencaptureMode);
        }

        return () => {
            window.removeEventListener("keyup", exitScreencaptureMode);
        }
        //eslint-disable-next-line
    }, [isOn]);

    const exitScreencaptureMode = (e: any) => {
        if (e.key.toLowerCase() === "escape") {
            setIsOn(false);
            setIsMouseDown(false);
            setBorderWidth("");
            onExit();
        }
    }

    const handleWindowResize = () => {
        const windowWidth =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        const windowHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

        setWindowWidth(windowWidth);
        setWindowHeight(windowHeight);
    };

    const handleMouseMove = (e: any) => {
        let newCropPositionTop = startY;
        let newCropPositionLeft = startX;
        const endX = e.clientX;
        const endY = e.clientY;
        const isStartTop = endY >= startY;
        const isStartBottom = endY <= startY;
        const isStartLeft = endX >= startX;
        const isStartRight = endX <= startX;
        const isStartTopLeft = isStartTop && isStartLeft;
        const isStartTopRight = isStartTop && isStartRight;
        const isStartBottomLeft = isStartBottom && isStartLeft;
        const isStartBottomRight = isStartBottom && isStartRight;
        let newBorderWidth = borderWidth;
        let newCropWidth = 0;
        let newCropHeigth = 0;

        if (isMouseDown) {
            if (isStartTopLeft) {
                newBorderWidth = `${startY}px ${windowWidth - endX}px ${windowHeight -
                    endY}px ${startX}px`;
                newCropWidth = endX - startX;
                newCropHeigth = endY - startY;
            }

            if (isStartTopRight) {
                newBorderWidth = `${startY}px ${windowWidth - startX}px ${windowHeight -
                    endY}px ${endX}px`;
                newCropWidth = startX - endX;
                newCropHeigth = endY - startY;
                newCropPositionLeft = endX;
            }

            if (isStartBottomLeft) {
                newBorderWidth = `${endY}px ${windowWidth - endX}px ${windowHeight -
                    startY}px ${startX}px`;
                newCropWidth = endX - startX;
                newCropHeigth = startY - endY;
                newCropPositionTop = endY;
            }

            if (isStartBottomRight) {
                newBorderWidth = `${endY}px ${windowWidth - startX}px ${windowHeight -
                    startY}px ${endX}px`;
                newCropWidth = startX - endX;
                newCropHeigth = startY - endY;
                newCropPositionLeft = endX;
                newCropPositionTop = endY;
            }
        }

        newCropWidth *= window.devicePixelRatio;
        newCropHeigth *= window.devicePixelRatio;

        setCrossHairsTop(e.clientY);
        setCrossHairsLeft(e.clientX);
        setBorderWidth(newBorderWidth);
        setCropWidth(newCropWidth);
        setCropHeight(newCropHeigth);
        setCropPositionTop(newCropPositionTop);
        setCropPositionLeft(newCropPositionLeft);
    }

    const handleMouseDown = (e: any) => {
        setStartX(e.clientX);
        setStartY(e.clientY);
        setCropPositionTop(e.clientY);
        setCropPositionLeft(e.clientX);
        setIsMouseDown(true);
        setBorderWidth(`${windowWidth}px ${windowHeight}px`);
    }

    const handleMouseUp = () => {
        setIsOn(false);
        setIsMouseDown(false);
        setBorderWidth("");
    }

    const handleClickTakeScreenShot = () => {
        const body = document.querySelector('body');

        if (body) {
            onLoadCapture();
            html2canvas(body).then((canvas: any) => {
                const croppedCanvas = document.createElement('canvas');
                const croppedCanvasContext = croppedCanvas.getContext('2d');

                croppedCanvas.width = cropWidth;
                croppedCanvas.height = cropHeight;

                if (croppedCanvasContext) {
                    croppedCanvasContext.drawImage(
                        canvas,
                        cropPositionLeft * window.devicePixelRatio,
                        cropPositionTop * window.devicePixelRatio,
                        cropWidth,
                        cropHeight,
                        0,
                        0,
                        cropWidth,
                        cropHeight,
                    );
                }

                if (croppedCanvas) {
                    onEndCapture(croppedCanvas.toDataURL(), {
                        top: cropPositionTop,
                        left: cropPositionLeft,
                        width: cropWidth / window.devicePixelRatio,
                        height: cropHeight / window.devicePixelRatio
                    });
                }
            });
        }

        setCrossHairsTop(0);
        setCrossHairsLeft(0);
    }

    const renderChild = () => {
        if (typeof children === 'function') {
            return children({
                onStartCapture: () => { setIsOn(true); }
            });
        }

        return children;
    }

    return (
        <>
            {
                isOn ?
                    <div
                        onMouseMove={handleMouseMove}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                    >
                        {renderChild()}
                        <div
                            className={`overlay ${isMouseDown && 'highlighting'}`}
                            style={{ borderWidth: `${borderWidth}` }}
                        />
                        <div
                            className='crosshairs'
                            style={{ left: crossHairsLeft + 'px', top: crossHairsTop + 'px' }}
                        />
                    </div>
                    :
                    renderChild()
            }
        </>
    )
};

export default ScreenCaptureFC;