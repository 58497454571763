export enum NavigationPages {
  Folder = 1,
  SearchCategories = 2,
  Dashboard = 3,
  Profile = 4,
  ChatSearch = 5,
  Integrations = 6,
  MarketInsights = 7
}

export const Box = {
  clientId: 'lsboa38a7jbjuz7rq83vrnjz0222p3no',
};

export const Datadog = {
  rumAppId: '7c614757-928e-413a-9292-1c5a9ec11643',
  rumClientToken: 'pubc12e99787de77a94d1b892b5cec7e0c4',
};

export const Dropbox = {
  clientId: 'i5n7sjsa013ihis',
};

export const Google = {
  clientId: '41474212797-ep967oh4r6ncgi2cai4j72uaq28bgukf.apps.googleusercontent.com',
  scope: 'openid email https://www.googleapis.com/auth/drive',
};

export const LaunchDarkly = (environment: string) => ({
  clientId: environment === 'app' ? '63ffb41435775a136e7ad900' : '640f52d2309d8413d6f88ed7',
});

export const Microsoft = {
  clientId: 'a8a883a4-ec5c-4f8b-a5a6-13a02c032521',
  scope: 'Files.ReadWrite Sites.ReadWrite.All User.Read GroupMember.Read.All offline_access',
};