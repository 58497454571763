import React, { useState } from "react";
import { HighlightArea, RenderHighlightsProps } from "@react-pdf-viewer/highlight";
import { useTheme } from "@mui/styles";
import pink from "@mui/material/colors/pink";
import PopupEditor from "../../../atoms/PopupEditor";
import { ExtendedComment } from "../../../../types/comments";

export interface CommentHightlightAreas {
    id: string;
    highlightAreas: HighlightArea[];
}

const CommentsHighlightArea: React.FC<{
    commentAreas: CommentHightlightAreas[],
    comments: ExtendedComment[],
    selectedComment: CommentHightlightAreas | undefined,
    onUpdate?: (item: ExtendedComment) => void,
    onRemove?: (id: string) => void
} & RenderHighlightsProps> =
    ({ commentAreas, comments, selectedComment, pageIndex, getCssProperties, rotation, onUpdate, onRemove }) => {
        const theme = useTheme();

        const [isOpen, setIsOpen] = useState<boolean>(false);
        const [popupComment, setPopupComment] = useState<{ comment: ExtendedComment, cssProps: React.CSSProperties } | undefined>();

        return (
            <div>
                {
                    selectedComment?.highlightAreas
                        .filter((area) => area.pageIndex === pageIndex)
                        .map((area, i) => (
                            <div
                                style={Object.assign(
                                    {},
                                    {
                                        background: pink[300],
                                        opacity: 0.3,
                                        zIndex: 1,
                                    },
                                    getCssProperties(area, rotation)
                                )}
                                key={'comments-highlight-area-42-' + i}
                            />
                        ))
                }
                {
                    commentAreas.map((commentArea, i) => {
                        return <React.Fragment key={'comments-highlight-area-48-' + i}>
                            {
                                commentArea.highlightAreas
                                    .filter((area) => area.pageIndex === pageIndex)
                                    .map((area, i) => (
                                        <>
                                            <div
                                                style={Object.assign(
                                                    {},
                                                    {
                                                        background: theme.palette.primary.main,
                                                        opacity: 0.3,
                                                        zIndex: 1,
                                                        cursor: onUpdate ? "pointer" : null
                                                    },
                                                    getCssProperties(area, rotation)
                                                )}
                                                onClick={() => {
                                                    if (onUpdate) {
                                                        const openedComment = comments.find(x => x.id === commentArea.id);
                                                        if (openedComment) {
                                                            setIsOpen(true);
                                                            setPopupComment({ comment: openedComment, cssProps: getCssProperties(area, rotation) });
                                                        }
                                                    }
                                                }}
                                                key={'comments-highlight-area-74-' + i} />

                                        </>
                                    ))
                            }
                            {
                                (isOpen && popupComment) &&
                                <div style={popupComment?.cssProps}>
                                    <PopupEditor
                                        close={() => setIsOpen(false)}
                                        comment={popupComment?.comment}
                                        updateComment={(item) => { if (onUpdate) { onUpdate(item); } setIsOpen(false); }}
                                        deleteComment={(id) => { if (onRemove) { onRemove(id); } setIsOpen(false); }}
                                        onMouseLeave={()=>{setIsOpen(false)}}
                                    />
                                </div>
                            }
                        </React.Fragment>
                    })
                }
            </div >
        )
    };

export default CommentsHighlightArea;