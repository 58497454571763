import React, { useContext, useEffect, useMemo } from "react";
import DigestAnswer from "../dashboard-query-answer/DigestAnswer";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import CustomQueryContainer from "../dashboard-query-container/CustomQueryContainer";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";

const DigestQuery: React.FC<{}> = () => {
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { queryAnswer, removeQuery } = useDashboardQuery('Digest');

    const isVisibleByDefault = useMemo(() =>
        defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Digest')?.visibleByDefault ?? true
    , [defaultQueryTemplateValues]);

    const isPresent = useMemo(() => 'answer' in queryAnswer && !!queryAnswer.answer, [queryAnswer]);
    const isDeleted = useMemo(() => 'deletedAt' in queryAnswer && !!queryAnswer.deletedAt, [queryAnswer]);
    const isHidden = useMemo(() => isDeleted || (!isVisibleByDefault && !isPresent), [isDeleted, isPresent, isVisibleByDefault]);

    useEffect(() => {
        if (!isDeleted && (!isVisibleByDefault && isPresent) && !queryAnswer.manualOverride)
            removeQuery(queryAnswer);
    // eslint-disable-next-line
    }, [isDeleted, isPresent, isVisibleByDefault, queryAnswer]);

    if (isHidden)
        return (<></>);

    return (<>
        <CustomQueryContainer queryAnswer={queryAnswer} contentOnly>
            <DigestAnswer answeredQuestion={queryAnswer.answeredQuestion} />
        </CustomQueryContainer>
    </>);
}

export default DigestQuery;
