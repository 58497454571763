import React, { createContext, useState } from "react";
import { CompanyFile, FolderTab } from "../types/files";
import { AdvancedSearchObject, SearchResult, SearchDocumentType, SearchType as NewSearchType } from "../types/search";
import { SearchType } from "../types/search";
import { v4 as uuidv4 } from "uuid";

interface Context {
    contextSelectedFile: (CompanyFile | null)[];
    contextSetSelectedFile: (file: CompanyFile | null) => void;
    contextSelectedFolder: (CompanyFile | null)[];
    contextSetSelectedFolder: (file: CompanyFile | null) => void;
    contextSearchKeywords: string[][];
    contextSetSearchKeywords: (keywords: string[]) => void;
    contextSearchFolder: (CompanyFile | null)[];
    contextSetSearchFolder: (file: CompanyFile | null) => void;
    contextSearchFile: (CompanyFile | null)[];
    contextSetSearchFile: (file: CompanyFile | null) => void;
    contextSelectedSearchResult: (CompanyFile | SearchResult | undefined)[];
    contextSetSelectedSearchResult: (file: CompanyFile | SearchResult | undefined) => void;
    contextSearchResults: SearchResult[][];
    contextSetSearchResults: (results: SearchResult[]) => void;
    contextTitleSearchResults: CompanyFile[][];
    contextSetTitleSearchResults: (results: CompanyFile[]) => void;
    contextShowSearchResults: boolean[];
    contextSetShowSearchResults: (res: boolean) => void;
    contextSelectedSearchType: SearchType[];
    contextSetSelectedSearchType: (res: SearchType) => void;
    contextIncludeExcels: boolean[];
    contextSetIncludeExcels: (res: boolean) => void;
    contextSetFolderTabs: (res: FolderTab[]) => void;
    contextSetSelectedFolderTab: (res: number) => void;
    contextTabs: FolderTab[];
    contextSelectedTab: number;
    contextAdvancedSearchObject: (AdvancedSearchObject | null | undefined)[][];
    contextSetAdvancedSearchObject: (res: (AdvancedSearchObject | null | undefined)[]) => void;
    contextSelectedDocTypes: (SearchDocumentType[] | null | undefined)[];
    contextSetSelectedDocTypes: (res: SearchDocumentType[] | null | undefined) => void;
}

export const SearchNavigationContext = createContext<Context>({
    contextSelectedFile: [],
    contextSetSelectedFile: () => { },
    contextSelectedFolder: [],
    contextSetSelectedFolder: () => { },
    contextSearchKeywords: [],
    contextSetSearchKeywords: () => { },
    contextSearchFolder: [],
    contextSetSearchFolder: () => { },
    contextSearchFile: [],
    contextSetSearchFile: () => { },
    contextSelectedSearchResult: [],
    contextSetSelectedSearchResult: () => { },
    contextSearchResults: [],
    contextSetSearchResults: () => { },
    contextTitleSearchResults: [],
    contextSetTitleSearchResults: () => { },
    contextShowSearchResults: [false],
    contextSetShowSearchResults: () => { },
    contextSelectedSearchType: [SearchType.Keyword],
    contextSetSelectedSearchType: () => { },
    contextIncludeExcels: [false],
    contextSetIncludeExcels: () => { },
    contextSetFolderTabs: () => { },
    contextSetSelectedFolderTab: () => { },
    contextTabs: [],
    contextSelectedTab: 0,
    contextAdvancedSearchObject: [],
    contextSetAdvancedSearchObject: () => { },
    contextSelectedDocTypes: [],
    contextSetSelectedDocTypes: () => { },
});

interface Props {
    children: React.ReactNode;
}

export const Provider = ({ children }: Props) => {
    const [contextSelectedFile, contextSetSelectedFile] = useState<(CompanyFile | null)[]>([]);
    const [contextSelectedFolder, contextSetSelectedFolder] = useState<(CompanyFile | null)[]>([]);
    const [contextSearchKeywords, contextSetSearchKeywords] = useState<string[][]>([]);
    const [contextSearchFolder, contextSetSearchFolder] = useState<(CompanyFile | null)[]>([]); //vechi
    const [contextSearchFile, contextSetSearchFile] = useState<(CompanyFile | null)[]>([]); // vechi
    const [contextSelectedSearchResult, contextSetSelectedSearchResult] = useState<(CompanyFile | SearchResult | undefined)[]>([]);
    const [contextSearchResults, contextSetSearchResults] = useState<SearchResult[][]>([]);
    const [contextTitleSearchResults, contextSetTitleSearchResults] = useState<CompanyFile[][]>([]);
    const [contextShowSearchResults, contextSetShowSearchResults] = useState<boolean[]>([false]);
    const [contextSelectedSearchType, contextSetSelectedSearchType] = useState<SearchType[]>([SearchType.Keyword]); //vechi folosibil
    const [contextIncludeExcels, contextSetIncludeExcels] = useState<boolean[]>([false]); // vechu
    const [contextTabs, contextSetTabs] = useState<FolderTab[]>([]);
    const [contextSelectedTab, contextSetSelectedTab] = useState<number>(0);
    const [contextSelectedDocTypes, contextSetSelectedDocTypes] = useState<(SearchDocumentType[] | null | undefined)[]>([]);
    const [contextAdvancedSearchObject, contextSetAdvancedSearchObject] = useState<(AdvancedSearchObject | null | undefined)[][]>([[{
        id: uuidv4(),
        file: null,
        fileValue: null,
        folder: null,
        value: null,
    } as AdvancedSearchObject]]);
    return (
        <SearchNavigationContext.Provider
            value={{
                contextAdvancedSearchObject,
                contextSetAdvancedSearchObject: (searchObject) => {
                    contextAdvancedSearchObject[contextSelectedTab] = searchObject;
                    contextSetAdvancedSearchObject([...contextAdvancedSearchObject]);
                },
                contextSelectedDocTypes,
                contextSetSelectedDocTypes: (docType) => {
                    contextSelectedDocTypes[contextSelectedTab] = docType;
                    contextSetSelectedDocTypes([...contextSelectedDocTypes]);
                },
                contextSelectedFile,
                contextSetSelectedFile: (file) => {
                    contextSelectedFile[contextSelectedTab] = file;
                    contextSetSelectedFile([...contextSelectedFile]);
                },

                contextSelectedFolder,
                contextSetSelectedFolder: (folder) => {
                    contextSelectedFolder[contextSelectedTab] = folder;
                    contextSetSelectedFolder([...contextSelectedFolder]);
                },

                contextSearchKeywords,
                contextSetSearchKeywords: (keywords) => {
                    contextSearchKeywords[contextSelectedTab] = keywords;
                    contextSetSearchKeywords([...contextSearchKeywords]);
                },

                contextSearchFolder,
                contextSetSearchFolder: (folder) => {
                    contextSearchFolder[contextSelectedTab] = folder;
                    contextSetSearchFolder([...contextSearchFolder]);
                },

                contextSearchFile,
                contextSetSearchFile: (file) => {
                    contextSearchFile[contextSelectedTab] = file;
                    contextSetSearchFile([...contextSearchFile]);
                },

                contextSearchResults,
                contextSetSearchResults: (results) => {
                    contextSearchResults[contextSelectedTab] = results;
                    contextSetSearchResults([...contextSearchResults]);
                },

                contextSelectedSearchResult,
                contextSetSelectedSearchResult: (result) => {
                    contextSelectedSearchResult[contextSelectedTab] = result;
                    contextSetSelectedSearchResult([...contextSelectedSearchResult]);
                },

                contextTitleSearchResults,
                contextSetTitleSearchResults: (results) => {
                    contextTitleSearchResults[contextSelectedTab] = results;
                    contextSetTitleSearchResults([...contextTitleSearchResults]);
                },

                contextShowSearchResults,
                contextSetShowSearchResults: (show) => {
                    contextShowSearchResults[contextSelectedTab] = show;
                    contextSetShowSearchResults([...contextShowSearchResults]);
                },

                contextSelectedSearchType,
                contextSetSelectedSearchType: (type) => {
                    contextSelectedSearchType[contextSelectedTab] = type;
                    contextSetSelectedSearchType([...contextSelectedSearchType]);
                },

                contextIncludeExcels,
                contextSetIncludeExcels: (include) => {
                    contextIncludeExcels[contextSelectedTab] = include;
                    contextSetIncludeExcels([...contextIncludeExcels]);
                },
                contextSetFolderTabs: contextSetTabs,
                contextSetSelectedFolderTab: (e) => {
                    contextSetSelectedTab(e);
                    contextSetAdvancedSearchObject([...contextAdvancedSearchObject, [{
                        id: uuidv4(),
                        file: null,
                        fileValue: null,
                        folder: null,
                        value: null,
                    } as AdvancedSearchObject]]);
                    contextSetSelectedSearchType([...contextSelectedSearchType, NewSearchType.Keyword]);
                },
                contextSelectedTab: contextSelectedTab,
                contextTabs: contextTabs,
            }}
        >
            {children}
        </SearchNavigationContext.Provider>
    );
};

export default Provider;
