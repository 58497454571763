import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExportPDF from "../../molecules/dashboard-export/ExportPDF";
import DashboardExportPDFPreviewModal from "./DashboardExportPDFPreviewModal";
import { ReactComponent as ExportIcon } from "../../../assets/icons/download.svg";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {DashboardContext} from "../../../contexts/DashboardContext";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";
import storage from "../../../utils/storage";
import { useSnackbar } from "notistack";
import { ExcludeExportQueries } from "../../../hooks/useBulkDashboards";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: 450,
            height: 600,
            maxHeight: 600,
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.25rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
    },
    downloadIcon: {
        width: 24,
        height: 24,
        stroke: 'black',
    },
    checklist: {
        height: '100%',
        overflowY: 'auto',
    },
    listItem: {
       "& > .MuiTypography-root": {
            fontSize: '0.95rem',
       }
    },
    exportIcon: {
        color: theme.colors.primary['400'],
    },
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const DashboardExportPDFModal: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { dashboard } = useContext(DashboardContext);
    const { queries, defaultQueryTemplateValues } = useContext(DashboardQueriesContext);

    const [selectedTitles, setSelectedTitles] = useState<({title: string, displayTitle: string, checked: boolean})[]>([]);
    const [openPreview, setOpenPreview] = useState<boolean>(false);

    // this will enable previewing of the PDF without downloading
    const showPDFPreview = storage.getItem('config.dashboardExport-preview') === true;

    const filteredTitles = useMemo(() => selectedTitles
        .filter(selection => selection.checked)
        .map(selection => selection.title)
    , [selectedTitles]);

    const filteredQueries = useMemo(() => [...queries]
        .filter(query => !ExcludeExportQueries.includes(query.title))
        .filter(query => filteredTitles.includes(query.title))
        .map(query => ({...query,
            title: (defaultQueryTemplateValues
                .find(defQuery => defQuery?.title === query.title)?.displayTitle || query.title)
                    .replace('2.0', ''),
            order: defaultQueryTemplateValues.find(defQuery => defQuery.title === query.title)?.order || query.order
        })).sort((qA, qB) => qA.order - qB.order)
    , [queries, filteredTitles, defaultQueryTemplateValues]);

    const handlePersistFilteredTitles = () => {
        storage.setItem('config.dashboardExport-titles', filteredTitles.join('|'));
        enqueueSnackbar(`Default queries saved successfully.`, {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right"
            },
            autoHideDuration: 4000
        });
    }

    const handleFilteredTitles = useCallback((title: string) => () => {
        setSelectedTitles(prev => prev.map(selection =>
            selection.title === title ? ({...selection, checked: !selection.checked}) : selection));
    }, []);

    useEffect(() => {
        if (!selectedTitles.length) {
            const persistedFilteredTitles = storage.getItem('config.dashboardExport-titles')?.split('|');

            setSelectedTitles(defaultQueryTemplateValues
                .filter(defQuery => !ExcludeExportQueries.includes(defQuery.title))
                .sort((qA, qB) => qA.order - qB.order)
                .map(defQuery => ({
                    title: defQuery.title,
                    displayTitle: defQuery.displayTitle,
                    checked: persistedFilteredTitles?.includes(defQuery.title) || false,
                })));
        }
    // eslint-disable-next-line
    }, []);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                    <ExportIcon className={classes.downloadIcon} />
                    <span>{'Download as PDF'}</span>
                </Stack>
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Typography fontWeight="bold">{'Select the information to be shown:'}</Typography>
                <List className={classes.checklist} >
                    {selectedTitles.map(({title, displayTitle, checked}, i) => (
                        <ListItem key={'dashboard-export-pdf-dialog-137-' + i} disablePadding>
                            <ListItemButton role={undefined} onClick={handleFilteredTitles(title)} dense>
                                <ListItemIcon>
                                    <Checkbox edge="start" checked={checked} disableRipple />
                                </ListItemIcon>
                                <ListItemText id={displayTitle} className={classes.listItem} primary={displayTitle} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    <Button
                        variant="contained"
                        className={classes.commonButton}
                        onClick={handlePersistFilteredTitles}
                    > Save as default </Button>
                    {showPDFPreview && (
                        <IconButton
                            size="medium"
                            className={classes.exportIcon}
                            disabled={!dashboard}
                            onClick={() => setOpenPreview(true)}
                        > <OpenInNewIcon /> </IconButton>
                    )}
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                    <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            onClose();
                        }}
                    > Cancel </Button>
                    <ExportPDF
                        dashboard={dashboard}
                        queries={filteredQueries}
                        onDownload={onClose} />
                </Stack>
            </DialogActions>
        </Dialog>
        <DashboardExportPDFPreviewModal
            isOpen={openPreview}
            dashboard={dashboard}
            filteredQueries={filteredQueries}
            onClose={() => setOpenPreview(false)} />
    </>);
}

export default DashboardExportPDFModal;