import React, { ReactNode, useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    icon: {
        padding: 'unset',
        "& > svg": {
            fill: theme.colors.neutral['500'],
        },
    },
    small: {
        "& > svg": {
            width: 20,
            height: 20,
        },
    },
    medium: {
        "& > svg": {
            width: 24,
            height: 24,
        },
    },
    large: {
        "& > svg": {
            width: 28,
            height: 28,
        },
    },
}));

const HoverEditable: React.FC<{
    variant: 'small' | 'medium' | 'large',
    disableEdit?: boolean,
    children?: ReactNode,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
}> = ({ variant, disableEdit, children, onClick }) => {
    const classes = useStyles();
    const [hovered, setHovered] = useState<boolean>(false);

    return (<>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start"
            onMouseLeave={() => setHovered(false)}>
            <Box width="auto" onMouseEnter={() => setHovered(true)}>
                {children}
            </Box>
            {(!disableEdit && hovered) ? (
                <IconButton className={classNames(classes.icon,
                        (variant === 'small') && classes.small,
                        (variant === 'medium') && classes.medium,
                        (variant === 'large') && classes.large,
                    )}
                    onClick={(e) => { e.stopPropagation(); onClick?.(e);}}
                    disableRipple> <EditOutlinedIcon /> </IconButton>
            ) : (<Box width={24} />)}
        </Stack>
    </>);
}

export default HoverEditable;