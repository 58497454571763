import React from 'react';
import { Dropzone, ExtFile } from "@files-ui/react";
import makeStyles from '@mui/styles/makeStyles';
import FilePreviewer from './FilePreviewer';
import FileInput from './FileInput';

const useStyles = makeStyles(() => ({
    dropzone: {
        width: '100%',
        height: '100%',
        border: 'none !important',
    },
}));

const FileDropzone: React.FC<{
    extFiles: Array<ExtFile>,
    validFile?: {
        maxFileSize?: number,
        allowedFileTypes?: Array<string>,
    },
    onUpdate: (extFiles: ExtFile[]) => void,
    onDelete: (fileId: string | number | undefined) => void,
}> = ({ extFiles, validFile, onUpdate, onDelete }) => {
    const classes = useStyles();

    return (<>
        <Dropzone className={classes.dropzone}
            onChange={onUpdate}
            value={extFiles}
            maxFileSize={validFile?.maxFileSize}
            accept={!!validFile?.allowedFileTypes ? validFile.allowedFileTypes.join(',') : undefined}
            behaviour="replace"
            header={false}
            footer={false}
            clickable={false}
            label="">
            {!extFiles.length ? (
                <FileInput extFiles={extFiles} validFile={validFile} onUpdate={onUpdate} />
            ) : (
                <FilePreviewer extFiles={extFiles} onDelete={onDelete} />
            )}
        </Dropzone>
    </>);
}

export default FileDropzone;