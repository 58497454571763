import { Theme, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    badge: {
        padding: '4px 8px',
        height: '30px',
        borderRadius: '32px',
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap'
    },
}));

const getColors = (title: string, theme: Theme): {
    backgroundColor: string,
    color: string
} => {
    let colors = {
        backgroundColor: theme.colors.primary['100'],
        color: theme.colors.primary['600']
    };

    switch (title.toLowerCase()) {
        case 'new':
            colors.color = theme.colors.secondary['800'];
            colors.backgroundColor = theme.colors.secondary['50'];
            break;
        case 'in progress':
            colors.color = theme.colors.blue['800'];
            colors.backgroundColor = theme.colors.blue['100'];
            break;
        case 'passed':
            colors.backgroundColor = theme.colors.warning['100'];
            colors.color = theme.colors.warning['600'];
            break;
        case 'done':
            colors.backgroundColor = theme.colors.success['100'];
            colors.color = theme.colors.success['600'];
            break;
        default:
            colors.backgroundColor = theme.colors.neutral['100'];
            colors.color = theme.colors.neutral['600'];
            break;
    }

    return colors;
}

const SingleStatusBadge: React.FC<{ title: string, callback?: () => void }> = ({ title, callback }) => {
    const classes = useStyles();
    const theme = useTheme();

    return <Typography
        className={classes.badge}
        variant="b3-semibold"
        sx={{
            ...getColors(title, theme),
            textTransform: 'capitalize'
        }}
        onClick={() => {
            callback?.();
        }}
    >
        {title}
    </Typography>
};

export default SingleStatusBadge;