import { DialogActions, DialogContent, Divider } from "@mui/material";
import { ReactNode } from "react";
import { makeStyles } from "@mui/styles";
import { TabPanel } from "@mui/lab";
import { BulkShareCuratedTabs } from ".";

const useStyles = makeStyles(() => ({
    tabPanel: {
        width: 'fit-content',
        height: 'fit-content',
        padding: 'unset',
    },
    dialogContent: {
        width: '100%',
        height: '100%',
        padding: '4px 8px',
    },
    dialogActions: {
        width: '100%',
        height: '100%',
        padding: '4px 8px',
    },
}));

const BulkSharePanel: React.FC<{
    value: BulkShareCuratedTabs,
    content?: ReactNode,
    actions?: ReactNode,
    noActionDiv?: boolean,
}> = ({ value, content, actions, noActionDiv }) => {
    const classes = useStyles();

    return (<>
        <TabPanel className={classes.tabPanel} value={value}>
            <DialogContent className={classes.dialogContent}>
                {content}
            </DialogContent>
            {!noActionDiv && (<Divider sx={{ margin: '8px 0' }} />)}
            <DialogActions className={classes.dialogActions}>
                {actions}
            </DialogActions>
        </TabPanel>
    </>);
}

export default BulkSharePanel;