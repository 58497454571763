import React, { createContext, useState, useContext, ReactNode, useMemo, useEffect } from "react";
import { getGroupActiveSubscriptionFunc, getUsageFunc } from "../lib/helper";
import { AuthContext } from "./AuthContext";
import { CustomerSubscription, UsageData } from "../API";

type SubscriptionContextProps = {
    subscription: CustomerSubscription | undefined | null;
    usage: UsageData | undefined | null;
}

export const SubscriptionContext = createContext<SubscriptionContextProps>({
    subscription: undefined,
    usage: undefined,
});

const SubscriptionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { userGroup } = useContext(AuthContext);
    const [subscription, setSubscription] = useState<CustomerSubscription | undefined | null>(undefined);
    const [usage, setUsage] = useState<UsageData | undefined | null>(undefined);

    useEffect(() => {
        if (userGroup) {
            Promise.all([
                getGroupActiveSubscriptionFunc({ group: userGroup }),
                getUsageFunc({ group: userGroup }),
            ]).then(([subscriptionData, usageData]) => {
                setSubscription(subscriptionData ?? null);
                setUsage(usageData ?? null);
            });
        }
    }, [userGroup]);

    const contextValue = useMemo(() => ({
        subscription,
        usage,
    }), [
        subscription,
        usage,
    ]);

    return (
        <SubscriptionContext.Provider value={contextValue}>
            {children}
        </SubscriptionContext.Provider>
    );
}

export default SubscriptionProvider;
