import { useCallback } from 'react';
import { Dashboard } from '../types/files';

const useBackwardsCompatible = () => {
  const dashboardWorkspaces = useCallback((dashboard?: Dashboard): string[] =>
    dashboard?.workspaceMemberships?.map(ws => ws.workspaceId) ?? dashboard?.workspaces ?? []
  , []);

  return { dashboardWorkspaces };
};

export default useBackwardsCompatible;
