import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Stack } from "@mui/material";
import MobileAccessPage from "./pages/MobileAccessPage";

const MobileApp: React.FC<{}> = () => {
  	return (<>
		<Stack direction="row" minHeight="100vh" maxHeight="100vh">
			<Switch>
				<Route exact path="/">
					<MobileAccessPage />
				</Route>

				<Route exact path="/*">
					<Redirect to="/" />
				</Route>
			</Switch>
		</Stack>
	</>);
}

export default MobileApp;
