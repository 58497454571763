import { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { LoadingButton } from "@mui/lab";
import { Tooltip, Typography, Divider, Stack, Button } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from "@mui/styles";
import LinkIcon from '@mui/icons-material/Link';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Dashboard } from "../../../types/files";
import {DashboardContext} from "../../../contexts/DashboardContext";
import useDashboard from "../../../hooks/useDashboard";
import useRoute from "../../../hooks/useRoute";
import theme from "../../../theme";
import DashboardShareModal from "../../modals/dashboard-details/DashboardShareModal";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        width: 60,
        height: 36,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 20,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        transition: 'ease-in-out 300ms',
        "& > span": {
            margin: 'unset',
        },
    },
    linkIcon: {
        width: 26,
        height: 26,
        color: theme.palette.primary.main,
    },
    sharedIcon: {
        width: 28,
        height: 28,
        color: theme.colors.warning['500'],
    },
    sharedIconButton: {
        "&:hover": {
            background: theme.colors.primary['50'],
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    slimButton: {
        minWidth: 40,
        padding: 'unset',
        margin: 'unset',
        background: 'unset',
        "& > svg": {
            color: theme.colors.warning['500'],
        },
        "&:hover": {
            background: 'unset',
        }
    },
    inProgressButton: {
        "& > svg": {
            color: 'transparent',
        },
    },
    sharingText: {
        color: theme.colors.orange['500'],
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        textDecoration: 'none',
    },
    copyText: {
        color: theme.colors.neutral['600'],
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        textDecoration: 'none',
        textTransform: 'none',
    },
    stopSharingText: {
        color: theme.colors.error['500'],
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        textDecoration: 'none',
        textTransform: 'none',
    },
}));

const DashboardShare: React.FC<{
    dashboard?: Dashboard,
    hidden?: boolean,
    hiddenOnPrivate?: boolean,
    slim?: boolean,
}> = ({dashboard, hidden, hiddenOnPrivate, slim}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { dashboard: globalDashboard } = useContext(DashboardContext);
    const { generateDashboardSharingURL } = useRoute();
    const { sharePublicly } = useDashboard();

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
    const [shared, setShared] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    const localDashboard = useMemo(() => dashboard ?? globalDashboard, [dashboard, globalDashboard]);

    const handleSharePublic = useCallback(async () => {
        if (!!localDashboard) {
            setShareModalOpen(false);
            setInProgress(true);
            await sharePublicly(true, localDashboard);
        }
    // eslint-disable-next-line
    }, [localDashboard, shared]);

    const handleCopy = useCallback(() => {
        enqueueSnackbar('Link copied to clipboard', {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            autoHideDuration: 4000,
        });
        setShow(false);
    // eslint-disable-next-line
    }, []);

    const handleSwitchSharing = useCallback(async (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (shared) {
            setInProgress(true);
            await sharePublicly(false, localDashboard!);
        } else {
            setShareModalOpen(true);
        }
    // eslint-disable-next-line
    }, [localDashboard, shared]);

    useEffect(() => {
        if (!!localDashboard) {
            setShared(localDashboard!.isPublic || false);
            setInProgress(false);
        }
    }, [localDashboard]);

    if (hidden || !localDashboard)
        return (<></>);

    if (hiddenOnPrivate && !localDashboard?.isPublic)
        return (<></>);

    return (<>
        {shared ? (
            <Tooltip open={show}
                onOpen={() => setShow(true)}
                onClose={() => setShow(false)}
                placement="bottom-end"
                title={
                    <Stack spacing={1} alignItems="flex-start" justifyContent="center"
                        onClick={(e) => e.stopPropagation()}>
                        <Typography className={classes.sharingText} fontWeight="bold">
                            {'You are currently sharing this dashboard.'}
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        <CopyToClipboard
                            text={generateDashboardSharingURL(localDashboard.id)}
                            options={{ format: "text/plain" }}
                            onCopy={handleCopy}>
                            <Button variant="text" component={Stack} direction="row" spacing={1} alignItems="center" justifyContent="flex-start !important"
                                width="100%" p="4px !important">
                                <ContentCopyIcon sx={{ width: 20, height: 20, marginLeft: '4px', color: theme.colors.neutral['600'] }} />
                                <Typography className={classes.copyText}>
                                    {'Copy URL to dashboard'}
                                </Typography>
                            </Button>
                        </CopyToClipboard>
                        <LoadingButton variant="text" component={Stack} direction="row" spacing={1} alignItems="center" justifyContent="flex-start !important"
                            width="100%" p="4px" onClick={handleSwitchSharing}>
                            <FiberManualRecordIcon sx={{ width: 20, height: 20, color: theme.colors.error['500'] }} />
                            <Typography className={classes.stopSharingText}>
                                {'Stop sharing'}
                            </Typography>
                        </LoadingButton>
                    </Stack>
                }
                componentsProps={{tooltip:{sx:{
                    display: 'flex',
                    width: 240,
                    height: 'auto',
                    padding: 2,
                    overflow: 'hidden',
                    borderRadius: 1,
                    backgroundColor: '#fff',
                    border: `1px solid ${theme.colors.neutral['100']}`,
                    boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
                    transition: '0.5s all ease-in-out',
                }}}}
                key={'dashboard-share-197'}>
                <LoadingButton
                    variant={slim ? 'text': 'contained'}
                    className={classNames(
                        classes.iconButton,
                        slim && classes.slimButton,
                        shared && classes.sharedIconButton,
                        inProgress && classes.inProgressButton,
                    )}
                    loading={inProgress}
                    onClick={handleSwitchSharing}>
                    <LinkIcon className={classNames(classes.linkIcon, shared && classes.sharedIcon)} />
                </LoadingButton>
            </Tooltip>
        ) : (
            <Tooltip
                placement="top"
                title={<Typography fontSize="0.85rem">{'Copy link'}</Typography>}
                componentsProps={{tooltip:{sx:{
                    paddingLeft: 2, paddingRight: 2,
                    backgroundColor: 'rgba(97, 97, 97)',
                }}}}
                key={'dashboard-share-220'}>
                <LoadingButton
                    variant={slim ? 'text': 'outlined'}
                    className={classNames(
                        classes.iconButton,
                        slim && classes.slimButton,
                    )}
                    onClick={handleSwitchSharing}>
                    <LinkIcon className={classes.linkIcon} />
                </LoadingButton>
            </Tooltip>
        )}
        {shareModalOpen && (
            <DashboardShareModal
                isOpen={shareModalOpen}
                publicURL={generateDashboardSharingURL(localDashboard.id)}
                onShare={handleSharePublic}
                onClose={() => setShareModalOpen(false)}
            />
        )}
    </>);
}

export default DashboardShare;