import React, { ReactNode, useContext } from 'react';
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import {FileStructureContext} from '../../../contexts/FileStructureContext';
import { useHistory } from 'react-router-dom';
import { DashboardContext } from '../../../contexts/DashboardContext';

const useStyles = makeStyles((theme) => ({
    sourcesContainer: {
        color: theme.colors.shades["100"],
        marginBottom: '16px',
        marginTop: '16px',
        width: '100%',
    },
    pdfContainer: {
        alignItems: 'center',
        display: 'flex',
        marginTop: '8px',
        cursor: 'pointer',
    },
    pdfIcon: {
        color: theme.colors.error['500'],
        height: '16.67px',
        marginRight: '4px',
        width: '16.67px',
    },
    pdfPage: {
        color: theme.colors.neutral["600"],
    },
    pdfTitle: {
        color: theme.colors.shades["100"],
        marginRight: '3px',
        width: '130px',
    },
}));

const AnswerSources: React.FC<{
    answeredQuestion: AnsweredQuestion,
    collapseElement?: ReactNode,
}> = ({ answeredQuestion, collapseElement }) => {
    const classes = useStyles();
    const history = useHistory();
    const { fileStructure } = useContext(FileStructureContext);
    const { dashboard } = useContext(DashboardContext);

    return (<>
        {answeredQuestion?.sources && (
            <Box className={classes.sourcesContainer} px={2}>
                <Typography variant="b1-semibold">Sources</Typography>
                <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                        <Stack direction="column">
                            {answeredQuestion.sources.map((source, i) => (
                                <Box className={classes.pdfContainer} key={'search-result-list-1326-' + i}>
                                    <PDFIcon className={classes.pdfIcon} />
                                    <Typography variant="b2-regular" className={classes.pdfTitle}
                                        onClick={() =>{
                                            const found = fileStructure.find((fS) => fS.name === source.filename);
                                            if (found)
                                                history.push('/dashboards/' +dashboard!.id +'/files/' + found.id +'?page=' + String(source.page ?? 1));
                                        }}> {source.filename} </Typography>
                                    <Typography className={classes.pdfPage} variant="b2-regular"> (Page {source.page}) </Typography>
                                </Box>
                            ))}
                        </Stack>
                    {collapseElement}
                </Stack>
            </Box>
        )}
    </>);
}

export default AnswerSources;
