import React, { useContext, useMemo, useState } from "react";
import { Button, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import { Dashboard } from "../../types/files";
import ShareDealModal from "../modals/dashboard-details/ShareDealModal";
import { GroupSettingsContext } from "../../contexts/GroupSettingsContext";
import { DashboardsContext } from "../../contexts/DashboardsContext";
import { ReactComponent as ShareIcon } from "../../assets/icons/share.svg";

const useStyles = makeStyles((theme) => ({
    button: {
        minWidth: 120,
        width: 'auto',
        height: 'fit-content',
        borderRadius: 32,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    addIcon: {
        height: 32,
        width: 32,
        color: theme.palette.common.white,
    },
    shareIcon: {
        width: 24,
        height: 24,
        stroke: 'white',
    },
}));

const ShareDealButton: React.FC<{ dashboard?: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const params: {[key: string]: string} = useParams();
    const { workspaces } = useContext(GroupSettingsContext);
    const { workspace } = useContext(DashboardsContext);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const hasWorkspace = useMemo(() =>
        !!workspaces.find(workspace => workspace.id === params?.workspaceId) || !!workspace
    , [params?.workspaceId, workspace, workspaces]);

    if (!dashboard || hasWorkspace)
        return (<></>);

    return (<>
        <Button variant="contained"
            className={classes.button}
            startIcon={<ShareIcon className={classes.shareIcon} />}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setModalOpen(true);
            }}
        >{`Share deal`} </Button>
        {modalOpen && (
            <ShareDealModal isOpen={modalOpen}
                onClose={() => setModalOpen(false)} />
        )}
    </>);
};

export default ShareDealButton;
