import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Fab, Grid, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import classnames from 'classnames';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { CompanyFile, Dashboard, DashboardDocument, DashboardScreenshot, DashboardSelection, DashboardUid, RefreshDataEnum } from '../../../types/files';
import { getDashboardItemType } from '../../../shared/dashboard';
import { ExtendedComment, Comment } from "../../../types/comments";
import { SaveDashboardItemType } from '../../../types/common';
//import DashboardSummaryEditor from '../DashboardSummaryEditor';
import { updateDashboardFunc } from '../../../lib/helper';
import DashboardFileComponent from '../../molecules/dashboard-item-types/DashboardFileComponent';
import DashboardScreenshotComponent from '../../molecules/dashboard-item-types/DashboardScreenshotComponent';
import DashboardSelectionComponent from '../../molecules/dashboard-item-types/DashboardSelectionComponent';
import DashboardItem from "../../molecules/dashboard-items/DashboardItem";
import DashboardTitleEditor from '../../molecules/dashboard-header/DashboardTitleEditor';
//import { useCurrentWidth } from "react-socks";
//import { mobileWidth } from '../../../components/App';
//import { ReactComponent as TextBubbleIcon } from "../../../assets/icons/textBubble.svg";
//import { ReactComponent as InfoIcon } from "../../../assets/icons/circle-info.svg";
import CloseIcon from "@mui/icons-material/Close";
import { CommentHightlightAreas } from '../../folders/components/file-preview/CommentsHighlightArea';
import { getFolderFileIcon, getFileIcon } from '../../folders/utils/files';

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: 8,
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '80%',
        maxHeight: '90%',
        padding: 0,
    },
    card: {
        height: 60,
        display: "flex",
        alignItems: "center"
    },
    documentContainer: {
        height: '100%',
    },
    sourceElement: {
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordBreak: 'break-word',
        padding: 0,
        maxWidth: "calc(100% - 55px)",
        cursor: 'pointer',
        "&:hover": {
            textDecoration: "underline"
        }
    },
    fileIcon: {
        marginRight: 10,
        marginLeft: 10,
    },
    titleContainer: {
        display: 'flex',
        padding: "8px 20px",
        alignItems: 'center',
        cursor: 'pointer',
        justifyContent: 'space-between'
    },
    separator: {
        marginLeft: 10,
    },
    fabScrollLeft: {
        position: "fixed",
        left: "calc(10% - 26px)",
        top: "calc(50% - 26px)",
        height: 52,
        width: 52,
        borderRadius: "50%",
        zIndex: 0,
        backgroundColor: 'white',
        color: '#048290',
        border: '1px solid #048290',
        '& svg': {
            fill: '#048290',
            width: 26,
            height: 30
        }
    },
    fabScrollRight: {
        position: "fixed",
        right: "calc(10% - 26px)",
        top: "calc(50% - 26px)",
        height: 52,
        width: 52,
        borderRadius: "50%",
        zIndex: 0,
        backgroundColor: 'white',
        color: '#048290',
        border: '1px solid #048290',
        '& svg': {
            fill: '#048290',
            width: 26,
            height: 30
        }
    },
    rightPanel: {
        backgroundColor: '#F8F9FA',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 32,
        gap: 32
    },
    dialogFooter: {
        marginTop: -50,
        marginBottom: 10
    }
}));

interface Props {
    dashboard: Dashboard | undefined,
    item: DashboardDocument | DashboardScreenshot | DashboardSelection | undefined,
    itemIndex: number,
    itemsCount: number,
    isOpen: boolean,
    onSummaryUpdated: (item: DashboardDocument | DashboardScreenshot | DashboardSelection) => void,
    onItemTitleUpdated: (item: DashboardDocument | DashboardScreenshot | DashboardSelection) => void,
    onClose: () => void,
    files: CompanyFile[],
    onNext: () => void,
    onBack: () => void,
    preselectedCommentId?: string;
    externalComments: ExtendedComment[];
    readonly?: boolean
}

const DashboardItemDetailsDialog = ({ externalComments, item, itemIndex, itemsCount, dashboard, isOpen, onSummaryUpdated, onItemTitleUpdated, onClose, files, onNext, onBack, preselectedCommentId, readonly = false}: Props) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    //const [identifier, setIdentifier] = useState<string>("");
    const [comments, setComments] = useState<ExtendedComment[]>([]);
    const [selectedComment, setSelectedComment] = useState<CommentHightlightAreas | undefined>();
    const [dashboardElement, setDashboardElement] = useState<DashboardDocument | DashboardScreenshot | DashboardSelection>();
    const [isNavigationEnabled] = useState<boolean>(true);

    useEffect(() => {
        setComments(externalComments);
    }, [externalComments]);

    useEffect(() => {
        if (item) {
            setDashboardElement(item);
        }
        // eslint-disable-next-line
    }, [item]);

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            const isFocusOnInput = event.target.nodeName === "INPUT" ||
                event.target.nodeName === "TEXTAREA" ||
                event.target.isContentEditable;

            if (event.key === 'ArrowRight' && itemIndex !== itemsCount - 1 && isNavigationEnabled && !isFocusOnInput) {
                onNext();
            } else if (event.key === 'ArrowLeft' && itemIndex > 0 && isNavigationEnabled && !isFocusOnInput) {
                onBack();
            }
        };
        document.removeEventListener('keydown', keyDownHandler);
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        }

        // eslint-disable-next-line
    }, [item, isNavigationEnabled]);

    useEffect(() => {
        if (location) {
            const params: any = new URLSearchParams(location.search);
            const commentId = params.get("commentId");
            const parentId = params.get("parentId");
            const dashboardItemId = params.get("dashboardItemId");
            if (isOpen && item && comments.length) {
                const itemType = getDashboardItemType(item);
                const itemId = itemType === SaveDashboardItemType.Page ? item.key : item.id;
                //setIdentifier(itemId);
                const usedCommentId = (dashboardItemId === itemId ? commentId : preselectedCommentId) || '';
                if (usedCommentId) {
                    let selectedComm = comments.find((e: any) => e.id === usedCommentId);
                    if (!selectedComm) {
                        comments.forEach(e => {
                            const localSelectedComm = e?.children?.find((e: any) => e.id === usedCommentId);

                            if (!selectedComm) {
                                selectedComm = localSelectedComm;
                            }
                        });
                    }
                    const highlightArea = parentId ?
                        comments.find((e: any) => e.id === parentId)
                        :
                        comments.find((e: any) => e.id === usedCommentId);
                    if (selectedComm) {
                        setSelectedComment({ id: usedCommentId, highlightAreas: highlightArea?.commentedItem } as CommentHightlightAreas);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [location, isOpen, item, comments]);


    const getDashboardItemComponent = (item: DashboardDocument | DashboardScreenshot | DashboardSelection | undefined) => {
        if (!item) {
            return;
        }
        let itemComponent: any;
        if ("searchTerms" in item) {
            itemComponent = <DashboardFileComponent
                dashboardId={dashboard?.id!}
                dashboardItemType={SaveDashboardItemType.Page}
                data={item as DashboardDocument}
                comments={comments}
                selectedCommentId={selectedComment?.id}
                selectedCommentParentId={''}
                onCommentAdded={addComment}
            />;
        } else if ("selectedText" in item) {
            itemComponent = <DashboardSelectionComponent
                dashboardId={dashboard?.id!}
                dashboardItemType={SaveDashboardItemType.Text}
                data={item as DashboardSelection}
                comments={comments}
                selectedCommentId={selectedComment?.id}
                selectedCommentParentId={''}
                onCommentAdded={addComment}
            />;
        } else {
            itemComponent = <DashboardScreenshotComponent
                dashboardItem={{
                    id: dashboard?.id!,
                    dashboardItemId: item.id,
                    dashboardItemType: SaveDashboardItemType.ScreenCapture,
                } as DashboardUid}
                data={item as DashboardSelection}
                isSelected={isOpen}
                adjustSizes
                comments={comments}
                selectedCommentId={selectedComment?.id}
                onCommentAdded={readonly ? undefined : addComment}
                updateComment={readonly ? undefined : updateComment}
                deleteComment={readonly ? undefined : removeComment}
            />
        }

        return <DashboardItem id={item?.id!}>
            <div style={{ height: "100%" }}>
                {itemComponent}
            </div>
        </DashboardItem>;
    }

    const addComment = (newComment: Comment) => {
        if (!item) {
            return;
        }

        const nextCommentIndex = comments.findIndex((comment) => {
            return comment.commentedItem[0].pageIndex >= newComment.commentedItem[0].pageIndex + 1;
        });

        const newCommentItem = { ...newComment } as ExtendedComment;
        newCommentItem.children = [];
        newCommentItem.index = comments.length + 1;
        let newSelectedItemComments = [...comments];
        if (getDashboardItemType(item) === SaveDashboardItemType.ScreenCapture) {
            newSelectedItemComments = [...newSelectedItemComments, newCommentItem];
        } else {
            newSelectedItemComments.splice(nextCommentIndex === -1 ? comments.length : nextCommentIndex, 0, newCommentItem);
        }

        setComments(newSelectedItemComments);

        updateDashboardFunc({
            ...dashboard,
            updatedAt: new Date(),
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: false,
                modifiedData: RefreshDataEnum.Comments
            }
        });
    }
    /*
    const addReply = (reply: ExtendedComment) => {
        let newComments = [...comments];

        newComments = newComments?.map((comment: ExtendedComment) => {
            if (comment.id === reply.parentId) {
                if (!comment.children) {
                    comment.children = [reply];
                } else {
                    comment.children.push(reply);
                }
            }
            return comment;
        });

        setComments(newComments);

        updateDashboardFunc({
            ...dashboard,
            updatedAt: new Date(),
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: false,
                modifiedData: RefreshDataEnum.Comments
            }
        });
    }*/

    const updateComment = (item: Comment) => {
        setComments(comments.map(x => {
            if (x.id === item.id) {
                x.text = item.text;
                x.title = item.title;
                x.usersMentioned = item.usersMentioned;
                x.description = item.description;
            } else if (x.children) {
                x.children = x.children.map(reply => {
                    if (reply.id === item.id) {
                        reply.text = item.text;
                        reply.title = item.title;
                        reply.usersMentioned = item.usersMentioned;
                        reply.description = item.description;
                    }

                    return reply;
                });
            }

            return x;
        }));

        updateDashboardFunc({
            ...dashboard,
            updatedAt: new Date(),
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: false,
                modifiedData: RefreshDataEnum.Comments
            }
        });
    }

    const removeComment = (commentId: string, parentId: string | undefined) => {
        let newComments = [...comments];

        if (parentId) {
            newComments = newComments?.map((comment: ExtendedComment) => {
                if (comment.id === parentId) {
                    comment.children = comment.children?.filter((child: Comment) => child.id !== commentId);
                }
                return comment;
            });
        }
        newComments = newComments?.filter((comment: Comment) => comment.id !== commentId);

        setComments(newComments);

        if (selectedComment?.id === commentId) {
            setSelectedComment(undefined);
        }

        updateDashboardFunc({
            ...dashboard,
            updatedAt: new Date(),
            refreshData: {
                lastUpdatedBy: 'user',
                shouldRefresh: false,
                modifiedData: RefreshDataEnum.Comments
            }
        });
    }
    /*
    const onUpdateSummary = (value: string) => {
        if (dashboardElement) {
            const newItem = {
                ...dashboardElement,
                summary: value
            };
            setDashboardElement(newItem);
            onSummaryUpdated(newItem);
        }
    }*/

    const getSourceElement = () => {
        if (item?.key) {
            const keyFileName = item.key.split('/').slice(-1)[0];
            const document = files.find(e => e.key === item.key);
            return (<Box
                sx={{ display: 'flex', width: '100%', alignItems: "center" }}
                onClick={(e) => {
                    if (document) {
                        e.preventDefault();
                        e.stopPropagation();
                        onSourceClick();
                    }
                }}>
                {document ? getFolderFileIcon(document, classes.fileIcon, classes.fileIcon) : getFileIcon(keyFileName, classes.fileIcon)}
                {document ? <Typography className={classes.sourceElement}>{document?.name}</Typography> : <Typography className={classes.sourceElement}>{keyFileName}</Typography>}
            </Box>);
        } else {
            return <Box
                sx={{ display: 'flex', width: 'calc(100% - 50px)' }}
            >
                <LanguageIcon className={classes.fileIcon} />
                <Typography
                    className={classes.sourceElement}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onSourceClick();
                    }}
                >{(item as DashboardScreenshot)?.url}</Typography>
            </Box>;
        }
    }

    const onSourceClick = () => {
        if (!item) {
            return;
        }

        const pageNumber = ((item as any)?.page || (item as any)?.page === 0) ?
            (item as any)?.page :
            (item as any)?.pages && (item as any)?.pages[0];

        const documentId = item.key;
        const url = (item as DashboardScreenshot)?.url;

        if (documentId && (pageNumber || pageNumber === 0)) {
            history.push(`/folders?documentId=${encodeURIComponent(documentId)}&page=${pageNumber}`);
        } else if (url) {
            window.open(url);
        }
    }

    const onUpdateItemTitle = (value: string): boolean => {
        if (dashboardElement) {
            const newItem = {
                ...dashboardElement,
                title: value
            };
            setDashboardElement(newItem);
            onItemTitleUpdated(newItem);
        }

        return true;
    }

    //const currentWidth = useCurrentWidth();
    //const isMobile = currentWidth && currentWidth < 9999 ? currentWidth <= mobileWidth : window?.innerWidth <= mobileWidth;

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    minWidth: '80%',
                    height: '95%',
                    position: 'relative',
                    overflow: 'unset',
                    borderRadius: "10px"
                }
            }}
            maxWidth="xs"
            open={isOpen}
            onBackdropClick={(e) => { e.preventDefault(); e.stopPropagation(); onClose(); }}
            onClose={() => { onClose(); }}
        >
            <Grid container className={classes.titleContainer}>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                    <DashboardTitleEditor
                        title={dashboardElement?.title || ''}
                        variant="h6"
                        onUpdate={onUpdateItemTitle}
                        style={{ fontSize: 20, fontWeight: 700, margin: 16 }}
                        readonly={readonly}
                    />
                </Grid>
                <CloseIcon onClick={onClose} style={{color: '#AEB5BC', cursor: 'pointer'}}/>
            </Grid>
            <DialogContent dividers sx={{ padding: "0 20px" }}>
                <Grid container display="flex" flexDirection="column" sx={{ height: '100%' }}>
                    <Grid item sx={{ height: '100%' }}>
                        <Grid container sx={{ height: '100%' }}>
                            <Grid
                                item
                                xs={12}
                                p="8px"
                                className={classnames(classes.documentContainer)}
                                sx={{ height: '100%' }}
                            >
                                <Grid container display="flex" flexDirection="column" height="100%">
                                    <div style={{ height: '90%' }} data-dashboarditemid={dashboardElement?.id}>
                                        {getDashboardItemComponent(dashboardElement)}
                                    </div>
                                    <Grid item p={1} display="flex" alignItems="center" sx={{ height: '25%' }}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/*
                            <Grid className={classes.rightPanel} item xs={1} height='100%'>
                                <div><TextBubbleIcon /></div>
                                <div><InfoIcon /></div>
                            </Grid>*/
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogFooter}>
                <Grid item sx={{ marginRight: "10px", marginLeft: "10px", display: 'flex', flex: "1", alignItems: 'center' }}>
                    {getSourceElement()}
                </Grid>
            </DialogActions>
            {
                itemIndex > 0 &&
                <Fab
                    size="large"
                    variant="extended"
                    className={classes.fabScrollLeft}
                    disabled={!isNavigationEnabled}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onBack();
                    }}
                >
                    <KeyboardArrowLeftIcon fontSize="large" color="info" />
                </Fab>
            }
            {
                itemIndex < itemsCount - 1 &&
                <Fab
                    size="large"
                    variant="extended"
                    className={classes.fabScrollRight}
                    disabled={!isNavigationEnabled}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onNext();
                    }}
                >
                    <KeyboardArrowRightIcon fontSize="large" color="info" />
                </Fab>
            }
        </Dialog>
    );
}

export default DashboardItemDetailsDialog;
