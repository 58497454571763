import { useCallback, useContext, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import awsExports from "../aws-exports";
import {AuthContext} from "../contexts/AuthContext";
import { DashboardsContext } from "../contexts/DashboardsContext";
import { DashboardContext } from "../contexts/DashboardContext";

const { app_domain } = awsExports;

export type FromRouteType = {
  origin?: string | null;
  originId?: string | null;
  originGroup?: string | null;
};

const useRoute = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params: { [key: string]: string } = useParams();
  const { userGroup } = useContext(AuthContext);
  const { isPublicView: publicDashboard } = useContext(DashboardContext);
  const { isPublicView: publicWorkspace } = useContext(DashboardsContext);

  const isPublic = useMemo(() => publicDashboard || publicWorkspace, [publicDashboard, publicWorkspace]);

  const getURLFrom = useCallback(() => {
    const urlFrom = new URLSearchParams(search).get('from');

    if (!!urlFrom) {
      const parsedFrom = JSON.parse(atob(urlFrom)) as FromRouteType | null;

      if (!!parsedFrom) {
        return {
          ...parsedFrom,
          originGroup: !!parsedFrom?.originGroup ? atob(parsedFrom?.originGroup) : null,
        } as FromRouteType;
      }
    }

    return null;
  }, [search]);

  const validateFrom = useCallback((workspaces: string[]) => {
    const from = getURLFrom();

    if (!!from) {
      if (!from.origin || from.origin === 'dashboards')
        return true;
      else if (from.origin === 'workspaces' && workspaces.includes(from.originId || ''))
        return true;
    } else if (!isPublic) {
      return true;
    }

    return false;
  // eslint-disable-next-line
  }, [isPublic, userGroup, getURLFrom]);

  const generateWorkspaceSharingURL = useCallback((workspaceId: string) => {
    let route = `${app_domain}/workspaces/public/${workspaceId}`;

    route += `?from=${btoa(JSON.stringify({
      origin: 'self',
      originId: null,
      originGroup: btoa(userGroup),
    }))}`;

    return route;
  }, [userGroup]);

  const generateDashboardSharingURL = useCallback((dashboardId: string) => {
    const from = getURLFrom();
    let route = `${app_domain}/dashboards/public/${dashboardId}`;

    if (!!from && from.origin === 'workspaces' && !!from.originId) {
      route += `?from=${btoa(JSON.stringify({
        origin: from.origin,
        originId: from.originId,
        originGroup: btoa(from.originGroup || userGroup),
      }))}`;
    } else {
      route += `?from=${btoa(JSON.stringify({
        origin: !!params.workspaceId ? 'workspaces' : 'dashboards',
        originId: params.workspaceId ?? null,
        originGroup: btoa(from?.originGroup || userGroup),
      }))}`;
    }

    return route;
  }, [getURLFrom, params.workspaceId, userGroup]);

  const redirectFromDashboard = useCallback(() => {
    const from = getURLFrom();
    let route = '/workspaces';

    if (isPublic)
        route += '/public';

    if (!!from && from.origin === 'workspaces' && !!from.originId) {
      route += `/${from.originId}`;

      route += `?from=${btoa(JSON.stringify({
        origin: 'self',
        originId: null,
        originGroup: btoa(from.originGroup || userGroup),
      }))}`;

      history.push(route);
    } else if (!isPublic) {
      history.push('/dashboards');
    } else {
      // history.goBack();
    }
  // eslint-disable-next-line
  }, [isPublic, userGroup, getURLFrom]);

  const redirectFromWorkspace = useCallback(() => {
    history.push('/investors');
  // eslint-disable-next-line
  }, [isPublic, userGroup, getURLFrom]);

  const redirectToDashboard = useCallback((dashboardId: string, tab?: string) => {
    const from = getURLFrom();
    let route = '/dashboards';

    if (isPublic)
      route += '/public';
    route += `/${dashboardId}`;

    if (!!tab)
      route += `/${tab}`;

    if (!!from && from.origin === 'workspaces' && !!from.originId) {
      route += `?from=${btoa(JSON.stringify({
        origin: from.origin,
        originId: from.originId,
        originGroup: btoa(from.originGroup || userGroup),
      }))}`;
    } else {
      route += `?from=${btoa(JSON.stringify({
        origin: !!params.workspaceId ? 'workspaces' : 'dashboards',
        originId: params.workspaceId ?? null,
        originGroup: btoa(from?.originGroup || userGroup),
      }))}`;
    }

    history.push(route);
  // eslint-disable-next-line
  }, [isPublic, params.workspaceId, userGroup, getURLFrom]);

  const redirectToWorkspace = useCallback((workspaceId: string) => {
    let route = '/workspaces';

    if (isPublic)
      route += '/public';

    route += `/${workspaceId}`;

    route += `?from=${btoa(JSON.stringify({
      origin: 'self',
      originId: null,
      originGroup: btoa(userGroup),
    }))}`;

    history.push(route);
  // eslint-disable-next-line
  }, [getURLFrom, isPublic, userGroup]);

  return {
    getURLFrom,
    validateFrom,
    generateWorkspaceSharingURL,
    generateDashboardSharingURL,
    redirectFromDashboard,
    redirectFromWorkspace,
    redirectToDashboard,
    redirectToWorkspace,
  };
};

export default useRoute;
