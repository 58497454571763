import { ReactComponent as BoxIcon } from "../../../assets/icons/box.svg";
import { ReactComponent as DropboxIcon } from "../../../assets/icons/dropbox.svg";
import { ReactComponent as GoogleDriveIcon } from "../../../assets/icons/google-drive.svg";
import { ReactComponent as SharePointIcon } from "../../../assets/icons/microsoft-sharepoint.svg";
import { ReactComponent as MyDesktopIcon } from "../../../assets/icons/my-desktop.svg";
import { ReactComponent as OneDriveIcon } from "../../../assets/icons/onedrive.svg";
import sortFilesByName from "../../../helpers/sortFilesByName";
import { CompanyFile } from "../../../types/files";

export const DrivePlatforms = ["googledrive", "dropbox", "onedrive", "box", "sharepoint"];
export const DrivePlatformsTitles: any = {
    mydesktop: "My desktop",
    googledrive: "Google Drive",
    dropbox: "Dropbox",
    onedrive: "OneDrive",
    box: "Box",
    sharepoint: "SharePoint"
};
export const DrivePlatformIcons: any = {
    mydesktop: <MyDesktopIcon />,
    googledrive: <GoogleDriveIcon />,
    dropbox: <DropboxIcon />,
    onedrive: <OneDriveIcon />,
    box: <BoxIcon />,
    sharepoint: <SharePointIcon />
};

export const getGroupedFolders = (folders: CompanyFile[], parentId: string | undefined) => {
    const roots = folders.filter(x => x.parentId === "root");
    const rootsDrive = roots.filter(x => DrivePlatforms.includes(x.path.toLowerCase())).map(x => ({ name: x.path.toLowerCase(), folders: folders.filter(y => y.path.toLowerCase().startsWith(x.path.toLowerCase())).sort(sortFilesByName), isDrive: true, parentId: x.id }));
    const rootsDesktop = roots.filter(x => !DrivePlatforms.includes(x.path.toLowerCase())).map(x => ({ name: x.path.toLowerCase(), folders: folders.filter(y => y.path.toLowerCase().startsWith(x.path.toLowerCase())).sort(sortFilesByName) }));

    const desktopFolders = rootsDesktop.map(x => x.folders).flat();

    const groupedFolders = [];

    if (desktopFolders?.length) {
        groupedFolders.push({ name: "mydesktop", folders: desktopFolders, isDrive: false, parentId: parentId });
    }

    groupedFolders.push(...rootsDrive);
    return groupedFolders;
}