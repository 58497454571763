import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import CrunchBaseIcon from "../../../assets/images/crunchbase-icon.png";
import makeStyles from '@mui/styles/makeStyles';
import { FallbackLoading } from "../../templates/loader";

export interface CrunchbaseOption {
    name: string;
    permalink: string;
    description: string;
}

const useStyles = makeStyles(() => ({
    formContainer: {
        height: '145px',
        maxHeight: '165px',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: '16px',
    },
    form: {
        padding: '4px',
        width: '100%',
    },
    icon: {
        width: '30px',
        height: '30px',
    },
    noCrunchbase: {
        fontSize: '0.9rem',
        color: 'gray',
    },
}));

const CrunchbaseSelection: React.FC<{
    crunchbases: Array<CrunchbaseOption>,
    loaded: boolean,
}> = ({ crunchbases, loaded }) => {
    const classes = useStyles();

    return (<>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
            <img src={CrunchBaseIcon} className={classes.icon} alt="crunch-base-icon" />
            <Typography variant="h6-bold">Select a Crunchbase page:</Typography>
        </Stack>
        <Box className={classes.formContainer}>
            {loaded ? (
                !!crunchbases.length ? (
                    crunchbases.map((option: CrunchbaseOption, i) => (
                        <FormControlLabel className={classes.form}
                            value={option.permalink}
                            control={<Radio />}
                            label={
                                <Typography sx={{ width: '100%' }}>
                                    <span style={{ fontWeight: '700' }}>{option.name}</span> - {option.description}
                                </Typography>
                            }
                            sx={{ width: '100%' }}
                            key={'crunchbase-selection-47-' + i}/>
                    ))
                ) : (
                    <Stack direction="column" alignItems="center" justifyContent="center" height="100%">
                        <Typography className={classes.noCrunchbase}>No crunchbase found</Typography>
                    </Stack>
                )
            ) : (
                <FallbackLoading />
            )}
        </Box>
    </>);
}

export default CrunchbaseSelection;