import { HighlightArea } from "@react-pdf-viewer/highlight";

export interface Comment {
    id?: string | undefined;
    parentId?: string | undefined;
    email: string;
    //userId: string;
    title: string;
    text: string;
    group: string;
    project: string;
    documentId: string;
    dashboardId?: string;
    commentedItem: HighlightArea[];
    usersMentioned: string[];
    description: string;
    originType: CommentOriginType;
    originUrl: string;
    createdAt?: Date | undefined;
    updatedAt?: string;
}

export interface ExtendedComment extends Comment {
    index?: number;
    children?: Comment[];
    lastUpdatedAt?: Date | undefined;
}

export interface Mention {
    id?: string | undefined;
    email: string;
    group: string;
    emailOfPersonWhoMentioned: string;
    commentId: string;
    createdAt?: Date | undefined;
    updatedAt?: Date | undefined;
}

export enum CommentOriginType {
    Dashboard = "Dashboard",
    Folder = "Folder"
}
