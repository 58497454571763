import { Stack, Typography, Tooltip } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { useCallback } from "react";

const WhatsExtracted: React.FC<{
    source: string,
    iconAtStart?: boolean,
    iconAtEnd?: boolean,
    position?: string,
}> = ({ source, iconAtStart, iconAtEnd }) => {
    const getBoldText = useCallback((text: string) => (
        <Typography display="inline" fontSize="0.8rem" fontWeight="bold" fontFamily="Inter">{text}</Typography>
    ), []);

    const getHelpIcon = useCallback(() => (
        <HelpIcon sx={{ height: '20px', width: '20px', fill: '#7bd4d4' }} />
    ), []);

    return (<>
        <Tooltip placement="top-start" title={
            <Stack direction="column" spacing={1} alignItems="flex-start">
                    <Typography fontSize="0.8rem" fontFamily="Inter">
                        {`Notissia extract all types of content from your ${source}, including founders, business and market information.`}
                    </Typography>
                    <Typography fontSize="0.8rem" fontFamily="Inter">
                        {'Notissia also extracts key metrics including '}
                        {getBoldText('funding round size')}{', '}
                        {getBoldText('valuation')}{', '}
                        {getBoldText('revenue')}{', '}
                        {getBoldText('margins')}{', and '}
                        {getBoldText('retention')}{' (more will be added).'}
                    </Typography>
                </Stack>}
                componentsProps={{tooltip:{sx:{
                    display: 'flex', alignItems: 'center', borderRadius: 'unset',
                    backgroundColor: 'rgba(97, 97, 97)', width: '240px', padding: 2,
                }}}}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    {iconAtStart && getHelpIcon()}
                    <Typography color="#7bd4d4" fontSize="0.9rem" fontFamily="Inter">
                        {`What gets extracted from ${source}?`}
                    </Typography>
                    {iconAtEnd && getHelpIcon()}
                </Stack>
        </Tooltip>
    </>);
}

export default WhatsExtracted;