import { useContext, useState } from "react";
import { Button, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { RenderHighlightContentProps } from "@react-pdf-viewer/highlight";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import { AuthContext } from "../../../../contexts/AuthContext";
import { getUserEmail } from "../../../../helpers/authUser";
import { createCommentFunc, createMentionFunc } from "../../../../lib/helper";
import classNames from "classnames";
import { LoadingButton } from "@mui/lab";
import { User } from "../../../../types/common";
import { Comment, CommentOriginType, ExtendedComment } from "../../../../types/comments";
import { getUserDisplayName } from "../../../../helpers/comments";
import { DashboardUid } from "../../../../types/files";
import PopupEditor from "../../../atoms/PopupEditor";

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 4,
        padding: 8,
        position: 'absolute',
        zIndex: 2,
        width: 250,
    },
    textArea: {
        fontFamily: theme.typography.fontFamily,
        marginBottom: 10,
        padding: 0,
        width: '100%',
        border: 'none',
        borderBottom: `2px solid ${theme.palette.text.primary}`,
        "& > div": {
            "& textarea": {
                border: 'none',
                outlineColor: 'transparent',
            },
        },
    },
    textLength: {
        textAlign: "right"
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    button: {
        marginRight: 10,
    },
    listItem: {
        padding: 0,
    },
    mention: {
        fontWeight: 'bold !important',
        color: theme.palette.secondary.main,
        position: "relative",
        zIndex: 1,
        textShadow: "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
        pointerEvents: "none"
    },
    mentionFocused: {
        backgroundColor: theme.palette.action.hover,
    }
}));

const AddCommentPane: React.FC<
    {
        commentOriginType: CommentOriginType,
        documentId: string,
        users: User[],
        onAdded: (item: Comment) => void,
        dashboardItem?: DashboardUid,
        containerClass?: string | undefined,
        triggerCancelAfterAdd?: boolean,
        forScreenshot?: boolean
        newEditor?: boolean
    } & RenderHighlightContentProps> = ({ commentOriginType, documentId, users, onAdded, selectedText, selectionRegion, highlightAreas, cancel, containerClass, dashboardItem, triggerCancelAfterAdd = true, forScreenshot = false, newEditor }) => {
        const classes = useStyles();
        const [loading, setLoading] = useState<boolean>(false);
        const [content, setContent] = useState<string>("");
        const [mentions, setMentions] = useState<string[]>([]);
        const { user, userGroup } = useContext(AuthContext);

        const add = (text?: string) => {
            if (!text && !content) {
                return;
            }

            setLoading(true);

            const currentEmail = getUserEmail(user);
            const item = {
                email: currentEmail,
                group: userGroup,
                project: userGroup,
                title: selectedText,
                text: text || content.toLocaleLowerCase(),
                documentId: documentId,
                dashboardId: dashboardItem ? dashboardItem.id : null,
                commentedItem: highlightAreas,
                usersMentioned: mentions,
                description: text || content,
                originType: commentOriginType,
                originUrl: dashboardItem ? JSON.stringify(dashboardItem) : null
            } as Comment;
            createCommentFunc(item).then((result: any) => {
                Promise.all(mentions.map(async (mentionUserId) => {
                    await createMentionFunc({
                        email: currentEmail,
                        group: userGroup,
                        emailOfPersonWhoMentioned: mentionUserId,
                        commentId: result.id
                    });
                })).then(() => {
                    setLoading(false);
                    onAdded(result);
                    if (triggerCancelAfterAdd) {
                        cancel();
                    }
                });
            });
        }

        const searchUsers = (query: string, callback: (data: SuggestionDataItem[]) => void) => {
            if (!query) {
                callback([]);
                return;
            }

            callback(users
                .filter(x => x?.email?.includes(query) || x?.firstName?.includes(query) || x?.lastName?.includes(query))
                .slice(0, 5)
                .map(x => ({ id: x.email, display: getUserDisplayName(x) })));
        }

        const getPositioning = (paneSize: number) => {
            const position: any = {
                top: `${selectionRegion.top + selectionRegion.height}%`,
                left: `${selectionRegion.left}%`,
                right: undefined
            };

            let pageLayerElement = document.querySelector(".active-tab .rpv-core__page-layer");

            if (!pageLayerElement) {
                pageLayerElement = document.querySelector(".dashboard-doc-selected .rpv-core__page-layer");
            }

            if (containerClass) {
                if (forScreenshot) {
                    pageLayerElement = document.querySelectorAll(`.MuiDialog-container [data-dashboarditemid="${documentId}"] .${containerClass}`)[0];
                    if (!pageLayerElement) { // if dialog does not exist/is opened
                        pageLayerElement = document.querySelectorAll(`[data-dashboarditemid="${documentId}"] .${containerClass}`)[0];
                    }
                } else {
                    pageLayerElement = document.getElementsByClassName(containerClass)[0];
                }
            } else if (forScreenshot) {
                pageLayerElement = document.body;
            }

            if (!pageLayerElement) {
                pageLayerElement = document.querySelector(".rpv-core__page-layer");
            }
            if (!pageLayerElement) {
                return position;
            }

            const leftPositionInPixels = pageLayerElement.clientWidth * selectionRegion.left / 100;
            const topPositionInPixels = pageLayerElement.clientHeight * selectionRegion.top / 100;

            const paneHeight = 310;

            if (topPositionInPixels + paneHeight > pageLayerElement.clientHeight && topPositionInPixels - paneHeight > 0) {
                position.top = `${topPositionInPixels - paneHeight}px`;
            } else if (forScreenshot) {
                position.top = `${topPositionInPixels + 25}px`;
            }

            if (leftPositionInPixels + paneSize > pageLayerElement.clientWidth - 50) {
                position.left = `${pageLayerElement.clientWidth - paneSize - 10}px`;
            }

            return position;
        }

        return <Paper
            className={classes.container}
            style={{...getPositioning(260), padding: newEditor ? 0 : 8}}
            id="new-editor"
        >
            { newEditor ? (
                <PopupEditor
                    close={cancel}
                    onMouseLeave={cancel}
                    comment={{
                        email: '',
                        title: '',
                        text: '',
                        description: ''
                    } as ExtendedComment}
                    addComment={add}
                    editableByDefault={true}
                />
            ) : (
            <div>
                <MentionsInput
                    value={content}
                    onChange={(e, _1, _2, mentions) => {
                        const maxLength = 300;
                        if (e.target.value.length > maxLength) {
                            e.target.value = e.target.value.slice(0, maxLength);
                        }
                        setContent(e.target.value);
                        setMentions(mentions.map(x => x.id));
                    }}
                    singleLine={false}
                    rows={3}
                    className={classes.textArea}
                    maxLength={300}
                >
                    <Mention
                        trigger="@"
                        data={searchUsers}
                        displayTransform={(_, display) => `@${display}`}
                        className={classes.mention}
                        renderSuggestion={(suggestion: SuggestionDataItem, _1, _2, _3, focused) => (
                            <ListItem alignItems="flex-start" className={classNames(classes.listItem, { [classes.mentionFocused]: focused })} divider>
                                <ListItemText
                                    primary={suggestion.display}
                                />
                            </ListItem>
                        )}
                    />
                </MentionsInput>
                <div className={classes.textLength}>
                    <Typography fontSize={12}>{content?.length || 0}/300</Typography>
                </div>
                <div className={classes.buttonsContainer}>
                    <LoadingButton
                        className={classes.button}
                        variant="contained"
                        loading={loading}
                        onClick={()=>add()}
                    >
                        Add
                    </LoadingButton>
                    <Button
                        className={classes.button}
                        disabled={loading}
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>)}
        </Paper>
    };

export default AddCommentPane;