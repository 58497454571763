import { Storage } from 'aws-amplify';
import { User } from '../types/auth';
import { getUserGroups } from './authUser';
import path from "path"
const { sep } = path

export enum s3Status {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

Storage.configure({
  customPrefix: {
    public: ''
  },
})

export const removeFirstFolder = (file: string) => {
  const splitPath = path.dirname(file).split(sep)
  const [, ...rest] = splitPath
  return path.join(...rest)
}

export const sha256 = async (text: string) => {
  const result = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(text))
  const hashArray = Array.from(new Uint8Array(result));
  const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
  return hashHex;
}

const getNameAndExt = (fileName: string) => {
  const [, name, ext] = fileName.match(/(.*)\.(.*)/) || []
  return [name, ext]
}

export const getFullPath = (path: string, user: User) => {
  return 'public/' + getUserGroups(user)[0] + '/' + path
}


export const getS3DashboardScreenshotMetadata = async (user: User, fileName: string, dashboardId: string) => {
  const [name, ext] = getNameAndExt(fileName);
  const group = getUserGroups(user)[0];
  let key = path.join("public", group, "dashboard-screenshots", dashboardId, `${await sha256(name)}.${ext}`);

  return {
    key
  };
}

export const LEVEL = "public";
export const s3Upload = ({ path, file }: { path: string; file: File | Blob }) =>
  Storage.put(path, file, { contentType: file.type, level: LEVEL });

//terraform has policies similar to https://docs.amplify.aws/cli/storage/import/#configuring-iam-role-to-use-amplify-recommended-policies
export const s3UploadToExternalBucket = ({ path, file, bucket }: { path: string; file: any, bucket: string }) => {
  return Storage.put(path, file, { contentType: file.type, level: LEVEL, bucket }); //will this automatically create a /public folder :|
}


export const s3Delete = ({ path }: { path: string }) =>
  Storage.remove(path, { level: LEVEL })

export const s3UrlVerify = ({
  key
}: {
  key: string;
}) => Storage.get(key, { download: false })

export const s3UrlVerifyExternalBucket = ({
  key, bucket
}: {
  key: string;
  bucket: string;
}) => Storage.get(key, { download: false, bucket })

export const s3Download = ({
  key
}: {
  key: string;
}) => Storage.get(key, { download: true }).then(data => {
  if (data.Body instanceof Blob) {
    return data.Body.arrayBuffer().then(r => new Uint8Array(r))
  }
  else {
    return undefined
  }
});

export const s3DownloadExternalBucket = ({
  key, bucket
}: {
  key: string;
  bucket: string;
}) => Storage.get(key, { download: true, bucket }).then(data => {
  if (data.Body instanceof Blob) {
    return data.Body.arrayBuffer().then(r => new Uint8Array(r))
  }
  else {
    return undefined
  }
});

export const base64ToFile = (dataUrl: string, fileName: string): File => {
  var arr = dataUrl.split(',');

  if (!arr) {
    return new File([], fileName, { type: "text/plain" });
  }

  var mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};


