import { Grid, CircularProgress, Typography, Autocomplete, TextField, FormControlLabel, Radio, RadioGroup, Button, IconButton, Tooltip, Paper, Divider } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Comment } from '../../types/comments';
import {
  getCommentsFilteredFunc, getDashboards,
} from "../../lib/helper";
import { User } from "../../types/common";
import { grey } from "@mui/material/colors";
import { CommentSearchType } from "../../types/search";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { UsersContext } from "../../contexts/UsersContext";
import { Dashboard } from "../../types/files";
import RecentActivityCard from "../molecules/recent-activity/RecentActivityCard";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 10,
    color: theme.palette.text.primary,
  },
  container: {
    height: '100%',
    overflow: 'hidden',
    padding: 20,
  },
  fullHeight: {
    height: '100%'
  },
  commentsContainer: {
    height: 'calc(100% - 30px)',
    overflowY: 'auto',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    height: 'inherit',
    alignItems: 'center'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    justifyContent: "center",
  },
  autocomplete: {
    width: '20%',
    marginLeft: 20,
    marginRight: 20,
    '& > label': {
      color: theme.palette.primary.main,
    }
  },
  autocompleteTexfield: {
    '& > label': {
      color: theme.palette.primary.main,
    }
  },
  searchButton: {
    borderRadius: 4,
    marginLeft: 20,
  },
  iconButton: {
    marginLeft: 10,
    color: theme.palette.primary.main,
  },
  searchElementsContainer: {
    marginBottom: 20,
  },
  mentionsNotesContainer: {
    height: 'calc(100% - 170px)'
  },
  mentionsNotesPaper: {
    height: '100%',
    padding: 20,
  }
}));

const RecentActivityPage = () => {
  const classes = useStyles();
  const [comments, setComments] = useState<Comment[]>();
  const [mentions, setMentions] = useState<Comment[]>();
  const [commentsLoading, setCommentsLoading] = useState<boolean>(false);
  const [mentionsLoading, setMentionsLoading] = useState<boolean>(false);
  const [mentionUsers, setMentionUsers] = useState<User[]>([]);
  const [selectedSearchUser, setSelectedSearchUser] = useState<User | null | undefined>();
  const [contentSearchValue, setContentSearchValue] = useState<string>('');
  const [selectedSearchType, setSelectedSearchType] = useState<CommentSearchType>(CommentSearchType.User);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);

  const { userGroup } = useContext(AuthContext);

  const { users, usersLoading } = useContext(UsersContext);


  useEffect(() => {
    if (!usersLoading) {
      const filteredUsers = users.filter(user => user.group === userGroup);
      setMentionUsers(filteredUsers);
    }
  }, [users, usersLoading, userGroup]);

  useEffect(() => {
    setCommentsLoading(true);
    getCommentsFilteredFunc(userGroup).then(data => {
      setComments(data);
      setCommentsLoading(false);
    });

    // mentions
    setMentionsLoading(true);
    getCommentsFilteredFunc(userGroup, true).then(data => {
      setMentions(data);
      setMentionsLoading(false);
    });

    getDashboards(userGroup).then(
      (data: Dashboard[]) => {
        // TODO: dashboard not sorted
        setDashboards(data);
      },
      error => {
        console.log(error);
      });
  }, [userGroup]);

  const handleRadioChange = (event: any) => {
    setSelectedSearchType(event.target.value);
  };

  const handleSearch = () => {
    if (selectedSearchType === CommentSearchType.User) {
      setMentionsLoading(true);

      let promise;
      if (!selectedSearchUser) {
        // mentions
        promise = getCommentsFilteredFunc(userGroup, true);
      } else {
        // mentions
        promise = getCommentsFilteredFunc(userGroup, true, undefined, selectedSearchUser.email);
      }

      promise.then(data => {
        setMentions(data);
        setMentionsLoading(false);
      });

    } else if (selectedSearchType === CommentSearchType.Content) {
      setCommentsLoading(true);
      setMentionsLoading(true);

      let commentsPromise;
      let mentionsPromise;

      if (!contentSearchValue) {
        commentsPromise = getCommentsFilteredFunc(userGroup);
        mentionsPromise = getCommentsFilteredFunc(userGroup, true);
      } else {
        commentsPromise = getCommentsFilteredFunc(userGroup, false, contentSearchValue.toLocaleLowerCase());
        mentionsPromise = getCommentsFilteredFunc(userGroup, true, contentSearchValue.toLocaleLowerCase());
      }

      commentsPromise.then(data => {
        setComments(data);
        setCommentsLoading(false);
      });

      mentionsPromise.then(data => {
        setMentions(data);
        setMentionsLoading(false);
      });
    }
  }

  const refresh = () => {
    setCommentsLoading(true);
    setMentionsLoading(true);
    setSelectedSearchType(CommentSearchType.User);
    const commentsPromise = getCommentsFilteredFunc(userGroup);
    const mentionsPromise = getCommentsFilteredFunc(userGroup, true);

    commentsPromise.then(data => {
      setComments(data);
      setCommentsLoading(false);
    });

    mentionsPromise.then(data => {
      setMentions(data);
      setMentionsLoading(false);
    });
  }

  return (
    <Grid container direction='row' className={classes.container}>
      <Grid item xs={12} className={classes.headerContainer}>
        <Typography variant="h6" className={classes.header}>Activity</Typography>
      </Grid>
      <Grid item xs={12} className={classes.searchElementsContainer}>
        <Paper className={classes.searchContainer}>
          <RadioGroup
            row
            value={selectedSearchType}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={CommentSearchType.User}
              control={<Radio />}
              label={CommentSearchType.User}
              color="default"
              sx={{
                color: grey[500],
                '&.Mui-checked': {
                  color: grey[500],
                },
                '> span': {
                  color: grey[500],
                }
              }}
            />
            <FormControlLabel
              value={CommentSearchType.Content}
              control={<Radio />}
              label={CommentSearchType.Content}
              color="default"
              sx={{
                color: grey[500],
                '&.Mui-checked': {
                  color: grey[500],
                },
                '> span': {
                  color: grey[500],
                }
              }}
            />
          </RadioGroup>
          {selectedSearchType === CommentSearchType.User && <Autocomplete
            limitTags={2}
            className={classes.autocomplete}
            getOptionLabel={(option) => option.firstName || option.email}
            options={mentionUsers}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                className={classes.autocompleteTexfield}
                label="Search by user"
              />
            )}
            onChange={(e, value) => setSelectedSearchUser(value)}
          />}
          {selectedSearchType === CommentSearchType.Content && <TextField
            className={classes.autocomplete}
            variant="standard"
            label="Search by content"
            onChange={(e) => setContentSearchValue(e.target.value)}
          />}
          <Button
            variant="contained"
            size="small"
            onClick={handleSearch}
            className={classes.searchButton}
          >Search</Button>
          <Tooltip title="Refresh" placement="top">
            <IconButton className={classes.iconButton} onClick={refresh}>
              <RestartAltIcon />
            </IconButton>
          </Tooltip>
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.mentionsNotesContainer}>
        <Paper className={classes.mentionsNotesPaper}>
          <Grid container justifyContent="space-between" className={classes.fullHeight}>
            <Grid item xs={5.85} className={classes.fullHeight}>
              <Typography variant="body1" mb="10px">Mentions</Typography>
              <div className={classes.commentsContainer}>
                {mentionsLoading ?
                  <div className={classes.loader}>
                    <CircularProgress />
                  </div> :
                  mentions?.map((comment: Comment, index: number) => {
                    const reportingUser = mentionUsers.find((user: User) => user.email === comment.email);
                    return <div key={'recent-activity-page-288-' + index}>
                      <RecentActivityCard
                        comment={comment}
                        reportingUser={reportingUser}
                        dashboards={dashboards}
                      />
                      {index < mentions?.length - 1 && <Divider />}
                    </div>
                  })}
              </div>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={5.85} className={classes.fullHeight}>
              <Typography variant="body1" mb="10px">Notes</Typography>
              <div className={classes.commentsContainer}>
                {commentsLoading ?
                  <div className={classes.loader}>
                    <CircularProgress />
                  </div> :
                  comments?.map((comment: Comment, index: number) => {
                    const reportingUser = mentionUsers.find((user: User) => user.email === comment.email);
                    return <div key={'recent-activity-page-311-' + index}>
                      <RecentActivityCard
                        comment={comment}
                        reportingUser={reportingUser}
                        dashboards={dashboards}
                      />
                      {index < comments?.length - 1 && <Divider />}
                    </div>
                  })}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default RecentActivityPage;
