import { DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { CompanyFile, Dashboard } from "../../../types/files";
import { useCallback, useContext, useMemo } from "react";
import { updateFileFunc } from "../../../lib/helper";
import {FileStructureContext} from "../../../contexts/FileStructureContext";
import {AuthContext} from "../../../contexts/AuthContext";
import FileCategoryRow from "../../folders/components/categories/FileCategoryRow";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: '600px',
            minHeight: '400px',
            height: 'fit-content',
            padding: 20,
            borderRadius: 16,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    categoryContent: {
        width: '100%',
        marginBottom: 20,
    },
}));

const DashboardDeckSelectModal: React.FC<{
    isOpen: boolean,
    dashboard: Dashboard,
    onFileSelect: (file: CompanyFile) => void ,
    onClose: () => void,
}> = ({ isOpen, dashboard, onFileSelect, onClose }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { fileStructure, reloadFileStructure } = useContext(FileStructureContext);

    const files = useMemo(() =>
        fileStructure.filter(file => !file.isDirectory && file.dashboardId === dashboard?.id) as CompanyFile[]
    , [dashboard, fileStructure]);

    const handleSetDeck = useCallback(async (file: CompanyFile) => {
        const deckFiles = files?.filter(f => f.isDeck);
        const promises: Promise<CompanyFile>[] = [];

        deckFiles?.forEach(dF => {
            promises.push(updateFileFunc({
                id: dF.id,
                group: userGroup,
                documentCategory: dF.documentCategory,
                status: dF.status,
                dashboardId: dF.dashboardId,
                marketMetrics: dF.marketMetrics,
                isDeck: false,
            }))
        });

        promises.push(updateFileFunc({
            id: file.id,
            group: userGroup,
            documentCategory: file.documentCategory,
            status: file.status,
            dashboardId: file.dashboardId,
            marketMetrics: file.marketMetrics,
            isDeck: true,
        }));

        await new Promise<any>(() => {
            Promise.all(promises).then(() => {
                reloadFileStructure();
                onClose();
            });
        });
    // eslint-disable-next-line
    }, [files, userGroup]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                {'Select latest deck'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack className={classes.categoryContent} spacing={1} alignItems="stretch" justifyContent="flex-start">
                    {files?.map((file: CompanyFile) => {
                        return <FileCategoryRow forSelection
                            file={file}
                            refetch={reloadFileStructure}
                            onFileSelect={onFileSelect}
                            onSetDeck={handleSetDeck}
                            key={'dashboard-deck-select-modal-101-' + file.id} />
                    })}
                </Stack>
            </DialogContent>
        </Dialog>
    </>);
}
export default DashboardDeckSelectModal;