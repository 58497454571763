import { useCallback, useContext } from 'react';
import { Dashboard, Workspace } from '../types/files';
import {AuthContext} from '../contexts/AuthContext';
import { BulkActionsContext } from '../contexts/BulkActionsContext';
import { addWorkspaceToDashboardFunc, deleteWorkspaceFunc, sendShareWorkspaceEmailFunc, updateWorkspaceFunc } from '../lib/helper';
import { GroupSettingsContext } from '../contexts/GroupSettingsContext';
import { DashboardsContext } from '../contexts/DashboardsContext';
import useBackwardsCompatible from './useBackwardsCompatible';

const useBulkWorkspaces = () => {
  const { user, userGroup } = useContext(AuthContext);
  const { dashboards, setDashboards } = useContext(DashboardsContext);
  const { setWorkspaces } = useContext(GroupSettingsContext);
  const { loading, bulkWorkspacesMap, setLoading, setBulkWorkspacesMap } = useContext(BulkActionsContext);
  const { dashboardWorkspaces } = useBackwardsCompatible();

  const getBulkWorkspaces = useCallback((collection: string) =>
    Array.from(bulkWorkspacesMap, ([collection, bulk]) => ({ collection, bulk })).filter(data => data.collection === collection).map(data => data.bulk).flat()
  , [bulkWorkspacesMap]);

  const clearBulk = useCallback((collection?: string) => {
    if (collection) {
      setBulkWorkspacesMap(prev => {
        prev.set(collection, []);

        return new Map(prev);
      });
    } else {
      setBulkWorkspacesMap(new Map([]));
    }
  }, [setBulkWorkspacesMap]);

  const addToBulk = useCallback((collection: string, workspace: Workspace) => {
    setBulkWorkspacesMap(prev => {
      const collections = prev.get(collection);

      if (!collections?.find(item => item.id === workspace.id))
        prev.set(collection, [...(collections || []), workspace]);

      return new Map(prev);
    });
  }, [setBulkWorkspacesMap]);

  const removeFromBulk = useCallback((collection: string, workspace: Workspace) => {
    setBulkWorkspacesMap(prev => {
      const collections = prev.get(collection);

      if (!!collections?.find(item => item.id === workspace.id))
        prev.set(collection, collections?.filter(item => item.id !== workspace.id) ?? []);

      return new Map(prev);
    });
  }, [setBulkWorkspacesMap]);

  const assignToDashboards = useCallback((collection: string, dashboardIds: string[]) => {
    return new Promise<Dashboard[] | any>((resolve, reject) => {
      const promises: Promise<Dashboard>[] = [];
      const bulkWorkspaces = getBulkWorkspaces(collection);

      setLoading(true);
      bulkWorkspaces.forEach(workspace => {
        dashboardIds.forEach(id => {
          promises.push(addWorkspaceToDashboardFunc({
            dashboardId: id,
            workspaceId: workspace.id,
            group: userGroup,
          }));
        });
      });

      Promise.all(promises).then((dashboardUpdates: Dashboard[]) => {
        resolve(dashboardUpdates);
      }).catch((error) => {
        reject(error);
      }).finally(() => setLoading(false));
    });
  // eslint-disable-next-line
  }, [userGroup, getBulkWorkspaces]);

  const shareWorkspaceEmails = useCallback((collection: string) => {
    return new Promise<Dashboard[] | any>((resolve, reject) => {
      const updatePromises: Promise<Workspace>[] = [];
      const sharePromises: Promise<string | any>[] = [];
      const bulkWorkspaces = getBulkWorkspaces(collection);

      setLoading(true);
      bulkWorkspaces.forEach(workspace => {
        if (!workspace.isPublic)
          updatePromises.push(updateWorkspaceFunc({...workspace, isPublic: true }));

        const dashboardIds = dashboards.filter(dashboard => dashboardWorkspaces(dashboard)?.includes(workspace.id))
          .map(dashboard => dashboard.id);

        sharePromises.push(sendShareWorkspaceEmailFunc({
          workspaceId: workspace.id,
          toAddress: workspace.email,
          replyToAddress: user.attributes.email,
          dashboardIds: dashboardIds,
        }));
      });

      Promise.all(updatePromises).then(async (updatedWorkspaces?: Workspace[]) => {
        const workspaceIds = updatedWorkspaces?.map(workspace => workspace.id);

        setDashboards(prev => prev.map(dashboard => ({...dashboard,
          isPublic: dashboardWorkspaces(dashboard)?.some(wsId => workspaceIds?.includes(wsId)) || dashboard.isPublic,
        })));
        setWorkspaces(prev => prev.map(ws => updatedWorkspaces?.find(updated => updated.id === ws.id) ?? ws));

        await Promise.all(sharePromises);
        resolve(true);
      }).catch((error) => {
        reject(error);
      }).finally(() => setLoading(false));
    });
  // eslint-disable-next-line
  }, [userGroup, getBulkWorkspaces]);

  const deleteWorkspaces = useCallback((collection: string) => {
    return new Promise((resolve, reject) => {
      const promises: Promise<any>[] = [];
      const collections = getBulkWorkspaces(collection);

      setLoading(true);
      collections.forEach(item => promises.push(deleteWorkspaceFunc({ id: item.id })));
      Promise.all(promises).then(() => {
        const workspaceIds = collections.map(item => item.id);

        collections.forEach(workspace => removeFromBulk(collection, workspace));
        setWorkspaces(prev => prev.filter(workspace => !workspaceIds.includes(workspace.id)));
        setLoading(false);

        resolve(true);
      }).catch(() => {
        reject([]);
      }).finally(() => setLoading(false));
    });
  }, [getBulkWorkspaces, removeFromBulk, setLoading, setWorkspaces]);

  return { loading, getBulkWorkspaces, clearBulk, addToBulk, removeFromBulk,
    assignToDashboards, shareWorkspaceEmails, deleteWorkspaces };
};

export default useBulkWorkspaces;