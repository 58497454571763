import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Collapse, Rating, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import classnames from 'classnames';
import configuredAsyncComponentLoader from '../../templates/loader';
import {DashboardContext} from '../../../contexts/DashboardContext';

const MiniDashboardQueries = configuredAsyncComponentLoader(() => import('../../organisms/dashboard-queries/MiniDashboardQueries'));

const useStyles = makeStyles((theme) => ({
    url: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'underline',
        }
    },
    textClamp: {
        color: '#666666',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '3',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const SimilarCompanyComponentAnswer: React.FC<{ answer: any }> = ({ answer }) => {
    const classes = useStyles();
    const { isPublicView } = useContext(DashboardContext);
    const [isCollapsable, setCollapsable] = useState(false);
    const [isExpanded, setExpanded] = useState(false);

    const rating = !!answer.score ? (
            answer.score >= 0.84 ? 3 :
            answer.score >= 0.82 ? 2 :
            answer.score >= 0.80 ? 1 :
            0
        ) : 0;

    useEffect(() => {
        if (answer.content?.length > 55)
            setCollapsable(true);
        else
            setExpanded(false);
    }, [answer.content]);

    return (<>
        <Collapse in={isCollapsable && isExpanded} collapsedSize={55}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" height="100%">
                <Stack direction="column" sx={{ width: '100%', p: '4px 0', cursor: 'text' }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Typography
                            component="a"
                            className={classes.url}
                            href={answer.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        > {answer.title} </Typography>
                        <Stack direction="row" spacing={1} mr={1}>
                            <Typography
                                color="rgba(102, 102, 102, 0.5)"
                                fontSize="0.8rem"
                                fontStyle="italic"
                            > Relevance Score: </Typography>
                            <Rating name="similar-companies-score"
                                defaultValue={rating}
                                max={3}
                                icon={<HorizontalRuleIcon sx={{ transform: 'scale(1.25, 1.5)'}} />}
                                emptyIcon={<HorizontalRuleIcon sx={{ transform: 'scale(1.25, 1.5)'}} />}
                                sx={{'& .MuiRating-iconFilled': { color: rating > 1 ? 'limegreen' : 'unset', }}}
                                readOnly />
                        </Stack>
                    </Stack>
                    <Box className={classnames(!isExpanded ? classes.textClamp : {})}
                        sx={{ WebkitLineClamp: !isExpanded ? '2' : 'unset' }}
                        onClick={(e)=>{
                            e.preventDefault();
                            setExpanded((prev) => !prev);
                        }}> <Typography> {answer.content} </Typography>
                    </Box>
                </Stack>
                {isCollapsable ? (
                    <Stack direction="column" alignItems="center" justifyContent={isExpanded ? 'space-between' : 'flex-start'}
                        sx={{ height: '100%', margin: '4px', }}>
                        {isPublicView ? (<Typography />) : (<MiniDashboardQueries company={answer.title} />)}
                        <Button variant="text"
                            onClick={(e) => {
                                e.preventDefault();
                                setExpanded((prev) => !prev);
                            }}
                            sx={{ marginTop: '8px', minWidth: 'unset', padding: 'unset' }}>
                            <ExpandMoreIcon sx={{ fill: '#666666', transform: `rotate(${isExpanded ? 180 : 0}deg)` }} />
                        </Button>
                    </Stack>
                ) : (
                    <Box sx={{ width: '24px', height: '100%', margin: '4px', }}>
                        {isPublicView ? (<Typography />) : (<MiniDashboardQueries company={answer.title} />)}
                    </Box>
                )}
            </Stack>
        </Collapse>
    </>);
}

export default SimilarCompanyComponentAnswer;
