import React, { useContext } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { scrollbarStyle } from '../../../shared/dashboard';
import DashboardNotesViewer from "../../organisms/dashboard-notes/DashboardNotesViewer";
import { DashboardContext } from "../../../contexts/DashboardContext";

const useStyles = makeStyles((theme) => ({
    panel: {
        transition: 'width .3s ease',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
        ...scrollbarStyle
    },
}));

const DashboardNotes: React.FC<{}> = () => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);

    if (!dashboard)
        return (<></>);

    return (<>
        <Box className={classes.panel}>
            <Box padding="12px 0" />
            <DashboardNotesViewer />
        </Box>
    </>);
}

export default DashboardNotes;