import React from 'react';
import { Box, Stack, Typography } from "@mui/material";
import grey from '@mui/material/colors/grey';
import RecentPostsAccordion from '../../atoms/RecentPostsAccordion';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import { NoInfoYet } from '../../../types/search';

const LinkedInPostsAnswer: React.FC<{ recentPosts?: AnsweredQuestion|null, }> = ({ recentPosts }) => {
    return (<>
        <Stack spacing={3.5} mt={1} width="calc(100% - 8px)">
            <Box style={{height: `calc(100% - 32px)`}}>
                <Typography variant="subtitle1" color={grey[500]} sx={{fontWeight: 'bolder', fontSize: '0.9rem', lineHeight: '1.75'}}>
                    {"Recent Posts"}
                </Typography>
                <Stack direction="column" spacing={2} mt={1} sx={{overflowX: 'hidden', overflowY: 'auto', height: '100%'}}>
                    {Array.isArray(recentPosts?.answer) && !!(recentPosts?.answer as AnsweredQuestion[]).length ? (
                        (recentPosts?.answer as AnsweredQuestion[])?.map((element: AnsweredQuestion, i) => (
                            <RecentPostsAccordion element={element} timeStamp={recentPosts?.time} key={'search-result-list-1256-' + i}/>
                    ))) : (
                        <Typography variant="subtitle1" color={grey[400]} sx={{fontSize: '0.9rem', mt: 1, mb: 1}}>
                            {NoInfoYet.answer}
                        </Typography>
                    )}
                </Stack>
            </Box>
        </Stack>
    </>);
}

export default LinkedInPostsAnswer;
