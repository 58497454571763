import { Button, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    noneOfTheseButton: {
        textTransform: 'none',
        backgroundColor: '#E0F1F1',
        borderRadius: '20px',
        padding: '10px 24px',
    },
    cancelButton: {
        textTransform: 'none',
        backgroundColor: '#EAECEF',
        borderRadius: '20px',
        padding: '8px 36px',
        color: '#AEB5BC',
    },
    saveButton: {
        textTransform: 'none',
        backgroundColor: '#048290',
        borderRadius: '20px',
        padding: '8px 36px',
        color: '#FFFFFF',
        "&:hover": {
            color: '#048290',
        }
    },
}));

const ActionButtons: React.FC<{
    onSkip?: () => void,
    onDeny: () => void,
    onConfirm: () => void,
    disableSave: boolean,
}> = ({ onSkip, onDeny, onConfirm, disableSave }) => {
    const classes = useStyles();

    return (<>
        {!!onSkip && (
            <Button className={classes.noneOfTheseButton} onClick={onSkip}>
                <Typography variant="b2-bold">None of these</Typography>
            </Button>
        )}
        <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button className={classes.cancelButton} onClick={onDeny}>
                <Typography variant="b2-bold">Cancel</Typography>
            </Button>
            <Button className={classes.saveButton} onClick={onConfirm} disabled={disableSave}>
                <Typography variant="b2-bold">Save</Typography>
            </Button>
        </Stack>
    </>);
}

export default ActionButtons;