import { Stack, Typography } from "@mui/material";
import TextQuery from "../../molecules/dashboard-queries/TextQuery";
import AddSection, { CompanyDetailsQueries, GeneralInfoQueries, KeyMetricsQueries, PeopleQueries } from "../../atoms/AddSection";
import SimilarCompaniesQuery from "../../molecules/dashboard-queries/SimilarCompaniesQuery";
import RecentNewsQuery from "../../molecules/dashboard-queries/RecentNewsQuery";
import FundingQuery from "../../molecules/dashboard-queries/FundingQuery";
import { useContext, useMemo } from "react";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";
import { DashboardQuery } from "../../../types/files";
import theme from "../../../theme";

export const Column = 'Company details';

const CompanyDetailsColumnQueries: React.FC<{}> = () => {
    const { queries } = useContext(DashboardQueriesContext);

    const customQueries: DashboardQuery[] = useMemo(() => queries.filter(query => (
        ![...CompanyDetailsQueries, ...KeyMetricsQueries, ...PeopleQueries, ...GeneralInfoQueries].includes(query.title)
        && !!query.manualOverride && query.source === 'MANUAL'
    )), [queries]);

    return (<>
        <Stack direction="column" spacing={2} alignItems="flex-start" width="35%">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                <Typography variant="b1-semibold" color={theme.colors.neutral['400']}>
                    {Column}
                </Typography>
                <AddSection column={Column} />
            </Stack>
            <TextQuery queryTitle="Description" />
            <TextQuery queryTitle="Market" />
            <FundingQuery />
            <TextQuery queryTitle="Product" />
            <TextQuery queryTitle="Go To Market" />
            <TextQuery queryTitle="Business Model" />
            <TextQuery queryTitle="Possible Risks" />
            <SimilarCompaniesQuery />
            <RecentNewsQuery />
            {customQueries.map(query => (<TextQuery queryTitle={query.title} customQuery />))}
        </Stack>
    </>);
}

export default CompanyDetailsColumnQueries;