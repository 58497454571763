import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataGrid, GridColDef, GridRowId, GridRowModes, GridRowModesModel, useGridApiRef } from '@mui/x-data-grid';
import { Alert, AlertProps, Button, IconButton, Stack, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import makeStyles from "@mui/styles/makeStyles";
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../../contexts/AuthContext";
import { createUserFunc, getAllUsersFunc, getGroupActiveSubscriptionFunc } from "../../../lib/helper";
import { defineActionColumn, defineTextColumn } from "../../molecules/admin-menus/ColumnsDefinition";
import AccountMenu from "../../molecules/admin-menus/AccountMenu";
import AddUserModal from "../../modals/admin/AddUserModal";
import { CustomerSubscription } from "../../../API";
import { User } from "../../../types/common";
import theme from "../../../theme";

export type UserRow = User & {id: string, isNew: boolean};

const useStyles = makeStyles((theme) => ({
    table: {
        border: 'none',
        maxHeight: 'calc(100vh - 300px)',
        "& .MuiDataGrid-withBorderColor": {
            borderColor: theme.colors.primary['300'],
        },
    },
    header: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: '1.1rem',
        color: theme.colors.neutral['900'],
    },
    row: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: theme.colors.primary['700'],
        "& .MuiDataGrid-cell--editing > .MuiInputBase-root":{
            fontFamily: 'Inter',
            color: theme.colors.primary['700'],
            "& > input": {
                fontSize: '1rem',
            }
        }
    },
    backButton: {
        width: 40,
        height: 40,
        color: theme.palette.primary.main,
    },
    addButton: {
        width: 150,
        height: 40,
        borderRadius: 32,
        borderWidth: 3,
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: '#048290',
        background: '#E0F1F1',
        "&:hover": {
            opacity: 0.8,
            background: '#E0F1F1',
        },
    },
    addButtonInline: {
        width: 100,
        height: 32,
        fontSize: '1rem',
    },
    cancelButton: {
        width: 100,
        height: 32,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        fontSize: '1rem',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const UsersTable: React.FC<{ selectedGroup: string|undefined }> = ({ selectedGroup }) => {
    const classes = useStyles();
    const history = useHistory();
    const apiRef = useGridApiRef();
    const { isNotissiaAdmin } = useContext(AuthContext);

    const [users, setUsers] = useState<User[]>([]);
    const [rows, setRows] = useState<UserRow[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newUserRow, setNewUserRow] = useState<UserRow|null>(null);
    const [addUserModalOpen, setAddUserModalOpen] = useState<boolean>(false);
    const [activeSubscription, setActiveSubscription] = useState<CustomerSubscription | undefined>();

    const columns: GridColDef[] = useMemo(() => [
        defineTextColumn('firstName', 'First Name', 300),
        defineTextColumn('lastName', 'Last Name', 300),
        defineTextColumn('username', 'Username', 300),
        defineTextColumn('email', 'Email', 300),
        defineActionColumn('actions', (params) => {
            if (params.row.isNew) {
                return [(
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                        <Button className={classes.cancelButton}
                            onClick={handleCancelUser}>Cancel</Button>
                        <Button variant="contained"
                            className={classnames(classes.addButton, classes.addButtonInline)}
                            onClick={handleAddUser}>Add</Button>
                    </Stack>
                )];
            }

            return [(
                <AccountMenu
                    actionParams={params}
                    loading={isLoading}
                    users={users}
                    selectedGroup={selectedGroup}
                    allowChanges={!(rowModesModel[params.id]?.mode === GridRowModes.Edit || activeSubscription?.user === params.row.email)}
                    onLoading={setIsLoading}
                    onUpdateUsers={(users) => {setUsers(users); setRows(users);}}/>
            )];
        }),
    // eslint-disable-next-line
    ], [selectedGroup, rows, rowModesModel, isLoading, activeSubscription]);

    const getUsersData = useCallback(async (group: string) => {
        const [usersFromApi, activeSub ] = await Promise.all([
            getAllUsersFunc(group),
            getGroupActiveSubscriptionFunc({ group }),
        ]);

        const users = usersFromApi.map((user: any) => ({
            id: user.username,
            username: user.username,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
        })).sort((a: { username: string }, b: { username: string }) =>
            a.username?.toLowerCase().localeCompare(b.username?.toLowerCase())
        );

        setUsers(users);
        setRows(users);
        setActiveSubscription(activeSub);
    }, []);

    const handleAddRow = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const id = uuidv4();
        const newUser = {
            id,
            username: '',
            email: '',
            group: selectedGroup!,
            firstName: '',
            lastName: '',
            isNew: true,
        };

        e.preventDefault();
        setNewUserRow(newUser);
        setRows((prev) => [...prev, newUser]);
        setRowModesModel((prev) => ({...prev,
          [id]: { mode: GridRowModes.Edit, fieldToFocus: 'username' },
        }));
    }, [selectedGroup]);

    const handleAddUser = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (rowModesModel[newUserRow!.id]?.mode === GridRowModes.Edit)
            apiRef.current.stopRowEditMode({ id: newUserRow!.id as GridRowId});
        setAddUserModalOpen(true);
    }, [apiRef, newUserRow, rowModesModel]);

    const handleCancelUser = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (rowModesModel[newUserRow!.id]?.mode === GridRowModes.Edit)
            apiRef.current.stopRowEditMode({ id: newUserRow!.id as GridRowId, ignoreModifications: true});
        setRows((prev) => prev.filter(user => user.id !== newUserRow!.id));
        setNewUserRow(null);
        apiRef.current.forceUpdate();
    }, [apiRef, newUserRow, rowModesModel]);

    const handleSaveNewUser = useCallback(async () => {
        if (!newUserRow)
            return;

        try {
            setIsLoading(true);
            apiRef.current.startRowEditMode({ id: newUserRow!.id as GridRowId});
            await createUserFunc({...newUserRow} as User);
            newUserRow.isNew = false;
            apiRef.current.stopRowEditMode({ id: newUserRow!.id as GridRowId});
            setRows((prev) => prev.map(user => user.id === newUserRow.id ? newUserRow : user));
            setUsers((prev) => [...prev, newUserRow]);
            setSnackbar({
                children: 'User created successfully.',
                severity: 'success',
            });
        } catch (e: any) {
            setSnackbar({
                children: e?.errors[0]?.message || e.message || `There was an error creating the user. Please try again.`,
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
            setAddUserModalOpen(false);
            apiRef.current.forceUpdate();
        }
    }, [apiRef, newUserRow]);

    const handleProcessRowUpdate = useCallback((newRow) => {
        if (!newUserRow!.isNew) {
            const recentUserRow = {...newUserRow};
            setNewUserRow(null);

            return recentUserRow;
        }
        setNewUserRow(newRow);

        return newRow;
    }, [newUserRow]);

    const handleProcessRowUpdateError = useCallback((error: Error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const handleRowModesModelChange = useCallback((newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    }, []);

    useEffect(() => {
        if (!!selectedGroup) {
            setIsLoading(true);
            getUsersData(selectedGroup)
                .then(() => setIsLoading(false));
        }
    // eslint-disable-next-line
    }, [selectedGroup]);

    if (!selectedGroup)
        return (<></>);

    return (<>
        <Stack direction="column">
            <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" my={1}>
                    {isNotissiaAdmin && (
                        <IconButton className={classes.backButton}
                            onClick={() => history.push('/groups')} >
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    <Typography variant="h5-bold">{selectedGroup}</Typography>
                </Stack>
                <Typography
                    fontFamily="Inter"
                    fontWeight="bold"
                    fontSize="1.5rem"
                    color={theme.colors.cyan['500']}>
                    {users.length}
                    <Typography
                        component="span"
                        fontFamily="Inter"
                        fontWeight="bold"
                        fontSize="1.2rem"
                        color={theme.colors.neutral['500']}
                        display="inline">
                        {` user${users.length > 1 ? 's': ''} in this workspace`}
                    </Typography>
                </Typography>
            </Stack>
            <Box width="100%" height="100%">
                <DataGrid
                    apiRef={apiRef}
                    className={classes.table}
                    // initialState={{
                    //     pagination: {
                    //         paginationModel: {
                    //             pageSize: 10,
                    //         },
                    //     },
                    // }}
                    loading={isLoading}
                    columns={columns}
                    rows={rows}
                    rowModesModel={rowModesModel}
                    getRowClassName={() => classes.row}
                    isCellEditable={({row}) => row.isNew}
                    editMode="row"
                    processRowUpdate={handleProcessRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    onRowModesModelChange={handleRowModesModelChange}
                    // pageSizeOptions={[10, 50, 100]}
                    hideFooter
                />
            </Box>
            <Stack direction="row" alignItems="center" justifyContent="flex-start" mt={1}>
                {(!isLoading && !newUserRow) && (
                    <Button variant="contained"
                        className={classes.addButton}
                        onClick={handleAddRow}>Add a user</Button>
                )}
            </Stack>
        </Stack>
        <AddUserModal
            isOpen={addUserModalOpen}
            isLoading={isLoading}
            group={selectedGroup}
            email={newUserRow?.email || '[no email]'}
            newCount={users.length + 1}
            onAdd={handleSaveNewUser}
            onClose={() => setAddUserModalOpen(false)} />
        <Snackbar
            open={Boolean(snackbar)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setSnackbar(null)}
            autoHideDuration={6000}>
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
    </>);
}

export default UsersTable;