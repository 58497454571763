import React, { useCallback, useContext, useMemo, useState } from "react";
import { Badge, Fab, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {ReactComponent as BulkIcon} from "../../assets/icons/bulk.svg"
import DashboardsBulkActionModal from "../modals/dashboards-overview/DashboardsBulkActionModal";
import DashboardsBulkExportPDFModal from "../modals/dashboard-details/DashboardsBulkExportPDFModal";
import classnames from "classnames";
import DashboardsBulkAddToInvestorModal from "../modals/investors/DashboardsBulkAddToInvestorModal";
import CreateEditInvestorModal from "../modals/investors/CreateEditInvestorModal";
import {DashboardsContext} from "../../contexts/DashboardsContext";
import { RefreshDataEnum } from "../../types/files";
import { updateDashboardFunc } from "../../lib/helper";
import useBulkDashboards from "../../hooks/useBulkDashboards";

const useStyles = makeStyles((theme) => ({
    badge: {
        "& .MuiBadge-badge": {
            position: 'absolute',
            top: 8,
            right: 4,
            width: 24,
            height: 24,
            padding: 6,
            borderRadius: '50%',
            background: theme.colors.orange['400'],
            color: 'white',
            fontSize: '0.85rem',
            fontWeight: 'bold',
            zIndex: 1200,
        },
    },
    bulkButton: {
        width: 40,
        height: 40,
        background: 'unset',
        boxShadow: 'unset',
        "&.Mui-disabled": {
            background: 'unset',
            boxShadow: 'unset',
        }
    },
    icon: {
        height: 24,
        width: 24,
        fill: theme.colors.neutral['300'],
    },
    activeIcon: {
        fill: theme.colors.neutral['500'],
    },
    openModalIcon: {
        fill: theme.colors.cyan['500'],
    },
}));

const BulkActionBadge: React.FC<{}> = () => {
    const classes = useStyles();
    const { setDashboards } = useContext(DashboardsContext);
    const {getBulkDashboards} = useBulkDashboards();

    const [bulkActionModalOpen, setBulkActionModalOpen] = useState<boolean>(false);
    const [exportPDFsModalOpen, setExportPDFsModalOpen] = useState<boolean>(false);
    const [addToWorkspaceModalOpen, setAddtoWorkspaceModalOpen] = useState<boolean>(false);
    const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState<boolean>(false);
    const [multiZipped, setMultiZipped] = useState<boolean>(false);

    const bulkCount = useMemo(() => getBulkDashboards('bulk-action').length, [getBulkDashboards]);

    const handleStopDashboardSharing = useCallback(async () => {
        const promises: Promise<boolean>[] = [];
        const bulkDashboards = getBulkDashboards('bulk-action');

        bulkDashboards.forEach(({dashboard}) => {
            if (dashboard.isPublic) {
                const updatedDashboard = {
                    ...dashboard,
                    isPublic: false,
                    refreshData: {
                        lastUpdatedBy: 'user',
                        shouldRefresh: true,
                        modifiedData: RefreshDataEnum.Public,
                    }
                };

                promises.push(updateDashboardFunc(updatedDashboard));
            }
        });
        Promise.all(promises).then(() => {
            const bulkDashboardIds = bulkDashboards.map(({dashboard}) => dashboard.id);

            setDashboards(prev => prev.map(dashboard =>
                bulkDashboardIds.includes(dashboard.id) && dashboard.isPublic ? {...dashboard, isPublic: false} : dashboard,
            ));
        });
    // eslint-disable-next-line
    }, [getBulkDashboards]);

    const handleBulkAction = useCallback((mode: string) => {
        switch (mode) {
            case 'add-to-workspace':
                setAddtoWorkspaceModalOpen(true);
                break;
            case 'export-pdf':
                setMultiZipped(false);
                setExportPDFsModalOpen(true);
                break;
            case 'export-pdf-zipped':
                setMultiZipped(true);
                setExportPDFsModalOpen(true);
                break;
            case 'stop-sharing':
                handleStopDashboardSharing();
                break;
            case 'close':
            default:
                break;
        }
        setBulkActionModalOpen(false);
    }, [handleStopDashboardSharing]);

    return (<>
        <Badge className={classes.badge} badgeContent={bulkCount}>
            <Fab className={classes.bulkButton}
                onClick={() => setBulkActionModalOpen(true)}
                disabled={!bulkCount}>
                <BulkIcon className={classnames(classes.icon,
                    !!bulkCount && classes.activeIcon,
                    (bulkActionModalOpen || exportPDFsModalOpen) && classes.openModalIcon,
                )} />
            </Fab>
        </Badge>
        <DashboardsBulkActionModal
            isOpen={bulkActionModalOpen}
            onClose={handleBulkAction} />
        <DashboardsBulkExportPDFModal
            isOpen={exportPDFsModalOpen}
            multiZipped={multiZipped}
            onClose={() => setExportPDFsModalOpen(false)}
        />
        {addToWorkspaceModalOpen && (
            <DashboardsBulkAddToInvestorModal
                isOpen={addToWorkspaceModalOpen}
                onClose={(createWorkspace?: boolean) => {
                    setAddtoWorkspaceModalOpen(false);
                    setCreateWorkspaceModalOpen(createWorkspace || false);
                }}
        />)}
        <CreateEditInvestorModal
            isOpen={createWorkspaceModalOpen}
            onClose={() => {
                setCreateWorkspaceModalOpen(false);
                setAddtoWorkspaceModalOpen(true);
            }}
        />
    </>);
};

export default BulkActionBadge;
