import React, { ReactNode, useRef } from 'react';
import { Button, Divider, Stack, TextField, TextFieldProps } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    answerEditContainer: {
        width: '100%',
        padding: 8,
        backgroundColor: "#fff",
        borderRadius: 10,
        border: `1px ${theme.colors.neutral['500']} solid`,
    },
    inputField: {
        "& .MuiOutlinedInput-root": {
            width: '100%',
            padding: 'unset',
            "& > input": {
                fontSize: '1rem',
                padding: 'unset',
            },
        },
    },
    cancelButton: {
        borderRadius: 40,
        borderColor: theme.colors.neutral['100'],
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    saveButton: {
        borderRadius: 40,
        backgroundColor: theme.colors.primary['600'],
        color: 'white',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        '&:hover': {
            backgroundColor: theme.colors.primary['500'],
        }
    },
}));

export const RowLineHeight = 16 * 1.5;

const AnswerEditor: React.FC<{
    currentAnswer: string,
    placeHolder?: string,
    rows?: number,
    linkOnly?: boolean,
    externalControl?: ReactNode,
    handleCancel: (e: any) => void,
    handleSave: (answer: string) => void,
}> = ({ placeHolder, currentAnswer, rows, linkOnly, externalControl,
    handleSave, handleCancel }) => {
    const classes = useStyles();
    const inputValue = useRef<TextFieldProps>(null);

    return (<>
        <Stack direction="column" className={classes.answerEditContainer}>
            <TextField variant="outlined"
                className={classnames('no-border', classes.inputField)}
                inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                style={{ width: '100%', height: '100%' }}
                defaultValue={currentAnswer}
                placeholder={placeHolder || ''}
                inputRef={inputValue}
                rows={rows ?? 5}
                multiline={!linkOnly}
            />
            <Divider sx={{ margin: '8px 2px', height: 'unset' }} />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    {externalControl}
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                    <Button variant="outlined" size="small"
                        className={classes.cancelButton}
                        onClick={handleCancel}
                    > {'Cancel'} </Button>
                    <Button variant="contained" size="small"
                        className={classes.saveButton}
                        onClick={() => handleSave(inputValue.current?.value as string)}
                    > {'Save'} </Button>
                </Stack>
            </Stack>
        </Stack>
    </>);
}

export default AnswerEditor;
