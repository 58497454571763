import { Alert, AlertProps, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback, useState } from "react";
import ConfirmDialog from "../../modals/ConfirmDialog";
import { deleteUserFunc, resendUserInviteFunc } from "../../../lib/helper";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GridRowParams } from "@mui/x-data-grid";
import { User } from "../../../types/common";
import { UserRow } from "../../organisms/admin/UsersTable";

const AccountMenu: FC<{
    actionParams: GridRowParams<any>,
    loading: boolean,
    users: User[],
    selectedGroup: string | undefined,
    allowChanges: boolean,
    onLoading: Dispatch<SetStateAction<boolean>>,
    onUpdateUsers: (users: UserRow[]) => void,
}> = ({ actionParams, loading, users, selectedGroup, allowChanges, onLoading, onUpdateUsers }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [snackbar, setSnackbar] = useState<Pick<AlertProps, 'children' | 'severity'> | null>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

    const { row } = actionParams;

    const handleDelete = useCallback(async ({ email, username }: { email: string, username: string }) => {
        try {
            onLoading(true);
            await deleteUserFunc({ email, username , group: selectedGroup! });
            onLoading(false);
            onUpdateUsers((users as any[])?.filter((row) => row.username !== username));
            setSnackbar({
                children: 'User deleted successfully.',
                severity: 'success',
            });
        } catch (e: any) {
            setSnackbar({
                children: e?.errors[0]?.message || e.message || `There was an error deleting the user. Please try again.`,
                severity: 'error',
            });
        } finally {
            onLoading(false);
        }
    // eslint-disable-next-line
    }, [users, selectedGroup]);

    const handleResend = useCallback(async ({ email, username }: { email: string, username: string }) => {
        try {
            onLoading(true);
            await resendUserInviteFunc({ email, username });
            setSnackbar({
                children: 'User invite resent successfully.',
                severity: 'success',
            });
        } catch (e: any) {
            setSnackbar({
                children: e?.errors[0]?.message || e.message || `There was an error resending the user invite. Please try again.`,
                severity: 'error',
            });
        } finally {
            onLoading(false);
        }
    // eslint-disable-next-line
    }, []);

    if (!row?.email)
        return (<></>);

    return (<>
        <IconButton onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(e.currentTarget);
        }}> <MoreVertIcon /> </IconButton>
        <Menu open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => setAnchorEl(null)}>
            {allowChanges && (
                <MenuItem disabled onClick={(e) => {
                    e.stopPropagation();
                    // TODO action
                }}>Edit</MenuItem>
            )}
            <MenuItem onClick={(e) => {
                e.stopPropagation();
                handleResend({ username: row.username, email: row.email });
                setAnchorEl(null);
            }}>Resend Invite</MenuItem>
            {allowChanges && (
                <MenuItem onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDeleteOpen(true);
                    setAnchorEl(null);
                }}>Delete</MenuItem>
            )}
        </Menu>
        <ConfirmDialog
            title="Delete confirmation"
            content="Are you sure you want to delete this user?"
            open={confirmDeleteOpen}
            loading={loading}
            confirmCallback={() => handleDelete({ username: row.username, email: row.email })}
            cancelCallback={() => setConfirmDeleteOpen(false)}
        />
        <Snackbar
            open={Boolean(snackbar)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={() => setSnackbar(null)}
            autoHideDuration={6000}>
            <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
    </>);
}

export default AccountMenu;