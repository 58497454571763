import { MENTION_REGEX } from "../shared/comments";
import { User } from "../types/common";

export const getUserDisplayName = (user: User) => {
    let displayName: string = "";

    if (user.firstName) {
        displayName += `${user.firstName} `;
    }

    if (user.lastName) {
        displayName += `${user.lastName} `;
    }

    if (user.email && !displayName) {
        const atIndex = user.email.indexOf("@");

        displayName += `${user.email.substring(0, atIndex)} `;
    }

    return displayName;
}

export const getTextHighlighted = (content: string, mentionClass: string) => {
    content = content.replace(new RegExp(MENTION_REGEX, "gm"), "");

    const mentions = content.match(/(@\w+)/g);

    mentions?.forEach(user => {
        const index = content.indexOf(user);
        if (index >= 0) {
            content = content.substring(0, index) + `<span class='${mentionClass}'>` + content.substring(index, index + user.length) + "</span>" + content.substring(index + user.length);
        }
    });

    return content;
}