import { useContext, useEffect, useState } from 'react';
import { SearchNavigationContext } from '../../../contexts/SearchNavigationContext';

import { CompanyFile } from '../../../types/files';
import { SearchType, SearchResult } from '../../../types/search';
import SearchResultsLayout from '../components/SearchResultsLayout';

interface Props {
  keywords: string[];
  searchFolder: CompanyFile | null;
  searchResults: SearchResult[];
  titleSearchResults: CompanyFile[];
  loading: boolean
  onClose: () => void;
  searchType: SearchType;
  heightToSubtract: number;
  tabId: number;
}

// Assumption: if searchFile is specified, ignore searchFolder
// if searchFile is NOT specified, use searchFolder
const SearchResultsContainer = ({
  keywords,
  searchFolder, //should this be a list of folders?
  searchResults,
  titleSearchResults,
  loading,
  onClose,
  searchType,
  heightToSubtract,
  tabId,
}: Props) => {

  const {
    contextSelectedSearchResult,
    contextSetSelectedSearchResult,
  } = useContext(SearchNavigationContext);

  const [selectedSearchResult, setSelectedSearchResult] = useState<SearchResult | CompanyFile | undefined>();

  useEffect(() => {
    setSelectedSearchResult(contextSelectedSearchResult[tabId]);
    //eslint-disable-next-line
  }, [tabId]);

  const onResultSelection = (result: SearchResult | CompanyFile) => {
    setSelectedSearchResult(result);
    contextSetSelectedSearchResult(result);
  }

  //needed to "unselect" filekey when doing second search
  useEffect(() => {
    if (keywords && keywords.length) {
      setSelectedSearchResult(undefined);
      contextSetSelectedSearchResult(undefined);
    }
    //eslint-disable-next-line
  }, [keywords])

  return (
    <SearchResultsLayout
      keywords={keywords}
      loading={loading}
      searchResults={searchResults}
      titleSearchResults={titleSearchResults}
      selectedSearchResult={selectedSearchResult}
      onClose={onClose}
      onResultSelection={onResultSelection}
      searchType={searchType}
      searchFolder={searchFolder}
      heightToSubtract={heightToSubtract}
    />
  );
};

export default SearchResultsContainer;
