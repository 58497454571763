import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import { Dashboard } from "../../../types/files";

const useStyles = makeStyles(() => ({
    copyButton: {
        minWidth: 'unset !important',
        "& > .MuiButton-startIcon": {
            margin: 'unset !important',
        },
        '&:hover': {
            background: 'inherit',
            color: '#666666',
        },
    },
    copyIcon: {
        width: '20px !important',
        height: '20px !important',
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
}));

const DashboardSelect: React.FC<{
    collection: string,
    dashboard?: Dashboard,
    hidden?: boolean,
}> = ({ collection, dashboard, hidden }) => {
    const classes = useStyles();
    const { dashboardsQueries } = useContext(DashboardsContext);
    const { getBulkDashboards, addToBulk, removeFromBulk } = useBulkDashboards();
    const [checked, setChecked] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const queries = useMemo(() =>
        dashboardsQueries.filter(query => query.dashboardId === dashboard?.id)
    , [dashboardsQueries, dashboard?.id]);

    const selected = useMemo(() =>
        !!getBulkDashboards(collection).find(exportable => exportable.dashboard.id === dashboard?.id)
    , [getBulkDashboards, collection, dashboard?.id]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            if (loaded && !!dashboard) {
                if (checked && !selected)
                    addToBulk(collection, dashboard, queries);
                else if (!checked && selected)
                    removeFromBulk(collection, dashboard);
            }

            clearTimeout(timerRef.current);
        }, 200);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [checked]);

    useEffect(() => {
        if (!loaded) {
            setChecked(selected);
            setLoaded(true);
        }
    // eslint-disable-next-line
    }, []);

    if (hidden || !dashboard)
        return (<></>);

    return (<>
        <Checkbox edge="end"
            className={classes.checkBox}
            checked={checked}
            onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                setChecked(prev => !prev);
            }}
            disableRipple
        />
    </>);
}

export default memo(DashboardSelect);