import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { SignUpLink } from "../molecules/dashboard-header/DashboardTitle";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
    campain: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
    },
    signUpButton: {
        minWidth: 180,
        width: 'fit-content',
        height: 40,
        padding: '6px 10px',
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const CampainSection: React.FC<{}> = () => {
    const classes = useStyles();

    return (<>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
            <Box maxWidth={250}>
                <Typography component="a"
                    className={classes.campain}
                    color={`${theme.palette.primary.main} !important`}
                    href="https://notissia.com"
                    target="_blank"
                    rel="noopener noreferrer">
                    {'Notissia'}
                </Typography>
                <Typography className={classes.campain} display="inline">
                    {' is an AI-powered deal flow database for investors.'}
                </Typography>
            </Box>
            <Button variant="outlined" className={classes.signUpButton}
                onClick={() => window.open(SignUpLink, '_blank', 'noopener, noreferrer')}>
                {'Sign up'}
            </Button>
        </Stack>
    </>);
}

export default CampainSection;