import React, { useEffect } from "react";
import { RenderPageProps } from "@react-pdf-viewer/core";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    highlightPage: {
        width: "100%",
        height: "100%",
        zIndex: 0,
    }
}));

const CustomPageRender: React.FC<{
    renderPageProps: RenderPageProps,
    initialPage: number | undefined
}> = ({ renderPageProps, initialPage }) => {
    const classes = useStyles();

    // Taken from https://react-pdf-viewer.dev/updates/ for version 3.1.0
    useEffect(() => {
        if (renderPageProps.canvasLayerRendered && renderPageProps.textLayerRendered) {
            renderPageProps.markRendered(renderPageProps.pageIndex);
        }
        // eslint-disable-next-line
    }, [renderPageProps.canvasLayerRendered, renderPageProps.textLayerRendered]);

    return (
        <>
            {renderPageProps.canvasLayer.children}
            {
                renderPageProps.pageIndex === initialPage &&
                <div className={classes.highlightPage}>
                </div>
            }
            {renderPageProps.textLayer.children}
        </>
    );
};

export default CustomPageRender;