import { Authenticator } from '@aws-amplify/ui-react';
import React, { createContext } from 'react';
import { getUserGroups } from '../helpers/authUser';
import { User } from '../types/auth';
import '@aws-amplify/ui-react/styles.css';
import { ReactComponent as NotissiaLogoIcon } from '../assets/icons/notissia-logo.svg';
import { Box, Divider, Typography } from '@mui/material';

const NOTISSIA_ADMIN_GROUP_NAME = 'NotissiaAdmin';

interface Context {
  user: User;
  userGroup: string;
  logout: any;
  isNotissiaAdmin: boolean;
}

export const AuthContext = createContext<Context>({
  user: {
    attributes: { email: '', sub: '', name: '' },
    signInUserSession: { accessToken: { payload: {}, jwtToken: "" }, idToken: { jwtToken: "" } },
    username: ''
  },
  userGroup: "",
  logout: () => () => { },
  isNotissiaAdmin: false,
});

interface Props {
  children: React.ReactNode;
}

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your username or work email...",
      isRequired: true,
      label: "Username or email",
    },
    password: {
      placeholder: "Enter your password...",
      isRequired: true,
      label: "Password",
    },
  },
  resetPassword: {
    username:  {
      placeholder: "Enter your username or work email...",
      isRequired: true,
      label: "Username or email",
    },
  }
};

const headerFooterComponents = {
  SignIn: {
    Header() {
      return (
        <Box pt={4} px={4}>
          <Box display="flex" justifyContent="center" pb={4}>
            <NotissiaLogoIcon style={{ width: 50, height: 50 }} />
          </Box>
          <Typography variant="h5-bold">Sign in to Notissia</Typography>
          <Box pt={3}>
            <Divider />
          </Box>
        </Box>
      )
    }
  }
};

export const Provider = ({ children }: Props) => {
  return (
    <Authenticator hideSignUp={true} formFields={formFields} components={headerFooterComponents}>
      {({ user: amplifyUser, signOut }) => {
        // @ts-ignore - The type of user here doesn't reflect the actual object, which in reality matches our User type
        const user = amplifyUser as User;
        const userGroup = getUserGroups(user)[0] || "";
        const isNotissiaAdmin = (getUserGroups(user) || []).includes(NOTISSIA_ADMIN_GROUP_NAME);

        return (
          <AuthContext.Provider value={{ user, logout: signOut, userGroup, isNotissiaAdmin }}>
            {children}
          </AuthContext.Provider>
        )
      }}
    </Authenticator>
  );
};

export default Provider;
