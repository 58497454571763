import React from "react";
import { AnswerQuestionResponseType, NoInfoYet } from "../../../types/search";
import { Link, Text, View } from '@react-pdf/renderer';
import { DashboardQuery } from "../../../types/files";
import styles from '../dashboard-export/styles';

const LinkAnswer: React.FC<{ query: DashboardQuery }> = ({ query }) => {
    const answer = !!query.answer ? JSON.parse(query.answer) : null;
    const parsed = !!answer ? answer?.answer : null;
    const type = !!answer ? answer?.type : '';
    let links: { title: string; link: string, content?: string }[] = [];

    if (type === AnswerQuestionResponseType.URL) {
        links.push({title: parsed, link: parsed});
    } else  if (type === AnswerQuestionResponseType.URL_LIST)  {
        parsed.forEach((item: { title: string; link: string, content?: string }) => links.push(item));
    }

    return (<>
        {!!links.length ? (
            links?.map((item, i) => (!!item && (
                <View style={styles.linkContent} key={'item-answer-22-' + i}>
                    <Link style={{...styles.link, fontSize: !!item?.content ? 11 : 10}} src={item.link as string} >
                        {item.title}
                    </Link>
                    {item?.content && (
                        <Text style={styles.sentence}>{item?.content}</Text>
                    )}
                </View>
        )))) : (
            <Text style={styles.sentence}>{NoInfoYet.answer}</Text>
        )}
    </>);
}

export default LinkAnswer;
