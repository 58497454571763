import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { useContext, useState, useMemo } from "react";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { Dashboard, Workspace } from "../../../types/files";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import WorkspaceShareEmailModal from "../../modals/workspaces/WorkspaceShareEmailModal";
import { Tooltip, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        borderRadius: 20,
        background: '#048290',
        paddingLeft: 16,
        paddingRight: 16,
    },
    shareIcon: {
        width: 24,
        height: 24,
        stroke: 'white',
    },
}));

const WorkspaceShareEmail: React.FC<{
    dashboard?: Dashboard,
    workspace?: Workspace,
    hidden?: boolean,
}> = ({ dashboard, workspace, hidden }) => {
    const classes = useStyles();
    const { workspace: globalWorkspace } = useContext(DashboardsContext);
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [workspace, globalWorkspace]);

    if (hidden || !localWorkspace)
        return (<></>);

    return (<>
        <Tooltip placement="top"
            title={<Typography fontSize="0.85rem">{'Share workspace via email'}</Typography>}
            componentsProps={{tooltip:{sx:{
                paddingLeft: 2, paddingRight: 2,
                backgroundColor: 'rgba(97, 97, 97)',
            }}}}>
            <LoadingButton variant="contained"
                className={classes.iconButton}
                onClick={() => setShareModalOpen(true)}>
                <ShareIcon className={classes.shareIcon} />
            </LoadingButton>
        </Tooltip>
        {shareModalOpen && (
            <WorkspaceShareEmailModal
                isOpen={shareModalOpen}
                dashboard={dashboard}
                workspace={localWorkspace}
                onClose={() => setShareModalOpen(false)}
            />
        )}
    </>);
}

export default WorkspaceShareEmail;
