import { Button, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import {AuthContext} from '../../../contexts/AuthContext';
import { getUserEmail } from '../../../helpers/authUser';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    planButton: {
        width: 280,
        height: 50,
        borderRadius: 32,
        borderWidth: 3,
        textTransform: 'none',
        padding: '8px 24px',
        fontSize: '1.25rem',
        fontWeight: 'bold',
    },
    downgradeNoticeText: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        color: theme.colors.primary[600],
    },
    starterText: {
        fontFamily: 'Poppins',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.colors.primary[600],
    },
    url: {
        color: theme.colors.cyan[400],
        textDecoration: 'none',
        fontSize: '1.1rem',
        "&:hover": {
            textDecoration: 'none !important',
        }
    },
}));

const ManageSubscription: React.FC<{
    onSelectPlan: () => void,
    selected: boolean,
    admin: string,
    daysLeft?: number,
    cancelled: boolean,
    hasPayment: boolean,
    loaded: boolean,
}> = ({ onSelectPlan, selected, admin, daysLeft, cancelled, hasPayment, loaded }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [isAdmin, setAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (user && admin)
            setAdmin(admin === getUserEmail(user).toLowerCase());
    }, [admin, user]);

    return (<>
        {loaded && selected && (
            <Stack spacing={3} alignItems="center" justifyContent="center" py={5}>
                {!!daysLeft && (cancelled ? (
                    <Typography className={classes.downgradeNoticeText}>
                        {'Your plan will downgrade to '}
                        <Typography className={classes.starterText} display="inline">Starter</Typography>
                        {` on ${moment().add(daysLeft, 'days').format('MMM D, YYYY')}.`}
                    </Typography>
                ) : (hasPayment) ? (<>
                    <Typography className={classes.downgradeNoticeText}>
                        {'Your plan will upgrade to '}
                        <Typography className={classes.starterText} display="inline">Pro</Typography>
                        {' at the end of the free trial.'}
                    </Typography>
                </>) : (<>
                    <Stack alignItems="center" justifyContent="center">
                        <Typography className={classes.downgradeNoticeText}>
                            {'Your plan will downgrade to '}
                            <Typography className={classes.starterText} display="inline">Starter</Typography>
                            {' at the end of the free trial.'}
                        </Typography>
                        {isAdmin ? (
                            <Typography className={classes.downgradeNoticeText}>
                                {'To keep using the Pro plan, go to Manage My Subscription and subscribe to Pro.'}
                            </Typography>
                        ) : (
                            <Typography className={classes.downgradeNoticeText}>
                                {'To keep using the Pro plan, contact your account administrator '}
                                (<Typography
                                    component="a"
                                    className={classes.url}
                                    href={'mailto:' + admin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    display="inline"
                                >{admin}</Typography>)
                                {` to subscribe to Pro.`}
                            </Typography>
                        )}
                    </Stack>
                </>))}
                {isAdmin && (
                    <Button variant="contained" className={classes.planButton} onClick={onSelectPlan}>
                        Manage My Subscription
                    </Button>
                )}
            </Stack>
        )}
    </>);
}

export default ManageSubscription;