import { useContext, useMemo } from 'react';
import { CompanyFile } from '../../../types/files';
import Dashboard from '@uppy/dashboard';
import Uppy, { UppyFile } from '@uppy/core'
import { DashboardModal } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import DragDrop from '@uppy/drag-drop'
// import GoogleDrive from '@uppy/google-drive';
// import Dropbox from '@uppy/dropbox';
// import Box from '@uppy/box';
// import OneDrive from '@uppy/onedrive';
import path from "path"
import { AuthContext } from '../../../contexts/AuthContext';
import Transloadit from '@uppy/transloadit'
import awsConfig from '../../../aws-exports';
import { SingleFilesFolderName } from '../../../shared/files';

const ACCEPTED_EXTENSIONS = ['.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls']//, '.DOCX.pdf']

interface Props {
  open?: boolean,
  currentFolder?: CompanyFile;
  onClose: () => void;
  onUpload: (uploadName: string, uploadType: string, uploadSource: string) => void;
}
enum UploadType {
  FolderUpload,
  FileUpload,
  RemoteUpload
}


const deriveUploadType = (file: UppyFile) => {
  if (file.source === "react:DashboardModal") {
    if (file.meta?.relativePath) {
      return UploadType.FolderUpload
    }
    else {
      return UploadType.FileUpload
    }
  }
  else {
    return UploadType.RemoteUpload
  }
}

const getAction = (files: UppyFile[], uploadType: UploadType, location: string, selectedFolder: string | undefined) => {
  switch (uploadType) {
    case UploadType.FolderUpload:
      return [{ upload: path.join("/", location, (files[0].meta.relativePath as string).split(path.sep)[0]), uploadType: "folder", uploadSource: "local" }] //root folder
    case UploadType.FileUpload:
      return files.map(file => ({ upload: getFileLocation(file, location, selectedFolder), uploadType: "file", uploadSource: "local" }))
    case UploadType.RemoteUpload:
      if (selectedFolder) {
        return [{ upload: path.dirname(getFileLocation(files[0], location, selectedFolder)), uploadType: "folder", uploadSource: files[0].source || "remote" }]
      }
      return files.map(file => ({ upload: getFileLocation(file, location, selectedFolder), uploadType: "file", uploadSource: file.source || "remote" }))
  }
}

const getFileLocation = (file: UppyFile, location: string, selectedFolder: string | undefined) => {
  const uploadType = deriveUploadType(file)
  switch (uploadType) {
    case UploadType.FolderUpload:
      return file.meta.relativePath as string
    case UploadType.FileUpload:
      return path.join("/", location || SingleFilesFolderName, file.name)
    case UploadType.RemoteUpload:
      return path.join("/", file.source || "", selectedFolder || "", file.name)
  }
}

function patchEmitter(emitter: any, callback: (selection: string[]) => void) {
  var oldEmit = emitter.emit;

  emitter.emit = function () {
    var emitArgs = arguments;
    if (emitArgs.length === 4) {
      if (emitArgs[3].plugins) {
        const selections = Object.values<{ currentSelection: { name: string }[] }>(emitArgs[3].plugins).find((val) => val && val.currentSelection && val.currentSelection.length > 0)?.currentSelection.map(v => v.name)
        const wasRecentlySelected = Object.values<{ selectedFolders: {} }>(emitArgs[3].plugins).find((val) => val && val.selectedFolders)
        if (!wasRecentlySelected) {
          callback(selections || [])
        }
      }
    }
    // need to send selections back to caller
    oldEmit.apply(emitter, arguments);
  }
}

const { environment } = awsConfig
const FileUploadModal = ({
  open,
  currentFolder,
  onUpload,
  onClose
}: Props) => {


  const location = currentFolder?.path || ""
  const { userGroup } = useContext(AuthContext);
  const uppy = useMemo(() => {
    // eslint-disable-next-line
    const refPath = "${fields.full_path_name}"
    const uppyLocal: Uppy = new Uppy({
      meta: { type: 'avatar' },
      restrictions: { allowedFileTypes: ACCEPTED_EXTENSIONS },
      autoProceed: true,
      /*onBeforeFileAdded: (currentFile, files) => {
        console.log(currentFile) //if folder information exists it will be displayed in this currentFile object
        return true
      }*/
    }).use(Dashboard, {})
      .use(DragDrop, {})
      // .use(GoogleDrive, {
      //   target: Dashboard,
      //   companionUrl: Transloadit.COMPANION,
      //   companionAllowedHosts: Transloadit.COMPANION_PATTERN,
      // }).use(Dropbox, {
      //   target: Dashboard,
      //   companionUrl: Transloadit.COMPANION,
      //   companionAllowedHosts: Transloadit.COMPANION_PATTERN,
      //   // Options
      // }).use(Box, {
      //   target: Dashboard,
      //   companionUrl: Transloadit.COMPANION,
      //   companionAllowedHosts: Transloadit.COMPANION_PATTERN,
      //   // Options
      // }).use(OneDrive, {
      //   target: Dashboard,
      //   companionUrl: Transloadit.COMPANION,
      //   companionAllowedHosts: Transloadit.COMPANION_PATTERN,
      //   // Options
      // })
      .use(Transloadit, {
        getAssemblyOptions(file) {
          return {
            params: {
              auth: {
                key: process.env.REACT_APP_TRANSLOADIT_KEY || "fakekey",
              },
              steps: {
                ":original": {
                  "robot": "/upload/handle"
                },
                "export_uploaded_file": {
                  "use": [
                    ":original"
                  ],
                  "robot": "/s3/store",
                  "credentials": environment,
                  "path": path.join("/", userGroup, refPath)
                }
              }
            },
            fields: {
              full_path_name: getFileLocation(file, location, uppyLocal.getState().selectedFolder/*file.meta.relativePath as string, location, file.source, uppyLocal.getState().selectedFolder, file.name*/)
            },
          }
        },
      })

    uppyLocal.on('upload', ({ id, fileIDs }) => {
      const [first, ...rest] = fileIDs.map(id => uppyLocal.getFile(id))
      const uploadType = deriveUploadType(first)
      Promise.all(getAction([first, ...rest], uploadType, location, uppyLocal.getState().selectedFolder).map(v => onUpload(v.upload || "", v.uploadType, v.uploadSource)))
    })
    patchEmitter(uppyLocal, folders => {
      uppyLocal.setState({ selectedFolder: folders.length > 0 ? folders[0] : undefined })
    }) //state-update  event
    return uppyLocal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userGroup])
  return (
    uppy ? <DashboardModal
      uppy={uppy}
      closeModalOnClickOutside
      open={open}
      onRequestClose={onClose}
      fileManagerSelectionType="both"
      proudlyDisplayPoweredByUppy={false}
      locale={{
        strings: {
          browseFiles: "files",
          browseFolders: "folders",
          uploadFailed: "Some files did not upload successfully. To try again. please wait for the upload to complete, and press \"retry\".",
          dropPasteImportBoth: 'Drop files here or upload from your %{browseFiles} or %{browseFolders}',
          dropPasteBoth: 'Drop files here or upload from your %{browseFiles} or %{browseFolders}',
        }
      }
      }
    />
      :
      <p>Loading</p>
  );
};

export default FileUploadModal;
