import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { FileStructureContext } from '../../../contexts/FileStructureContext';
import { CompanyFile } from '../../../types/files';
import { SearchNavigationContext } from '../../../contexts/SearchNavigationContext';
import { parseKeywords } from '../components/search-terms/SearchTermUtils';
import NewSearchBar from '../components/search-bar/NewSearchBar';

interface Props {
    setTitleSearchResults: (results: any) => void;
    setKeywordsSearchResults: (results: any) => void;
    setSearchKeywords: (results: any) => void;
    setLoading: (loading: SetStateAction<boolean>) => void;
    setShowSearchResults: (show: boolean) => void;
    searchFolder: CompanyFile | null;
    tabId: number;
    documentCategories: string[] | undefined
}

const SearchBarContainer: React.FC<Props> = ({
    setTitleSearchResults,
    setSearchKeywords,
    setLoading,
    setShowSearchResults,
    searchFolder,
    tabId,
}: Props) => {
    const {
        contextSearchKeywords,
        contextSetSearchKeywords,
        contextSelectedFolder,
        contextAdvancedSearchObject,
    } = useContext(SearchNavigationContext);
    const [keywords, setKeywords] = useState<string[][]>([]);
    const [keywordsAndOperations, setKeywordsAndOperations] = useState<string[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<CompanyFile | null>(null);
    const [searchedFolder, setSearchedFolder] = useState<CompanyFile | null>(searchFolder);
    const { fileStructure } = useContext(FileStructureContext);


    useEffect(() => {
        if (contextSelectedFolder[tabId]) {
            setSelectedFolder(contextSelectedFolder[tabId]);
        }
        setKeywordsAndOperations(contextSearchKeywords[tabId] || []);
        setKeywords(parseKeywords(contextSearchKeywords[tabId] || []));

    }, [tabId, contextSelectedFolder, contextSearchKeywords, contextAdvancedSearchObject]);

    useEffect(() => {
        setSelectedFolder(searchFolder);
    }, [searchFolder]);

    const search = () => {
        const searchKeywords = keywords;
        if (searchKeywords?.length > 0) {
            setSearchedFolder(selectedFolder);
            setShowSearchResults(true);
            setTitleSearchResults([]);

            setSearchKeywords(searchKeywords);
            let fileNames = [];
            if (searchedFolder) {
                fileNames = fileStructure.filter(file => !file.isDirectory && file.parentId === searchedFolder?.id);
            }
            else {
                fileNames = fileStructure.filter(file => !file.isDirectory);
            }

            //search through file names by the search expression
            //ex: [[revenue, balance], [income]] will search for (revenue AND balance) or (income)
            //if a file name contains the first array of strings (AND array) it returns, otherwise it goes to the next array
            const result = fileNames.filter(file => {
                for (const andKeywords of keywords) {
                    if (andKeywords.every(x => file.name.toLocaleLowerCase().includes(x.toLocaleLowerCase()))) {
                        return true;
                    }
                }

                return false;
            });
            setTitleSearchResults(result);
            setLoading(false);
        }
    }

    const clearAll = () => {
        setKeywords([]);
        setSearchKeywords([]);
        contextSetSearchKeywords([]);
        setKeywordsAndOperations([]);
        setSelectedFolder(null);
    };

    return (
        <NewSearchBar
            keywords={keywords}
            keywordsAndOperations={keywordsAndOperations}
            onKeywordsUpdated={(newKeywords, newPhrase) => {
                setKeywords(newKeywords);
                setKeywordsAndOperations(newPhrase);
                contextSetSearchKeywords(newPhrase);
            }}
            onSearch={search}
            onReset={clearAll}
        />
    );
};

export default SearchBarContainer;
