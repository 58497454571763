import { datadogRum } from '@datadog/browser-rum';
import { Datadog } from "../helpers/constants";
import awsExports from '../aws-exports';

const { environment, is_live_env: isLiveEnv } = awsExports;
const rumEnabledEnvs = ['app', 'cont-tf-mig'];

if (rumEnabledEnvs.includes(environment) && isLiveEnv) {
  datadogRum.init({
    applicationId: Datadog.rumAppId,
    clientToken: Datadog.rumClientToken,
    site: 'us3.datadoghq.com',
    service: 'notissia-web-app',
    env: environment,
    // @ts-ignore - version is set by dockerpush.yml, but isn't needed locally
    version: awsExports.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'allow'
  });
}