import { Button, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import { Dashboard, Workspace } from "../../../types/files";
import { updateWorkspaceFunc, sendShareWorkspaceEmailFunc } from "../../../lib/helper";
import {AuthContext} from "../../../contexts/AuthContext";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import {DashboardContext} from "../../../contexts/DashboardContext";
import ArrayUtils from "../../../utils/ArrayUtils";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";
import { FallbackLoading } from "../../templates/loader";
import BulkShareDeals from "../../atoms/home-bulk-share-deals";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: 'fit-content',
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        },
    },
    dialogTitle: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.3rem',
    },
    dialogContent: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        padding: '8px 0',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        width: 'auto',
        height: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainContent: {
        width: '45vw',
        height: 'max-content',
        maxHeight: '50vh',
        padding: 'unset',
        overflow: 'hidden',
    },
    scrollable: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
    },
    mailingList: {
        maxHeight: 240,
        height: '100%',
        width: '100%',
        overflowY: 'auto',
    },
    sharedIcon: {
        width: 40,
        height: 40,
        color: theme.palette.primary.main,
    },
    successText : {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    linkText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    subText: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['800'],
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    commonButton: {
        minWidth: 80,
        width: 'fit-content',
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const WorkspacesBulkShareEmailModal: React.FC<{
    isOpen: boolean,
    collectionKey: string,
    onClose: () => void,
}> = ({ isOpen, collectionKey, onClose }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useContext(AuthContext);
    const { dashboards, setDashboards } = useContext(DashboardsContext);
    const { dashboard, setDashboard } = useContext(DashboardContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const { getBulkWorkspaces, assignToDashboards } = useBulkWorkspaces();
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [localWorkspaces, setLocalWorkspaces] = useState<Workspace[]>([]);
    const [shared, setShared] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [mailables, setMailables] = useState<{workspace: Workspace, dashboards: Dashboard[]}[]>([]);

    const workspaces = useMemo(() => getBulkWorkspaces(collectionKey), [collectionKey, getBulkWorkspaces]);

    const handleShareEmails = useCallback(async () => {
        return new Promise<Dashboard[] | any>(async (resolve, reject) => {
            const updatePromises: Promise<Workspace>[] = [];
            const sharePromises: Promise<string | any>[] = [];

            setLoading(true);
            workspaces.forEach(workspace => {
                if (!workspace.isPublic)
                    updatePromises.push(updateWorkspaceFunc({...workspace, isPublic: true, }));

                const dashboardIds = !!dashboard ? [dashboard.id] :
                    dashboards.filter(dashboard => dashboardWorkspaces(dashboard)?.includes(workspace.id))
                        .map(dashboard => dashboard.id);

                sharePromises.push(sendShareWorkspaceEmailFunc({
                    workspaceId: workspace.id,
                    toAddress: localWorkspaces.find(lWs => lWs.id === workspace.id)?.email ?? workspace.email,
                    replyToAddress: user.attributes.email,
                    dashboardIds: dashboardIds,
                }));
            });

            if (!!dashboard) {
                const dashboardsData: Dashboard[] = await assignToDashboards(collectionKey, [dashboard!.id]) ?? [];

                if (!!dashboardsData.length) {
                    // force update dashboards list
                    const updatedDashboards = [...dashboards].map(current => {
                        const updates = dashboardsData.filter(updated => updated.id === current.id);

                        if (!!updates.length) {
                            const dashboardUpdate = ArrayUtils.sortByDescending(updates, 'createdAt')[0];

                            if (dashboard!.id === current.id)
                                setDashboard(dashboardUpdate);

                            return dashboardUpdate;
                        }

                        return current;
                    });

                    setDashboards(ArrayUtils.sortByDescending(updatedDashboards, 'createdAt'));
                }
            }

            Promise.all(updatePromises).then(async (updatedWorkspaces?: Workspace[]) => {
                const workspaceIds = updatedWorkspaces?.map(workspace => workspace.id);

                setDashboards(prev => prev.map(dashboard => ({...dashboard,
                    isPublic: dashboardWorkspaces(dashboard)?.some(wsId => workspaceIds?.includes(wsId)) || dashboard.isPublic,
                })));
                setWorkspaces(prev => prev.map(ws => updatedWorkspaces?.find(updated => updated.id === ws.id) ?? ws));
                await Promise.all(sharePromises);
                enqueueSnackbar('Workspaces updated successfully', {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                    autoHideDuration: 4000,
                });

                resolve(true);
            }).catch((error) => {
                reject(error);
            }).finally(() => setLoading(false));
        });
    // eslint-disable-next-line
    }, [workspaces, dashboard, dashboards, localWorkspaces, user]);

    const handleShare = useCallback(async () => {
        handleShareEmails().then(() => setShared(true));
    // eslint-disable-next-line
    }, [handleShareEmails]);

    useEffect(() => {
        if (localWorkspaces.length !== workspaces.length)
            setLocalWorkspaces(structuredClone(workspaces));
    // eslint-disable-next-line
    }, [workspaces]);

    useEffect(() => {
        const collections = getBulkWorkspaces(collectionKey).map(workspace => ({ workspace,
            dashboards: !!dashboard ? [dashboard] : dashboards.filter(dashboard => dashboardWorkspaces(dashboard).includes(workspace.id)),
        }));

        setMailables(collections);
    // eslint-disable-next-line
    }, [collectionKey, dashboard, dashboards, getBulkWorkspaces]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            {shared ? (<>
                <DialogContent className={classes.dialogContent}>
                    <Stack spacing={2} alignItems="center" justifyContent="center" width="100%" py={3}>
                        <CheckCircleIcon className={classes.sharedIcon} />
                        <Typography className={classes.successText}>
                            {'Workspace successfully shared with:'}
                        </Typography>
                        <Stack className={classes.mailingList} alignItems="center" justifyContent="flex-start">
                            {localWorkspaces.map(workspace => (
                                <Typography className={classes.linkText}
                                    key={'workspaces-bulk-share-email-modal-123-' + workspace.id}>
                                    {workspace.email}
                                </Typography>
                            ))}
                        </Stack>
                    </Stack>
                </DialogContent>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogActions className={classes.dialogActions}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
                        <Button variant="contained"
                            className={classes.commonButton}
                            onClick={onClose}> {'Close'} </Button>
                    </Stack>
                </DialogActions>
            </>) : (<>
                <DialogTitle className={classes.dialogTitle}>
                    {`Share Deals`}
                </DialogTitle>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogContent className={classes.dialogContent}>
                    <Stack spacing={2} alignItems="flex-start" justifyContent="center">
                        <Typography className={classes.mainText}>
                            {`You are about to share the following deals with these investors:`}
                        </Typography>
                        <Typography className={classes.subText}>
                            {'The recepient(s) will receive an email where they will be able to access their shared workspace'}<br/>
                            {'using their email, edit their investment preferences, and view deals you share.'}<br/>
                        </Typography>
                        <Stack direction="column" className={classes.mainContent} spacing={1} alignItems="flex-start" justifyContent="stretch">
                            {!mailables.length ? (<FallbackLoading />) : (
                                <Stack direction="column" className={classes.scrollable}
                                    spacing={1} alignItems="flex-start" justifyContent="stretch">
                                    {mailables.map(mailable =>
                                        <BulkShareDeals.AssignCards
                                            workspace={mailable.workspace}
                                            dashboards={mailable.dashboards}
                                            onWorkspace={(workspace) => {
                                                setLocalWorkspaces(prev => prev.map(ws => ws.id === workspace.id ? workspace : ws));
                                                setMailables(prev => prev.map(mailable => (
                                                    (mailable.workspace.id === workspace!.id)
                                                        ? ({...mailable, workspace})! : mailable))
                                                );
                                            }} />
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </DialogContent>
                <Divider sx={{ margin: '8px 0'}} />
                <DialogActions className={classes.dialogActions}>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                        <Button className={classes.cancelButton}
                            onClick={onClose}> {'Cancel'} </Button>
                        <LoadingButton variant="contained"
                            className={classes.commonButton}
                            loading={loading}
                            disabled={localWorkspaces.some(workspace => !workspace?.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))}
                            onClick={handleShare}>
                                {'Send'}
                        </LoadingButton>
                    </Stack>
                </DialogActions>
            </>)}
        </Dialog>
    </>);
}
export default WorkspacesBulkShareEmailModal;