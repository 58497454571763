import makeStyles from "@mui/styles/makeStyles";
import { ExtendedComment } from "../../types/comments";
import { Avatar, Typography } from "@mui/material";
import { CSSProperties } from "react";

const avatarStyle: CSSProperties = {
    border: '2px solid white',
    fontSize: 12,
    fontWeight: 600,
    height: 32,
    width: 32
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
        marginTop: 8,
        marginLeft: 16
    },
    subcontainer: {
        display: 'flex'
    }
}));

const renderAvatar = ( comment?: ExtendedComment ) => (
    comment && (<div style={{ width: 20 }}>
        <Avatar style={avatarStyle}>{ comment.email.slice(0, 2).toUpperCase() }</Avatar>
    </div>)
)

const CommentAvatars: React.FC<{ comments: ExtendedComment[]}> = ({ comments }) => {
    const classes = useStyles();
    const { length } = comments;

    return length > 0 ? (
        <div className={ classes.container }>
            <div className={ classes.subcontainer }>
                {renderAvatar(comments[0])}
                {renderAvatar(comments[1])}
                {length > 2 && <Avatar style={avatarStyle}>{`+${length - 2}`}</Avatar>}
            </div>
            <Typography>{`${comments.length} comments`}</Typography>
        </div>
    ) : <div />
}

export default CommentAvatars;