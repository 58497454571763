import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, InputAdornment, Popper, PopperPlacementType, TextField, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import { Workspace } from "../../../types/files";
import { createWorkspaceFunc } from "../../../lib/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { ChromeExtensionLink } from "../../../shared/dashboard";
import { useDebouncedCallback } from "use-debounce";
import ArrayUtils from "../../../utils/ArrayUtils";
import {ReactComponent as InvestorsIcon} from "../../../assets/icons/investors.svg";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    optionPaper: {
        background: 'white',
        border: `1px solid ${theme.colors.neutral['100']}`,
        borderRadius: 12,
        boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
    },
    optionContainer: {
        padding: '2px 12px',
        margin: '4px 8px 4px 16px',
        border: `1px solid ${theme.colors.neutral['200']}`,
        borderRadius: 32,
        transition: '0.5s ease-in-out',
        "&:hover": {
            backgroundColor: '#EFF6FF',
            boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        },
        cursor: 'pointer',
    },
    optionItem: {
        fontSize: '0.9rem !important',
        fontWeight: '600 !important',
        color: `${theme.colors.neutral['800']} !important`,
    },
    inputField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            height: 45,
            padding: '0 8px !important',
            borderRadius: 32,
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    disabledInputField: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderWidth: '1px !important',
                borderColor: 'rgba(0, 0, 0, 0.38) !important',
            },
        },
    },
    smallInputField: {
        "& .MuiOutlinedInput-root": {
            height: 32,
            borderRadius: 16,
        },
    },
    mediumInputField: {
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 24,
        },
    },
    createSaveButton: {
        height: 32,
        width: 40,
        borderRadius: '16px !important',
        borderWidth: 3,
        fontSize: '14px !important',
        textTransform: 'none !important' as any,
        fontWeight: 'bold !important',
        "&:hover": {
            borderWidth: 3,
            fontWeight: 'bold !important',
        },
    },
    success: {
        background: theme.colors.success[600],
        "&:hover, &:disabled": {
            background: `${theme.colors.success[600]} !important`,
        },
        "& > svg": {
            fill: 'white !important',
        }
    },
    failed: {
        background: theme.colors.error[600],
        "&:hover, &:disabled": {
            background: `${theme.colors.error[600]} !important`,
        },
        "& > svg": {
            fill: 'white !important',
        }
    },
    smallButton: {
        height: 24,
    },
	invalidBlock: {
		textAlign: 'center',
		"&::before": {
			position: 'absolute',
			height: 0,
			width: 0,
			top: '100%',
			left: '50%',
			borderRight: '12px solid transparent',
			borderBottom: '12px solid gray',
			borderLeft: '12px solid transparent',
			transform: 'translateY(-76px)',
			content: '""',
		}
	},
	errorBlock: {
		textAlign: 'center',
		"&::before": {
			position: 'absolute',
			height: 0,
			width: 0,
			top: '100%',
			left: '50%',
			borderRight: '12px solid transparent',
			borderBottom: '12px solid red',
			borderLeft: '12px solid transparent',
			transform: 'translateY(-68px)',
			content: '""',
		}
	},
    option: {
        fontFamily: 'Lato',
        fontSize: '1rem !important',
        fontStyle: 'italic',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
}));

const InvalidNameNotification: React.FC<{}> = () => {
	const classes = useStyles();

	return (<>
		<Box className={classes.invalidBlock}>
			<Typography component="span" fontSize="0.95rem" fontFamily="Inter">
				{'Please enter a investor name.'}<br/>
				{'For DocSends, use our Chrome Extension '}
				<Typography display="inline"
					onClick={() => window.open(ChromeExtensionLink, '_blank', 'noopener, noreferrer')}
					sx={{
						cursor: 'pointer',
						color: '#fff',
						fontSize: '0.95rem',
						fontFamily: 'Inter',
						textDecoration: 'underline',
					}}>{'here'}
				</Typography>{'.'}
			</Typography>
		</Box>
	</>);
}

const ErrorNotification: React.FC<{ message: string }> = ({message}) => {
	const classes = useStyles();

	return (<>
		<Box className={classes.errorBlock}>
			<Typography component="span" fontSize="0.95rem" fontFamily="Inter">
				{message}
			</Typography>
		</Box>
	</>);
}

const WorkspacesSearchOrCreate: React.FC<{
    search?: boolean,
    create?: boolean,
    save?: boolean,
    execute?: string,
    autoComplete?: boolean,
    smallFit?: boolean,
    mediumFit?: boolean,
    disabled?: boolean,
    placement?: string,
    withOptionLabel?: boolean,
    onSearch?: (searchValue: string, status: string) => void,
    onPreExecute?: () => Promise<boolean>,
    onCreate?: (workspace: Workspace) => void,
    onSave?: (workspace: Workspace) => void,
    onElseExecute?: () => void,
}> = ({ search, create, save, execute, autoComplete, smallFit, mediumFit, disabled, placement, withOptionLabel,
    onSearch, onPreExecute, onCreate, onSave, onElseExecute }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { workspaces, setWorkspaces } = useContext(GroupSettingsContext);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
	const [invalid, setInvalid] = useState<boolean>(false);
	const [mode, setMode] = useState<string>('');
	const [createMode, setCreateMode] = useState<boolean>(false);
	const [saveMode, setSaveMode] = useState<boolean>(false);
    const [message, setMessage ] = useState<string|null>(null);
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);
    const [workspaceName, setWorkspaceName] = useState<string>('');
    const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace|null>(null);

    const existing = useMemo(() =>
        !!workspaces.find(workspace => workspace.name.trim().toLowerCase() === workspaceName.trim().toLowerCase())
    ,[workspaceName, workspaces]);

    const getPlaceHolder = useCallback(() => {
        let placeholder = 'Enter keywords...';

        if (search) {
            if (create) {
                placeholder = 'Search or add investor...';
            } else
                placeholder = 'Search investor...';
        } else if (create) {
            placeholder = 'Investor name...';
        }

        return placeholder;
    }, [create, search]);

    const debouncedName = useDebouncedCallback((value) => {
        const inputElement = document.createElement('input');

        inputElement.type = 'url';
        inputElement.value = value;

		const invalid = inputElement.value.includes(':/');
        setWorkspaceName(value);
        setInvalid(invalid);
        setSuccess(false);
        setFailed(false);
    }, 500, { maxWait: 2000 });

    const createNewWorkspace = useCallback(() => {
        setLoading(true);

        const newWorkspace: Workspace = {
            id: uuidv4(),
            name: workspaceName,
            email: '',
            website: '',
            isPublic: false,
            investmentThesis: {
                focusArea: undefined,
                stage: undefined,
                geography: undefined,
                additionalDetails: undefined
            },
        };

        createWorkspaceFunc(newWorkspace).then((workspaceData?: Workspace) => {
            if (!!workspaceData) {
                setSelectedWorkspace(workspaceData);
                setWorkspaces(prev => [...prev, workspaceData]);
                setMode('create');
                setSuccess(true);
            } else {
                enqueueSnackbar(`Investor already exists.`, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right"
                    },
                    autoHideDuration: 4000
                });
                setFailed(true);
            }
        }).finally(() => {
            setLoading(false);
            setIsNotificationVisible(true);
        });
        // eslint-disable-next-line
    }, [userGroup, workspaceName]);

    const handleCreate = useCallback(async () => {
        if (workspaceName) {
            const inputElement = document.createElement('input');

            inputElement.type = 'url';
            inputElement.value = workspaceName.trim();
            if (!inputElement.checkValidity()) {
                const proceed = await onPreExecute?.();

                if (proceed ?? true) {
                    setInvalid(false);
                    createNewWorkspace();
                } else {
                    onElseExecute?.();
                }
            } else {
                setInvalid(true);
            }
        }
    // eslint-disable-next-line
    }, [workspaceName, onPreExecute, onElseExecute]);

    const handleSave = useCallback(async () => {
        if (workspaceName) {
            const inputElement = document.createElement('input');

            inputElement.type = 'url';
            inputElement.value = workspaceName.trim();
            if (!inputElement.checkValidity()) {
                const lookUpWorkspace = workspaces.find(workspace => workspace.name.trim().toLowerCase() === workspaceName.trim().toLowerCase());
                const proceed = await onPreExecute?.();

                if (proceed ?? true) {
                    if (!!lookUpWorkspace) {
                        setMode('save');
                        setSuccess(true);
                        setSelectedWorkspace(lookUpWorkspace);
                        setInvalid(false);
                    } else {
                        setFailed(true);
                        setInvalid(true);
                    }
                    setIsNotificationVisible(true);
                } else {
                    onElseExecute?.();
                }
            } else {
                setFailed(true);
                setInvalid(true);
                setIsNotificationVisible(true);
            }
        }
    }, [workspaceName, workspaces, onPreExecute, onElseExecute]);

    const handleReset = useCallback(() => {
        setLoading(false);
        setSuccess(false);
        setFailed(false);
        setMode('');
        setCreateMode(false);
        setSaveMode(false);
        setMessage('');
        setWorkspaceName('');
        setSelectedWorkspace(null);
    }, []);

    useEffect(() => {
        if (!!workspaceName)
            setCreateMode(!!create && !existing);
        else
            setCreateMode(false);
    }, [create, workspaceName, existing]);

    useEffect(() => {
        if (save && !!workspaceName)
            setSaveMode(existing);
        else
            setSaveMode(false);
    }, [workspaceName, save, existing]);

    useEffect(() => {
        onSearch?.(workspaceName,
			  invalid ? 'invalid'
			: failed ? 'failed'
			: success ? 'success'
			: createMode ? 'create'
			: saveMode ? 'save'
            : existing ? 'existing'
			: 'not-found');
    }, [workspaceName, invalid, failed, success, createMode, saveMode, existing, onSearch]);

    useEffect(() => {
		if (execute !== undefined) {
            if (createMode && ['mode', 'create'].includes(execute))
                handleCreate();
            else if (saveMode && ['mode', 'save'].includes(execute))
                handleSave();
            else if (execute === 'reset')
                handleReset();
		}
    // eslint-disable-next-line
    }, [createMode, saveMode, execute]);

    useEffect(() => {
      if (isNotificationVisible) {
        const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId);
            setIsNotificationVisible(false);
            if (!!selectedWorkspace) {
                if (mode === 'save')
                    onSave?.(selectedWorkspace);
                else if (mode === 'create')
                    onCreate?.(selectedWorkspace);
            }
            handleReset();
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        }
      }
      // eslint-disable-next-line
    }, [isNotificationVisible, selectedWorkspace]);

    return (<>
        <Tooltip open={invalid || !!message} placement="bottom" title={
                invalid ? (<InvalidNameNotification />) :
                (!!message) && (<ErrorNotification message={message} />)
            }
            componentsProps={{tooltip:{sx:{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 4,
                background: (!!message) ? 'red' : 'gray',
                maxWidth: 400,
                width: 400,
                maxHeight: 64,
                height: 'fit-content',
                padding: 2,
            }}}}>
                <Autocomplete
                    disableClearable
                    openOnFocus
                    autoHighlight
                    autoComplete
                    includeInputInList
                    disablePortal
                    freeSolo
                    forcePopupIcon={false}
                    open={!autoComplete ? false : undefined}
                    disabled={success || disabled}
                    value={workspaceName}
                    onChange={(_, option: string) => debouncedName(option)}
                    options={ArrayUtils.sortByAscending(workspaces, 'name').map((workspace: Workspace) => workspace.name!)}
                    filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt?.toLowerCase().includes(inputValue.toLowerCase()) : !!opt)}
                    getOptionLabel={(option: string) => option}
                    PopperComponent={(props) => (<Popper {...props} disablePortal placement={placement as PopperPlacementType}/>)}
                    PaperComponent={(props) => (<Box {...props} className={classes.optionPaper} />)}
                    ListboxProps={{style: { maxHeight: 165, padding: 8, }}}
                    renderOption={(props: any, option: string) => (
                        <Box {...props} className={classes.optionContainer}>
                            <Typography className={classes.optionItem}>{option}</Typography>
                        </Box>
                    )}
                    sx={{ width: '100%', }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            className={classnames(
                                classes.inputField,
                                disabled && classes.disabledInputField,
                                smallFit && classes.smallInputField,
                                mediumFit && classes.mediumInputField,
                            )}
                            placeholder={getPlaceHolder()}
                            disabled={loading || success || disabled}
                            onChange={(e) => debouncedName(e.target.value)}
                            sx={{ width: '100%' }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                                        {(createMode || !search) ? (<AddRoundedIcon />) : search && (
                                            <InvestorsIcon height={24} width={24} fill={theme.palette.primary.main} />
                                        )}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end" >
                                        {(execute === undefined) && (<>
                                            {(createMode || saveMode || success || failed) && (
                                                <LoadingButton
                                                    variant="contained"
                                                    className={classnames(
                                                        classes.createSaveButton,
                                                        smallFit && classes.smallButton,
                                                        success && classes.success,
                                                        failed && classes.failed,
                                                    )}
                                                    loading={loading}
                                                    disabled={!workspaceName.trim() || success || failed || disabled}
                                                    onClick={createMode ? handleCreate : (saveMode ? handleSave : undefined)}>
                                                    {success ? (
                                                        <CheckCircleOutlineIcon/>
                                                    ) : (failed) ? (
                                                        <ErrorOutlineIcon />
                                                    ) : (
                                                        <>{createMode ? 'Add' : (saveMode ? 'Save' : 'Search') }</>
                                                    )}
                                                </LoadingButton>
                                            )}
                                        </>)}
                                    </InputAdornment>
                        )}}/>
                    )}
                />
        </Tooltip>
    </>);
}

export default WorkspacesSearchOrCreate;