import React from 'react';
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AnswerQuestionResponseUrlList, ArticleCategoryDisplay, NoInfoYet } from '../../../types/search';
import moment from 'moment';
import { getActivityFeedEventIcon } from '../../atoms/activityFeed';
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';

const useStyles = makeStyles((theme) => ({
    urlContainer: {
        margin: '8px 0',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    urlIcon: {
        width: '24px',
        height: '24px',
        borderRadius: '8px',
    },
    url: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'underline',
        }
    },
}));

const RecentNewsAnswer: React.FC<{ answeredQuestion: AnsweredQuestion }> = ({ answeredQuestion }) => {
    const classes = useStyles();

    return (<>
        <Stack direction="column" maxHeight={320} height='auto' overflow="auto">
            {Array.isArray(answeredQuestion?.answer) && !!(answeredQuestion?.answer as AnsweredQuestion[])?.length ?
                (answeredQuestion as AnswerQuestionResponseUrlList).answer.sort((a, b) => a.date ? (b.date ? 0 : -1) : (b.date ? 1 : 0))
                .map((curr, i) => (
                    <Stack className={classes.urlContainer} key={'search-result-list-1046-' + i}>
                        <Stack direction="row" alignItems="center">
                            <Box mr={1} display="flex">{getActivityFeedEventIcon(curr?.category, classes.urlIcon)}</Box>
                            <Box>
                                <Typography
                                    component="span"
                                    color="#666666"
                                    fontWeight="bold"
                                    whiteSpace="nowrap"
                                    mr={1}
                                >
                                    {ArticleCategoryDisplay[curr.category as keyof typeof ArticleCategoryDisplay]}:
                                </Typography>
                                <Typography
                                    component="a"
                                    className={classes.url}
                                    href={curr.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {curr.title}
                                </Typography>
                                {(curr?.date && moment(curr?.date).isValid()) &&
                                    <Typography component="span"> - {moment(curr?.date).fromNow()}</Typography>}
                            </Box>
                        </Stack>
                    </Stack>
            )) : (
                <Stack className={classes.urlContainer}>
                    <Stack direction="column" height={124} overflow="hidden">
                        <Typography>{NoInfoYet.answer}</Typography>
                    </Stack>
                </Stack>
            )}
        </Stack>
    </>);
}

export default RecentNewsAnswer;
