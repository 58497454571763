import React, { useContext, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as FileUploadIcon } from "../../../assets/icons/upload.svg";
import { LoadingButton } from "@mui/lab";
import { Tooltip, Typography } from "@mui/material";
import {DashboardContext} from "../../../contexts/DashboardContext";
import FileUploaderModal from "../../modals/dashboard-details/FileUploaderModal";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        marginBottom: 4,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 20,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        transition: 'ease-in-out 300ms',
    },
    uploadIcon: {
        width: 24,
        height: 24,
        fill: theme.palette.primary.main,
    },
}));

export const DEF_VALID_FILE = { maxFileSize: 100 * 1024 * 1024, allowedFileTypes: ['application/pdf'], };

const DashboardFileUploader: React.FC<{ hidden ?: boolean }> = ({hidden }) => {
    const classes = useStyles();
    const { dashboard } = useContext(DashboardContext);
    const [fileUploadModalOpen, setFileUploadModalOpen] = useState<boolean>(false);

    if (hidden || !dashboard)
        return (<></>);

    return (<>
        <Tooltip
            placement="top"
            title={<Typography fontSize="0.85rem">{'Upload file(s)'}</Typography>}
            componentsProps={{tooltip:{sx:{
                paddingLeft: 2, paddingRight: 2,
                backgroundColor: 'rgba(97, 97, 97)',
            }}}}>
            <LoadingButton
                variant="outlined"
                className={classes.iconButton}
                onClick={() => setFileUploadModalOpen(true)}
            > <FileUploadIcon className={classes.uploadIcon} /> </LoadingButton>
        </Tooltip>
        <FileUploaderModal
            open={fileUploadModalOpen}
            validFile={DEF_VALID_FILE}
            validPath={!!dashboard ? [dashboard.id, dashboard.title].join("/") : undefined}
            onClose={() => setFileUploadModalOpen(false)}
        />
    </>);
}

export default DashboardFileUploader;