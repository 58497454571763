import React, { useContext, useEffect, useMemo } from "react";
import { DashboardQueriesContext, AnsweredQuestion } from "../../../contexts/DashboardQueriesContext";
import SimilarCompaniesAnswer from "../dashboard-query-answer/SimilarCompaniesAnswer";
import CustomQueryContainer from "../dashboard-query-container/CustomQueryContainer";
import DifferentiatingFactorAnswer from "../dashboard-query-answer/DifferentiatingFactorAnswer";
import { Info } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import { DashboardContext } from "../../../contexts/DashboardContext";

const InfoToolTip: React.FC<{}> = () => {
    const { dashboard } = useContext(DashboardContext);

    return (<>
        <Tooltip placement="top-start" title={<Typography component="span" fontSize="0.8rem">
                This is an AI-generated list of <br />
                companies that provide similar or <br />
                adjacent products to similar or <br />
                adjacent markets as {dashboard!.title}. <br /><br />
                (This list will drastically improve <br />
                as we add more companies)
            </Typography>}
            componentsProps={{tooltip:{sx:{
                display: 'flex', alignItems: 'center', borderRadius: 'unset', transform: 'translate(-90px) !important',
                backgroundColor: 'rgba(97, 97, 97)', width: '220px', height: '190px',  padding: 2,
            }}}}>
                <Info sx={{ fill: 'lightgray', marginRight: '8px', }} />
        </Tooltip>
    </>);
}

const SimilarCompaniesQuery: React.FC<{}> = () => {
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { queryAnswer: similarCompaniesAnswer, removeQuery } = useDashboardQuery('Similar Companies 2.0');
    const { queryAnswer: differentiatorAnswer } = useDashboardQuery('Differentiating Factors');

    const isVisibleByDefault = useMemo(() =>
        defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Similar Companies 2.0')?.visibleByDefault ?? true
    , [defaultQueryTemplateValues]);

    const isPresent = useMemo(() => 'answer' in similarCompaniesAnswer && !!similarCompaniesAnswer.answer, [similarCompaniesAnswer]);
    const isDeleted = useMemo(() => 'deletedAt' in similarCompaniesAnswer && !!similarCompaniesAnswer.deletedAt, [similarCompaniesAnswer]);
    const isHidden = useMemo(() => isDeleted || (!isVisibleByDefault && !isPresent), [isDeleted, isPresent, isVisibleByDefault]);

    useEffect(() => {
        if (!isDeleted && (!isVisibleByDefault && isPresent) && !similarCompaniesAnswer.manualOverride)
            removeQuery(similarCompaniesAnswer);
    // eslint-disable-next-line
    }, [isDeleted, isPresent, isVisibleByDefault, similarCompaniesAnswer]);

    if (isHidden)
        return (<></>);

    return (<>
        <CustomQueryContainer queryAnswer={similarCompaniesAnswer} extraTooltip={<InfoToolTip />}>
            <SimilarCompaniesAnswer answeredQuestion={similarCompaniesAnswer.answeredQuestion} />
            <DifferentiatingFactorAnswer answeredQuestion={differentiatorAnswer?.answeredQuestion as AnsweredQuestion} />
        </CustomQueryContainer>
    </>);
}

export default SimilarCompaniesQuery;
