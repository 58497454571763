export const parseKeywords = (newKeywordsAndOperations: string[]) => {
    let ands: string[] = []
    let andIndex: { [key: number]: boolean } = {}
    let numOrs = 0
    newKeywordsAndOperations?.forEach((item, index, arr) => {
        if (item === "[AND]") {
            if (numOrs > 0) {
                andIndex[index + 1] = true
                ands.push(arr[index + 1]) //assumption is that all "ors" come next to each other
            }
            else if (index > 0) {
                andIndex[index - 1] = true
                ands.push(arr[index - 1]) //assumption is that all "ors" come next to each other
            }
        }
        else if (item === "[OR]") {
            numOrs++
        }
    })
    let ors: string[] = []
    newKeywordsAndOperations?.forEach((item, index, arr) => {
        if (item !== "[AND]" && item !== "[OR]" && !andIndex[index]) {
            ors.push(item)
        }
    })

    return ors.map(or => [or, ...ands])
}

export const parseKeywordsAndOperations = (searchPhrase: string[]) => {
    return searchPhrase.map((e: string) => {
        if (e.includes("[") && e.includes("]")) {
            return e.replace("[", "").replace("]", "");
        }
        return e;
    }).join(" ");
}