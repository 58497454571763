import React from "react";
import { Divider, Typography, Stack, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const useStyles = makeStyles((theme) => ({
    urlIcon: {
        width: 36,
        height: 36,
        color: theme.palette.primary.light,
    },
}));

const DashboardCreationElement: React.FC<{item: any}> = ({item}) => {
    const classes = useStyles();

    if (!!item.created) {
        return (<>
            <Stack direction="column" spacing={2} alignItems="center">
                <Box display="flex">
                    <AddCircleIcon className={classes.urlIcon} />
                </Box>
                <Stack alignItems="center">
                    <Typography fontFamily="Inter" fontWeight="bold" fontSize="1rem" textAlign="center" color="#6e6e6e">
                        {'Added to'}<br/>
                        {'Notissia'}
                    </Typography>
                </Stack>
                <Divider orientation="vertical" sx={{ height: '80px', borderColor: 'lightgrey' }} />
            </Stack>
        </>);
    }

    return (<></>);
}

export default DashboardCreationElement;