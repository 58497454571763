import React, { ReactNode, useCallback, useContext, useState } from "react";
import { Box, Dialog, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import { updateInvestmentThesisFunc } from "../../../lib/helper";
import Preferences from "../../molecules/workspaces/Preferences";
import { InvestmentThesis as InvestmentThesisType, Workspace } from "../../../types/files";
import classNames from "classnames";
import {AuthContext} from "../../../contexts/AuthContext";
import useRoute from "../../../hooks/useRoute";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiPaper-root": {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: 'fit-content',
            height: 'fit-content',
            padding: '16px 24px 16px 24px',
            background: 'white',
            borderRadius: 16,
            overflow: 'hidden',
        },
    },
    wrapper: {
        width: 'auto',
        height: 'fit-content',
        paddingLeft: 6,
        borderRadius: 16,
        border: `1px solid transparent`,
        "&:hover": {
            border: `1px solid ${theme.colors.cyan['500']}`,
        },
    },
}));

const PreferencesModal: React.FC<{
    workspace: Workspace,
    children: ReactNode,
    forSharing?: boolean,
}> = ({ workspace, children, forSharing }) => {
    const classes = useStyles();
    const { userGroup } = useContext(AuthContext);
    const { setWorkspaces } = useContext(GroupSettingsContext);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { getURLFrom } = useRoute();

    const handleSave = useCallback((investmentThesis: InvestmentThesisType) => {
        return new Promise<boolean>((resolve) => {
            updateInvestmentThesisFunc({
                id: workspace.id,
                group: getURLFrom()?.originGroup || userGroup,
                investmentThesis: investmentThesis,
            }).then((updatedWorkspace?: Workspace) => {
                setWorkspaces(prev => prev.map(ws =>
                    ws.id === workspace.id ? {...updatedWorkspace!} : ws
                ));
            }).finally(() => resolve(true));
        });
    // eslint-disable-next-line
    }, [getURLFrom, userGroup, workspace]);

    return (<>
        <Box className={classNames(!forSharing && classes.wrapper)}
            onClick={() => !forSharing ? setOpenModal(true) : undefined}
            sx={{ cursor: !forSharing ? 'pointer' : 'auto' }}>
            {children}
        </Box>
        {openModal && (
            <Dialog className={classes.dialog} open={openModal} onClose={() => setOpenModal(false)}>
                <Preferences workspace={workspace} onSave={handleSave} onClose={() => setOpenModal(false)} modal independent />
            </Dialog>
        )}
    </>);
};

export default PreferencesModal;