import { Stack } from "@mui/material";
import Commentary from "../../molecules/dashboard-notes/Commentary";
import DigestMultiQuery from "../../molecules/dashboard-queries/DigestMultiQuery";

const DigestMultiSpanQueries: React.FC<{}> = () => {
    return (<>
        <Stack direction="row" spacing={1} height="100%">
            <Stack width="70%" flex="1 1 auto">
                <DigestMultiQuery />
            </Stack>
            <Stack width="30%" flex="1 1 auto">
                <Commentary />
            </Stack>
        </Stack>
    </>);
}

export default DigestMultiSpanQueries;