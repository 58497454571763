import path from "path";
import FolderIcon from '@mui/icons-material/Folder';
import { ReactComponent as PdfIcon } from "../../../assets/icons/pdfNew.svg";
import { ReactComponent as ExcelIcon } from "../../../assets/icons/excel.svg";
import { ReactComponent as WordIcon } from "../../../assets/icons/word.svg";
import { ReactComponent as PowerpointIcon } from "../../../assets/icons/powerpoint.svg";
import { CompanyFile } from "../../../types/files";

export const getFileIcon = (fileName: string, className?: string) => {
    const extension = path.extname(fileName)?.replace(".", "")?.toLowerCase();
    switch (extension) {
        case "pdf":
            return <PdfIcon className={className} />;
        case "doc":
        case "docx":
            return <WordIcon className={className} />;
        case "xls":
        case "xlsx":
            return <ExcelIcon className={className} />;
        case "ppt":
        case "pptx":
            return <PowerpointIcon className={className} />

        default:
            return <PdfIcon className={className} />;
    }
}

export const getFolderFileIcon = (file: CompanyFile, folderClassName: string, fileClassName: string) => {
    if (file.isDirectory) {
        return <FolderIcon className={folderClassName} />
    }

    return getFileIcon(file.name, fileClassName);
}