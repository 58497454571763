import { Avatar, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { deleteCommentFunc, updateCommentFunc } from "../../lib/helper";
import { EDITOR_JS_TOOLS } from "../../helpers/editor-js";
import { useContext, useRef, useState } from "react";
import { createReactEditorJS } from "react-editor-js";
import { Comment, ExtendedComment } from "../../types/comments";
import moment from "moment";
import { AuthContext } from "../../contexts/AuthContext";

const useStyles = (adjustTop: number = 0, adjustLeft: number = 0) => makeStyles((theme) => ({
    editorContainer: {
        position: 'fixed',
        width: 380,
        zIndex: 1100,
    },
    editor: {
        backgroundColor: 'white',
        height: 310,
        position: 'relative',
        top: adjustTop === 0 ? -32 : -100 - adjustTop,
        left: adjustLeft === 0 ? 0 : -200 - adjustLeft,
        border: '1px solid #AEB5BC',
        borderRadius: 8,
        fontFamily: 'system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,Oxygen,Open Sans,Helvetica Neue',
        "& .codex-editor__redactor": {
            paddingBottom: "0 !important"
        },
        "& .ce-toolbar": {
            display: 'none'
        }
    },
    header: {
        margin: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    avatar: {
        border: '2px solid white',
        fontSize: 12,
        fontWeight: 600,
        height: 32,
        width: 32
    },
    user: {
        fontWeight: 'bold'
    },
    updatedAt: {
        color: '#AEB5BC'
    },
    editorInternal: {
        '&>:first-child': {
            height: 200,
            overflowY: 'auto',
            margin: 12,
            overflowX: 'hidden'
        }
    },
    buttonRow: {
        display: 'flex',
        margin: 8,
        justifyContent: 'space-between',
    },
    buttonRowRight: {
        display: 'flex',
        justifyContent: 'end',
        gap: 16
    },
    buttonSave: {
        borderRadius: 16,
        backgroundColor: '#048290',
        color: 'white',
        textTransform: 'none',
        fontWeight: 700
    },
    buttonCancel: {
        borderRadius: 16,
        color: '#048290',
        textTransform: 'none',
        fontWeight: 700
    },
    commentHeading: {
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: 5,
    },
    userHeading: {
        fontSize: 14,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 1,
        color: 'black',
        width: 'calc(100% - 60px)',
        marginRight: 10,
        whiteSpace: 'nowrap'
    },
    leftCommentHeading: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        alignItems: 'baseline',
        width: 'calc(100% - 90px)'
    },
    rightCommentHeading: {
        display: 'flex',
        flexDirection: 'row',
        width: 90,
        whiteSpace: 'nowrap',
        justifyContent: 'end'
    },
}));

const PopupEditor: React.FC<{
    comment: ExtendedComment,
    editableByDefault?: boolean,
    close: () => void,
    onEdit?: () => void,
    onMouseLeave: () => void,
    updateComment?: (c: Comment) => void,
    addComment?: (text?: string) => void,
    deleteComment?: (id: string) => void
}> = ({
    comment,
    editableByDefault,
    close,
    onMouseLeave,
    updateComment,
    addComment,
    deleteComment,
    onEdit
}) => {
    const [isEditable, setIsEditable] = useState(editableByDefault || false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [editorInstance, setEditorInstance] = useState<any>(null);
    const [adjustTop, setAdjustTop] = useState(0);
    const [adjustLeft, setAdjustLeft] = useState(0);
    const ReactEditorJS = createReactEditorJS();
    const editorCore = useRef<any>(null);

    const classes = useStyles(adjustTop, adjustLeft)();
    const { user } = useContext(AuthContext);
    const userName = comment?.email || user.username;

    const enableEdit = () => {
        if (editorInstance?.readOnly?.toggle) {
            editorInstance?.readOnly?.toggle(false);
            setIsEditable(true);
            onEdit?.();
        }
    }

    return (
        <div className={classes.editorContainer} ref={el => {
            if (!el) return;
            const rect = el.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
                setAdjustTop(rect.bottom - window.innerHeight);
            } else {
                if (adjustTop !== 0) {
                    setAdjustTop(0);
                }
            }
            if (rect.right > window.innerWidth) {
                setAdjustLeft(rect.right - window.innerWidth);
            } else {
                if (adjustLeft !== 0) {
                    setAdjustLeft(0);
                }
            }
        }}>
            <div
                className={classes.editor}
                onMouseLeave={() => {
                    if (!isEditable) {
                        onMouseLeave();
                    }
                }}
                onDoubleClick={(e: any) => {
                    if (!isEditable) {
                        e.preventDefault();
                        e.stopPropagation();
                        enableEdit();
                    }
                }}
            >
                <div className={classes.commentHeading}>
                    <div className={classes.leftCommentHeading}>
                        <Avatar className={classes.avatar}>{userName.slice(0, 2).toUpperCase()}</Avatar>
                        <Typography className={classes.userHeading} title={userName}>
                            {userName || 'None'}
                        </Typography>
                    </div>
                    <div className={classes.rightCommentHeading}>
                        <Typography className='updatedAt'>{moment(comment.updatedAt).fromNow()}</Typography>
                    </div>
                </div>
                <div className={classes.editorInternal} id="editor">
                    <ReactEditorJS
                        autofocus={true}
                        hideToolbar={true}
                        readOnly={!editableByDefault}
                        onInitialize={async (instance: any) => {
                            await instance._editorJS.isReady;
                            editorCore.current = instance;
                            setEditorInstance(instance._editorJS);
                            if (comment?.description) {
                                try {
                                    const json = JSON.parse(comment?.description);
                                    await instance._editorJS?.blocks.render(json);
                                } catch {
                                    await instance._editorJS?.blocks.renderFromHTML(comment?.description);
                                }
                            }
                        }}
                        tools={EDITOR_JS_TOOLS}
                        onChange={() => setIsUpdated(true)}
                    />
                </div>
                <div className={classes.buttonRow}>
                    <div>
                        {isEditable && deleteComment && (
                            <Button
                                variant="contained"
                                color="warning"
                                className={classes.buttonSave}
                                onClick={async () => {
                                    if (comment?.id) {
                                        await deleteCommentFunc(comment.id);
                                        deleteComment?.(comment.id);
                                    }
                                }}
                            >Delete</Button>)
                        }
                    </div>
                    {isEditable ? (
                        <div className={classes.buttonRowRight}>
                            <Button
                                variant="text"
                                className={classes.buttonCancel}
                                onClick={close}
                            >Cancel</Button>
                            {(addComment || updateComment) && (
                                <Button
                                    variant="contained"
                                    className={classes.buttonSave}
                                    color="primary"
                                    disabled={!isUpdated}
                                    onClick={async () => {
                                        const savedData = await editorCore.current?.save();
                                        const data = JSON.stringify(savedData);
                                        if (addComment) {
                                            addComment(data);
                                        }
                                        else {
                                            const updatedComment = { ...comment, description: data };
                                            await updateCommentFunc(updatedComment);
                                            updateComment?.(updatedComment);
                                        }
                                    }
                                    }
                                >Save</Button>
                            )}
                        </div>
                    ) : (updateComment && <div className={classes.buttonRowRight}>
                        <Button
                            variant="outlined"
                            className={classes.buttonCancel}
                            onClick={enableEdit}
                        >Edit</Button>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PopupEditor;
