import { Button, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: 500,
            height: 'fit-content',
            padding: 32,
            borderRadius: 32,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 16,
    },
    text: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        color: theme.colors.neutral['800'],
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    addButton: {
        width: 'fit-content',
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const AddUserModal: React.FC<{
    isOpen: boolean,
    isLoading: boolean,
    group: string,
    email: string,
    newCount: number,
    onAdd: () => void,
    onClose: () => void,
}> = ({ isOpen, isLoading, group, email, newCount, onClose, onAdd }) => {
    const classes = useStyles();

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                {'Confirm new user'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack spacing={2} alignItems="flex-start" justifyContent="center">
                    <Typography className={classes.text}>
                        {`An invite to join the ${group} workspace will be sent to ${email}.`}
                    </Typography>
                    <Typography className={classes.text}>
                        {`Your subscription will be updated to ${newCount} users.`}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    <Button className={classes.cancelButton}
                        onClick={onClose}> Cancel </Button>
                    <LoadingButton variant="contained"
                        loading={isLoading}
                        className={classes.addButton}
                        onClick={onAdd}> Confirm </LoadingButton>
                    </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default AddUserModal;