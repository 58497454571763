import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import classNames from 'classnames';
import sortFilesByName from '../../../helpers/sortFilesByName';
import { CompanyFile } from '../../../types/files';
import { getFolderAncestors } from '../../../helpers/fileStructure';
import { useState } from 'react';
import { Checkbox, Tooltip } from '@mui/material';
import FileDeletionModal from '../components/FileDeletionModal';
import { SingleFilesFolderName } from '../../../shared/files';
import { getFolderFileIcon } from '../utils/files';

const useStyles = makeStyles((theme) => ({
  fileIcon: {
    marginRight: theme.spacing(1),
    height: 28,
    width: 28,
  },
  rightIcon: {
    height: 24,
    width: 24,
  },
  folder: {
    marginBottom: 10,
    cursor: 'pointer',
  },
  file: {
    marginBottom: 10,
    cursor: 'pointer',
    paddingLeft: 0,
  },
  folderElement: {
    zIndex: 1,
    position: "relative",
    '&::before': {
      position: " absolute",
      left: "-11px",
      top: "5px",
      content: "''",
      display: " block",
      borderLeft: "1px solid #ddd",
      height: "1em",
      borderBottom: "1px solid #ddd",
      width: "10px",
    },
    "&::after": {
      position: "absolute",
      left: "-11px",
      top: "-20px",
      content: "''",
      display: "block",
      borderLeft: "1px solid #ddd",
      height: "100%",
      zIndex: 0,
    },
    "&:last-of-type::after": {
      height: 40
    }
  },
  mainFolderIcon: {
    marginRight: theme.spacing(1),
    strokeWidth: 1,
    strokeLinejoin: 'round',
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  secondaryFolderIcon: {
    marginRight: theme.spacing(1),
    strokeWidth: 1,
    strokeLinejoin: 'round',
    color: "#D1D1D1"
  },
  folderDisabled: {
    pointerEvents: "none",
    opacity: 0.7
  },
  selectedIcon: {
    color: theme.palette.secondary.main
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: "calc(100% - 40px)",
  },
  selected: {
    border: "none !important",
    backgroundColor: `${theme.palette.info.main} !important`,
    borderRadius: 70,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
      boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    },
  },
  selectedMainText: {
    fontWeight: '900',
  },
  selectedSecondaryText: {
    fontWeight: '600',
  },
  selectedFile: {
    color: theme.palette.secondary.main
  },
  dropzoneContainer: {
    height: 'calc(100% - 130px)',
    border: `5px solid ${grey[300]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 10px',
  },
  fileLoaderContainer: {
    margin: '15px 24px 0 15px',
    width: 'calc(100% - 48px)',
  },
  fileLoader: {
    width: '100%',
  },
  fileLoaderText: {
    fontSize: '0.8rem',
  },
  folderUploadError: {
    padding: '0 24px',
  },
  singleFilesFolder: {
    color: theme.palette.secondary.main
  },
  folderContainer: {
    width: "100%"
  },
  mainFolderElement: {
    backgroundColor: "#F7F5F2",
    borderRadius: 70,
    paddingLeft: 10,
    paddingRight: 10,
    height: 40,
    alignItems: "center",
    '&:hover': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.secondary.main,
      boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    },
    '&:hover svg': {
      color: theme.palette.secondary.main,
    },
  },
}));

interface Props {
  parentId?: string;
  originalParentId?: string;
  folders: CompanyFile[];
  selectedFolder?: CompanyFile;
  onFolderSelect: (folder: CompanyFile) => void;
  selectedFile?: CompanyFile | null;
  onFileSelect: (file: CompanyFile) => void;
  currentUploadingFolderName: string | null;
  toggleFileDelete?: () => void;
  toggleFolderDelete?: () => void;
  openDeleteFileModal: boolean;
  openDeleteFolderModal: boolean;
  deleteCurrentlySelectedFile?: (file: CompanyFile) => Promise<void>;
  deleteCurrentlySelectedFolder?: (folder: CompanyFile) => Promise<void>;
  allowMultipleSelection?: boolean;
  selectedUploadFolders?: CompanyFile[];
  onSelectUploadFolder?: (folder: CompanyFile) => void;
}

const FoldersList = ({
  parentId = 'root',
  originalParentId = 'root',
  folders,
  selectedFolder,
  onFolderSelect,
  currentUploadingFolderName,
  openDeleteFileModal,
  openDeleteFolderModal,
  toggleFileDelete,
  toggleFolderDelete,
  deleteCurrentlySelectedFile,
  deleteCurrentlySelectedFolder,
  selectedFile,
  onFileSelect,
  allowMultipleSelection = false,
  selectedUploadFolders = [],
  onSelectUploadFolder,
}: Props) => {
  const classes = useStyles();
  const [isShown, setIsShown] = useState<string>("");
  const ancestors = getFolderAncestors(selectedFolder?.path);

  const [fileToDelete, setFileToDelete] = useState<CompanyFile | undefined>(undefined);

  const getName = (name: string) => {
    if (name?.length <= 33) {
      return name;
    }

    return `${name.substring(0, 15)}...${name.slice(-15)}`;
  }

  return (
    <>
      {
        folders
          .filter((folder) => folder.parentId === parentId)
          .sort(sortFilesByName)
          .map((folder, i) => {
            const selected = folder.id === selectedFolder?.id || folder?.id === selectedFile?.id || "" || ancestors.indexOf(folder.path) >= 0;
            const isRoot = parentId === "root" || parentId === originalParentId;
            const isFolder = folder.isDirectory;
            const isSelectedForUpload = !!selectedUploadFolders.find(e => e.id === folder.id);

            return (
              <div className={classNames(classes.folderContainer, { [classes.folderElement]: !isRoot })} key={'folder-list-224-' + i}>
                <Box
                  className={classNames({
                    [classes.folder]: isFolder,
                    [classes.file]: !isFolder,
                    [classes.folderDisabled]: folder.name === currentUploadingFolderName,
                  })}
                  pr={1}
                  py={1}
                  display="flex"
                  justifyContent="space-between"
                  onClick={() => {
                    if (isFolder) {
                      onFolderSelect(folder);
                    } else {
                      onFileSelect(folder);
                    }
                  }}
                  onMouseEnter={() => setIsShown(folder.id)}
                  onMouseLeave={() => setIsShown("")}
                >
                  {allowMultipleSelection &&
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 1 }}
                      checked={isSelectedForUpload}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!!onSelectUploadFolder) {
                          onSelectUploadFolder(folder);
                        }
                      }}
                    />}
                  <Box display="flex" width="100%" minWidth={0}
                    style={{ width: allowMultipleSelection && isFolder ? "calc(100% - 38px)" : "100%", }}
                    className={
                      classNames(
                        classes.mainFolderElement,
                        {
                          [classes.selected]: selected && isFolder,
                        })
                    }>
                    <Box display="flex" width="100%" minWidth={0}>
                      {
                        !(isFolder && folder.name === SingleFilesFolderName) &&
                        getFolderFileIcon(
                          folder,
                          classNames({
                            [classes.mainFolderIcon]: isRoot,
                            [classes.secondaryFolderIcon]: !isRoot,
                            [classes.selectedIcon]: selected
                          }),
                          classes.fileIcon
                        )
                      }
                      <Tooltip title={folder.name} placement="top">
                        <Typography
                          className={classNames(classes.name, {
                            [classes.selectedSecondaryText]: (selected && !isRoot) || (selected && !isFolder),
                            [classes.selectedMainText]: selected && isRoot,
                            [classes.singleFilesFolder]: isFolder && folder.name === SingleFilesFolderName,
                            [classes.selectedFile]: selected && !isFolder
                          })}
                          color={selected ? 'inherit' : 'textPrimary'}
                        >
                          {getName(folder.name)}
                        </Typography>
                      </Tooltip>
                    </Box>
                    {!isShown && selected && <KeyboardArrowRightIcon className={classes.rightIcon} />}
                    {
                      isShown === folder.id && (toggleFileDelete || toggleFolderDelete) &&
                      <ClearIcon
                        className={classes.rightIcon}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setFileToDelete(folder)
                          if (folder.isDirectory) {
                            if (toggleFolderDelete) {
                              toggleFolderDelete();
                            }
                          } else {
                            //onFileSelect(folder);
                            if (toggleFileDelete) {
                              toggleFileDelete();
                            }
                          }
                        }}
                      />
                    }
                  </Box>
                </Box>
                <Collapse in={selectedFolder?.path.includes(folder.path)}>
                  <Box ml={2}>
                    <FoldersList
                      toggleFileDelete={toggleFileDelete}
                      toggleFolderDelete={toggleFolderDelete}
                      openDeleteFileModal={openDeleteFileModal}
                      openDeleteFolderModal={openDeleteFolderModal}
                      deleteCurrentlySelectedFile={deleteCurrentlySelectedFile}
                      deleteCurrentlySelectedFolder={deleteCurrentlySelectedFolder}
                      parentId={folder.id}
                      originalParentId={originalParentId}
                      folders={folders}
                      selectedFolder={selectedFolder}
                      onFolderSelect={onFolderSelect}
                      currentUploadingFolderName={currentUploadingFolderName}
                      selectedFile={selectedFile}
                      onFileSelect={onFileSelect}
                      allowMultipleSelection={allowMultipleSelection}
                      selectedUploadFolders={selectedUploadFolders}
                      onSelectUploadFolder={onSelectUploadFolder}
                    />
                  </Box>
                </Collapse>
              </div>
            );
          })
      }

      {
        openDeleteFileModal && fileToDelete && (
          <FileDeletionModal
            fileToDelete={fileToDelete}
            onClose={toggleFileDelete}
            onConfirm={deleteCurrentlySelectedFile}
          />
        )
      }
      {
        openDeleteFolderModal && fileToDelete && (
          <FileDeletionModal
            fileToDelete={fileToDelete}
            onClose={toggleFolderDelete}
            onConfirm={deleteCurrentlySelectedFolder}
          />
        )
      }
    </>
  );
};

export default FoldersList;
