import makeStyles from "@mui/styles/makeStyles";
import DropboxIcon from "../../assets/icons/dropboxNew.svg";
import GoogleDriveIcon from "../../assets/icons/googleDriveNew.svg";
import OneDriveIcon from "../../assets/icons/oneDriveNew.svg";
import BoxIcon from "../../assets/icons/boxNew.svg";
import CheckIcon from "../../assets/icons/check.svg";
import { integration } from "../../types/integrations";

export type IntegrationIconStatus = {
    name: integration;
    enabled?: boolean;
}

const integrationIconMap: Record<integration, typeof DropboxIcon> = {
    BOX: BoxIcon,
    DROPBOX: DropboxIcon,
    GOOGLE: GoogleDriveIcon,
    MICROSOFT: OneDriveIcon,
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        marginRight: 10,
    },
    check: {
        position: 'absolute',
        bottom: -5,
        right: -5,
        borderRadius: 16
    },
    unCheck: {
        position: 'absolute',
        bottom: -5,
        right: -5,
        borderRadius: '50%',
        background: theme.colors.error['700'],
        width: '18px',
        height: '18px',
        display: 'grid',
        placeContent: 'center'
    },
    unCheckIcon: {
        color: 'white',
        width: 12,
        height: 12
    }
}));

const IntegrationIcon: React.FC<{ icon: IntegrationIconStatus }> = ({ icon }) => {
    const classes = useStyles();

    if (!icon.enabled) {
        return <></>
    }

    return (
        <div className={classes.container}>
            <img alt={icon.name} src={integrationIconMap[icon.name]} />
            {icon.enabled ?
                <img className={classes.check} alt="checked" src={CheckIcon} /> :
                <></>
            }
        </div>
    )
}

export default IntegrationIcon;