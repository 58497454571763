import makeStyles from "@mui/styles/makeStyles";
import { Button } from "@mui/material";
import { CompanyFile } from "../../types/files";

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        marginLeft: 13,
        marginBottom: 13
    },
    buttonContainer: {
        width: '50%'
    },
    selectedButton: {
        color: 'black',
        fontSize: 16,
        borderRadius: 0,
        borderBottom: '3px solid #048290',
        width: '100%',
        textTransform: 'none',
        fontWeight: 'bold',
        height: 40
    },
    notSelectedButton: {
        color: '#878E95',
        fontSize: 16,
        borderRadius: 0,
        width: '100%',
        textTransform: 'none',
        height: 40,
        '&:hover': {
            borderWidth: 2,
        }
    },
    flex: {
        display: 'flex',
        justifyContent: 'start'
    },
    secondRow: {
        marginTop: 8
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        overflow: 'hidden'
    }
}));

const TagModeButton: React.FC<{ name: string, isSelected?: boolean, onClick: (mode: string) => void }> = ({ name, isSelected, onClick }) => {
    const classes = useStyles();
    return (
        <span className={classes.buttonContainer}>
            <Button
                variant="text"
                className={isSelected ? classes.selectedButton : classes.notSelectedButton}
                onClick={() => onClick(name)}
            >
                {['All', 'Pins', 'Files'].includes(name) ? (name === 'All' ? 'All comments' : name) : (
                    <span className={classes.ellipsis}>
                        <span>This file</span>
                    </span>)
                }
            </Button>
        </span>
    );
}

const TagModes: React.FC<{ selectedMode: string, selectedFile?: CompanyFile, onSelectMode: (mode: string) => void, publicDashboard?: boolean }> = (
    { selectedMode, selectedFile, onSelectMode, publicDashboard }
) => {
    const renderTagModeButton = (name: string) => {
        return <TagModeButton name={name} isSelected={selectedMode === name} onClick={onSelectMode} />
    }

    const classes = useStyles();

    return (
        <div className={classes.buttonsContainer}>
            <div className={classes.flex}>
                {!publicDashboard && renderTagModeButton('All')}
                {/*renderTagModeButton('Pins')*/}
                {/*!publicDashboard && renderTagModeButton('Files')*/}
                {!publicDashboard && selectedFile && renderTagModeButton(selectedFile.name)}
            </div>
            {/*!publicDashboard && selectedFile && <div className={classes.secondRow}>
                {renderTagModeButton(selectedFile.name)}
            </div>*/}
        </div>
    )
}

export default TagModes;