import { IconButton, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RenderHighlightTargetProps } from "@react-pdf-viewer/highlight";
import CommentIcon from '@mui/icons-material/Comment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { PreviewHighlightAction } from '../../../../types/common';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        position: 'absolute',
        transform: 'translate(0, 8px)',
        zIndex: 10,
        "& > *:not(:last-child)": {
            marginRight: "5px"
        }
    },
    iconButton: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        }
    }
}));

const HighlightAreaActions: React.FC<{ enableDashboard: boolean; onOpen: (action: PreviewHighlightAction) => void; } & RenderHighlightTargetProps> = ({ enableDashboard, onOpen, toggle, selectionRegion }) => {
    const classes = useStyles();

    const getLeftPosition = (paneSize: number) => {
        let pageLayerElement = document.querySelector(".active-tab .rpv-core__page-layer");

        if (!pageLayerElement) {
            pageLayerElement = document.querySelector(".dashboard-doc-selected .rpv-core__page-layer");
        }

        if (!pageLayerElement) {
            return `${selectionRegion.left}%`;
        }

        const positionInPixels = pageLayerElement.clientWidth * selectionRegion.left / 100;

        if (positionInPixels + paneSize > pageLayerElement.clientWidth) {
            return `${pageLayerElement.clientWidth - paneSize - 10}px`;
        }

        return `${selectionRegion.left}%`;
    }

    return <div
        className={classes.container}
        style={{
            left: getLeftPosition(85),
            top: `${selectionRegion.top + selectionRegion.height}%`,
        }}
    >
        <Tooltip title="Add a note" placement="top">
            <IconButton className={classes.iconButton} onClick={() => { onOpen(PreviewHighlightAction.Comment); toggle(); }}>
                <CommentIcon />
            </IconButton>
        </Tooltip>
        {
            enableDashboard &&
            <Tooltip title="Add text to dashboard" placement="top">
                <IconButton className={classes.iconButton} onClick={() => { onOpen(PreviewHighlightAction.Dashboard); toggle(); }}>
                    <DashboardIcon />
                </IconButton>
            </Tooltip>
        }
    </div>
};

export default HighlightAreaActions;