import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Autocomplete, Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from "uuid";
import { Dashboard } from "../../../../types/files";
import { getUserEmail } from "../../../../helpers/authUser";
import { AuthContext } from "../../../../contexts/AuthContext";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";

const useStyles = makeStyles((theme) => ({
    searchBarContainer: {
        width: '100%',
    },
    searchBar: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            borderRadius: 32,
            paddingRight: '15px !important',
        }
    },
    popper: {
        "& .MuiAutocomplete-paper": {
            boxShadow: "none"
        }
    },
    optionContainer: {
        border: `1px solid #DFE2E6`,
        padding: 10,
        marginBottom: 10,
        marginLeft: 25,
        marginRight: 25,
        borderRadius: 32,
        cursor: 'pointer',
    },
    checkIcon: {
        color: '#048290',
    }
}));

const SearchBar: React.FC<{
    dashboards: Dashboard[],
    disabled: boolean,
    onSelectDashboard: (dashboard: Dashboard | null | undefined) => void,
    preselectedDashboard?: Dashboard,
    flags?: any,
}> = ({ dashboards, disabled, onSelectDashboard, preselectedDashboard, flags }) => {
    const classes = useStyles();
    const { user, userGroup } = useContext(AuthContext);
    const { usage } = useContext(SubscriptionContext);

    const [selectedDashboardName, setSelectedDashboardName] = useState<string | undefined | null>();
    const [inputValue, setInputValue] = useState<string | undefined | null>();
    const searchRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (preselectedDashboard) {
            setSelectedDashboardName(preselectedDashboard.title);
        }
    }, [preselectedDashboard]);

    useEffect(() => {
        if (selectedDashboardName) {
            const found = dashboards.find((dashboard) =>
                (dashboard.title.trim().toLowerCase()) === selectedDashboardName.toLowerCase()
            );

            onSelectDashboard(found ?? {
                createdAt: (new Date()).toISOString(),
                updatedAt: (new Date()).toISOString(),
                email: getUserEmail(user),
                group: userGroup,
                project: userGroup,
                id: uuidv4(),
                title: selectedDashboardName,
                documents: [],
                selections: [],
                screenshots: [],
                notes: [],
                lastUpdatedBy: "",
                shouldRefresh: false,
                refreshData: null,
                summary: "",
                status: "",
            } as Dashboard);
        }
        // eslint-disable-next-line
    }, [selectedDashboardName, dashboards]);

    return (
        <Box className={classes.searchBarContainer}>
            <Autocomplete
                freeSolo
                isOptionEqualToValue={(option, value) => option === value}
                disabled={disabled}
                value={selectedDashboardName || ""}
                options={dashboards.map((option) => option.title)}
                autoComplete
                clearOnEscape
                openOnFocus
                filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt?.toLowerCase().includes(inputValue.toLowerCase()) : !!opt)}
                getOptionLabel={option => option || ""}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        backgroundColor: "#fff"
                    }
                }}
                classes={{
                    popper: classNames(classes.popper, "large-grey-scrollbar")
                }}
                renderOption={(props, option) => {
                    return <Box {...props} component="li" key={option} className={classes.optionContainer}>
                        <Typography fontWeight="bold" color="#000" marginLeft={1}>{option}</Typography>
                    </Box>
                }}
                ListboxProps={
                    {
                        style: {
                            maxHeight: '230px',
                        }
                    }
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        ref={searchRef}
                        className={classes.searchBar}
                        onChange={(e) => setInputValue(e.target.value)}
                        variant="outlined"
                        size="small"
                        placeholder="Select dashboard…"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start" >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: selectedDashboardName && !inputValue ?
                                <InputAdornment position="end" >
                                    <CheckCircleIcon className={classes.checkIcon} />
                                </InputAdornment> :
                                (((flags?.monthlyDashboardLimit < 0) || ((usage?.dashboardsCreated ?? 0) < flags?.monthlyDashboardLimit))
                                    && inputValue && selectedDashboardName !== inputValue
                                    && !dashboards.find(d => d.title.toLowerCase().includes(inputValue.toLowerCase())) ?
                                    <InputAdornment position="end" >
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                if (searchRef && searchRef?.current) {
                                                    const event = new KeyboardEvent('keydown', {
                                                        altKey: false,
                                                        bubbles: true,
                                                        cancelable: true,
                                                        charCode: 0,
                                                        code: "Enter",
                                                        key: "Enter",
                                                        keyCode: 13,
                                                        which: 13
                                                    });
                                                    searchRef?.current?.dispatchEvent(event);
                                                    setInputValue(null);
                                                }
                                            }}
                                        >
                                            Add
                                        </Button>
                                    </InputAdornment> :
                                    null),
                            style: {
                                backgroundColor: selectedDashboardName ? "#F7F9FA" : "transparent",
                                width: '98%',
                                borderColor: "#DFE2E6",
                                fontWeight: "bold",
                                color: "#000"
                            }
                        }}
                    />
                )}
                onChange={(_, value) => {
                    setSelectedDashboardName(value?.trim());
                    setInputValue(value?.trim());
                }}
            />
        </Box>
    );

};

export default withLDConsumer()(SearchBar);