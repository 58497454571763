import React, { useEffect, useState } from 'react';
import { Stack, Typography } from "@mui/material";
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import { splitter } from '../../../helpers/textSplitter';
import { NoInfoYet } from '../../../types/search';

const DigestAnswer: React.FC<{ answeredQuestion: AnsweredQuestion }> = ({ answeredQuestion }) => {
    const [sentences, setSentences] = useState<string[]>([]);

    useEffect(() => {
        if (typeof answeredQuestion.answer === 'string')
            setSentences(splitter(answeredQuestion.answer as string).map((split) => split.sent));
    }, [answeredQuestion]);

    return (<>
        <Stack direction="column" spacing={1} alignItems="baseline" mb={1}>
            <Typography> {!!sentences.length ? (sentences[0]) : (NoInfoYet.answer)} </Typography>
        </Stack>
    </>);
}

export default DigestAnswer;
