import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Dashboard } from '../types/files';
import { API, graphqlOperation } from 'aws-amplify';
import { onDashboard } from '../graphql/subscriptions';
import {AuthContext} from './AuthContext';
import ObjectUtils from '../utils/ObjectUtils';

type DashboardContextProps = {
  dashboard: Dashboard | null;
  setDashboard: Dispatch<SetStateAction<Dashboard | null>>;
  isPublicView: boolean;
  setPublicView: Dispatch<SetStateAction<boolean>>;
}

export const DashboardContext = createContext<DashboardContextProps>({
  dashboard: null,
  setDashboard: () => {},
  isPublicView: false,
  setPublicView: () => {},
});

const DashboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { userGroup } = useContext(AuthContext);
  const [dashboard,  setDashboard] = useState<Dashboard | null>(null);
  const [isPublicView, setPublicView] = useState<boolean>(false);
  const dashboardSubscriptionRef = useRef<any>(null);
  const dashboardRef = useRef<Dashboard|null>(null);

  const updateSubscribedDashboard = useCallback((updatedDashboard?: Dashboard|null) => {
    if (!updatedDashboard?.id)
      return;

    if (updatedDashboard?.id !== dashboardRef.current?.id)
      return;

    if (!ObjectUtils.equalObjects(updatedDashboard, dashboardRef.current)) {
      dashboardRef.current = updatedDashboard;
      setDashboard({ ...dashboardRef.current });
    }
  }, []);

  const subscribeDashboard = useCallback(async () => {
    if (dashboardSubscriptionRef.current)
      dashboardSubscriptionRef.current.unsubscribe();

    if (userGroup) {
      dashboardSubscriptionRef.current = await (API.graphql(
        graphqlOperation(onDashboard, { group: userGroup })) as any)
          .subscribe({ next: ({ value }: any) => updateSubscribedDashboard(value?.data?.onDashboard)});
    }
  }, [userGroup, updateSubscribedDashboard]);

  useEffect(() => {
    if (!!userGroup && !!dashboard)
      subscribeDashboard();
    else
      dashboardSubscriptionRef.current?.unsubscribe();

    return () => {
      dashboardSubscriptionRef.current?.unsubscribe();
    }
    // eslint-disable-next-line
  }, [userGroup, dashboard]);

  useEffect(() => {
    if (!!dashboard) {
      if (!dashboardRef.current)
        dashboardRef.current = {...dashboard};
    } else {
      dashboardRef.current = null;
    }
    // eslint-disable-next-line
  }, [dashboard]);

  const contextValue = useMemo(() => ({
    dashboard,
    setDashboard,
    isPublicView,
    setPublicView,
  }), [
    dashboard,
    setDashboard,
    isPublicView,
    setPublicView,
  ]);

  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
